import { useMemo } from 'react'

import moment from 'moment'

type TotalSpend = {
  tenantId: string
  subscriptionId: string
  totalSpendGBP: number
  usageDate: string

  resourceLocation?: string
  resourceGroup?: string
  meterCategory?: string
}

interface UseExtendedTotalSpendConfig {
  data?: TotalSpend[] | null
  stack: string

  selectedSubscriptions: Array<{ id: string; name: string }>
  selectedTenants: Array<{ id: string; name: string }>
}

const stackKeyMap = {
  subscription: 'subscriptionId',
  tenant: 'tenantId',
  meterCategory: 'meterCategory',
  resourceLocation: 'resourceLocation',
  resourceGroup: 'resourceGroup'
}

const useExtendedTotalSpend = ({ data, stack, selectedSubscriptions, selectedTenants }: UseExtendedTotalSpendConfig) => {
  // 1. Filter relevant data
  const filteredData = useMemo(() => {
    const filtered = data?.filter((d) => selectedSubscriptions.some((s) => s.id === d.subscriptionId)).filter((d) => d.totalSpendGBP > 0)
    return filtered
  }, [data, selectedSubscriptions])

  // 2. Group by stack by identifier
  const groupedByIdentifier = useMemo(() => {
    const stackKey = stackKeyMap[stack as keyof typeof stackKeyMap]
    const grouped = new Map<string, TotalSpend[]>()

    filteredData?.forEach((item) => {
      const key = (item[stackKey as keyof typeof item] as string) || 'None'
      const group = grouped.get(key) || []
      group.push(item)
      grouped.set(key, group)
    })

    return grouped
  }, [filteredData, stack])

  // 3. Sort by total spend and extend with total spend, average and inceptionDate
  const extendedTotalSpend = useMemo(() => {
    if (!groupedByIdentifier) return null

    // Pre-compute mappings for quick lookups
    const subscriptionNameMap = new Map(selectedSubscriptions.map((s) => [s.id, s.name]))
    const tenantNameMap = new Map(selectedTenants.map((t) => [t.id, t.name]))

    const extended = Array.from(groupedByIdentifier.entries())
      .map(([key, values]) => {
        const nameMap = stack === 'subscription' ? subscriptionNameMap : tenantNameMap
        const name = nameMap.get(key)
        const isNameRequired = ['subscription', 'tenant'].includes(stack)

        if (isNameRequired && !name) return null

        const totalSpend = values.reduce((acc, curr) => acc + curr.totalSpendGBP, 0)
        const _values = values.map((v) => ({ ...v, usageDate: moment(v.usageDate), strUsageDate: v.usageDate.slice(0, 10) }))

        const dates = _values.map((v) => v.usageDate)
        const inceptionDate = moment.min(dates)
        const lastDataPointDate = moment.max(dates)

        const average = totalSpend / (lastDataPointDate.diff(inceptionDate, 'days') + 1)

        return {
          name: name ?? key,
          key,
          totalSpend,
          values: _values,
          inceptionDate,
          average,
          lastContentfulDate: lastDataPointDate
        }
      })
      .filter((d) => d && d.totalSpend > 0.1)
      .sort((a, b) => b!.totalSpend - a!.totalSpend)
    return extended as NonNullable<(typeof extended)[number]>[]
  }, [groupedByIdentifier, selectedSubscriptions, selectedTenants, stack])

  // 3.5 Check to see if it's a complete data set, meaning that the last day of the data points is in every spend
  const completeDataSetStats = useMemo(() => {
    if (!extendedTotalSpend) return null

    const firstDataPointDate = moment.min(extendedTotalSpend.flatMap((d) => d.values.map((v) => v.usageDate)))
    const lastDataPointDate = moment.max(extendedTotalSpend.flatMap((d) => d.values.map((v) => v.usageDate)))

    const lastDataPointKey = lastDataPointDate.format('MM/DD/YYYY')
    const complete = extendedTotalSpend.every((d) => d.values.some((v) => v.strUsageDate === lastDataPointKey))

    if (!complete) return null

    // return the overall average and the overall total
    const totalSpend = extendedTotalSpend.reduce((acc, curr) => acc + curr.totalSpend, 0)
    const totalAverage = extendedTotalSpend.reduce((acc, curr) => acc + curr.average, 0)

    if (totalSpend === 0) return null

    const dates = [] as moment.Moment[]
    for (const startDate = firstDataPointDate.clone(); startDate?.isSameOrBefore(lastDataPointDate); startDate.add(1, 'day')) dates.push(startDate.clone())

    const dailySpend = dates.map((date) => {
      const dateKey = date.format('MM/DD/YYYY')
      const values = extendedTotalSpend.flatMap((d) => d.values.filter((v) => v.strUsageDate === dateKey))
      const totalSpend = values.reduce((acc, curr) => acc + curr.totalSpendGBP, 0)

      return { date, totalSpend }
    })

    return { totalSpend, totalAverage, dailySpend }
  }, [extendedTotalSpend])

  return [extendedTotalSpend, completeDataSetStats] as const
}

export default useExtendedTotalSpend
