import { useCallback } from 'react'

import moment from 'moment'
import { useDebouncedCallback } from 'use-debounce'

import { useChangeApprovalFiltersContext } from '../context/ChangeApprovalFilter/useContext'

export const useChangeApprovalFilters = () => {
  const { filterOptions, setFilterOptions } = useChangeApprovalFiltersContext()

  const handleDatesChange = useCallback(
    (startDate: moment.Moment, endDate: moment.Moment) => {
      setFilterOptions((prev) => ({
        ...prev,
        startDate,
        endDate
      }))
    },
    [setFilterOptions]
  )

  const handlePeriodChange = useCallback(
    (newPeriod: string) => {
      const firstDayOfThisMonth = moment().startOf('month')
      const lastDayOfThisMonth = moment().endOf('month')

      switch (newPeriod) {
        case 'current':
          handleDatesChange(firstDayOfThisMonth, lastDayOfThisMonth)
          break
        case 'previous':
          handleDatesChange(firstDayOfThisMonth.subtract(1, 'month'), lastDayOfThisMonth.subtract(1, 'month'))
          break
      }

      setFilterOptions((prev) => ({
        ...prev,
        period: newPeriod
      }))
    },
    [handleDatesChange, setFilterOptions]
  )

  const handleSearchChange = useDebouncedCallback((searchTerm: string) => {
    setFilterOptions((prev) => ({
      ...prev,
      keyword: searchTerm,
      state: null
    }))
  }, 500)

  const handleClearSearch = useCallback(() => {
    setFilterOptions((prev) => ({
      ...prev,
      keyword: null
    }))
  }, [setFilterOptions])

  const handleStateFilter = useCallback(
    (state: string | null) => {
      setFilterOptions((prev) => ({
        ...prev,
        state: state
      }))
    },
    [setFilterOptions]
  )

  const handleSortChange = useCallback(
    (orderBy: string | null, orderByDesc: boolean) => {
      setFilterOptions((prev) => ({
        ...prev,
        orderBy,
        orderByDesc
      }))
    },
    [setFilterOptions]
  )

  const handlePageChange = useCallback(
    (page: number) => {
      setFilterOptions((prev) => ({
        ...prev,
        skip: page * prev.take
      }))
    },
    [setFilterOptions]
  )

  const handlePageSizeChange = useCallback(
    (pageSize: number) => {
      setFilterOptions((prev) => ({
        ...prev,
        take: pageSize
      }))
    },
    [setFilterOptions]
  )

  const handleShowOwnApprovalsChange = useCallback(
    (showOwnApprovals: boolean) => {
      setFilterOptions((prev) => ({
        ...prev,
        showOwnApprovals
      }))
    },
    [setFilterOptions]
  )

  return {
    filterOptions,
    handleDatesChange,
    handlePeriodChange,
    handleSearchChange,
    handleClearSearch,
    handleStateFilter,
    handleSortChange,
    handlePageChange,
    handlePageSizeChange,
    handleShowOwnApprovalsChange
  }
}
