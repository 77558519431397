import { type FunctionComponent, type ReactNode } from 'react'

import { Iconly } from 'react-iconly'
import { useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

import { closeNavigation } from '../../../actions/navigationActions'
import Badge from '../Badge/Badge'

/* css */
import './ParentLink.css'

interface Props {
  id: number
  label: string
  icon: string
  route?: string
  activeId: number
  children: ReactNode
  onClick: (id: number) => void
}

const ParentLink: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { id, label, icon, route, activeId, children } = props
  const active = activeId === id

  const dispatch = useDispatch()
  const location = useLocation()

  return (
    <div
      className="parent-link rounded-3xl bg-th-lowlight px-6 font-bold xs:rounded-none xs:rounded-l-2xl xs:bg-th-navigation-parent-active xs:p-0 xs:font-normal"
      aria-expanded={active}
    >
      <Link
        to={route || '/'}
        className="title flex h-14 items-center"
        onClick={(event) => {
          if (route) {
            if (route !== location.pathname) {
              //dispatch(startLoading())
            }
            dispatch(closeNavigation())
          } else {
            event.preventDefault()
          }
          props.onClick(id)
        }}
      >
        <Iconly name={icon} set="light" />
        <Badge active={false} className="-ml-2.5 -mt-4" />
        <span className="flex-auto px-4 xs:flex-none">{label}</span>
        {children && <Iconly name="ChevronDown" set="light" aria-expanded={active} />}
      </Link>
      {children && active && <hr className="border-th-border xs:hidden" />}
      {children && (
        <div className="children relative" aria-expanded={active}>
          {children}
        </div>
      )}
    </div>
  )
}

export default ParentLink
