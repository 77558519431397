import { useState } from 'react'

import moment, { type Moment } from 'moment'

export const useCalendar = (handleDatesChange: (start: Moment, end: Moment) => void) => {
  const [calendarDate, setCalendarDate] = useState<Moment>(moment())

  const firstDayOfLastMonth = moment().subtract(1, 'month').startOf('month')
  const lastDayOfNextMonth = moment().add(1, 'month').endOf('month')

  const handleTodayClick = () => {
    setCalendarDate(moment())
    handleDatesChange(firstDayOfLastMonth, lastDayOfNextMonth)
  }

  const handleNextClick = () => {
    const newdate = calendarDate.clone().add(1, 'month')
    const newstart = newdate.clone().add(-1, 'month').startOf('month')
    const newend = newdate.clone().add(1, 'month').endOf('month')
    setCalendarDate(newdate)
    handleDatesChange(newstart, newend)
  }

  const handlePrevClick = () => {
    const newdate = calendarDate.clone().add(-1, 'month')
    const newstart = newdate.clone().add(-1, 'month').startOf('month')
    const newend = newdate.clone().add(1, 'month').endOf('month')
    setCalendarDate(newdate)
    handleDatesChange(newstart, newend)
  }

  return {
    calendarDate,
    handleTodayClick,
    handleNextClick,
    handlePrevClick
  }
}
