import { type FunctionComponent } from 'react'

import { useNavigate } from 'react-router'

import Button from '../../Common/Button'

const Unauthorised: FunctionComponent = (): JSX.Element => {
  const navigate = useNavigate()

  return (
    <div className="relative h-full w-full grow">
      <div className="z-8 absolute flex h-full w-full flex-col items-center justify-center bg-th-content opacity-90">
        <p>You do not have permission to view this data.</p>
        <Button bordered onClick={() => navigate('/profile')} className="mt-8">
          View Permissions
        </Button>
      </div>
    </div>
  )
}

export default Unauthorised
