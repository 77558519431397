import { type MutableRefObject, useEffect, useRef } from 'react'
import { type ReactNode } from 'react'

/* utils */
import { classNames } from '../../utils/classNames'

/* positions */
enum Position {
  CENTER,
  TOP_LEFT,
  TOP_RIGHT,
  BOTTOM_LEFT,
  BOTTOM_RIGHT
}

const POSITION_MAPS: Record<Position, string> = {
  [Position.CENTER]: 'left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2',
  [Position.TOP_LEFT]: '',
  [Position.TOP_RIGHT]: 'right-0',
  [Position.BOTTOM_LEFT]: 'bottom-0',
  [Position.BOTTOM_RIGHT]: 'bottom-0 right-0'
}

interface Props {
  position: Position
  className?: string
  children?: ReactNode
  close?: () => void
}

const Card = (props: Props): JSX.Element => {
  const { position, className, children } = props

  const ref = useRef() as MutableRefObject<HTMLInputElement>

  const useOutsideDetector = (ref: MutableRefObject<HTMLInputElement>) => {
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          if (props.close) {
            props.close()
          }
        }
      }

      /* bind the event listener */
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        /* unbind the event listener on clean up */
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  useOutsideDetector(ref)

  return (
    <div
      className={classNames(
        'absolute z-50 rounded-lg border border-th-border bg-th-content p-4 shadow',
        'w-full xs:w-auto',
        POSITION_MAPS[position],
        className
      )}
      ref={ref}
    >
      {children}
    </div>
  )
}

Card.defaultProps = {
  position: Position.CENTER
}

Card.position = Position

export default Card
