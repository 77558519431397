import { useMemo } from 'react'

import { getBlueGradientColors, getRainbowColors } from 'src/utils/colors'
import { formatCurrency } from 'src/utils/format'

import { PiInfo } from 'react-icons/pi'

import Popover from '../Common/Popover'

type LinearBarProps = {
  title: string
  data: { name: string; value: number; open?: boolean }[]
  colorful?: boolean
  info?: string
}

const LinearBar: React.FC<LinearBarProps> = (props) => {
  const percentageBreakdown = useMemo(() => {
    const total = props.data.reduce((acc, cur) => acc + cur.value, 0)
    const length = props.data.length - 1
    const colors = props.colorful ? getRainbowColors(length) : getBlueGradientColors(length)
    return props.data.map((d, idx) => {
      return {
        ...d,
        percentage: (d.value / total) * 100,
        color: d.open ? '' : colors[idx]
      }
    })
  }, [props.data, props.colorful])

  return (
    <div className="space-y-1 rounded-xl border border-th-border bg-th-content px-8 py-6">
      <div className="mb-6 flex gap-2">
        <p className="font-headline text-2xl font-bold text-th-text">{props.title}</p>
        {props.info && (
          <Popover text={props.info} position="bottom">
            <PiInfo className="text-gray-500 mb-auto h-4 w-4 text-2xl" />
          </Popover>
        )}
      </div>
      <div className="w-full gap-0.5 overflow-hidden rounded-full">
        {percentageBreakdown.map((d, idx) => (
          <div
            key={d.name + '_bar' + idx}
            className={`h-3 ${idx === 0 ? 'rounded-l-full' : ''} ${idx === percentageBreakdown.length - 1 ? 'rounded-r-full' : ''} ${
              d.open ? 'bg-th-border' : ''
            } inline-block`}
            style={{ width: `${d.percentage}%`, backgroundColor: d.color }}
          ></div>
        ))}
      </div>
      <div className="flex flex-wrap gap-x-12 gap-y-2 pt-4 text-sm text-th-text-secondary">
        {percentageBreakdown.map((d, idx) => (
          <div key={d.name + '_label' + idx} className={``}>
            <div className="flex items-center gap-2">
              <div className={`h-3 w-3 rounded-full ${d.open ? 'bg-th-border' : ''}`} style={{ backgroundColor: d.color }}></div>
              <span className="font-bold">{d.name}</span>
            </div>
            <span className="text-lg font-extrabold text-th-text">{formatCurrency(d.value, true)}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default LinearBar
