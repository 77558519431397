const initialState = {
  ticket: false,
  new_ticket: false,
  request_quote: false,
  service: false,
  order: false,
  contact: false,
  new_contact: false,
  profile: false,
  quote: false,
  invoice: false,
  reserved_instance: false,
  software_subscription: false,
  m365_secure_score_info: false,
  recommendation: false,
  creditnote: false,
  new_azure_deployment: false,
  new_azure_plan: false
}

const drawerReducer = (
  state = initialState,
  action: {
    type: string
    payload: Record<string, boolean>
  }
): Record<string, boolean> => {
  switch (action.type) {
    case 'OPEN_TICKET_DRAWER':
    case 'OPEN_CREDITNOTE_DRAWER':
    case 'OPEN_QUOTE_DRAWER':
    case 'OPEN_NEW_TICKET_DRAWER':
    case 'OPEN_REQUEST_QUOTE_DRAWER':
    case 'OPEN_CHANGE_ACCOUNT_DETAILS_DRAWER':
    case 'OPEN_FEEDBACK_DRAWER':
    case 'OPEN_CANCEL_SERVICE_DRAWER':
    case 'OPEN_SERVICE_DRAWER':
    case 'OPEN_ORDER_DRAWER':
    case 'OPEN_NEW_CONTACT_DRAWER':
    case 'OPEN_PROFILE_DRAWER':
    case 'OPEN_INVOICE_DRAWER':
    case 'OPEN_RESERVED_INSTANCE_DRAWER':
    case 'OPEN_SOFTWARE_SUBSCRIPTION_DRAWER':
    case 'OPEN_BOOK_SECURITY_REVIEW_DRAWER':
    case 'OPEN_M365_SECURE_SCORE_INFO_DRAWER':
    case 'OPEN_RECOMMENDATION_DRAWER':
    case 'OPEN_AZURE_DEPLOYMENT_DRAWER':
    case 'OPEN_NEW_AZURE_PLAN_DRAWER':
    case 'OPEN_LIGHTHOUSE_CONNECTION_DRAWER':
      return {
        ...state,
        ...action.payload
      }
    case 'CLOSE_DRAWERS':
      return initialState
    default:
      return state
  }
}

export default drawerReducer
