import { useMutation, useQueryClient } from '@tanstack/react-query'

import api from 'src/constants/api'

import useAccount from '~/hooks/utils/useAccount'

import type { FeatureFlag } from '../queries/useAdminFeatureFlagQuery'

interface CreateFeatureFlagInput {
  name: string
  description: string
  enabled: boolean
  accountIds: string[]
}

const useCreateFeatureFlagMutation = () => {
  const queryClient = useQueryClient()
  const account = useAccount()

  return useMutation(
    async (input: CreateFeatureFlagInput) => {
      const response = await api.post('/provide/featureflags', input)
      return response.data as FeatureFlag
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getAdminFeatureFlags', account.id])
      }
    }
  )
}

export default useCreateFeatureFlagMutation
