import api from 'src/constants/api'

import useMutation from 'src/hooks/utils/useMutation'

const useCreateOwnContactRecordMutation = () => {
  return useMutation(
    async (accountId: string) => {
      const response = await api.post(`/servicenow/account/${accountId}/contact/create`)
      return response.data
    },
    {
      errorMessage: 'Failed to create own contact record'
    }
  )
}

export default useCreateOwnContactRecordMutation
