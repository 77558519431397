import { type FormEvent, Fragment, type FunctionComponent, useState } from 'react'

import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { showAlert } from '../../actions/alertsActions'
import { closeDrawers } from '../../actions/drawerActions'
import api from '../../constants/api'
import Button from '../Common/Button'
import Drawer from '../Common/Drawer'
import Input from '../Common/Input'
import Select from '../Common/Select'
import Submit from '../Common/Submit'
import TextArea from '../Common/TextArea'

/* feedback types */
const typeOptions = [
  {
    label: 'Issue/Bug',
    value: 'Issue/Bug'
  },
  {
    label: 'Enhancement',
    value: 'Enhancement'
  },
  {
    label: 'Other',
    value: 'Other'
  }
]

const areaOptions = [
  {
    label: 'Dashboard',
    value: 'Dashboard'
  },
  {
    label: 'Account',
    value: 'Account'
  },
  {
    label: 'Microsoft 365',
    value: 'Microsoft'
  },
  {
    label: 'Support',
    value: 'Support'
  },
  {
    label: 'Security',
    value: 'Security'
  },
  {
    label: 'Profile',
    value: 'Profile'
  },
  {
    label: 'Permissions',
    value: 'Permissions'
  },
  {
    label: 'Training',
    value: 'Training'
  },
  {
    label: 'Other',
    value: 'Other'
  }
]

/* form names */
interface Forms extends HTMLCollectionOf<HTMLFormElement> {
  newFeedbackForm: HTMLFormElement
}

const FeedbackDrawer: FunctionComponent = (): JSX.Element => {
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const user = useSelector((state: RootStateOrAny) => state.user)
  const state = useSelector((state: RootStateOrAny) => state)

  const account = useSelector((state: RootStateOrAny) => state.account)

  /* drawer actions */
  const Actions: FunctionComponent = (): JSX.Element => {
    return (
      <Fragment>
        <Button bordered onClick={() => dispatch(closeDrawers())}>
          Cancel
        </Button>
        <Submit variant="primary" form="newFeedbackForm" />
      </Fragment>
    )
  }

  const newFeedback = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    const forms = document.forms as Forms
    const form = forms.newFeedbackForm
    const data = new FormData(form)

    const ticket = {
      account: account.id,
      contact_type: 'Provide',
      category: 'Incident',
      title: 'PROVIDE Feedback:' + data.get('title'),
      users_affected: 'Single',
      business_impact: 'No Immediate Impact',
      description:
        'Feedback from Provide portal' +
        '\n\n' +
        'Area of feedback: ' +
        data.get('area') +
        '\n' +
        'User description:' +
        data.get('description') +
        '\n\n' +
        'Technical data:' +
        '\n' +
        '----------------------------------------------------------------------------------------\n' +
        'User email:' +
        user.email +
        '\n' +
        'Submitted from page: ' +
        window.location.href +
        '\n' +
        'Redux store:' +
        btoa(JSON.stringify(state))
    }

    api
      .post(`/servicenow/account/${account.id}/ticket`, ticket)
      .then((response) => {
        setLoading(false)
        dispatch(closeDrawers())

        dispatch(
          showAlert({
            type: 'success',
            message: response.data.number + ' created.',
            component: 'NewFeedback'
          })
        )
      })
      .catch((error) => {
        setLoading(false)
        dispatch(
          showAlert({
            type: 'error',
            message: 'We were unable to create your feedback. Please try again.',
            component: 'NewFeedback',
            error
          })
        )
      })
  }

  return (
    <Drawer title={'Send Feedback'} loading={loading} actions={<Actions />}>
      <form onSubmit={(e) => newFeedback(e)} id="newFeedbackForm" className="flex flex-col gap-4">
        {/* type of feedback */}
        <Select name="type" label="Type" options={typeOptions} required />
        {/* area of feedback */}
        <Select name="area" label="Area" options={areaOptions} required />
        {/* title */}
        <Input type="text" name="title" label="Title" required />

        {/* description */}
        <TextArea className="h-32" name="description" label="Description" required />
      </form>
    </Drawer>
  )
}

export default FeedbackDrawer
