import { type ChangeEvent, type FunctionComponent } from 'react'

import { type GroupTypeBase, type OptionTypeBase } from 'react-select'

import { classNames } from '../../utils/classNames'

interface Props {
  name: string
  label?: string
  selected?: string
  focus?: true
  required?: true
  options: GroupTypeBase<OptionTypeBase>[] | OptionTypeBase[]
  className?: string
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void
}

const GroupedSelect: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { name, label, selected, focus, required, options, className } = props

  return (
    <div className="flex flex-col gap-1">
      {label && (
        <label htmlFor={name} className="flex gap-1 text-th-text-secondary">
          {label}
          {required && <div className="text-th-error">*</div>}
        </label>
      )}
      <select
        autoFocus={focus}
        required={required}
        name={name}
        className={classNames('rounded-lg border border-th-border p-2 font-body text-sm', 'cursor-pointer bg-th-content text-th-text', className)}
        onChange={props.onChange}
        defaultValue={selected || ''}
      >
        <option disabled value={''} className="hidden" />
        {options.map((group: GroupTypeBase<OptionTypeBase> | OptionTypeBase) => {
          if (group.options) {
            return (
              <optgroup key={group.label} label={group.label}>
                {group.options.map((option: OptionTypeBase) => {
                  return (
                    <option
                      key={option.value}
                      value={option.value}
                      disabled={option.disabled}
                      title={option.disabled && 'Deployments are not enabled for this subscription'}
                      selected={selected === undefined ? undefined : selected === option.value}
                    >
                      {option.label}
                    </option>
                  )
                })}
              </optgroup>
            )
          } else {
            return (
              <option key={'key_' + group.value} value={group.value} selected={selected === undefined ? undefined : selected === group.value}>
                {group.label}
              </option>
            )
          }
        })}
      </select>
    </div>
  )
}

export default GroupedSelect
