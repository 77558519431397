import React, { type PropsWithChildren, useCallback, useEffect, useState } from 'react'

import { type Entitlement, type Tenant } from 'src/types/azure'

import useNewTrendsInsights from 'src/hooks/composite/useNewTrendsInsights'
import { type MergedInsight } from 'src/hooks/services/microsoft/queries/useTrendsInsights'
import { useTenantsQuery } from 'src/hooks/services/servicenow/queries/useTenantsQuery'

import { formatCurrency } from 'src/utils/format'

import type { EmblaOptionsType } from 'embla-carousel'
import { type EmblaCarouselType } from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import moment from 'moment'
import { FcBarChart, FcFlashOff, FcFlashOn, FcInfo, FcNegativeDynamic, FcPositiveDynamic } from 'react-icons/fc'
import { PiX } from 'react-icons/pi'

import Spinner from '../Common/Spinner'

type InsightsCarouselProps = {
  options?: EmblaOptionsType
  onTileClick?: (id: string) => void
}

export const InsightsMapper = (insight: MergedInsight, tenants: Tenant[], subscriptions: Entitlement[]) => {
  const formattedDate = moment(insight.date).format('DD MMMM YYYY')

  let identifier: string | null = null
  let name = insight.groupName
  switch (insight.group) {
    case 'TenantId':
      name = tenants.find((tenant) => tenant.id === insight.groupName)?.name || insight.groupName
      break
    case 'SubscriptionId':
      name = subscriptions.find((subscription) => subscription.id === insight.groupName)?.name || insight.groupName
      break
    default:
      identifier = subscriptions.find((subscription) => subscription.id === insight.subscriptionId)?.name || null
      break
  }

  switch (insight.type) {
    case 'Increase':
      return {
        type: 'error',
        title: `Spending Increased (${formatCurrency(insight.amount, true, 0)})`,
        message: `Your daily average spending for ${name || 'None'}${identifier ? ` within ${identifier}` : ''} has risen by roughly ${formatCurrency(
          insight.amount,
          true,
          0
        )} from this time last ${insight.period}.`,
        icon: <FcPositiveDynamic size="60" />
      }
    case 'Decrease':
      return {
        type: 'success',
        title: `Spending Decreased (${formatCurrency(insight.amount, true, 0)})`,
        message: `Your daily average spending for ${name || 'None'}${identifier ? ` within ${identifier}` : ''} has dropped by roughly ${formatCurrency(
          insight.amount,
          true,
          0
        )} from this time last ${insight.period}.`,
        icon: <FcNegativeDynamic size="60" />
      }
    case 'Unusual':
      if (insight.startDate && insight.endDate) {
        const startDate = moment(insight.startDate).format('DD MMMM YYYY')
        const endDate = moment(insight.endDate).format('DD MMMM YYYY')

        return {
          type: 'warning',
          title: `Unusual Spending Alert`,
          message: `An average daily ${insight.amount > 0 ? 'increase' : 'decrease'} of roughly ${formatCurrency(insight.amount, true, 0)} for ${
            name || 'None'
          }${identifier ? ` within ${identifier}` : ''} was detected between ${startDate} and ${endDate}.`,
          icon: <FcBarChart size="60" />
        }
      }

      return {
        type: 'warning',
        title: `Unusual Spending Alert`,
        message: `An ${insight.amount > 0 ? 'increase' : 'decrease'} of roughly ${formatCurrency(insight.amount, true, 0)} for ${name || 'None'}${
          identifier ? ` within ${identifier}` : ''
        } was detected on ${formattedDate}.`,
        icon: <FcBarChart size="60" />
      }
    case 'New':
      return {
        type: 'info',
        title: `New Spend Detected`,
        message: `A new spend for ${name || 'None'}${identifier ? ` within ${identifier}` : ''} was recorded on ${formattedDate}.`,
        icon: <FcFlashOn size="60" />
      }
    case 'Ended':
      return {
        type: 'info',
        title: `Spending Removed`,
        message: `The spending for ${name || 'None'}${identifier ? ` within ${identifier}` : ''} was removed on ${formattedDate}.`,
        icon: <FcFlashOff size="60" />
      }
    default:
      return {
        type: 'info',
        title: `Unknown Insight`,
        message: `An undefined insight was detected.`,
        icon: <FcInfo size="60" />
      }
  }
}

const InsightsCarousel: React.FC<InsightsCarouselProps> = (props) => {
  const { options } = props
  const [emblaRef, emblaApi] = useEmblaCarousel(options)
  const { data: insights, isLoading, readInsight } = useNewTrendsInsights()

  const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi)

  const { data: tenants } = useTenantsQuery({ active: true })
  const subscriptions = tenants?.flatMap((tenant) => tenant.subscriptions)

  return (
    <>
      <section className={`embla flex items-center ${!insights?.length && !isLoading ? 'hidden' : ''}`}>
        <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">
            {insights?.map((insight) => (
              <div className="embla__slide" key={insight.id}>
                <div className="relative grid h-full grid-cols-3 items-center rounded-lg border border-th-border bg-th-content p-8 text-xs">
                  {InsightsMapper(insight, tenants, subscriptions)?.icon}
                  <div className="col-span-2">
                    <p
                      className={`text-lg font-bold ${!!props.onTileClick ? 'cursor-pointer hover:underline' : ''}`}
                      onClick={() => props.onTileClick?.(insight.id)}
                    >
                      {InsightsMapper(insight, tenants, subscriptions)?.title}
                    </p>
                    <p className="text-sm">{InsightsMapper(insight, tenants, subscriptions)?.message}</p>
                  </div>
                  <button
                    className="absolute right-2 top-2 cursor-pointer rounded-md border border-th-border p-0.5 hover:bg-th-border"
                    onClick={() => readInsight(insight.id)}
                  >
                    <PiX />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
      </section>
      <div className={`mx-auto flex w-full items-center justify-center ${!insights?.length && !isLoading ? '' : 'hidden'}`}>
        {!insights || isLoading ? <Spinner className="h-8 w-8" /> : 'You have read all curated insights'}
      </div>
    </>
  )
}

type UsePrevNextButtonsType = {
  prevBtnDisabled: boolean
  nextBtnDisabled: boolean
  onPrevButtonClick: () => void
  onNextButtonClick: () => void
}

const usePrevNextButtons = (emblaApi: EmblaCarouselType | undefined): UsePrevNextButtonsType => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollPrev()
  }, [emblaApi])

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollNext()
  }, [emblaApi])

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev())
    setNextBtnDisabled(!emblaApi.canScrollNext())
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    onSelect(emblaApi)
    emblaApi.on('reInit', onSelect)
    emblaApi.on('select', onSelect)
  }, [emblaApi, onSelect])

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  }
}

type PropType = PropsWithChildren<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>>

export const PrevButton: React.FC<PropType> = (props) => {
  const { children, ...restProps } = props

  return (
    <button className="embla__button embla__button--prev shrink-0 focus:border-th-border" type="button" {...restProps}>
      <svg className="embla__button__svg" viewBox="0 0 532 532">
        <path
          fill="currentColor"
          d="M355.66 11.354c13.793-13.805 36.208-13.805 50.001 0 13.785 13.804 13.785 36.238 0 50.034L201.22 266l204.442 204.61c13.785 13.805 13.785 36.239 0 50.044-13.793 13.796-36.208 13.796-50.002 0a5994246.277 5994246.277 0 0 0-229.332-229.454 35.065 35.065 0 0 1-10.326-25.126c0-9.2 3.393-18.26 10.326-25.2C172.192 194.973 332.731 34.31 355.66 11.354Z"
        />
      </svg>
      {children}
    </button>
  )
}

export const NextButton: React.FC<PropType> = (props) => {
  const { children, ...restProps } = props

  return (
    <button className="embla__button embla__button--next shrink-0 focus:border-th-border" type="button" {...restProps}>
      <svg className="embla__button__svg" viewBox="0 0 532 532">
        <path
          fill="currentColor"
          d="M176.34 520.646c-13.793 13.805-36.208 13.805-50.001 0-13.785-13.804-13.785-36.238 0-50.034L330.78 266 126.34 61.391c-13.785-13.805-13.785-36.239 0-50.044 13.793-13.796 36.208-13.796 50.002 0 22.928 22.947 206.395 206.507 229.332 229.454a35.065 35.065 0 0 1 10.326 25.126c0 9.2-3.393 18.26-10.326 25.2-45.865 45.901-206.404 206.564-229.332 229.52Z"
        />
      </svg>
      {children}
    </button>
  )
}

export default InsightsCarousel
