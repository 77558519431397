import React, { type FunctionComponent, type ReactNode, useEffect, useRef, useState } from 'react'

import { type RootStateOrAny, useSelector } from 'react-redux'
import { ArrowContainer, type PopoverPosition, Popover as TinyPopover } from 'react-tiny-popover'

/* utils */
import { classNames } from '../../utils/classNames'

interface Props {
  children: ReactNode
  text: string | JSX.Element
  className?: string
  position?: 'top' | 'bottom' | 'left' | 'right'
  disabled?: boolean
  delay?: number
  textAlign?: 'center' | 'left' | 'right'
}

const parsePopoverText = (text: string) => {
  const regex = /\[(.*?)\]\((.*?)\)/g
  const lines = text.split('\n')

  return lines.map((line, lineIndex) => {
    const parts = line.split(regex)
    const elements = []

    for (let i = 0; i < parts.length; i += 3) {
      // Text
      if (parts[i]) elements.push(<span key={`text-${lineIndex}-${i}`}>{parts[i]}</span>)

      // Links
      if (i + 1 < parts.length && parts[i + 1] && parts[i + 2]) {
        elements.push(
          <a href={parts[i + 2]} key={`link-${lineIndex}-${i + 1}`} className="text-th-blue underline" target="_blank" rel="noreferrer">
            {parts[i + 1]}
          </a>
        )
      }
    }

    // Add br after each line except the last one
    return (
      <React.Fragment key={`line-${lineIndex}`}>
        {elements}
        {lineIndex < lines.length - 1 && <br />}
      </React.Fragment>
    )
  })
}

const Popover: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { children, text, className, delay = 300, textAlign = 'center' } = props

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const theme = useSelector((state: RootStateOrAny) => state.settings.theme) || 'default'

  const parsedText = typeof text === 'string' ? parsePopoverText(text) : text

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  const handleMouseEnter = () => {
    if (props.disabled) return
    timeoutRef.current = setTimeout(() => setIsOpen(true), delay)
  }

  const handleMouseLeave = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    setIsOpen(false)
  }

  const textAlignClass = textAlign === 'center' ? 'text-center' : textAlign === 'left' ? 'text-left' : 'text-right'

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <TinyPopover
        isOpen={isOpen}
        positions={['top', 'bottom', 'left', 'right'].filter((position) => !props.position || position === props.position) as PopoverPosition[]}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={'var(--border)'}
            arrowSize={8}
            className={classNames('popover-arrow-container', 'theme-' + theme)}
            arrowClassName="popover-arrow"
          >
            <div className={classNames('z-[9999] max-w-sm rounded-lg border border-th-border bg-th-content p-2 text-sm text-th-text shadow', textAlignClass)}>
              {parsedText}
            </div>
          </ArrowContainer>
        )}
      >
        <div className={classNames(className)}>{children}</div>
      </TinyPopover>
    </div>
  )
}

export default Popover
