import { type User } from 'src/types/user'

import { type RootStateOrAny, useSelector } from 'react-redux'

const useOwnUser = () => {
  const user: User | null = useSelector((state: RootStateOrAny) => state.user)
  return user
}

export default useOwnUser
