import { useEffect, useState } from 'react'

import { type Entitlement } from 'src/types/azure'

import { stopLoading } from 'src/actions/loadingActions'

import { useEmissionsChartData, useEmissionsPieChartData } from 'src/hooks/composite/calculation/useEmissionChartData'
import useEmissionTileData from 'src/hooks/composite/calculation/useEmissionTileData'
import useEmissionsQuery from 'src/hooks/services/sustainability/queries/useEmissionsQuery'

import Banner from 'src/components/Common/Banner'
import Heading from 'src/components/Common/Heading'
import MonthRangePicker, { type DateRange } from 'src/components/Common/MonthRangePicker'
import Dropdown from 'src/components/Common/RefactoredDropdown'
import Section from 'src/components/Common/Section'
import Spacer from 'src/components/Common/Spacer'
import SubscriptionDropdown from 'src/components/Dropdowns/SubscriptionDropdown'
import BarGraph from 'src/components/Graphs/BarGraph'
import PieGraph from 'src/components/Graphs/PieGraph'
import SectionedArea from 'src/components/Graphs/SectionedArea'
import SectionedBar from 'src/components/Graphs/SectionedBar'
import SectionedTable from 'src/components/Tables/SectionedTable'

import { FcFactory, FcIcons8Cup, FcInTransit, FcLandscape } from 'react-icons/fc'
import { useDispatch } from 'react-redux'

import SustainabilityTile from './components/EmissionsTile'

type ChartState = {
  stackBy: string
  type: string
  intensity: boolean
  cumulative: boolean
}

const MULTICHART_OPTIONS = [
  { label: 'Pie Chart', value: 'pie', checked: true },
  { label: 'Bar Chart', value: 'bar' }
]

const AzureEmissionsOverview: React.FC = () => {
  const dispatch = useDispatch()
  useEffect(() => void dispatch(stopLoading()), [dispatch])

  const cutoffDate = new Date()
  cutoffDate.setHours(0, 0, 0, 0)
  cutoffDate.setDate(1)
  cutoffDate.setMonth(cutoffDate.getMonth() - 2)

  const startOfYear = new Date(cutoffDate.getFullYear(), 0, 1)

  const [chartState, setChartState] = useState<ChartState>({ stackBy: 'tenant', type: 'bar', intensity: false, cumulative: false })
  const [multichartConfig, setMultichartConfig] = useState(MULTICHART_OPTIONS[0])

  const [dateRange, setDateRange] = useState<DateRange>({ startDate: startOfYear, endDate: cutoffDate })

  // Months needs to be the number of months between the start and end date plus the months between cutoff and end date
  const months = Math.ceil((cutoffDate.getTime() - dateRange.startDate.getTime()) / (1000 * 60 * 60 * 24 * 30)) + 3

  const [selectedSubscriptions, setSelectedSubscriptions] = useState<Entitlement[]>([])

  // Chart Data
  const { data: emissions, isLoading: isLoadingEmissions } = useEmissionsQuery({ subscriptionIds: selectedSubscriptions.map((s) => s.id), months })

  const timezoneAdjustedEmissionTotals = emissions?.map((e) => {
    const date = new Date(e.date ?? '')
    date.setHours(date.getHours() + date.getTimezoneOffset() / 60)
    return { ...e, parsedDate: date }
  })

  const dateFilteredEmissionTotals = timezoneAdjustedEmissionTotals?.filter((e) => {
    const parsedDate = new Date(e.parsedDate ?? '')
    return parsedDate >= dateRange.startDate && parsedDate <= dateRange.endDate
  })

  const chartShapedData = useEmissionsChartData(
    dateFilteredEmissionTotals,
    selectedSubscriptions,
    dateRange,
    chartState.stackBy,
    chartState.intensity,
    chartState.cumulative
  )
  const [graphFilter, setGraphFilter] = useState<Array<string>>([])

  // Tile Data
  const { emissionTotal, scope1Total, scope2Total, scope3Total } = useEmissionTileData(dateFilteredEmissionTotals, months, dateRange, chartState.intensity)

  const tenantPieChartData = useEmissionsPieChartData(dateFilteredEmissionTotals, selectedSubscriptions, 'tenant', chartState.intensity)
  const subscriptionPieChartData = useEmissionsPieChartData(dateFilteredEmissionTotals, selectedSubscriptions, 'subscriptionId', chartState.intensity)
  const meterCategoryPieChartData = useEmissionsPieChartData(dateFilteredEmissionTotals, selectedSubscriptions, 'azureServiceName', chartState.intensity)
  const scopePieChartData = useEmissionsPieChartData(dateFilteredEmissionTotals, selectedSubscriptions, 'scope', chartState.intensity)
  const regionPieChartData = useEmissionsPieChartData(dateFilteredEmissionTotals, selectedSubscriptions, 'region', chartState.intensity)

  const pieChartData = [
    { name: 'Tenant', data: tenantPieChartData },
    { name: 'Subscription', data: subscriptionPieChartData },
    { name: 'Service', data: meterCategoryPieChartData },
    { name: 'Scope', data: scopePieChartData },
    { name: 'Region', data: regionPieChartData }
  ]

  return (
    <>
      <Section>
        <Banner>
          <MonthRangePicker initialEndDate={dateRange.endDate} initialStartDate={dateRange.startDate} onChange={setDateRange} cutOffDate={cutoffDate} />
          <Spacer />
          <Dropdown
            data={[
              { label: 'Carbon Emissions', value: 'emissions', checked: true },
              { label: 'Carbon Intensity', value: 'intensity' }
            ]}
            onChange={(option) => setChartState((prev) => ({ ...prev, intensity: option.value === 'intensity' }))}
            label="Type"
            mode="simpleSelect"
          />
          <SubscriptionDropdown onChange={(_, subs) => setSelectedSubscriptions(subs)} />
        </Banner>
        <div className="mt-8 grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-4">
          <SustainabilityTile
            icon={<FcFactory className="h-full w-full" />}
            value={emissionTotal.toFixed(4)}
            label="Total"
            unit={chartState.intensity ? 'kg CO₂e/USD' : 'kg CO₂e'}
          />
          <SustainabilityTile
            icon={<FcIcons8Cup className="h-full w-full" />}
            value={scope1Total.toFixed(4)}
            label="Scope 1"
            unit={chartState.intensity ? 'kg CO₂e/USD' : 'kg CO₂e'}
          />
          <SustainabilityTile
            icon={<FcInTransit className="h-full w-full" />}
            value={scope2Total.toFixed(4)}
            label="Scope 2"
            unit={chartState.intensity ? 'kg CO₂e/USD' : 'kg CO₂e'}
          />
          <SustainabilityTile
            icon={<FcLandscape className="h-full w-full" />}
            value={scope3Total.toFixed(4)}
            label="Scope 3"
            unit={chartState.intensity ? 'kg CO₂e/USD' : 'kg CO₂e'}
          />
        </div>
      </Section>
      <Section className="!h-max">
        <Heading
          text="Monthly Emissions"
          info={`Detailed report on carbon ${chartState.intensity ? 'intensity' : 'emissions'} associated with each subscription, including ${
            chartState.intensity ? 'intensity' : 'emission'
          } trends and comparison across subscriptions. Learn more [here](https://learn.microsoft.com/en-us/industry/sustainability/api-calculation-method)`}
        >
          <Dropdown
            data={[
              { label: 'None', value: 'none', checked: true },
              { label: 'Cumulative', value: 'cumulative' }
            ]}
            onChange={(option) => setChartState((prev) => ({ ...prev, cumulative: option.value === 'cumulative' }))}
            label="Aggregate"
            mode="simpleSelect"
          />
          <Dropdown
            data={[
              { label: 'Bar Chart', value: 'bar', checked: true },
              { label: 'Area Chart', value: 'area' },
              { label: 'Table', value: 'table' }
            ]}
            onChange={(option) => setChartState((prev) => ({ ...prev, type: option.value }))}
            label="View As"
            mode="simpleSelect"
          />
          <Dropdown
            data={[
              { label: 'Tenant', value: 'tenant', checked: true },
              { label: 'Subscription', value: 'subscriptionId' },
              { label: 'Service', value: 'azureServiceName' },
              { label: 'Scope', value: 'scope' },
              { label: 'Region', value: 'region' }
            ]}
            onChange={(option) => setChartState((prev) => ({ ...prev, stackBy: option.value }))}
            label="Stack By"
            mode="simpleSelect"
          />
        </Heading>
        {selectedSubscriptions.length === 0 ? (
          <div className="relative h-80 w-full md:h-96 lg:h-[30rem]">
            <div className="pointer-events-none absolute inset-0 z-10 flex items-center justify-center">
              <p className="pb-8 pl-8 text-2xl text-th-text-secondary">Please select at least one subscription to view the data.</p>
            </div>
          </div>
        ) : (
          <>
            {chartState.type === 'bar' && (
              <SectionedBar
                data={chartShapedData}
                colorful={false}
                filtered={graphFilter}
                setFiltered={setGraphFilter}
                isLoading={isLoadingEmissions}
                hideBudget={true}
                hideForecast={true}
                removeForecast={true}
                onHideChange={() => {}}
                shouldFormatCurrency={false}
                yAxisLabel={chartState.intensity ? 'kg CO₂e/USD' : 'kg CO₂e'}
              />
            )}
            {chartState.type === 'area' && (
              <SectionedArea
                data={chartShapedData}
                colorful={true}
                filtered={graphFilter}
                setFiltered={setGraphFilter}
                isLoading={isLoadingEmissions}
                hideBudget={true}
                hideForecast={true}
                removeForecast={true}
                onHideChange={() => {}}
                shouldFormatCurrency={false}
                interval={'preserveStartEnd'}
                yAxisLabel={chartState.intensity ? 'kg CO₂e/USD' : 'kg CO₂e'}
              />
            )}
            {chartState.type === 'table' && (
              <SectionedTable
                data={chartShapedData}
                colorful={false}
                cumulative={chartState.cumulative}
                filtered={graphFilter}
                setFiltered={setGraphFilter}
                shouldFormatCurrency={false}
              />
            )}
          </>
        )}
      </Section>
      <Section>
        <Heading
          text={`Total ${chartState.intensity ? 'Carbon Intensity' : 'Emissions'} Breakdown`}
          info={`Breakdown of ${chartState.intensity ? 'carbon intensity' : 'emissions'} by different categories`}
        >
          <Spacer className="hidden sm:block" />
          <Dropdown data={MULTICHART_OPTIONS} label="View As" onChange={(option) => setMultichartConfig(option)} mode="simpleSelect" />
        </Heading>
        <div className="my-8 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5">
          {pieChartData.map((data, idx) => (
            <>
              {multichartConfig.value === 'bar' ? (
                <BarGraph key={idx + '_bar'} title={data.name} data={data.data} colorful={false} shouldFormatCurrency={false} />
              ) : (
                <PieGraph key={idx + '_pie'} title={data.name} data={data.data} colorful={false} shouldFormatCurrency={false} />
              )}
            </>
          ))}
        </div>
      </Section>
    </>
  )
}

export default AzureEmissionsOverview
