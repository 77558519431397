import { withAITracking } from '@microsoft/applicationinsights-react-js'
import { useQuery } from '@tanstack/react-query'

import { Fragment, useEffect, useMemo, useState } from 'react'

import { type Ticket } from '../../types/ticket'

import { showAlert } from 'src/actions/alertsActions'
import { openTicketDrawer } from 'src/actions/drawerActions'

import useDatePicker from 'src/hooks/composite/useDateRangePicker'
import useAccount from 'src/hooks/utils/useAccount'

import Modal from 'src/components/Common/Modal'
import ProgressIndicator from 'src/components/ProgressIndictor'

import { classNames } from 'src/utils/classNames'

import moment, { type Moment } from 'moment'
import qs from 'qs'
import { Iconly } from 'react-iconly'
import { FcAlarmClock, FcDocument, FcOk } from 'react-icons/fc'
import { IoCloseCircleOutline, IoTicket } from 'react-icons/io5'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useDebouncedCallback } from 'use-debounce'

import Banner from '../../components/Common/Banner'
import Button from '../../components/Common/Button'
import Count from '../../components/Common/Count'
import DateRange from '../../components/Common/DateRange/DateRange'
import Dropdown from '../../components/Common/Dropdown'
import Input from '../../components/Common/Input'
import Section from '../../components/Common/Section'
import Spacer from '../../components/Common/Spacer'
import TicketTable from '../../components/Tables/TicketTable'
import { reactPlugin } from '../../configs/appInsights'
import api from '../../constants/api'

const lastDayOfThisMonth = moment().endOf('month')
const startOfAllTime = moment('1970-01-01').startOf('day')

/* dropdown options */
const periodOptions = [
  {
    label: 'All Time',
    value: 'all'
  },
  {
    label: 'Current Month',
    value: 'current'
  },
  {
    label: 'Previous Month',
    value: 'previous'
  },
  {
    label: 'Custom',
    value: 'custom'
  }
]

type TicketFilterOptions = {
  startDate: Moment
  endDate: Moment
  keyword: string | null
  priority: string[] | null
  category: string[] | null
  state: string | null
  orderBy: string | null
  orderByDesc: boolean
  take: number
  skip: number
}

type TicketStats = {
  open: number
  awaiting_response: number
  awaiting: number
  closed: number
}

const PRIORITY_OPTIONS = ['Critical', 'High', 'Medium', 'Low']
const CATEGORY_OPTIONS = ['Request', 'Complaint', 'Incident', 'Event', 'Proactive']

const DEFAULT_FILTER_OPTIONS: TicketFilterOptions = {
  startDate: startOfAllTime,
  endDate: lastDayOfThisMonth,
  keyword: null,
  priority: PRIORITY_OPTIONS,
  category: CATEGORY_OPTIONS,
  state: 'Open',
  orderBy: null,
  orderByDesc: false,
  take: 10,
  skip: 0
}

const Tickets = () => {
  const { id } = useParams<{ id: string }>()

  const account = useAccount()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!id || !account.id || !dispatch) return

    const fetchTicket = async () => {
      try {
        const ticket = (await api.get(`/servicenow/account/${account.id}/ticket/${id}`)).data as Ticket
        dispatch(openTicketDrawer(ticket))
      } catch (error) {
        dispatch(
          showAlert({
            type: 'error',
            message: 'Unable to find ticket with the provided ID',
            component: 'Ticket',
            error: error as Error
          })
        )
      }
    }

    fetchTicket()
  }, [id, account, dispatch])

  /* reformat the url */
  const location = useLocation()
  const navigate = useNavigate()
  if (location.search) {
    const id = qs.parse(location.search, { ignoreQueryPrefix: true }).ticket
    if (id) {
      navigate('/tickets/' + id)

      /* const ticket = await api.get(`/servicenow/account/${account.id}/ticket/${id}`)
      dispatch(openTicketDrawer()) */
    }
  }

  const [filterOptions, setFilterOptions] = useState<TicketFilterOptions>(DEFAULT_FILTER_OPTIONS)

  const [period, setPeriod] = useState<string>(periodOptions[0].value)
  const [showSearch, setShowSearch] = useState<boolean>(false)

  /* set the start and end dates */
  const handleDatesChange = (startDate: Moment, endDate: Moment) => {
    setFilterOptions((prev) => ({
      ...prev,
      startDate,
      endDate
    }))
  }

  /* set the date range based on the period that has been selected */
  const handlePeriodChange = (period: string) => {
    setPeriod(period)

    const firstDayOfThisMonth = moment().startOf('month')
    const lastDayOfThisMonth = moment().endOf('month')

    if (period === 'all') return handleDatesChange(startOfAllTime, lastDayOfThisMonth)
    if (period === 'current') return handleDatesChange(firstDayOfThisMonth, lastDayOfThisMonth)
    if (period === 'previous') return handleDatesChange(firstDayOfThisMonth.subtract(1, 'month'), lastDayOfThisMonth.subtract(1, 'month'))
  }

  const strippedFilterOptions = useMemo(() => {
    const { startDate, endDate } = filterOptions
    return { startDate, endDate }
  }, [filterOptions])

  const debounce = useDebouncedCallback((s) => {
    setFilterOptions((prev) => ({ ...prev, keyword: s, state: null }))
  }, 500)

  const { data: ticketStats } = useQuery({
    queryKey: ['ticketStats', account.id, strippedFilterOptions],
    queryFn: async ({ signal }) => {
      try {
        const response = await api.post(
          `/servicenow/account/${account.id}/tickets/stats`,
          {
            start_date: strippedFilterOptions.startDate.format('YYYY-MM-DD'),
            end_date: strippedFilterOptions.endDate.format('YYYY-MM-DD')
          },
          {
            signal
          }
        )
        return response.data as TicketStats
      } catch (error) {
        console.error(error)
      }

      return null
    },
    enabled: !!account.id
  })

  const { DateRangePicker, PeriodDropdown, dateRange } = useDatePicker()
  const [csvModalOpen, setCsvModalOpen] = useState<boolean>(false)
  const [downloadPercentage, setDownloadPercentage] = useState<number | null>(null)
  const [downloadError, setDownloadError] = useState<string | null>(null)
  const handleDownloadCSV = async () => {
    setDownloadError(null)
    setDownloadPercentage(0)
    const mapToQueryParams = (filterOptions: TicketFilterOptions) => ({
      start_date: dateRange[0].format('YYYY-MM-DD'),
      end_date: dateRange[1].format('YYYY-MM-DD'),
      keyword: filterOptions.keyword || null,
      order_by: filterOptions.orderBy || null,
      order_by_desc: filterOptions.orderByDesc,
      take: 0,
      skip: 0
    })

    const interval = setInterval(() => setDownloadPercentage((prev) => ((prev ?? 0) >= 80 ? (prev ?? 0) + (100 - (prev ?? 0)) * 0.05 : (prev ?? 0) + 2)), 150)
    const response = await api.post(`/servicenow/account/${account.id}/tickets`, mapToQueryParams(filterOptions))

    setDownloadPercentage(null)
    clearInterval(interval)

    const data = response.data as Ticket[]
    if (!data || !data.length) return setDownloadError('No data found for date range')

    const csvData = data
      .map((f) => {
        return {
          ...f,
          description: f.description.replaceAll('"', "'"),
          title: f.title.replaceAll('"', "'")
        }
      })
      .map(({ id: _, notification: _n, contact_type: _c, originator: _o, account: _a, comment: _m, assigned: _s, ...fieldsToKeep }) => fieldsToKeep)

    const headers = Object.keys(csvData[0])
    const csv = [
      headers.join(','),
      ...csvData.map((row) => {
        return headers
          .map((fieldName) => {
            const field = row[fieldName as keyof typeof row]
            if (typeof field === 'string') return `"${field}"`
            if (typeof field === 'number') return field
            if (typeof field === 'boolean') return field ? 'true' : 'false'
            if (typeof field === 'object') return JSON.stringify(field)
            return ''
          })
          .join(',')
      })
    ].join('\n')

    const blob = new Blob([csv], { type: 'text/csv' })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = 'tickets.csv'
    link.click()

    setCsvModalOpen(false)
  }

  return (
    <Fragment>
      <Section>
        <Banner>
          {/* date range */}
          <Dropdown
            options={periodOptions}
            defaultValue={periodOptions[0]}
            variant={Dropdown.variant.DEFAULT}
            label="Created"
            bordered
            multi={false}
            onChange={(option) => handlePeriodChange(option.value)}
          />
          <DateRange
            start={filterOptions.startDate}
            end={filterOptions.endDate}
            startDateId="azure_overview_start_date"
            endDateId="azure_overview_end_date"
            variant={DateRange.variant.DEFAULT}
            onChange={handleDatesChange}
            disabled={period !== 'custom'}
            focus={period === 'custom'}
            bordered
          />
        </Banner>
        <div className="my-5 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-1 mdlg:grid-cols-2 xl:grid-cols-4">
          <Count
            icon={<IoTicket className="h-full w-full text-th-warning" />}
            value={ticketStats?.open || 'Loading...'}
            label="Open"
            className="cursor-pointer"
            onClick={() => setFilterOptions((prev) => ({ ...prev, state: filterOptions.state?.includes('Open') ? null : 'Open' }))}
            active={filterOptions.state?.includes('Open')}
          />
          <Count
            icon={<FcDocument className="h-full w-full" />}
            value={ticketStats?.awaiting_response || 'Loading...'}
            label="Awaiting Response"
            className="cursor-pointer"
            onClick={() => setFilterOptions((prev) => ({ ...prev, state: filterOptions.state?.includes('Awaiting Response') ? null : 'Awaiting Response' }))}
            active={filterOptions.state?.includes('Awaiting Response')}
          />
          <Count
            icon={<FcAlarmClock className="h-full w-full" />}
            value={ticketStats?.awaiting || 'Loading...'}
            label="Awaiting Action"
            className="cursor-pointer"
            onClick={() => setFilterOptions((prev) => ({ ...prev, state: filterOptions.state?.includes('Awaiting Action') ? null : 'Awaiting Action' }))}
            active={filterOptions.state?.includes('Awaiting Action')}
          />
          <Count
            icon={<FcOk className="h-full w-full" />}
            value={ticketStats?.closed || 'Loading...'}
            label="Closed"
            className="cursor-pointer"
            onClick={() => setFilterOptions((prev) => ({ ...prev, state: filterOptions.state?.includes('Closed') ? null : 'Closed' }))}
            active={filterOptions.state?.includes('Closed')}
          />
        </div>
      </Section>
      <Section>
        <Banner>
          {/* filters */}
          <Dropdown
            options={CATEGORY_OPTIONS.map((c) => ({ label: c, value: c }))}
            value={
              filterOptions.category?.length
                ? filterOptions.category.map((c) => ({ label: c, value: c }))
                : CATEGORY_OPTIONS.map((c) => ({ label: c, value: c }))
            }
            variant={Dropdown.variant.DEFAULT}
            label="Category"
            onChange={(option) =>
              setFilterOptions((prev) => (option.length ? { ...prev, category: option.map((o: { value: string }) => o.value) } : { ...prev, category: null }))
            }
            multi
            disabled={!!filterOptions.keyword}
            disableAll
            disabledOptions={filterOptions.category?.length === 1 ? filterOptions.category : []}
          />
          <Dropdown
            options={PRIORITY_OPTIONS.map((p) => ({ label: p, value: p }))}
            value={
              filterOptions.priority?.length
                ? filterOptions.priority.map((p) => ({ label: p, value: p }))
                : PRIORITY_OPTIONS.map((p) => ({ label: p, value: p }))
            }
            variant={Dropdown.variant.DEFAULT}
            label="Priority"
            onChange={(option) =>
              setFilterOptions((prev) => (option.length ? { ...prev, priority: option.map((o: { value: string }) => o.value) } : { ...prev, priority: null }))
            }
            multi
            disabled={!!filterOptions.keyword}
            disableAll
            disabledOptions={filterOptions.priority?.length === 1 ? filterOptions.priority : []}
          />
          <Spacer className="hidden sm:block" />
          {/* search */}
          {showSearch ? (
            <Fragment>
              <Input type="text" name="search" placeholder="Search" autoFocus onChange={(e) => debounce((e.target as HTMLInputElement).value)} />
              <IoCloseCircleOutline
                className="h-6 w-6 cursor-pointer"
                onClick={() => {
                  setShowSearch(false)
                  setFilterOptions((prev) => ({ ...prev, keyword: null, state: 'Open' }))
                }}
              />
            </Fragment>
          ) : (
            <Iconly
              name="Search"
              set="light"
              className="cursor-pointer"
              onClick={() => {
                setShowSearch(true)
                setFilterOptions((prev) => ({ ...prev, keyword: null }))
              }}
            />
          )}
          {/* csv download */}
          <Button onClick={async () => setCsvModalOpen(true)} disabled={downloadPercentage !== null}>
            {downloadPercentage ? 'Downloading...' : `Download CSV`}
          </Button>
        </Banner>
        <Spacer />
        <ProgressIndicator percent={downloadPercentage} />
        <TicketTable filterOptions={filterOptions} onFilterChange={setFilterOptions} />
      </Section>
      <Modal
        open={csvModalOpen}
        title="Download Configuration"
        loading={false}
        className="min-w-max overflow-visible focus:outline-none"
        onClose={() => {
          setDownloadError(null)
          setCsvModalOpen(false)
        }}
      >
        <div className="flex gap-4">
          {PeriodDropdown}
          {DateRangePicker}
        </div>

        <div className={classNames('my-4 flex h-10 w-full items-center justify-center rounded-full', downloadError && 'font-bold text-th-red')}>
          {downloadError ? `Error downloading... (${downloadError})` : 'For large datasets, this may take a while. Please be patient...'}
        </div>

        <div className="flex justify-end">
          <Button onClick={handleDownloadCSV} disabled={downloadPercentage !== null} bordered>
            {downloadPercentage ? 'Downloading...' : `Download CSV`}
          </Button>
        </div>
      </Modal>
    </Fragment>
  )
}

const TicketsWithTracking = withAITracking(reactPlugin, Tickets, 'Tickets')
export default TicketsWithTracking
