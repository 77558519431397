import { type FunctionComponent } from 'react'

/* utils */
import { classNames } from '../../utils/classNames'

interface Props {
  level: number
}

const Priority: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { level } = props

  let label: string
  let text: string
  let background: string
  let backgroundFaded: string

  switch (level) {
    case 1:
      label = 'Critical'
      text = 'text-th-error'
      background = 'bg-th-error'
      backgroundFaded = 'bg-th-error-light'
      break
    case 2:
      label = 'High'
      text = 'text-th-warning'
      background = 'bg-th-warning'
      backgroundFaded = 'bg-th-warning-light'
      break
    case 3:
      label = 'Medium'
      text = 'text-th-info'
      background = 'bg-th-info'
      backgroundFaded = 'bg-th-info-light'
      break
    case 4:
    default:
      label = 'Low'
      text = 'text-th-success'
      background = 'bg-th-success'
      backgroundFaded = 'bg-th-success-light'
      break
  }

  return (
    <div className={classNames('flex w-min items-center rounded-full p-1 text-sm font-bold', backgroundFaded)}>
      {/* priority number */}
      <div className={classNames('h-5 w-5 rounded-full text-center text-th-white', background)}>{level}</div>
      {/* priority label */}
      <span className={classNames('px-2', text)}>{label}</span>
    </div>
  )
}

export default Priority
