import { useContext } from 'react'

import { ChangeFiltersContext } from './context'

export function useChangeFiltersContext<T extends boolean = true>(throwError: T = true as T) {
  const context = useContext(ChangeFiltersContext)
  if (context === undefined && throwError) {
    throw new Error('useChangeFiltersContext must be used within a ChangeFiltersProvider')
  }
  return context as T extends true ? NonNullable<typeof context> : typeof context
}
