import { type FunctionComponent, useEffect, useState } from 'react'

import { type Account } from '../../types/account'

import useAccountsQuery from 'src/hooks/services/servicenow/queries/useAccountsQuery'

import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import Scrollbar from 'react-smooth-scrollbar'

import { setAccount } from '../../actions/accountActions'
import { closeModals } from '../../actions/modalActions'
import Input from '../Common/Input'
import Modal from '../Common/Modal'

const AccountsModal: FunctionComponent = (): JSX.Element => {
  const dispatch = useDispatch()

  const open = useSelector((state: RootStateOrAny) => state.modals.accounts)

  const { data: accounts, isLoading: isLoadingAccounts } = useAccountsQuery()

  const [value, setValue] = useState('')
  const filtered = accounts?.filter((account: Account) => account.name.toLowerCase().indexOf(value.toLowerCase()) !== -1)

  useEffect(() => {
    setValue('')
  }, [open])

  return (
    <Modal title="Accounts" open={open} loading={isLoadingAccounts}>
      <Input
        name="account_search"
        type="text"
        className="mb-4 w-full rounded-lg border border-th-border p-2 font-body text-sm"
        placeholder="Search"
        autoFocus
        onChange={(e) => setValue(e.target.value)}
      />
      <div className="flex max-h-96 font-body">
        <Scrollbar className="max-h-96 w-full">
          {filtered?.map((account: Account) => {
            return (
              <li
                key={account.id}
                className="w-full cursor-pointer list-none border-b border-th-content-secondary p-2 text-sm last:border-b-0 hover:bg-th-content-secondary"
                onClick={() => {
                  dispatch(setAccount(account))
                  dispatch(closeModals())
                }}
              >
                {account.name}
              </li>
            )
          })}
        </Scrollbar>
      </div>
    </Modal>
  )
}

export default AccountsModal
