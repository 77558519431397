import { useMemo } from 'react'

import { type Entitlement } from 'src/types/azure'

import moment from 'moment'

import useConsumptionTotal from './useConsumptionTotal'
import useReservedInstancesTotal from './useReservedInstancesTotal'
import useSoftwareSubscriptionsTotal from './useSoftwareSubscriptionsTotal'

interface UseProjectedSpendConfig {
  dateRange: [moment.Moment, moment.Moment]
  includeRecurringSpend?: boolean
  selectedSubscriptions?: Entitlement[]
  lastContentfulDate?: moment.Moment
}

const yearToLastMonthEnd = [moment().startOf('year'), moment().subtract(1, 'month').endOf('month')] as [moment.Moment, moment.Moment]

const useProjectedSpend = ({ dateRange, includeRecurringSpend, selectedSubscriptions, lastContentfulDate }: UseProjectedSpendConfig) => {
  const reservedInstancesTotal = useReservedInstancesTotal({ dateRange })
  const softwareSubscriptionsTotal = useSoftwareSubscriptionsTotal({ dateRange })
  const consumptionTotal = useConsumptionTotal({ dateRange, subscriptions: selectedSubscriptions })
  const consumptionTotalToEndOfLastMonth = useConsumptionTotal({ dateRange: yearToLastMonthEnd, subscriptions: selectedSubscriptions })

  const projectedTotal = useMemo(() => {
    if (consumptionTotal === undefined || !consumptionTotalToEndOfLastMonth) return null

    const lastContentfulDateDiff = lastContentfulDate && lastContentfulDate.isValid() ? Math.abs(lastContentfulDate?.diff(moment(), 'days')) - 1 : 0

    // The amount of days we still have left of the year * the average daily spend, from consumption, ri and ss
    const daysPast = Math.abs(dateRange[0].diff(moment(), 'days')) - lastContentfulDateDiff
    const daysLeft = Math.abs(dateRange[1].diff(moment(), 'days')) + lastContentfulDateDiff + 1

    const untilEndLastMonthDiff = Math.abs(yearToLastMonthEnd[1].diff(yearToLastMonthEnd[0], 'days')) + 1
    const untilEndLastMonthAverageDiff = consumptionTotalToEndOfLastMonth - untilEndLastMonthDiff * (consumptionTotal / daysPast)

    if (Math.floor(consumptionTotal) === 0 || daysPast === 0) return 0

    const stretchedTotal =
      (consumptionTotal / daysPast) * daysLeft + Math.floor(consumptionTotal) + (isNaN(untilEndLastMonthAverageDiff) ? 0 : untilEndLastMonthAverageDiff)
    const projectedTotal = stretchedTotal + (includeRecurringSpend ? reservedInstancesTotal + softwareSubscriptionsTotal : 0)

    return Math.floor(projectedTotal)
  }, [
    consumptionTotal,
    consumptionTotalToEndOfLastMonth,
    dateRange,
    includeRecurringSpend,
    lastContentfulDate,
    reservedInstancesTotal,
    softwareSubscriptionsTotal
  ])

  return projectedTotal
}

export default useProjectedSpend
