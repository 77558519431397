import { Button } from '~/components/Common/Button'
import Spinner from '~/components/Common/Spinner'
import Textarea from '~/components/Common/TextArea'

import { Iconly } from 'react-iconly'

import { useCommentForm } from './useCommentForm'

const CommentForm = () => {
  const { comment, setComment, handleSubmit, isSubmitting } = useCommentForm()

  return (
    <div className="space-y-4">
      <div>
        <div className="mb-1 font-bold">Add Comment</div>
        <Textarea value={comment} onChange={(e) => setComment(e.target.value)} placeholder="Add a comment..." className="h-24" />
      </div>

      <div className="flex justify-end">
        <Button onClick={handleSubmit} disabled={isSubmitting || !comment.trim()} variant="default" bordered className="gap-1">
          {isSubmitting ? (
            <>
              <Spinner className="mr-2 h-4 w-4 animate-spin" />
              Sending...
            </>
          ) : (
            <>
              <Iconly set="light" name="Send" className="h-5" />
              <span>Send</span>
            </>
          )}
        </Button>
      </div>
    </div>
  )
}

export default CommentForm
