import { createContext } from 'react'

import type { ChangeFilterOptions } from '~/hooks/services/servicenow/queries/useChangesQuery'

export interface ChangeFiltersContextType {
  filterOptions: ChangeFilterOptions
  setFilterOptions: (fn: (prev: ChangeFilterOptions) => ChangeFilterOptions) => void
}

export const ChangeFiltersContext = createContext<ChangeFiltersContextType | undefined>(undefined)
