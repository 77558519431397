import { withAITracking } from '@microsoft/applicationinsights-react-js'

import { Fragment, type FunctionComponent, useEffect } from 'react'

import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'

import { openNewAzurePlanDrawer } from '../../actions/drawerActions'
import { stopLoading } from '../../actions/loadingActions'
import { openNoAzureModal } from '../../actions/modalActions'
import Button from '../../components/Common/Button'
import Section from '../../components/Common/Section'
import Spacer from '../../components/Common/Spacer'
import NoAzureModal from '../../components/Modals/NoAzureModal'
import { reactPlugin } from '../../configs/appInsights'
import azureviews from '../../images/dummies/azureviews.png'

const NoAzureSplashScreen: FunctionComponent = (): JSX.Element => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const open = useSelector((state: RootStateOrAny) => state.modals.noazuremodal)

  const handleClick = () => {
    navigate('/tickets/')
    dispatch(openNewAzurePlanDrawer())
  }

  useEffect(() => {
    dispatch(stopLoading())
    if (!open) {
      dispatch(openNoAzureModal())
    }
  }, [dispatch, open, location.pathname])

  return (
    <Fragment>
      <Section>
        <img alt="What you could have" src={azureviews} />

        <NoAzureModal title="Ooops..." open={open} loading={false}>
          You don't seem to have any Azure services with us, are you interested?
          <Spacer />
          <div className="flex justify-center">
            <Button className="" variant="primary" onClick={() => handleClick()}>
              Click here!
            </Button>
          </div>
        </NoAzureModal>
      </Section>
    </Fragment>
  )
}

const NoAzureSplashScreenWithTracking = withAITracking(reactPlugin, NoAzureSplashScreen, 'NoAzureSplashScreen')
export default NoAzureSplashScreenWithTracking
