import type { ChangeFilterOptions } from '~/hooks/services/servicenow/queries/useChangesQuery'

import moment from 'moment'

export const CLASSIFICATION_OPTIONS = ['Standard', 'Small', 'Normal', 'Major', 'Emergency']
export const CATEGORY_OPTIONS = [
  'Customer',
  'Contract',
  'Marketing',
  'Process',
  'Retrospective',
  'Sales',
  'Security',
  'Service',
  'Supplier',
  'Technical',
  'Other'
]
export const STATE_OPTIONS = ['Planned', 'Pending Approval', 'Rejected', 'Closed']

export const PERIOD_OPTIONS = [
  { label: 'All Time', value: 'all' },
  { label: 'Current Month', value: 'current' },
  { label: 'Previous Month', value: 'previous' },
  { label: 'Custom', value: 'custom' }
]

export const DEFAULT_FILTER_OPTIONS: ChangeFilterOptions = {
  period: 'all',
  startDate: moment('1970-01-01').startOf('day'),
  endDate: moment().endOf('month'),
  keyword: null,
  classification: CLASSIFICATION_OPTIONS,
  category: CATEGORY_OPTIONS,
  state: ['Planned'],
  orderBy: null,
  orderByDesc: false,
  take: 10,
  skip: 0
}

export const USERS_AFFECTED_OPTIONS = ['All', 'None', 'Specific']
