import { withAITracking } from '@microsoft/applicationinsights-react-js'

import { useEffect } from 'react'

import { stopLoading } from 'src/actions/loadingActions'

import useDatePicker from 'src/hooks/composite/useDateRangePicker'

import Heading from 'src/components/Common/Heading'
import Section from 'src/components/Common/Section'
import Spacer from 'src/components/Common/Spacer'
import Graph from 'src/components/Graphs/DecompositionGraph/Graph'

import { reactPlugin } from 'src/configs/appInsights'

import { useDispatch } from 'react-redux'

// -~= Component =~- //
const AzureResourceExplorer = () => {
  // --~= State definitions =~-- /
  const dispatch = useDispatch()
  useEffect(() => void dispatch(stopLoading()), [dispatch])

  // -~= Helper Hooks =~- //
  const { DateRangePicker, PeriodDropdown, dateRange } = useDatePicker()

  return (
    <>
      <Section className="!py-0">
        <Heading text="Resource Explorer">
          <Spacer className="my-8" />
          {PeriodDropdown}
          {DateRangePicker}
        </Heading>

        <Graph props={{ className: 'w-full', height: 480 }} dateRange={dateRange} />
      </Section>
    </>
  )
}

const AzureResourceExplorerWithTracking = withAITracking(reactPlugin, AzureResourceExplorer, 'Azure Resource Explorer')
export default AzureResourceExplorerWithTracking
