import { useEffect, useRef, useState } from 'react'

import moment, { type Moment } from 'moment'
import { DateRangePicker, type FocusedInputShape } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

/* utils */
import { classNames } from '../../../utils/classNames'

/* css */
import './DateRange.css'

/* variants */
enum Variant {
  PRIMARY,
  SECONDARY,
  DEFAULT
}

const VARIANT_MAPS: Record<Variant, string> = {
  [Variant.PRIMARY]: 'bg-th-primary text-th-white',
  [Variant.SECONDARY]: 'bg-th-content-secondary',
  [Variant.DEFAULT]: 'bg-th-content'
}

const formatDate = (date: Moment | null) => (date ? date.format('DD/MM/YYYY') : '')

interface Props {
  start: Moment
  end: Moment
  startDateId: string
  endDateId: string
  variant: Variant
  bordered?: true
  disabled?: boolean
  hidden?: boolean
  focus?: boolean
  onChange: (startDate: Moment, endDate: Moment) => unknown
  minDate?: Moment
}

const DateRange = (props: Props): JSX.Element => {
  const { start, end, startDateId, endDateId, variant, bordered, disabled, hidden, focus } = props

  const [startDate, setStartDate] = useState<Moment | null>(start)
  const [endDate, setEndDate] = useState<Moment | null>(end)
  const [startDateString, setStartDateString] = useState(formatDate(start))
  const [endDateString, setEndDateString] = useState(formatDate(end))

  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null)

  // open the picker if the focus prop is set
  useEffect(() => {
    if (focus) setFocusedInput('startDate')
  }, [focus])

  /* set the start and ends dates on selection */
  const handleChange = (startDate: Moment | null, endDate: Moment | null) => {
    setStartDate(startDate)
    setEndDate(endDate)
    setStartDateString(formatDate(startDate))
    setEndDateString(formatDate(endDate))
  }

  const startDateInput = useRef<HTMLInputElement>(null)
  const endDateInput = useRef<HTMLInputElement>(null)

  useEffect(() => {
    handleChange(start, end)
  }, [start, end])

  const handleStringChange = (type: 'start' | 'end', value: string) => {
    type === 'start' ? setStartDateString(value) : setEndDateString(value)

    const parsedDate = moment(value, 'DD/MM/YYYY', true).startOf('day')
    if (!parsedDate.isValid()) return

    if (type === 'start' && parsedDate.isAfter(moment('1 Jan 1970')) && parsedDate.isBefore(endDate)) {
      setStartDate(parsedDate)
      setTimeout(() => startDateInput.current?.focus(), 0)
    } else if (type === 'end' && parsedDate.isAfter(startDate) && parsedDate.isBefore(moment().endOf('month'))) {
      setEndDate(parsedDate)
      setTimeout(() => endDateInput.current?.focus(), 0)
    }
  }

  const handleDatePickerClose = ({ startDate, endDate }: { startDate: Moment | null; endDate: Moment | null }) => {
    if (startDate && endDate) props.onChange(startDate.startOf('day'), endDate.endOf('day'))
    if (startDate) setStartDateString(formatDate(startDate))
    if (endDate) setEndDateString(formatDate(endDate))
  }

  return (
    <div
      className={classNames(
        'flex h-full flex-col gap-1 rounded-full px-2.5 py-1.5',
        'items-center',
        VARIANT_MAPS[variant],
        bordered && 'border border-th-border',
        disabled ? 'cursor-default opacity-50' : 'z-20',
        hidden && 'hidden'
      )}
    >
      <DateRangePicker
        startDate={startDate}
        startDateId={startDateId}
        endDate={endDate}
        endDateId={endDateId}
        minDate={props.minDate ?? moment('1 Jan 1970')}
        // maxDate={moment().endOf('month')}
        isOutsideRange={() => false}
        onDatesChange={({ startDate, endDate }) => {
          handleChange(startDate, endDate)
        }}
        onClose={handleDatePickerClose}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
        displayFormat="DD/MM/YYYY"
        numberOfMonths={1}
        firstDayOfWeek={1}
        disabled={disabled}
        hideKeyboardShortcutsPanel
        noBorder
        small
        keepFocusOnInput={false}
        readOnly
        calendarInfoPosition="top"
        renderCalendarInfo={() => {
          return (
            <div className="grid w-full grid-cols-2 gap-1 px-5 pt-3">
              <label id="startDate">
                <span className="ml-1 text-sm font-semibold">Start date</span>
                <input
                  type="text"
                  className="w-full rounded-lg border border-th-border bg-th-content px-2 py-1 font-body text-sm text-th-text"
                  value={startDateString}
                  onChange={(e) => handleStringChange('start', e.target.value)}
                  ref={startDateInput}
                  onKeyPress={(e) => {
                    if (e.key !== 'Enter') return
                    handleDatePickerClose({ startDate, endDate })
                    setFocusedInput(null)
                  }}
                />
              </label>
              <label id="endDate">
                <span className="ml-1 text-sm font-semibold">End date</span>
                <input
                  type="text"
                  className="w-full rounded-lg border border-th-border bg-th-content px-2 py-1 font-body text-sm text-th-text"
                  value={endDateString}
                  onChange={(e) => handleStringChange('end', e.target.value)}
                  ref={endDateInput}
                  onKeyPress={(e) => {
                    if (e.key !== 'Enter') return
                    handleDatePickerClose({ startDate, endDate })
                    setFocusedInput(null)
                  }}
                />
              </label>
            </div>
          )
        }}
      />
    </div>
  )
}

DateRange.defaultProps = {
  variant: Variant.DEFAULT
}

DateRange.variant = Variant

export default DateRange
