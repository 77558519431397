import * as React from 'react'

import { classNames } from 'src/utils/classNames'

import { type VariantProps, cva } from 'class-variance-authority'

import Spinner from './Spinner'

const buttonVariants = cva(
  'flex items-center font-headline rounded-full py-2 px-4 focus:outline-none justify-center text-sm tracking-wide h-max disabled:opacity-50 disabled:cursor-default',
  {
    variants: {
      variant: {
        primary: 'bg-th-primary text-th-white',
        secondary: 'bg-th-content-secondary',
        default: 'bg-th-content text-th-text',
        error: 'bg-th-error text-th-white'
      },
      bordered: {
        true: 'border border-th-border',
        false: ''
      }
    },
    defaultVariants: {
      variant: 'default',
      bordered: false
    }
  }
)

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  'aria-label'?: string
  isLoading?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, bordered, children, 'aria-label': ariaLabel, isLoading, ...props }, ref) => {
    const disabled = props.disabled || isLoading
    return (
      <button
        {...props}
        className={classNames(buttonVariants({ variant, bordered, className }))}
        ref={ref}
        aria-label={ariaLabel}
        aria-disabled={disabled}
        disabled={disabled}
      >
        {isLoading && <Spinner className={classNames('mr-2 h-4 w-4', variant == 'primary' && '!border-l-th-content')} />}
        {children}
      </button>
    )
  }
)

Button.displayName = 'Button'

// eslint-disable-next-line react-refresh/only-export-components
export { Button, buttonVariants }
export default Button
