import { withAITracking } from '@microsoft/applicationinsights-react-js'

import Section from '~/components/Common/Section'
import Spacer from '~/components/Common/Spacer'
import ChangeApprovalDrawer from '~/components/Drawers/ChangeApproval/ChangeApprovalDrawer'
import ChangeCustomerApprovalTable from '~/components/Tables/ChangeCustomerApprovalTable'

import { reactPlugin } from '~/configs/appInsights'

import { ChangeApprovalFilters } from './components/ChangeApprovalFilters'
import { ChangeApprovalStats } from './components/ChangeApprovalStats'
import { ChangeApprovalFiltersProvider } from './context/ChangeApprovalFilter/provider'

const ChangeApprovals = () => {
  return (
    <ChangeApprovalFiltersProvider>
      <Section>
        <ChangeApprovalStats />
      </Section>

      <Section>
        <ChangeApprovalFilters />

        <Spacer className="!block" />

        <ChangeCustomerApprovalTable />
      </Section>

      <ChangeApprovalDrawer />
    </ChangeApprovalFiltersProvider>
  )
}

const ChangeApprovalsWithTracking = withAITracking(reactPlugin, ChangeApprovals, 'ChangeApprovals')
export default ChangeApprovalsWithTracking
