import { type QueryKey, type UseQueryOptions as UseBaseQueryOptions, useQuery as useBaseQuery } from '@tanstack/react-query'

import { showAlert } from 'src/actions/alertsActions'

import axios from 'axios'
import { useDispatch } from 'react-redux'

type CustomQueryOptions<TData, TError> = Omit<UseBaseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'> & {
  errorMessage?: string
}

const useQuery = <TData = unknown, TError = unknown>(
  queryKey: QueryKey,
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<TData>,
  options?: CustomQueryOptions<TData, TError>
) => {
  const dispatch = useDispatch()

  return useBaseQuery<TData, TError>({
    queryKey,
    queryFn: async ({ signal }) => {
      try {
        const response = await queryFn({ signal })
        return response
      } catch (error) {
        if (!axios.isCancel(error)) {
          dispatch(
            showAlert({
              type: 'error',
              message: options?.errorMessage || 'An error occurred while processing your request.',
              component: queryKey[0] ? JSON.stringify(queryKey[0]) : 'Unknown',
              error: error as Error
            })
          )

          if (import.meta.env.MODE !== 'production') console.error('Query error:', error)
        }

        throw error
      }
    },
    ...options
  })
}

export default useQuery
