import useAccountRoles from './useAccountRoles'
import useOwnUser from './useOwnUser'

const roleOptions = {
  GLOBAL_ADMIN: 'Global.Admin',
  SUPPORT_USER: 'Support.User',
  SUPPORT_ADMIN: 'Support.Admin',
  FINANCE_USER: 'Finance.User',
  AZURE_USER: 'Azure.User',
  AZURE_ADMIN: 'Azure.Admin',
  M365_USER: 'M365.User',
  M365_ADMIN: 'M365.Admin',
  DOCUMENT_ADMIN: 'Document.Admin',
  DOCUMENT_USER: 'Document.User'
} as const

type RoleOption = (typeof roleOptions)[keyof typeof roleOptions]

const useHasAccountRoles = (requiredRoles: RoleOption[]) => {
  const user = useOwnUser()
  const { roles } = useAccountRoles()

  return user?.super_admin || roles.includes(roleOptions.GLOBAL_ADMIN) || requiredRoles.every((role) => roles.includes(role))
}

export { roleOptions }
export default useHasAccountRoles
