import { type FunctionComponent } from 'react'

import { type Term as TermType } from '../../../../../types/microsoft365'

import { Iconly } from 'react-iconly'

import Popover from '../../../../../components/Common/Popover'

/* utils */
import { classNames } from '../../../../../utils/classNames'

interface Props {
  term: TermType
  selected: boolean
  onClick: (term: TermType) => void
}

const Term: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { term, selected } = props

  const termDurations = {
    P1M: '1 Month',
    P1Y: '1 Year',
    P3Y: '3 Years'
  }

  return (
    <div
      className={classNames(
        'flex justify-between rounded-lg border p-4',
        'cursor-pointer bg-th-content hover:shadow',
        selected ? 'border-th-primary' : 'border-th-border'
      )}
      onClick={() => props.onClick(term)}
    >
      <div className="flex flex-col gap-2">
        <div>
          Term Duration: <span className="font-bold">{termDurations[term.duration as keyof typeof termDurations]}</span>
        </div>
        <div>
          Billing Cycle: <span className="font-bold">{term.billingCycle}</span>
        </div>
        {term.price ? (
          <div>
            Price:{' '}
            <span className="font-bold">
              £
              {term.billingCycle === 'Monthly' && term.duration === 'P1Y'
                ? (term.price / 12).toFixed(2) + ' / Month'
                : term.billingCycle === 'Monthly' && term.duration === 'P3Y'
                  ? (term.price / 36).toFixed(2) + ' / Month'
                  : term.billingCycle === 'Annual' && term.duration === 'P3Y'
                    ? (term.price / 3).toFixed(2) + ' / Year'
                    : term.price}
              &nbsp; excl. VAT
            </span>
          </div>
        ) : term.price === 0 ? (
          'Free'
        ) : (
          'Price Unavailable'
        )}
      </div>
      {/* info icon */}
      <Popover text={term.description}>
        <Iconly name="InfoCircle" set="light" className="rotate-180 transform cursor-pointer text-th-text-secondary" />
      </Popover>
    </div>
  )
}

export default Term
