import { type FunctionComponent, useEffect, useState } from 'react'

import { type Tenant } from '../../types/azure'

import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { type OptionTypeBase } from 'react-select'

import { setTenant } from '../../actions/tenantActions'
import Dropdown from './Dropdown'

interface Props {
  className?: string
}

const TenantDropdown: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { className } = props

  const dispatch = useDispatch()

  const tenant = useSelector((state: RootStateOrAny) => state.tenant)
  const tenants = useSelector((state: RootStateOrAny) => state.tenants)

  const [tenantOptions, setTenantOptions] = useState<OptionTypeBase[]>([])

  /* build the tenant options from the tenants */
  useEffect(() => {
    if (tenants) {
      if (tenants.length > 0) {
        setTenantOptions(
          tenants.map((tenant: Tenant) => {
            return {
              label: tenant.name,
              value: tenant.id
            }
          })
        )
      }
    }
  }, [tenants, dispatch])

  return (
    <Dropdown
      options={tenantOptions}
      defaultValue={(tenant && tenantOptions.find((t: OptionTypeBase) => t.value === tenant.id)) || tenantOptions[0]}
      variant={Dropdown.variant.DEFAULT}
      label="Tenant"
      bordered
      multi={false}
      onChange={(tenant: OptionTypeBase) => dispatch(setTenant({ name: tenant.label, id: tenant.value }))}
      className={className}
    />
  )
}

export default TenantDropdown
