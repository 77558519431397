import { useEffect, useMemo } from 'react'

import { type Entitlement, type Tenant } from 'src/types/azure'

import { setEntitlement, setEntitlements } from 'src/actions/entitlementActions'
import { setTenant, setTenants } from 'src/actions/tenantActions'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useQuery from 'src/hooks/utils/useQuery'

import { useDispatch } from 'react-redux'

interface TenantsQueryProps {
  active?: boolean
  includeSubscriptions?: boolean
}

const defaultProps: TenantsQueryProps = {
  active: false,
  includeSubscriptions: true
}

export const useTenantsQuery = ({ active, includeSubscriptions }: TenantsQueryProps = defaultProps) => {
  const account = useAccount()
  const dispatch = useDispatch()

  const { data: accountTenants, isError: isTenantError } = useQuery(
    ['account-tenants', account.id],
    async ({ signal }) => {
      const response = await api.get(`/servicenow/account/${account.id}/tenants`, {
        signal,
        headers: {
          THROW_UNAUTHORISED: false
        }
      })
      return response.data as Tenant[]
    },
    {
      enabled: !!account.id,
      cacheTime: 60 * 1000 * 5,
      refetchOnWindowFocus: false,
      errorMessage: 'Error fetching tenants'
    }
  )

  // Handle Successful Tenant Fetching
  useEffect(() => {
    if (!accountTenants?.length) {
      dispatch(
        setTenant({
          name: 'None',
          id: 'undefined'
        })
      )
      return
    }

    dispatch(setTenants(accountTenants))
    const storedTenantItem = localStorage.getItem('tenant')
    if (!storedTenantItem || storedTenantItem === 'undefined') {
      if (accountTenants.length) dispatch(setTenant(accountTenants[0]))
      return
    }

    const storedTenant = JSON.parse(storedTenantItem) as Tenant
    const exists = accountTenants.find((tenant) => tenant.id === storedTenant.id)

    if (exists) dispatch(setTenant(storedTenant))
    else dispatch(setTenant(accountTenants[0]))
  }, [accountTenants, dispatch])

  const { data: subscriptionOptions, isError: isSubscriptionError } = useQuery(
    ['entitlements', account.id],
    async ({ signal }) => {
      const response = await api.get(`/servicenow/account/${account.id}/entitlements`, {
        signal,
        headers: {
          THROW_UNAUTHORISED: false
        }
      })
      return response.data as Entitlement[]
    },
    {
      enabled: !!account.id && includeSubscriptions,
      cacheTime: 60 * 1000 * 5,
      refetchOnWindowFocus: false,
      errorMessage: 'Error fetching subscriptions'
    }
  )

  const { data: lighthouseSubscriptions } = useQuery(
    ['lighthouse-subscriptions', account.id],
    async ({ signal }) => {
      const response = await api.get(`/azure/account/${account.id}/lighthouse/subscriptions`, { signal, headers: { THROW_UNAUTHORISED: false } })
      return (response.data ?? []) as Entitlement[]
    },
    {
      enabled: !!account.id && includeSubscriptions,
      cacheTime: 60 * 1000 * 5,
      refetchOnWindowFocus: false
    }
  )

  const entitlements = useMemo(
    () =>
      subscriptionOptions?.map((subscription) => ({
        ...subscription,
        in_lighthouse: lighthouseSubscriptions?.some((lighthouse) => lighthouse.id === subscription.id) ?? false
      })),
    [subscriptionOptions, lighthouseSubscriptions]
  )

  useEffect(() => {
    if (!entitlements?.length) {
      dispatch(
        setEntitlement({
          name: 'None',
          id: 'undefined',
          subscription_id: '',
          tenant_id: '',
          state: ''
        })
      )
      return
    }

    dispatch(setEntitlements(entitlements))

    const storedEntitlement = localStorage.getItem('entitlement')
    if (!storedEntitlement || storedEntitlement === 'undefined') {
      if (entitlements.length) dispatch(setEntitlement(entitlements[0]))
      return
    }

    const parsedEntitlement = JSON.parse(storedEntitlement) as Entitlement
    const exists = entitlements.find((entitlement) => entitlement.id === parsedEntitlement.id)

    if (exists) dispatch(setEntitlement(parsedEntitlement))
    else dispatch(setEntitlement(entitlements[0]))
  }, [dispatch, entitlements])

  const data = useMemo(() => {
    if (!accountTenants) return []

    if (!entitlements && includeSubscriptions) return []

    const mappedTenants = accountTenants.map((tenant) => ({
      ...tenant,
      subscriptions: entitlements?.filter((option) => option.tenant_id === tenant.id) ?? []
    }))

    return active ? (mappedTenants.filter((tenant) => tenant.subscriptions?.length) ?? []) : (mappedTenants ?? [])
  }, [accountTenants, entitlements, active, includeSubscriptions])

  const isLoading = useMemo(() => !accountTenants || (!entitlements && includeSubscriptions), [accountTenants, entitlements, includeSubscriptions])

  return { data: data, isLoading: isLoading, isSubscriptionError, isTenantError }
}
