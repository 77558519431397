import { withAITracking } from '@microsoft/applicationinsights-react-js'

import { Fragment, type FunctionComponent, useEffect, useState } from 'react'

import { type Order } from '../../types/order'

import axios from 'axios'
import moment, { type Moment } from 'moment'
import { CSVLink } from 'react-csv'
import { Iconly } from 'react-iconly'
import { FcInTransit, FcLeave, FcOk, FcSalesPerformance } from 'react-icons/fc'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { showAlert } from '../../actions/alertsActions'
import { startLoading, stopLoading } from '../../actions/loadingActions'
import Banner from '../../components/Common/Banner'
import Button from '../../components/Common/Button'
import Count from '../../components/Common/Count'
import DateRange from '../../components/Common/DateRange/DateRange'
import Dropdown from '../../components/Common/Dropdown'
import Section from '../../components/Common/Section'
import Spacer from '../../components/Common/Spacer'
import OrderTable from '../../components/Tables/OrderTable'
import { reactPlugin } from '../../configs/appInsights'
import api from '../../constants/api'

/* dropdown options */
const periodOptions = [
  {
    label: 'Current Month',
    value: 'current'
  },
  {
    label: 'Previous Month',
    value: 'previous'
  },
  {
    label: 'Custom',
    value: 'custom'
  }
]

const firstDayOfThisMonth = moment().startOf('month')
const lastDayOfThisMonth = moment().endOf('month')

const Orders: FunctionComponent = (): JSX.Element => {
  const [startDate, setStartDate] = useState<Moment>(firstDayOfThisMonth)
  const [endDate, setEndDate] = useState<Moment>(lastDayOfThisMonth)
  const [period, setPeriod] = useState<string>(periodOptions[0].value)
  const [orders, setOrders] = useState<Order[]>([])
  const [filtered, setFiltered] = useState<Order[]>(orders)
  const [showSearch, setShowSearch] = useState<boolean>(false)
  const [state, setState] = useState<string | undefined>(undefined)
  const [active, setActive] = useState<number>(0)
  const [ended, setEnded] = useState<number>(0)
  const [one_time, setOneTime] = useState<number>(0)

  const dispatch = useDispatch()
  const account = useSelector((state: RootStateOrAny) => state.account)

  /* filter the orders by search term */
  const filter = (value: string) => {
    const searchTerm = value.toLowerCase()

    setFiltered(
      orders.filter((order: Order) => order.number.toLowerCase().indexOf(searchTerm) !== -1 || order.ordered_by.toLowerCase().indexOf(searchTerm) !== -1)
    )
  }

  /* set the date range based on the period that has been selected */
  const handlePeriodChange = (period: string) => {
    setPeriod(period)

    const firstDayOfThisMonth = moment().startOf('month')
    const lastDayOfThisMonth = moment().endOf('month')

    if (period === 'current') {
      /* set the dates to span the current month */
      setStartDate(firstDayOfThisMonth.startOf('month'))
      setEndDate(lastDayOfThisMonth.endOf('month'))
    } else if (period === 'previous') {
      /* set the dates to span the previous month */
      setStartDate(moment(firstDayOfThisMonth.subtract(1, 'months').startOf('month')))
      setEndDate(moment(lastDayOfThisMonth.subtract(1, 'months').endOf('month')))
    }
  }

  /* set the start and end dates */
  const handleDatesChange = (startDate: Moment, endDate: Moment) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  /* start loading animation on page load */
  useEffect(() => {
    dispatch(startLoading())
  }, [dispatch])

  /* get the orders for the selected account */
  useEffect(() => {
    const source = axios.CancelToken.source()

    dispatch(startLoading())

    const data = {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD')
    }

    if (account.id) {
      api
        .post(`/servicenow/account/${account.id}/orders`, data, { cancelToken: source.token })
        .then((response) => {
          const states: Record<string, number> = {}

          if (response.data) {
            for (const order of response.data) {
              states[order.state] = states[order.state] ? states[order.state] + 1 : 1
            }

            /* stop loading here if there are no orders to process */
            if (response.data.length === 0) {
              dispatch(stopLoading())
            }
          }

          setOrders(response.data || [])
          setActive(states['Active'] || 0)
          setEnded(states['Ended'] || 0)
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            dispatch(
              showAlert({
                type: 'error',
                message: 'We were unable to retrieve your orders.',
                component: 'Orders',
                error
              })
            )
            dispatch(stopLoading())
          }
        })
    }

    return () => {
      source.cancel()
    }
  }, [account, startDate, endDate, dispatch])

  /* filter the orders by state */
  useEffect(() => {
    const filtered = orders.filter((order: Order) => {
      return order.state === state || state === undefined
    })
    setFiltered(filtered)
  }, [state, orders, showSearch])

  useEffect(() => {
    if (filtered.length > 0) {
      /* sum the cost of filtered orders */
      const cost = filtered
        .map((order) => {
          return Number(order.one_time)
        })
        .reduce((a, b) => a + b)

      setOneTime(cost)

      /* stop loading when the orders have been processed */
      dispatch(stopLoading())
    } else {
      setOneTime(0)
    }
  }, [filtered, dispatch])

  return (
    <Fragment>
      <Section>
        <div className="my-5 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-1 mdlg:grid-cols-2 xl:grid-cols-4">
          <Count
            icon={<FcInTransit className="h-full w-full text-th-warning" />}
            value={orders.length}
            label="All Orders"
            className="cursor-pointer"
            onClick={() => setState(undefined)}
            active={state === undefined}
          />
          <Count
            icon={<FcOk className="h-full w-full text-th-warning" />}
            value={active}
            label="Active"
            className="cursor-pointer"
            onClick={() => setState('Active')}
            active={state === 'Active'}
          />
          <Count
            icon={<FcLeave className="h-full w-full text-th-warning" />}
            value={ended}
            label="Ended"
            className="cursor-pointer"
            onClick={() => setState('Ended')}
            active={state === 'Ended'}
          />
          <Count
            icon={<FcSalesPerformance className="h-full w-full text-th-warning" />}
            value={one_time.toLocaleString('en-UK', {
              style: 'currency',
              currency: 'GBP'
            })}
            label=" One Time Cost"
          />
        </div>
      </Section>
      <Section>
        <Banner>
          {/* date range */}
          <Dropdown
            options={periodOptions}
            defaultValue={periodOptions[0]}
            variant={Dropdown.variant.DEFAULT}
            label="Created"
            multi={false}
            onChange={(option) => handlePeriodChange(option.value)}
          />
          <DateRange
            start={startDate}
            end={endDate}
            startDateId="services_start_date"
            endDateId="services_end_date"
            variant={DateRange.variant.DEFAULT}
            onChange={(startDate, endDate) => handleDatesChange(startDate, endDate)}
            disabled={period !== 'custom'}
            focus={period === 'custom'}
          />
          <Spacer className="hidden sm:block" />
          {/* search */}
          {showSearch ? (
            <Fragment>
              <input type="text" className="rounded-lg p-2 font-body text-sm" placeholder="Search" autoFocus onChange={(e) => filter(e.target.value)} />
              <IoCloseCircleOutline className="h-6 w-6 cursor-pointer" onClick={() => setShowSearch(false)} />
            </Fragment>
          ) : (
            <Iconly name="Search" set="light" className="cursor-pointer" onClick={() => setShowSearch(true)} />
          )}
          {/* csv download */}
          <CSVLink data={filtered} filename="orders.csv" target="_blank">
            <Button onClick={() => null}>Download CSV</Button>
          </CSVLink>
        </Banner>
        <Spacer />
        <OrderTable orders={filtered} />
      </Section>
    </Fragment>
  )
}

const OrdersWithTracking = withAITracking(reactPlugin, Orders, 'Orders')
export default OrdersWithTracking
