import { ADD_UPLOAD, COMPLETE_UPLOAD, REMOVE_UPLOAD, SET_UPLOAD_ERROR, UPDATE_UPLOAD_PROGRESS } from 'src/actions/uploadActions'

interface UploadState {
  uploads: {
    [id: string]: {
      progress: number
      fileName: string
      status: 'uploading' | 'completed' | 'error'
    }
  }
}

const initialState: UploadState = {
  uploads: {}
}

const uploadReducer = (state = initialState, action: any): UploadState => {
  switch (action.type) {
    case ADD_UPLOAD:
      return {
        ...state,
        uploads: {
          ...state.uploads,
          [action.payload.id]: {
            progress: 0,
            fileName: action.payload.fileName,
            status: 'uploading'
          }
        }
      }
    case UPDATE_UPLOAD_PROGRESS:
      return {
        ...state,
        uploads: {
          ...state.uploads,
          [action.payload.id]: {
            ...state.uploads[action.payload.id],
            progress: action.payload.progress
          }
        }
      }
    case COMPLETE_UPLOAD:
      return {
        ...state,
        uploads: {
          ...state.uploads,
          [action.payload.id]: {
            ...state.uploads[action.payload.id],
            status: 'completed'
          }
        }
      }
    case SET_UPLOAD_ERROR:
      return {
        ...state,
        uploads: {
          ...state.uploads,
          [action.payload.id]: {
            ...state.uploads[action.payload.id],
            status: 'error'
          }
        }
      }
    case REMOVE_UPLOAD:
      const { [action.payload.id]: _, ...remainingUploads } = state.uploads
      return {
        ...state,
        uploads: remainingUploads
      }
    default:
      return state
  }
}

export default uploadReducer
