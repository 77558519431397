import { useIsAuthenticated } from '@azure/msal-react'

import { type FunctionComponent } from 'react'

import { Iconly } from 'react-iconly'
import { useDispatch } from 'react-redux'

import { openFeedbackDrawer } from '../../../actions/drawerActions'
import Close from './Close/Close'
import FooterLink from './FooterLink/FooterLink'
import User from './User/User'

const Footer: FunctionComponent = (): JSX.Element => {
  const isAuthenticated = useIsAuthenticated()
  const dispatch = useDispatch()
  return (
    <footer className="flex flex-none items-center justify-center py-4 xs:block xs:bg-th-secondary xs:p-0">
      {isAuthenticated && (
        <button
          onClick={() => dispatch(openFeedbackDrawer())}
          className="flex h-12 w-12 items-center justify-center rounded-full bg-th-lowlight px-2.5 xs:h-14 xs:w-full xs:rounded-none xs:bg-th-secondary xs:hover:bg-th-navigation-parent-active"
        >
          <span className="icon flex h-full items-center justify-center xs:w-14">
            <Iconly name="Message" set="light" />
          </span>
          <span className="hidden flex-auto text-left xs:block">Send Feedback</span>
        </button>
      )}
      {!isAuthenticated && <FooterLink label="Sign In" route="/login" icon="Login" />}
      <Close />
      {isAuthenticated && <User />}
    </footer>
  )
}
export default Footer
