export const formatFileSize = (bytes: number): string => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
}

export const downloadFile = (blob: Blob, filename: string) => {
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')

  a.style.display = 'none'
  a.href = url
  a.download = filename

  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)

  window.URL.revokeObjectURL(url)
}

export const exportToCSV = async (data: object[], filename: string) => {
  const headers = Object.keys(data[0])
  const csv = [
    headers.join(','),
    ...data.map((row) => {
      return headers
        .map((fieldName) => {
          const field = row[fieldName as keyof typeof row]
          if (typeof field === 'string') return `"${field}"`
          if (typeof field === 'number') return field
          if (typeof field === 'boolean') return field ? 'true' : 'false'
          if (typeof field === 'object') return JSON.stringify(field)
          return ''
        })
        .join(',')
    })
  ].join('\n')

  const blob = new Blob([csv], { type: 'text/csv' })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = filename
  link.click()
}
