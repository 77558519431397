import { type FunctionComponent, useMemo, useState } from 'react'

import type { Account } from '../../types/account'
import type { User } from '../../types/user'

import axios from 'axios'
import type { AxiosResponse } from 'axios'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'

import { showAlert } from '../../actions/alertsActions'
import { closeModals } from '../../actions/modalActions'
import api from '../../constants/api'
import Button from '../Common/Button'
import Input from '../Common/Input'
import Modal from '../Common/Modal'

const ContactConfirmModal: FunctionComponent = (): JSX.Element => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const open = useSelector((state: RootStateOrAny) => state.modals.contactconfirmmodal)

  const location = useLocation()
  const contact = location.state

  const [contactEmail, setContactEmail] = useState('')
  const isValidName = useMemo(() => contact?.email === contactEmail, [contact?.email, contactEmail])

  const account: Account = useSelector((state: RootStateOrAny) => state.account)
  const user: User = useSelector((state: RootStateOrAny) => state.user)

  const [loading, setLoading] = useState(false)
  const handleConfirmDelete = () => {
    setLoading(true)

    if (contact?.email !== user.email && contact) {
      api
        .post(`/servicenow/account/${account.id}/relationship/${contact.relationship}/remove`)
        .then((response: AxiosResponse) => {
          if (response.data.success) {
            dispatch(
              showAlert({
                type: 'success',
                message: `Successfully removed the relationship with ${contact.first_name} ${contact.last_name}.`,
                component: 'Contact'
              })
            )
            /* redirect back to contacts */
            navigate('/contacts')
            dispatch(closeModals())
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            dispatch(
              showAlert({
                type: 'error',
                message: `We were unable to remove the relationship with ${contact.first_name} ${contact.last_name}.`,
                component: 'Contacts',
                error
              })
            )
          }
        })
        .finally(() => setLoading(false))
    } else {
      dispatch(
        showAlert({
          type: 'error',
          message: "You can't remove your own contact record.",
          component: 'Profile'
        })
      )
    }
  }

  return (
    <Modal title="Confirm removal" open={open} loading={loading}>
      <Input
        name="contact_email"
        type="text"
        className="mb-4 w-full rounded-lg border border-th-border p-2 font-body text-sm"
        placeholder="Enter contact email..."
        autoFocus
        onChange={(e) => setContactEmail(e.target.value)}
      />
      <div className="flex justify-end">
        <Button className="gap-2" bordered onClick={handleConfirmDelete} disabled={!isValidName}>
          Confirm
        </Button>
      </div>
    </Modal>
  )
}

export default ContactConfirmModal
