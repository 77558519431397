import { withAITracking } from '@microsoft/applicationinsights-react-js'

import { Fragment, type FunctionComponent, createRef, useEffect, useMemo, useState } from 'react'

import { type Entitlement, type Tag, type Tenant } from '../../../types/azure'

import useBasicChartDataShape from 'src/hooks/composite/calculation/useBasicChartDataShape'
import useChartDataShape from 'src/hooks/composite/calculation/useChartDataShape'
import useExtendedTotalSpend from 'src/hooks/composite/calculation/useExtendedTotalSpend'
import useConsumptionTotal from 'src/hooks/composite/costs/useConsumptionTotal'
import useProjectedSpend from 'src/hooks/composite/costs/useProjectedSpend'
import useReservedInstancesTotal from 'src/hooks/composite/costs/useReservedInstancesTotal'
import useSoftwareSubscriptionsTotal from 'src/hooks/composite/costs/useSoftwareSubscriptionsTotal'
import useDatePicker from 'src/hooks/composite/useDateRangePicker'
import useBudgets from 'src/hooks/services/microsoft/queries/useBudgets'
import useDailyTotalsForTagset from 'src/hooks/services/microsoft/queries/useDailyTotalsForTagset'
import { useTenantsQuery } from 'src/hooks/services/servicenow/queries/useTenantsQuery'

import Button from 'src/components/Common/Button'
import Heading from 'src/components/Common/Heading'
import PriceDetailsCard from 'src/components/Content/PriceDetailsCard'
import SubscriptionDropdown from 'src/components/Dropdowns/SubscriptionDropdown'
import TagsDropdown from 'src/components/Dropdowns/TagsDropdown'
import BarGraph from 'src/components/Graphs/BarGraph'
import ForecastLineGraph from 'src/components/Graphs/ForecastLineGraph'
import LinearBar from 'src/components/Graphs/LinearBar'
import PieGraph from 'src/components/Graphs/PieGraph'
import SectionedArea from 'src/components/Graphs/SectionedArea'
import SectionedBar from 'src/components/Graphs/SectionedBar'
import SectionedTable from 'src/components/Tables/SectionedTable'

import moment, { type Moment } from 'moment'
import { PiLink } from 'react-icons/pi'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { stopLoading } from '../../../actions/loadingActions'
import Banner from '../../../components/Common/Banner'
import Dropdown from '../../../components/Common/Dropdown'
import Section from '../../../components/Common/Section'
import Spacer from '../../../components/Common/Spacer'
import { reactPlugin } from '../../../configs/appInsights'
import AzureQuickLinks from './AzureQuickLinks'

// -~= Defining Options & Defaults =~- //
const VARIANT_OPTIONS = [
  { label: 'Blue Gradient', value: 'blue' },
  { label: 'Colorful', value: 'color' }
]

const DEFAULT_VARIANT = VARIANT_OPTIONS[0].value

const STACK_OPTIONS = [
  { label: 'Tenant', value: 'tenant' },
  { label: 'Subscription', value: 'subscription' },
  { label: 'Service', value: 'meterCategory' },
  { label: 'Region', value: 'resourceLocation' },
  { label: 'Resource Group', value: 'resourceGroup' }
]

const CHART_OPTIONS = [
  { label: 'Bar Chart', value: 'bar' },
  { label: 'Area Chart', value: 'area' },
  { label: 'Table', value: 'table' },
  { label: 'Line', value: 'line' }
]

const MULTICHART_OPTIONS = [
  { label: 'Pie Chart', value: 'pie' },
  { label: 'Bar Chart', value: 'bar' }
]

const CUMULATIVE_OPTIONS = [
  { label: 'None', value: false },
  { label: 'Cumulative', value: true }
]

const DEFAULT_STACK = STACK_OPTIONS[1].value
const DEFAULT_CHART = CHART_OPTIONS[0].value

const DEFAULT_CHART_CONFIG = {
  type: DEFAULT_CHART,
  stack: DEFAULT_STACK,
  cumulative: false,
  hideForecast: false,
  hideBudget: false
}

const currentYearDateRange = [moment().startOf('year'), moment().endOf('year')] as [Moment, Moment]
const ytdDateRange = [moment().startOf('year'), moment().startOf('day').subtract(1, 'day')] as [Moment, Moment]
const lastYearDateRange = [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')] as [Moment, Moment]
const mtdDateRange = [moment().startOf('month'), moment().startOf('day').subtract(1, 'day')] as [Moment, Moment]
const lastMonthDateRange = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')] as [Moment, Moment]
const lastThreeMonthsDateRange = [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')] as [Moment, Moment]
const lastThirtyDaysDateRange = [moment().subtract(30, 'days').startOf('day'), moment().subtract(1, 'day')] as [Moment, Moment]

// -~= Component =~- //
const AzureOverview: FunctionComponent = (): JSX.Element => {
  // --~= State definitions =~-- //
  const [selectedVariant] = useState(DEFAULT_VARIANT)
  const [chartConfig, setChartConfig] = useState(DEFAULT_CHART_CONFIG)
  const [multichartConfig, setMultichartConfig] = useState(MULTICHART_OPTIONS[0])
  const [graphFilter, setGraphFilter] = useState<Array<string>>([])

  const [selectedTags, setSelectedTags] = useState<Tag[]>([])
  const [selectedTenants, setSelectedTenants] = useState<Tenant[]>([])
  const [selectedSubscriptions, setSelectedSubscriptions] = useState<Entitlement[]>([])

  // -~= Helper Hooks =~- //
  const { DateRangePicker, PeriodDropdown, dateRange } = useDatePicker({
    preset: 'full',
    minDate: moment('08/01/2022', 'MM/DD/YYYY')
  })

  const dispatch = useDispatch()
  useEffect(() => void dispatch(stopLoading()), [dispatch])

  const tagDropdownRef = createRef<{ clear: () => void }>()

  /* -~= Queries =~- */
  const { data: tenants } = useTenantsQuery({ active: true })

  const budgets = useBudgets({ tenants })

  const { data: generalConsumption } = useDailyTotalsForTagset({ dateRange, tenants, tags: selectedTags })
  const { data: meterCategoryConsumption } = useDailyTotalsForTagset({ dateRange, tenants, tags: selectedTags, groupBy: 'meterCategory' })
  const { data: resourceLocationConsumption } = useDailyTotalsForTagset({ dateRange, tenants, tags: selectedTags, groupBy: 'resourceLocation' })
  const { data: resourceGroupConsumption } = useDailyTotalsForTagset({ dateRange, tenants, tags: selectedTags, groupBy: 'resourceGroup' })

  const [lastContentfulDate, setLastContentfulDate] = useState<Date | null>(null)
  useEffect(() => {
    if (!generalConsumption) return

    const last = Math.max(...(generalConsumption.map((d) => new Date(d.usageDate).valueOf()) ?? []))
    setLastContentfulDate(last ? new Date(last) : null)
  }, [generalConsumption])

  /* -~= Data Processing =~- */
  const reservedInstancesTotal = useReservedInstancesTotal({ dateRange: ytdDateRange })
  const softwareSubscriptionsTotal = useSoftwareSubscriptionsTotal({ dateRange: ytdDateRange })

  const overallConsumptionTotal = useConsumptionTotal({ dateRange: ytdDateRange })

  const ytdConsumptionTotal = useConsumptionTotal({ dateRange: ytdDateRange, subscriptions: selectedSubscriptions })
  const mtdConsumptionTotal = useConsumptionTotal({ dateRange: mtdDateRange, subscriptions: selectedSubscriptions })

  const lastYearConsumptionTotal = useConsumptionTotal({ dateRange: lastYearDateRange, subscriptions: selectedSubscriptions })
  const lastYearMonthlyAverage = lastYearConsumptionTotal ? lastYearConsumptionTotal / 12 : null

  const lastMonthConsumptionTotal = useConsumptionTotal({ dateRange: lastMonthDateRange, subscriptions: selectedSubscriptions })
  const lastMonthDailySpend = lastMonthConsumptionTotal ? lastMonthConsumptionTotal / moment().subtract(1, 'month').daysInMonth() : null

  // Averages
  const monthlyConsumptionTotal = useConsumptionTotal({ dateRange: lastThreeMonthsDateRange, subscriptions: selectedSubscriptions })
  const monthlyAverage = monthlyConsumptionTotal ? monthlyConsumptionTotal / 3 : null

  const dailyConsumptionTotal = useConsumptionTotal({ dateRange: lastThirtyDaysDateRange, subscriptions: selectedSubscriptions })
  const dailyAverage = dailyConsumptionTotal ? dailyConsumptionTotal / 30 : null

  const projectedYearlySpend = useProjectedSpend({ dateRange: currentYearDateRange, includeRecurringSpend: true })
  const projectedLastYearSpend = lastYearConsumptionTotal
  const projectedYTDSpend = useProjectedSpend({
    dateRange: currentYearDateRange,
    selectedSubscriptions,
    lastContentfulDate: lastContentfulDate ? moment(lastContentfulDate) : undefined
  })

  const budgetTotal = useMemo(() => {
    const filtered = budgets?.filter((b) => selectedTenants.some((t) => t.id === b.tenant_id))
    const total = filtered?.reduce((acc, cur) => acc + cur.value, 0)
    return total
  }, [budgets, selectedTenants])

  const stackConsumptionMap = {
    tenant: generalConsumption,
    subscription: generalConsumption,
    meterCategory: meterCategoryConsumption,
    resourceLocation: resourceLocationConsumption,
    resourceGroup: resourceGroupConsumption
  }

  const [tenantExtendedTotalSpend, tenantCompleteDataSetStats] = useExtendedTotalSpend({
    data: generalConsumption,
    stack: 'tenant',
    selectedSubscriptions,
    selectedTenants
  })

  const [subscriptionExtendedTotalSpend, subscriptionCompleteDataSetStats] = useExtendedTotalSpend({
    data: generalConsumption,
    stack: 'subscription',
    selectedSubscriptions,
    selectedTenants
  })

  const [meterCategoryExtendedTotalSpend, meterCategoryCompleteDataSetStats] = useExtendedTotalSpend({
    data: meterCategoryConsumption,
    stack: 'meterCategory',
    selectedSubscriptions,
    selectedTenants
  })

  const [resourceLocationExtendedTotalSpend, resourceLocationCompleteDataSetStats] = useExtendedTotalSpend({
    data: resourceLocationConsumption,
    stack: 'resourceLocation',
    selectedSubscriptions,
    selectedTenants
  })

  const [resourceGroupExtendedTotalSpend, resourceGroupCompleteDataSetStats] = useExtendedTotalSpend({
    data: resourceGroupConsumption,
    stack: 'resourceGroup',
    selectedSubscriptions,
    selectedTenants
  })

  const tenantPieChartData = useBasicChartDataShape({ data: tenantExtendedTotalSpend ?? [] })
  const subscriptionPieChartData = useBasicChartDataShape({ data: subscriptionExtendedTotalSpend ?? [] })
  const meterCategoryPieChartData = useBasicChartDataShape({ data: meterCategoryExtendedTotalSpend ?? [] })
  const resourceLocationPieChartData = useBasicChartDataShape({ data: resourceLocationExtendedTotalSpend ?? [] })
  const resourceGroupPieChartData = useBasicChartDataShape({ data: resourceGroupExtendedTotalSpend ?? [] })

  const pieChartData = [
    { name: 'Tenant', data: tenantPieChartData },
    { name: 'Subscription', data: subscriptionPieChartData },
    { name: 'Service', data: meterCategoryPieChartData },
    { name: 'Region', data: resourceLocationPieChartData },
    { name: 'Resource Group', data: resourceGroupPieChartData }
  ]

  const extendedTotalSpend = {
    tenant: tenantExtendedTotalSpend,
    subscription: subscriptionExtendedTotalSpend,
    meterCategory: meterCategoryExtendedTotalSpend,
    resourceLocation: resourceLocationExtendedTotalSpend,
    resourceGroup: resourceGroupExtendedTotalSpend
  }

  const completeDataSetStats =
    tenantCompleteDataSetStats ??
    subscriptionCompleteDataSetStats ??
    meterCategoryCompleteDataSetStats ??
    resourceLocationCompleteDataSetStats ??
    resourceGroupCompleteDataSetStats

  const { data: alteredConsumption, periodicBudget } = useChartDataShape({
    data: extendedTotalSpend[chartConfig.stack as keyof typeof extendedTotalSpend] ?? [],
    budgetTotal,
    dateRange,
    cumulative: chartConfig.cumulative,
    completeDataSetStats
  })

  return (
    <Fragment>
      <Section>
        <LinearBar
          title="Azure Spend Summary"
          info="Usage data for the most recent month is subject to change. Any Marketplace item usage will be included in these figures, but may be invoiced seperately."
          data={[
            { name: 'Consumption YTD', value: overallConsumptionTotal ?? 0 },
            { name: 'Reservations YTD', value: reservedInstancesTotal },
            { name: 'Software Subscriptions YTD', value: softwareSubscriptionsTotal },
            { name: 'Projected', value: projectedYearlySpend ?? 0, open: true }
          ]}
          colorful={selectedVariant === 'color'}
        />
      </Section>
      <Section>
        <div className="flex items-center justify-between">
          <h2 className="font-headline text-2xl font-bold text-th-text">Consumption Summary</h2>
          <div className="flex items-center gap-2">
            <SubscriptionDropdown
              onChange={(tenants, subscriptions) => {
                setSelectedTenants(tenants)
                setSelectedSubscriptions(subscriptions)
              }}
            />
          </div>
        </div>

        <div className="mt-8 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-6">
          <PriceDetailsCard
            title="Cost Year To Date"
            subtitle="Previous Year"
            prices={[
              ytdConsumptionTotal,
              lastYearConsumptionTotal,
              lastYearConsumptionTotal && ytdConsumptionTotal ? lastYearConsumptionTotal - ytdConsumptionTotal : null
            ]}
          />
          <PriceDetailsCard
            title="Cost Month To Date"
            subtitle="Previous Month"
            prices={[
              mtdConsumptionTotal,
              lastMonthConsumptionTotal,
              lastMonthConsumptionTotal && mtdConsumptionTotal ? lastMonthConsumptionTotal - mtdConsumptionTotal : null
            ]}
          />
          <PriceDetailsCard
            title="Average Daily Cost"
            subtitle="Previous Month"
            prices={[dailyAverage, lastMonthDailySpend, dailyAverage && lastMonthDailySpend ? lastMonthDailySpend - dailyAverage : null]}
          />
          <PriceDetailsCard
            title="Average Monthly Cost"
            subtitle="Previous Year"
            prices={[monthlyAverage, lastYearMonthlyAverage, monthlyAverage && lastYearMonthlyAverage ? lastYearMonthlyAverage - monthlyAverage : null]}
          />
          <PriceDetailsCard
            title="Projected Cost This Year"
            subtitle="Previous Year"
            prices={[
              projectedYTDSpend,
              projectedLastYearSpend,
              projectedLastYearSpend && projectedYTDSpend ? projectedLastYearSpend - projectedYTDSpend : null
            ]}
          />
          {budgets?.length !== 0 && budgetTotal ? (
            <PriceDetailsCard
              title="Projected Budget VAR"
              subtitle="Total Budget"
              prices={[projectedYTDSpend ? projectedYTDSpend - budgetTotal * 12 : null, lastYearConsumptionTotal ? budgetTotal * 12 : null, null]}
              includeChangeDirectionInMain
            />
          ) : (
            <div className="space-y-1 rounded-xl border border-th-border bg-th-content px-8 py-6">
              <p className="font-semibold text-th-text-secondary">No Budget Found</p>
              <div className="flex items-center gap-3">
                <p className="text-3xl font-extrabold text-th-text">N/A</p>
              </div>
              <div className="flex items-center gap-2">
                <PiLink />
                <Link className="cursor-pointer text-sm font-semibold text-th-text-secondary hover:text-th-text hover:underline" to="/azure_overview/budgets">
                  Set Budget
                </Link>
              </div>
            </div>
          )}
        </div>
      </Section>
      <Section>
        <Heading text="Spend Overview">
          <Spacer className="hidden sm:block" />
          {/* <Popover.Root
            onOpenChange={(open) => {
              if (open) return

              setViewName(null)
              setRemovingView(false)
            }}
          >
            <Popover.Trigger asChild>
              <button
                className="rounded-full inline-flex items-center justify-center bg-th-content cursor-default outline-none border border-th-border text-sm px-4 py-2"
                aria-label="View Presets"
              >
                View Presets
              </button>
            </Popover.Trigger>

            <Popover.Close className="hidden" ref={viewPopoverRef} />
            <Popover.Portal>
              <Popover.Content
                className="rounded-xl p-5 w-full bg-th-content border border-th-border z-50 shadow min-w-[18.25rem]"
                sideOffset={5}
                collisionPadding={16}
              >
                <div className="flex flex-col gap-2.5">
                  <div className="flex justify-between items-center">
                    <p className="text-[15px] leading-[19px] font-medium">View Presets</p>
                    <div className="flex gap-2.5">
                      <button
                        className="rounded-full w-7 h-7 inline-flex items-center justify-center bg-th-content cursor-default outline-none border border-th-border aria-busy:bg-th-content-secondary"
                        aria-label="Delete view"
                        onClick={() => setViewName((prev) => (prev === null ? '' : null))}
                        aria-busy={isLoadingSavedViews || viewName !== null}
                      >
                        <PiPlus />
                      </button>
                      <button
                        className="rounded-full w-7 h-7 inline-flex items-center justify-center bg-th-content cursor-default outline-none border border-th-border aria-busy:bg-th-content-secondary"
                        aria-label="Delete view"
                        onClick={() => setRemovingView((prev) => !prev)}
                        aria-busy={isLoadingSavedViews || removingView}
                      >
                        <PiTrash />
                      </button>
                    </div>
                  </div>
                  {viewName !== null && (
                    <>
                      <hr className="border-th-border" />
                      <div className={`flex items-center gap-2.5`}>
                        <Input type="text" name="viewName" placeholder="View Name" onChange={(e) => setViewName(e.target.value)} />
                        <Button
                          variant="primary"
                          onClick={async () => {
                            await saveCurrentView()
                            setViewName(null)
                          }}
                          disabled={isLoadingSavedViews || viewName === ''}
                        >
                          {isLoadingSavedViews ? <Spinner className="w-[14px] h-[14px] border-l-th-content" /> : <PiPushPin />}
                        </Button>
                      </div>
                    </>
                  )}
                  <hr className="border-th-border" />
                  <div className="max-h-36 overflow-y-auto flex flex-col">
                    {savedViews?.length !== 0 &&
                      savedViews?.map((view) => (
                        <button
                          className={`w-full rounded-md py-1 text-sm bg-th-content group ${removingView ? 'hover:bg-th-red' : 'hover:bg-th-content-secondary'}`}
                          disabled={isLoadingSavedViews}
                          key={view.name}
                          onClick={() => {
                            if (removingView) {
                              setRemovingView(false)
                              return removeView(view.name)
                            }

                            if (view.chartConfig) setChartConfig(view.chartConfig)
                            if (view.selectedTags?.length) setSelectedTags(view.selectedTags)
                            //setSelectedTenants(view.selectedTenants)
                            //setSelectedSubscriptions(view.selectedSubscriptions)
                            setDateRange([moment(view.dateRange[0]), moment(view.dateRange[1])])
                            if (view.graphFilter?.length) setGraphFilter(view.graphFilter)

                            viewPopoverRef.current?.click()
                          }}
                        >
                          <p className={`${removingView ? 'group-hover:hidden' : ''}`}>{view.name}</p>
                          <PiTrash className={`${removingView ? 'group-hover:block hidden' : 'hidden'} w-5- h-5 text-th-content mx-auto`} />
                        </button>
                      ))}
                  </div>
                </div>

                <Popover.Arrow className="fill-white" />
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root> */}
        </Heading>
        <Banner className="mb-8 mt-4">
          {PeriodDropdown}
          {DateRangePicker}
          <Spacer className="hidden sm:block" />
          <Button
            onClick={() => {
              setGraphFilter([])
              setChartConfig(DEFAULT_CHART_CONFIG)

              tagDropdownRef.current?.clear()
            }}
            disabled={JSON.stringify(chartConfig) === JSON.stringify(DEFAULT_CHART_CONFIG) && graphFilter.length === 0 && selectedTags.length === 0}
            bordered
          >
            Reset
          </Button>
          <Dropdown
            options={CUMULATIVE_OPTIONS}
            value={CUMULATIVE_OPTIONS.find((o) => o.value === chartConfig.cumulative)}
            variant={Dropdown.variant.DEFAULT}
            label="Aggregate"
            bordered
            multi={false}
            disableAll
            onChange={(option) => setChartConfig({ ...chartConfig, cumulative: option.value })}
          />

          <Dropdown
            options={CHART_OPTIONS}
            value={CHART_OPTIONS.find((o) => o.value === chartConfig.type)}
            variant={Dropdown.variant.DEFAULT}
            label="View As"
            bordered
            multi={false}
            disableAll
            onChange={(option) => setChartConfig({ ...chartConfig, type: option.value })}
          />

          <Dropdown
            options={STACK_OPTIONS}
            value={STACK_OPTIONS.find((o) => o.value === chartConfig.stack)}
            variant={Dropdown.variant.DEFAULT}
            label="Stack By"
            bordered
            multi={false}
            disableAll
            onChange={(option) => {
              setGraphFilter([]) // reset the graph filter when changing the stack
              setChartConfig({ ...chartConfig, stack: option.value })
            }}
            disabled={chartConfig.type === 'line'}
          />

          <TagsDropdown tenants={selectedTenants} dateRange={dateRange} onTagsChange={setSelectedTags} ref={tagDropdownRef} value={selectedTags} />
        </Banner>
        {chartConfig.type === 'bar' && (
          <SectionedBar
            data={alteredConsumption}
            colorful={selectedVariant === 'color'}
            filtered={graphFilter}
            setFiltered={setGraphFilter}
            isLoading={!stackConsumptionMap[chartConfig.stack as keyof typeof stackConsumptionMap]}
            hideBudget={chartConfig.hideBudget}
            hideForecast={chartConfig.hideForecast}
            onHideChange={(hideForecast, hideBudget) => setChartConfig({ ...chartConfig, hideForecast, hideBudget })}
          />
        )}
        {chartConfig.type === 'area' && (
          <SectionedArea
            data={alteredConsumption}
            colorful={selectedVariant === 'color'}
            filtered={graphFilter}
            setFiltered={setGraphFilter}
            isLoading={!stackConsumptionMap[chartConfig.stack as keyof typeof stackConsumptionMap]}
            hideBudget={chartConfig.hideBudget}
            hideForecast={chartConfig.hideForecast}
            onHideChange={(hideForecast, hideBudget) => setChartConfig({ ...chartConfig, hideForecast, hideBudget })}
          />
        )}
        {chartConfig.type === 'table' && (
          <SectionedTable
            data={alteredConsumption}
            colorful={selectedVariant === 'color'}
            cumulative={chartConfig.cumulative}
            filtered={graphFilter}
            setFiltered={setGraphFilter}
          />
        )}

        {chartConfig.type === 'line' && (
          <ForecastLineGraph
            data={alteredConsumption}
            budget={periodicBudget}
            cumulative={chartConfig.cumulative}
            className="h-80 md:h-96 lg:h-[30rem]"
            interactive
            isLoading={!stackConsumptionMap[chartConfig.stack as keyof typeof stackConsumptionMap]}
          />
        )}
      </Section>
      <Section>
        <Banner align="right">
          <Dropdown
            options={MULTICHART_OPTIONS}
            defaultValue={MULTICHART_OPTIONS[0]}
            variant={Dropdown.variant.DEFAULT}
            label="View As"
            bordered
            multi={false}
            disableAll
            onChange={(option) => setMultichartConfig(option as { label: string; value: string })}
          />
        </Banner>
        <div className="my-8 grid grid-cols-2 gap-4 lg:grid-cols-3 2xl:grid-cols-5">
          {pieChartData.map((data, idx) => (
            <>
              {multichartConfig.value === 'bar' ? (
                <BarGraph key={idx + '_bar'} title={data.name} data={data.data} colorful={selectedVariant === 'color'} />
              ) : (
                <PieGraph key={idx + '_pie'} title={data.name} data={data.data} colorful={selectedVariant === 'color'} />
              )}
            </>
          ))}
        </div>
      </Section>

      <Section>
        <Heading text="Azure Quick Links" />
        <AzureQuickLinks />
      </Section>
    </Fragment>
  )
}

const AzureOverviewWithTracking = withAITracking(reactPlugin, AzureOverview, 'Azure Overview')
export default AzureOverviewWithTracking
