import useHasAccountRoles, { roleOptions } from '~/hooks/utils/useHasAccountRoles'

import Banner from '~/components/Common/Banner'
import DateRange from '~/components/Common/DateRange/DateRange'
import Dropdown from '~/components/Common/Dropdown'
import Spacer from '~/components/Common/Spacer'

import { PERIOD_OPTIONS, SHOW_OWN_APPROVALS_OPTIONS } from '../../constants'
import { useChangeApprovalFilters } from '../../hooks/useChangeApprovalFilters'
import { ChangeApprovalCSVDialog } from '../ChangeApprovalCSVDialog'
import { SearchFilter } from './SearchFilter'

export const ChangeApprovalFilters = () => {
  const { filterOptions, handlePeriodChange, handleDatesChange, handleShowOwnApprovalsChange } = useChangeApprovalFilters()

  const hasOtherApprovalAccess = useHasAccountRoles([roleOptions.SUPPORT_ADMIN])

  return (
    <Banner>
      <Dropdown
        options={PERIOD_OPTIONS}
        defaultValue={PERIOD_OPTIONS[0]}
        variant={Dropdown.variant.DEFAULT}
        label="Created"
        multi={false}
        onChange={(option) => handlePeriodChange(option.value)}
      />
      <DateRange
        start={filterOptions.startDate}
        end={filterOptions.endDate}
        startDateId="changeCustomerApprovals_start_date"
        endDateId="changeCustomerApprovals_end_date"
        variant={DateRange.variant.DEFAULT}
        onChange={(startDate, endDate) => handleDatesChange(startDate, endDate)}
        disabled={filterOptions.period !== 'custom'}
        focus={filterOptions.period === 'custom'}
      />
      <Spacer className="hidden sm:block" />
      {hasOtherApprovalAccess && (
        <Dropdown
          options={SHOW_OWN_APPROVALS_OPTIONS}
          defaultValue={SHOW_OWN_APPROVALS_OPTIONS[0]}
          variant={Dropdown.variant.DEFAULT}
          label="Show"
          multi={false}
          onChange={(option) => handleShowOwnApprovalsChange(option.value)}
        />
      )}
      <SearchFilter />
      <ChangeApprovalCSVDialog />
    </Banner>
  )
}
