import RefactoredDropdown from '~/components/Common/RefactoredDropdown'

import { STATE_OPTIONS } from '../../constants'
import { useChangeFilters } from '../../hooks/useChangeFilters'

export const StateFilter = () => {
  const { filterOptions, handleStateFilter } = useChangeFilters()

  return (
    <RefactoredDropdown
      data={STATE_OPTIONS.map((state) => ({
        label: state,
        value: state,
        checked: filterOptions.state?.includes(state) || false,
        disabled: filterOptions.state?.length === 1 ? filterOptions.state.includes(state) : false
      }))}
      label="State"
      onChange={(_, selectedNodes) => handleStateFilter(selectedNodes.map((o) => o.value))}
      disabled={!!filterOptions.keyword}
    />
  )
}
