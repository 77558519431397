import type { UseBaseQueryOptions } from '@tanstack/react-query'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useQuery from 'src/hooks/utils/useQuery'

export interface Emission {
  subscriptionId?: string
  azureServiceName?: string
  subService?: string
  azureRegionName?: string
  scope1?: number
  scope2?: number
  scope3?: number
  usageUSD?: number
  date?: string // Date only string
}

export interface EmissionsQueryInput {
  subscriptionIds: string[]
  months?: number
}

const useEmissionsQuery = (input: EmissionsQueryInput, options?: UseBaseQueryOptions<Emission[]>) => {
  const account = useAccount()

  return useQuery(
    ['useEmissionsQuery', account.id, input],
    async ({ signal }) => {
      const response = await api.post(`/sustainability/account/${account.id}/emissions`, input, {
        signal
      })

      return response.data
    },
    {
      enabled: input.subscriptionIds.length > 0 && !!account.id,
      ...options
    }
  )
}

export default useEmissionsQuery
