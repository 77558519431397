import { type Tenant } from '../types/azure'

export const setTenants = (
  tenants: Tenant[]
): {
  type: string
  payload: Tenant[]
} => {
  return {
    type: 'SET_TENANTS',
    payload: tenants
  }
}

export const setTenant = (
  tenant: Tenant
): {
  type: string
  payload: Tenant
} => {
  return {
    type: 'SET_TENANT',
    payload: tenant
  }
}
