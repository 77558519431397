import { useContext } from 'react'

import { ChangeApprovalFiltersContext } from './context'

export function useChangeApprovalFiltersContext<T extends boolean = true>(throwError: T = true as T) {
  const context = useContext(ChangeApprovalFiltersContext)
  if (context === undefined && throwError) {
    throw new Error('useChangeApprovalFiltersContext must be used within a ChangeApprovalFiltersProvider')
  }
  return context as T extends true ? NonNullable<typeof context> : typeof context
}
