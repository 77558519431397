export const generateTestDataForTable = (numRows: number = 100) => {
  const data: any[] = []

  const locations = ['uksouth', 'westeurope', 'eastus', 'westus', 'australiaeast', 'canadacentral', 'francecentral', 'japaneast']
  const meterNames = [
    'S10 Disks',
    'Free Instance',
    'F1 App',
    'All Other Operations',
    'Consumption Standard Connector Actions',
    'Blob Storage',
    'Table Storage',
    'File Storage',
    'Page Blob',
    'DTU',
    'vCore',
    'Node Hour'
  ]
  const units = ['1/Month', '1/Day', '1 Hour', '10K', '1', '1/Sec', '1000 Executions', '1 GB', '1 MB']
  const subscriptionNames = [
    'Microsoft Azure - ARM Template Testing1',
    'Microsoft Azure Demos',
    'Enterprise Subscription',
    'Pay-as-you-go',
    'Azure for Students'
  ]
  const tags = ['Env=Dev', 'Env=Prod', 'Type=WebApp', 'Type=DB', 'Role=Frontend', 'Role=Backend', 'Stage=Alpha', 'Stage=Beta']

  const resourceGroups = ['ResourceGroup1', 'ResourceGroup2', 'ResourceGroup3']
  const resourceNames = ['Resource1', 'Resource2', 'Resource3']
  const meterCategories = ['Compute', 'Storage', 'Networking']
  const meterSubCategories = ['VM', 'Disk', 'Data Transfer']

  const generateRandomUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0
      const v = c === 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }

  const generateRandomTags = (count: number) => {
    const selectedTags = []
    for (let i = 0; i < count; i++) {
      selectedTags.push(tags[Math.floor(Math.random() * tags.length)])
    }
    return selectedTags.join(',')
  }

  for (let i = 0; i < numRows; i++) {
    data.push({
      id: generateRandomUUID(),
      usageDate: new Date().toISOString().split('T')[0], // Generating current date in YYYY-MM-DD format
      tenantId: generateRandomUUID(),
      subscriptionId: generateRandomUUID(),
      tenantName: `Tenant-${Math.floor(Math.random() * 999) + 1}`,
      subscriptionName: subscriptionNames[Math.floor(Math.random() * subscriptionNames.length)],
      resourceGroup: resourceGroups[Math.floor(Math.random() * resourceGroups.length)],
      resourceLocation: locations[Math.floor(Math.random() * locations.length)],
      resourceURI: `https://example.com/resources/${Math.floor(Math.random() * 1000)}`,
      resourceName: resourceNames[Math.floor(Math.random() * resourceNames.length)],
      meterId: generateRandomUUID(),
      meterName: meterNames[Math.floor(Math.random() * meterNames.length)],
      meterRegion: locations[Math.floor(Math.random() * locations.length)],
      meterCategory: meterCategories[Math.floor(Math.random() * meterCategories.length)],
      meterSubCategory: meterSubCategories[Math.floor(Math.random() * meterSubCategories.length)],
      unitOfMeasure: units[Math.floor(Math.random() * units.length)],
      unitPriceGBP: parseFloat((Math.random() * 10).toFixed(2)).toString(),
      quantity: Math.floor(Math.random() * 100).toString(),
      totalSpendGBP: parseFloat((Math.random() * 1000).toFixed(2)).toString(),
      tags: generateRandomTags(2),
      billed: Math.random() > 0.5 ? 'Yes' : 'No' // Randomly assigning 'Yes' or 'No'
    })
  }

  return data
}

export const TABLE_DATA: [string, string][] = [
  ['usageDate', 'The date on which the usage occurred.'],
  ['tenantId', "The unique identifier for the Azure tenant, essentially representing an organization's dedicated Azure instance."],
  ['subscriptionId', 'The unique identifier for the Azure subscription under which the resource falls.'],
  ['tenantName', "The name of the Azure tenant, which is essentially an organization's dedicated Azure instance."],
  ['subscriptionName', 'The name of the Azure subscription under which the resource falls.'],
  ['resourceGroup', 'The Azure resource group to which the resource belongs.'],
  ['resourceLocation', 'The Azure region where the resource is hosted (e.g., uksouth, westeurope).'],
  ['resourceURI', 'The URI of the resource.'],
  ['resourceName', 'The specific name of the resource within the service.'],
  ['meterId', 'Unique identifier for the meter used for billing.'],
  ['meterName', 'Identifies the type of meter used for billing (e.g., "S10 Disks", "Free Instance").'],
  ['meterRegion', 'The geographical region associated with the metered resource.'],
  ['meterCategory', 'The category of the meter used for billing.'],
  ['meterSubCategory', 'The sub-category of the meter used for billing.'],
  ['unitOfMeasure', 'The billing unit for the resource usage (e.g., "1/Month", "1/Day", "1 Hour").'],
  ['unitPriceGBP', 'The unit price in GBP for the resource usage.'],
  ['quantity', 'The amount of the resource that was used, as measured in the specified unit.'],
  ['totalSpendGBP', 'The total cost incurred for the particular resource and meter, in GBP.'],
  ['tags', 'Metadata associated with the resource, such as environment and owner (comma-separated key-value pairs).'],
  ['billed', 'Information indicating whether the usage has been billed.']
]
