import ProgressBar from '@ramonak/react-progress-bar'

import { Fragment, type FunctionComponent, useEffect, useState } from 'react'

import { type SecureScoreTenant } from '../../types/security'

import axios, { type AxiosResponse } from 'axios'
import { Iconly } from 'react-iconly'
import { FcBullish, FcLock, FcPieChart } from 'react-icons/fc'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { showAlert } from '../../actions/alertsActions'
import { openM365SecureScoreInfoDrawer } from '../../actions/drawerActions'
import { startLoading, stopLoading } from '../../actions/loadingActions'
import Banner from '../../components/Common/Banner'
import Button from '../../components/Common/Button'
import Count from '../../components/Common/Count'
import Heading from '../../components/Common/Heading'
import Section from '../../components/Common/Section'
import Spacer from '../../components/Common/Spacer'
import TenantDropdown from '../../components/Common/TenantDropdown'
import api from '../../constants/api'

const m365ScoreCategories: Record<string, number> = {
  Identity: 0,
  IdentityMax: 0,
  Device: 0,
  DeviceMax: 0,
  Apps: 0,
  AppsMax: 0,
  Data: 0,
  DataMax: 0
}

const M365SecureScore: FunctionComponent = (): JSX.Element => {
  /* secure scores */
  const [m365SecureScores, setM365SecureScores] = useState<Record<string, number>>(m365ScoreCategories)
  const [m365Total, setM365Total] = useState<number>(0)
  const [m365MaxTotal, setM365MaxTotal] = useState<number>(0)
  const [m365Percentage, setM365Percentage] = useState<number>(0)

  const dispatch = useDispatch()
  const account = useSelector((state: RootStateOrAny) => state.account)
  const tenant = useSelector((state: RootStateOrAny) => state.tenant)

  /* get the tenants secure score */
  useEffect(() => {
    const source = axios.CancelToken.source()

    setM365SecureScores(m365ScoreCategories)
    dispatch(startLoading())

    if (tenant.id !== '') {
      /* m365 secure scores */
      api
        .get(`/soteria/account/${account.id}/tenant/${tenant.id}`, { cancelToken: source.token })
        .then((response: AxiosResponse) => {
          if (response.data) {
            const tenant: SecureScoreTenant = response.data

            setM365SecureScores({
              Identity: tenant.currentSecureScore.identityScore,
              IdentityMax: tenant.currentSecureScore.identityScoreMax,
              Device: tenant.currentSecureScore.deviceScore,
              DeviceMax: tenant.currentSecureScore.deviceScoreMax,
              Apps: tenant.currentSecureScore.appsScore,
              AppsMax: tenant.currentSecureScore.appsScoreMax,
              Data: tenant.currentSecureScore.dataScore,
              DataMax: tenant.currentSecureScore.dataScoreMax
            })

            setM365Total(tenant.currentSecureScore.secureScore)
            setM365MaxTotal(tenant.currentSecureScore.maxSecureScore)
            setM365Percentage(tenant.currentSecureScore.scorePercent)

            dispatch(stopLoading())
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            setM365SecureScores(m365ScoreCategories)
            dispatch(
              showAlert({
                type: 'error',
                message: 'We were unable to retrieve your secure score data.',
                component: 'Security',
                error
              })
            )
          }
        })
    }

    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant])

  return (
    <Fragment>
      <Section>
        <Banner>
          {/* information button */}
          <Button onClick={() => dispatch(openM365SecureScoreInfoDrawer())} className="flex gap-2" bordered>
            <Iconly name="InfoCircle" set="light" className="rotate-180 transform" />
            Information
          </Button>
          <Spacer />
          {/* tenant filter */}
          <TenantDropdown />
        </Banner>
        <Spacer />
        {/* score tiles */}
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-1 mdlg:grid-cols-2 xl:grid-cols-3">
          <Count icon={<FcBullish className="h-full w-full text-th-warning" />} value={m365Total.toFixed(0)} label="Current Score" />
          <Count icon={<FcLock className="h-full w-full" />} value={m365MaxTotal.toFixed(0)} label="Maximum Score" />
          <Count icon={<FcPieChart className="h-full w-full" />} value={m365Percentage.toFixed(0) + '%'} label="Score Percentage" />
        </div>
      </Section>
      <Section>
        {/* score breakdown */}
        <Heading text="Score Breakdown" />
        <div className="mb-4 flex flex-col gap-4">
          {Object.keys(m365ScoreCategories).map(
            (category: string) =>
              !category.endsWith('Max') && (
                <div className="flex flex-col gap-1">
                  <div className="flex justify-between">
                    <div>{category}</div>
                    <div>{m365SecureScores[category].toFixed(0) + ' / ' + m365SecureScores[category + 'Max'].toFixed(0)}</div>
                  </div>
                  <ProgressBar
                    completed={m365SecureScores[category]}
                    maxCompleted={m365SecureScores[category + 'Max']}
                    className=""
                    isLabelVisible={false}
                    bgColor="var(--primary)"
                    baseBgColor="var(--border)"
                  />
                </div>
              )
          )}
        </div>
      </Section>
      <Section>
        <Heading text="Disclaimer" />
        <div className="text-sm">
          The Secure Score does not express an absolute measure of how likely you are to get breached. It expresses the extent to which you have adopted
          controls which can offset the risk of being breached. No service can guarantee that you will not be breached, and the Secure Score should not be
          interpreted as a guarantee in any way.
        </div>
      </Section>
    </Fragment>
  )
}

export default M365SecureScore
