import api from 'src/constants/api'

import useMutation, { type UseMutationOptions } from 'src/hooks/utils/useMutation'

interface UpdateSubscriptionParams {
  accountId: string
  tenantId: string
  subscriptionId: string
  quantity: number
  totalSpend?: number
}

const useUpdateSubscriptionMutation = (options?: UseMutationOptions<UpdateSubscriptionParams>) => {
  return useMutation(async ({ accountId, tenantId, subscriptionId, quantity, totalSpend }) => {
    const response = await api.patch(`/ms-api/subscriptions/account/${accountId}/${tenantId}/${subscriptionId}/quantity`, {
      Quantity: quantity,
      TotalNewSpend: totalSpend
    })
    return response.data
  }, options)
}

export default useUpdateSubscriptionMutation
