import useChangeAttachmentsQuery from '~/hooks/services/servicenow/queries/useChangeAttachmentsQuery'
import useChangeCommentsQuery from '~/hooks/services/servicenow/queries/useChangeCommentsQuery'
import useChangeQuery from '~/hooks/services/servicenow/queries/useChangeQuery'
import useAccount from '~/hooks/utils/useAccount'
import useOwnUser from '~/hooks/utils/useOwnUser'

import PortalDrawer from '~/components/Common/PortalDrawer'

import { useNavigate, useParams } from 'react-router-dom'

import ApprovalActions from './components/ApprovalActions'
import Attachments from './components/Attachments'
import ChangeHeader from './components/ChangeHeader'
import CommentForm from './components/CommentForm'
import CommentList from './components/CommentList'
import Properties from './components/Properties'

const ChangeDrawer = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { data: change, isLoading: isChangeLoading } = useChangeQuery(id)
  const { data: comments, isLoading: isCommentsLoading } = useChangeCommentsQuery(id)
  const { isLoading: isAttachmentsLoading } = useChangeAttachmentsQuery(id)

  const handleClose = () => {
    const currentPath = window.location.pathname
    const newPath = currentPath.substring(0, currentPath.lastIndexOf('/'))
    navigate(newPath)
  }

  const user = useOwnUser()
  const account = useAccount()

  const drawerIsHidden = !id || !user || !account
  const isLoading = isChangeLoading || isCommentsLoading || isAttachmentsLoading

  return (
    <PortalDrawer
      title={change ? change.number : 'Change'}
      loading={isLoading}
      hidden={drawerIsHidden}
      onClose={handleClose}
      actions={<ApprovalActions onClose={handleClose} />}
    >
      <ChangeHeader />

      <hr className="my-8 border-th-border" />
      <Attachments />

      <hr className="my-8 border-th-border" />
      <Properties />

      {change?.state !== 'Closed' && (
        <>
          <hr className="my-8 border-th-border" />
          <CommentForm />
        </>
      )}

      {comments && comments.length > 0 && (
        <>
          <hr className="my-8 border-th-border" />
          <CommentList />
        </>
      )}
    </PortalDrawer>
  )
}

export default ChangeDrawer
