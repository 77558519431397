import { useState } from 'react'

import { useCreateChangeCommentMutation } from '~/hooks/services/servicenow/mutations/useCreateChangeCommentMutation'

import { useParams } from 'react-router-dom'

export const useCommentForm = () => {
  const [comment, setComment] = useState('')
  const { id } = useParams()
  const { mutate: submitComment, isLoading } = useCreateChangeCommentMutation()

  const handleSubmit = () => {
    if (!comment.trim()) return
    submitComment(
      { changeId: id!, comment },
      {
        onSuccess: () => {
          setComment('')
        }
      }
    )
  }

  return {
    comment,
    setComment,
    handleSubmit,
    isSubmitting: isLoading
  }
}
