import { useQueryClient } from '@tanstack/react-query'

import { useCallback, useState } from 'react'

import { showAlert } from 'src/actions/alertsActions'

import useUpdateFileMutation from 'src/hooks/services/provide/mutations/useUpdateFileMutation'
import useUpdateFolderMutation from 'src/hooks/services/provide/mutations/useUpdateFolderMutation'
import useAccount from 'src/hooks/utils/useAccount'

import Input from 'src/components/Common/Input'
import ConfirmationModal from 'src/components/Modals/ConfirmationModal'

import { useDispatch, useSelector } from 'react-redux'
import { type FolderState } from 'src/reducers/folderReducer'

export const useFileFolderRename = () => {
  const { id: accountId } = useAccount()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const [renamingFileIds, setRenamingFileIds] = useState<string[]>([])
  const [renameModalState, setRenameModalState] = useState<{ isOpen: boolean; id: string; currentName: string; type: string }>({
    isOpen: false,
    id: '',
    currentName: '',
    type: ''
  })

  const search = useSelector((state: FolderState) => state.folder.search)
  const currentFolderId = useSelector((state: FolderState) => state.folder.currentFolderId)

  const { mutateAsync: updateFileMetadata } = useUpdateFileMutation({
    queryKeysToInvalidate: [['getFilesQuery', search || null, currentFolderId || null, accountId]],
    onSuccess: () => {
      queryClient.setQueryData(['getFilesQuery', search || null, currentFolderId || null, accountId], (prev: any) => {
        return {
          ...prev,
          files: prev.files.map((file: any) => {
            if (file.id === renameModalState.id) {
              return { ...file, fileName: renameModalState.currentName }
            }
            return file
          })
        }
      })
    }
  })

  const { mutateAsync: updateFolder } = useUpdateFolderMutation({
    queryKeysToInvalidate: [['getFilesQuery', search || null, currentFolderId || null, accountId]],
    onSuccess: () => {
      queryClient.setQueryData(['getFilesQuery', search || null, currentFolderId || null, accountId], (prev: any) => {
        return {
          ...prev,
          folders: prev.folders.map((folder: any) => {
            if (folder.id === renameModalState.id) {
              return { ...folder, name: renameModalState.currentName }
            }
            return folder
          })
        }
      })
    }
  })

  const handleFileRename = async (id: string, newFileName: string) => {
    setRenamingFileIds((prev) => [...prev, id])
    try {
      if (renameModalState.type === 'file') {
        await updateFileMetadata({ id: id, fileName: newFileName })
      } else {
        await updateFolder({ id: id, name: newFileName })
      }
      const type = renameModalState.type === 'file' ? 'File' : 'Folder'
      dispatch(showAlert({ component: 'FileRename', message: `${type} renamed successfully`, type: 'success' }))
    } catch (error) {
      console.error('Rename failed:', error)
      const type = renameModalState.type === 'file' ? 'File' : 'Folder'
      dispatch(showAlert({ component: 'FileRename', message: `Failed to rename ${type}`, type: 'error' }))
    } finally {
      setRenamingFileIds((prev) => prev.filter((id) => id !== id))
      closeRenameModal()
    }
  }

  const openRenameModal = useCallback((id: string, currentName: string, type: string) => {
    setTimeout(() => setRenameModalState({ isOpen: true, id, currentName, type }), 10)
  }, [])

  const closeRenameModal = () => {
    setRenameModalState({ isOpen: false, id: '', currentName: '', type: '' })
  }

  const RenameModal = (
    <ConfirmationModal
      isOpen={renameModalState.isOpen}
      onClose={closeRenameModal}
      onConfirm={() => handleFileRename(renameModalState.id, renameModalState.currentName)}
      title="Rename File"
      isLoading={renamingFileIds.includes(renameModalState.id)}
      countdownDuration={0}
    >
      <Input value={renameModalState.currentName} onChange={(e) => setRenameModalState({ ...renameModalState, currentName: e.target.value })} />
    </ConfirmationModal>
  )

  return {
    renamingFileIds,
    openRenameModal,
    RenameModal
  }
}
