import { useQuery } from '@tanstack/react-query'

import { type Tag, type Tenant } from 'src/types/azure'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'

import axios from 'axios'
import { type Moment } from 'moment'

interface UseDailyTotalsForTagsetProps {
  dateRange: [Moment, Moment]
  tenants: Array<Tenant>
  tags: Array<Tag>
  groupBy?: 'resourceLocation' | 'resourceGroup' | 'meterCategory' | null
}

type TotalSpend = {
  tenantId: string
  subscriptionId: string
  totalSpendGBP: number
  usageDate: string

  resourceLocation?: string
  resourceGroup?: string
  meterCategory?: string
}

const useDailyTotalsForTagset = ({ dateRange, tenants, tags, groupBy }: UseDailyTotalsForTagsetProps) => {
  const account = useAccount()

  const formattedDateRange = dateRange.map((date) => date.format('YYYY-MM-DD'))
  const tenantIds = tenants.map((tenant) => tenant.id)

  const result = useQuery({
    queryKey: ['daily-totals-for-tag-set', account.id, formattedDateRange[0], formattedDateRange[1], tenantIds, tags, groupBy],
    queryFn: async ({ signal }) => {
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by TanStack Query')
      })

      try {
        const response = await api.post(
          `/ms-api/v2/account/${account.id}/azure_usage/get_daily_totals_for_set_of_tags`,
          {
            tenantIds: tenantIds,
            tags: tags,
            startDate: formattedDateRange[0],
            endDate: formattedDateRange[1],
            groupByColumn: groupBy
          },
          { cancelToken: source.token }
        )
        return response.data as TotalSpend[]
      } catch {
        // Ignore internal error
        return null
      }
    },
    enabled: !!account.id && !!tenants.length
  })

  return { ...result, enabled: !!account.id && !!tenants.length }
}

export default useDailyTotalsForTagset
