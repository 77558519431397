import React, { useMemo } from 'react'

import { classNames } from 'src/utils/classNames'
import { formatCurrency } from 'src/utils/format'

import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

type SingleLineGraphProps = {
  data: {
    name: string
    value: number
    budget?: number
    projected?: boolean
  }[]
  cumulative?: boolean
  extraLines?: React.ReactNode
  className?: string
  title?: string
  interactive?: boolean
  yDomain?: [number, number]
  format?: 'currency' | 'none'
  isLoading?: boolean
}

const SingleLineGraph: React.FC<SingleLineGraphProps> = ({ data, cumulative, extraLines, className, title, interactive, yDomain, format, isLoading }) => {
  const altered = useMemo(() => {
    let cumulativeValue = 0
    let cumulativeBudget = 0
    const newData = [] as { name: string; value?: number; projectedValue?: number; budget?: number }[]

    let startedProjecting = false
    data.forEach((item) => {
      if (cumulative) cumulativeValue += item.value
      if (cumulative && item.budget) cumulativeBudget += item.budget
      const record = { ...item, value: cumulative ? cumulativeValue : item.value, budget: cumulative ? cumulativeBudget : item.budget }

      if (item.projected) {
        if (!startedProjecting) {
          startedProjecting = true
          newData.push({ name: record.name, projectedValue: record.value, budget: record.budget, value: record.value })
        } else newData.push({ name: record.name, projectedValue: record.value, budget: record.budget })
      } else newData.push({ name: record.name, value: record.value, budget: record.budget })
    })

    for (let i = 2; i < newData.length - 1; i++) {
      if (newData[i + 1]?.value !== undefined && newData[i].value === undefined) {
        newData[i].value = 0
      }

      if (newData[i - 1]?.value !== undefined && newData[i].value === undefined) {
        newData[i].value = 0
      }
    }

    return newData
  }, [data, cumulative])

  const renderTooltip = (tooltipProps: any) => {
    const payload = tooltipProps.payload as { name: string; value: number; payload: { name: string } }[]
    if (!payload || !payload.length) return <></>

    const total = payload.filter((p) => ['projectedValue', 'value'].includes(p.name))[0]?.value
    const budget = payload.filter((p) => p.name === 'budget')[0]?.value
    const date = payload.filter((p) => ['projectedValue', 'value'].includes(p.name))[0]?.payload.name

    return (
      <div className={classNames('flex gap-2 rounded-md bg-th-content p-4 shadow-md')}>
        <div className="px-2">
          <p>Total</p>
          <p className="font-bold text-th-text">{format === 'none' ? total : formatCurrency(Math.round(total), true, 0)}</p>
        </div>
        {!!budget && (
          <div className="px-2">
            <p className="capitalize">Budget</p>
            <p className="font-bold text-th-text">{format === 'none' ? budget : formatCurrency(Math.round(budget), true, 0)}</p>
          </div>
        )}
        <div className="px-2">
          <p>Date</p>
          <p className="font-bold text-th-text">{date}</p>
        </div>
      </div>
    )
  }

  return (
    <>
      {title && <p className="mb-10 font-headline text-2xl font-bold text-th-text">{title}</p>}
      <div className={classNames('relative w-full p-2', className ? className : 'h-60')}>
        {isLoading ? (
          <div className="pointer-events-none absolute inset-0 z-10 flex items-center justify-center bg-th-content">
            <div className="pb-8 pl-8 text-2xl text-th-text-secondary">
              <div className="spinner h-16 w-16" />
            </div>
          </div>
        ) : (
          !data.length && (
            <div className="pointer-events-none absolute inset-0 z-10 flex items-center justify-center bg-th-content">
              <p className="pb-8 pl-8 text-2xl text-th-text-secondary">No data to show</p>
            </div>
          )
        )}
        <ResponsiveContainer>
          <AreaChart data={altered}>
            <defs>
              <linearGradient id="blueGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#0087AE" stopOpacity={0.1} />
                <stop offset="95%" stopColor="#0087AE" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid vertical={false} />
            <XAxis dataKey="name" fontSize={12} axisLine={false} tickLine={false} interval={'equidistantPreserveStart'} />
            <YAxis
              tickFormatter={(value) => (format === 'none' ? value : formatCurrency(Math.round(parseFloat(value)), true, 0))}
              fontSize={12}
              axisLine={false}
              tickLine={false}
              tickCount={10}
              width={70}
              domain={
                yDomain ?? [
                  0,
                  parseInt(
                    (
                      Math.round(
                        Math.max(
                          Math.max(...altered.map((d) => (d.value || 0) * 1.1)),
                          Math.max(...altered.map((d) => (d.projectedValue || 0) * 1.1)),
                          Math.max(...altered.map((d) => (d.budget || 0) * 1.1))
                        ) * 25
                      ) / 25 ?? 0
                    ).toString()
                  )
                ]
              }
            />
            <Tooltip content={interactive ? renderTooltip : <></>} />
            {extraLines}
            <Area dataKey="budget" stroke="gray" strokeWidth={2} dot={false} isAnimationActive={false} fillOpacity={1} fill="none" />

            <Area dataKey="value" stroke="#0087AE" strokeWidth={2} dot={false} isAnimationActive={false} fillOpacity={1} fill="url(#blueGradient)" />
            <Area
              dataKey="projectedValue"
              stroke="#0087AE"
              strokeWidth={2}
              dot={false}
              isAnimationActive={false}
              strokeDasharray="3 3"
              fillOpacity={1}
              fill="url(#blueGradient)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </>
  )
}

export default SingleLineGraph
