import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useMutation, { type UseMutationOptions } from 'src/hooks/utils/useMutation'

const useDeleteFolderMutation = (options?: UseMutationOptions<{ folderId: string }>) => {
  const account = useAccount()

  return useMutation(
    async ({ folderId }) => {
      await api.post(`provide/${account.id}/folder/delete`, { id: folderId })
    },
    {
      ...options,
      errorMessage: 'An error occurred while deleting the folder.'
    }
  )
}

export default useDeleteFolderMutation
