import { withAITracking } from '@microsoft/applicationinsights-react-js'

import { Fragment, type FunctionComponent, useEffect, useMemo, useState } from 'react'

import { type Charge, type ReservedInstance, type SoftwareSubscription } from 'src/types/azure'

import { stopLoading } from 'src/actions/loadingActions'

import useDatePicker from 'src/hooks/composite/useDateRangePicker'
import useReservedInstancesQuery from 'src/hooks/services/servicenow/queries/useReservedInstancesQuery'
import useSoftwareSubscriptionsQuery from 'src/hooks/services/servicenow/queries/useSoftwareSubscriptionsQuery'
import { useTenantsQuery } from 'src/hooks/services/servicenow/queries/useTenantsQuery'

import Banner from 'src/components/Common/Banner'
import Button from 'src/components/Common/Button'
import Dropdown from 'src/components/Common/Dropdown'
import Heading from 'src/components/Common/Heading'
import Input from 'src/components/Common/Input'
import Section from 'src/components/Common/Section'
import Spacer from 'src/components/Common/Spacer'
import ReservedInstanceDrawer from 'src/components/Drawers/ReservedInstance'
import SoftwareSubscriptionDrawer from 'src/components/Drawers/SoftwareSubscription'
import SectionedArea from 'src/components/Graphs/SectionedArea'
import SectionedBar from 'src/components/Graphs/SectionedBar'
import SingleLineGraph from 'src/components/Graphs/SingleLineGraph'
import AzureRecurringSpendTable from 'src/components/Tables/AzureRecurringSpendTable'
import SectionedTable from 'src/components/Tables/SectionedTable'

import { format } from 'src/utils/format'

import { reactPlugin } from 'src/configs/appInsights'

import moment from 'moment'
import { CSVLink } from 'react-csv'
import { Iconly } from 'react-iconly'
import { FcBarChart, FcComboChart, FcDoughnutChart } from 'react-icons/fc'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { useDispatch } from 'react-redux'

import AzureTile from './AzureOverview/AzureTile/AzureTile'

const CHART_OPTIONS = [
  { label: 'Bar Chart', value: 'bar' },
  { label: 'Area Chart', value: 'area' },
  { label: 'Table', value: 'table' },
  { label: 'Line', value: 'line' }
]

const CUMULATIVE_OPTIONS = [
  { label: 'None', value: false },
  { label: 'Cumulative', value: true }
]

const STATE_FILTER_OPTIONS = [
  { label: 'Active', value: 'active' },
  { label: 'Expired', value: 'expired' },
  { label: 'Cancelled', value: 'cancelled' }
]

const DEFAULT_CHART = CHART_OPTIONS[0].value

const DEFAULT_CHART_CONFIG = {
  type: DEFAULT_CHART,
  cumulative: false,
  hideForecast: false,
  hideBudget: false
}

const DEFAULT_TABLE_CONFIG = {
  searchTerm: null as string | null,
  stateFilter: ['active']
}

// -~= Component =~- //
const AzureRecurringSpend: FunctionComponent = () => {
  // --~= State definitions =~-- //
  const [chartConfig, setChartConfig] = useState(DEFAULT_CHART_CONFIG)
  const [graphFilter, setGraphFilter] = useState<Array<string>>([])

  const [selectedSpend, setSelectedSpend] = useState<{ type: 'ri' | 'ss'; instance: ReservedInstance | SoftwareSubscription } | null>(null)

  const [tableConfig, setTableConfig] = useState(DEFAULT_TABLE_CONFIG)

  // -~= Helper Hooks =~- //
  const { DateRangePicker, PeriodDropdown, dateRange } = useDatePicker({
    preset: 'full'
  })

  const dispatch = useDispatch()
  useEffect(() => void dispatch(stopLoading()), [dispatch])

  const { data: tenants } = useTenantsQuery()
  const { data: softwareSubscriptions } = useSoftwareSubscriptionsQuery({ tenants })
  const { data: reservedInstances } = useReservedInstancesQuery({ tenants })

  const tableData = useMemo(() => {
    if (!reservedInstances || !softwareSubscriptions) return []

    const mappedReservedInstances = reservedInstances?.map((instance) => ({ ...instance, type: 'Reserved Instance' })) || []

    const mappedSoftwareSubscriptions =
      softwareSubscriptions?.map((subscription) => ({
        ...subscription,
        type: 'Software Subscription',
        expires: moment(subscription.charges?.[0]?.date).add(1, 'year') ?? null
      })) || []

    const allInstances = [...mappedReservedInstances, ...mappedSoftwareSubscriptions].filter((instance) => instance.name.trim() !== '')
    const filteredInstances = allInstances.filter((instance) => instance.name.toLowerCase().includes(tableConfig.searchTerm?.toLowerCase() ?? ''))

    const stateExtendedInstances = filteredInstances.map((instance) => {
      return {
        ...instance
      }
    })

    const filteredByStateInstances = stateExtendedInstances.filter((instance) => tableConfig.stateFilter.includes(instance.state?.toLowerCase()))

    return filteredByStateInstances
  }, [reservedInstances, softwareSubscriptions, tableConfig])

  const azureTileData = useMemo(() => {
    const averageMonthly = tableData
      .flatMap((instance) => {
        const isMonthly = format(instance.billing_plan, 'billingPlan') === 'Monthly'

        const charges =
          instance.charges
            ?.filter((charge) =>
              moment(charge.date).isSameOrAfter(
                moment()
                  .subtract(1, 'year')
                  .startOf(isMonthly ? 'month' : 'year')
              )
            )
            .toSorted((a, b) => moment(a.date).diff(moment(b.date))) ?? []

        const latestRelevant = isMonthly ? charges.slice(0, 3) : charges.slice(0, 1)
        const totalCost = latestRelevant.reduce((acc, curr) => acc + curr.cost, 0) / (latestRelevant.length || 1)
        return totalCost
      })
      .reduce((acc, curr) => acc + curr, 0)

    const costYearToDate = tableData
      .flatMap((instance) => {
        const charges =
          instance.charges
            ?.filter((charge) => moment(charge.date).isSameOrAfter(moment().startOf('year')))
            .toSorted((a, b) => moment(a.date).diff(moment(b.date))) ?? []

        const totalCost = charges.reduce((acc, curr) => acc + curr.cost, 0)
        return totalCost
      })
      .reduce((acc, curr) => acc + curr, 0)

    const projectedYearEndCost = averageMonthly * 12

    return { costYearToDate, averageMonthly, projectedYearEndCost }
  }, [tableData])

  const graphData = useMemo(() => {
    const instances = [...tableData]

    const filteredInstances = instances.filter((instance) => {
      const hasName = instance.name.trim() !== ''
      const hasRelevantCharge = instance.charges?.some((c) => moment(c.date).isBetween(dateRange[0], dateRange[1], 'day', '[)'))
      return hasName && hasRelevantCharge
    })

    const filteredCharges = filteredInstances.map((instance) => {
      const charges = instance.charges?.filter((c) => moment(c.date).isBetween(dateRange[0], dateRange[1], 'day', '[)')) ?? []
      return { ...instance, charges }
    })

    const uniqueNames = [...new Set(filteredCharges.map((d) => d.name))]
    const grouped = uniqueNames.map((name) => {
      const charges = filteredCharges.filter((d) => d.name === name).flatMap((d) => d.charges ?? [])
      return { name, charges }
    })

    const totalExtended = grouped.map((instance) => ({ ...instance, total: instance.charges?.reduce((acc, curr) => acc + curr.cost, 0) ?? 0 }))
    const sortedByTotal = totalExtended.sort((a, b) => b.total - a.total)

    const compressed: Array<{ name: string; charges: Array<Charge> | null }> =
      instances.length > 7
        ? [
            ...sortedByTotal.slice(0, 7).map((instance) => ({ name: instance.name, charges: instance.charges })),
            {
              name: 'Other',
              charges: sortedByTotal.slice(7).flatMap((instance) => instance.charges ?? [])
            }
          ]
        : sortedByTotal.map((instance) => ({ name: instance.name, charges: instance.charges }))

    const months = Array.from({ length: moment(dateRange[1]).diff(moment(dateRange[0]), 'months') + 1 }).map((_, i) =>
      moment(dateRange[0]).add(i, 'months').format('YYYY-MM')
    )
    const names = compressed.map((d) => d.name)

    const data = months.map((month) => {
      const values = names.map((name) => {
        const _charges = compressed.filter((d) => d.name === name).flatMap((d) => d.charges ?? [])
        const _chargesInMonth = _charges.filter((c) => c.date.slice(0, 7) === month)
        const _total = _chargesInMonth.reduce((acc, curr) => acc + curr.cost, 0)
        return { name, value: _total, forecast: false }
      })
      const displayDate = moment(month).format('MMM YYYY')
      return { date: displayDate, values }
    })

    if (!data.some((d) => d.values.length > 0)) return [] // has no data

    if (!chartConfig.cumulative) return data

    const cumulativeData = data.map((d) => {
      const values = d.values.map((v, i) => {
        const previous = data.slice(0, data.indexOf(d)).reduce((acc, curr) => acc + curr.values[i].value, 0)
        return { ...v, value: v.value + previous }
      })
      return { ...d, values }
    })

    return cumulativeData
  }, [tableData, dateRange, chartConfig.cumulative])

  const addedForecast = useMemo(() => {
    if (graphData.length === 0) return graphData

    const data = graphData.map((d) => {
      return { ...d, values: d.values.flatMap((v) => [v, { value: 0, name: v.name, forecast: true }]) }
    })

    return data
  }, [graphData])

  const isLoadingAzureTiles = !reservedInstances || !softwareSubscriptions

  const handleRowClick = (row: (typeof tableData)[number]) => {
    const rowTypeMap = { 'Reserved Instance': 'ri', 'Software Subscription': 'ss' } as Record<string, 'ri' | 'ss'>
    setSelectedSpend({ instance: row, type: rowTypeMap[row.type]! })
  }

  return (
    <Fragment>
      <Section>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-1 mdlg:grid-cols-2 xl:grid-cols-3">
          <AzureTile
            icon={<FcBarChart className="h-full w-full" />}
            value={azureTileData.costYearToDate}
            label="Spend Year To Date"
            isLoading={isLoadingAzureTiles}
          />
          <AzureTile
            icon={<FcDoughnutChart className="h-full w-full" />}
            value={azureTileData.averageMonthly}
            label="Average Monthly Spend"
            isLoading={isLoadingAzureTiles}
          />
          <AzureTile
            icon={<FcComboChart className="h-full w-full" />}
            value={azureTileData.projectedYearEndCost}
            label="Projected Yearly Spend"
            isLoading={isLoadingAzureTiles}
          />
        </div>
      </Section>
      <Section>
        <Heading text="Recurring Cost">
          <Spacer className="hidden sm:block" />
          {tableConfig.searchTerm !== null ? (
            <>
              <Input
                type="text"
                name="search"
                placeholder="Search"
                autoFocus
                onChange={(e) => setTableConfig((prev) => ({ ...prev, searchTerm: (e.target as HTMLInputElement).value }))}
              />
              <IoCloseCircleOutline
                className="h-6 w-6 cursor-pointer"
                onClick={() => {
                  setTableConfig((prev) => ({ ...prev, searchTerm: null }))
                }}
              />
            </>
          ) : (
            <Iconly
              name="Search"
              set="light"
              className="cursor-pointer"
              onClick={() => {
                setTableConfig((prev) => ({ ...prev, searchTerm: '' }))
              }}
            />
          )}
          <Dropdown
            options={STATE_FILTER_OPTIONS}
            value={STATE_FILTER_OPTIONS.filter((o) => tableConfig.stateFilter.includes(o.value))}
            variant={Dropdown.variant.DEFAULT}
            label="State"
            bordered
            multi
            onChange={(option) => setTableConfig({ ...tableConfig, stateFilter: option.map((o: { value: string }) => o.value) })}
          />
          <Button variant="primary">
            <CSVLink
              data={tableData.map((record) => {
                const { charges, account: _a, tenant_sys_id: _t, cost, ...rest } = record as ReservedInstance
                return { ...rest, total_charge: cost, last_charge: charges?.[0].cost ?? 0 }
              })}
              filename={'recurring-spend.csv'}
            >
              Export
            </CSVLink>
          </Button>
        </Heading>

        <AzureRecurringSpendTable data={tableData} onRowClick={(row) => handleRowClick(row as (typeof tableData)[number])} />
      </Section>
      <Section>
        <Heading text="Spend Overview" />
        <Banner className="mb-8 mt-4">
          {PeriodDropdown}
          {DateRangePicker}
          <Spacer className="hidden sm:block" />
          <Button
            onClick={() => {
              setGraphFilter([])
              setChartConfig(DEFAULT_CHART_CONFIG)
            }}
            disabled={JSON.stringify(chartConfig) === JSON.stringify(DEFAULT_CHART_CONFIG)}
            bordered
          >
            Reset
          </Button>
          <Dropdown
            options={CUMULATIVE_OPTIONS}
            value={CUMULATIVE_OPTIONS.find((o) => o.value === chartConfig.cumulative)}
            variant={Dropdown.variant.DEFAULT}
            label="Aggregate"
            bordered
            multi={false}
            disableAll
            onChange={(option) => setChartConfig({ ...chartConfig, cumulative: option.value })}
          />

          <Dropdown
            options={CHART_OPTIONS}
            value={CHART_OPTIONS.find((o) => o.value === chartConfig.type)}
            variant={Dropdown.variant.DEFAULT}
            label="View As"
            bordered
            multi={false}
            disableAll
            onChange={(option) => setChartConfig({ ...chartConfig, type: option.value })}
          />
        </Banner>
        {chartConfig.type === 'bar' && (
          <SectionedBar
            data={addedForecast}
            filtered={graphFilter}
            setFiltered={setGraphFilter}
            removeForecast
            hideBudget={chartConfig.hideBudget}
            hideForecast={chartConfig.hideForecast}
            onHideChange={(hideForecast, hideBudget) => setChartConfig({ ...chartConfig, hideForecast, hideBudget })}
          />
        )}
        {chartConfig.type === 'area' && (
          <SectionedArea
            data={addedForecast}
            filtered={graphFilter}
            setFiltered={setGraphFilter}
            removeForecast
            hideBudget={chartConfig.hideBudget}
            hideForecast={chartConfig.hideForecast}
            onHideChange={(hideForecast, hideBudget) => setChartConfig({ ...chartConfig, hideForecast, hideBudget })}
          />
        )}
        {chartConfig.type === 'table' && (
          <SectionedTable
            data={graphData.map((d) => ({ ...d, values: d.values.map((v) => ({ ...v, forecast: false })) }))}
            cumulative={chartConfig.cumulative}
            filtered={graphFilter}
            setFiltered={setGraphFilter}
          />
        )}

        {chartConfig.type === 'line' && (
          <SingleLineGraph
            data={graphData.map((d) => ({ name: d.date, value: d.values.reduce((acc, curr) => acc + curr.value, 0) }))}
            className="h-80 md:h-96 lg:h-[30rem]"
            interactive
          />
        )}
      </Section>
      {selectedSpend?.type === 'ri' && selectedSpend.instance && (
        <ReservedInstanceDrawer instance={selectedSpend.instance as ReservedInstance} onClose={() => setSelectedSpend(null)} />
      )}
      {selectedSpend?.type === 'ss' && selectedSpend.instance && (
        <SoftwareSubscriptionDrawer instance={selectedSpend.instance as SoftwareSubscription} onClose={() => setSelectedSpend(null)} />
      )}
    </Fragment>
  )
}

const AzureRecurringSpendWithTracking = withAITracking(reactPlugin, AzureRecurringSpend, 'Azure Recurring Spend')
export default AzureRecurringSpendWithTracking
