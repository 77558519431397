import { Fragment, type FunctionComponent, useEffect, useState } from 'react'

import { type Video } from '../../types/training'

import { Iconly } from 'react-iconly'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { useDispatch } from 'react-redux'

import { stopLoading } from '../../actions/loadingActions'
import Banner from '../../components/Common/Banner'
import Input from '../../components/Common/Input'
import Section from '../../components/Common/Section'
import Spacer from '../../components/Common/Spacer'
import TrainingTable from '../../components/Tables/TrainingTable'

const videos: Video[] = [
  {
    title: 'Provide™ Portal - Dashboard and New Ticket',
    description: 'An introduction to the Dashboard and New Ticket feature in Provide',
    type: 'Video',
    category: 'Provide',
    duration: '2:21',
    link: 'https://www.youtube.com/watch?v=KiJaNB3bvIM&list=PLBgraSeJpOOD396F6giiN6vIwCWxYjkEO&index=2'
  },
  {
    title: 'Provide™ Portal - Account, Contacts',
    description: 'An introduction to Contacts in Provide',
    type: 'Video',
    category: 'Provide',
    duration: '2:10',
    link: 'https://www.youtube.com/watch?v=_5yg_7SWDZk&list=PLBgraSeJpOOD396F6giiN6vIwCWxYjkEO&index=2'
  },
  {
    title: 'Provide™ Portal - Account, Invoices',
    description: 'An introduction to Invoices in Provide',
    type: 'Video',
    category: 'Provide',
    duration: '1:00',
    link: 'https://www.youtube.com/watch?v=ciWnSc7Xndg&list=PLBgraSeJpOOD396F6giiN6vIwCWxYjkEO&index=3'
  },
  {
    title: 'Provide™ Portal - Account, Credit Notes',
    description: 'An introduction to Credit Notes in Provide',
    type: 'Video',
    category: 'Provide',
    duration: '0:57',
    link: 'https://www.youtube.com/watch?v=6_uPFNOX5fw&list=PLBgraSeJpOOD396F6giiN6vIwCWxYjkEO&index=4'
  },
  {
    title: 'Provide™ Portal - Account, Quotes',
    description: 'An introduction to Quotes in Provide',
    type: 'Video',
    category: 'Provide',
    duration: '2:25',
    link: 'https://www.youtube.com/watch?v=3wRarmB-Alg&list=PLBgraSeJpOOD396F6giiN6vIwCWxYjkEO&index=5'
  },
  {
    title: 'Provide™ Portal - Account, Services and Orders',
    description: 'An introduction to Services and Orders in Provide',
    type: 'Video',
    category: 'Provide',
    duration: '2:11',
    link: 'https://www.youtube.com/watch?v=K1N89BzThi0&list=PLBgraSeJpOOD396F6giiN6vIwCWxYjkEO&index=6'
  },
  {
    title: 'Provide™ Portal - Azure, Overview',
    description: 'An introduction to your Azure Overview in Provide',
    type: 'Video',
    category: 'Provide',
    duration: '0:51',
    link: 'https://www.youtube.com/watch?v=7ouaAmoXNwo&list=PLBgraSeJpOOD396F6giiN6vIwCWxYjkEO&index=7'
  },
  {
    title: 'Provide™ Portal - Azure, Subscription and Resource Usage',
    description: 'An introduction to your Azure Subscription and Resource Usage in Provide',
    type: 'Video',
    category: 'Provide',
    duration: '1:35',
    link: 'https://www.youtube.com/watch?v=ah2c15aredU&list=PLBgraSeJpOOD396F6giiN6vIwCWxYjkEO&index=8'
  },
  {
    title: 'Provide™ Portal - Azure, Azure Advisor',
    description: 'An introduction to Azure Advisor in Provide',
    type: 'Video',
    category: 'Provide',
    duration: '1:10',
    link: 'https://www.youtube.com/watch?v=z91KnYYhRok&list=PLBgraSeJpOOD396F6giiN6vIwCWxYjkEO&index=9'
  },
  {
    title: 'Provide™ Portal - Microsoft 365, Subscriptions Products Cart',
    description: 'An introduction to Microsoft 365 in Provide',
    type: 'Video',
    category: 'Provide',
    duration: '1:36',
    link: 'https://www.youtube.com/watch?v=qDUtCaDGhdk&list=PLBgraSeJpOOD396F6giiN6vIwCWxYjkEO&index=10'
  },
  {
    title: 'Provide™ Portal - Support, Tickets',
    description: 'An introduction to Tickets in Provide',
    type: 'Video',
    category: 'Provide',
    duration: '1:35',
    link: 'https://www.youtube.com/watch?v=at_pOtPBDgY&list=PLBgraSeJpOOD396F6giiN6vIwCWxYjkEO&index=11'
  },
  {
    title: 'Provide™ Portal - Security, Azure and Microsoft Secure Score',
    description: 'An introduction to Secure Scores in Provide',
    type: 'Video',
    category: 'Provide',
    duration: '1:29',
    link: 'https://www.youtube.com/watch?v=KgWDSr0aMYE&list=PLBgraSeJpOOD396F6giiN6vIwCWxYjkEO&index=12'
  },
  {
    title: 'Provide™ Portal - Send Feedback',
    description: 'How to send feedback in Provide',
    type: 'Video',
    category: 'Provide',
    duration: '0:50',
    link: 'https://www.youtube.com/watch?v=8G4GM1oQ_RI&list=PLBgraSeJpOOD396F6giiN6vIwCWxYjkEO&index=13'
  },
  {
    title: 'Provide™ Portal - Security, Identity Access Management Introduction',
    description: 'An introduction to Access Management in Provide',
    type: 'Video',
    category: 'Provide',
    duration: '8:15',
    link: 'https://www.youtube.com/watch?v=XPV12rQv7E4&list=PLBgraSeJpOOD396F6giiN6vIwCWxYjkEO&index=14'
  }
]

const Training: FunctionComponent = (): JSX.Element => {
  const [showSearch, setShowSearch] = useState<boolean>(false)
  const [filtered, setFiltered] = useState<Video[]>(videos)

  const dispatch = useDispatch()

  /* filter the tickets by search term */
  const filter = (value: string) => {
    const searchTerm = value.toLowerCase()

    setFiltered(
      videos.filter((videos: Video) => videos.description.toLowerCase().indexOf(searchTerm) !== -1 || videos.title.toLowerCase().indexOf(searchTerm) !== -1)
    )
  }

  useEffect(() => {
    dispatch(stopLoading())
  })

  return (
    <Fragment>
      <Section>
        Welcome to Cloud Direct Training. The recordings found here are for your upskill. To provide feedback please use the "Send Feedback" button.
      </Section>
      <Section>
        <Banner>
          <Spacer />
          {/* search */}
          {showSearch ? (
            <Fragment>
              <Input type="text" name="search" placeholder="Search" autoFocus onChange={(e: React.ChangeEvent<HTMLInputElement>) => filter(e.target.value)} />
              <IoCloseCircleOutline className="h-6 w-6 cursor-pointer" onClick={() => setShowSearch(false)} />
            </Fragment>
          ) : (
            <Iconly name="Search" set="light" className="cursor-pointer" onClick={() => setShowSearch(true)} />
          )}
        </Banner>
        <Spacer />
        <TrainingTable videos={filtered} />
      </Section>
    </Fragment>
  )
}

export default Training
