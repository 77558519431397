import { type ColumnDef } from '@tanstack/react-table'

import { type FunctionComponent, useCallback, useMemo } from 'react'

import { type Account } from '../../types/account'
import { type Attachment } from '../../types/attachment'
import { type Quote } from '../../types/quote'

import moment from 'moment'
import { Iconly } from 'react-iconly'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { showAlert } from '../../actions/alertsActions'
import { closeDrawers, openQuoteDrawer } from '../../actions/drawerActions'
import { startLoading, stopLoading } from '../../actions/loadingActions'

/* api */
import api from '../../constants/api'
import Table from '../Common/Table/Table'

interface Props {
  quotes: Quote[]
}

const QuoteTable: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { quotes } = props

  const account: Account = useSelector((state: RootStateOrAny) => state.account)

  const dispatch = useDispatch()

  /* download pdf */
  const downloadPDF = useCallback(
    (event: React.MouseEvent, quote_id: string, quote_number: string) => {
      event.stopPropagation()
      if (quote_number) {
        dispatch(startLoading())

        api
          .get(`/servicenow/account/${account.id}/u_sales_quote/${quote_id}/attachments`)
          .then((response) => {
            let creditnote_exists = false
            /* check if the pdf exists as an attachment */
            response.data.forEach(async (attachment: Attachment) => {
              if (attachment.name.toLowerCase() === (quote_number + '.pdf').toLowerCase()) {
                creditnote_exists = true
                /* download the attachment  */
                return await api
                  .get(`/servicenow/account/${account.id}/u_sales_quote/${quote_id}/attachment/${attachment.id}`, {
                    responseType: 'blob'
                  })
                  .then((response) => {
                    const blob = new Blob([response.data])
                    const url = window.URL.createObjectURL(blob)
                    const a = document.createElement('a')

                    a.style.display = 'none'
                    a.href = url
                    a.download = attachment.name

                    document.body.appendChild(a)
                    a.click()

                    dispatch(stopLoading())

                    return window.URL.revokeObjectURL(url)
                  })
                  .catch((error) => {
                    /* alert the user that the download failed */
                    dispatch(
                      showAlert({
                        type: 'error',
                        message: 'We were unable to download the PDF for quote ' + quote_number + '.',
                        component: 'QuoteTable',
                        error
                      })
                    )

                    dispatch(stopLoading())
                  })
              }
            })

            /* alert the user that the pdf couldn't be found */
            if (!creditnote_exists) {
              dispatch(
                showAlert({
                  type: 'info',
                  message: "We couldn't find the PDF for quote " + quote_number + '. It may not be ready yet.',
                  component: 'QuoteTable'
                })
              )
            }

            dispatch(stopLoading())
          })
          .catch((error) => {
            dispatch(
              showAlert({
                type: 'error',
                message: 'We were unable to retrieve the PDF for credit note ' + quote_number + '.',
                component: 'QuoteTable',
                error
              })
            )

            dispatch(stopLoading())
          })
      }
    },
    [account.id, dispatch]
  )

  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'number',
          header: 'Number'
        },
        {
          accessorKey: 'ordered_by',
          header: 'Ordered By'
        },
        {
          accessorKey: 'sales_person',
          header: 'Sales Person'
        },
        {
          accessorKey: 'created',
          header: 'Quote Date',
          cell: (info: any) => moment(info.getValue()).format('DD/MM/YY'),
          sortingFn: (a: any, b: any, id: string) => moment(a.getValue(id)).unix() - moment(b.getValue(id)).unix()
        },
        {
          accessorKey: 'state',
          header: 'State'
        },
        {
          accessorKey: 'one_time',
          header: 'One Time (£)'
        },
        {
          accessorKey: 'mrr',
          header: 'Monthly Recurring (£)'
        },
        {
          accessorKey: 'pdf',
          header: 'PDF',
          cell: (info) => (
            <Iconly
              set="light"
              name="PaperDownload"
              className="cursor-pointer"
              onClick={(event: React.MouseEvent) => downloadPDF(event, info.row.original.id, info.row.original.number)}
            />
          )
        }
      ] satisfies ColumnDef<(typeof quotes)[number]>[],
    [downloadPDF]
  )

  return (
    <Table
      data={quotes}
      columns={columns}
      onRowClick={(row) => {
        dispatch(closeDrawers())
        dispatch(openQuoteDrawer(row))
      }}
    />
  )
}

export default QuoteTable
