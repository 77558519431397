import { formatCurrency } from 'src/utils/format'

import { PiTrendDownBold, PiTrendUpBold, PiWarningFill } from 'react-icons/pi'

import Popover from '../Common/Popover'

type NullableNumber = number | undefined | null

type PriceDetailsCardProps = {
  titles?: [string, string] // [title, subtitle]

  title?: string
  subtitle?: string

  prices?: [NullableNumber, NullableNumber, NullableNumber] // [mainPrice, subPrice, changePrice]

  mainPrice?: NullableNumber
  subPrice?: NullableNumber
  changePrice?: NullableNumber

  problemText?: string

  subTextClassName?: string
  includeChangeDirectionInMain?: boolean
}

const PriceDetailsCard: React.FC<PriceDetailsCardProps> = (props) => {
  // Title string formatting
  const mainProvidedTitle = props.title || props.titles?.[0]
  const subProvidedTitle = props.subtitle || props.titles?.[1]

  // Price string formatting
  const mainProvidedPrice = props.mainPrice || props.prices?.[0]
  const subProvidedPrice = props.subPrice || props.prices?.[1]
  const changeProvidedPrice = props.changePrice || props.prices?.[2]

  const mainPrice = props.includeChangeDirectionInMain ? Math.abs(Math.round(mainProvidedPrice ?? 0)) : Math.round(mainProvidedPrice ?? 0)

  const main = mainProvidedPrice !== undefined && mainProvidedPrice !== null ? formatCurrency(mainPrice) : 'Loading...'
  const sub = subProvidedPrice !== undefined && subProvidedPrice !== null ? formatCurrency(Math.round(subProvidedPrice)) : 'Loading...'
  const change = changeProvidedPrice !== undefined && changeProvidedPrice !== null ? formatCurrency(Math.round(Math.abs(changeProvidedPrice))) : ''

  const changeDirection = changeProvidedPrice ? (changeProvidedPrice > 0 ? '-' : '+') : mainProvidedPrice ? (mainProvidedPrice <= 0 ? '-' : '+') : ''
  const changeComponent = changeDirection === '+' ? <PiTrendUpBold className="h-7 w-7 text-th-red" /> : <PiTrendDownBold className="h-7 w-7 text-th-green" />

  return (
    <div className="space-y-1 rounded-xl border border-th-border bg-th-content px-8 py-6">
      <p className="font-semibold text-th-text-secondary">{mainProvidedTitle}</p>
      <div className="flex items-center gap-3">
        {changeComponent}
        <p className="whitespace-nowrap text-3xl font-extrabold text-th-text">{props.includeChangeDirectionInMain ? changeDirection + main : main}</p>
      </div>
      <p className="text-sm font-semibold text-th-text-secondary">{subProvidedTitle}</p>
      <div className="flex items-center gap-2">
        {props.problemText && (
          <Popover text={props.problemText}>
            <PiWarningFill className="h-4 w-4 text-th-red" />
          </Popover>
        )}
        <p className={`${!changeProvidedPrice ? '' : changeDirection === '+' ? 'text-th-red' : 'text-th-green'} font-extrabold`}>
          <span className={props.subTextClassName}>{sub}</span> {change ? `(${changeDirection} ${change})` : ''}
        </p>
      </div>
    </div>
  )
}

export default PriceDetailsCard
