import { useMsal } from '@azure/msal-react'

import Button from '../Common/Button'

type ErrorBoundaryMessageProps = {
  fullPage?: boolean
}

const ErrorBoundaryMessage: React.FC<ErrorBoundaryMessageProps> = (props) => {
  const { instance } = useMsal()

  return (
    <div className={`flex items-center justify-center bg-th-content ${props.fullPage ? 'fixed inset-0 z-50' : 'h-full w-full'}`}>
      <div className="flex flex-col items-center gap-4">
        <p className="text-th-text">An unexpected error occured.</p>
        <Button onClick={() => instance.logout()} variant="primary">
          Return to Login
        </Button>
      </div>
    </div>
  )
}

export default ErrorBoundaryMessage
