import { CategoryFilter } from './CategoryFilter'
import { ClassificationFilter } from './ClassificationFilter'
import { StateFilter } from './StateFilter'

export const ChangeScheduleFilters = () => {
  return (
    <>
      <StateFilter />
      <CategoryFilter />
      <ClassificationFilter />
    </>
  )
}
