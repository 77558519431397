import { type ColumnDef } from '@tanstack/react-table'

import React, { type FunctionComponent, useMemo } from 'react'

import { type Video } from '../../types/training'

import { FaBook, FaVideo } from 'react-icons/fa'

import Table from '../Common/Table/Table'

interface Props {
  videos: Video[]
}

const TrainingTable: FunctionComponent<Props> = ({ videos }: Props): JSX.Element => {
  const columns = useMemo<ColumnDef<Video>[]>(
    () => [
      {
        accessorKey: 'icon',
        header: '',
        cell: (info) => (info.row.original.type === 'Article' ? <FaBook /> : <FaVideo />)
      },
      {
        accessorKey: 'title',
        header: 'Course Title'
      },
      {
        accessorKey: 'description',
        header: 'Description'
      },
      {
        accessorKey: 'category',
        header: 'Category'
      },
      {
        accessorKey: 'type',
        header: 'Type'
      },
      {
        accessorKey: 'duration',
        header: 'Duration'
      }
    ],
    []
  )

  return (
    <Table
      data={videos}
      columns={columns}
      onRowClick={(row) => {
        window.open(row.link, '_blank')
      }}
    />
  )
}

export default TrainingTable
