import { useMemo } from 'react'

import moment from 'moment'

type TotalSpend = {
  tenantId: string
  subscriptionId: string
  totalSpendGBP: number
  usageDate: string

  resourceLocation?: string
  resourceGroup?: string
  meterCategory?: string
}

interface UseTimeseriesDataShape {
  data: TotalSpend[]
  dateRange: [moment.Moment, moment.Moment]
  forceDaily?: boolean
}

const useTimeseriesDataShape = ({ data, dateRange, forceDaily }: UseTimeseriesDataShape) => {
  const timeseries = useMemo(() => {
    if (!data?.length) return []

    // Build up main array
    const days = dateRange[1].diff(dateRange[0], 'days')
    const months = dateRange[1].diff(dateRange[0], 'months')

    const loopLimit = months > 2 && !forceDaily ? months : days

    const contentfulDateRange =
      (months > 2 && !forceDaily
        ? moment(data?.at(0)!.usageDate).diff(moment(data?.at(-1)!.usageDate), 'months')
        : moment(data?.at(0)!.usageDate).diff(moment(data?.at(-1)!.usageDate), 'days')) || 1

    const average = data?.reduce((acc, curr) => acc + curr.totalSpendGBP, 0) / Math.abs(contentfulDateRange - 1)

    const output = []
    for (let i = 0; i <= loopLimit; i++) {
      const relativeStartDate = months > 2 && !forceDaily ? moment(dateRange[0]).add(i, 'months') : moment(dateRange[0]).add(i, 'days')
      const relativeEndDate = months > 2 && !forceDaily ? moment(dateRange[0]).add(i + 1, 'months') : moment(dateRange[0]).add(i + 1, 'days')

      const name = months > 2 && !forceDaily ? relativeStartDate.format('MMM YYYY') : relativeStartDate.format('DD MMM')

      const startDate = months > 2 && !forceDaily ? relativeStartDate.startOf('month') : relativeStartDate.startOf('day')
      const endDate = months > 2 && !forceDaily ? relativeEndDate.startOf('month') : relativeEndDate.startOf('day')

      const values = data.filter((d) => moment(d.usageDate).isBetween(startDate, endDate, 'day', '[)'))
      const total = values?.length ? (values?.reduce((acc, curr) => acc + curr.totalSpendGBP, 0) ?? 0) : (average ?? 0)

      if (!values?.length && output.length) output[output.length - 1].projected = true

      const entry = {
        date: name,
        value: total,
        projected: !values?.length
      }

      output.push(entry)
    }

    return output
  }, [data, dateRange, forceDaily])

  return timeseries
}

export default useTimeseriesDataShape
