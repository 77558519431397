import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useMutation, { type UseMutationOptions } from 'src/hooks/utils/useMutation'

interface UpdateCartItemQuantityMutation {
  tenantId: string
  cartId: string
  itemId: number
  quantity: number
}

const useUpdateCartItemQuantityMutation = (options?: UseMutationOptions<UpdateCartItemQuantityMutation>) => {
  const { id: accountId } = useAccount()

  return useMutation(
    async ({ tenantId, cartId, quantity, itemId }) => {
      const response = await api.put(`/ms-api/orders/account/${accountId}/${tenantId}/cart/${cartId}/item/${itemId}/quantity/${quantity}`)
      return response.data
    },
    {
      ...options
    }
  )
}

export default useUpdateCartItemQuantityMutation
