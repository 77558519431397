import useTrendsInsights from '../services/microsoft/queries/useTrendsInsights'
import useUserData from '../services/provide/queries/useUserData'

const useNewTrendsInsights = () => {
  const { data, isLoading, update } = useUserData<Array<string>>('viewed_trends_insights')

  const allTrendsInsights = useTrendsInsights({ cutoffCoefficientMultiplier: 2 })
  const newTrendsInsights = allTrendsInsights?.filter((insight) => !data?.includes(insight.id))

  const readInsight = (insightId: string) => update([...(data || []), insightId])

  return {
    data: newTrendsInsights,
    isLoading,
    readInsight
  }
}

export default useNewTrendsInsights
