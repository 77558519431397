import { useMemo, useState } from 'react'

import { classNames } from 'src/utils/classNames'
import { getBlueGradientColors, getRainbowColors } from 'src/utils/colors'
import { formatCurrency } from 'src/utils/format'

import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'

type PieGraphProps = {
  title: string
  data: { name: string; value: number }[]
  colorful?: boolean
  shouldFormatCurrency?: boolean
}

const PieGraph: React.FC<PieGraphProps> = (props) => {
  const { shouldFormatCurrency = true } = props
  const [filteredNames, setFilteredNames] = useState<Set<string>>(new Set())

  const colors = useMemo(() => {
    const getColors = props.colorful ? getRainbowColors : getBlueGradientColors
    const _colors = getColors(props.data.length)
    return _colors.reduce((acc, cur, idx) => ({ ...acc, [props.data[idx].name]: cur }), {} as Record<string, string>)
  }, [props.data, props.colorful])

  const filteredData = useMemo(() => {
    return filteredNames.size ? props.data.filter((item) => filteredNames.has(item.name)) : props.data
  }, [props.data, filteredNames])

  const renderTooltip = (tooltipProps: any) => {
    const payload = tooltipProps.payload as { name: string; value: number }[]
    if (!payload || !payload.length) return <></>

    const entry = (payload[0] as unknown as { payload: { payload: { name: string; value: number } } }).payload.payload

    return (
      <div className={classNames('rounded-md bg-th-content px-4 py-2 shadow-md')}>
        <p>{entry.name}</p>
        <p className="font-bold text-th-text">{shouldFormatCurrency ? formatCurrency(Math.round(entry.value), true, 0) : entry.value.toPrecision(4)}</p>
      </div>
    )
  }

  const [hovered, setHovered] = useState<string | null>(null)

  return (
    <div className="space-y-1 rounded-xl border border-th-border bg-th-content px-4 py-3">
      <p className="mb-6 font-headline text-2xl font-bold text-th-text">{props.title}</p>
      <div className="aspect-square w-full">
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={filteredData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={'60%'}
              focusable={false}
              tabIndex={undefined}
              cornerRadius={4}
              animationDuration={0}
            >
              {filteredData.map((_, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[filteredData[index].name]}
                  fillOpacity={hovered && filteredData[index].name !== hovered ? 0.5 : 1}
                  className={`${hovered === filteredData[index].name ? 'identify' : ''} cursor-pointer`}
                  onClick={() =>
                    setFilteredNames((prev) =>
                      prev.has(filteredData[index].name)
                        ? new Set([...prev].filter((name) => name !== filteredData[index].name))
                        : new Set([...prev, filteredData[index].name])
                    )
                  }
                  focusable={false}
                  tabIndex={undefined}
                  onMouseOver={() => setHovered(filteredData[index].name)}
                  onMouseOut={() => setHovered(null)}
                />
              ))}
            </Pie>
            <Tooltip content={renderTooltip} />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="flex flex-col gap-x-6 gap-y-2 text-sm">
        {props.data.map((item, idx) => (
          <button
            key={idx}
            className={classNames(
              'flex items-center gap-2 whitespace-nowrap rounded-md px-2 py-0.5 duration-100',
              filteredNames.has(item.name) ? 'bg-th-border' : 'hover:bg-th-border'
            )}
            onClick={() =>
              setFilteredNames((prev) => (prev.has(item.name) ? new Set([...prev].filter((name) => name !== item.name)) : new Set([...prev, item.name])))
            }
            title={item.name}
          >
            <div className={`h-3 w-3 shrink-0 rounded-full ${props.colorful ? 'bg-th-border' : ''}`} style={{ backgroundColor: colors[item.name] }} />
            <span className="overflow-hidden overflow-ellipsis whitespace-nowrap font-bold">{item.name}</span>
          </button>
        ))}
      </div>
    </div>
  )
}

export default PieGraph
