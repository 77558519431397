import { fileTypeFromBlob } from 'file-type'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useMutation, { type UseMutationOptions } from 'src/hooks/utils/useMutation'

const useUploadSasUriMutation = (
  options?: UseMutationOptions<{ file: File; folderId?: string | null; shouldNotify?: boolean }, { sasUri: string; documentId: string; folderId: string }>
) => {
  const account = useAccount()

  return useMutation(
    async ({ file, folderId, shouldNotify }) => {
      let contentType: string

      try {
        const fileTypeResult = await fileTypeFromBlob(file)
        contentType = fileTypeResult?.mime || file.type || 'application/octet-stream'
      } catch (error) {
        console.warn('Could not automatically determine file type:', error)
        contentType = file.type || 'application/octet-stream'
      }

      const response = await api.post(`provide/${account.id}/file/upload`, {
        fileName: file.name,
        contentType,
        contentLength: file.size,
        folderId,
        shouldNotify
      })
      return response.data
    },
    {
      ...options,
      errorMessage: 'An error occurred while getting the upload url.'
    }
  )
}

export default useUploadSasUriMutation
