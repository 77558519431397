import { type FunctionComponent } from 'react'

import { Pie, PieChart as ReChartsPieChart, ResponsiveContainer, Tooltip } from 'recharts'

interface ChartData {
  name: string
  cost: number
  fill: string
}

interface Props {
  data: ChartData[]
}

const PieChart: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data } = props

  return (
    <div className="relative">
      <ResponsiveContainer height={280} className="rounded-3xl bg-th-content text-2xs">
        <ReChartsPieChart
          data={data}
          margin={{
            top: 40,
            right: 30,
            left: 20,
            bottom: 45
          }}
        >
          <Pie stroke="none" data={data} dataKey="cost" name="Cost (£)" cx="50%" cy="50%" innerRadius={55} outerRadius={100} fill="#82ca9d" label />
          <Tooltip
            itemStyle={{ color: 'var(--text)' }}
            contentStyle={{
              backgroundColor: 'var(--content)',
              color: 'var(--text)'
            }}
          />
        </ReChartsPieChart>
      </ResponsiveContainer>
      {/* no data message */}
      {data.length === 0 && (
        <div className="absolute top-0 flex h-full w-full items-center justify-center bg-th-translucent text-th-text">No data to display.</div>
      )}
    </div>
  )
}

export default PieChart
