import { useEffect } from 'react'
import { useCallback, useState } from 'react'

export const useLocalStorage = <T,>(key: string, initialValue: T) => {
  // Initialize state by reading from localStorage
  const readValue = useCallback((): T => {
    try {
      const item = window.localStorage.getItem(key)
      try {
        return item ? JSON.parse(item) : initialValue
      } catch {
        return (item as T) ?? initialValue
      }
    } catch (error) {
      console.warn(`Error reading localStorage key "${key}":`, error)
      return initialValue
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, JSON.stringify(initialValue)])

  const [storedValue, setStoredValue] = useState<T>(readValue)

  // Update state if key changes
  useEffect(() => {
    setStoredValue(readValue())
  }, [key, readValue])

  // Return a wrapped version of useState's setter function that persists the new value to localStorage
  const setValue = useCallback(
    (value: T | ((val: T) => T)) => {
      try {
        // Allow value to be a function to mirror useState's API
        const valueToStore = value instanceof Function ? value(storedValue) : value

        // Save state
        setStoredValue(valueToStore)

        // Save to localStorage
        window.localStorage.setItem(key, JSON.stringify(valueToStore))
      } catch (error) {
        console.warn(`Error setting localStorage key "${key}":`, error)
      }
    },
    [key, storedValue]
  )

  // Handle storage events from other windows/tabs
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === key && event.newValue !== null) {
        try {
          setStoredValue(JSON.parse(event.newValue))
        } catch (error) {
          console.warn(`Error parsing localStorage change for key "${key}":`, error)
        }
      }
    }

    // Listen for changes across windows/tabs
    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [key])

  return [storedValue, setValue] as const
}
