import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react'
import { AppInsightsContext, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js'
import { QueryClient } from '@tanstack/react-query'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'

import { type FunctionComponent, useState } from 'react'

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import Spinner from './components/Common/Spinner'
import Content from './components/Content/Content'
import UnauthenticatedContent from './components/Content/UnauthenticatedContent'
import ErrorBoundaryMessage from './components/ErrorBoundaryMessage/ErrorBoundaryMessage'
import Initialisation from './components/Initialisation/Initialisation'
import Navigation from './components/Navigation/Navigation'
import { reactPlugin } from './configs/appInsights'
import { msalInstance } from './configs/b2c'
import { createIDBPersister } from './constants/indexDBPersister'
import useTheme from './hooks/utils/useTheme'
import { classNames } from './utils/classNames'

const queryClient = new QueryClient()
const persister = createIDBPersister()

/* declare types for runtime config */
declare global {
  interface Window {
    config: Record<string, string>
  }
}

const App: FunctionComponent = (): JSX.Element => {
  const [loading, setLoading] = useState(true)
  const [message, setMessage] = useState('')

  const theme = useTheme()

  return (
    <div className={classNames('theme-' + theme, 'flex h-screen max-h-screen w-screen overflow-hidden bg-th-navigation font-body')} style={{ minWidth: 360 }}>
      <Router>
        <MsalProvider instance={msalInstance}>
          <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
            <AppInsightsContext.Provider value={reactPlugin}>
              <Routes>
                <Route
                  path="*"
                  element={
                    <>
                      {/* authenticated */}
                      <AuthenticatedTemplate>
                        <AppInsightsErrorBoundary onError={() => <ErrorBoundaryMessage fullPage />} appInsights={reactPlugin}>
                          {/* handles token claims and data retrieval on load */}
                          <Initialisation
                            setLoading={(isLoading, message) => {
                              setLoading(isLoading)
                              if (message) setMessage(message)
                            }}
                          >
                            {loading && (
                              /* full screen loading animation */
                              <div className="fixed z-50 flex h-screen w-screen flex-col items-center justify-center gap-8 bg-th-content">
                                <Spinner className="h-16 w-16" />
                                <div className="text-th-text">{message}</div>
                              </div>
                            )}
                            <Navigation />
                            <Content />
                          </Initialisation>
                        </AppInsightsErrorBoundary>
                      </AuthenticatedTemplate>

                      {/* unauthenticated */}
                      <UnauthenticatedTemplate>
                        <AppInsightsErrorBoundary onError={() => <ErrorBoundaryMessage />} appInsights={reactPlugin}>
                          <UnauthenticatedContent />
                        </AppInsightsErrorBoundary>
                      </UnauthenticatedTemplate>
                    </>
                  }
                />
              </Routes>
            </AppInsightsContext.Provider>
          </PersistQueryClientProvider>
        </MsalProvider>
      </Router>
    </div>
  )
}

export default App
