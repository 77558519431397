import { Fragment, type FunctionComponent } from 'react'
import { useEffect } from 'react'

import { BillingCycleType, type CartLineItem } from '../../../types/cart'

import axios, { type AxiosResponse } from 'axios'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { showAlert } from '../../../actions/alertsActions'
import { startLoading, stopLoading } from '../../../actions/loadingActions'
import Button from '../../../components/Common/Button'
import api from '../../../constants/api'

const NoSubscriptionSplash: FunctionComponent = (): JSX.Element => {
  const dispatch = useDispatch()
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  const account = useSelector((state: RootStateOrAny) => state.account)
  const tenant = useSelector((state: RootStateOrAny) => state.tenant)

  const getProductDetails = () => {
    // get product id
    api
      .get(
        // get product id
        `/ms-api/products/account/${account.id}/${tenant.id}/MicrosoftAzure`,
        { cancelToken: source.token }
      )
      .then((productResponse: AxiosResponse) => {
        api
          .get(
            // get sku
            `/ms-api/products/account/${account.id}/${tenant.id}/${productResponse.data[1].Id}/skus`,
            { cancelToken: source.token }
          )
          .then((skuResponse: AxiosResponse) => {
            api
              .get(
                // get sku
                `/ms-api/products/account/${account.id}/${tenant.id}/${productResponse.data[1].Id}/skus/${skuResponse.data.items[0].id}`,
                { cancelToken: source.token }
              )
              .then((availabilityResponse: AxiosResponse) => {
                addToCart(availabilityResponse.data.items[0].catalogItemId)
              })
              .catch((error) => {
                if (!axios.isCancel(error)) {
                  dispatch(
                    showAlert({
                      type: 'error',
                      message: error.response?.data,
                      component: 'Products',
                      error
                    })
                  )
                }
              })
          })
          .catch((error) => {
            if (!axios.isCancel(error)) {
              dispatch(
                showAlert({
                  type: 'error',
                  message: error.response?.data,
                  component: 'Products',
                  error
                })
              )
            }
          })
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(
            showAlert({
              type: 'error',
              message: error.response?.data,
              component: 'Products',
              error
            })
          )
        }
      })
  }

  /* add an item to the shopping cart */
  const addToCart = (id: string) => {
    dispatch(startLoading())
    const cartLineItem: CartLineItem = {
      CatalogItemId: id,
      Quantity: 1,
      BillingCycle: BillingCycleType.Monthly,
      TermDuration: ''
    }

    /* create a new cart */
    createCart(cartLineItem)
  }

  /* create a cart */
  const createCart = (cartLineItem: CartLineItem) => {
    api
      .post(
        `/ms-api/orders/account/${account.id}/${tenant.id}/cart`,
        {
          LineItems: [cartLineItem]
        },
        { cancelToken: source.token }
      )
      .then(() => {
        dispatch(stopLoading())
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(
            showAlert({
              type: 'error',
              message: error.response?.data,
              component: 'Availability',
              error
            })
          )
          dispatch(stopLoading())
        }
      })
  }

  useEffect(() => {
    return () => {
      // cancel source reqs on componentwillunmount
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(stopLoading())
  }, [dispatch])

  return (
    <Fragment>
      <div className="z-8 fixed flex h-full w-full items-center justify-center bg-th-content opacity-90">
        <div className="flex flex-col items-center gap-4">
          <div className="-mt-10">You do not seem to have any Azure Plan.</div>
        </div>
      </div>
      <div className="z-8 fixed flex h-full w-full items-center justify-center">
        <Button bordered onClick={() => getProductDetails()} className="mt-10">
          Create Azure Plan
        </Button>
      </div>
    </Fragment>
  )
}

export default NoSubscriptionSplash
