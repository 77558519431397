import { zodResolver } from '@hookform/resolvers/zod'

import { useEffect } from 'react'

import { type AZServiceCatalogEntry } from '../../../types/azure'

import useCreateTicketMutation from 'src/hooks/services/servicenow/mutations/useCreateTicketMutation'

import SubsDropdown from 'src/components/Dropdowns/SubsDropdown'
import TenantDropdown from 'src/components/Dropdowns/TenantDropdown'

import { useForm, useWatch } from 'react-hook-form'
import { z } from 'zod'

import Button from '../Button'
import { Form, FormControl, FormField, FormItem, FormMessage } from '../Form'
import Popover from '../Popover'
import PortalDrawer from '../PortalDrawer'
import Submit from '../Submit'
import TextArea from '../TextArea'

interface AzureManualDeploymentDrawerProps {
  deployment: AZServiceCatalogEntry
  onClose: () => void
}

const manualFormSchema = z.object({
  tenantId: z.string(),
  subscriptionId: z.string(),
  description: z.string()
})

const AzureManualDeploymentDrawer: React.FC<AzureManualDeploymentDrawerProps> = ({ deployment, onClose }) => {
  const { mutateAsync: createTicket, isLoading } = useCreateTicketMutation()

  const manualForm = useForm<z.infer<typeof manualFormSchema>>({
    resolver: zodResolver(manualFormSchema),
    defaultValues: {
      tenantId: '',
      subscriptionId: '',
      description: deployment.description_text
    }
  })

  useEffect(() => manualForm.setValue('description', deployment.description_text), [deployment.description_text, manualForm])

  const handleManualFormSubmit = async (values: z.infer<typeof manualFormSchema>) => {
    await createTicket({
      ticket: {
        category: 'Request',
        title: 'Request for ' + deployment.resource_type + ' deployment',
        contact_type: 'Provide',
        correlation_display: 'ProvideAzureDeployment',
        service_technology: 'c4cd923b1bad99d021b7dc62b24bcb6a',
        issue: '84f305f51b120dd0cf8b404cd34bcb1f',
        assigned_group: deployment.assignment_group,
        users_affected: 'Single',
        business_impact: 'No immediate impact',
        description: 'Tenant ID: ' + values.tenantId + '\nSubscription ID: ' + values.subscriptionId + '\nDescription: ' + values.description
      }
    })
    onClose()
  }

  const tenantId = useWatch({
    control: manualForm.control,
    name: 'tenantId'
  })

  const subscriptionId = useWatch({
    control: manualForm.control,
    name: 'subscriptionId'
  })

  return (
    <PortalDrawer
      title={`New ${deployment.resource_type}`}
      onClose={onClose}
      actions={
        <div className="grid grid-cols-2 gap-4">
          <Button variant="default" onClick={onClose} bordered disabled={isLoading}>
            Cancel
          </Button>
          <Popover text="Please select a tenant and subscription" disabled={!!tenantId && !!subscriptionId} className="w-full">
            <Submit variant="primary" form="ManualForm" disabled={isLoading || !tenantId || !subscriptionId} className="w-full">
              Submit
            </Submit>
          </Popover>
        </div>
      }
    >
      <Form {...manualForm}>
        <form className="space-y-4" id="ManualForm" onSubmit={manualForm.handleSubmit(handleManualFormSubmit)}>
          <FormField
            control={manualForm.control}
            name="tenantId"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <TenantDropdown onChange={(selectedTenants) => field.onChange(selectedTenants.at(0)?.id ?? '')} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={manualForm.control}
            name="subscriptionId"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <SubsDropdown
                    selectedTenantId={tenantId}
                    onChange={(selectedSubs) =>
                      selectedSubs instanceof Array ? field.onChange(selectedSubs.at(0)?.id ?? '') : field.onChange(selectedSubs?.id ?? '')
                    }
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={manualForm.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <TextArea {...field} label="Description" rows={10} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </form>
      </Form>
    </PortalDrawer>
  )
}

export default AzureManualDeploymentDrawer
