import { useMemo } from 'react'

const useCumulate = <T extends any[]>(data: T, key: keyof T[number]) => {
  const cumulatedData = useMemo(() => {
    if (!data) return null

    let counter = 0
    const _data = data.map((item) => {
      const value = item[key] as number
      counter += value
      return { ...item, [key]: counter }
    })

    return _data
  }, [data, key])

  return cumulatedData as T
}

export default useCumulate
