import { PublicClientApplication } from '@azure/msal-browser'

export const clientId = window.config.clientId

const b2cUserFlows = {
  names: {
    signUpSignIn: 'b2c_1a_signup_signin'
  },
  authorities: {
    signUpSignIn: {
      authority: window.config.authority
    }
  },
  authorityDomain: window.config.authorityDomain
}

const msalConfig = {
  auth: {
    clientId: clientId,
    authority: b2cUserFlows.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cUserFlows.authorityDomain],
    redirectUri: window.location.origin.toString()
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  }
}

export const msalInstance = new PublicClientApplication(msalConfig)
