import { useEffect } from 'react'

import { setSearch } from 'src/actions/folderActions'
import { stopLoading } from 'src/actions/loadingActions'

import useDocumentAccess from 'src/hooks/access/useDocumentAccess'
import { useFileShare } from 'src/hooks/composite/files/useFileShare'
import useCreateUploadIncidentMutation from 'src/hooks/services/provide/mutations/useCreateUploadIncidentMutation'
import useFolderConfigQuery from 'src/hooks/services/provide/queries/useFolderConfigQuery'
import useFileUpload from 'src/hooks/utils/useFileUpload'
import useOwnUser from 'src/hooks/utils/useOwnUser'

import Input from 'src/components/Common/Input'
import Popover from 'src/components/Common/Popover'
import Section from 'src/components/Common/Section'
import UploadButton from 'src/components/Common/UploadButton'
import FileExplorer from 'src/components/Content/FileExplorer/FileExplorer'
import Unauthorised from 'src/components/Content/Unauthorised/Unauthorised'
import CreateFolderDialog from 'src/components/Dialogs/CreateFolderDialog'
import ProgressIndicator from 'src/components/ProgressIndictor'

import { useDispatch, useSelector } from 'react-redux'
import { type FolderState } from 'src/reducers/folderReducer'

const DocumentsPage = () => {
  // Stop loading on initial render
  const dispatch = useDispatch()
  useEffect(() => void dispatch(stopLoading()), [dispatch])

  const user = useOwnUser()

  const folderPath = useSelector((state: FolderState) => state.folder.folderPath)
  const search = useSelector((state: FolderState) => state.folder.search)

  const { ShareModal, openShareModal } = useFileShare()
  const { mutateAsync: createUploadIncident } = useCreateUploadIncidentMutation()

  const { uploadFiles, uploadProgress, isUploading, UploadConfirmationModal } = useFileUpload({
    onComplete: (newFiles) => {
      openShareModal(
        newFiles.map((file) => file.id),
        newFiles[0].fileName
      )

      if (!user?.super_admin) {
        createUploadIncident({ fileIds: newFiles.map((file) => file.id) })
      }
    }
  })

  const { data: folderConfigs, isLoading: isLoadingFolderOptions } = useFolderConfigQuery()

  const lastFolderConfigId = folderPath.findLast((path) => path.createdFromConfigId !== null)?.createdFromConfigId
  const lastFolderConfig = folderConfigs?.find((folder) => folder.id === lastFolderConfigId) ?? null
  const enableFolderConfigs = lastFolderConfig?.type === 'Unstructured' || folderPath.at(-1)?.name === 'Uploads'
  const isUploadableFolder = !enableFolderConfigs && user?.super_admin

  const isInUploadsFolder = folderPath.some((path) => path.name === 'Uploads')

  const { hasDocumentsAccess, hasGlobalWriteAccess } = useDocumentAccess()

  const getUploadButtonPopoverText = () => {
    if (!hasGlobalWriteAccess) {
      return 'You do not have access to upload files, please contact your administrator to request access.'
    }
    if ((isUploadableFolder || isInUploadsFolder) && user?.super_admin) {
      return 'You are only able to upload files to specific locations in the folder structure. Please refer to our internal documentation for more information.'
    }
    return ''
  }

  return hasDocumentsAccess ? (
    <>
      {UploadConfirmationModal}
      {ShareModal}
      <Section>
        <ProgressIndicator percent={uploadProgress} />
        <div className="flex flex-col justify-between gap-4 pb-8 sm:flex-row sm:items-center">
          <CreateFolderDialog />
          <div className="flex gap-4 sm:justify-between [&>*]:grow">
            <Input type="search" placeholder="Search" value={search} onChange={(e) => dispatch(setSearch(e.target.value))} />

            <Popover text={getUploadButtonPopoverText()} disabled={!getUploadButtonPopoverText()} className="w-max">
              <UploadButton
                onUpload={uploadFiles}
                loading={isUploading || isLoadingFolderOptions}
                disabled={isUploadableFolder || !hasGlobalWriteAccess || (isInUploadsFolder && user?.super_admin)}
              />
            </Popover>
          </div>
        </div>

        <FileExplorer key="fileExplorer" />
      </Section>
    </>
  ) : (
    <Unauthorised />
  )
}

export default DocumentsPage
