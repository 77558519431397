import { Fragment } from 'react'

import type { Change } from '~/hooks/services/servicenow/queries/useChangesQuery'
import useChangesQuery from '~/hooks/services/servicenow/queries/useChangesQuery'

import Button from '~/components/Common/Button'
import Classification from '~/components/Common/Classification'
import { Label } from '~/components/Common/Label'
import Popover from '~/components/Common/Popover'
import Spacer from '~/components/Common/Spacer'
import State from '~/components/Common/State'

import { classNames } from '~/utils/classNames'

import moment from 'moment'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { PiCaretLeft, PiCaretRight } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'

import { useChangeFilters } from '../../hooks/useChangeFilters'
import { ChangeScheduleFilters } from '../ChangeScheduleFilters'
import { useCalendar } from './useCalendar'

moment.updateLocale('en', {
  week: {
    dow: 1 // Monday is the first day of the week.
  }
})

const copyChangeToClipBoard = (changeStr: string) => {
  window.navigator.clipboard.writeText(changeStr)
}

const getChangeEventTooltip = (change: Change) => {
  return [
    `${change.number} - ${change.title}`,
    `Start: ${change.starts}`,
    `End: ${change.ends}`,
    `Category: ${change.category}`,
    `Classification: ${change.classification}`,
    `State: ${change.state}`
  ].join('\n')
}

const getChangeEventTitle = (change: Change) => {
  return (
    <div className="flex items-center">
      <Classification classification={change.classification} displayMode="icon" enableTooltip />
      <State state={change.state} displayMode="icon" enableTooltip />
      <div className="w-full truncate pl-1">
        <Popover text={getChangeEventTooltip(change)} position="bottom" textAlign="left">
          <p className="break-words">
            <b>{change.number}</b> - {change.title}
          </p>
        </Popover>
      </div>
    </div>
  )
}

export const ChangeScheduleCalendar = () => {
  const localizer = momentLocalizer(moment)
  const navigate = useNavigate()

  const { filterOptions, handleDatesChange } = useChangeFilters()
  const { calendarDate, handleTodayClick, handleNextClick, handlePrevClick } = useCalendar(handleDatesChange)

  const { data: changes } = useChangesQuery(filterOptions)

  const events = changes?.changes
    ?.sort((a, b) => (a.starts > b.starts ? 1 : -1))
    .filter((c) => c.starts)
    .map((change, index) => ({
      ...change,
      startdate: new Date(change.starts),
      enddate: new Date(change.ends),
      eventTitle: getChangeEventTitle(change),
      title: '',
      index
    }))

  return (
    <Fragment>
      <div className="mb-8 flex flex-wrap items-center gap-2">
        <Button bordered onClick={handleTodayClick}>
          Today
        </Button>

        <Button className={classNames('w-16 text-center')} bordered onClick={handlePrevClick}>
          <PiCaretLeft className="h-5 w-5" />
        </Button>

        <Label className={classNames('w-36 rounded-full border border-th-border text-center', 'bg-th-content p-3 font-headline text-sm font-medium')}>
          {calendarDate.format('YYYY MMMM')}
        </Label>

        <Button className={classNames('w-16 text-center')} bordered onClick={handleNextClick}>
          <PiCaretRight className="h-5 w-5" />
        </Button>

        <Spacer className="block" />

        <ChangeScheduleFilters />
      </div>

      <Calendar
        localizer={localizer}
        events={events}
        date={calendarDate.toDate()}
        startAccessor="startdate"
        endAccessor="enddate"
        titleAccessor="eventTitle"
        onSelectEvent={(change) => navigate(`/changes/schedules/${change.id}`)}
        onDoubleClickEvent={(event) => copyChangeToClipBoard(getChangeEventTooltip(event))}
        showMultiDayTimes
        popup={true}
        style={{ height: 1280 }}
        views={['month']}
      />
    </Fragment>
  )
}
