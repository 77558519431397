import { withAITracking } from '@microsoft/applicationinsights-react-js'

import { Fragment, type FunctionComponent, useEffect, useState } from 'react'

import { type CreditNote } from '../../types/creditnote'

import axios from 'axios'
import moment, { type Moment } from 'moment'
import { CSVLink } from 'react-csv'
import { Iconly } from 'react-iconly'
import { FcFolder } from 'react-icons/fc'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { showAlert } from '../../actions/alertsActions'
import { startLoading, stopLoading } from '../../actions/loadingActions'
import Banner from '../../components/Common/Banner'
import Button from '../../components/Common/Button'
import Count from '../../components/Common/Count'
import DateRange from '../../components/Common/DateRange/DateRange'
import Dropdown from '../../components/Common/Dropdown'
import Section from '../../components/Common/Section'
import Spacer from '../../components/Common/Spacer'
import CreditNoteTable from '../../components/Tables/CreditNoteTable'
import { reactPlugin } from '../../configs/appInsights'
import api from '../../constants/api'
import { ReactComponent as SVGIconCoinsHigh } from '../../images/SalesPerfIconHigh.svg'
import { ReactComponent as SVGIconCoinsLow } from '../../images/SalesPerfIconLow.svg'
import { ReactComponent as SVGIconCoinsMedium } from '../../images/SalesPerfIconMedium.svg'

/* dropdown options */
const periodOptions = [
  {
    label: 'All time',
    value: 'all'
  },
  {
    label: 'Current Month',
    value: 'current'
  },
  {
    label: 'Previous Month',
    value: 'previous'
  },
  {
    label: 'Custom',
    value: 'custom'
  }
]

const beginningOfTime = moment(0)
const endOfTime = moment().add(1, 'days').startOf('day')

const CreditNotes: FunctionComponent = (): JSX.Element => {
  const [startDate, setStartDate] = useState<Moment>(beginningOfTime)
  const [endDate, setEndDate] = useState<Moment>(endOfTime)
  const [period, setPeriod] = useState<string>(periodOptions[0].value)
  const [creditnotes, setCreditNotes] = useState<CreditNote[]>([])
  const [filtered, setFiltered] = useState<CreditNote[]>(creditnotes)
  const [showSearch, setShowSearch] = useState<boolean>(false)
  const [size, setSize] = useState<string | undefined>(undefined)
  const [small, setSmall] = useState<number>(0)
  const [medium, setMedium] = useState<number>(0)
  const [large, setLarge] = useState<number>(0)

  const dispatch = useDispatch()
  const account = useSelector((state: RootStateOrAny) => state.account)

  /* filter the creditnotes by search term */
  const filter = (value: string) => {
    const searchTerm = value.toLowerCase()

    setFiltered(creditnotes.filter((creditnote: CreditNote) => creditnote.number.toLowerCase().indexOf(searchTerm) !== -1))
  }

  /* set the date range based on the period that has been selected */
  const handlePeriodChange = (period: string) => {
    setPeriod(period)

    const beginningOfTime = moment(0)
    const endOfTime = moment().add(1, 'days').startOf('day')
    const firstDayOfThisMonth = moment().startOf('month')
    const lastDayOfThisMonth = moment().endOf('month')

    if (period === 'all') {
      /* set the dates to span all time */
      setStartDate(beginningOfTime)
      setEndDate(endOfTime)
    } else if (period === 'current') {
      /* set the dates to span the current month */
      setStartDate(firstDayOfThisMonth.startOf('month'))
      setEndDate(lastDayOfThisMonth.endOf('month'))
    } else if (period === 'previous') {
      /* set the dates to span the previous month */
      setStartDate(moment(firstDayOfThisMonth.subtract(1, 'months').startOf('month')))
      setEndDate(moment(lastDayOfThisMonth.subtract(1, 'months').endOf('month')))
    }
  }

  /* set the start and end dates */
  const handleDatesChange = (startDate: Moment, endDate: Moment) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  /* start loading animation on page load */
  useEffect(() => {
    dispatch(startLoading())
  }, [dispatch])

  /* get the creditnotes for the selected account */
  useEffect(() => {
    const source = axios.CancelToken.source()

    dispatch(startLoading())

    const data = {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD')
    }

    if (account.id) {
      api
        .post(`/servicenow/account/${account.id}/creditnotes`, data, { cancelToken: source.token })
        .then((response) => {
          if (response.data) {
            const states: Record<string, number> = {}
            const sizes: Record<string, number> = {}

            for (const creditnote of response.data) {
              const due_date = moment(creditnote.due_date)
              const today = moment()

              if (creditnote.due_date && due_date.isBefore(today) && creditnote.state !== 'Paid In Full') {
                creditnote.state = 'Overdue'
              }

              if (creditnote.total < 500) {
                creditnote.size = 'Small'
              } else if (creditnote.total >= 500 && creditnote.total < 5000) {
                creditnote.size = 'Medium'
              } else {
                creditnote.size = 'Large'
              }

              states[creditnote.state] = states[creditnote.state] ? states[creditnote.state] + 1 : 1

              sizes[creditnote.size] = sizes[creditnote.size] ? sizes[creditnote.size] + 1 : 1
            }

            setCreditNotes(response.data || [])
            setSmall(sizes['Small'] || 0)
            setMedium(sizes['Medium'] || 0)
            setLarge(sizes['Large'] || 0)

            /* stop loading here if there are no creditnotes to process */
            if (response.data.length === 0) {
              dispatch(stopLoading())
            }
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            dispatch(
              showAlert({
                type: 'error',
                message: 'We were unable to retrieve your creditnotes.',
                component: 'CreditNotes',
                error
              })
            )
            dispatch(stopLoading())
          }
        })
    }

    return () => {
      source.cancel()
    }
  }, [account, startDate, endDate, dispatch])

  /* filter the creditnotes by state */
  useEffect(() => {
    const filtered = creditnotes.filter((creditnote: CreditNote) => {
      return creditnote.size === size || size === undefined
    })
    setFiltered(filtered)
  }, [size, creditnotes, showSearch])

  /* stop loading when the creditnotes have been processed */
  useEffect(() => {
    if (filtered.length > 0) {
      dispatch(stopLoading())
    }
  }, [filtered, dispatch])

  return (
    <Fragment>
      <Section>
        <div className="my-5 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-1 mdlg:grid-cols-2 xl:grid-cols-4">
          <Count
            icon={<FcFolder className="h-full w-full text-th-warning" />}
            value={creditnotes.length}
            label="All CreditNotes"
            className="cursor-pointer"
            onClick={() => setSize(undefined)}
            active={size === undefined}
          />
          <Count
            icon={<SVGIconCoinsLow className="h-full w-full" />}
            value={small}
            label="Less than £500"
            className="cursor-pointer"
            onClick={() => setSize('Small')}
            active={size === 'Small'}
          />
          <Count
            icon={<SVGIconCoinsMedium className="h-full w-full" />}
            value={medium}
            label="Between £500 and £5000"
            className="cursor-pointer"
            onClick={() => setSize('Medium')}
            active={size === 'Medium'}
          />
          <Count
            icon={<SVGIconCoinsHigh className="h-full w-full" />}
            value={large}
            label="£5000 or more"
            className="cursor-pointer"
            onClick={() => setSize('Large')}
            active={size === 'Large'}
          />
        </div>
      </Section>
      <Section>
        <Banner>
          {/* date range */}
          <Dropdown
            options={periodOptions}
            defaultValue={periodOptions[0]}
            variant={Dropdown.variant.DEFAULT}
            label="Created"
            multi={false}
            onChange={(option) => handlePeriodChange(option.value)}
          />
          <DateRange
            start={startDate}
            end={endDate}
            startDateId="creditnotes_start_date"
            endDateId="creditnotes_end_date"
            variant={DateRange.variant.DEFAULT}
            onChange={(startDate, endDate) => handleDatesChange(startDate, endDate)}
            disabled={period !== 'custom'}
            hidden={period === 'all'}
            focus={period === 'custom'}
          />
          <Spacer className="hidden sm:block" />
          {/* search */}
          {showSearch ? (
            <Fragment>
              <input type="text" className="rounded-lg p-2 font-body text-sm" placeholder="Search" autoFocus onChange={(e) => filter(e.target.value)} />
              <IoCloseCircleOutline className="h-6 w-6 cursor-pointer" onClick={() => setShowSearch(false)} />
            </Fragment>
          ) : (
            <Iconly name="Search" set="light" className="cursor-pointer" onClick={() => setShowSearch(true)} />
          )}
          {/* csv download */}
          <CSVLink data={filtered} filename="creditnotes.csv" target="_blank">
            <Button onClick={() => null}>Download CSV</Button>
          </CSVLink>
        </Banner>
        <Spacer />
        <CreditNoteTable creditnotes={filtered} />
      </Section>
    </Fragment>
  )
}

const CreditNotesWithTracking = withAITracking(reactPlugin, CreditNotes, 'Credit Notes')
export default CreditNotesWithTracking
