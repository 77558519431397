import React, { type ReactNode, useEffect, useState } from 'react'

import { Button } from 'src/components/Common/Button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from 'src/components/Common/Dialog'

type ConfirmationModalProps = {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  title: string
  children: ReactNode
  confirmButtonText?: string
  cancelButtonText?: string
  countdownDuration?: number
  isLoading?: boolean
  disabled?: boolean
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  children,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  countdownDuration = 5,
  isLoading = false,
  disabled = false
}) => {
  const [countdown, setCountdown] = useState(countdownDuration)

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (isOpen && countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000)
    }
    return () => clearTimeout(timer)
  }, [isOpen, countdown])

  useEffect(() => {
    if (isOpen) {
      setCountdown(countdownDuration)
    }
  }, [isOpen, countdownDuration])

  const handleConfirm = () => {
    if (countdown === 0) {
      onConfirm()
    }
  }

  const handleOpenChange = (open: boolean) => {
    if (!open) onClose()
  }

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogContent className="text-th-text">
        <DialogHeader className="text-th-text">
          <DialogTitle className="w-full max-w-[350px] truncate text-th-text" title={title}>
            {title}
          </DialogTitle>
        </DialogHeader>
        <div className="py-4 text-th-text">{children}</div>
        <DialogFooter className="text-th-text">
          <Button bordered onClick={onClose} disabled={isLoading}>
            {cancelButtonText}
          </Button>
          <Button onClick={handleConfirm} disabled={countdown > 0 || disabled} variant="primary" isLoading={isLoading}>
            {countdown > 0 ? `${confirmButtonText} (${countdown})` : confirmButtonText}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmationModal
