/* utils */
import { ReactComponent as BlogIcon } from '../../images/blog-svgrepo-com.svg'
import { ReactComponent as KBIcon } from '../../images/idea-bulb-learning-knowledge-education-book-idea-svgrepo-com.svg'
import { ReactComponent as ResIcon } from '../../images/links-svgrepo-com.svg'
import { ReactComponent as NewsIcon } from '../../images/news-svgrepo-com.svg'
import ArticleLink from './ArticleLink'
import Tile from './Tile'

const ArticleLinkList = (): JSX.Element => {
  return (
    <div className="my-5 flex grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-1 mdlg:grid-cols-2 xl:grid-cols-4">
      <Tile
        onClick={() => {
          window.open('https://www.clouddirect.net/media-type/news/?post_types=post', '_blank')
        }}
        className="flex cursor-pointer items-center justify-center gap-1 px-4 py-6 md:px-8 mdlg:px-4 lg:px-8"
      >
        <ArticleLink
          icon={<NewsIcon className="h-full w-8 flex-none xl:w-10" />}
          link="https://www.clouddirect.net/media-type/news/?post_types=post"
          text="News"
        />
      </Tile>
      <Tile
        onClick={() => {
          window.open('https://www.clouddirect.net/media-type/blog/?post_types=post', '_blank')
        }}
        className="flex cursor-pointer items-center justify-center gap-1 px-4 py-6 md:px-8 mdlg:px-4 lg:px-8"
      >
        <ArticleLink
          icon={<BlogIcon className="h-full w-8 flex-none xl:w-10" />}
          link="https://www.clouddirect.net/media-type/blog/?post_types=post"
          text="Blog"
        />
      </Tile>
      <Tile
        onClick={() => {
          window.open('https://www.clouddirect.net/knowledge-base/', '_blank')
        }}
        className="flex cursor-pointer items-center justify-center gap-1 px-4 py-6 md:px-8 mdlg:px-4 lg:px-8"
      >
        <ArticleLink icon={<KBIcon className="h-full w-8 flex-none xl:w-10" />} link="https://www.clouddirect.net/knowledge-base/" text="Knowledge Base" />
      </Tile>
      <Tile
        onClick={() => {
          window.open('https://www.clouddirect.net/learning-hub/', '_blank')
        }}
        className="flex cursor-pointer items-center justify-center gap-1 px-4 py-6 md:px-8 mdlg:px-4 lg:px-8"
      >
        <ArticleLink icon={<ResIcon className="h-full w-8 flex-none xl:w-10" />} link="https://www.clouddirect.net/learning-hub/" text="Learning Hub" />
      </Tile>
    </div>
  )
}

export default ArticleLinkList
