import { type FormEvent, Fragment, type FunctionComponent, useState } from 'react'

import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { showAlert } from '../../actions/alertsActions'
import { closeDrawers } from '../../actions/drawerActions'
import api from '../../constants/api'
import Button from '../Common/Button'
import Drawer from '../Common/Drawer'
import Select from '../Common/Select'
import Submit from '../Common/Submit'
import TextArea from '../Common/TextArea'

/* ticket categories */
const categoryOptions = [
  {
    label: 'Company Name',
    value: 'Company Name'
  },

  {
    label: 'Phone',
    value: 'Phone'
  },

  {
    label: 'Email',
    value: 'Email'
  },

  {
    label: 'Billing Email',
    value: 'Billing Email'
  },

  {
    label: 'Shipping Address',
    value: 'Shipping Address'
  },

  {
    label: 'Billing Address',
    value: 'Billing Address'
  }
]

/* form names */
interface Forms extends HTMLCollectionOf<HTMLFormElement> {
  changeAccountDetails: HTMLFormElement
}

const RequestAccountDetailChange: FunctionComponent = (): JSX.Element => {
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const account = useSelector((state: RootStateOrAny) => state.account)

  /* drawer actions */
  const Actions: FunctionComponent = (): JSX.Element => {
    return (
      <Fragment>
        <Button bordered onClick={() => dispatch(closeDrawers())}>
          Cancel
        </Button>
        <Submit variant="primary" form="changeAccountDetails" />
      </Fragment>
    )
  }

  const changeAccountDetails = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    const forms = document.forms as Forms
    const form = forms.changeAccountDetails
    const data = new FormData(form)

    const ticket = {
      account: account.id,
      contact_type: 'Provide',
      category: 'Request',
      title: 'Request to change ' + data.get('title'),
      users_affected: 'Single',
      business_impact: 'Some',
      description: data.get('description')
    }

    api
      .post(`/servicenow/account/${account.id}/ticket`, ticket)
      .then((response) => {
        setLoading(false)
        dispatch(closeDrawers())

        dispatch(
          showAlert({
            type: 'success',
            message: response.data.number + ' created.',
            component: 'RequestAccountDetailChange'
          })
        )
      })
      .catch((error) => {
        setLoading(false)
        dispatch(
          showAlert({
            type: 'error',
            message: 'We were unable to create your ticket. Please try again.',
            component: 'RequestAccountDetailChange',
            error
          })
        )
      })
  }

  return (
    <Drawer title={'Request account details change '} loading={loading} actions={<Actions />}>
      <form onSubmit={(e) => changeAccountDetails(e)} id="changeAccountDetails" className="flex flex-col gap-4">
        {/* what to change */}
        <Select name="title" label="Data field to change" options={categoryOptions} required />

        {/* description */}
        <TextArea className="h-32" name="description" label="New value" required />
      </form>
    </Drawer>
  )
}

export default RequestAccountDetailChange
