import { withAITracking } from '@microsoft/applicationinsights-react-js'
import { useQueryClient } from '@tanstack/react-query'

import { Fragment, type FunctionComponent, useEffect, useMemo, useState } from 'react'

import { type Entitlement, type Tenant } from 'src/types/azure'

import { showAlert } from 'src/actions/alertsActions'
import { stopLoading } from 'src/actions/loadingActions'

import api from 'src/constants/api'

import useConsumptionTotal from 'src/hooks/composite/costs/useConsumptionTotal'
import useProjectedSpend from 'src/hooks/composite/costs/useProjectedSpend'
import useBudgets from 'src/hooks/services/microsoft/queries/useBudgets'
import useDailyTotalsForTagset from 'src/hooks/services/microsoft/queries/useDailyTotalsForTagset'
import { useTenantsQuery } from 'src/hooks/services/servicenow/queries/useTenantsQuery'
import useAccount from 'src/hooks/utils/useAccount'

import Heading from 'src/components/Common/Heading'
import Section from 'src/components/Common/Section'
import EditBudgetDrawer from 'src/components/Drawers/EditBudgetDrawer'
import BudgetsTable from 'src/components/Tables/BudgetsTable'

import { reactPlugin } from 'src/configs/appInsights'

import type { AxiosError } from 'axios'
import moment, { type Moment } from 'moment'
import { FcBarChart, FcComboChart, FcDoughnutChart } from 'react-icons/fc'
import { PiPencilLine } from 'react-icons/pi'
import { useDispatch } from 'react-redux'

import AzureTile from './AzureOverview/AzureTile/AzureTile'

const currentYearDateRange = [moment().startOf('year'), moment().endOf('year')] as [Moment, Moment]
const ytdDateRange = [moment().startOf('year'), moment().startOf('day')] as [Moment, Moment]

// -~= Component =~- //
const AzureBudgets: FunctionComponent = () => {
  // --~= State definitions =~-- //
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [drawerTenant, setDrawerTenant] = useState<(Tenant & { budget?: number | null; subscriptions: Entitlement[] }) | null>(null)

  // -~= Helper Hooks =~- //
  const dispatch = useDispatch()
  useEffect(() => void dispatch(stopLoading()), [dispatch])

  const account = useAccount()
  const { data: tenants } = useTenantsQuery({ active: true })
  const budgets = useBudgets({ tenants })

  const budgetData = useMemo(() => {
    const breakdown =
      tenants?.map((t) => {
        const budget = budgets?.find((b) => b.tenant_id === t.id)?.value

        return {
          title: t.name,
          values: [
            { value: budget ?? 'Not Set' },
            {
              value: <PiPencilLine className="h-5 w-5" />,
              action: () => {
                setOpen(true)
                setDrawerTenant({ ...t, budget: budgets ? (budget ?? null) : undefined })
              }
            }
          ]
        }
      }) || []

    const total_budget = budgets?.reduce((acc, cur) => acc + cur.value, 0) ?? 0

    return { total_budget, breakdown }
  }, [budgets, tenants])

  const queryClient = useQueryClient()

  const handleBudgetSave = async (budget: number) => {
    if (!drawerTenant) return

    setLoading(true)

    try {
      try {
        await api.patch(`/ms-api/account/${account.id}/azure_usage/${drawerTenant.id}/budget/${budget}`)
      } catch (error) {
        const typedError = error as { response: { status: number } }
        const allowedErrorResponse = typedError.response.status === 400 && budget === 0
        if (!allowedErrorResponse) throw new Error('Error updating budget')
      }

      // Optimistic update
      queryClient.setQueryData(['budgets', account.id, tenants?.map((t) => t.id)], (prev: typeof budgets | undefined) => {
        return budget === 0 ? prev?.filter((b) => b.tenant_id !== drawerTenant.id) : [...(prev ?? []), { tenant_id: drawerTenant.id, value: budget }]
      })
      setDrawerTenant((prev) => (prev ? { ...prev, budget: budget === 0 ? null : budget } : null))
      setOpen(false)
      setLoading(false)

      // Invalidate query
      await queryClient.invalidateQueries(['budgets', account.id, tenants?.map((t) => t.id)])
    } catch (error) {
      dispatch(
        showAlert({
          type: 'error',
          message: 'We were unable to update your budget.',
          component: 'AzureOverview',
          error: error as AxiosError
        })
      )
      setLoading(false)
    }
  }

  const consumptionTotal = useConsumptionTotal({ dateRange: ytdDateRange })
  const generalConsumption = useDailyTotalsForTagset({ dateRange: ytdDateRange, tenants, tags: [] })

  const lastContentfulDate = useMemo(
    () => Math.max(...(generalConsumption?.data?.map((d) => new Date(d.usageDate).valueOf()) ?? [])) || null,
    [generalConsumption]
  )
  const projectedSpend = useProjectedSpend({ dateRange: currentYearDateRange, lastContentfulDate: lastContentfulDate ? moment(lastContentfulDate) : undefined })

  return (
    <Fragment>
      {open && drawerTenant && (
        <EditBudgetDrawer onClose={() => setOpen(false)} tenant={drawerTenant} onSubmit={handleBudgetSave} budget={drawerTenant.budget} loading={loading} />
      )}
      <Section>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-1 mdlg:grid-cols-2 xl:grid-cols-3">
          <AzureTile icon={<FcBarChart className="h-full w-full" />} value={budgetData.total_budget * 12} label="Total Yearly Budget" />
          <AzureTile icon={<FcDoughnutChart className="h-full w-full" />} value={consumptionTotal} label="Spend Year to Date" />
          <AzureTile icon={<FcComboChart className="h-full w-full" />} value={projectedSpend} label="Projected Yearly Spend" />
        </div>
      </Section>
      <Section>
        <Heading text="Budget Breakdown" />
        <BudgetsTable data={budgetData.breakdown} headers={['Total Monthly Budget', '']} mainHeader="Categories" />
      </Section>
    </Fragment>
  )
}

const AzureBudgetsWithTracking = withAITracking(reactPlugin, AzureBudgets, 'Azure Budgets')
export default AzureBudgetsWithTracking
