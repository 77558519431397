import { type ColumnDef } from '@tanstack/react-table'

import React, { type FunctionComponent, useMemo } from 'react'

import { type Recommendation } from '../../types/azure'

import moment from 'moment'
import { useDispatch } from 'react-redux'

import { closeDrawers, openRecommendationDrawer } from '../../actions/drawerActions'
import State from '../Common/State'
import Table from '../Common/Table/Table'

interface Props {
  recommendations: Recommendation[]
}

const RecommendationTable: FunctionComponent<Props> = ({ recommendations }: Props): JSX.Element => {
  const dispatch = useDispatch()

  const columns = useMemo<ColumnDef<Recommendation>[]>(
    () => [
      {
        accessorKey: 'notification',
        header: '',
        cell: () => <div className="h-2 w-2 rounded-full"></div>
      },
      {
        accessorKey: 'category',
        header: 'Category',
        accessorFn: (row) => row.properties.category
      },
      {
        accessorKey: 'description',
        header: 'Description',
        accessorFn: (row) => row.properties.shortDescription.problem
      },
      {
        accessorKey: 'subscription',
        header: 'Subscription',
        accessorFn: (row) => row.subscriptionName
      },
      {
        accessorKey: 'resources',
        header: 'Resources',
        accessorFn: (row) => row.resources
      },
      {
        accessorKey: 'impact',
        header: 'Impact',
        cell: (info) => <State state={`${info.getValue<string>()} Impact`} />,
        accessorFn: (row) => row.properties.impact
      },
      {
        accessorKey: 'updated',
        header: 'Updated',
        cell: (info) => moment(info.getValue<string>()).format('DD/MM/YY HH:mm'),
        accessorFn: (row) => row.properties.lastUpdated,
        sortingFn: (a, b, id) => moment(a.getValue(id)).unix() - moment(b.getValue(id)).unix()
      }
    ],
    []
  )

  return (
    <Table
      data={recommendations}
      columns={columns}
      onRowClick={(row) => {
        dispatch(closeDrawers())
        dispatch(openRecommendationDrawer(row))
      }}
    />
  )
}

export default RecommendationTable
