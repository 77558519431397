import { withAITracking } from '@microsoft/applicationinsights-react-js'
import { useQueries, useQueryClient } from '@tanstack/react-query'

import { Fragment, type FunctionComponent, useMemo, useState } from 'react'

import { type CartLineItem, type Cart as CartType } from '../../../types/cart'
import type { Availability, Price, Term } from '../../../types/microsoft365'
import { type Tenant } from 'src/types/azure'

import useCartCheckoutMutation from 'src/hooks/services/microsoft/mutations/useCartCheckoutMutation'
import useUpdateCartItemQuantityMutation from 'src/hooks/services/microsoft/mutations/useUpdateCartItemQuantityMutation'
import useCartQuery from 'src/hooks/services/microsoft/queries/useCartQuery'
import useAccount from 'src/hooks/utils/useAccount'

import Popover from 'src/components/Common/Popover'
import Spinner from 'src/components/Common/Spinner'
import Tile from 'src/components/Common/Tile'
import TenantDropdown from 'src/components/Dropdowns/TenantDropdown'
import ConfirmationModal from 'src/components/Modals/ConfirmationModal'

import { formatCurrency } from 'src/utils/format'

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'

import { showAlert } from '../../../actions/alertsActions'
import { startLoading, stopLoading } from '../../../actions/loadingActions'
import Banner from '../../../components/Common/Banner'
import Button from '../../../components/Common/Button'
import Input from '../../../components/Common/Input'
import Section from '../../../components/Common/Section'
import Spacer from '../../../components/Common/Spacer'
import { reactPlugin } from '../../../configs/appInsights'
import api from '../../../constants/api'
import CartItem from './CartItem/CartItem'

const Cart = (): JSX.Element => {
  /* cart states */
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const account = useAccount()
  const [tenant, setTenant] = useState<Tenant | null>(null)

  const termDurations: { [key: string]: string } = {
    P1M: '1 Month',
    P1Y: '1 Year',
    P3Y: '3 Years'
  }

  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false)
  const cartCheckoutMutation = useCartCheckoutMutation()

  /* checkout a cart */
  const checkout = () => {
    if (!tenant?.id) return

    if (!cart || !cart.Id) {
      return dispatch(
        showAlert({
          type: 'error',
          message: 'Your cart is empty.',
          component: 'Cart'
        })
      )
    }

    dispatch(startLoading())
    cartCheckoutMutation.mutate(
      { cart: cart, tenantId: tenant?.id },
      {
        onSuccess: () => {
          dispatch(
            showAlert({
              type: 'success',
              message: 'Cart checked out successfully. It may take a few minutes for your subscriptions to appear.',
              component: 'Cart'
            })
          )
          localStorage.removeItem(`cart-${tenant?.id}`)
          setCart(null)
          setCheckoutModalOpen(false)
        },
        onSettled: () => dispatch(stopLoading())
      }
    )
  }

  const {
    data: cart,
    setData: setCart,
    refetch: refetchCart,
    isFetching: isFetchingCart,
    isInitialLoading: isLoadingCart
  } = useCartQuery({ tenantId: tenant?.id })
  const updateCartItemQuantityMutation = useUpdateCartItemQuantityMutation()

  const handleRemoveItemFromCart = (itemId: number) => {
    if (!tenant?.id || !cart?.Id) return

    setCart({ ...cart, LineItems: cart.LineItems.filter((lineItem) => lineItem.Id !== itemId) })
    updateCartItemQuantityMutation.mutateAsync({ cartId: cart.Id, tenantId: tenant.id, itemId, quantity: 0 })
  }

  const cartItemsQueryResults = useQueries({
    queries:
      cart?.LineItems.map((item) => {
        return {
          queryKey: ['cartItem', item.Id, tenant?.id],
          queryFn: async () => {
            const ids = item.CatalogItemId.split(':')
            const productId = ids[0]
            const skuId = ids[1]

            const [skuResponse, priceResponse] = await Promise.all([
              api.get(`/ms-api/products/account/${account.id}/${tenant?.id}/${productId}/skus/${skuId}`),
              api.get(`/ms-api/products/account/${account.id}/${productId}/sku/${skuId}/prices`)
            ])

            const availabilities = skuResponse.data.items as Availability[]
            const availability = availabilities.find((availability: Availability) => availability.id === ids[2])
            if (!availability) {
              handleRemoveItemFromCart(item.Id!)
              return null
            }

            const term = availability.terms.find(
              (term: Term) => term.duration === item.TermDuration && term.billingCycle.toLowerCase() === item.BillingCycle.toString().toLowerCase()
            )
            if (!term) {
              handleRemoveItemFromCart(item.Id!)
              return null
            }

            const prices = priceResponse.data as Price[]
            const price = prices.find((price: Price) => price.termDuration === term.duration && price.billingPlan.trim() === term.billingCycle)
            if (!price) {
              handleRemoveItemFromCart(item.Id!)
              return null
            }

            return {
              ...item,
              FriendlyName: availability.product.title,
              Price: price,
              Availability: availability
            }
          },
          enabled: !!account.id && !!tenant?.id && item.Id !== undefined && item.Id !== null
        }
      }) ?? []
  })

  const queryClient = useQueryClient()
  const handleSetCart = (cart?: CartType | null) => {
    setCart(cart)

    for (const queryResult of cartItemsQueryResults) {
      if (!queryResult.data) continue

      const updatedItem = cart?.LineItems.find((item) => item.Id === queryResult.data?.Id)
      if (!updatedItem) continue

      queryClient.setQueryData(['cartItem', updatedItem.Id, tenant?.id], (oldData?: CartLineItem | null) =>
        oldData ? { ...oldData, Quantity: updatedItem.Quantity } : null
      )
    }
  }

  const cartItems = cartItemsQueryResults.map((queryResult) => queryResult.data).filter((data) => data !== null)
  const isCartItemsFetching = cartItemsQueryResults.some((queryResult) => queryResult.isFetching)
  const refetchCartItems = () => cartItemsQueryResults.forEach((query) => void query.refetch())

  /* empty cart */
  const EmptyCart: FunctionComponent = (): JSX.Element => {
    return (
      <div className="flex h-full flex-col items-center justify-center gap-8">
        <div>It looks like your cart is empty!</div>
        <Button variant="primary" onClick={() => navigate('/products')}>
          View Products
        </Button>
      </div>
    )
  }

  const [updatingCartIds, setUpdatingCartIds] = useState<number[]>([])
  const handleUpdateCardChange = (id: number, updating: boolean) => {
    if (updating) {
      setUpdatingCartIds([...updatingCartIds, id])
    } else {
      setUpdatingCartIds(updatingCartIds.filter((cartId) => cartId !== id))
    }
  }

  const totalSpend = useMemo(
    () =>
      cartItems.reduce((total, item) => {
        if (item?.Price) return total + item.Price.erp * item.Quantity
        return total
      }, 0),
    [cartItems]
  )

  return (
    <Fragment>
      <Section>
        <ConfirmationModal
          isOpen={checkoutModalOpen}
          onClose={() => setCheckoutModalOpen(false)}
          onConfirm={checkout}
          title="Checkout"
          isLoading={cartCheckoutMutation.isLoading}
          disabled={isFetchingCart || isCartItemsFetching}
        >
          <span className="text-th-text">Are you sure you want to checkout?</span>
          {isFetchingCart || isCartItemsFetching ? (
            <Spinner className="mx-auto mt-4 h-5 w-5" />
          ) : (
            <>
              <ul className="mt-4 max-h-40 space-y-1 overflow-y-auto rounded-lg border border-th-border p-1 text-th-text">
                {cartItems
                  .flatMap((item) => item ?? [])
                  .map((item, index) => (
                    <li key={index} className="flex items-center justify-between border-b border-th-border px-2 py-2 last:border-b-0">
                      <div className="flex-grow">
                        <p className="font-medium">{item.FriendlyName}</p>
                        <p>
                          <span className="text-gray-600 text-sm">
                            ({item.Quantity} x {formatCurrency(item.Price?.erp || 0, true)})
                          </span>
                          <span className="text-gray-500 ml-2 text-sm">
                            | Term: {termDurations[item.Price.termDuration]}, Billing: {item.Price?.billingPlan}
                          </span>
                        </p>
                      </div>
                      <div className="font-semibold">{formatCurrency((item.Price?.erp || 0) * item.Quantity, true)}</div>
                    </li>
                  ))}
              </ul>
              <p className="mt-4 font-bold text-th-text">Total: {formatCurrency(totalSpend, true)}</p>
            </>
          )}
        </ConfirmationModal>
        <Banner>
          <Spacer />
          {/* tenant filter */}
          <TenantDropdown
            showAllTenants
            onChange={(selectedTenants) => {
              setTenant(selectedTenants[0])
            }}
          />
        </Banner>
        {/* <Spacer /> */}
        <Spacer />
        {cart && cart.Id != null ? (
          <Fragment>
            <div className="flex flex-col gap-2">
              {/* cart items */}
              <div className="flex flex-col gap-4">
                {tenant &&
                  cartItems.map((item) => {
                    if (!item)
                      return (
                        <Tile className="p-6">
                          <Spinner className="mx-auto h-6 w-6" />
                        </Tile>
                      )

                    return (
                      <CartItem
                        tenant={tenant}
                        cart={cart}
                        item={item}
                        product={item.Availability.product}
                        price={item.Price}
                        setCart={(cart) => handleSetCart(cart)}
                        key={item.Id}
                        setUpdating={(updating) => {
                          if (item.Id === undefined) return
                          handleUpdateCardChange(item.Id, updating)
                        }}
                      />
                    )
                  })}
              </div>
              <Spacer />
              <div className="flex flex-wrap-reverse justify-between gap-4">
                {/* terms and conditions */}
                <div className="flex items-center">
                  <Input
                    type="checkbox"
                    name="terms_and_conditions"
                    className="mr-2"
                    onChange={() => setTermsAndConditionsAccepted(!termsAndConditionsAccepted)}
                  />
                  I accept the{' '}
                  <a
                    className="ml-1 text-th-primary"
                    href="https://www.clouddirect.net/knowledge-base/KB0014194/on-direct-general-terms-and-conditions-version-11-0"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms and Conditions
                  </a>
                  .
                </div>
                {/* total */}
                <div className="font-bold">Total: {formatCurrency(totalSpend)} excl. VAT</div>
              </div>
              <Spacer />
              {/* checkout */}
              <div className="flex justify-center">
                <Popover text="Some items in your cart are being updated. Please update them before checking out." disabled={updatingCartIds.length === 0}>
                  <Button
                    variant="primary"
                    onClick={async () => {
                      setCheckoutModalOpen(true)
                      await refetchCart()
                      refetchCartItems()
                    }}
                    className="w-max"
                    disabled={!termsAndConditionsAccepted || updatingCartIds.length > 0}
                  >
                    Checkout
                  </Button>
                </Popover>
              </div>
            </div>
          </Fragment>
        ) : isLoadingCart ? (
          <Spinner className="mx-auto h-8 w-8" />
        ) : (
          <EmptyCart />
        )}
      </Section>
    </Fragment>
  )
}

const CartWithTracking = withAITracking(reactPlugin, Cart, 'Cart')
export default CartWithTracking
