import { withAITracking } from '@microsoft/applicationinsights-react-js'

import { type FunctionComponent, useEffect, useState } from 'react'

import { type Account } from '../../types/account'

import axios, { type AxiosResponse } from 'axios'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { showAlert } from '../../actions/alertsActions'
import { startLoading, stopLoading } from '../../actions/loadingActions'
import Heading from '../../components/Common/Heading'
import Section from '../../components/Common/Section'
import Spacer from '../../components/Common/Spacer'
import Tile from '../../components/Common/Tile'
import { reactPlugin } from '../../configs/appInsights'
import api from '../../constants/api'
import KeyContacts from '../Dashboard/KeyContacts/KeyContacts'

const MyAccount: FunctionComponent = (): JSX.Element => {
  const dispatch = useDispatch()

  const [value, setValue] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [billingEmail, setBillingEmail] = useState('')

  const [billingAddressStreet, setBillingAddressStreet] = useState('')
  const [billingAddressCity, setBillingAddressCity] = useState('')
  const [billingAddressState, setBillingAddressState] = useState('')
  const [billingAddressZip, setBillingAddressZip] = useState('')
  const [billingAddressCountry, setBillingAddressCountry] = useState('')

  const [shippingAddressStreet, setShippingAddressStreet] = useState('')
  const [shippingAddressCity, setShippingAddressCity] = useState('')
  const [shippingAddressState, setShippingAddressState] = useState('')
  const [shippingAddressZip, setShippingAddressZip] = useState('')
  const [shippingAddressCountry, setShippingAddressCountry] = useState('')

  const account: Account = useSelector((state: RootStateOrAny) => state.account)

  useEffect(() => {
    const source = axios.CancelToken.source()

    setBillingAddressStreet('')
    setBillingAddressCity('')
    setBillingAddressState('')
    setBillingAddressZip('')
    setBillingAddressCountry('')
    setShippingAddressStreet('')
    setShippingAddressCity('')
    setShippingAddressState('')
    setShippingAddressZip('')
    setShippingAddressCountry('')

    if (account) {
      dispatch(startLoading())
      if (account.id) {
        api
          .get(`/servicenow/account/${account.id}/details`, { cancelToken: source.token })
          .then((details_response: AxiosResponse) => {
            setValue(details_response.data[0]['name'])
            setPhone(details_response.data[0]['phone'])
            setEmail(details_response.data[0]['u_company_email'])
            setBillingEmail(details_response.data[0]['u_account_email'])

            api
              .get(`/servicenow/account/${account.id}/locations`, { cancelToken: source.token })
              .then((locations_response) => {
                for (const location of locations_response.data) {
                  if (location.u_default_billing_address === 'Yes') {
                    setBillingAddressStreet(location.street)
                    setBillingAddressCity(location.city)
                    setBillingAddressState(location.state)
                    setBillingAddressZip(location.zip)
                    setBillingAddressCountry(location.country)
                  }
                  if (location.u_default_shipping_address === 'Yes') {
                    setShippingAddressStreet(location.street)
                    setShippingAddressCity(location.city)
                    setShippingAddressState(location.state)
                    setShippingAddressZip(location.zip)
                    setShippingAddressCountry(location.country)
                  }
                }
                dispatch(stopLoading())
              })
              .catch((error) => {
                if (!axios.isCancel(error)) {
                  dispatch(stopLoading())
                  dispatch(
                    showAlert({
                      type: 'error',
                      message: 'We were unable to load your addresses, please try again later.',
                      component: 'MyAccount',
                      error
                    })
                  )
                }
              })
          })
          .catch((error) => {
            if (!axios.isCancel(error)) {
              dispatch(stopLoading())
              dispatch(
                showAlert({
                  type: 'error',
                  message: 'We were unable to load your support contacts, please try again later.',
                  component: 'KeyContacts',
                  error
                })
              )
            }
          })
      }
    }

    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.id])

  return (
    <Section>
      <Tile>
        <div className="grid grid-flow-row-dense grid-cols-3 gap-4 lg:grid-cols-6">
          <div className="col-span-2 flex items-center gap-8">
            <div className="">Company Name</div>
          </div>
          <div className="col-span-3 flex items-center">
            <div className="font-bold">{value}</div>
          </div>
          <div className="flex flex-none items-center justify-end"></div>
          <div className="col-span-2 flex items-center gap-8">
            <div className="">Phone</div>
          </div>
          <div className="col-span-3 flex items-center">
            <div className="font-bold">{phone}</div>
          </div>
          <div className="flex flex-none items-center justify-end"></div>
          <div className="col-span-2 flex items-center gap-8">
            <div className="">Email</div>
          </div>
          <div className="col-span-3 flex items-center">
            <div className="font-bold">{email}</div>
          </div>
          <div className="flex flex-none items-center justify-end"></div>
          <div className="col-span-2 flex items-center gap-8">
            <div className="">Billing Email</div>
          </div>
          <div className="col-span-3 flex items-center">
            <div className="font-bold">{billingEmail}</div>
          </div>
          <div className="flex flex-none items-center justify-end"></div>
        </div>
      </Tile>
      <Spacer />
      {billingAddressStreet && (
        <Tile>
          <Heading text="Billing Address" />
          <div className="grid grid-flow-row-dense grid-cols-3 gap-4 lg:grid-cols-6">
            <div className="col-span-2 flex items-center gap-8">
              <div className="">Street</div>
            </div>
            <div className="col-span-3 flex items-center">
              <div className="font-bold">{billingAddressStreet}</div>
            </div>
            <div className="flex flex-none items-center justify-end"></div>
            <div className="col-span-2 flex items-center gap-8">
              <div className="">City</div>
            </div>
            <div className="col-span-3 flex items-center">
              <div className="font-bold">{billingAddressCity}</div>
            </div>
            <div className="flex flex-none items-center justify-end"></div>
            <div className="col-span-2 flex items-center gap-8">
              <div className="">State</div>
            </div>
            <div className="col-span-3 flex items-center">
              <div className="font-bold">{billingAddressState}</div>
            </div>
            <div className="flex flex-none items-center justify-end"></div>
            <div className="col-span-2 flex items-center gap-8">
              <div className="">ZIP</div>
            </div>
            <div className="col-span-3 flex items-center">
              <div className="font-bold">{billingAddressZip}</div>
            </div>
            <div className="flex flex-none items-center justify-end"></div>
            <div className="col-span-2 flex items-center gap-8">
              <div className="">Country</div>
            </div>
            <div className="col-span-3 flex items-center">
              <div className="font-bold">{billingAddressCountry}</div>
            </div>
            <div className="flex flex-none items-center justify-end"></div>
          </div>
        </Tile>
      )}
      <Spacer />
      {shippingAddressStreet && (
        <Tile>
          <Heading text="Shipping Address" />
          <div className="grid grid-flow-row-dense grid-cols-3 gap-4 lg:grid-cols-6">
            <div className="col-span-2 flex items-center gap-8">
              <div className="">Street</div>
            </div>
            <div className="col-span-3 flex items-center">
              <div className="font-bold">{shippingAddressStreet}</div>
            </div>
            <div className="flex flex-none items-center justify-end"></div>
            <div className="col-span-2 flex items-center gap-8">
              <div className="">City</div>
            </div>
            <div className="col-span-3 flex items-center">
              <div className="font-bold">{shippingAddressCity}</div>
            </div>
            <div className="flex flex-none items-center justify-end"></div>
            <div className="col-span-2 flex items-center gap-8">
              <div className="">State</div>
            </div>
            <div className="col-span-3 flex items-center">
              <div className="font-bold">{shippingAddressState}</div>
            </div>
            <div className="flex flex-none items-center justify-end"></div>
            <div className="col-span-2 flex items-center gap-8">
              <div className="">ZIP</div>
            </div>
            <div className="col-span-3 flex items-center">
              <div className="font-bold">{shippingAddressZip}</div>
            </div>
            <div className="flex flex-none items-center justify-end"></div>
            <div className="col-span-2 flex items-center gap-8">
              <div className="">Country</div>
            </div>
            <div className="col-span-3 flex items-center">
              <div className="font-bold">{shippingAddressCountry}</div>
            </div>
            <div className="flex flex-none items-center justify-end"></div>
          </div>
        </Tile>
      )}
      <Spacer />
      <KeyContacts title="Key Contacts" />
    </Section>
  )
}

const MyAccountWithTracking = withAITracking(reactPlugin, MyAccount, 'My Account')
export default MyAccountWithTracking
