import { Fragment, type FunctionComponent, useEffect, useState } from 'react'

import { type Attachment } from '../../types/attachment'
import { type Invoice } from '../../types/invoice'

import moment from 'moment'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { showAlert } from '../../actions/alertsActions'
import { closeDrawers } from '../../actions/drawerActions'
import api from '../../constants/api'
import Button from '../Common/Button'
import Drawer from '../Common/Drawer'
import State from '../Common/State'

const InvoiceDrawer: FunctionComponent = (): JSX.Element => {
  const account = useSelector((state: RootStateOrAny) => state.account)
  const invoice: Invoice = useSelector((state: RootStateOrAny) => state.drawers.invoice)

  const [loading, setLoading] = useState(true)
  const [attachments, setAttachments] = useState<Attachment[]>([])

  const dispatch = useDispatch()

  useEffect(() => {
    if (invoice) {
      setLoading(true)
      //get invoice attachments
      api
        .get(`/servicenow/account/${account.id}/u_sales_invoice/${invoice.id}/attachments`)
        .then((response) => {
          /* save attachments to state */
          setAttachments(response.data)
        })
        .catch((error) => {
          setLoading(false)

          /* alert the user that the attachments couldn't be retrieved */
          dispatch(
            showAlert({
              type: 'error',
              message: "We couldn't retrieve the attachments for " + invoice.number + ' at this time. Please try again later.',
              component: 'Invoice',
              error
            })
          )
        })

      setLoading(false)
    }
  }, [invoice, account, dispatch])

  const formatBytes = (bytes: number, decimals = 0) => {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  /* download an attachment */
  const downloadAttachment = (attachment: Attachment) => {
    api
      .get(`/servicenow/account/${account.id}/u_sales_invoice/${invoice.id}/attachment/${attachment.id}`, {
        responseType: 'blob'
      })
      .then((response) => {
        const blob = new Blob([response.data])
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')

        a.style.display = 'none'
        a.href = url
        a.download = attachment.name

        document.body.appendChild(a)
        a.click()

        return window.URL.revokeObjectURL(url)
      })
      .catch((error) => {
        /* alert the user that the attachment couldn't be downloaded */
        dispatch(
          showAlert({
            type: 'error',
            message: "We couldn't download " + attachment.name + ' at this time. Please try again later.',
            component: 'Invoice',
            error
          })
        )
      })
  }

  /* drawer actions */
  const Actions: FunctionComponent = (): JSX.Element => {
    return (
      <Fragment>
        <Button bordered onClick={() => dispatch(closeDrawers())}>
          Close
        </Button>
      </Fragment>
    )
  }

  /* drawer content */
  return (
    <Drawer title={invoice ? invoice.number : 'Invoice'} loading={loading} actions={<Actions />}>
      {/* state */}
      <div className="mb-4 flex justify-between">
        <State state={invoice.state} />
      </div>

      {/* amount */}
      <div className="mb-8 flex justify-between">
        {/* tax date */}
        <div>
          <div className="font-bold">Amount</div>
          <div className="text-sm">£{invoice.total}</div>
        </div>
      </div>

      <div className="mb-8 flex justify-between">
        {/* tax date */}
        <div>
          <div className="font-bold">Taxed on</div>
          <div className="text-sm">{moment(invoice.tax_date).format('DD/MM/yy')}</div>
        </div>
      </div>

      <hr className="my-8 border-th-border" />

      {/* attachment list */}
      <div className="mt-6">
        <div className="font-bold">Attachments</div>
        <ul>
          {attachments.map((attachment: Attachment) => (
            <li key={attachment.id} className="flex cursor-pointer items-center border-b border-th-border py-2 text-sm last:border-b-0">
              {/* attachment details */}
              <div className="flex flex-auto justify-between" onClick={() => downloadAttachment(attachment)}>
                <span>{attachment.name}</span>
                <span>{formatBytes(attachment.size)}</span>
              </div>
            </li>
          ))}
        </ul>
        {attachments.length < 1 && (
          <div className="flex flex-auto justify-between">
            <span>No attachments found</span>
          </div>
        )}
      </div>
    </Drawer>
  )
}

export default InvoiceDrawer
