export const startLoading = (): {
  type: string
} => {
  return {
    type: 'START_LOADING'
  }
}

export const stopLoading = (): {
  type: string
} => {
  return {
    type: 'STOP_LOADING'
  }
}
