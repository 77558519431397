import { Breadcrumb, type IBreadcrumbItem } from '@fluentui/react'

import { type FunctionComponent } from 'react'

import { Iconly } from 'react-iconly'

/* css */
import './Breadcrumbs.css'

interface Props {
  items: IBreadcrumbItem[]
}

/* breadcrumb divider */
const divider = (): JSX.Element => {
  return (
    <span aria-hidden="true" style={{ cursor: 'pointer', padding: 5 }} className="py-10 text-th-text-secondary">
      <Iconly name="ChevronRight" set="light" />
    </span>
  )
}

const Breadcrumbs: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { items } = props

  return <Breadcrumb items={items} dividerAs={divider} onReduceData={() => undefined} onGrowData={() => undefined} className="m-0" />
}

export default Breadcrumbs
