import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

import { classNames } from 'src/utils/classNames'

import DropdownTreeSelect, { type DropdownTreeSelectProps, type TreeNode } from 'react-dropdown-tree-select'

import Spinner from './Spinner'

type DropdownProps = DropdownTreeSelectProps & {
  label?: string
  loading?: boolean
  customTriggerIndicator?: React.ReactNode
  selectionLimit?: number
  sortCheckedFirst?: boolean
  hideCheckbox?: boolean
}

const Dropdown = forwardRef((props: DropdownProps, ref) => {
  const [_data, setData] = useState<TreeNode[]>([])

  useImperativeHandle(ref, () => ({
    clear: () => {
      setData(_data.map((treeNode: TreeNode) => ({ ...treeNode, checked: false })))
      props.onChange?.(null as any, [])
    }
  }))

  const [stringifiedData, setStringifiedData] = useState<string>('')
  useEffect(() => {
    const stringified = JSON.stringify((props.data as TreeNode[]).map((d) => ({ label: d.label, value: d.value, checked: d.checked, disabled: d.disabled })))
    if (stringified === stringifiedData) return

    setData((props.data as TreeNode[]) ?? [])
    setStringifiedData(stringified)
  }, [props.data, stringifiedData])

  const nodeLength = useMemo(() => {
    const getChildNodeLength = (treeNode: TreeNode) => {
      if (!treeNode.children) return 1

      let count = 1
      count += treeNode.children.length
      treeNode.children.forEach((childNode: TreeNode) => {
        count += getChildNodeLength(childNode)
      })

      return count
    }

    const count = _data.reduce((acc: number, treeNode: TreeNode) => {
      acc += getChildNodeLength(treeNode)
      return acc
    }, 0)

    return count
  }, [_data])

  const selectedNodeLength = useMemo(() => {
    const count = _data.reduce((acc: number, treeNode: TreeNode) => {
      if (!treeNode.children?.length) return acc + (treeNode.checked ? 1 : 0)
      return acc + treeNode.children.filter((childNode: TreeNode) => childNode.checked).length
    }, 0)

    return count
  }, [_data])

  const trigger = (
    <div className={classNames('flex items-center justify-between gap-2 text-th-text')}>
      <span className="overflow-hidden overflow-ellipsis whitespace-nowrap">
        {props.label ? props.label + ': ' : props.mode === 'simpleSelect' ? '' : 'Select: '}
        {props.mode === 'simpleSelect' && !props.loading && (
          <span className={classNames((props.mode !== 'simpleSelect' || props.label) && 'font-bold')}>
            {selectedNodeLength ? _data.find((treeNode: TreeNode) => treeNode.checked)?.label : 'None'}
          </span>
        )}
        {props.customTriggerIndicator}

        {props.mode !== 'simpleSelect' && !props.loading && !props.customTriggerIndicator && (
          <div className="inline-flex h-5 w-5 items-center justify-center rounded-full bg-th-primary text-center text-xs leading-4 text-th-white">
            {selectedNodeLength}
          </div>
        )}
      </span>

      {props.loading ? (
        <Spinner className="h-4 w-4" />
      ) : (
        <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="fill-th-text">
          <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
        </svg>
      )}
    </div>
  )

  const dropdownRef = useRef<DropdownTreeSelect>(null)

  const _onChange: typeof props.onChange = (currentNode, selectedNodes) => {
    props.onChange?.(currentNode, selectedNodes)

    // Side effect and internal tracking -- alter data
    setData((prevData) => {
      if (!currentNode) return prevData

      // If the mode is singleSelect, then we only needs to set the currentNode to checked
      if (props.mode === 'simpleSelect')
        return prevData.map((treeNode: TreeNode) => ({
          ...treeNode,
          checked: JSON.stringify(treeNode.value) === JSON.stringify(currentNode.value) ? currentNode.checked : false
        }))

      // DFS to find node and alter it
      const alterNode = (treeNode: TreeNode, selected: boolean) => {
        if (treeNode.value === currentNode.value) {
          treeNode.checked = selected

          const alterChildren = (treeNode: TreeNode, selected: boolean) => {
            if (!treeNode.children) return

            treeNode.children.forEach((childNode: TreeNode) => {
              childNode.checked = selected
              alterChildren(childNode, selected)
            })
          }

          alterChildren(treeNode, selected)

          const findParent = (treeNode: TreeNode, value: string): TreeNode | undefined => {
            if (!treeNode.children) return

            if (treeNode.children.some((childNode: TreeNode) => childNode.value === value)) return treeNode

            let result
            treeNode.children.forEach((childNode: TreeNode) => {
              result = findParent(childNode, value)
            })

            return result
          }

          const parentNode = prevData.find((treeNode: TreeNode) => findParent(treeNode, currentNode.value))
          if (!parentNode) return

          const allChildrenChecked = parentNode.children?.every((childNode: TreeNode) => childNode.checked) ?? false
          parentNode.checked = allChildrenChecked

          return
        }

        if (!treeNode.children) return

        treeNode.children.forEach((childNode: TreeNode) => {
          alterNode(childNode, selected)
        })
      }

      const alteredData = prevData.map((treeNode: TreeNode) => {
        alterNode(treeNode, currentNode.checked || false)
        return treeNode
      })

      return alteredData
    })
  }

  const { customTriggerIndicator, ...rest } = props

  const DropdownTreeSelectM = useMemo(() => {
    // sort by check first
    const mappedData = _data.map((d) => ({ ...d, label: d.label })) // d.label.length > 35 ? `${d.label.slice(0, 35)}...` : d.label }))

    const sortedData =
      props.mode !== 'simpleSelect' && props.sortCheckedFirst
        ? mappedData.toSorted((a, b) => {
            if (a.checked && !b.checked) return -1
            if (!a.checked && b.checked) return 1
            return 0
          })
        : mappedData

    const selectionLimited = props.selectionLimit
      ? sortedData.map((d) => ({ ...d, disabled: !d.checked && selectedNodeLength >= props.selectionLimit! }))
      : sortedData

    const emptyData = [{ label: 'No results found', value: 'no-results', disabled: true, selectable: false }]
    const alteredData = nodeLength ? selectionLimited : emptyData

    return (
      <DropdownTreeSelect
        keepTreeOnSearch
        disablePoppingOnBackspace
        inlineSearchInput
        showPartiallySelected
        clearSearchOnChange={false}
        keepChildrenOnSearch
        {...props}
        data={alteredData}
        className={classNames(
          nodeLength <= 11 && 'hide-search',
          (props.disabled || props.loading) && 'pointer-events-none opacity-50',
          props.hideCheckbox && 'hide-checkbox',
          props.className
        )}
        texts={{ ...props.texts, placeholder: (trigger as unknown as string) || props.texts?.placeholder }}
        onChange={_onChange}
        disabled={props.disabled || props.loading}
        ref={dropdownRef}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_data, JSON.stringify(rest), nodeLength, selectedNodeLength, customTriggerIndicator])

  useEffect(() => {
    if (!dropdownRef.current) return

    const search = dropdownRef.current.node.querySelector('input.search') as HTMLInputElement
    if (!search) return

    const inputChange = (dropdownRef.current as unknown as { onInputChange: (value: string) => void }).onInputChange
    inputChange(search.value)
  }, [DropdownTreeSelectM])

  return <>{DropdownTreeSelectM}</>
})

export default Dropdown
