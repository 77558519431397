import React, { Fragment, useEffect, useRef, useState } from 'react'

import Popover from 'src/components/Common/Popover'

import { PiCaretRight, PiDotsThree, PiHouse } from 'react-icons/pi'
import { useSelector } from 'react-redux'
import { type FolderState } from 'src/reducers/folderReducer'

interface Folder {
  id: string
  name: string
}

interface BreadcrumbComponentProps {
  folderPath: Folder[]
  handleFolderNavigate: (index: number) => void
}

const BreadcrumbComponent: React.FC<BreadcrumbComponentProps> = ({ folderPath, handleFolderNavigate }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const hiddenContainerRef = useRef<HTMLDivElement>(null)
  const [visibleCrumbs, setVisibleCrumbs] = useState<number[]>([])

  const search = useSelector((state: FolderState) => state.folder.search)
  const disabled = !!search

  useEffect(() => {
    const updateVisibleCrumbs = () => {
      if (!containerRef.current || !hiddenContainerRef.current) return

      const containerWidth = containerRef.current.getBoundingClientRect().width - 144 // Account for the home icon
      const crumbElements = Array.from(hiddenContainerRef.current.children)
      let availableWidth = containerWidth
      const newVisibleCrumbs: number[] = []

      // Always show the first and last items
      newVisibleCrumbs.push(0, folderPath.length - 1)
      availableWidth -=
        ((crumbElements[0] as HTMLElement)?.getBoundingClientRect().width ?? 0) +
        ((crumbElements[crumbElements.length - 1] as HTMLElement)?.getBoundingClientRect().width || 0)

      // Add middle items if there's space
      for (let i = 1; i < folderPath.length - 1; i++) {
        const crumbWidth = (crumbElements[i * 2] as HTMLElement)?.getBoundingClientRect().width + 20 ?? 20 // Account for separators
        if (availableWidth >= crumbWidth) {
          newVisibleCrumbs.push(i)
          availableWidth -= crumbWidth
        } else {
          break
        }
      }

      setVisibleCrumbs(newVisibleCrumbs.sort((a, b) => a - b))
    }

    updateVisibleCrumbs()

    // Use ResizeObserver to update visible crumbs when the container resizes
    const resizeObserver = new ResizeObserver(updateVisibleCrumbs)
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current)
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [folderPath])

  const renderBreadcrumbs = () => {
    const isEllipses = (index: number) => index > visibleCrumbs[0] && index < visibleCrumbs[visibleCrumbs.length - 1] && !visibleCrumbs.includes(index)
    const ellipsesStartIndex = folderPath.findIndex((_, i) => isEllipses(i))
    const ellipsesEndIndex = folderPath.findLastIndex((_, i) => isEllipses(i))

    const ellipsesPath = folderPath.slice(ellipsesStartIndex, ellipsesEndIndex + 1).reduce((acc, folder) => acc + `/${folder.name}`, '')

    return folderPath.map((folder, index) => {
      const isVisible = visibleCrumbs.includes(index)
      const isEllipsis = ellipsesStartIndex === index

      if (isVisible || isEllipsis) {
        return (
          <Fragment key={folder.id}>
            {index > 0 && <PiCaretRight className={`mx-1 h-5 w-5 shrink-0 ${disabled ? 'opacity-50' : ''}`} />}

            <Popover text={index === 0 ? 'Home' : isEllipsis ? ellipsesPath : folder.name} className="flex items-center" disabled={disabled}>
              <button
                onClick={() => handleFolderNavigate(index)}
                className={`truncate text-sm font-medium ${
                  isEllipsis ? 'w-6' : 'max-w-[192px]'
                } hover:bg-gray-100 shrink-0 rounded font-semibold underline underline-offset-4 disabled:pointer-events-none disabled:select-none disabled:opacity-50`}
                disabled={disabled}
              >
                {isEllipsis ? <PiDotsThree className="h-5 w-5" /> : index === 0 ? <PiHouse className="h-5 w-5" /> : folder.name}
              </button>
            </Popover>
          </Fragment>
        )
      }

      return null
    })
  }

  return (
    <>
      <div ref={containerRef} className="flex grow items-center overflow-hidden">
        {renderBreadcrumbs()}
      </div>
      <div ref={hiddenContainerRef} className="absolute left-[-9999px] whitespace-nowrap">
        {folderPath.map((folder, index) => (
          <Fragment key={folder.id}>
            {index > 0 && <PiCaretRight className="mx-1 h-5 w-5 shrink-0" />}
            <span className="text-sm font-medium">{folder.name}</span>
          </Fragment>
        ))}
      </div>
    </>
  )
}

export default BreadcrumbComponent
