import { Shimmer, ShimmerElementType, ShimmerElementsGroup, createTheme } from '@fluentui/react'

import React, { useMemo, useState } from 'react'

import { type Tenant } from 'src/types/azure'
import { type Subscription as SubscriptionType } from 'src/types/microsoft365'

import { showAlert } from 'src/actions/alertsActions'

import useCancelSubscription from 'src/hooks/services/microsoft/mutations/useCancelSubscriptionMutation'
import useUpdateSubscription from 'src/hooks/services/microsoft/mutations/useUpdateSubscriptionMutation'
import { useSubscriptionPriceQuery } from 'src/hooks/services/microsoft/queries/useSubscriptionPriceQuery'
import useAccount from 'src/hooks/utils/useAccount'

import Button from 'src/components/Common/Button'
import Input from 'src/components/Common/Input'
import Pin from 'src/components/Common/Pin'
import Popover from 'src/components/Common/Popover'
import Tile from 'src/components/Common/Tile'
import ConfirmationModal from 'src/components/Modals/ConfirmationModal'

import { classNames } from 'src/utils/classNames'
import { calculateAnnualizedPrice, calculatePriceChange, formatPrice } from 'src/utils/subscriptions'

import moment from 'moment'
import { IoCheckmarkSharp, IoCloseSharp, IoEllipsisHorizontalSharp, IoReloadSharp, IoTimeOutline } from 'react-icons/io5'
import { useDispatch } from 'react-redux'

interface Props {
  tenant: Tenant | null
  subscription?: SubscriptionType | null
}

const termDurations = {
  P1M: '1 Month',
  P1Y: '1 Year',
  P3Y: '3 Years'
}

const shimmerElements = (): JSX.Element => {
  return (
    <div style={{ display: 'flex' }}>
      {/* each shimmer elements container matches the height of the tallest container as they are wrapped.
        This means the gaps between wrapped shimmer elements is determnined by setting the height value
        relative to the height of the tallest element */}
      <ShimmerElementsGroup
        flexWrap
        width="100%"
        height="320px"
        shimmerElements={[
          /* title */
          { type: ShimmerElementType.line, width: '100%', height: 40 },
          /* id */
          { type: ShimmerElementType.line, width: '50%', height: 20 },
          { type: ShimmerElementType.gap, width: '50%', height: 20 },
          /* description */
          { type: ShimmerElementType.line, width: '100%', height: 40 },
          { type: ShimmerElementType.line, width: '100%', height: 40 },
          { type: ShimmerElementType.line, width: '100%', height: 40 },
          { type: ShimmerElementType.line, width: '100%', height: 40 },
          { type: ShimmerElementType.line, width: '100%', height: 40 },
          /* promotion */
          { type: ShimmerElementType.line, width: '50%', height: 20 },
          { type: ShimmerElementType.gap, width: '50%', height: 20 }
        ]}
        backgroundColor="var(--content)"
      />
    </div>
  )
}

const Subscription: React.FC<Props> = ({ tenant, subscription }) => {
  const [quantity, setQuantity] = useState<number>(subscription?.Quantity || 0)
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false)

  const dispatch = useDispatch()
  const account = useAccount()

  const { data: price } = useSubscriptionPriceQuery(account.id, subscription)
  const updateSubscriptionMutation = useUpdateSubscription()
  const cancelSubscriptionMutation = useCancelSubscription()

  const updateQuantity = () => {
    if (!subscription || !tenant) return

    const totalSpend = price ? calculateAnnualizedPrice(price, subscription) * quantity : price
    updateSubscriptionMutation.mutate(
      { accountId: account.id, tenantId: tenant.id, subscriptionId: subscription.Id, quantity, totalSpend },
      {
        onSuccess: () => {
          dispatch(
            showAlert({
              type: 'success',
              message: 'Subscription quantity successfully updated. It may take a few minutes for the change to be reflected.',
              component: 'Subscription'
            })
          )
          setIsUpdateModalOpen(false)
        },
        onError: (error: any) => {
          dispatch(
            showAlert({
              type: 'error',
              message: error.response?.data || 'An error occurred while updating the subscription.',
              component: 'Subscription',
              error
            })
          )
        }
      }
    )
  }

  const cancelSubscription = () => {
    if (!subscription || !tenant) return

    cancelSubscriptionMutation.mutate(
      { accountId: account.id, tenantId: tenant.id, subscriptionId: subscription.Id },
      {
        onSuccess: () => {
          dispatch(
            showAlert({
              type: 'success',
              message: 'Your subscription has been cancelled. It may take a few minutes for the change to be reflected.',
              component: 'Subscription'
            })
          )
          setIsCancelModalOpen(false)
        },
        onError: (error: any) => {
          dispatch(
            showAlert({
              type: 'error',
              message: error.response?.data || 'An error occurred while cancelling the subscription.',
              component: 'Subscription',
              error
            })
          )
        }
      }
    )
  }

  const customThemeForShimmer = useMemo(
    () =>
      createTheme({
        palette: {
          themeSecondary: 'var(--translucent)',
          themeTertiary: 'var(--lowlight)'
        }
      }),
    []
  )

  if (!subscription) {
    return (
      <Tile className="relative text-th-text">
        <Shimmer
          customElementsGroup={shimmerElements()}
          shimmerColors={{
            shimmer: customThemeForShimmer.palette.themeTertiary,
            shimmerWave: customThemeForShimmer.palette.themeSecondary
          }}
        />
      </Tile>
    )
  }

  const isActive = subscription.Status === 'active'
  const isPending = subscription.Status === 'pending'
  const isDeleted = subscription.Status === 'deleted' || subscription.Status === 'expired'

  return (
    <Tile className={classNames('text-th-text', isDeleted && 'pointer-events-none select-none')}>
      <ConfirmationModal
        isOpen={isUpdateModalOpen}
        onClose={() => setIsUpdateModalOpen(false)}
        onConfirm={updateQuantity}
        title="Confirm Update"
        isLoading={updateSubscriptionMutation.isLoading}
      >
        <div className="flex flex-col gap-4">
          <div className="text-base">You are about to update the quantity of this subscription. Are you sure you want to proceed?</div>
          <div className="text-sm">
            <strong>Current Quantity:</strong> {subscription?.Quantity}
          </div>
          <div className="text-sm">
            <strong>New Quantity:</strong> {quantity}
          </div>
          {price && subscription ? (
            <div className="text-sm">
              This will change your cost by {formatPrice(calculatePriceChange(price, subscription, quantity, subscription?.Quantity || 0), subscription)}
            </div>
          ) : (
            <div className="rounded-lg bg-th-warning p-2 text-center text-sm font-bold text-th-white">
              Unable to check the price change. Please make sure to verify the price before confirming.
            </div>
          )}
        </div>
      </ConfirmationModal>

      <ConfirmationModal
        isOpen={isCancelModalOpen}
        onClose={() => setIsCancelModalOpen(false)}
        onConfirm={cancelSubscription}
        title="Confirm Cancellation"
        isLoading={cancelSubscriptionMutation.isLoading}
      >
        <div className="text-base">Are you sure you want to cancel this subscription?</div>
      </ConfirmationModal>

      <div className={classNames('flex flex-col gap-4', isDeleted ? 'opacity-50' : 'opacity-100')}>
        <div className="flex justify-between gap-4">
          <div>
            <div className="text-lg font-bold">{subscription.FriendlyName}</div>
            <div className="text-sm text-th-text-secondary">{subscription.Id.toLowerCase()}</div>
          </div>
          <div className="flex gap-1">
            {subscription.IsTrial && <Pin icon={<IoTimeOutline className="h-5 w-5" />} popoverText="Trial" />}
            {subscription.AutoRenewEnabled && <Pin icon={<IoReloadSharp className="h-5 w-5" />} popoverText="Auto Renew Enabled" />}
            {isActive && <Pin icon={<IoCheckmarkSharp className="h-5 w-5" />} popoverText="Active" />}
            {isPending && <Pin icon={<IoEllipsisHorizontalSharp className="h-5 w-5" />} popoverText="Pending" />}
            {isDeleted && <Pin icon={<IoCloseSharp className="h-5 w-5" />} popoverText="Deleted/Expired" />}
          </div>
        </div>

        <SubscriptionDetails subscription={subscription} />
        <SubscriptionPricing subscription={subscription} price={price} quantity={quantity} />

        <div className="mt-2 flex flex-col gap-4">
          <QuantityInput quantity={quantity} setQuantity={setQuantity} disabled={isDeleted} />
          <TotalCost subscription={subscription} price={price} quantity={quantity} />
          <SubscriptionActions
            subscription={subscription}
            quantity={quantity}
            onCancel={() => setIsCancelModalOpen(true)}
            onUpdate={() => setIsUpdateModalOpen(true)}
          />
          <div className="text-xs">Cancellation allowed until: {moment(subscription.CancellationAllowedUntilDate).format('DD/MM/yyyy HH:mm')}</div>
        </div>
      </div>
    </Tile>
  )
}

const SubscriptionDetails: React.FC<{ subscription: SubscriptionType }> = ({ subscription }) => (
  <>
    <div className="flex flex-col">
      <div className="flex justify-between">
        Commitment Start Date: <strong>{moment(subscription.EffectiveStartDate).format('DD/MM/yyyy')}</strong>
      </div>
      <div className="flex justify-between">
        Commitment End Date: <strong>{moment(subscription.CommitmentEndDate).format('DD/MM/yyyy')}</strong>
      </div>
    </div>
    <div className="flex flex-col">
      <div className="flex justify-between">
        Billing Cycle: <strong>{subscription.BillingCycle.charAt(0).toUpperCase() + subscription.BillingCycle.slice(1)}</strong>
      </div>
      <div className="flex justify-between">
        Term Duration: <strong>{termDurations[subscription.TermDuration as keyof typeof termDurations]}</strong>
      </div>
    </div>
  </>
)

const SubscriptionPricing: React.FC<{ subscription: SubscriptionType; price: any; quantity: number }> = ({ subscription, price }) => (
  <div className="flex justify-between">
    Price/License:{' '}
    <strong>
      {price ? (
        <div>
          {formatPrice(calculateAnnualizedPrice(price, subscription), subscription)}
          &nbsp; excl. VAT
        </div>
      ) : (
        'Price Unavailable'
      )}
    </strong>
  </div>
)

const QuantityInput: React.FC<{ quantity: number; setQuantity: (q: number) => void; disabled: boolean }> = ({ quantity, setQuantity, disabled }) => (
  <div className="flex items-center justify-between gap-2">
    Quantity: <Input name="quantity" type="number" value={quantity} onChange={(e) => setQuantity(parseInt(e.target.value))} disabled={disabled} />
  </div>
)

const TotalCost: React.FC<{ subscription: SubscriptionType; price: any; quantity: number }> = ({ subscription, price, quantity }) => (
  <div className="flex justify-between">
    Total Cost:{' '}
    <strong>
      {price ? (
        <div>
          {formatPrice(calculateAnnualizedPrice(price, subscription) * quantity, subscription)}
          &nbsp; excl. VAT
        </div>
      ) : (
        'Price Unavailable'
      )}
    </strong>
  </div>
)

const SubscriptionActions: React.FC<{
  subscription: SubscriptionType
  quantity: number
  onCancel: () => void
  onUpdate: () => void
}> = ({ subscription, quantity, onCancel, onUpdate }) => (
  <div className="flex justify-between">
    <Button onClick={onCancel} bordered disabled={subscription.Status !== 'active'}>
      Cancel
    </Button>
    <Popover text="Adjust the quantity to enable updates" disabled={quantity !== subscription?.Quantity}>
      <Button onClick={onUpdate} variant="primary" disabled={subscription.Status !== 'active' || quantity === subscription?.Quantity}>
        Update
      </Button>
    </Popover>
  </div>
)

export default Subscription
