import useApprovalStatsQuery from '~/hooks/services/servicenow/queries/useApprovalStatsQuery'

import Count from '~/components/Common/Count'
import { Grid } from '~/components/Common/Grid'

import { FcAlarmClock, FcCancel, FcOk, FcServices } from 'react-icons/fc'

import { useChangeApprovalFilters } from '../../hooks/useChangeApprovalFilters'

export const ChangeApprovalStats = () => {
  const { filterOptions, handleStateFilter } = useChangeApprovalFilters()
  const { data } = useApprovalStatsQuery(filterOptions)

  return (
    <Grid>
      <Count
        icon={<FcAlarmClock className="h-full w-full" />}
        value={data?.requested ?? '...'}
        label="Requested"
        className="cursor-pointer"
        onClick={() => handleStateFilter('Requested')}
        active={filterOptions.state === 'Requested'}
      />
      <Count
        icon={<FcOk className="h-full w-full" />}
        value={data?.approved ?? '...'}
        label="Approved"
        className="cursor-pointer"
        onClick={() => handleStateFilter('Approved')}
        active={filterOptions.state === 'Approved'}
      />
      <Count
        icon={<FcCancel className="h-full w-full" />}
        value={data?.rejected ?? '...'}
        label="Rejected"
        className="cursor-pointer"
        onClick={() => handleStateFilter('Rejected')}
        active={filterOptions.state === 'Rejected'}
      />
      <Count
        icon={<FcServices className="h-full w-full text-th-warning" />}
        value={data?.expired ?? '...'}
        label="Expired"
        className="cursor-pointer"
        onClick={() => handleStateFilter('Expired')}
        active={filterOptions.state === 'Expired'}
      />
    </Grid>
  )
}
