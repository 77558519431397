import { PiCloudCheckFill, PiCloudFill, PiCloudWarningFill, PiCloudXFill, PiX } from 'react-icons/pi'

type CalloutProps = {
  type?: 'info' | 'success' | 'warning' | 'error'
  text: string
  className?: string
  onClose?: (() => void) | undefined | null
}

const parseCalloutText = (text: string) => {
  const regex = /\[(.*?)\]\((.*?)\)/g
  const parts = text.split(regex)

  const elements = []
  for (let i = 0; i < parts.length; i += 3) {
    // Text
    if (parts[i]) elements.push(<span key={`text-${i}`}>{parts[i]}</span>)

    // Links
    if (i + 1 < parts.length && parts[i + 1] && parts[i + 2]) {
      elements.push(
        <a href={parts[i + 2]} key={`link-${i + 1}`} className="text-th-white underline" target="_blank" rel="noreferrer">
          {parts[i + 1]}
        </a>
      )
    }
  }

  return elements
}

const Callout: React.FC<CalloutProps> = ({ type = 'info', text, className, onClose }) => {
  const colorMap = {
    info: 'bg-th-primary',
    success: 'bg-th-success',
    warning: 'bg-th-warning',
    error: 'bg-th-error'
  }

  const iconMap = {
    info: <PiCloudFill className="h-5 w-5" key="info" />,
    success: <PiCloudCheckFill className="h-5 w-5" key="success" />,
    warning: <PiCloudWarningFill className="h-5 w-5" key="warning" />,
    error: <PiCloudXFill className="h-5 w-5" key="error" />
  }

  const parsedText = parseCalloutText(text)

  return (
    <div className={`flex items-center rounded-full px-4 py-2 text-th-white ${colorMap[type]} ${className}`}>
      <div className="flex grow items-center justify-center gap-3">
        {iconMap[type]}
        <p className="text-sm font-bold">{parsedText}</p>
      </div>
      {onClose && <PiX className="ml-2 h-4 w-4 cursor-pointer" onClick={onClose} />}
    </div>
  )
}

export default Callout
