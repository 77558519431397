import { useMemo } from 'react'

const BLUE_GRADIENT_COLORS = [
  'rgba(179, 219, 231, 1)',
  'rgba(128, 195, 215, 1)',
  'rgba(77, 171, 198, 1)',
  'rgba(38, 153, 186, 1)',
  'rgba(0, 127, 167, 1)',
  'rgba(0, 116, 157, 1)',
  'rgba(0, 106, 148, 1)',
  'rgba(0, 87, 132, 1)',
  'rgba(20, 32, 59, 1)'
]

/**
 * Generates an array of blue gradient colors.
 * @param {number} amount - The number of colors to generate - max 9
 * @returns {string[]} An array of rgba color strings.
 * @example
 * const colors = getBlueGradientColors(5);
 * <div style={{ background: `linear-gradient(to bottom, ${colors.join(', ')})` }} />
 */
export const getBlueGradientColors = (amount: number): string[] => {
  const maxColors = BLUE_GRADIENT_COLORS.length
  const validAmount = amount > maxColors ? maxColors : amount

  // If only one color is requested, return the middle color
  if (validAmount === 1) {
    return [BLUE_GRADIENT_COLORS[Math.floor(maxColors / 2)]]
  }

  const step = (maxColors - 1) / (validAmount - 1)
  let selectedColors = []
  for (let i = 0; i < validAmount; i++) {
    const colorIndex = Math.round(i * step)
    selectedColors.push(BLUE_GRADIENT_COLORS[colorIndex])
  }

  return selectedColors
}

/**
 * Returns an array of colors, including a blue gradient if `gradientIncluded` is true.
 * @param {number} amount - The number of colors to return.
 * @param {boolean} [gradientIncluded=true] - Whether or not to include a blue gradient.
 * @returns {string[]} An array of colors.
 */
export const getRainbowColors = (amount: number, gradientIncluded = true) => {
  // Constants
  //const yellow = 'rgba(242, 180, 77, 1)'
  const purple = 'rgba(149, 139, 218, 1)'
  const red = 'rgba(216, 110, 122, 1)'
  const green = 'rgba(47, 157, 139, 1)'

  const gradient = amount - 3 > 0 ? getBlueGradientColors(amount - 3) : []
  const colorArray = gradientIncluded ? [purple, red, green, ...[gradient]] : [purple, red, green]
  return colorArray.flatMap((color) => (Array.isArray(color) ? color : [color]))
}

export const useColors = (colorful = false) => {
  const colors = useMemo(() => {
    const getColors = colorful ? getRainbowColors : getBlueGradientColors
    return getColors(8)
  }, [colorful])

  return colors
}
