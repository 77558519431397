import { type Account } from 'src/types/account'

import api from 'src/constants/api'

import type { Claims } from 'src/hooks/utils/useMsalClaims'
import useMutation from 'src/hooks/utils/useMutation'

import type { AxiosError } from 'axios'

import useCreateOwnContactRecordMutation from './useCreateOwnContactRecordMutation'
import useCreateOwnRelationshipMutation from './useCreateOwnRelationshipMutation'

const useSysIdByEmailMutation = () => {
  const createOwnContactRecordMutation = useCreateOwnContactRecordMutation()
  const createOwnRelationshipMutation = useCreateOwnRelationshipMutation()

  return useMutation(
    async ({ claims }: { claims?: Claims | null }) => {
      try {
        const response = await api.get('/servicenow/contact/email')
        await createOwnRelationshipMutation.mutateAsync()
        return response.data || null
      } catch (error) {
        if ((error as AxiosError).response?.status !== 404) throw error

        if (!claims?.email) return

        const account = (await api.get(`/servicenow/account/domain`)).data as Account
        if (!account) return

        await createOwnContactRecordMutation.mutateAsync(account.id)
      }
    },
    {
      errorMessage: 'Failed to fetch sys_id by email'
    }
  )
}

export default useSysIdByEmailMutation
