import { type UseMutationOptions as UseBaseMutationOptions, useMutation as useBaseMutation, useQueryClient } from '@tanstack/react-query'

import { showAlert } from 'src/actions/alertsActions'

import axios from 'axios'
import { useDispatch } from 'react-redux'

type CustomMutationOptions<TData, TError, TVariables, TContext> = UseBaseMutationOptions<TData, TError, TVariables, TContext> & {
  queryKeysToInvalidate?: any[]
  successMessage?: string
  errorMessage?: string
}

export type UseMutationOptions<TVariables, TData = any> = CustomMutationOptions<TData, any, TVariables, unknown>

const useMutation = <TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
  mutationFn: (variables: TVariables) => Promise<TData>,
  options?: CustomMutationOptions<TData, TError, TVariables, TContext>
) => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useBaseMutation<TData, TError, TVariables, TContext>({
    ...options,
    mutationFn: async (variables: TVariables) => {
      try {
        const response = await mutationFn(variables)

        if (options?.successMessage)
          dispatch(
            showAlert({
              type: 'success',
              message: options.successMessage,
              component: options?.mutationKey?.[0] ? JSON.stringify(options?.mutationKey?.[0]) : 'Unknown'
            })
          )
        return response
      } catch (error) {
        if (!axios.isCancel(error)) {
          dispatch(
            showAlert({
              type: 'error',
              message: options?.errorMessage || 'An error occurred while processing your request.',
              component: options?.mutationKey?.[0] ? JSON.stringify(options?.mutationKey?.[0]) : 'Unknown',
              error: error as Error
            })
          )
        }
        if (import.meta.env.MODE !== 'production') console.error('Mutation error:', error)

        throw error
      }
    },
    onSuccess: async (data, variables, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, variables, context)
      }

      if (options?.queryKeysToInvalidate) {
        await Promise.all(
          options.queryKeysToInvalidate.map((key) => {
            queryClient.invalidateQueries(key)
          })
        )
      }
    }
  })
}

export default useMutation
