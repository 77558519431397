import { useMsal } from '@azure/msal-react'

import { Fragment, type FunctionComponent, type ReactNode, useEffect, useMemo, useState } from 'react'

import { stopLoading } from 'src/actions/loadingActions'

import useSetUserSysIdMutation from 'src/hooks/services/provide/mutations/useSetUserSysIdMutation'
import useUserQuery from 'src/hooks/services/provide/queries/useUserQuery'
import useSysIdByEmailMutation from 'src/hooks/services/servicenow/mutations/useSysIdByEmailMutation'
import useAccountsQuery from 'src/hooks/services/servicenow/queries/useAccountsQuery'
import { useTenantsQuery } from 'src/hooks/services/servicenow/queries/useTenantsQuery'
import useMsalClaims from 'src/hooks/utils/useMsalClaims'

import { useDispatch } from 'react-redux'

import Button from '../Common/Button'

/* Clears Cached Tokens From Localstorage for Account */
const removeCachedTokens = () => {
  const keys = Object.keys(localStorage).filter((x) => {
    const value = localStorage.getItem(x) || ''

    if (value.indexOf('credentialType') >= 0 || value.indexOf('authorityType')) {
      if (value.indexOf('{') >= 0) {
        const json = JSON.parse(value)

        return json.credentialType === 'AccessToken' || json.credentialType === 'IdToken' || json.authorityType === 'MSSTS'
      }
    }

    return false
  })

  keys.forEach((x) => localStorage.removeItem(x))
}

interface Props {
  setLoading: (bool: boolean, message?: string) => void
  children: ReactNode
}

const Initialisation: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { children } = props

  const { isLoading, isSubscriptionError } = useTenantsQuery() // Initializes Global Tenant and Subscriptions

  const [error, setError] = useState<ReactNode>()
  const dispatch = useDispatch()

  const { instance } = useMsal()

  const { data: claims } = useMsalClaims()
  const { data: user, fetchStatus: userFetchStatus } = useUserQuery({ claims })
  const { data: accounts, fetchStatus: accountFetchStatus } = useAccountsQuery()

  const userError = useMemo(
    () => (
      <div className="flex flex-col items-center gap-2">
        <div className="text-center">We couldn't retrieve your profile.</div>
        <div className="text-center">Please try again later.</div>
      </div>
    ),
    []
  )

  const profileLinkingError = useMemo(
    () => (
      <div className="flex flex-col items-center gap-2">
        <div className="text-center">We could not link your profile to an account.</div>
        <div className="text-center">Please contact your account admin and ask them to add you to your account.</div>
        <div className="text-center">The changes will take effect the next time you log in.</div>
      </div>
    ),
    []
  )

  const accountsError = useMemo(
    () => (
      <div className="flex flex-col items-center gap-2">
        <div className="text-center">We couldn't retrieve your account(s).</div>
        <div className="text-center">Please try again later.</div>
      </div>
    ),
    []
  )

  useEffect(() => {
    if (user === undefined) return props.setLoading(true, 'Retrieving your profile...')
    if (!user && userFetchStatus === 'idle' && claims?.sub && claims.sys_id) return setError(userError)
  }, [user, userFetchStatus, claims, props, userError])

  useEffect(() => {
    if (accounts === undefined) return props.setLoading(true, 'Retrieving your account(s)...')
    if (!accounts?.length && accountFetchStatus === 'idle') return setError(accountsError)
  }, [accounts, accountFetchStatus, props, accountsError])

  useEffect(() => {
    if (isSubscriptionError) return props.setLoading(false)
  }, [isSubscriptionError, props])

  useEffect(() => {
    if (isLoading || !user || !accounts) return
    if (user && accounts) {
      props.setLoading(false)
      dispatch(stopLoading())
    }
  }, [isLoading, user, accounts, props, dispatch])

  const { mutateAsync: getSysIdByEmail } = useSysIdByEmailMutation()
  const { mutateAsync: setUserSysId } = useSetUserSysIdMutation()

  /* get and update the users profile */
  useEffect(() => {
    if (!claims?.sub || claims?.sys_id) return

    // Get User sys_id if they do not already have one
    const initializeUser = async () => {
      props.setLoading(true, 'Updating your profile...')

      const record = await getSysIdByEmail({ claims })
      if (!record?.id) return setError(profileLinkingError)

      await setUserSysId(record.id)

      /* clear out the old tokens */
      removeCachedTokens()

      /* wait five seconds for the users b2c profile to update */
      await new Promise((r) => setTimeout(r, 2000))

      props.setLoading(true, 'Restarting your session...')

      await new Promise((r) => setTimeout(r, 3000))

      /* start a new session retrieving new claims */
      const login = async () => {
        await instance.handleRedirectPromise()
        await instance.loginRedirect()
      }

      login()
    }

    initializeUser()
  }, [claims, getSysIdByEmail, instance, profileLinkingError, props, setUserSysId])

  return (
    <Fragment>
      {error ? (
        <div className="flex h-screen w-screen items-center justify-center overflow-hidden bg-th-content p-8 text-th-text" style={{ minWidth: 360 }}>
          <div className="flex flex-col items-center gap-8">
            {error}
            <Button variant="primary" onClick={() => instance.logout()} className="w-max">
              Logout
            </Button>
          </div>
        </div>
      ) : (
        children
      )}
    </Fragment>
  )
}

export default Initialisation
