import { type ReactNode, useState } from 'react'

import type { ChangeApprovalFilterOptions } from '~/hooks/services/servicenow/queries/useChangeApprovalsQuery'

import { DEFAULT_FILTER_OPTIONS } from '../../constants'
import { ChangeApprovalFiltersContext } from './context'

export function ChangeApprovalFiltersProvider({ children }: { children: ReactNode }) {
  const [filterOptions, setFilterOptions] = useState<ChangeApprovalFilterOptions>(DEFAULT_FILTER_OPTIONS)

  return <ChangeApprovalFiltersContext.Provider value={{ filterOptions, setFilterOptions }}>{children}</ChangeApprovalFiltersContext.Provider>
}
