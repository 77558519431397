import { type Cart } from 'src/types/cart'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useMutation, { type UseMutationOptions } from 'src/hooks/utils/useMutation'

interface CartCheckoutMutation {
  tenantId: string
  cart: Cart
}

const useCartCheckoutMutation = (options?: UseMutationOptions<CartCheckoutMutation>) => {
  const { id: accountId } = useAccount()

  return useMutation(
    async ({ tenantId, cart }) => {
      const response = await api.post(`/ms-api/orders/account/${accountId}/${tenantId}/cart/${cart.Id}/checkout`, cart)
      return response.data
    },
    {
      ...options
    }
  )
}

export default useCartCheckoutMutation
