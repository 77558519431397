import { type FunctionComponent } from 'react'

import { Iconly } from 'react-iconly'
import { IoChevronForwardCircleOutline } from 'react-icons/io5'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { openUser } from '../../../../actions/userActions'

const User: FunctionComponent = (): JSX.Element => {
  const dispatch = useDispatch()
  const user = useSelector((state: RootStateOrAny) => state.user)
  const account = useSelector((state: RootStateOrAny) => state.account)

  return (
    <div
      onClick={() => dispatch(openUser())}
      className="user flex h-12 w-12 flex-none cursor-pointer items-center justify-center rounded-full bg-th-lowlight px-2.5 xs:mx-0 xs:h-14 xs:w-full xs:rounded-none xs:bg-th-secondary xs:hover:bg-th-navigation-parent-active"
    >
      <div className="flex items-center justify-center rounded-full xs:h-full xs:w-16 xs:rounded-none xs:p-3">
        <Iconly name="User" className="icon h-9 w-9 rounded-full p-1.5 xs:h-8 xs:w-8 xs:bg-th-translucent xs:p-2" />
      </div>
      <div className="hidden w-full flex-auto flex-col xs:flex">
        <div className="name flex w-full items-center text-sm font-bold leading-4">
          {user.first_name + ' ' + user.last_name}
          <IoChevronForwardCircleOutline className="-mt-0.5 ml-2 h-3.5 w-3.5" />
        </div>
        <div className="w-full text-2xs opacity-40">{account.name}</div>
      </div>
    </div>
  )
}

export default User
