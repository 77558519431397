import { type FunctionComponent } from 'react'

import Spinner from '../../../components/Common/Spinner'

/* utils */
import { classNames } from '../../../utils/classNames'

interface Props {
  className: string
}

const Loading: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { className } = props

  return (
    <div className={classNames('absolute left-0 z-40 -mt-1 flex items-center', 'justify-center bg-th-content opacity-90', className)}>
      <Spinner className="h-10 w-10" />
    </div>
  )
}

export default Loading
