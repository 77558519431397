import { type ColumnDef } from '@tanstack/react-table'

import React, { type FunctionComponent, useCallback, useMemo } from 'react'

import { type Account } from '../../types/account'
import { type Attachment } from '../../types/attachment'
import { type Invoice } from '../../types/invoice'

import moment from 'moment'
import { Iconly } from 'react-iconly'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { showAlert } from '../../actions/alertsActions'
import { closeDrawers, openInvoiceDrawer } from '../../actions/drawerActions'
import { startLoading, stopLoading } from '../../actions/loadingActions'

/* api */
import api from '../../constants/api'
import State from '../Common/State'
import Table from '../Common/Table/Table'

interface Props {
  invoices: Invoice[]
}

const InvoiceTable: FunctionComponent<Props> = ({ invoices }: Props): JSX.Element => {
  const account: Account = useSelector((state: RootStateOrAny) => state.account)
  const dispatch = useDispatch()

  /* download pdf */
  const downloadPDF = useCallback(
    (event: React.MouseEvent, invoice_id: string, invoice_number: string) => {
      event.stopPropagation()
      if (invoice_number) {
        dispatch(startLoading())

        api
          .get(`/servicenow/account/${account.id}/u_sales_invoice/${invoice_id}/attachments`)
          .then((response) => {
            let invoice_exists = false
            /* check if the pdf exists as an attachment */
            response.data.forEach(async (attachment: Attachment) => {
              if (attachment.name.toLowerCase() === (invoice_number + '.pdf').toLowerCase()) {
                invoice_exists = true
                /* download the attachment  */
                return await api
                  .get(`/servicenow/account/${account.id}/u_sales_invoice/${invoice_id}/attachment/${attachment.id}`, {
                    responseType: 'blob'
                  })
                  .then((response) => {
                    const blob = new Blob([response.data])
                    const url = window.URL.createObjectURL(blob)
                    const a = document.createElement('a')

                    a.style.display = 'none'
                    a.href = url
                    a.download = attachment.name

                    document.body.appendChild(a)
                    a.click()

                    dispatch(stopLoading())

                    return window.URL.revokeObjectURL(url)
                  })
                  .catch((error) => {
                    /* alert the user that the download failed */
                    dispatch(
                      showAlert({
                        type: 'error',
                        message: 'We were unable to download the PDF for invoice ' + invoice_number + '.',
                        component: 'InvoiceTable',
                        error
                      })
                    )

                    dispatch(stopLoading())
                  })
              }
            })

            /* alert the user that the pdf couldn't be found */
            if (!invoice_exists) {
              dispatch(
                showAlert({
                  type: 'info',
                  message: "We couldn't find the PDF for invoice " + invoice_number + '. It may not be ready yet.',
                  component: 'InvoiceTable'
                })
              )
            }

            dispatch(stopLoading())
          })
          .catch((error) => {
            dispatch(
              showAlert({
                type: 'error',
                message: 'We were unable to retrieve the PDF for invoice ' + invoice_number + '.',
                component: 'InvoiceTable',
                error
              })
            )

            dispatch(stopLoading())
          })
      }
    },
    [account.id, dispatch]
  )

  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'number',
          header: 'Number'
        },
        {
          accessorKey: 'tax_date',
          header: 'Tax Date',
          cell: (info: any) => moment(info.getValue()).format('DD/MM/YY'),
          sortingFn: (a: any, b: any, id: string) => moment(a.getValue(id)).unix() - moment(b.getValue(id)).unix()
        },
        {
          accessorKey: 'due_date',
          header: 'Due Date',
          cell: (info: any) => moment(info.getValue()).format('DD/MM/YY'),
          sortingFn: (a: any, b: any, id: string) => moment(a.getValue(id)).unix() - moment(b.getValue(id)).unix()
        },
        {
          accessorKey: 'subtotal',
          header: 'Subtotal (£)'
        },
        {
          accessorKey: 'tax',
          header: 'Tax (£)'
        },
        {
          accessorKey: 'total',
          header: 'Total (£)'
        },
        {
          accessorKey: 'state',
          header: 'State',
          cell: (info) => <State state={info.getValue() as string} />
        },
        {
          accessorKey: 'pdf',
          header: 'PDF',
          cell: (info) => (
            <Iconly
              set="light"
              name="PaperDownload"
              className="cursor-pointer"
              onClick={(event: React.MouseEvent) => downloadPDF(event, info.row.original.id, info.row.original.number)}
            />
          )
        }
      ] satisfies ColumnDef<(typeof invoices)[number]>[],
    [downloadPDF]
  )

  return (
    <Table
      data={invoices}
      columns={columns}
      onRowClick={(row) => {
        dispatch(closeDrawers())
        dispatch(openInvoiceDrawer(row))
      }}
    />
  )
}

export default InvoiceTable
