import { type FunctionComponent, useEffect, useState } from 'react'

import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import Scrollbar from 'react-smooth-scrollbar'

import { showAlert } from '../../actions/alertsActions'
import api from '../../constants/api'
import Input from '../Common/Input'
import Modal from '../Common/Modal'
import Submit from '../Common/Submit'
import Loading from '../Content/Loading/Loading'

const DelegateQuoteModal: FunctionComponent = (): JSX.Element => {
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [delegated, setDelegated] = useState(false)
  const [quoteDetailsStr, setQuoteDetailsStr] = useState('')
  const [pdf, setPDF] = useState('')
  const [delegate_org, setDelegateOrg] = useState('')
  const [delegate_fname, setDelegateFName] = useState('')
  const [delegate_lname, setDelegateLName] = useState('')
  const [delegate_email, setDelegateEmail] = useState('')
  const [delegate_jobtitle, setDelegateJobTitle] = useState('')

  const dispatch = useDispatch()

  const open = useSelector((state: RootStateOrAny) => state.modals.delegatequotemodal)
  const quote = useSelector((state: RootStateOrAny) => state.modals.quote)

  const confirmSignMode = () => {
    setSubmitted(true)
  }

  useEffect(() => {
    //load quote details and show form
    if (open) {
      setLoading(true)
      api.get(`old-provide/sign_quote/get_quote_info?id=${quote.encrypted_number}`).then((response) => {
        if (response.data.error) {
          setLoading(false)
          dispatch(
            showAlert({
              type: 'error',
              message: 'Error fetching details for Quote',
              component: 'DelegateQuote'
            })
          )
          return
        }
        setLoading(false)
        const details: string = response.data.quote_details
        setQuoteDetailsStr(details)
      })
    }
  }, [quote, open, dispatch])

  useEffect(() => {
    //generate pdf on submit
    setLoading(true)
    if (quoteDetailsStr && submitted) {
      const quoteDetails = JSON.parse(quoteDetailsStr)
      const quoteState = quoteDetails.qut_u_state
      const content = JSON.parse(quoteDetails.qut_u_contract_content)

      if (quoteState !== 'Sent') {
        if (quoteState !== 'Delegated') {
          dispatch(
            showAlert({
              type: 'error',
              message: 'The Signable document has already been requested for this Quote.',
              component: 'DelegateQuote'
            })
          )
          return
        }
      }
      const config = {
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: 'Basic cGRmZ2VuOkJXOW5pTVNU'
        }
      }

      const postData = new URLSearchParams()
      postData.append('quote_sys_id', quote.id)
      postData.append('quote_body', content.body)
      postData.append('quote_header', content.header)
      postData.append('quote_footer', content.footer)
      postData.append('internal', 'true')
      postData.append('snow_instance', 'cdstaging')

      api
        .post(`old-provide/sign_quote/pdf_gen`, postData, config)
        .then((response) => {
          setLoading(false)
          const pdfStr: string = response.data.pdf
          setPDF(pdfStr)
        })
        .catch((error) => {
          setLoading(false)
          dispatch(
            showAlert({
              type: 'error',
              message: 'We were unable to generate quote PDF for signing.',
              component: 'DelegateQuote',
              error
            })
          )
        })
    }
  }, [dispatch, quote.id, quoteDetailsStr, submitted])

  useEffect(() => {
    //wait for pdf_generation response and call send_doc to do the actual delegation
    setLoading(true)
    if (pdf) {
      const config = {
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: 'Basic cGRmZ2VuOkJXOW5pTVNU'
        }
      }

      const postData2 = new URLSearchParams()
      postData2.append('doc_title', quote.number)
      postData2.append('pdf', pdf)
      postData2.append('organisation', delegate_org)
      postData2.append('fname', delegate_fname)
      postData2.append('lname', delegate_lname)
      postData2.append('email', delegate_email)
      postData2.append('job_title', delegate_jobtitle)
      postData2.append('id', quote.number)
      postData2.append('sys_id', quote.id)
      postData2.append('snow_instance', 'cdstaging')
      postData2.append('self_sign', '0')
      api.post(`old-provide/sign_quote/send_doc`, postData2, config).then((response) => {
        setLoading(false)
        if (response.data.success) {
          setDelegated(true)
        }
      })
    }
  }, [delegate_email, delegate_fname, delegate_jobtitle, delegate_lname, delegate_org, pdf, quote.id, quote.number])

  return (
    <Modal title={'Delegating ' + quote.number} open={open} loading={loading} className="xs:w-128">
      <div className="flex font-body">
        <Scrollbar className="w-full">
          {loading && <Loading />}
          {!loading && !submitted && (
            <div id="delegated_signer_form" className="mx-auto w-96">
              <p>
                You have chosen to delegate signing of the legal agreement to another member of your organisation. Please enter their details below and press{' '}
                <b>Submit</b>. They will then receive an invitation to sign the agreement.
              </p>
              <br />
              <form onSubmit={() => confirmSignMode()} id="delegate_details_form" name="delegate_details_form" className="flex flex-col">
                <Input type="text" name="organisation" label="Organisation:" onChange={(e) => setDelegateOrg(e.target.value)} required className="mb-4" />
                <Input type="text" name="firstname" label="First name:" onChange={(e) => setDelegateFName(e.target.value)} required className="mb-4" />
                <Input type="text" name="lastname" label="Last name" onChange={(e) => setDelegateLName(e.target.value)} required className="mb-4" />
                <Input type="email" name="emailaddress" label="Email address:" onChange={(e) => setDelegateEmail(e.target.value)} required className="mb-4" />
                <Input type="text" name="jobtitle" label="Job Title:" onChange={(e) => setDelegateJobTitle(e.target.value)} required className="mb-4" />
                <div className="flex flex-row">
                  <Submit variant="primary" form="delegate_details_form" />
                </div>
              </form>
              <br />
            </div>
          )}
          {!loading && submitted && delegated && (
            <div id="delegated_completed_form" className="mx-auto w-3/4">
              <p className="mb-8">
                Thanks for confirming signatory details. An email will shortly be sent to your nominated contact inviting them to sign the service agreement.
              </p>
            </div>
          )}
        </Scrollbar>
      </div>
    </Modal>
  )
}

export default DelegateQuoteModal
