import { useEffect, useMemo, useState } from 'react'

/* hooks */
import useCurrentRoute from 'src/hooks/utils/useCurrentRoute'
import useOwnUser from 'src/hooks/utils/useOwnUser'

import { BiX } from 'react-icons/bi'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import Scrollbar from 'react-smooth-scrollbar'

import { closeNavigation } from '../../actions/navigationActions'
import { adminRoutes, routes } from '../../configs/routes'

/* utils */
import { classNames } from '../../utils/classNames'
import ChildLink from './ChildLink/ChildLink'
import Footer from './Footer/Footer'
import Logo from './Logo/Logo'
import ParentLink from './ParentLink/ParentLink'

const Navigation = (): JSX.Element => {
  const route = useCurrentRoute()
  const location = useLocation()
  const dispatch = useDispatch()

  const [activeId, setActiveId] = useState(route.index)

  const navigation = useSelector((state: RootStateOrAny) => state.navigation)

  /* set active link on redirect */
  useEffect(() => {
    setActiveId(route.index)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const user = useOwnUser()
  const completeRoutes = useMemo(() => {
    if (user?.super_admin) return [...routes, ...adminRoutes]
    return routes
  }, [user])

  return (
    <div
      className={classNames(
        navigation.open ? 'flex' : 'hidden',
        'bg-th-content text-th-tertiary xs:bg-th-navigation xs:text-th-white',
        'absolute h-screen w-screen flex-col xs:w-60 md:relative',
        'z-10 flex-none md:flex'
      )}
    >
      {/* translucent layer for fading nav */}
      {user?.open && <div className="absolute z-20 h-full w-full bg-th-translucent opacity-80 xs:hidden" />}
      {/* close icon for small screen widths */}
      <BiX onClick={() => dispatch(closeNavigation())} className="absolute right-1 top-1 hidden h-7 w-7 cursor-pointer xs:block md:hidden" />
      <Logo />
      <Scrollbar className="flex-auto">
        <nav className="p-4 xs:ml-2.5 xs:p-0">
          {user?.id &&
            completeRoutes.map((parent, index) => {
              if (!parent.hidden) {
                return (
                  <ParentLink id={index} key={index} label={parent.label} icon={parent.icon} route={parent.route} activeId={activeId} onClick={setActiveId}>
                    {parent.children &&
                      parent.children.map((child, index: number) => {
                        return <ChildLink key={index} label={child.label} route={child.route} />
                      })}
                  </ParentLink>
                )
              }
              return null
            })}
        </nav>
      </Scrollbar>
      <Footer />
    </div>
  )
}

export default Navigation
