import { useContext } from 'react'

import { ChangeFiltersContext } from './context'

export function useChangeFiltersContext() {
  const context = useContext(ChangeFiltersContext)
  if (context === undefined) {
    throw new Error('useChangeFiltersContext must be used within a ChangeFiltersProvider')
  }
  return context
}
