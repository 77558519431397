import { type FormEvent, Fragment, type FunctionComponent, useState } from 'react'

import { type AxiosResponse } from 'axios'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { showAlert } from '../../actions/alertsActions'
import { closeDrawers } from '../../actions/drawerActions'
import api from '../../constants/api'
import Button from '../Common/Button'
import Drawer from '../Common/Drawer'
import Input from '../Common/Input'
import Submit from '../Common/Submit'

/* form names */
interface Forms extends HTMLCollectionOf<HTMLFormElement> {
  newContactForm: HTMLFormElement
}

const NewContact: FunctionComponent = (): JSX.Element => {
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const account = useSelector((state: RootStateOrAny) => state.account)

  /* drawer actions */
  const Actions: FunctionComponent = (): JSX.Element => {
    return (
      <Fragment>
        <Button bordered onClick={() => dispatch(closeDrawers())}>
          Cancel
        </Button>
        <Submit variant="primary" form="newContactForm" />
      </Fragment>
    )
  }

  const newContact = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    const forms = document.forms as Forms
    const form = forms.newContactForm
    const data = new FormData(form)

    const contact = {
      account: account.id,
      first_name: data.get('first_name'),
      last_name: data.get('last_name'),
      email: data.get('email'),
      phone: data.get('phone'),
      mobile: data.get('mobile')
    }

    api
      .post(`/servicenow/account/${account.id}/contact`, contact)
      .then((response: AxiosResponse) => {
        setLoading(false)
        dispatch(closeDrawers())

        /* pass the new contact to the contacts page to trigger a rerender */
        navigate('/contacts', { state: contact })

        /* notify the user that their new contact already exists */
        if (response.data.existing) {
          return dispatch(
            showAlert({
              type: 'success',
              message: `We already have a contact record for ${contact.first_name} ${contact.last_name} 
                        and it has been linked to your account. We will continue to use the information we
                        already hold for ${contact.first_name} and they can update their own record if needed.`,
              component: 'NewContact'
            })
          )
        }

        /* new contact successfully created */
        dispatch(
          showAlert({
            type: 'success',
            message: `Contact record for ${contact.first_name} ${contact.last_name} created.`,
            component: 'NewContact'
          })
        )
      })
      .catch((error) => {
        setLoading(false)

        /* contact already exists and is already linked to the account */
        if (error.response?.status === 409) {
          return dispatch(
            showAlert({
              type: 'info',
              message: 'A contact with this email already exists.',
              component: 'NewContact',
              error
            })
          )
        }

        /* contact couldn't be created */
        dispatch(
          showAlert({
            type: 'error',
            message: 'We were unable to create your contact. Please try again.',
            component: 'NewContact',
            error
          })
        )
      })
  }

  return (
    <Drawer title={'New Contact'} loading={loading} actions={<Actions />}>
      <form onSubmit={(e) => newContact(e)} id="newContactForm" className="flex flex-col gap-4">
        {/* first name */}
        <Input type="text" name="first_name" label="First Name" required />
        {/* last name */}
        <Input type="text" name="last_name" label="Last Name" required />
        {/* email */}
        <Input type="email" name="email" label="Email" required />
        {/* phone */}
        <Input type="tel" name="phone" label="Phone" />
        {/* mobile */}
        <Input type="tel" name="mobile" label="Mobile" />
      </form>
    </Drawer>
  )
}

export default NewContact
