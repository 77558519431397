import { useQuery } from '@tanstack/react-query'

import { useMemo } from 'react'

import { showAlert } from 'src/actions/alertsActions'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'

import axios from 'axios'
import moment from 'moment'
import { useDispatch } from 'react-redux'

import { useTenantsQuery } from '../../servicenow/queries/useTenantsQuery'
import useDailyTotalsForTagset from './useDailyTotalsForTagset'

export type Insight = {
  id: string // Datebase generated
  date: string // format: yyyy-MM-dd, the date the insight refers to
  period: string // year, month, day, over which time the insights was generated
  amount: number // TOTALSPENDGDP
  type: string // Increase, Decrease, Unusual
  group: string // Subscription, Tenant, Resource Group
  groupName: string // SubscriptionId, TenantId, ResourceGroupId
  subscriptionId: string // SubscriptionId
  tenantId: string // TenantId
}

export type MergedInsight = Insight & {
  startDate?: string
  endDate?: string
  groupIds?: string[]
}

interface UseTrendsInsightsProps {
  cutoffCoefficientMultiplier?: number
}

const useTrendsInsights = ({ cutoffCoefficientMultiplier }: UseTrendsInsightsProps = {}) => {
  const { data: tenants } = useTenantsQuery({ active: true })
  const tenantIds = tenants.map((tenant) => tenant.id)
  const subscriptionIds = tenants.flatMap((tenant) => tenant.subscriptions.map((subscription) => subscription.id))

  const account = useAccount()
  const dispatch = useDispatch()

  const { data: insights } = useQuery({
    queryKey: ['trends_insights', account.id, tenantIds, subscriptionIds],
    queryFn: async ({ signal }) => {
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by TanStack Query')
      })

      try {
        const response = await api.post(
          `/ms-api/v2/account/${account.id}/azure_usage/get_trends_insights`,
          {
            tenantIds: tenantIds,
            subscriptionIds: subscriptionIds,
            startDate: moment().subtract(3, 'month').format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD')
          },
          { cancelToken: source.token }
        )
        return response.data as Insight[]
      } catch (error) {
        if (!axios.isCancel(error)) {
          dispatch(
            showAlert({
              type: 'error',
              message: 'We were unable to retrieve your trends insights.',
              component: 'AzureOverview',
              error: error as Error
            })
          )
        }
        return null
      }
    },
    enabled: !!account.id && !!tenantIds.length
  })

  const tenantTotals = useDailyTotalsForTagset({ dateRange: [moment().subtract(1, 'year').startOf('month'), moment().endOf('month')], tenants, tags: [] })

  const filteredInsights = useMemo(() => {
    if (!insights) return null
    if (!tenantTotals.data) return null

    const minDate = tenantTotals.data.toSorted((a, b) => new Date(a.usageDate).valueOf() - new Date(b.usageDate).valueOf())[0].usageDate
    const dayDiff = Math.abs(moment().diff(moment(minDate), 'day'))
    const dailyAverage = tenantTotals.data.reduce((acc, cur) => acc + cur.totalSpendGBP, 0) / dayDiff

    let cutoff = 20
    if (dailyAverage > 100) {
      cutoff = 30
    }

    return insights.filter((insight) => insight.amount > (cutoffCoefficientMultiplier ?? 1) * cutoff)
  }, [insights, tenantTotals, cutoffCoefficientMultiplier])

  const mergedInsights = useMemo(() => {
    if (!filteredInsights) return null
    if (!filteredInsights.length) return []

    const mergedInsightGroups: MergedInsight[] = []
    for (const mainInsight of filteredInsights) {
      const insightGroup = [mainInsight]

      const alreadyGrouped = mergedInsightGroups.some((group) => group.groupIds && group.groupIds.includes(mainInsight.id))
      if (alreadyGrouped) continue

      for (const insight of filteredInsights) {
        const shouldGroup =
          mainInsight.group === insight.group &&
          mainInsight.groupName === insight.groupName &&
          mainInsight.date !== insight.date &&
          mainInsight.type === insight.type
        const dateDiffCondition = insightGroup.some((groupInsight) => Math.abs(moment(groupInsight.date).diff(moment(insight.date), 'day')) === 1)

        if (shouldGroup && dateDiffCondition) {
          insightGroup.push(insight)
        }
      }

      if (insightGroup.length === 1) {
        mergedInsightGroups.push(mainInsight)
        continue
      }

      const sortedByDate = insightGroup.sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf())
      const startDate = sortedByDate.at(0)?.date
      const endDate = sortedByDate.at(-1)?.date

      mergedInsightGroups.push({
        ...mainInsight,
        startDate,
        endDate,
        groupIds: insightGroup.map((insight) => insight.id),
        amount: insightGroup.reduce((acc, cur) => acc + cur.amount, 0) / insightGroup.length
      })
    }

    return mergedInsightGroups
  }, [filteredInsights])

  return mergedInsights
}

export default useTrendsInsights
