import { useMemo, useState } from 'react'

import { type Entitlement, type Tenant } from 'src/types/azure'

import useCumulate from 'src/hooks/composite/calculation/useCumulate'
import useTimeseriesDataShape from 'src/hooks/composite/calculation/useTimeseriesDataShape'
import useDailyTotalsForTagset from 'src/hooks/services/microsoft/queries/useDailyTotalsForTagset'

import { formatCurrency } from 'src/utils/format'

import { type Moment } from 'moment'
import moment from 'moment'
import { ReferenceLine } from 'recharts'

import Button from '../Common/Button'
import Callout from '../Common/Callout'
import Input from '../Common/Input'
import PortalDrawer from '../Common/PortalDrawer'
import SingleLineGraph from '../Graphs/SingleLineGraph'

type EditBudgetDrawerProps = {
  tenant: Tenant & { subscriptions: Entitlement[] }
  budget?: number | null
  onClose: () => void
  onSubmit: (value: number) => void | Promise<void>

  loading?: boolean
}

const ytdDateRange = [moment().startOf('year'), moment().startOf('day')] as [Moment, Moment]
const monthToDateRange = [moment().startOf('month'), moment().endOf('month')] as [Moment, Moment]

const EditBudgetDrawer: React.FC<EditBudgetDrawerProps> = (props) => {
  const [budget, setBudget] = useState(props.budget?.toFixed(2))

  const tenantConsumption = useDailyTotalsForTagset({ dateRange: ytdDateRange, tenants: [props.tenant], tags: [] })
  const alteredConsumption = useTimeseriesDataShape({ data: tenantConsumption.data ?? [], dateRange: monthToDateRange })

  const cumulatedConsumption = useCumulate(alteredConsumption, 'value')

  const totalSpend = useMemo(() => {
    return alteredConsumption.reduce((acc, curr) => acc + (curr.value ?? 0), 0)
  }, [alteredConsumption])

  const max = useMemo(() => {
    return Math.max(...cumulatedConsumption.map((d) => d.value).flatMap((d) => (d === undefined ? [] : d)))
  }, [cumulatedConsumption])

  return (
    <PortalDrawer
      title={props.tenant.name}
      subtitle={props.tenant.id}
      onClose={props.onClose}
      actions={
        <Button className="w-full justify-center" onClick={() => budget && props.onSubmit(parseFloat(budget))} variant="primary" disabled={props.loading}>
          {props.loading ? 'Saving budget...' : 'Save Budget'}
        </Button>
      }
    >
      <SingleLineGraph
        data={alteredConsumption.map((d) => ({ ...d, name: d.date }))}
        extraLines={<ReferenceLine y={props.budget ?? undefined} strokeWidth={2} stroke="#B7525B" />}
        yDomain={[0, Math.max(max, props.budget ?? 0)]}
        isLoading={tenantConsumption === undefined}
        cumulative
        interactive
      />

      {props.budget !== undefined && props.budget !== null ? (
        <Callout
          text={totalSpend >= props.budget ? 'Your predicted spend is higher than your budget' : 'Your predicted spend is within your budget range'}
          className="my-8"
          type={totalSpend >= props.budget ? 'error' : 'info'}
        />
      ) : null}

      <div className="mb-6 grid grid-cols-2 gap-4">
        <div className="rounded-md border border-th-border p-4">
          <p className="text-center text-th-text-secondary">Current Budget</p>
          <p className="text-center text-lg font-bold text-th-text">
            {props.budget === undefined ? 'Loading...' : props.budget !== null ? formatCurrency(props.budget, true) : 'Not Set'}
          </p>
        </div>

        <div className="rounded-md border border-th-border p-4">
          <p className="text-center text-th-text-secondary">Projected Spend</p>
          <p className="text-center text-lg font-bold text-th-text">{max !== null ? formatCurrency(max, true) : 'Loading...'}</p>
        </div>
      </div>

      <Input
        type="text"
        name="budget"
        label="New Monthly Budget (£)"
        placeholder={props.budget?.toFixed(2)}
        className="rounded-full"
        onChange={(e) => setBudget(e.target.value)}
      />
    </PortalDrawer>
  )
}

export default EditBudgetDrawer
