import useChangeQuery from '~/hooks/services/servicenow/queries/useChangeQuery'

import Classification from '~/components/Common/Classification'
import State from '~/components/Common/State'

import moment from 'moment'
import { Iconly } from 'react-iconly'
import { useParams } from 'react-router-dom'

const ChangeHeader = () => {
  const { id } = useParams()
  const { data: change } = useChangeQuery(id)

  return (
    <>
      <div className="mb-8 flex justify-between">
        <div>
          <div className="font-bold">Planned Start</div>
          <div className="text-sm">{change?.starts ? moment(change.starts).format('DD/MM/yy HH:mm') : 'Not yet scheduled'}</div>
        </div>

        <div>
          <div className="font-bold">Planned End</div>
          <div className="text-sm">{change?.ends ? moment(change.ends).format('DD/MM/yy HH:mm') : 'Not yet scheduled'}</div>
        </div>
      </div>

      <div className="mb-4 flex justify-between">
        <State state={change?.state ?? ''} />
        <Classification classification={change?.classification ?? ''} />
      </div>

      <div className="flex w-max items-center rounded-full bg-th-warning-light p-1 text-sm font-bold text-th-warning">
        <Iconly name="User" set="bold" className="h-5" />
        <span className="px-1">{change?.assigned_name || 'Unassigned'}</span>
      </div>
    </>
  )
}

export default ChangeHeader
