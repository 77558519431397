import { useCallback, useEffect } from 'react'

import { initializeFolderPath, setCurrentFolderId, setFolderPath, setSearch, uninitializeFolderPath } from 'src/actions/folderActions'

import { type Folder } from 'src/hooks/services/provide/queries/useFilesQuery'
import useGetPathToRootQuery from 'src/hooks/services/provide/queries/usePathToRootQuery'
import useAccount from 'src/hooks/utils/useAccount'

import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { DEFAULT_FOLDER_PATH, type FolderState } from 'src/reducers/folderReducer'

export const useFilePath = () => {
  const dispatch = useDispatch()
  const account = useAccount()
  const navigate = useNavigate()
  const location = useLocation()

  const folderPath = useSelector((state: FolderState) => state.folder.folderPath)
  const currentFolderId = useSelector((state: FolderState) => state.folder.currentFolderId)
  const initializedFolderPath = useSelector((state: FolderState) => state.folder.initializedFolderPath)
  const search = useSelector((state: FolderState) => state.folder.search)

  useGetPathToRootQuery(
    { folderId: currentFolderId! },
    {
      onSuccess: (response) => {
        dispatch(setFolderPath(DEFAULT_FOLDER_PATH.concat(response)))
        dispatch(initializeFolderPath())
      },
      enabled: !!currentFolderId && !!account.id && !initializedFolderPath
    }
  )

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const initialFolderId = queryParams.get('folderId')
    if (folderPath.at(-1)?.id !== initialFolderId && initialFolderId) {
      dispatch(setCurrentFolderId(initialFolderId))
    }
  }, [location.search, folderPath, dispatch])

  const updateUrl = useCallback(
    (folderId: string) => {
      const newPath = folderId ? `/documents?folderId=${folderId}` : '/documents'
      navigate(newPath, { replace: true })
    },
    [navigate]
  )

  const handleFolderClick = useCallback(
    (folder: Folder, reset = false) => {
      dispatch(setCurrentFolderId(folder.id))
      updateUrl(folder.id)

      if (search || reset) {
        dispatch(setSearch(''))
        dispatch(uninitializeFolderPath())
        dispatch(setFolderPath(DEFAULT_FOLDER_PATH))
      } else {
        dispatch(setFolderPath([...folderPath, folder]))
      }
    },
    [dispatch, folderPath, updateUrl, search]
  )

  const handleBackClick = useCallback(() => {
    if (folderPath.length > 1) {
      const newPath = folderPath.slice(0, -1)
      dispatch(setFolderPath(newPath))
      const newFolderId = newPath[newPath.length - 1].id
      dispatch(setCurrentFolderId(newFolderId))
      updateUrl(newFolderId)
    }
  }, [dispatch, folderPath, updateUrl])

  const handleFolderNavigate = useCallback(
    (index: number) => {
      const newPath = folderPath.slice(0, index + 1)
      dispatch(setFolderPath(newPath))
      const newFolderId = index === 0 ? '' : newPath[newPath.length - 1].id
      dispatch(setCurrentFolderId(newFolderId))
      updateUrl(newFolderId)
    },
    [dispatch, folderPath, updateUrl]
  )

  return {
    folderPath,
    currentFolderId,
    accountId: account.id,
    handleFolderClick,
    handleBackClick,
    handleFolderNavigate
  }
}
