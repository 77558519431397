const initialState = {
  open: false
}

const navigationReducer = (
  state = initialState,
  action: {
    type: string
  }
): {
  open: boolean
} => {
  switch (action.type) {
    case 'OPEN_NAVIGATION':
      return {
        open: true
      }
    case 'CLOSE_NAVIGATION':
      return {
        open: false
      }
    default:
      return state
  }
}

export default navigationReducer
