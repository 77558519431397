import { useQuery, useQueryClient } from '@tanstack/react-query'

import { type Ticket } from '../../types/ticket'

import { showAlert } from 'src/actions/alertsActions'
import { startLoading, stopLoading } from 'src/actions/loadingActions'

import api from 'src/constants/api'

import axios from 'axios'
import moment, { type Moment } from 'moment'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { closeDrawers, openTicketDrawer } from '../../actions/drawerActions'
import PaginatedTable from '../Common/PaginatedTable'
import Priority from '../Common/Priority'
import State from '../Common/State'

type TicketFilterOptions = {
  startDate: Moment
  endDate: Moment
  keyword: string | null
  priority: string[] | null
  category: string[] | null
  state: string | null
  orderBy: string | null
  orderByDesc: boolean
  take: number
  skip: number
  requester_page?: string
}

const mapToQueryParams = (filterOptions: TicketFilterOptions) => ({
  start_date: filterOptions.startDate.format('YYYY-MM-DD'),
  end_date: filterOptions.endDate.format('YYYY-MM-DD'),
  keyword: filterOptions.keyword || null,
  priority: (filterOptions.priority && filterOptions.priority.length >= 4) || filterOptions.keyword ? null : (filterOptions.priority ?? null),
  category: (filterOptions.category && filterOptions.category.length >= 4) || filterOptions.keyword ? null : (filterOptions.category ?? null),
  state: filterOptions.keyword ? null : filterOptions.state || null,
  order_by: filterOptions.orderBy || null,
  order_by_desc: filterOptions.orderByDesc,
  take: filterOptions.take,
  skip: filterOptions.skip,
  requester_page: filterOptions.requester_page || null
})

type TicketTableProps = {
  filterOptions: TicketFilterOptions
  onFilterChange: (filterOptions: TicketFilterOptions) => void
}

const TicketTable: React.FC<TicketTableProps> = ({ filterOptions, onFilterChange }): JSX.Element => {
  const dispatch = useDispatch()
  const account = useSelector((state: RootStateOrAny) => state.account)

  const queryClient = useQueryClient()

  /* get the tickets for the selected account */
  type TicketsType = { tickets: Ticket[]; total_count: number }
  const { data: data } = useQuery({
    queryKey: ['tickets', account.id, filterOptions],
    queryFn: async ({ signal }) => {
      if (!data) dispatch(startLoading())

      try {
        const response = await api.post(`/servicenow/account/${account.id}/tickets`, mapToQueryParams(filterOptions), { signal })

        dispatch(stopLoading())
        return response.data as TicketsType
      } catch (error: any) {
        if (!axios.isCancel(error)) {
          dispatch(
            showAlert({
              type: 'error',
              message: error?.response?.data || 'An error occured while fetching the tickets',
              component: 'Tickets',
              error: error || new Error('An error occured while fetching the tickets')
            })
          )
          dispatch(stopLoading())
        }
      }

      return null
    },
    onSuccess: () => {
      // prefetch the next page and previous page
      queryClient.prefetchQuery({
        queryKey: ['tickets', account.id, { ...filterOptions, skip: filterOptions.skip + filterOptions.take }],
        queryFn: async ({ signal }) => {
          try {
            const nextFilterOptions = { ...filterOptions, skip: filterOptions.skip + filterOptions.take }
            const response = await api.post(`/servicenow/account/${account.id}/tickets`, mapToQueryParams(nextFilterOptions), { signal })
            return response.data as TicketsType
          } catch {
            // do nothing
          }

          return []
        }
      })
    },
    enabled: !!account.id
  })

  const getCleanTitle = (title: string, reference?: string): string => {
    if (!reference) return title

    const match = title.match(/^\[(.*?)\](.*)/)
    if (match && match[1] === reference) {
      return match[2].trim()
    }
    return title
  }

  return (
    <PaginatedTable
      onRowClick={(rowIndex) => {
        if (!data) return
        dispatch(closeDrawers())
        dispatch(openTicketDrawer(data.tickets[rowIndex]))
      }}
      headers={['', 'Number', 'Title', 'Originator', 'Assigned', 'Category', 'Priority', 'Created', 'Updated', 'State']}
      data={
        data?.tickets.map((ticket: Ticket) => {
          return [
            ticket.notification ? <div className="h-2 w-2 rounded-full bg-th-primary" /> : '',
            ticket.number,
            getCleanTitle(ticket.title, ticket.customer_ticket_reference),
            ticket.originator_name,
            ticket.assigned_name,
            ticket.category,
            <Priority level={ticket.priority} />,
            <span>{moment(ticket.created).format('DD/MM/YY HH:mm')}</span>,
            <span>{moment(ticket.updated).format('DD/MM/YY HH:mm')}</span>,
            <State state={ticket.state} />
          ]
        }) || null
      }
      totalRowCount={data?.total_count || 0}
      onPageChange={(newPage) => onFilterChange({ ...filterOptions, skip: Math.max(newPage, 0) * filterOptions.take })}
      onPageSizeChange={(newPageSize) => onFilterChange({ ...filterOptions, take: newPageSize })}
      onSortChange={(columnIndex, direction) => {
        const orderBy = [null, 'number', 'title', 'originator_name', 'assigned_name', 'category', 'priority', 'created', 'updated', 'state'][columnIndex]
        onFilterChange({ ...filterOptions, orderBy, orderByDesc: direction === 'desc' })
      }}
    />
  )
}

export default TicketTable
