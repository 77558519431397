export const ADD_UPLOAD = 'ADD_UPLOAD'
export const UPDATE_UPLOAD_PROGRESS = 'UPDATE_UPLOAD_PROGRESS'
export const COMPLETE_UPLOAD = 'COMPLETE_UPLOAD'
export const SET_UPLOAD_ERROR = 'SET_UPLOAD_ERROR'
export const REMOVE_UPLOAD = 'REMOVE_UPLOAD'

export const addUpload = (id: string, fileName: string) => ({
  type: ADD_UPLOAD as typeof ADD_UPLOAD,
  payload: { id, fileName }
})

export const updateUploadProgress = (id: string, progress: number) => ({
  type: UPDATE_UPLOAD_PROGRESS as typeof UPDATE_UPLOAD_PROGRESS,
  payload: { id, progress }
})

export const completeUpload = (id: string) => ({
  type: COMPLETE_UPLOAD as typeof COMPLETE_UPLOAD,
  payload: { id }
})

export const setUploadError = (id: string) => ({
  type: SET_UPLOAD_ERROR as typeof SET_UPLOAD_ERROR,
  payload: { id }
})

export const removeUpload = (id: string) => ({
  type: REMOVE_UPLOAD as typeof REMOVE_UPLOAD,
  payload: { id }
})
