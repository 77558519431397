import { type FormEvent, type FunctionComponent, useState } from 'react'

import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { showAlert } from '../../actions/alertsActions'
import { startLoading, stopLoading } from '../../actions/loadingActions'
import { closeEditAzureBudgetModal } from '../../actions/modalActions'
import api from '../../constants/api'
import Input from '../Common/Input'
import Modal from '../Common/Modal'
import Submit from '../Common/Submit'

/* form names */
interface Forms extends HTMLCollectionOf<HTMLFormElement> {
  editAzureBudgetForm: HTMLFormElement
}

const EditAzureBudgetModal: FunctionComponent = (): JSX.Element => {
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const budget = useSelector((state: RootStateOrAny) => state.modals.editazurebudgetmodal)

  const submitAzureBudget = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    dispatch(startLoading())
    const forms = document.forms as Forms
    const form = forms.editAzureBudgetForm
    const data = new FormData(form)

    const new_budget = data.get('edit_azure_budget') || 0
    if (new_budget !== budget.value) {
      api
        .patch(`/ms-api/account/${budget.account_id}/azure_usage/${budget.tenant_id}/budget/${new_budget}`)
        .then(() => {
          dispatch(
            showAlert({
              type: 'success',
              message: 'Your Azure Budget has been updated.',
              component: 'AzureBudget'
            })
          )
          /* pass the value in location data */
          navigate('/azure_overview', {
            state: {
              ...budget,
              value: new_budget ? parseFloat(new_budget.toString()) : 0
            }
          })
          dispatch(stopLoading())
        })
        .catch((error) => {
          dispatch(
            showAlert({
              type: 'error',
              message: error.response?.data,
              component: 'AzureBudget',
              error
            })
          )
          dispatch(stopLoading())
          navigate('/azure_overview', {
            state: { budget: budget }
          })
        })
    }
    dispatch(closeEditAzureBudgetModal())
    setLoading(false)
  }

  return (
    <Modal title="Edit Azure budget" open={budget ? true : false} loading={loading}>
      <form onSubmit={(e) => submitAzureBudget(e)} id="editAzureBudgetForm">
        <>Set a monthly Azure spending budget to help you manage the Azure spending.</>
        <Input
          name="edit_azure_budget"
          type="text"
          className="mb-4 w-full rounded-lg border border-th-border p-2 font-body text-sm"
          placeholder={budget && budget.value ? budget.value : 0}
          autoFocus
          required
        />
      </form>
      <div className="flex max-h-96 justify-items-end font-body">
        <Submit variant="primary" form="editAzureBudgetForm" />
      </div>
    </Modal>
  )
}

export default EditAzureBudgetModal
