import { type FunctionComponent, useEffect, useState } from 'react'

import { type Account } from '../../../types/account'
import { type Role as RoleType } from '../../../types/role'
import { type User } from '../../../types/user'

import axios, { type AxiosResponse } from 'axios'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { showAlert } from '../../../actions/alertsActions'
import { stopLoading } from '../../../actions/loadingActions'
import api from '../../../constants/api'
import Role from './Role/Role'

interface Props {
  user: User
}

const Roles: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { user } = props

  const dispatch = useDispatch()
  const account: Account = useSelector((state: RootStateOrAny) => state.account)

  const [groups, setGroups] = useState<string[]>([])
  const [roles, setRoles] = useState<RoleType[]>([])
  const [usersRoles, setUsersRoles] = useState<string[]>([])

  /* assign a role to a user */
  const assignRole = async (system: string, role: string) => {
    return await api
      .post(`/provide/account/${account.id}/user/${user.id}/role/assign`, {
        system,
        role
      })
      .then((response: AxiosResponse) => {
        setUsersRoles([...usersRoles, role])
        return response
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(
            showAlert({
              type: 'error',
              message: `We were unable to assign this role to ${user.first_name} at this time. Please try again later.`,
              component: 'Roles',
              error
            })
          )
        }
      })
  }

  /* unassign a role from a user */
  const unassignRole = async (system: string, role: string) => {
    return await api
      .post(`/provide/account/${account.id}/user/${user.id}/role/unassign`, {
        system,
        role
      })
      .then((response: AxiosResponse) => {
        setUsersRoles(usersRoles.filter((userRole: string) => userRole !== role))
        return response
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(
            showAlert({
              type: 'error',
              message: `We were unable to unassign this role from ${user.first_name} at this time. Please try again later.`,
              component: 'Roles',
              error
            })
          )
        }
      })
  }

  /* handle the toggle switch changes */
  const handleChange = async (checked: boolean, role: string) => {
    const success = checked ? await assignRole('ServiceNow', role) : await unassignRole('ServiceNow', role)

    /* if the role change was successful return the new switch state */
    return success ? checked : !checked
  }

  /* extract the roles from the user object */
  useEffect(() => {
    if (user.accounts) {
      const index: number = user.accounts.findIndex((acc) => acc.id === account.id)
      const acc = user.accounts[index]
      if (acc) setUsersRoles(acc.roles)
    }
  }, [account.id, user])

  /* get the available roles */
  useEffect(() => {
    const source = axios.CancelToken.source()

    api
      .get(`/provide/roles`, { cancelToken: source.token })
      .then((response: AxiosResponse) => {
        /* extract the role groups for splitting the roles on the ui */
        const groups = Array.from(new Set<string>(response.data.map((role: RoleType) => role.group))).sort()

        /* move the admin group to the first index */
        groups.unshift(groups.splice(groups.indexOf('Admin'), 1)[0])

        /* order the available roles by group */
        const roles = response.data.sort((a: RoleType, b: RoleType) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))

        setGroups(groups)
        setRoles(roles)
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(
            showAlert({
              type: 'error',
              message: 'We could not retrieve the available roles at this time. Please try again later.',
              component: 'Roles',
              error
            })
          )
        }
      })

    return () => {
      source.cancel('Request cancelled by the user')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /* stop loading when the roles have been retrieved */
  useEffect(() => {
    if (roles.length > 0) {
      dispatch(stopLoading())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles, user])

  return (
    <div className="flex flex-col gap-4">
      {groups.map((group: string) => (
        <div>
          <div className="py-2 text-lg font-bold text-th-text">{group}</div>
          <div key={group} className="rounded-lg bg-th-content">
            {/* group roles */}
            <div>
              {roles?.map(
                (role: RoleType) =>
                  /* render the roles that are part of this group */
                  role.group === group && <Role role={role} initialState={usersRoles.indexOf(role.id) !== -1} user={user} onChange={handleChange} />
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Roles
