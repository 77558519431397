import useAccount from '~/hooks/utils/useAccount'

import Button from '~/components/Common/Button'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '~/components/Common/Dialog'
import ProgressIndicator from '~/components/ProgressIndictor'

import { classNames } from 'src/utils/classNames'

import { useChangeCSV } from '../../hooks/useChangeCSV'
import { useChangeFilters } from '../../hooks/useChangeFilters'
import { DateRangeFilter } from '../ChangeStats/DateRangeFilter'

export const ChangeCSVDialog = () => {
  const account = useAccount()

  const { filterOptions } = useChangeFilters()

  const { handleDownload, downloadPercentage, downloadError } = useChangeCSV(account.id)

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button>Download CSV</Button>
      </DialogTrigger>
      <DialogContent className="min-w-max overflow-visible sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Download Configuration</DialogTitle>
        </DialogHeader>

        <DateRangeFilter />

        <p className={classNames('py-2 text-center', downloadError && 'font-bold text-th-red')}>
          {downloadError ? `Error downloading... (${downloadError})` : 'For large datasets, this may take a while. Please be patient...'}
        </p>

        <ProgressIndicator percent={downloadPercentage} />

        <div className="flex justify-end">
          <Button onClick={() => handleDownload(filterOptions)} disabled={downloadPercentage !== null} bordered>
            {downloadPercentage ? 'Downloading...' : 'Download CSV'}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
