import { type FunctionComponent, type ReactNode, useEffect } from 'react'

import { IoClose } from 'react-icons/io5'
import { default as ReactModal } from 'react-modal'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { closeModals } from '../../actions/modalActions'

/* utils */
import { classNames } from '../../utils/classNames'
import Spinner from './Spinner'

interface Props {
  open: boolean
  title: string
  loading: boolean
  loadingStage?: string
  children?: ReactNode
  className?: string
  onClose?: () => void
}

const Modal: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { open, title, loading, loadingStage, children, className } = props

  const theme = useSelector((state: RootStateOrAny) => state.settings.theme) || 'default'

  const dispatch = useDispatch()

  useEffect(() => {
    ReactModal.setAppElement('#content')
  }, [])

  return (
    <ReactModal
      isOpen={open}
      onRequestClose={() => {
        props.onClose?.()
        dispatch(closeModals())
      }}
      className={classNames(
        'absolute left-1/2 right-auto top-1/2 z-50 -translate-x-1/2 transform',
        '-translate-y-1/2 rounded-lg bg-th-modal p-4 shadow',
        'w-screen text-th-text xs:w-96',
        'theme-' + theme,
        theme === 'dark' && 'border border-th-border',
        className,
        className?.split(' ').includes('overflow-visible') ? '' : 'overflow-hidden'
      )}
      overlayClassName={classNames('fixed inset-0 bg-th-translucent md:ml-60 md:rounded-tl-content z-50', 'theme-' + theme)}
    >
      {/* modal header */}
      <div className="flex items-center font-headline text-xl font-bold">
        <span className="flex-auto">{title}</span>
        <button
          onClick={() => {
            props.onClose?.()
            dispatch(closeModals())
          }}
        >
          <IoClose />
        </button>
      </div>
      <hr className="my-4 border-th-border" />
      {/* loading spinner */}
      {loading && (
        <div className="flex justify-center py-4">
          <Spinner className="h-10 w-10" />
        </div>
      )}
      {/* loading text */}
      {loading && loadingStage && <div className="flex justify-center py-2 text-2xs">{loadingStage}</div>}
      {/* modal content */}
      {!loading && children}
    </ReactModal>
  )
}

export default Modal
