import { type ChangeEvent, type FormEvent, type FunctionComponent, type ReactNode, useEffect, useState } from 'react'

import { type AZServiceCatalogEntry, type Entitlement, type ResourceGroup, type Tenant } from '../../../types/azure'
import { type User } from '../../../types/user'

import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { type GroupTypeBase, type OptionTypeBase } from 'react-select'

import { showAlert } from '../../../actions/alertsActions'
import { closeDrawers } from '../../../actions/drawerActions'
import api from '../../../constants/api'
import GroupedSelect from '../GroupedSelect'
import Input from '../Input'
import Spinner from '../Spinner'

interface Props {
  deployment: AZServiceCatalogEntry
  children?: ReactNode
}

/* form names */
interface Forms extends HTMLCollectionOf<HTMLFormElement> {
  newAzureDeploymentForm: HTMLFormElement
}

const AzureAutomationAccountDeploymentForm: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { deployment } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const user: User = useSelector((state: RootStateOrAny) => state.user)
  const account = useSelector((state: RootStateOrAny) => state.account)
  const tenants = useSelector((state: RootStateOrAny) => state.tenants) as Array<Tenant>
  const subscriptions = useSelector((state: RootStateOrAny) => state.entitlements) as Array<Entitlement>
  const [selectedTenant, setSelectedTenant] = useState<string>()
  const [selectedSubscription, setSelectedSubscription] = useState<string>()
  const [resourceGroupOptions, setResourceGroupOptions] = useState<OptionTypeBase[]>([])
  const [tenantsAndSubscriptionsOptions, setTenantsAndSubscriptionsOptions] = useState<GroupTypeBase<OptionTypeBase>[]>([])
  const [locationOptions, setLocationOptions] = useState<OptionTypeBase[]>([
    {
      label: '(Europe) UK South',
      value: 'uksouth'
    },
    {
      label: '(Europe) UK West',
      value: 'ukwest'
    },
    {
      label: '(Europe) North Europe',
      value: 'northeurope'
    },
    {
      label: '(Europe) West Europe',
      value: 'westeurope'
    }
  ])
  const newAzureSubscriptionDeployment = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    const forms = document.forms as Forms
    const form = forms.newAzureDeploymentForm
    const data = new FormData(form)

    const formData: Record<string, unknown> = {}
    for (const [key, val] of data.entries()) {
      formData[key] = val
    }

    const deployment_incident_data = {
      account: account.id,
      category: 'Request',
      title: 'Request for ' + deployment.resource_type + ' deployment',
      contact_type: 'Provide',
      correlation_display: 'ProvideAzureDeployment',
      service_technology: 'c4cd923b1bad99d021b7dc62b24bcb6a',
      issue: '84f305f51b120dd0cf8b404cd34bcb1f',
      assigned_group: 'f9750c4c1bb12190b2cca860f54bcb3a',
      users_affected: 'Single',
      business_impact: 'No immediate impact',
      description: 'Request for  ' + deployment.resource_type + '\n\ndata:\n' + JSON.stringify(formData)
    }

    api
      .post(`/servicenow/account/${account.id}/ticket`, deployment_incident_data)
      .then((incidentResponse) => {
        const deployment_data = {
          CustomerAccId: account.id,
          CustomerRequester: user.first_name + ' ' + user.last_name,
          SnowIncId: incidentResponse.data.id,

          CustomerTenantId: selectedTenant,
          CustomerSubscriptionId: selectedSubscription,
          ResourceGroupName: data.get('resource_group'),

          AutomationAccountName: data.get('resource_name'),

          location: data.get('location'),

          EnvironmentTag: data.get('env'),
          OwnerTag: data.get('owner'),
          ApplicationTag: data.get('app')
        }
        api
          .post(`/azure/account/${account.id}/pipelines/run/${deployment.pipeline_short_name}`, deployment_data)
          .then(() => {
            setLoading(false)
            dispatch(closeDrawers())
            // api add comment based on response
            addComment(incidentResponse.data.id, 'Deployment initiated for')
            navigate('/tickets/' + incidentResponse.data.id, { state: deployment_incident_data })
          })
          .catch((error) => {
            dispatch(
              showAlert({
                type: 'error',
                message: 'We were unable to initiate your deployment to Azure.',
                component: 'NewAzureDeployment',
                error
              })
            )
            addComment(incidentResponse.data.id, error.message)
            navigate('/tickets/' + incidentResponse.data.id, { state: deployment_incident_data })
          })
        dispatch(
          showAlert({
            type: 'success',
            message: incidentResponse.data.number + ' created.',
            component: 'NewAzureDeployment'
          })
        )
      })
      .catch((error) => {
        dispatch(
          showAlert({
            type: 'error',
            message: 'We were unable to initiate your deployment to Azure. Please try again.',
            component: 'NewAzureDeployment',
            error
          })
        )
      })
  }

  const addComment = (ticket_id: string, comment: string) => {
    const data = {
      comment: `Provide <{#}> \n ${comment} ${deployment.resource_type}`,
      assignment_group_id: 'f9750c4c1bb12190b2cca860f54bcb3a'
    }

    api.post(`/servicenow/account/${account.id}/ticket/${ticket_id}/comment`, data).catch((error) => {
      /* alert the user of an unsuccessful comment */
      dispatch(
        showAlert({
          type: 'error',
          message: 'We were unable to update the deployment ticket. Please try again.',
          component: 'Ticket',
          error
        })
      )
    })
  }

  const handleSubscriptionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setResourceGroupOptions([
      {
        label: ' Loading, please wait...',
        value: undefined
      }
    ])
    setLocationOptions([
      {
        label: ' Loading, please wait...',
        value: undefined
      }
    ])
    setSelectedTenant(subscriptions.find((subscription: Entitlement) => subscription.id === e.target.value)?.tenant_id)
    setSelectedSubscription(e.target.value)
  }

  /* build the TenantsAndSubscriptions options from the tenants & subs */
  useEffect(() => {
    if (tenants && subscriptions) {
      if (tenants.length > 0 && subscriptions.length > 0) {
        const groupedOptions: GroupTypeBase<OptionTypeBase>[] = tenants
          .filter((t) => subscriptions.filter((s) => s.tenant_id === t.id).length > 0)
          .map((tenant: Tenant) => {
            return {
              label: tenant.name,
              value: tenant.id,
              options: subscriptions
                .filter((subscription: Entitlement) => {
                  return subscription.tenant_id === tenant.id
                })
                .map((subscription: Entitlement) => {
                  return {
                    label: subscription.name,
                    value: subscription.id,
                    disabled: subscription.in_lighthouse ? false : true
                  }
                })
            }
          })
        setTenantsAndSubscriptionsOptions(groupedOptions)
      }
    }
  }, [tenants, subscriptions])

  /* build the Locations options from the tenants & subs */
  useEffect(() => {
    if (selectedSubscription)
      api
        .get(`/azure/account/${account.id}/lighthouse/subscriptions/${selectedSubscription}/resources/Microsoft.Resources%2Flocations`, {})
        .then((response) => {
          const locations: OptionTypeBase[] = response.data
          if (locations && locations.length > 0) {
            const options: OptionTypeBase[] = locations.map((r: OptionTypeBase) => {
              return {
                label: r.name,
                value: r.id
              }
            })
            setLocationOptions(options)
          }
        })
  }, [account.id, selectedSubscription])

  useEffect(() => {
    if (selectedTenant && selectedSubscription) {
      api
        .get(`/azure/account/${account.id}/lighthouse/subscriptions/${selectedSubscription}/resources/Microsoft.Resources%2FresourceGroups`, {})
        .then((response) => {
          const resourceGroups: ResourceGroup[] = response.data
          if (resourceGroups && resourceGroups.length > 0) {
            const options: OptionTypeBase[] = resourceGroups.map((r: ResourceGroup) => {
              return {
                label: r.name,
                value: r.name
              }
            })
            setResourceGroupOptions(options)
          }
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantsAndSubscriptionsOptions, selectedSubscription])

  return loading ? (
    <div className="z-8 fixed left-0 top-0 flex h-full w-full items-center justify-center bg-th-content opacity-90">
      <Spinner className="h-14 w-14" />
    </div>
  ) : (
    <form
      onSubmit={(e) => {
        newAzureSubscriptionDeployment(e)
      }}
      id="newAzureDeploymentForm"
      className="flex flex-col gap-4"
    >
      <GroupedSelect
        name="tenantsandsubscriptions"
        label="Subscription"
        options={tenantsAndSubscriptionsOptions}
        required
        onChange={(e) => {
          handleSubscriptionChange(e)
        }}
      />
      <GroupedSelect name="resource_group" label="Resource Group" options={resourceGroupOptions} required />
      <Input
        type="text"
        name="resource_name"
        label={deployment.resource_type.replace('Azure', '') + ' Name'}
        required
        pattern="[A-Za-z0-9][A-Za-z0-9\-]{4,48}[A-Za-z0-9]"
        onInput={(e) => (e.target as HTMLInputElement).setCustomValidity('')}
        onInvalid={(e) =>
          (e.target as HTMLInputElement).setCustomValidity(
            'The account name must not be empty, and must start and end with a letter or a number. The name can contain only letters, numbers and hyphens(-). The length must be from 6 to 50 characters.'
          )
        }
      />
      <GroupedSelect name="location" label="Location" options={locationOptions} selected="UK South" required />
      <Input type="text" name="env" label="Environment Tag" required />
      <Input type="text" name="owner" label="Owner Tag" required />
      <Input type="text" name="app" label="Application/Workload Tag" required />
    </form>
  )
}

export default AzureAutomationAccountDeploymentForm
