import { useEffect, useMemo } from 'react'

import { hideAlert, showAlert } from 'src/actions/alertsActions'

import { useDispatch } from 'react-redux'

import useGlobalConfig from '../services/provide/queries/useGlobalConfigQuery'
import { useLocalStorage } from '../utils/useLocalStorage'

const useAlerts = ({ admin } = { admin: false }) => {
  const dispatch = useDispatch()

  const { globalConfigs, isFetching } = useGlobalConfig({ admin })
  const alerts = useMemo(() => globalConfigs?.filter((c) => c.type === 'alert') ?? [], [globalConfigs])

  const [dismissedAlerts, setDismissedAlerts] = useLocalStorage<Record<string, string>>('dismissed', {}) // { [alertId]: [updated_at] }
  useEffect(() => {
    if (!globalConfigs || isFetching) return

    const showGlobalAlert = (config: (typeof globalConfigs)[number], index: number) => {
      dispatch(hideAlert(index))
      dispatch(
        showAlert(
          {
            type: config.variant,
            message: config.text,
            component: 'GlobalConfigAlert'
          },
          index
        )
      )
    }

    const nonDismissedAlerts = alerts.filter(
      (a) =>
        (!dismissedAlerts[a.id] || dismissedAlerts[a.id] !== a.updated_at) &&
        a.enabled &&
        (a.appliedToCurrentRoute || a.isGlobal) &&
        a.persistence_mode === 'auto-close'
    )
    nonDismissedAlerts.forEach((a, idx) => showGlobalAlert(a, idx + 1))
    if (!nonDismissedAlerts.length) return

    setDismissedAlerts((prev) => ({
      ...prev,
      ...nonDismissedAlerts.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.updated_at }), {})
    }))
  }, [globalConfigs, isFetching, dismissedAlerts, alerts, setDismissedAlerts, dispatch])

  const dismissAlert = (id: string) => setDismissedAlerts((prev) => ({ ...prev, [id]: alerts.find((a) => a.id === id)!.updated_at }))

  const extendedAlerts = alerts.map((a) => ({
    ...a,
    isDismissed: dismissedAlerts[a.id] === a.updated_at
  }))

  return { alerts: extendedAlerts, dismissAlert }
}

export type Alert = ReturnType<typeof useAlerts>['alerts'][number]

export default useAlerts
