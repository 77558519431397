import React, { useMemo } from 'react'

import { type Entitlement } from 'src/types/azure'

import { useTenantsQuery } from 'src/hooks/services/servicenow/queries/useTenantsQuery'

import { type TreeNode } from 'react-dropdown-tree-select'

import NestedDropdown from '../Common/RefactoredDropdown'

type SubsDropdownProps = {
  onChange?: (selectedSubscription: Entitlement) => void
  selectedTenantId?: string | null
}

const SubsDropdown: React.FC<SubsDropdownProps> = ({ onChange, selectedTenantId }) => {
  const { data: tenants, isLoading } = useTenantsQuery({ active: true })

  const data = useMemo(() => {
    const subscriptions = tenants && selectedTenantId ? (tenants.find((tenant) => tenant.id === selectedTenantId)?.subscriptions ?? []) : []

    const mappedData = subscriptions.map((subscription) => ({
      label: subscription.name,
      value: subscription.id,
      checked: false
    }))

    return mappedData
  }, [tenants, selectedTenantId])

  const handleDropdownChange = (_curr: TreeNode, data: TreeNode[]) => {
    const topLevel = data.filter((d) => d._depth === 0)
    const selectedSubscriptions = topLevel.map((subscription) => tenants.flatMap((tenant) => tenant.subscriptions).find((s) => s.id === subscription.value)!)

    if (onChange) {
      onChange(selectedSubscriptions[0])
    }
  }

  return <NestedDropdown data={data} onChange={handleDropdownChange} label="Subscription" loading={isLoading} mode="simpleSelect" />
}

export default SubsDropdown
