import { withAITracking } from '@microsoft/applicationinsights-react-js'

import { Fragment, type FunctionComponent, useEffect, useState } from 'react'

import { type Entitlement, type Tenant } from '../../../types/azure'

import axios, { type AxiosResponse } from 'axios'
import { CSVLink } from 'react-csv'
import { Iconly } from 'react-iconly'
import { FcCancel, FcCheckmark, FcFolder, FcIdea } from 'react-icons/fc'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { type OptionTypeBase } from 'react-select'

import { showAlert } from '../../../actions/alertsActions'
import { startLoading, stopLoading } from '../../../actions/loadingActions'
import Banner from '../../../components/Common/Banner'
import Button from '../../../components/Common/Button'
import Count from '../../../components/Common/Count'
import Dropdown from '../../../components/Common/Dropdown'
import Section from '../../../components/Common/Section'
import Spacer from '../../../components/Common/Spacer'
import SubscriptionTable from '../../../components/Tables/SubscriptionTable'
import { reactPlugin } from '../../../configs/appInsights'
import api from '../../../constants/api'
import NoSubscriptionSplash from './NoSubscriptionSpash'

/* dropdown options */
const filterOptions = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Deplyoments Enabled',
    value: 'in_lighthouse'
  },
  {
    label: 'Active',
    value: 'active'
  },
  {
    label: 'Inactive',
    value: 'inactive'
  }
]

const AzureSubscriptions: FunctionComponent = (): JSX.Element => {
  /* lighthouseData */
  const [lightHouseData, setLightHouseData] = useState<Record<string, string>[]>([])

  /* entitlements */
  const [entitlements, setEntitlements] = useState<Entitlement[]>([])
  const [detailedEntitlements, setDetailedEntitlements] = useState<Entitlement[]>([])
  const [filtered, setFiltered] = useState<Entitlement[]>(detailedEntitlements)

  const dispatch = useDispatch()
  const account = useSelector((state: RootStateOrAny) => state.account)
  const tenants = useSelector((state: RootStateOrAny) => state.tenants)
  const initialized = useSelector((state: RootStateOrAny) => state.initialized)

  const [selectedTenants, setSelectedTenants] = useState<string[]>()
  const [tenantOptions, setTenantOptions] = useState<OptionTypeBase[]>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [type, setType] = useState<string | undefined>('all')
  const [showSearch, setShowSearch] = useState<boolean>(false)

  /* set the date range based on the period that has been selected */
  const handleTypeChange = (type: string) => {
    setType(type)
  }

  /* filter the subscriptions by search term */
  const filter = (value: string) => {
    setSearchTerm(value.toLowerCase())
  }

  /* start loading animation on page load */
  useEffect(() => {
    dispatch(startLoading())
  }, [dispatch])

  useEffect(() => {
    if (tenants) {
      const tenantOptions = tenants.map((tenant: Tenant) => {
        return {
          label: tenant.name,
          value: tenant.id
        }
      })
      setSelectedTenants(tenants.map((tenant: Tenant) => tenant.id))
      setTenantOptions(tenantOptions)
    }
  }, [tenants])

  /* get the current accounts entitlements */
  useEffect(() => {
    const source = axios.CancelToken.source()

    /* reset all the data */
    setEntitlements([])
    setLightHouseData([])
    setDetailedEntitlements([])
    dispatch(startLoading())
    if (account.id) {
      api
        .get(`/servicenow/account/${account.id}/entitlements`, { cancelToken: source.token })
        .then((response) => {
          if (response.data) {
            const entitlements: Entitlement[] = []
            response.data.map((entitlement: Entitlement) => {
              entitlements.push(entitlement)
              return {
                label: entitlement.name,
                value: entitlement.id
              }
            })
            setEntitlements(entitlements)
            if (entitlements.length === 0) {
              dispatch(stopLoading())
            }
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            dispatch(stopLoading())
            dispatch(
              showAlert({
                type: 'error',
                message: 'We were unable to retrieve your Azure entitlements.',
                component: 'AzureOverview',
                error
              })
            )
          }
        })
    }
    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  /* get the lighthouse entitlements */
  useEffect(() => {
    const source = axios.CancelToken.source()

    if (entitlements && entitlements.length > 0) {
      api
        .get(`/azure/account/${account.id}/lighthouse/subscriptions`, { cancelToken: source.token })
        .then((response: AxiosResponse) => {
          setLightHouseData(response.data)
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            dispatch(stopLoading())
            dispatch(
              showAlert({
                type: 'error',
                message: 'We were unable to retrieve your Lighthouse subscriptions.',
                component: 'Subscriptions',
                error
              })
            )
          }
        })
    }

    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entitlements])

  useEffect(() => {
    const entitlementswithlighthouse: Entitlement[] = []
    if (entitlements && lightHouseData) {
      entitlements.forEach((entitlement) => {
        entitlementswithlighthouse.push({
          id: entitlement.id,
          name: entitlement.name,
          in_lighthouse: lightHouseData.find((r: Record<string, string>) => {
            return r.id === entitlement.id
          })
            ? true
            : false,
          subscription_id: entitlement.subscription_id,
          tenant_id: entitlement.tenant_id,
          state: entitlement.state
        })
      })
    }
    setDetailedEntitlements(entitlementswithlighthouse)
    //dispatch(stopLoading())
  }, [entitlements, lightHouseData])

  useEffect(() => {
    if (detailedEntitlements && detailedEntitlements.length > 0) {
      dispatch(stopLoading())
    }
  }, [detailedEntitlements, dispatch])

  /* filter the subscriptions by tenant, type and then searchstring */
  useEffect(() => {
    const tenantfiltered = detailedEntitlements.filter((entitlement: Entitlement) => {
      return (
        selectedTenants?.filter((tenant: string) => {
          return tenant === entitlement.tenant_id
        }).length !== 0
      )
    })

    const typeFiltered = tenantfiltered.filter((entitlement: Entitlement) => {
      if (type === 'in_lighthouse') {
        return entitlement.in_lighthouse === true
      } else if (type === 'active') {
        return entitlement.state === 'active'
      } else if (type === 'inactive') {
        return entitlement.state !== 'active'
      } else {
        return true
      }
    })

    const searchFiltered = typeFiltered.filter((entitlement: Entitlement) => {
      return searchTerm === '' || entitlement.name.toLowerCase().indexOf(searchTerm) !== -1
    })

    setFiltered(searchFiltered)
  }, [type, selectedTenants, searchTerm, detailedEntitlements])

  return (
    <Fragment>
      {initialized && entitlements.length === 0 && <NoSubscriptionSplash />}
      {!initialized ||
        (entitlements.length !== 0 && (
          <Fragment>
            <Section>
              <Banner>
                <Spacer className="hidden sm:block" />
                {/* tenants filter */}
                <Dropdown
                  options={tenantOptions}
                  defaultValue={tenantOptions.length > 0 ? tenantOptions : undefined}
                  variant={Dropdown.variant.DEFAULT}
                  label="Tenants"
                  bordered
                  multi={true}
                  onChange={(options) => setSelectedTenants(options.map((option: OptionTypeBase) => option.value))}
                />
              </Banner>
              <div className="my-5 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-1 mdlg:grid-cols-2 xl:grid-cols-4">
                <Count
                  icon={<FcFolder className="h-full w-full text-th-warning" />}
                  value={detailedEntitlements.length}
                  label="All"
                  className="cursor-pointer"
                  onClick={() => setType('all')}
                  active={type === 'all'}
                />
                <Count
                  icon={<FcIdea className="h-full w-full" />}
                  value={
                    detailedEntitlements.filter((entitlement: Entitlement) => {
                      return entitlement.in_lighthouse === true
                    }).length
                  }
                  label="Deployments Enabled"
                  className="cursor-pointer"
                  onClick={() => setType('in_lighthouse')}
                  active={type === 'in_lighthouse'}
                />
                <Count
                  icon={<FcCheckmark className="h-full w-full" />}
                  value={
                    detailedEntitlements.filter((entitlement: Entitlement) => {
                      return entitlement.state === 'active'
                    }).length
                  }
                  label="Active"
                  className="cursor-pointer"
                  onClick={() => setType('active')}
                  active={type === 'active'}
                />
                <Count
                  icon={<FcCancel className="h-full w-full" />}
                  value={
                    detailedEntitlements.filter((entitlement: Entitlement) => {
                      return entitlement.state !== 'active'
                    }).length
                  }
                  label="Inactive"
                  className="cursor-pointer"
                  onClick={() => setType('inactive')}
                  active={type === 'inactive'}
                />
              </div>
            </Section>
            <Section>
              <Banner>
                {/* date range */}
                <Dropdown
                  options={filterOptions}
                  defaultValue={filterOptions[0]}
                  variant={Dropdown.variant.DEFAULT}
                  label="Type"
                  multi={false}
                  onChange={(option) => handleTypeChange(option.value)}
                />
                <Spacer className="hidden sm:block" />
                {/* search */}
                {showSearch ? (
                  <Fragment>
                    <input type="text" className="rounded-lg p-2 font-body text-sm" placeholder="Search" autoFocus onChange={(e) => filter(e.target.value)} />
                    <IoCloseCircleOutline className="h-6 w-6 cursor-pointer" onClick={() => setShowSearch(false)} />
                  </Fragment>
                ) : (
                  <Iconly name="Search" set="light" className="cursor-pointer" onClick={() => setShowSearch(true)} />
                )}
                {/* csv download */}
                <CSVLink data={filtered} filename="subscriptions.csv" target="_blank">
                  <Button onClick={() => null}>Download CSV</Button>
                </CSVLink>
              </Banner>
              <Spacer />
              <SubscriptionTable entitlements={filtered} />
            </Section>
          </Fragment>
        ))}
    </Fragment>
  )
}

const AzureSubscriptionsWithTracking = withAITracking(reactPlugin, AzureSubscriptions, 'Azure Subscriptions')
export default AzureSubscriptionsWithTracking
