import React, { useMemo } from 'react'

import { classNames } from 'src/utils/classNames'
import { formatCurrency } from 'src/utils/format'

import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

type ForecastLineGraphProps = {
  data: { date: string; values: { name: string; value: number; forecast?: boolean }[]; budget?: number }[]
  budget?: number

  cumulative?: boolean
  extraLines?: React.ReactNode
  className?: string
  title?: string
  interactive?: boolean
  yDomain?: [number, number]
  format?: 'currency' | 'none'
  isLoading?: boolean
}

const ForecastLineGraph: React.FC<ForecastLineGraphProps> = ({ data, extraLines, className, title, interactive, yDomain, format, isLoading }) => {
  const altered = useMemo(() => {
    // For this one we want to have two lines, forecast and actual
    const actual = data.map((dataItem) => {
      const nonForecast = dataItem.values.filter((v) => !v.forecast)
      const realized = nonForecast.reduce((acc, cur) => acc + cur.value, 0)
      const projected = dataItem.values.reduce((acc, cur) => acc + cur.value, 0)

      const cutoff = dataItem.values.some((v) => !!v.forecast || !!v.value)

      const shouldRealize = nonForecast.length > 0 && cutoff

      return {
        date: dataItem.date,
        realized: shouldRealize ? realized : null,
        projected,
        budget: dataItem.budget
      }
    })

    return actual
  }, [data])

  const renderTooltip = (tooltipProps: any) => {
    const payload = tooltipProps.payload as { name: string; value: number }[]
    if (!payload || !payload.length) return <></>

    const projected = payload.filter((p) => p.name === 'projected')[0]?.value
    const realized = payload.filter((p) => p.name === 'realized')[0]?.value
    const budget = payload.filter((p) => p.name === 'budget')[0]?.value

    return (
      <div className={classNames('flex gap-2 rounded-md bg-th-content p-4 shadow-md')}>
        <div className="px-2">
          <p>Realized</p>
          <p className="font-bold text-th-text">{format === 'none' ? realized : formatCurrency(realized, true, 0)}</p>
        </div>

        {realized !== projected && (
          <div className="px-2">
            <p className="capitalize">Projected</p>
            <p className="font-bold text-th-text">{format === 'none' ? projected : formatCurrency(projected, true, 0)}</p>
          </div>
        )}

        {!!budget && (
          <div className="px-2">
            <p className="capitalize">Budget</p>
            <p className="font-bold text-th-text">{format === 'none' ? budget : formatCurrency(budget, true, 0)}</p>
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      {title && <p className="mb-10 font-headline text-2xl font-bold text-th-text">{title}</p>}
      <div className={classNames('relative w-full p-2', className ? className : 'h-60')}>
        {isLoading ? (
          <div className="pointer-events-none absolute inset-0 z-10 flex items-center justify-center bg-th-content">
            <div className="pb-8 pl-8 text-2xl text-th-text-secondary">
              <div className="spinner h-16 w-16" />
            </div>
          </div>
        ) : (
          !data.length && (
            <div className="pointer-events-none absolute inset-0 z-10 flex items-center justify-center bg-th-content">
              <p className="pb-8 pl-8 text-2xl text-th-text-secondary">No data to show</p>
            </div>
          )
        )}
        <ResponsiveContainer>
          <AreaChart data={altered}>
            <defs>
              <linearGradient id="blueGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#0087AE" stopOpacity={0.1} />
                <stop offset="95%" stopColor="#0087AE" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid vertical={false} />
            <XAxis dataKey="date" fontSize={12} axisLine={false} tickLine={false} interval={'equidistantPreserveStart'} />
            <YAxis
              tickFormatter={(value) => (format === 'none' ? value : formatCurrency(Math.round(parseFloat(value)), true, 0))}
              fontSize={12}
              axisLine={false}
              tickLine={false}
              tickCount={10}
              width={70}
              domain={yDomain}
            />
            <Tooltip content={interactive ? renderTooltip : <></>} />
            {extraLines}
            <Area dataKey="budget" stroke="gray" strokeWidth={2} dot={false} isAnimationActive={false} fillOpacity={1} fill="none" />

            <Area dataKey="realized" stroke="#0087AE" strokeWidth={2} dot={false} isAnimationActive={false} fillOpacity={1} fill="url(#blueGradient)" />

            <Area
              dataKey="projected"
              stroke="#0087AE"
              strokeWidth={2}
              dot={false}
              isAnimationActive={false}
              strokeDasharray="3 3"
              fillOpacity={0.6}
              fill="url(#blueGradient)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </>
  )
}

export default ForecastLineGraph
