import { type ColumnDef } from '@tanstack/react-table'

import React, { useMemo } from 'react'

import { type Service } from '../../types/service'

import moment from 'moment'
import { useDispatch } from 'react-redux'

import { closeDrawers, openServiceDrawer } from '../../actions/drawerActions'
import State from '../Common/State'
import Table from '../Common/Table/Table'

interface Props {
  services: Service[]
}

const ServiceTable: React.FC<Props> = ({ services }) => {
  const dispatch = useDispatch()

  const columns = useMemo<ColumnDef<Service>[]>(
    () => [
      {
        accessorKey: 'number',
        header: 'Number'
      },
      {
        accessorKey: 'order_number',
        header: 'Order Number'
      },
      {
        accessorKey: 'item',
        header: 'Item'
      },
      {
        accessorKey: 'name',
        header: 'Name'
      },
      {
        accessorKey: 'state',
        header: 'State',
        cell: (info) => <State state={info.getValue() as string} />
      },
      {
        accessorKey: 'created',
        header: 'Created',
        cell: (info) => moment(info.getValue() as string).format('DD/MM/YY'),
        sortingFn: (a, b, id) => moment(a.getValue(id)).unix() - moment(b.getValue(id)).unix()
      }
    ],
    []
  )

  const onRowClick = (row: Service) => {
    dispatch(closeDrawers())
    dispatch(openServiceDrawer(row))
  }

  return <Table data={services} columns={columns} onRowClick={onRowClick} />
}

export default ServiceTable
