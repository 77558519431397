import { type FunctionComponent, type ReactNode } from 'react'

import { IoClose } from 'react-icons/io5'
import { useDispatch } from 'react-redux'
import Scrollbar from 'react-smooth-scrollbar'

import { closeDrawers } from '../../actions/drawerActions'
import Spinner from './Spinner'

interface Props {
  title: string
  loading: boolean
  children?: ReactNode
  actions?: ReactNode
  secondaryActions?: ReactNode
}

const Drawer: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { title, loading, children, actions, secondaryActions } = props

  const dispatch = useDispatch()

  return (
    <div className="z-10right-0 fixed flex h-screen w-screen flex-col overflow-hidden bg-th-content text-th-text shadow xs:w-128 2xl:relative 2xl:shadow-none">
      <div className="flex items-center justify-between p-4 text-th-text xs:p-10 lg:flex-nowrap">
        {/* title */}
        <span className="font-headline text-3xl font-bold">{title}</span>
        <button onClick={() => dispatch(closeDrawers())} className="h-6 w-6">
          <IoClose className="h-6 w-6" />
        </button>
      </div>

      <div className="px-4 xs:px-10">
        <hr className="border-th-border" />
      </div>

      {/* drawer content */}
      {!loading && (
        <Scrollbar className="flex-auto">
          <div className="p-4 xs:p-10">{children}</div>
        </Scrollbar>
      )}

      {/* loading spinner */}
      {loading && (
        <div className="flex h-full items-center justify-center">
          <Spinner className="h-12 w-12" />
        </div>
      )}

      {/* drawer actions */}

      {secondaryActions && (
        <div className="w-full px-4 pb-4 xs:px-10 xs:pb-8">
          <hr className="mb-8 border-th-border" />
          <div className="flex w-full gap-4 [&>*]:w-full">{secondaryActions}</div>
        </div>
      )}

      {actions && (
        <div className="w-full px-4 pb-4 xs:px-10 xs:pb-8">
          {!secondaryActions && <hr className="mb-8 border-th-border" />}
          <div className="flex w-full gap-4 [&>*]:w-full">{actions}</div>
        </div>
      )}
    </div>
  )
}

export default Drawer
