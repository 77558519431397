import { type ReactNode, useMemo, useState } from 'react'

import { classNames } from 'src/utils/classNames'
import { formatCurrency } from 'src/utils/format'

import { Iconly } from 'react-iconly'

type NestedRecord = {
  children?: NestedRecord[]
  title: string
  values: { value: number | ReactNode; uptrend?: boolean; action?: (row: NestedRecord) => void }[]
}

type BudgetsTableProps = {
  data: NestedRecord[]
  headers: string[]
  mainHeader: string
}

const BudgetsTable: React.FC<BudgetsTableProps> = ({ data, headers, mainHeader }) => {
  const [openRows, setOpenRows] = useState<Set<string>>(new Set(['Tenant 1', 'Subscription 11'])) // open the first row by default
  const handleRowClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, row: NestedRecord) => {
    // if the user clicked on the action button, don't open the row, this includes the pencil icon
    if (
      (event.target as HTMLElement)?.getAttribute('aria-label') === 'actioned' ||
      (event.target as HTMLElement)?.parentElement?.getAttribute('aria-label') === 'actioned'
    )
      return
    setOpenRows((prev) => {
      const newSet = new Set(prev)
      newSet.has(row.title) ? newSet.delete(row.title) : newSet.add(row.title)
      return newSet
    })
  }

  const totalRow = useMemo(() => {
    const total = data.reduce((acc, cur) => acc + cur.values.reduce((acc, cur) => acc + (typeof cur.value === 'number' ? cur.value : 0), 0), 0)
    return {
      title: 'Total',
      values: [
        {
          value: total
        },
        {
          value: undefined
        }
      ]
    }
  }, [data])

  const renderRow = (row: NestedRecord, depth: number): React.ReactChild => {
    const isOpen = openRows.has(row.title) ?? false

    return (
      <>
        <div style={{ paddingLeft: `${depth * 2}rem` }} onClick={(e) => handleRowClick(e, row)} className={classNames(row.children && 'cursor-pointer')}>
          <div className="flex w-full border border-l-2 border-th-border border-l-th-primary py-4 pl-8">
            <div className={classNames('flex grow items-center text-sm', row.children && 'text-bold font-headline')}>
              {row.children && <Iconly name="ChevronDown" set="light" className={classNames('h-4 duration-75', isOpen && 'rotate-180')} />}
              <span className="pl-2" title={row.title}>
                {row.title}
              </span>
            </div>
            <div className="flex gap-3">
              {row.values.map((value) => (
                <span
                  className={classNames(
                    'flex w-16 items-center text-sm font-bold',
                    value.uptrend && 'text-th-red',
                    value.uptrend === false && 'text-th-green',
                    value.action && 'cursor-pointer',
                    value.action && 'justify-center'
                  )}
                  onClick={() => value.action?.(row)}
                  aria-label={value.action ? 'actioned' : undefined}
                >
                  {typeof value.value === 'number' ? formatCurrency(value.value, true) : value.value}
                  {value.uptrend !== undefined && <Iconly name={value.uptrend ? 'ArrowUp' : 'ArrowDown'} set="medium" className="h-3" />}
                </span>
              ))}
            </div>
          </div>
        </div>
        {isOpen && row.children?.map((nested_row) => renderRow(nested_row, depth + 1))}
      </>
    )
  }

  return (
    <div className="overflow-x-auto overflow-y-hidden whitespace-nowrap rounded-content bg-th-content pb-8 pt-4">
      <div className="text-bold flex min-w-[40rem] py-4 font-headline text-xs text-th-text-secondary">
        <span className="ml-10 grow">{mainHeader}</span>
        {headers.map((header) => (
          <span className="w-max min-w-[4.5rem]">{header}</span>
        ))}
      </div>

      <div className="min-w-[40rem] border-b border-th-border">
        <div className="flex w-full border border-l-2 border-th-border border-l-th-gray py-4 pl-8">
          <div className={classNames('flex grow items-center text-sm font-extrabold')}>
            <span className="pl-2" title={totalRow.title}>
              {totalRow.title}
            </span>
          </div>
          <div className="flex gap-3">
            {totalRow.values.map((value) => (
              <span className={classNames('flex w-16 items-center text-sm font-extrabold')}>
                {typeof value.value === 'number' ? formatCurrency(value.value, true) : value.value}
              </span>
            ))}
          </div>
        </div>
        {data.map((row) => renderRow(row, 0))}
      </div>
    </div>
  )
}

export default BudgetsTable
