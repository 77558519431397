import { type FunctionComponent, useMemo } from 'react'

import useChartDataShape from 'src/hooks/composite/calculation/useChartDataShape'
import useExtendedTotalSpend from 'src/hooks/composite/calculation/useExtendedTotalSpend'
import useBudgets from 'src/hooks/services/microsoft/queries/useBudgets'
import useDailyTotalsForTagset from 'src/hooks/services/microsoft/queries/useDailyTotalsForTagset'
import { useTenantsQuery } from 'src/hooks/services/servicenow/queries/useTenantsQuery'

import Button from 'src/components/Common/Button'
import ForecastLineGraph from 'src/components/Graphs/ForecastLineGraph'

import moment, { type Moment } from 'moment'
import { Link } from 'react-router-dom'

import Tile from '../../../components/Common/Tile'
import Unauthorised from '../Unauthorised/Unauthorised'

const dateRange = [moment().startOf('month'), moment().endOf('month')] as [Moment, Moment]

const Azure: FunctionComponent = (): JSX.Element => {
  const { data: tenants, isTenantError, isSubscriptionError } = useTenantsQuery({ active: true })
  const selectedTenants = tenants
  const selectedSubscriptions = useMemo(() => tenants.flatMap((t) => t.subscriptions), [tenants])

  const budgets = useBudgets({ tenants })

  const budgetTotal = useMemo(() => {
    const filtered = budgets?.filter((b) => tenants.some((t) => t.id === b.tenant_id))
    const total = filtered?.reduce((acc, cur) => acc + cur.value, 0)
    return total
  }, [budgets, tenants])

  const { data: generalConsumption, isLoading: isConsumptionLoading, enabled: isConsumptionEnabled } = useDailyTotalsForTagset({ dateRange, tenants, tags: [] })

  const [tenantExtendedTotalSpend, tenantCompleteDataSetStats] = useExtendedTotalSpend({
    data: generalConsumption,
    stack: 'tenant',
    selectedSubscriptions,
    selectedTenants
  })

  const { data: alteredConsumption, periodicBudget } = useChartDataShape({
    data: tenantExtendedTotalSpend ?? [],
    budgetTotal,
    dateRange,
    cumulative: true,
    completeDataSetStats: tenantCompleteDataSetStats
  })

  return (
    <Tile className="relative h-full p-6">
      {(isTenantError || isSubscriptionError) && <Unauthorised className="z-20 h-full w-full" />}
      <div className="flex items-center justify-between gap-4 pb-4">
        <div className="flex items-center gap-8">
          <h1 className="font-headline text-2xl font-bold">Azure Spend</h1>
          <h3 className="hidden text-lg font-bold md:block">{moment().format('MMMM YYYY')}</h3>
        </div>
        <Link to="/azure_overview">
          <Button bordered>View Details</Button>
        </Link>
      </div>

      <ForecastLineGraph data={alteredConsumption} budget={periodicBudget} cumulative interactive isLoading={isConsumptionLoading && isConsumptionEnabled} />
    </Tile>
  )
}

export default Azure
