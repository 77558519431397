import * as React from 'react'

import { PiInfo } from 'react-icons/pi'

import { classNames } from '../../utils/classNames'
import Popover from './Popover'

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  selected?: string
  options: { label: string; value: string; disabled?: boolean }[]
  label?: string
  info?: string
}

const Select = React.forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
  const { label, info, selected, options, className, ...rest } = props

  return (
    <div className="flex flex-col gap-1">
      {label && (
        <label htmlFor={props.id} className="ml-2 flex gap-1 text-th-text-secondary">
          {label}
          {props.required && !props.disabled && <div className="text-th-error">*</div>}
          {info && (
            <Popover text={info} position="bottom">
              <PiInfo className="text-gray-500 mb-auto h-4 w-4 text-2xl" />
            </Popover>
          )}
        </label>
      )}
      {!props.disabled && (
        <select
          {...rest}
          id={props.id}
          className={classNames('rounded-lg border border-th-border p-2 font-body text-sm', 'bg-th-content text-th-text enabled:cursor-pointer', className)}
          ref={ref}
          defaultValue={selected}
        >
          <option disabled value="" hidden>
            Select an option
          </option>
          {options.map((option) => (
            <option key={option.value} value={option.value} disabled={option.disabled || false}>
              {option.label}
            </option>
          ))}
        </select>
      )}
      {props.disabled && (
        <span
          id={props.id}
          aria-disabled="true"
          className={classNames('rounded-lg border border-th-border p-2 font-body text-sm', 'bg-th-content text-th-text enabled:cursor-pointer', className)}
        >
          {selected ? selected : 'Not specified'}
        </span>
      )}
      {info && <div className="text-sm text-th-info">{info}</div>}
    </div>
  )
})

export default Select
