import useChangeQuery from '~/hooks/services/servicenow/queries/useChangeQuery'

import { useParams } from 'react-router-dom'

const Properties = () => {
  const { id } = useParams()
  const { data: change } = useChangeQuery(id)

  return (
    <div className="space-y-6">
      <div>
        <div className="mb-1 text-th-text-secondary">Category</div>
        <div className="rounded-lg border border-th-border p-2 text-sm">{change?.category || 'Not Specified'}</div>
      </div>

      <div>
        <div className="mb-1 text-th-text-secondary">Title</div>
        <div className="rounded-lg border border-th-border p-2 text-sm">{change?.title || 'Not Specified'}</div>
      </div>

      <div>
        <div className="mb-1 text-th-text-secondary">Users Affected</div>
        <div className="rounded-lg border border-th-border p-2 text-sm">{change?.users_affected || 'Not Specified'}</div>
      </div>

      <div>
        <div className="mb-1 text-th-text-secondary">Business Impact</div>
        <div className="rounded-lg border border-th-border p-2 text-sm">{change?.business_impact || 'Not Specified'}</div>
      </div>

      <div>
        <div className="mb-1 text-th-text-secondary">Description</div>
        <div className="max-h-48 overflow-y-auto whitespace-pre-wrap break-words rounded-lg border border-th-border p-2 text-sm">
          {change?.description || 'Not Specified'}
        </div>
      </div>
    </div>
  )
}

export default Properties
