import { type ChangeEvent, type FormEvent, Fragment, type FunctionComponent, useEffect, useRef, useState } from 'react'

import { type Account } from '../../types/account'
import { type Attachment } from '../../types/attachment'
import { type Ticket } from '../../types/ticket'
import { type Comment } from '../../types/ticket'
import { type User } from '../../types/user'

import moment from 'moment'
import { Iconly } from 'react-iconly'
import { IoClose } from 'react-icons/io5'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { showAlert } from '../../actions/alertsActions'
import { closeDrawers } from '../../actions/drawerActions'
import api from '../../constants/api'

/* utils */
import { classNames } from '../../utils/classNames'
import Button from '../Common/Button'
import Drawer from '../Common/Drawer'
import Input from '../Common/Input'
import Priority from '../Common/Priority'
import Select from '../Common/Select'
import State from '../Common/State'
import Submit from '../Common/Submit'
import TextArea from '../Common/TextArea'

/* ticket categories */
const categoryOptions = [
  {
    label: 'Incident',
    value: 'Incident'
  },
  {
    label: 'Request',
    value: 'Request'
  },
  {
    label: 'Complaint',
    value: 'Complaint',
    disabled: true
  },
  {
    label: 'Event',
    value: 'Event',
    disabled: true
  },
  {
    label: 'Proactive',
    value: 'Proactive',
    disabled: true
  }
]

/* users affected */
const usersAffectedOptions = [
  {
    label: 'Single User',
    value: 'Single'
  },
  {
    label: 'Multiple Users',
    value: 'Multiple'
  },
  {
    label: 'All Users',
    value: 'All'
  }
]

/* business impact */
const businessImpactOptions = [
  {
    label: 'No Immediate Impact',
    value: 'Not Immediate'
  },
  {
    label: 'Some Impact',
    value: 'Some'
  },
  {
    label: 'Significant Impact',
    value: 'Significant'
  }
]

/* form names */
interface Forms extends HTMLCollectionOf<HTMLFormElement> {
  updateTicketForm: HTMLFormElement
}

const TicketDrawer: FunctionComponent = (): JSX.Element => {
  const user: User = useSelector((state: RootStateOrAny) => state.user)
  const account: Account = useSelector((state: RootStateOrAny) => state.account)
  const ticket: Ticket = useSelector((state: RootStateOrAny) => state.drawers.ticket)

  const [loading, setLoading] = useState(true)
  const [attachments, setAttachments] = useState<Attachment[]>([])
  const [comment, setComment] = useState<string>()
  const [comments, setComments] = useState<Comment[]>([])

  const dispatch = useDispatch()

  const updateTicket = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    const forms = document.forms as Forms
    const form = forms.updateTicketForm
    const data = new FormData(form)

    const title = data.get('title')
    const customerRef = data.get('customer_ticket_reference')

    const formValues = {
      contact_type: 'Provide',
      category: data.get('category'),
      title: customerRef ? `[${customerRef}] ${title}` : title,
      originator: ticket.originator,
      users_affected: data.get('users_affected'),
      business_impact: data.get('business_impact'),
      description: data.get('description'),
      comment: data.get('comment'),
      customer_ticket_reference: customerRef || ''
    }

    api
      .post(`/servicenow/account/${account.id}/ticket/${ticket.id}`, formValues)
      .then((response) => {
        setLoading(false)
        dispatch(closeDrawers())
        dispatch(
          showAlert({
            type: 'info',
            message: response.data.number + ' updated.',
            component: 'Ticket'
          })
        )
      })
      .catch((error) => {
        setLoading(false)
        dispatch(
          showAlert({
            type: 'error',
            message: 'We were unable to update your ticket. Please try again.',
            component: 'Ticket',
            error
          })
        )
      })
  }

  /* add the comment to the ticket */
  const addComment = () => {
    const data = {
      comment: `${user.first_name} ${user.last_name} <{#}> ${comment}`
    }

    api
      .post(`/servicenow/account/${account.id}/ticket/${ticket.id}/comment`, data)
      .then(() => {
        /* clear the comment field */
        setComment('')
        /* add the comment to the array of existing comments */
        setComments([
          {
            ...data,
            author: 'Provide',
            created: moment()
          },
          ...comments
        ])
        /* notify the user of a successful comment */
        dispatch(
          showAlert({
            type: 'success',
            message: 'Comment added to ' + ticket.number + '.',
            component: 'Ticket'
          })
        )
      })
      .catch((error) => {
        /* alert the user of an unsuccessful comment */
        dispatch(
          showAlert({
            type: 'error',
            message: 'We were unable to add your comment to your ticket. Please try again.',
            component: 'Ticket',
            error
          })
        )
      })
  }

  const formatBytes = (bytes: number, decimals = 0) => {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  /* download an attachment */
  const downloadAttachment = (attachment: Attachment) => {
    api
      .get(`/servicenow/account/${account.id}/incident/${ticket.id}/attachment/${attachment.id}`, {
        responseType: 'blob'
      })
      .then((response) => {
        const blob = new Blob([response.data])
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')

        a.style.display = 'none'
        a.href = url
        a.download = attachment.name

        document.body.appendChild(a)
        a.click()

        return window.URL.revokeObjectURL(url)
      })
      .catch((error) => {
        /* alert the user that the attachment couldn't be downloaded */
        dispatch(
          showAlert({
            type: 'error',
            message: "We couldn't download " + attachment.name + ' at this time. Please try again later.',
            component: 'Ticket',
            error
          })
        )
      })
  }

  /* save comment in state when comment field value changes */
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value)
  }

  const hiddenFileInput = useRef<HTMLInputElement>(null)

  const handleClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click()
    }
  }

  const uploadAttachment = (event: FormEvent) => {
    const files = (event.target as HTMLInputElement).files

    if (files !== null) {
      const file = files[0]

      if (file) {
        setLoading(true)

        const data = new FormData()
        data.append('file', file)

        api
          .post(`/servicenow/account/${account.id}/incident/${ticket.id}/attachment`, data)
          .then((response) => {
            /* add attachment to state and sort by name */
            setAttachments([...attachments, response.data].sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)))
            setLoading(false)
          })
          .catch((error) => {
            setLoading(false)

            /* alert the user that the file couldn't be uploaded */
            dispatch(
              showAlert({
                type: 'error',
                message: "We couldn't upload the attachment for " + ticket.number + ' at this time. Please try again later.',
                component: 'Ticket',
                error
              })
            )
          })
      }
    }
  }

  const deleteAttachment = (attachment: Attachment) => {
    setLoading(true)

    /* get the tickets attachments */
    api
      .delete(`/servicenow/account/${account.id}/incident/${ticket.id}/attachment/${attachment.id}`)
      .then(() => {
        /* remove the attachment from state */
        setAttachments(attachments.filter((a: Attachment) => a.id !== attachment.id))
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)

        /* alert the user that the attachment couldn't be deleted */
        dispatch(
          showAlert({
            type: 'error',
            message: "We couldn't delete " + attachment.name + ' at this time. Please try again later.',
            component: 'Ticket',
            error
          })
        )
      })
  }

  /* get the tickets comments and attachments when it's opened */
  useEffect(() => {
    if (ticket) {
      setLoading(true)

      const promises = []

      /* get the tickets attachments */
      promises.push(
        api
          .get(`/servicenow/account/${account.id}/incident/${ticket.id}/attachments`)
          .then((response) => {
            /* save attachments to state */
            setAttachments(response.data)
          })
          .catch((error) => {
            setLoading(false)

            /* alert the user that the attachments couldn't be retrieved */
            dispatch(
              showAlert({
                type: 'error',
                message: "We couldn't retrieve the attachments for " + ticket.number + ' at this time. Please try again later.',
                component: 'Ticket',
                error
              })
            )
          })
      )

      /* get the tickets comments */
      promises.push(
        api
          .get(`/servicenow/account/${account.id}/ticket/${ticket.id}/comments`)
          .then((response) => {
            /* save comments to state */
            setComments(response.data)
            setLoading(false)
          })
          .catch((error) => {
            setLoading(false)

            /* alert the user that the comments couldn't be retrieved */
            dispatch(
              showAlert({
                type: 'error',
                message: "We couldn't retrieve the comments for " + ticket.number + ' at this time. Please try again later.',
                component: 'Ticket',
                error
              })
            )
          })
      )

      Promise.all(promises)
        .then(() => setLoading(false))
        .catch((error) => {
          dispatch(
            showAlert({
              type: 'error',
              message: "We couldn't retrieve some of the data for " + ticket.number + ' at this time. Please try again later.',
              component: 'Ticket',
              error
            })
          )

          setLoading(false)
        })
    }
  }, [account, ticket, dispatch])

  /* drawer actions */
  const Actions: FunctionComponent<{ ticket?: Ticket }> = ({ ticket }): JSX.Element => {
    return (
      <Fragment>
        <Button bordered onClick={() => dispatch(closeDrawers())}>
          Cancel
        </Button>

        {ticket?.state !== 'Closed' && <Submit variant="primary" form="updateTicketForm" />}
      </Fragment>
    )
  }

  const getCleanTitle = (title: string, reference?: string): string => {
    if (!reference) return title

    const match = title.match(/^\[(.*?)\](.*)/)
    if (match && match[1] === reference) {
      return match[2].trim()
    }
    return title
  }

  /* drawer content */
  return (
    <Drawer title={ticket ? ticket.number : 'Ticket'} loading={loading} actions={<Actions ticket={ticket} />}>
      <div className="mb-8 flex justify-between">
        {/* created */}
        <div>
          <div className="font-bold">Created on</div>
          <div className="text-sm">{moment(ticket.created).format('DD/MM/yy HH:mm')}</div>
        </div>

        {/* updated */}
        <div>
          <div className="font-bold">Last updated</div>
          <div className="text-sm">{moment(ticket.updated).format('DD/MM/yy HH:mm')}</div>
        </div>
      </div>

      {/* state and priority */}
      <div className="mb-4 flex justify-between">
        <State state={ticket.state} />
        <Priority level={ticket.priority} />
      </div>

      {/* assigned to */}
      <div className="flex w-max items-center rounded-full bg-th-warning-light p-1 text-sm font-bold text-th-warning">
        <Iconly name="User" set="bold" className="h-5" />
        <span className="px-1">{ticket.assigned_name || 'Unassigned'}</span>
      </div>

      <hr className="my-8 border-th-border" />

      {/* attachments */}
      <div className="flex items-center justify-between">
        <div>Attachments</div>
        {/* upload attachment button */}
        {ticket.state !== 'Closed' && (
          <Button onClick={handleClick} bordered className="gap-1">
            <Iconly name="PaperUpload" set="light" className="h-5" />
            <span>Upload</span>
          </Button>
        )}
      </div>

      {/* hidden file input controlled by upload attachment button */}
      <input type="file" name="file" onChange={uploadAttachment} ref={hiddenFileInput} className="hidden" />

      {/* attachment list */}
      <div className="mt-6">
        <ul>
          {attachments?.map((attachment: Attachment) => (
            <li key={attachment.id} className="flex cursor-pointer items-center border-b border-th-border py-2 text-sm last:border-b-0">
              {/* attachment details */}
              <div className="flex flex-auto justify-between" onClick={() => downloadAttachment(attachment)}>
                <span>{attachment.name}</span>
                <span>{formatBytes(attachment.size)}</span>
              </div>
              {/* delete attachment icon */}
              <IoClose className="ml-3 h-5 w-5 text-th-error" onClick={() => deleteAttachment(attachment)} />
            </li>
          ))}
        </ul>
      </div>

      <hr className="my-8 border-th-border" />

      <form onSubmit={(e) => updateTicket(e)} id="updateTicketForm" className="flex flex-col gap-4">
        {/* category */}
        <Select name="category" label="Category" options={categoryOptions} selected={ticket.category} required disabled={ticket.state === 'Closed'} />
        {/* title */}
        <Input
          type="text"
          name="title"
          label="Title"
          defaultValue={getCleanTitle(ticket.title, ticket.customer_ticket_reference)}
          required
          disabled={ticket.state === 'Closed'}
        />
        {/* customer ticket reference */}
        <Input type="text" name="customer_ticket_reference" label="Customer Ticket Reference" defaultValue={ticket.customer_ticket_reference} />
        {/* users affected */}
        <Select
          name="users_affected"
          label="Users Affected"
          options={usersAffectedOptions}
          selected={ticket.users_affected}
          required
          disabled={ticket.state === 'Closed'}
        />
        {/* business impact */}
        <Select
          name="business_impact"
          label="Business Impact"
          options={businessImpactOptions}
          selected={ticket.business_impact}
          required
          disabled={ticket.state === 'Closed'}
        />
        {/* description */}
        <TextArea name="description" label="Description" defaultValue={ticket.description} className="h-32" required disabled={ticket.state === 'Closed'} />

        {/* new comment */}
        {ticket.state !== 'Closed' && (
          <>
            <hr className="my-8 border-th-border" />
            <TextArea name="comment" label="Comment" className="h-24" value={comment} onChange={(e) => handleChange(e)} />
            <div className="mb-8 flex flex-row-reverse">
              <Button bordered onClick={() => addComment()} className="gap-1">
                <Iconly set="light" name="Send" className="h-5" />
                <span>Send</span>
              </Button>
            </div>
          </>
        )}

        {/* comments */}
        {comments.length > 0 && <hr className="my-8 border-th-border" />}

        {comments.length > 0 && (
          <div className="flex flex-col gap-4">
            {comments.map((c: Comment) => {
              const text = c.comment.split(' <{#}> ')

              /* extract author from the text if its a customer comment else use the servicenow author */
              const author = c.author === 'Provide' ? (text.length > 1 ? text[0] : 'Provide') : c.author

              return (
                <div className={classNames(c.author === 'Provide' ? 'ml-8' : 'mr-8')}>
                  {/* author and date */}
                  <div className="flex justify-between text-sm font-bold">
                    <div>{author}</div>
                    <div>{moment(c.created).format('DD/MM/yy HH:mm')}</div>
                  </div>
                  {/* comment text */}
                  <div className={classNames('rounded p-2 text-sm', c.author === 'Provide' ? 'bg-th-info-light' : 'bg-th-content-secondary')}>
                    {text[text.length - 1]}
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </form>
    </Drawer>
  )
}

export default TicketDrawer
