import { type FunctionComponent, type ReactNode } from 'react'

import { PiInfo } from 'react-icons/pi'

import Popover from './Popover'
import Spacer from './Spacer'

interface Props {
  text: string
  secondaryText?: string
  children?: ReactNode

  info?: string
}

const Heading: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { text, secondaryText, children } = props

  return (
    <div className="mb-8 flex grow flex-wrap items-center gap-4">
      <div className="flex flex-col items-baseline gap-2 font-bold sm:flex-row sm:gap-8">
        <span className="font-headline text-2xl font-bold">{text}</span>
        {secondaryText && <span className="sm:text-lg">{secondaryText}</span>}
      </div>
      {props.info && (
        <Popover text={props.info} position="bottom">
          <PiInfo className="text-gray-500 mb-auto h-4 w-4 text-2xl" />
        </Popover>
      )}
      <Spacer />
      <div className="flex items-center gap-4">{children}</div>
    </div>
  )
}

export default Heading
