import type { FunctionComponent } from 'react'

/* utils */
import { classNames } from '../../utils/classNames'
import Popover from './Popover'

/* props */
interface Props {
  classification: string
  displayMode?: 'icon' | 'text' | 'both'
  enableTooltip?: boolean
}

const Classification: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { classification, displayMode = 'both', enableTooltip = false } = props

  let label: string
  let text: string
  let background: string
  let backgroundFaded: string

  switch (classification) {
    case 'Emergency':
      label = 'Emergency'
      text = 'text-th-error'
      background = 'bg-th-error'
      backgroundFaded = 'bg-th-error-light'
      break
    case 'Major':
      label = 'Major'
      text = 'text-th-warning'
      background = 'bg-th-warning'
      backgroundFaded = 'bg-th-warning-light'
      break
    case 'Normal':
      label = 'Normal'
      text = 'text-th-info'
      background = 'bg-th-info'
      backgroundFaded = 'bg-th-info-light'
      break
    case 'Small':
      label = 'Small'
      text = 'text-th-info'
      background = 'bg-th-info'
      backgroundFaded = 'bg-th-info-light'
      break
    case 'Standard':
    default:
      label = 'Standard'
      text = 'text-th-success'
      background = 'bg-th-success'
      backgroundFaded = 'bg-th-success-light'
      break
  }

  return (
    <Popover disabled={!enableTooltip} text={`Classification: ${label}`}>
      <div className={classNames('flex w-min items-center rounded-full text-sm font-bold', displayMode === 'icon' ? 'px-1 py-0.5' : `${backgroundFaded} p-1`)}>
        {displayMode !== 'text' && (
          <div
            className={classNames(
              'h-5 w-5 rounded-full text-center text-th-white',
              background,
              displayMode === 'icon' && 'outline outline-1 outline-th-border'
            )}
          >
            {classification.substring(0, 1)}
          </div>
        )}
        {displayMode !== 'icon' && <span className={classNames('px-2', text)}>{label}</span>}
      </div>
    </Popover>
  )
}

export default Classification
