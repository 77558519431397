import type { Change } from '~/hooks/services/servicenow/queries/useChangesQuery'

export const exportToCSV = async (data: Change[], filename: string) => {
  const processedData = data.map((change) => ({
    ...change,
    description: change.description.replaceAll('"', "'"),
    title: change.title.replaceAll('"', "'")
  }))

  const headers = Object.keys(processedData[0])
  const csv = [
    headers.join(','),
    ...processedData.map((row) => {
      return headers
        .map((fieldName) => {
          const field = row[fieldName as keyof typeof row]
          if (typeof field === 'string') return `"${field}"`
          if (typeof field === 'number') return field
          if (typeof field === 'boolean') return field ? 'true' : 'false'
          if (typeof field === 'object') return JSON.stringify(field)
          return ''
        })
        .join(',')
    })
  ].join('\n')

  const blob = new Blob([csv], { type: 'text/csv' })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = filename
  link.click()
}
