import { type FormEvent, type FunctionComponent, type ReactNode, useEffect, useState } from 'react'

import { type AZServiceCatalogEntry, type Tenant } from '../../../types/azure'
import { type User } from '../../../types/user'

import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { type OptionTypeBase } from 'react-select'

import { showAlert } from '../../../actions/alertsActions'
import { closeDrawers } from '../../../actions/drawerActions'
import api from '../../../constants/api'
import GroupedSelect from '../GroupedSelect'
import Input from '../Input'
import Spinner from '../Spinner'

interface Props {
  deployment: AZServiceCatalogEntry
  children?: ReactNode
}

/* form names */
interface Forms extends HTMLCollectionOf<HTMLFormElement> {
  newAzureDeploymentForm: HTMLFormElement
}

const AzureSubscriptionDeploymentForm: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { deployment } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const user: User = useSelector((state: RootStateOrAny) => state.user)
  const account = useSelector((state: RootStateOrAny) => state.account)
  const tenants = useSelector((state: RootStateOrAny) => state.tenants)
  const [tenantOptions, setTenantOptions] = useState<OptionTypeBase[]>([])

  const newAzureSubscriptionDeployment = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    const forms = document.forms as Forms
    const form = forms.newAzureDeploymentForm
    const data = new FormData(form)

    const formData: Record<string, unknown> = {}
    for (const [key, val] of data.entries()) {
      formData[key] = val
    }

    const deployment_incident_data = {
      account: account.id,
      category: 'Request',
      title: 'Request for ' + deployment.resource_type + ' deployment',
      contact_type: 'Provide',
      correlation_display: 'ProvideAzureDeployment',
      service_technology: 'c4cd923b1bad99d021b7dc62b24bcb6a',
      issue: '84f305f51b120dd0cf8b404cd34bcb1f',
      assigned_group: 'f9750c4c1bb12190b2cca860f54bcb3a',
      users_affected: 'Single',
      business_impact: 'No immediate impact',
      description: 'Request for  ' + deployment.resource_type + '\n\ndata:\n' + JSON.stringify(formData)
    }

    api
      .post(`/servicenow/account/${account.id}/ticket`, deployment_incident_data)
      .then((incidentResponse) => {
        const deployment_data = {
          CustomerAccId: account.id,
          CustomerRequester: user.first_name + ' ' + user.last_name,
          SnowIncId: incidentResponse.data.id,
          CustomerTenantId: data.get('tenant'),
          SubscriptionName: data.get('name')
        }
        api
          .post(`/azure/account/${account.id}/pipelines/run/${deployment.pipeline_short_name}`, deployment_data)
          .then(() => {
            setLoading(false)
            dispatch(closeDrawers())
            // api add comment based on response
            addComment(incidentResponse.data.id, 'Deployment initiated for')
            navigate('/tickets/' + incidentResponse.data.id, { state: deployment_incident_data })
          })
          .catch((error) => {
            dispatch(
              showAlert({
                type: 'error',
                message: 'We were unable to initiate your deployment to Azure.',
                component: 'NewAzureDeployment',
                error
              })
            )
            addComment(incidentResponse.data.id, error.message)
            navigate('/tickets/' + incidentResponse.data.id, { state: deployment_incident_data })
          })
        dispatch(
          showAlert({
            type: 'success',
            message: incidentResponse.data.number + ' created.',
            component: 'NewAzureDeployment'
          })
        )
      })
      .catch((error) => {
        dispatch(
          showAlert({
            type: 'error',
            message: 'We were unable to initiate your deployment to Azure. Please try again.',
            component: 'NewAzureDeployment',
            error
          })
        )
      })
  }

  const addComment = (ticket_id: string, comment: string) => {
    const data = {
      comment: `Provide <{#}> \n ${comment} ${deployment.resource_type}`,
      assignment_group_id: 'f9750c4c1bb12190b2cca860f54bcb3a'
    }

    api.post(`/servicenow/account/${account.id}/ticket/${ticket_id}/comment`, data).catch((error) => {
      /* alert the user of an unsuccessful comment */
      dispatch(
        showAlert({
          type: 'error',
          message: 'We were unable to update the deployment ticket. Please try again.',
          component: 'Ticket',
          error
        })
      )
    })
  }
  /* build the tenant options from the tenants */
  useEffect(() => {
    if (tenants) {
      if (tenants.length > 0) {
        const options: OptionTypeBase[] = tenants.map((tenant: Tenant) => {
          return {
            label: tenant.name,
            value: tenant.id
          }
        })
        setTenantOptions(options)
      }
    }
  }, [tenants, dispatch])

  return loading ? (
    <div className="z-8 fixed left-0 top-0 flex h-full w-full items-center justify-center bg-th-content opacity-90">
      <Spinner className="h-14 w-14" />
    </div>
  ) : (
    <form
      onSubmit={(e) => {
        newAzureSubscriptionDeployment(e)
      }}
      id="newAzureDeploymentForm"
      className="flex flex-col gap-4"
    >
      <GroupedSelect name="tenant" label="Tenant" options={tenantOptions} required />
      <Input type="text" name="name" label="Subscription Name" required />
    </form>
  )
}

export default AzureSubscriptionDeploymentForm
