export const openNavigation = (): {
  type: string
} => {
  return {
    type: 'OPEN_NAVIGATION'
  }
}

export const closeNavigation = (): {
  type: string
} => {
  return {
    type: 'CLOSE_NAVIGATION'
  }
}
