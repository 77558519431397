import { withAITracking } from '@microsoft/applicationinsights-react-js'

import { Fragment, type FunctionComponent, useEffect, useState } from 'react'

import { type Quote } from '../../types/quote'

import axios from 'axios'
import moment, { type Moment } from 'moment'
import { CSVLink } from 'react-csv'
import { Iconly } from 'react-iconly'
import { FaRegHandshake } from 'react-icons/fa'
import { FcLeave, FcNews, FcOk } from 'react-icons/fc'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { showAlert } from '../../actions/alertsActions'
import { startLoading, stopLoading } from '../../actions/loadingActions'
import Banner from '../../components/Common/Banner'
import Button from '../../components/Common/Button'
import Count from '../../components/Common/Count'
import DateRange from '../../components/Common/DateRange/DateRange'
import Dropdown from '../../components/Common/Dropdown'
import Section from '../../components/Common/Section'
import Spacer from '../../components/Common/Spacer'
import DelegateQuoteModal from '../../components/Modals/DelegateQuoteModal'

/*sign quote modal*/
import SignQuoteModal from '../../components/Modals/SignQuoteModal'
import QuoteTable from '../../components/Tables/QuoteTable'
import { reactPlugin } from '../../configs/appInsights'
import api from '../../constants/api'

/* dropdown options */
const periodOptions = [
  {
    label: 'All time',
    value: 'all'
  },
  {
    label: 'Current Month',
    value: 'current'
  },
  {
    label: 'Previous Month',
    value: 'previous'
  },
  {
    label: 'Custom',
    value: 'custom'
  }
]

const beginningOfTime = moment(0)
const endOfTime = moment().add(1, 'days').startOf('day')

const Quotes: FunctionComponent = (): JSX.Element => {
  const [startDate, setStartDate] = useState<Moment>(beginningOfTime)
  const [endDate, setEndDate] = useState<Moment>(endOfTime)
  const [period, setPeriod] = useState<string>(periodOptions[0].value)
  const [quotes, setQuotes] = useState<Quote[]>([])
  const [filtered, setFiltered] = useState<Quote[]>(quotes)
  const [showSearch, setShowSearch] = useState<boolean>(false)
  const [state, setState] = useState<string | undefined>(undefined)
  const [unsigned, setUnsigned] = useState<number>(0)
  const [accepted, setAccepted] = useState<number>(0)
  const [closed, setClosed] = useState<number>(0)

  const dispatch = useDispatch()
  const account = useSelector((state: RootStateOrAny) => state.account)

  const signingOpen = useSelector((state: RootStateOrAny) => state.modals.signquotemodal)
  const delegatingOpen = useSelector((state: RootStateOrAny) => state.modals.delegatequotemodal)
  /* filter the quotes by search term */
  const filter = (value: string) => {
    const searchTerm = value.toLowerCase()
    setFiltered(quotes.filter((quote: Quote) => quote.number?.toLowerCase().includes(searchTerm) || quote.requested_by?.toLowerCase().includes(searchTerm)))
  }

  /* set the date range based on the period that has been selected */
  const handlePeriodChange = (period: string) => {
    setPeriod(period)

    const beginningOfTime = moment(0)
    const endOfTime = moment().add(1, 'days').startOf('day')
    const firstDayOfThisMonth = moment().startOf('month')
    const lastDayOfThisMonth = moment().endOf('month')

    if (period === 'all') {
      /* set the dates to span all time */
      setStartDate(beginningOfTime)
      setEndDate(endOfTime)
    } else if (period === 'current') {
      /* set the dates to span the current month */
      setStartDate(firstDayOfThisMonth.startOf('month'))
      setEndDate(lastDayOfThisMonth.endOf('month'))
    } else if (period === 'previous') {
      /* set the dates to span the previous month */
      setStartDate(moment(firstDayOfThisMonth.subtract(1, 'months').startOf('month')))
      setEndDate(moment(lastDayOfThisMonth.subtract(1, 'months').endOf('month')))
    }
  }

  /* set the start and end dates */
  const handleDatesChange = (startDate: Moment, endDate: Moment) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  /* start loading animation on page load */
  useEffect(() => {
    dispatch(startLoading())
  }, [dispatch])

  /* get the quotes for the selected account */
  useEffect(() => {
    const source = axios.CancelToken.source()

    dispatch(startLoading())

    const data = {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD')
    }

    if (account.id) {
      api
        .post(`/servicenow/account/${account.id}/quotes`, data, { cancelToken: source.token })
        .then((response) => {
          const states: Record<string, number> = {}

          if (response.data) {
            for (const quote of response.data) {
              if (quote.state.indexOf('-') < 0) {
                states[quote.state] = states[quote.state] ? states[quote.state] + 1 : 1
              } else {
                states[quote.state.substr(0, quote.state.indexOf('-'))] = states[quote.state.substr(0, quote.state.indexOf('-'))]
                  ? states[quote.state.substr(0, quote.state.indexOf('-'))] + 1
                  : 1
              }
            }

            /* stop loading here if there are no quotes to process */
            if (response.data.length === 0) {
              dispatch(stopLoading())
            }
          }

          setQuotes(response.data || [])
          setUnsigned(states['Unsigned'] || 0)
          setAccepted(states['Accepted'] || 0)
          setClosed(states['Closed'] || 0)
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            dispatch(
              showAlert({
                type: 'error',
                message: 'We were unable to retrieve your quotes.',
                component: 'Quotes',
                error
              })
            )
            dispatch(stopLoading())
          }
        })
    }

    return () => {
      source.cancel()
    }
  }, [account, startDate, endDate, dispatch])

  /* filter the quotes by state */
  useEffect(() => {
    const filtered = quotes.filter((quote: Quote) => {
      return quote.state.indexOf(state || '') >= 0 || state === undefined
    })
    setFiltered(filtered)
  }, [state, quotes, showSearch])

  /* stop loading when the quotes have been processed */
  useEffect(() => {
    if (filtered.length > 0) {
      dispatch(stopLoading())
    }
  }, [filtered, dispatch])

  return (
    <Fragment>
      <Section>
        <div className="my-5 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-1 mdlg:grid-cols-2 xl:grid-cols-4">
          <Count
            icon={<FcNews className="h-full w-full text-th-warning" />}
            value={quotes.length}
            label="All Quotes"
            className="cursor-pointer"
            onClick={() => setState(undefined)}
            active={state === undefined}
          />
          <Count
            icon={<FaRegHandshake className="h-full w-full text-th-warning" />}
            value={unsigned}
            label="Unsigned"
            className="cursor-pointer"
            onClick={() => setState('Unsigned')}
            active={state === 'Unsigned'}
          />
          <Count
            icon={<FcOk className="h-full w-full text-th-warning" />}
            value={accepted}
            label="Accepted"
            className="cursor-pointer"
            onClick={() => setState('Accepted')}
            active={state === 'Accepted'}
          />
          <Count
            icon={<FcLeave className="h-full w-full text-th-warning" />}
            value={closed}
            label="Closed"
            className="cursor-pointer"
            onClick={() => setState('Closed')}
            active={state === 'Closed'}
          />
        </div>
      </Section>
      <Section>
        <Banner>
          {/* date range */}
          <Dropdown
            options={periodOptions}
            defaultValue={periodOptions[0]}
            variant={Dropdown.variant.DEFAULT}
            label="Created"
            multi={false}
            onChange={(option) => handlePeriodChange(option.value)}
          />
          <DateRange
            start={startDate}
            end={endDate}
            startDateId="services_start_date"
            endDateId="services_end_date"
            variant={DateRange.variant.DEFAULT}
            onChange={(startDate, endDate) => handleDatesChange(startDate, endDate)}
            disabled={period !== 'custom'}
            hidden={period === 'all'}
            focus={period === 'custom'}
          />
          <Spacer className="hidden sm:block" />
          {/* search */}
          {showSearch ? (
            <Fragment>
              <input type="text" className="rounded-lg p-2 font-body text-sm" placeholder="Search" autoFocus onChange={(e) => filter(e.target.value)} />
              <IoCloseCircleOutline className="h-6 w-6 cursor-pointer" onClick={() => setShowSearch(false)} />
            </Fragment>
          ) : (
            <Iconly name="Search" set="light" className="cursor-pointer" onClick={() => setShowSearch(true)} />
          )}
          {/* csv download */}
          <CSVLink
            data={filtered.map(({ id: _id, account: _account, encrypted_number: _encrypted_number, ...fieldsToKeep }) => fieldsToKeep)}
            filename="quotes.csv"
            target="_blank"
          >
            <Button onClick={() => null}>Download CSV</Button>
          </CSVLink>
        </Banner>
        <Spacer />
        <QuoteTable quotes={filtered} />
        <div id="signQuoteContent">
          {signingOpen && <SignQuoteModal />}
          {delegatingOpen && <DelegateQuoteModal />}
        </div>
      </Section>
    </Fragment>
  )
}

const QuotesWithTracking = withAITracking(reactPlugin, Quotes, 'Quotes')
export default QuotesWithTracking
