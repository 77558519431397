import { useState } from 'react'

import { classNames } from 'src/utils/classNames'
import { formatCurrency } from 'src/utils/format'

import { Iconly } from 'react-iconly'

export type NestedRecord = { children?: NestedRecord[]; title: string; values: { value: number | null; uptrend?: boolean }[] }

type TrendsTableProps = {
  data: NestedRecord[]
  headers: string[]
}

const TrendsTable: React.FC<TrendsTableProps> = ({ data, headers }) => {
  const [openRows, setOpenRows] = useState<Map<string, boolean>>(new Map())
  const handleRowClick = (row: NestedRecord) => {
    setOpenRows((prev) => {
      const newMap = new Map(prev)
      newMap.set(row.title, !prev.get(row.title))
      return newMap
    })
  }

  const renderRow = (row: NestedRecord, depth: number): React.ReactChild => {
    const isOpen = openRows.get(row.title) ?? false

    return (
      <>
        <div style={{ paddingLeft: `${depth * 2}rem` }} onClick={() => handleRowClick(row)} className={classNames(row.children && 'cursor-pointer')}>
          <div className="flex w-full border border-l-2 border-th-border border-l-th-primary py-4 pl-2">
            <div className={classNames('flex grow items-center text-sm', row.children && 'text-bold font-headline')}>
              {row.children && <Iconly name="ChevronDown" set="light" className={classNames('h-4 duration-75', isOpen && 'rotate-180')} />}
              <span className="pl-2" title={row.title}>
                {row.title}
              </span>
            </div>
            <div className="flex gap-3">
              {row.values.map((value, index) => (
                <span
                  className={classNames(
                    'text-bold flex w-28 items-center font-headline text-sm',
                    value.uptrend && 'text-th-red',
                    value.uptrend === false && 'text-th-green',
                    index === 0 && 'font-bold'
                  )}
                >
                  {value.value !== null ? formatCurrency(value.value, true, 0) : 'Not Set'}
                  {value.uptrend !== undefined && <Iconly name={value.uptrend ? 'ArrowUp' : 'ArrowDown'} set="medium" className="h-3" />}
                </span>
              ))}
            </div>
          </div>
        </div>
        {isOpen && row.children?.map((nested_row) => renderRow(nested_row, depth + 1))}
      </>
    )
  }

  return (
    <div className="overflow-x-auto overflow-y-hidden whitespace-nowrap rounded-content border border-th-border bg-th-content pb-8 pt-4">
      <div className="text-bold flex min-w-[40rem] gap-3 py-4 font-headline text-xs text-th-text-secondary">
        <span className="ml-10 grow">Category</span>
        {headers.map((header) => (
          <span className="w-28">{header}</span>
        ))}
      </div>

      <div className="min-w-[40rem] border-b border-th-border">{data.map((row) => renderRow(row, 0))}</div>
    </div>
  )
}

export default TrendsTable
