import { withAITracking } from '@microsoft/applicationinsights-react-js'

import React, { useMemo, useState } from 'react'

import { type Subscription as SubscriptionType } from '../../../types/microsoft365'
import { type OptionTypeBase } from 'react-select/src/types'
import { type Tenant } from 'src/types/azure'

import useSubscriptionsQuery from 'src/hooks/services/microsoft/queries/useSubscriptionsQuery'

import Dropdown from 'src/components/Common/Dropdown'
import TenantDropdown from 'src/components/Dropdowns/TenantDropdown'

import { FcCancel, FcCheckmark, FcClock, FcMinus } from 'react-icons/fc'
import { useNavigate } from 'react-router-dom'

import Banner from '../../../components/Common/Banner'
import Button from '../../../components/Common/Button'
import Section from '../../../components/Common/Section'
import Spacer from '../../../components/Common/Spacer'
import { reactPlugin } from '../../../configs/appInsights'
import Subscription from './Subscription/Subscription'

const stateOptions = [
  {
    label: (
      <span className="flex items-center gap-2">
        <FcCheckmark /> Active
      </span>
    ),
    value: 'active'
  },
  {
    label: (
      <span className="flex items-center gap-2">
        <FcMinus /> Pending
      </span>
    ),
    value: 'pending'
  },
  {
    label: (
      <span className="flex items-center gap-2">
        <FcCancel /> Deleted
      </span>
    ),
    value: 'deleted'
  },
  {
    label: (
      <span className="flex items-center gap-2">
        <FcClock /> Expired
      </span>
    ),
    value: 'expired'
  }
]

const Subscriptions: React.FC = () => {
  const [states, setStates] = useState<string[]>([stateOptions[0].value])
  const [tenant, setTenant] = useState<Tenant | null>(null)
  const navigate = useNavigate()

  const { data: subscriptions, isLoading } = useSubscriptionsQuery(tenant?.id)

  const filteredSubscriptions = useMemo(() => {
    if (!subscriptions) return []
    return subscriptions.filter((subscription: SubscriptionType) => subscription.UnitType === 'Licenses' && states.includes(subscription.Status))
  }, [subscriptions, states])

  return (
    <>
      <Section>
        <Banner>
          <Dropdown
            options={stateOptions}
            defaultValue={[stateOptions[0]]}
            label="State"
            onChange={(selected: OptionTypeBase) => setStates(selected.map((option: any) => option.value))}
            multi
            bordered
          />
          <Spacer />
          <TenantDropdown onChange={(selectedTenants) => setTenant(selectedTenants[0])} showAllTenants />
        </Banner>
      </Section>
      <Section>
        {isLoading ? (
          <div className="grid gap-4 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
            {new Array(12).fill(undefined).map((subscription, index) => (
              <Subscription tenant={tenant} subscription={subscription} key={index} />
            ))}
          </div>
        ) : filteredSubscriptions.length > 0 ? (
          <div className="grid gap-4 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
            {filteredSubscriptions.map((subscription) => (
              <Subscription tenant={tenant} subscription={subscription} key={subscription.Id} />
            ))}
          </div>
        ) : (
          <div className="-mt-48 flex h-screen flex-col items-center justify-center gap-8">
            <div>We couldn't find any subscriptions matching your filters.</div>
            <div>You can purchase new subscriptions from our product catalogue!</div>
            <Button variant="primary" onClick={() => navigate('/products')}>
              View Products
            </Button>
          </div>
        )}
      </Section>
    </>
  )
}

const SubscriptionsWithTracking = withAITracking(reactPlugin, Subscriptions, 'Subscriptions')
export default SubscriptionsWithTracking
