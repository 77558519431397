import api from 'src/constants/api'

import useMutation from 'src/hooks/utils/useMutation'

const useSetUserSysIdMutation = () => {
  return useMutation(
    async (sys_id: string) => {
      const response = await api.post('graph/profile', { sys_id })
      return response.data
    },
    {
      errorMessage: 'Failed to set user sys_id'
    }
  )
}

export default useSetUserSysIdMutation
