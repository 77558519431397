import { useState } from 'react'

import type { ChangeFilterOptions } from '~/hooks/services/servicenow/queries/useChangesQuery'
import { fetchChanges } from '~/hooks/services/servicenow/queries/useChangesQuery'
import { useDownloadProgress } from '~/hooks/utils/useDownloadProgress'

import { exportToCSV } from '../utils/csvExport'

export const useChangeCSV = (accountId: string) => {
  const { downloadPercentage, startProgress, stopProgress } = useDownloadProgress()
  const [downloadError, setDownloadError] = useState<string | null>(null)

  const handleDownload = async (filterOptions: ChangeFilterOptions) => {
    setDownloadError(null)
    const interval = startProgress()

    try {
      const { changes } = await fetchChanges(accountId, {
        ...filterOptions,
        take: 0,
        skip: 0
      })

      if (!changes?.length) {
        setDownloadError('No data found for date range')
        return
      }

      await exportToCSV(changes, 'changes.csv')
    } catch (error) {
      setDownloadError(error instanceof Error ? error.message : 'Unknown error')
    } finally {
      clearInterval(interval)
      stopProgress()
    }
  }

  return {
    downloadPercentage,
    downloadError,
    handleDownload
  }
}
