/* 
  This hook gets the current route object from the routes config using the current pathname.

  This is used to set the correct parent navigation link as active when rendering itself or 
  a child, and to dynamically render the header title, tabs, and call to action.

  It only attempts to match the base route and not nested routes as nested routes should
  exist as tabs on the parent or child. This allows the header to know about all the tabs
  without caring about which one is currently active, as the links will always need to be 
  present if the parent or child that contains them is rendered.

  If no call to action is set on the tab, it will fall back to the childs. A call to action
  can be set on the parent if a route is defined, although its children will not fall back 
  to this if they do not have a call to action.
*/
import { useMemo } from 'react'

import { type Route } from '../../types/navigation'

import { useLocation } from 'react-router'

import { type ChildRouteDefinition, type RouteDefinition, allRoutes } from '../../configs/routes'

const useCurrentRoute = (): Route => {
  const location = useLocation()

  const currentRoute = useMemo(() => {
    const findCurrentRoute = (route: RouteDefinition | ChildRouteDefinition): RouteDefinition | ChildRouteDefinition | null => {
      if (route.route && location.pathname.startsWith(route.route)) return route

      for (const tab of route.tabs ?? []) if (tab.route === location.pathname) return { ...route, action: tab.action }

      const routeChildren = (route as RouteDefinition).children
      for (const child of routeChildren ?? []) {
        const found = findCurrentRoute(child)
        if (found) return found
      }

      return null
    }

    const _route = allRoutes.map((route, index) => ({ route: findCurrentRoute(route), index })).findLast((obj) => obj.route !== null)

    return {
      index: _route?.index ?? 0,
      route: location.pathname,
      label: _route?.route?.label ?? '',
      tabs: _route?.route?.tabs ?? [],
      action: _route?.route?.action ?? undefined
    }
  }, [location.pathname])

  return currentRoute
}

export default useCurrentRoute
