import { type FunctionComponent, type ReactNode } from 'react'

/* utils */
import { classNames } from '../../utils/classNames'

interface Props {
  active?: boolean
  className?: string
  children?: ReactNode
  onClick?: () => unknown
}

const Tile: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { className, children, active } = props

  return (
    <div
      className={classNames('overflow-hidden rounded-2xl border bg-th-content p-4', active ? 'border-th-primary' : 'border-th-border', className)}
      onClick={props.onClick}
    >
      {children}
    </div>
  )
}

export default Tile
