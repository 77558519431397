import { useState } from 'react'

import { type Charge, type ReservedInstance } from '../../types/azure'

import useAccount from 'src/hooks/utils/useAccount'

import { format } from 'src/utils/format'

import moment from 'moment'

import Button from '../Common/Button'
import Callout from '../Common/Callout'
import Input from '../Common/Input'
import Modal from '../Common/Modal'
import PortalDrawer from '../Common/PortalDrawer'

type ReservedInstanceDrawerProps = {
  instance: ReservedInstance
  onClose: () => void
}

const ReservedInstanceDrawer: React.FC<ReservedInstanceDrawerProps> = ({ instance, onClose }) => {
  useAccount({ onChange: onClose })

  const handleNavigateToAzurePortal = () => {
    if (!instance) return

    window.open(
      `https://portal.azure.com/#@${instance.tenant_id}/resource/providers/microsoft.capacity/reservationOrders/${instance.order_id}/reservations/${instance.id}/overview`
    )
  }

  const [cancelConfirmation, setCancelConfirmation] = useState<string | null>(null)
  const expired = moment(instance?.expires).isBefore(moment())

  return (
    <>
      <PortalDrawer
        title="Reserved Instance"
        subtitle={instance?.name}
        onClose={onClose}
        loading={!instance}
        actions={
          <div className="flex justify-end gap-4">
            <Button onClick={handleNavigateToAzurePortal} bordered variant="primary">
              View in Azure Portal
            </Button>
            {/*  <Button className="text-th-error border-th-error" onClick={() => setCancelConfirmation('')} bordered>
              Cancel Reservation
            </Button> */}
          </div>
        }
      >
        {/* id */}
        <div className="">
          <div className="font-bold">ID:</div>
          <div className="text-sm">{instance?.id}</div>
          {!instance && <div className="mt-1 h-4 w-1/2 animate-pulse rounded-md bg-th-border" />}
        </div>

        <br />

        {/* order id */}
        <div className="">
          <div className="font-bold">Order ID:</div>
          <div className="text-sm">{instance?.order_id}</div>
          {!instance && <div className="mt-1 h-4 w-1/2 animate-pulse rounded-md bg-th-border" />}
        </div>

        <hr className="my-8 border-th-border" />

        {/* product id */}
        <div className="flex justify-between">
          <div className="font-bold">Product ID:</div>
          <div className="text-sm">{instance?.product_id}</div>
          {!instance && <div className="h-4 w-1/4 animate-pulse rounded-md bg-th-border" />}
        </div>

        {/* sku */}
        <div className="flex justify-between">
          <div className="font-bold">SKU:</div>
          <div className="text-sm">{instance?.sku_id}</div>
          {!instance && <div className="h-4 w-1/4 animate-pulse rounded-md bg-th-border" />}
        </div>

        {/* term */}
        <div className="flex justify-between">
          <div className="font-bold">Term:</div>
          <div className="text-sm">{format(instance?.term, 'term')}</div>
          {!instance && <div className="h-4 w-1/4 animate-pulse rounded-md bg-th-border" />}
        </div>

        {/* billing plan */}
        <div className="flex justify-between">
          <div className="font-bold">Billing Plan:</div>
          <div className="text-sm">{format(instance?.billing_plan, 'billingPlan')}</div>
          {!instance && <div className="h-4 w-1/4 animate-pulse rounded-md bg-th-border" />}
        </div>

        {/* scope */}
        <div className="flex justify-between">
          <div className="font-bold">Scope:</div>
          <div className="text-sm">{instance?.scope}</div>
          {!instance && <div className="h-4 w-1/4 animate-pulse rounded-md bg-th-border" />}
        </div>

        {/* quantity */}
        <div className="flex justify-between">
          <div className="font-bold">Quantity:</div>
          <div className="text-sm">{instance?.quantity}</div>
          {!instance && <div className="h-4 w-1/4 animate-pulse rounded-md bg-th-border" />}
        </div>

        <hr className="my-8 border-th-border" />

        {expired && <Callout text={`This reserved instance expired ${moment(instance?.expires).fromNow()}`} className="my-8" type="error" />}

        <div className="mb-8 flex justify-between">
          {/* start date */}
          <div>
            <div className="font-bold">Start date</div>
            <div className="text-sm">{format(instance?.start_date, 'date')}</div>
            {!instance && <div className="mt-1 h-4 w-full animate-pulse rounded-md bg-th-border" />}
          </div>

          {/* expires */}
          <div>
            <div className="font-bold">Expires</div>
            <div className="text-sm">{format(instance?.expires, 'date')}</div>
            {!instance && <div className="mt-1 h-4 w-full animate-pulse rounded-md bg-th-border" />}
          </div>
        </div>

        <hr className="my-8 border-th-border" />

        {/* charges */}
        <div className="flex justify-between font-bold">
          <div>Charges</div>
          <div>
            {format(instance?.cost, 'currencyFixed')}
            {!instance && <div className="mt-1 h-6 w-1/4 animate-pulse rounded-md bg-th-border" />}
          </div>
        </div>
        <div className="mt-6">
          <ul>
            {instance?.charges?.map((charge: Charge) => (
              <li key={charge.date} className="flex items-center border-b border-th-border py-2 text-sm last:border-b-0">
                {/* charge details */}
                <div className="flex flex-auto justify-between">
                  <span>{format(charge.date, 'date')}</span>
                  <span>{format(charge.cost, 'currencyFixed')}</span>
                </div>
              </li>
            ))}
            {!instance &&
              Array.from({ length: 5 }, (_, idx) => (
                <li key={idx} className="flex items-center border-b border-th-border py-2 text-sm last:border-b-0">
                  <div className="h-4 w-full animate-pulse rounded-md bg-th-border" />
                </li>
              ))}
          </ul>
        </div>
      </PortalDrawer>
      <Modal
        open={cancelConfirmation !== null}
        title="Confirm Action"
        loading={false}
        className="flex flex-col gap-3"
        onClose={() => setCancelConfirmation(null)}
      >
        <span>
          To cancel this Reserved Instance Reservation, type in{' '}
          <span className="rounded-md bg-th-content-secondary px-1.5 py-0.5 text-sm font-bold"> {instance?.name}</span> below and click confirm.
        </span>
        <Input type="text" name="cancel" className="rounded-full" onChange={(e) => setCancelConfirmation(e.target.value)} />
        <div className="flex justify-end">
          <Button
            disabled={cancelConfirmation !== instance?.name}
            bordered
            className="border-th-error text-th-error"
            onClick={() => {
              setCancelConfirmation(null)
              onClose()
            }}
          >
            Confirm
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default ReservedInstanceDrawer
