import { Fragment, type FunctionComponent, useEffect, useState } from 'react'

import { Iconly } from 'react-iconly'
import ReactPaginate from 'react-paginate'
import SmoothScrollbar from 'smooth-scrollbar'

import Dropdown from './Dropdown'
import Spacer from './Spacer'

interface Props {
  data: any[]
  onPageChange: (currentPage: any[]) => void
}

/* pagination component */
const Pagination: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data } = props

  const [currentItems, setCurrentItems] = useState<any[]>([])
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(12)
  const [previousItemsPerPage, setPreviousItemsPerPage] = useState(12)

  /* pagination options */
  const itemsPerPageOptions = [
    {
      label: '12',
      value: 12
    },
    {
      label: '24',
      value: 24
    },
    {
      label: '48',
      value: 48
    }
  ]

  /* scroll top the top of the content div */
  const resetScroll = () => {
    const scrollbar = document.getElementsByClassName('scrollbar')[0] as HTMLElement
    if (scrollbar) SmoothScrollbar.get(scrollbar)?.setPosition(0, 0)
  }

  /* populate the current items based on offset */
  useEffect(() => {
    if (data) {
      const endOffset = itemOffset + itemsPerPage
      setCurrentItems(data.slice(itemOffset, endOffset))
      setPageCount(Math.ceil(data.length / itemsPerPage))
      props.onPageChange(data.slice(itemOffset, endOffset))

      /* reset the content scrollbar if the number of items per page was reduced */
      if (itemsPerPage < previousItemsPerPage) {
        resetScroll()
      }

      setPreviousItemsPerPage(itemsPerPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, itemOffset, itemsPerPage])

  /* request another page */
  const handlePageClick = (event: Record<string, number>) => {
    const newOffset = (event.selected * itemsPerPage) % data.length
    setItemOffset(newOffset)
    resetScroll()
  }

  return (
    <Fragment>
      <div className="fixed bottom-0 left-0 w-full md:pl-60">
        <div className="flex flex-wrap justify-between gap-2 rounded-t-content bg-th-content px-5 py-3 xs:gap-4 xs:px-10">
          <div className="order-2 text-th-text-secondary xl:order-1">
            {/* items per page */}
            <Dropdown
              options={itemsPerPageOptions}
              defaultValue={itemsPerPageOptions[0]}
              variant={Dropdown.variant.DEFAULT}
              label="Showing"
              multi={false}
              onChange={(option) => setItemsPerPage(option.value)}
              bordered
            />
          </div>
          <ReactPaginate
            previousLabel={<Iconly name="ArrowLeft" set="light" className="text-th-text-secondary" />}
            breakLabel="..."
            nextLabel={<Iconly name="ArrowRight" set="light" className="text-th-text-secondary" />}
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            renderOnZeroPageCount={undefined}
            className="order-1 flex w-full justify-center gap-2 bg-th-content font-bold xl:order-2 xl:w-auto"
            activeLinkClassName="text-th-primary border border-th-primary"
            disabledClassName="bg-th-content-secondary"
            pageClassName="border border-th-border rounded-lg w-12 h-10 flex"
            pageLinkClassName="w-full h-full rounded-lg text-center leading-9"
            previousClassName="border border-th-border rounded-lg w-12 h-10 flex justify-center items-center"
            breakClassName="w-12 h-10 flex"
            breakLinkClassName="self-center w-full text-center"
            nextClassName="border border-th-border rounded-lg w-12 h-10 flex justify-center items-center"
          />
          <div className="order-3 flex items-center text-right text-sm font-bold text-th-text-secondary">
            Showing {currentItems.length} of {data?.length || 0} entries
          </div>
        </div>
      </div>
      {/* spacers to allow content to be scrolled above fixed pagination element */}
      <Spacer />
      <Spacer />
      <Spacer className="xl:hidden" />
      <Spacer className="xl:hidden" />
    </Fragment>
  )
}

export default Pagination
