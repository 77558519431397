import { Fragment, type FunctionComponent, useEffect, useState } from 'react'

import { type Service } from '../../types/service'

import moment from 'moment'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { closeDrawers, openCancelServiceDrawer } from '../../actions/drawerActions'
import Button from '../Common/Button'
import Drawer from '../Common/Drawer'

const ServiceDrawer: FunctionComponent = (): JSX.Element => {
  const service: Service = useSelector((state: RootStateOrAny) => state.drawers.service)

  const [loading, setLoading] = useState(true)

  const dispatch = useDispatch()

  useEffect(() => {
    setLoading(false)
  }, [])

  /* drawer actions */
  const Actions: FunctionComponent = (): JSX.Element => {
    return (
      <Fragment>
        <Button bordered onClick={() => dispatch(closeDrawers())}>
          Cancel
        </Button>
        {service.state === 'Active' && (
          <Button bordered onClick={() => dispatch(openCancelServiceDrawer(service))}>
            Cancel Service
          </Button>
        )}
      </Fragment>
    )
  }

  /* drawer content */
  return (
    <Drawer title={service ? service.number : 'Service'} loading={loading} actions={<Actions />}>
      <div className="mb-8 flex justify-between">
        {/* rname */}
        <div>
          <div className="font-bold">Name</div>
          <div className="text-sm">{service.name}</div>
        </div>
      </div>
      <div className="mb-8 flex justify-between">
        {/* order number */}
        <div>
          <div className="font-bold">Order</div>
          <div className="text-sm">{service.order_number}</div>
        </div>
      </div>
      <div className="mb-8 flex justify-between">
        {/* state */}
        <div>
          <div className="font-bold">State</div>
          <div className="text-sm">{service.state}</div>
        </div>
      </div>
      <div className="mb-8 flex justify-between">
        {/* price */}
        <div>
          <div className="font-bold">Price</div>
          <div className="text-sm">{service.total}</div>
        </div>
      </div>
      <div className="mb-8 flex justify-between">
        {/* recurrence */}
        <div>
          <div className="font-bold">Recurrence</div>
          <div className="text-sm">{service.recurrence}</div>
        </div>
      </div>
      <div className="mb-8 flex justify-between">
        {/* start */}
        <div>
          <div className="font-bold">Start date</div>
          <div className="text-sm">{moment(service.start_date).format('DD/MM/yy')}</div>
        </div>
      </div>
      <div className="mb-8 flex justify-between">
        {/* end */}
        <div>
          <div className="font-bold">End date</div>
          <div className="text-sm">{moment(service.end_date).format('DD/MM/yy')}</div>
        </div>
      </div>
      <hr className="my-8 border-th-border" />
    </Drawer>
  )
}

export default ServiceDrawer
