import { type FunctionComponent, useEffect } from 'react'

import { type Account } from '../../../../types/account'
import { type Role as RoleType } from '../../../../types/role'
import { type User } from '../../../../types/user'

import axios from 'axios'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { showAlert } from '../../../../actions/alertsActions'
import { startLoading, stopLoading } from '../../../../actions/loadingActions'
import Button from '../../../../components/Common/Button'
import Popover from '../../../../components/Common/Popover'
import Switch from '../../../../components/Common/Switch'
import api from '../../../../constants/api'

interface Props {
  user: User
  role: RoleType
  initialState: boolean
  onChange: (checked: boolean, role: string) => Promise<boolean>
}

const Role: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { role, initialState } = props

  const dispatch = useDispatch()
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  const user: User = useSelector((state: RootStateOrAny) => state.user)
  const account: Account = useSelector((state: RootStateOrAny) => state.account)

  const handleClick = async () => {
    dispatch(startLoading())

    api
      .post(
        `/provide/account/${account.id}/role/request`,
        {
          account: account.name,
          role: role.name
        },
        { cancelToken: source.token }
      )
      .then(() => {
        dispatch(stopLoading())
        dispatch(
          showAlert({
            type: 'success',
            message: 'We have sent your request to your account admins.',
            component: 'Role'
          })
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(stopLoading())
          dispatch(
            showAlert({
              type: 'error',
              message: `We were unable to send the request. Please try again later.`,
              component: 'Role',
              error
            })
          )
        }
      })
  }

  useEffect(() => {
    return () => {
      // cancel source reqs on componentwillunmount
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div key={role.id} className="flex flex-wrap items-center border-b border-th-border last:border-0 lg:gap-4">
      <div className="w-52 flex-1 p-4 text-th-text lg:flex-none">{role.name}</div>
      <div className="flex p-4">
        <Switch
          id={role.id}
          onChange={(checked) => props.onChange(checked, role.id)}
          defaultChecked={initialState}
          disabled={props.user.email === user.email}
        />
      </div>
      <div className="flex-0 w-full p-4 lg:flex-1">{role.description}</div>
      {props.user.email === user.email && !initialState && (
        <div className="flex p-4">
          <Popover text="Send a request for this role to your admins.">
            <Button bordered onClick={handleClick}>
              Request
            </Button>
          </Popover>
        </div>
      )}
    </div>
  )
}

export default Role
