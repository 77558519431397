import type { ReservedInstance, Tenant } from 'src/types/azure'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useQuery from 'src/hooks/utils/useQuery'

import moment from 'moment'

interface useReservedInstancesQueryProps {
  tenants: Tenant[]
}

const useReservedInstancesQuery = ({ tenants }: useReservedInstancesQueryProps) => {
  const account = useAccount()

  const { data: reservedInstances, ...rest } = useQuery(
    ['reserved-instances', account.id, tenants],
    async ({ signal }) => {
      const response = await api.post(
        `/servicenow/account/${account.id}/reserved_instances`,
        {
          tenant_ids: tenants.map((t) => t.id),
          start_date: '1970-01-01',
          end_date: moment().format('YYYY-MM-DD')
        },
        { signal }
      )
      return response.data as ReservedInstance[]
    },
    {
      enabled: !!account.id && !!tenants && !!tenants.length,
      errorMessage: 'Failed to fetch reserved instances'
    }
  )

  const instances = reservedInstances ?? []

  const stateExtendedInstances = instances.map((instance) => {
    const instanceProvisioningState = (instance as ReservedInstance).provisioning_state as 'Cancelled' | 'Swapped' | 'Succeeded' | undefined
    const lastChargeCancelled = instance.charges?.[0]?.type === 'cancelImmediate'

    return {
      ...instance,
      state: (lastChargeCancelled || instanceProvisioningState !== 'Succeeded' ? 'Cancelled' : instance.provide_state) as 'Active' | 'Cancelled' | 'Expired'
    }
  })

  return { data: stateExtendedInstances, ...rest }
}

export default useReservedInstancesQuery
