import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { Attachment } from '~/types/attachment'

import api from 'src/constants/api'

import useAccount from '~/hooks/utils/useAccount'

import { getChangeAttachmentsQueryKey } from '../queries/useChangeAttachmentsQuery'

interface AttachmentMutationParams {
  changeId: string
  attachmentId: string
}

export const useDeleteChangeAttachmentMutation = () => {
  const queryClient = useQueryClient()
  const account = useAccount()

  return useMutation({
    mutationFn: async ({ changeId, attachmentId }: AttachmentMutationParams) => {
      const response = await api.delete(`/servicenow/account/${account.id}/change_request/${changeId}/attachment/${attachmentId}`)

      await queryClient.invalidateQueries({ queryKey: getChangeAttachmentsQueryKey(account.id, changeId) })

      return response.data as Attachment[]
    }
  })
}
