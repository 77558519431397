import { type UseBaseQueryOptions } from '@tanstack/react-query'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useQuery from 'src/hooks/utils/useQuery'

import type { Change } from './useChangesQuery'

export const fetchChange = async (accountId: string, changeId: string, signal?: AbortSignal) => {
  const response = await api.get(`/servicenow/account/${accountId}/change/${changeId}`, {
    signal
  })
  return response.data as Change
}

export const getChangeQueryKey = (accountId: string, changeId: string) => {
  return ['getChangeQuery', accountId, changeId] as const
}

const useChangeQuery = (changeId: string | undefined, options?: UseBaseQueryOptions<Change>) => {
  const account = useAccount()

  return useQuery(getChangeQueryKey(account.id, changeId || ''), async ({ signal }) => fetchChange(account.id, changeId!, signal), {
    enabled: !!account.id && !!changeId,
    refetchOnWindowFocus: false,
    ...options
  })
}

export default useChangeQuery
