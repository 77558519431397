import { type FunctionComponent, type ReactNode, useEffect } from 'react'

import { type Alert as AlertType } from '../../../types/alert'

import { IoAlertCircle, IoCheckmarkCircle, IoCloseCircle, IoCloseCircleOutline, IoInformationCircle } from 'react-icons/io5'
import { useDispatch } from 'react-redux'

import { hideAlert } from '../../../actions/alertsActions'

/* utils */
import { classNames } from '../../../utils/classNames'

interface Props {
  alert: AlertType
  index: number
}

const Alert: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { alert, index } = props

  const dispatch = useDispatch()

  /* hide the alert after 5 seconds */
  useEffect(() => {
    setTimeout(() => {
      dispatch(hideAlert(0))
    }, 5000)
  }, [dispatch])

  let border: string
  let background: string
  let text: string
  let icon: ReactNode

  switch (alert.type) {
    case 'error':
      border = 'border-th-error'
      background = 'bg-th-error-light'
      text = 'text-th-error'
      icon = <IoCloseCircle className={classNames('h-6 w-6', text)} />
      break
    case 'warning':
      border = 'border-th-warning'
      background = 'bg-th-warning-light'
      text = 'text-th-warning'
      icon = <IoAlertCircle className={classNames('h-6 w-6', text)} />
      break
    case 'success':
      border = 'border-th-success'
      background = 'bg-th-success-light'
      text = 'text-th-success'
      icon = <IoCheckmarkCircle className={classNames('h-6 w-6', text)} />
      break
    case 'info':
    default:
      border = 'border-th-info'
      background = 'bg-th-info-light'
      text = 'text-th-info'
      icon = <IoInformationCircle className={classNames('h-6 w-6', text)} />
      break
  }

  return (
    <div className={classNames('mb-2 flex items-center gap-4 rounded border-l-4 px-4 py-3 shadow', background, border)}>
      {/* alert icon */}
      <div>{icon}</div>
      {/* alert message */}
      <p className={classNames('flex-auto font-bold', text)}>{alert.message}</p>
      {/* close */}
      <div>
        <IoCloseCircleOutline className={classNames('h-6 w-6 cursor-pointer', text)} onClick={() => dispatch(hideAlert(index))} />
      </div>
    </div>
  )
}

export default Alert
