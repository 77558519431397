import { useMemo } from 'react'

import useTimeseriesDataShape from 'src/hooks/composite/calculation/useTimeseriesDataShape'
import useDailyTotalsForTagset from 'src/hooks/services/microsoft/queries/useDailyTotalsForTagset'
import { type MergedInsight } from 'src/hooks/services/microsoft/queries/useTrendsInsights'
import { useTenantsQuery } from 'src/hooks/services/servicenow/queries/useTenantsQuery'

import { formatCurrency, formatDate } from 'src/utils/format'

import { type Moment } from 'moment'
import moment from 'moment'
import { ReferenceLine } from 'recharts'

import PortalDrawer from '../Common/PortalDrawer'
import { InsightsMapper } from '../Content/InsightsCarousel'
import SingleLineGraph from '../Graphs/SingleLineGraph'

type InsightDrawerProps = {
  insight: MergedInsight
  onClose: () => void
}

const levelMapper = {
  TenantId: 'Tenant',
  SubscriptionId: 'Subscription',
  ResourceGroup: 'Resource Group',
  MeterCategory: 'Service',
  ResourceLocation: 'Region'
}

const InsightDrawer: React.FC<InsightDrawerProps> = ({ insight, onClose }) => {
  const dateRange = useMemo(() => {
    const middleRange = ['New', 'Ended'].includes(insight.type)

    const startDates = {
      'n/a':
        insight.type === 'New'
          ? moment(insight.startDate ?? insight.date).subtract(1, 'day')
          : insight.type === 'Unusual'
            ? moment(insight.startDate ?? insight.date).subtract(1, 'day')
            : moment(insight.startDate ?? insight.date).subtract(2, 'week'),
      month: middleRange
        ? moment(insight.startDate ?? insight.date).subtract(1, 'month')
        : moment(insight.startDate ?? insight.date)
            .subtract(1, 'month')
            .subtract(8, 'day'),
      quarter: middleRange
        ? moment(insight.startDate ?? insight.date).subtract(2, 'month')
        : moment(insight.startDate ?? insight.date)
            .subtract(3, 'month')
            .subtract(22, 'day'),
      year: middleRange
        ? moment(insight.startDate ?? insight.date).subtract(7, 'months')
        : moment(insight.startDate ?? insight.date)
            .subtract(15, 'month')
            .subtract(1, 'day')
    } as Record<string, Moment>

    const endDates = {
      'n/a': ['New'].includes(insight.type)
        ? moment(insight.endDate ?? insight.date).add(2, 'week')
        : insight.type === 'Unusual'
          ? moment().subtract(1, 'day')
          : moment(insight.endDate ?? insight.date).add(1, 'day'),
      month: middleRange ? moment(insight.endDate ?? insight.date).add(1, 'month') : moment(insight.endDate ?? insight.date).add(1, 'day'),
      quarter: middleRange ? moment(insight.endDate ?? insight.date).add(2, 'month') : moment(insight.endDate ?? insight.date).add(1, 'day'),
      year: middleRange ? moment(insight.endDate ?? insight.date).add(7, 'months') : moment(insight.endDate ?? insight.date).add(1, 'day')
    } as Record<string, Moment>

    return [startDates[insight.period], endDates[insight.period]] as [Moment, Moment]
  }, [insight])

  const { data: tenants } = useTenantsQuery({ active: true })

  const tenantConsumption = useDailyTotalsForTagset({
    dateRange: dateRange,
    tenants: tenants,
    tags: [],
    groupBy: ['TenantId', 'SubscriptionId'].includes(insight.group)
      ? null
      : ((insight.group.replaceAll(' ', '')[0].toLowerCase() + insight.group.replaceAll(' ', '').slice(1)) as 'meterCategory')
  })
  const specificConsumption = tenantConsumption?.data?.filter((c) => {
    if (insight.group === 'SubscriptionId') return c.subscriptionId === insight.groupName
    if (insight.group === 'TenantId') return c.tenantId === insight.groupName

    const key = insight.group.replaceAll(' ', '')[0].toLowerCase() + insight.group.replaceAll(' ', '').slice(1)
    return c[key as keyof typeof c] === insight.groupName && c.subscriptionId === insight.subscriptionId && c.tenantId === insight.tenantId
  })
  const alteredConsumption = useTimeseriesDataShape({ data: specificConsumption ?? [], dateRange: dateRange, forceDaily: true })
  const subscriptions = tenants?.flatMap((tenant) => tenant.subscriptions)

  const { title, message } = InsightsMapper(insight, tenants, subscriptions)

  return (
    <PortalDrawer
      title={title}
      onClose={onClose}
      subtitle={formatDate(insight.date) + ' - ' + levelMapper[insight.group as keyof typeof levelMapper] + ' Level'}
    >
      <div className="text-sm">{message}</div>

      <br />

      <SingleLineGraph
        data={alteredConsumption.map((d) => ({
          ...d,
          name: d.date,
          value: d.projected ? 0 : d.value
        }))}
        isLoading={tenantConsumption === undefined}
        interactive
        className="h-60 !p-0"
        extraLines={
          <>
            <ReferenceLine x={moment(insight.date).format('DD MMM')} stroke="red" />
            <ReferenceLine x={moment(insight.date).format('MMM YYYY')} stroke="red" />

            {insight.startDate && insight.endDate && (
              <>
                <ReferenceLine x={moment(insight.startDate).format('DD MMM')} stroke="red" />
                <ReferenceLine x={moment(insight.endDate).format('DD MMM')} stroke="red" />
                <ReferenceLine x={moment(insight.startDate).format('MMM YYYY')} stroke="red" />
                <ReferenceLine x={moment(insight.endDate).format('MMM YYYY')} stroke="red" />
              </>
            )}
            {!['New', 'Ended', 'Unusual'].includes(insight.type) && (
              <>
                <ReferenceLine
                  x={moment(insight.date)
                    .add(1, insight.period as any)
                    .format('DD MMM')}
                  stroke="red"
                />
                <ReferenceLine
                  x={moment(insight.date)
                    .add(1, insight.period as any)
                    .format('MMM YYYY')}
                  stroke="red"
                />
              </>
            )}
          </>
        }
      />

      <br />

      <div className="mb-6 grid grid-cols-2 gap-4">
        <div className="rounded-md border border-th-border p-4">
          <p className="text-center text-th-text-secondary">Period</p>
          <p className="text-center text-lg font-bold capitalize text-th-text">{insight.period}</p>
        </div>

        <div className="rounded-md border border-th-border p-4">
          <p className="text-center text-th-text-secondary">Amount</p>
          <p className="text-center text-lg font-bold text-th-text">
            {['Decrease', 'Ended'].includes(insight.type) ? '-' : '+'}
            {!['New', 'Ended', 'Unusual'].includes(insight.type) ? formatCurrency(insight.amount, true, 0) : formatCurrency(insight.amount, true, 0)}
          </p>
        </div>
      </div>
    </PortalDrawer>
  )
}

export default InsightDrawer
