import { useEffect, useState } from 'react'

import { type Account } from 'src/types/account'

import { type RootStateOrAny, useSelector } from 'react-redux'

type UseAccountProps = {
  onChange?: (account: Account) => void
}

const useAccount = (props?: UseAccountProps) => {
  const account: Account = useSelector((state: RootStateOrAny) => state.account)
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (initialized && props?.onChange) props.onChange(account)
    setInitialized(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, initialized])

  return account
}

export default useAccount
