import { type Alert } from '../types/alert'

const initialState: Alert[] = []

const alertReducer = (
  state = initialState,
  action: {
    type: string
    payload: {
      alert: Alert
      index: number
    }
  }
): Alert[] => {
  switch (action.type) {
    case 'SHOW_ALERT':
      return [...state, action.payload.alert]
    case 'HIDE_ALERT':
      return state.filter((alert: Alert, index: number) => index !== action.payload.index)
    default:
      return state
  }
}

export default alertReducer
