import RefactoredDropdown from '~/components/Common/RefactoredDropdown'

import { CATEGORY_OPTIONS } from '../../constants'
import { useChangeFilters } from '../../hooks/useChangeFilters'

export const CategoryFilter = () => {
  const { filterOptions, handleCategoryFilter } = useChangeFilters()

  return (
    <RefactoredDropdown
      data={CATEGORY_OPTIONS.map((category) => ({
        label: category,
        value: category,
        checked: filterOptions.category?.includes(category) || false,
        disabled: filterOptions.category?.length === 1 ? filterOptions.category.includes(category) : false
      }))}
      label="Category"
      onChange={(_, selectedNodes) => handleCategoryFilter(selectedNodes.map((o) => o.value))}
      disabled={!!filterOptions.keyword}
    />
  )
}
