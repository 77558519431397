import { useMemo } from 'react'

import { type Entitlement, type Tenant } from 'src/types/azure'

import { useTenantsQuery } from 'src/hooks/services/servicenow/queries/useTenantsQuery'

import { type TreeNode } from 'react-dropdown-tree-select'

import NestedDropdown from '../Common/RefactoredDropdown'

type SubscriptionDropdownProps = {
  onChange?: (selectedTenants: Tenant[], selectedSubscriptions: Entitlement[]) => void
  showAllTenants?: boolean
  className?: string
}

const SubscriptionDropdown: React.FC<SubscriptionDropdownProps> = ({ onChange, showAllTenants, className }) => {
  const { data: tenants, isLoading } = useTenantsQuery({ active: true })

  const data = useMemo(() => {
    const mappedData = tenants.map((tenant) => ({
      label: tenant.name,
      value: tenant.id,
      expanded: true,
      checked: true,
      children: tenant.subscriptions.map((option) => ({
        label: option.name,
        value: option.id,
        checked: true
      }))
    }))

    const result = showAllTenants ? mappedData : mappedData.filter((tenant) => tenant.children?.length)
    onChange?.(
      tenants,
      tenants.flatMap((tenant) => tenant.subscriptions)
    )
    return result
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAllTenants, tenants])

  const allSubscriptions = useMemo(() => tenants.flatMap((tenant) => tenant.subscriptions), [tenants])

  const handleDropdownChange = (_curr: TreeNode, _data: TreeNode[]) => {
    const topLevel = _data.filter((d) => d._depth === 0)
    const secondaryLevel = _data.filter((d) => d._depth === 1)

    // const selectedTenants = topLevel.map((tenant) => tenants.find((t) => tenant.value === t.id)).filter((t) => t) as Tenant[] // FOR EXCLUDING PARTIAL SELECTIONS
    const selectedTenants = tenants.filter(
      (tenant) => topLevel.some((t) => tenant.id === t.value) || tenant.subscriptions.some((sub) => secondaryLevel.some((s) => s.value === sub.id))
    ) // FOR INCLUDING PARTIAL SELECTIONS

    const selectedSubscriptions = topLevel
      .flatMap((tenant) => tenants.find((t) => tenant.value === t.id)?.subscriptions)
      .concat(secondaryLevel.map((subscription) => allSubscriptions.find((s) => s.id === subscription.value)))
      .filter((s) => s) as Entitlement[]

    onChange?.(selectedTenants, selectedSubscriptions)
  }

  return <NestedDropdown data={data} onChange={handleDropdownChange} label="Subscriptions" loading={isLoading} className={className} />
}

export default SubscriptionDropdown
