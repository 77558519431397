const initialState = {
  loading: true
}

const loadingReducer = (
  state = initialState,
  action: {
    type: string
  }
): {
  loading: boolean
} => {
  switch (action.type) {
    case 'START_LOADING':
      return {
        loading: true
      }
    case 'STOP_LOADING':
      return {
        loading: false
      }
    default:
      return state
  }
}

export default loadingReducer
