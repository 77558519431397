import * as React from 'react'

import { type VariantProps, cva } from 'class-variance-authority'

import { classNames } from '../../utils/classNames'

const submitVariants = cva(
  'flex items-center font-headline rounded-full py-2 px-4 focus:outline-none justify-center text-sm tracking-wide h-full cursor-pointer disabled:opacity-50 disabled:cursor-default',
  {
    variants: {
      variant: {
        primary: 'bg-th-primary text-th-white',
        secondary: 'bg-th-content-secondary',
        default: 'bg-th-content text-th-text'
      },
      bordered: {
        true: 'border border-th-border',
        false: ''
      }
    },
    defaultVariants: {
      variant: 'default',
      bordered: false
    }
  }
)

export interface SubmitProps extends React.InputHTMLAttributes<HTMLInputElement>, VariantProps<typeof submitVariants> {
  form?: string
}

const Submit = React.forwardRef<HTMLInputElement, SubmitProps>(({ form, variant, bordered, className, ...props }, ref) => {
  return <input type="submit" form={form} className={classNames(submitVariants({ variant, bordered, className }))} ref={ref} disabled={props.disabled} />
})

Submit.displayName = 'Submit'

// eslint-disable-next-line react-refresh/only-export-components
export { Submit, submitVariants }
export default Submit
