import { withAITracking } from '@microsoft/applicationinsights-react-js'

import { Fragment, type FunctionComponent, useEffect, useState } from 'react'

import { type Account } from '../../types/account'
import { type Contact } from '../../types/contact'
import { type User } from '../../types/user'

import axios, { type AxiosResponse } from 'axios'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { showAlert } from '../../actions/alertsActions'
import { startLoading, stopLoading } from '../../actions/loadingActions'
import Button from '../../components/Common/Button'
import Heading from '../../components/Common/Heading'
import Section from '../../components/Common/Section'
import { reactPlugin } from '../../configs/appInsights'
import api from '../../constants/api'
import ContactDetails from './ContactDetails/ContactDetails'
import Roles from './Roles/Roles'

const Profile: FunctionComponent = (): JSX.Element => {
  const dispatch = useDispatch()
  const location = useLocation()

  const user: User = useSelector((state: RootStateOrAny) => state.user)
  const account: Account = useSelector((state: RootStateOrAny) => state.account)

  const [contact, setContact] = useState<Contact>(location.state)
  const [selectedUser, setSelectedUser] = useState<User>()

  /* create the users B2C profile */
  const createProfile = (contact: Contact) => {
    dispatch(startLoading())

    api
      .post('/graph/user', {
        first_name: contact.first_name,
        last_name: contact.last_name,
        email: contact.email,
        mobile: contact.mobile,
        sys_id: contact.id
      })
      .then(() => {
        /* rerender the page to display the permissions section */
        api
          .get(`/provide/account/${account.id}/user/${contact.email}`)
          .then((response: AxiosResponse) => {
            setSelectedUser(response.data)
            dispatch(stopLoading())
          })
          .catch((error) => {
            if (!axios.isCancel(error)) {
              if (error.response?.status === 409) {
                /* multiple users with the same email have been found */
                dispatch(
                  showAlert({
                    type: 'error',
                    message: `We have found multiple users with ${contact.first_name}'s email address.`,
                    component: 'Profile',
                    error
                  })
                )
              } else if (error.response?.status !== 404) {
                dispatch(
                  showAlert({
                    type: 'error',
                    message: `We are having problems retrieving ${contact.first_name}'s profile. Please try again later.`,
                    component: 'Profile',
                    error
                  })
                )
              }
              dispatch(stopLoading())
            }
          })
        dispatch(
          showAlert({
            type: 'success',
            message: `${contact.first_name} has been successfully registered. An email with further instructions has been sent to them.`,
            component: 'Profile'
          })
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          /* a user with this email already exists */
          if (error.response?.status === 409) {
            dispatch(stopLoading())
            dispatch(
              showAlert({
                type: 'error',
                message: `A user with this email already exists.`,
                component: 'Profile',
                error
              })
            )
          } else {
            dispatch(stopLoading())
            dispatch(
              showAlert({
                type: 'error',
                message: `We were unable to create ${contact.first_name}'s profile. Please try again later.`,
                component: 'Profile',
                error
              })
            )
          }
        }
      })
  }

  /* get the contact record for the user if they're viewing their own profile */
  useEffect(() => {
    const source = axios.CancelToken.source()

    if (account.id) {
      if (user.email && (!contact || !location.state)) {
        dispatch(startLoading())

        api
          .get(`/servicenow/contact/email`, { cancelToken: source.token })
          .then((response: AxiosResponse) => {
            location.state = response.data
            setContact(response.data)
            dispatch(stopLoading())
          })
          .catch((error) => {
            if (!axios.isCancel(error)) {
              dispatch(stopLoading())
              dispatch(
                showAlert({
                  type: 'error',
                  message: `We were unable to retrieve your profile. Please try again later.`,
                  component: 'Profile',
                  error
                })
              )
            }
          })
      }
    }

    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.id, user.email, location.state])

  /* get the contacts user record from the provide database */
  useEffect(() => {
    const source = axios.CancelToken.source()

    if (account && contact) {
      dispatch(startLoading())

      if (contact.email === user.email) {
        /* get the users own profile from b2c using their id */
        api
          .get(`/provide/user`, { cancelToken: source.token })
          .then((response: AxiosResponse) => {
            setSelectedUser(response.data)
            dispatch(stopLoading())
          })
          .catch((error) => {
            if (!axios.isCancel(error)) {
              dispatch(stopLoading())
              dispatch(
                showAlert({
                  type: 'error',
                  message: 'We were unable to retrieve your profile.',
                  component: 'Profile',
                  error
                })
              )
            }
          })
      } else {
        /* get the contacts user profile from b2c using their email */
        api
          .get(`/provide/account/${account.id}/user/${contact.email}`, { cancelToken: source.token })
          .then((response: AxiosResponse) => {
            setSelectedUser(response.data)
            dispatch(stopLoading())
          })
          .catch((error) => {
            if (!axios.isCancel(error)) {
              if (error.response?.status === 409) {
                /* multiple users with the same email have been found */
                dispatch(
                  showAlert({
                    type: 'error',
                    message: `We have found multiple users with ${contact.first_name}'s email address.`,
                    component: 'Profile',
                    error
                  })
                )
              } else if (error.response?.status !== 404) {
                dispatch(
                  showAlert({
                    type: 'error',
                    message: `We are having problems retrieving ${contact.first_name}'s profile. Please try again later.`,
                    component: 'Profile',
                    error
                  })
                )
              }
              dispatch(stopLoading())
            }
          })
      }
    }

    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.id, contact])

  return (
    <Fragment>
      <Section>
        <ContactDetails contact={contact} />
      </Section>
      <Section>
        {selectedUser ? (
          /* roles */
          <Fragment>
            <Heading text="Roles" />
            <Roles user={selectedUser} />
          </Fragment>
        ) : (
          /* create the users profile for them */
          <div className="flex h-full w-full flex-col items-center justify-center gap-4">
            This user has not signed up to Provide yet.
            <Button onClick={() => createProfile(contact)} variant="primary" className="w-max">
              Create Profile
            </Button>
          </div>
        )}
      </Section>
    </Fragment>
  )
}

const ProfileWithTracking = withAITracking(reactPlugin, Profile, 'Profile')
export default ProfileWithTracking
