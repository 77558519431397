import { type Price, type Subscription } from '../../../../types/microsoft365'

import api from 'src/constants/api'

import useQuery from 'src/hooks/utils/useQuery'

import moment from 'moment'

export const useSubscriptionPriceQuery = (accountId: string, subscription?: Subscription | null) => {
  return useQuery(
    ['subscriptionPrice', accountId, subscription?.OfferId.split(':')[0], subscription?.OfferId.split(':')[1]],
    async ({ signal }) => {
      const ids = subscription!.OfferId.split(':') // [productId, skuId]
      const date = moment(subscription?.EffectiveStartDate).format('YYYY-MM-DD')
      const { data } = await api.get(`/ms-api/products/account/${accountId}/${ids[0]}/sku/${ids[1]}/prices/${date}`, { signal })

      return data.find((p: Price) => p.billingPlan.trim().toLowerCase() === subscription?.BillingCycle && p.termDuration === subscription.TermDuration) as Price
    },
    {
      enabled: !!accountId && !!subscription && !!subscription?.OfferId && subscription.ProductType?.Id === 'OnlineServicesNCE',
      refetchOnWindowFocus: false
    }
  )
}
