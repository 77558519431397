import { useQueryClient } from '@tanstack/react-query'

import { useCallback, useState } from 'react'

import { showAlert } from 'src/actions/alertsActions'

import useDeleteFileMutation from 'src/hooks/services/provide/mutations/useDeleteFileMutation'
import useDeleteFolderMutation from 'src/hooks/services/provide/mutations/useDeleteFolderMutation'
import { type FileMetadata, type Folder } from 'src/hooks/services/provide/queries/useFilesQuery'
import useAccount from 'src/hooks/utils/useAccount'
import useOwnUser from 'src/hooks/utils/useOwnUser'

import ConfirmationModal from 'src/components/Modals/ConfirmationModal'

import { useDispatch, useSelector } from 'react-redux'
import { type FolderState } from 'src/reducers/folderReducer'

type DeleteItem = { id: string; type: string; name: string }

export const useFileFolderDelete = () => {
  const { id: accountId } = useAccount()
  const queryClient = useQueryClient()
  const [deletingIds, setDeletingIds] = useState<string[]>([])
  const [deleteModalState, setDeleteModalState] = useState<{
    isOpen: boolean
    items: DeleteItem[]
  }>({ isOpen: false, items: [] })

  const search = useSelector((state: FolderState) => state.folder.search)
  const currentFolderId = useSelector((state: FolderState) => state.folder.currentFolderId)

  const updateQueryData = (items: DeleteItem[]) => {
    queryClient.setQueryData<{ [key: string]: (FileMetadata | Folder)[] }>(['getFilesQuery', search || null, currentFolderId || null, accountId], (oldData) => {
      if (!oldData) return oldData
      const newData = { ...oldData }
      items.forEach((item) => {
        const key = item.type === 'file' ? 'files' : 'folders'
        newData[key] = newData[key].filter((i) => i.id !== item.id)
      })
      return newData
    })
  }

  const { mutateAsync: deleteFile } = useDeleteFileMutation()
  const { mutateAsync: deleteFolder } = useDeleteFolderMutation()

  const dispatch = useDispatch()
  const handleDelete = async (items: DeleteItem[]) => {
    const ids = items.map((item) => item.id)
    setDeletingIds((prev) => [...prev, ...ids])

    const batchSize = 5
    const batches = []

    for (let i = 0; i < items.length; i += batchSize) {
      const batch = items.slice(i, i + batchSize)
      batches.push(batch)
    }

    try {
      await Promise.all(
        batches.map(async (batch) => {
          await Promise.all(
            batch.map(async (item) => {
              if (item.type === 'file') {
                await deleteFile({ fileId: item.id })
              } else {
                await deleteFolder({ folderId: item.id })
              }
            })
          )
        })
      )

      updateQueryData(items)

      const itemTypes = new Set(items.map((item) => item.type))
      const message =
        Array.from(itemTypes)
          .map((type) => `${type.charAt(0).toUpperCase() + type.slice(1)}${items.filter((i) => i.type === type).length > 1 ? 's' : ''}`)
          .join(' and ') +
        `(${items.length})` +
        ' deleted successfully'

      dispatch(showAlert({ component: 'FileFolderDelete', message, type: 'success' }))
    } catch {
      dispatch(showAlert({ component: 'FileFolderDelete', message: 'Failed to delete some items', type: 'error' }))
    } finally {
      setDeletingIds((prev) => prev.filter((id) => !ids.includes(id)))
    }
  }

  const openDeleteModal = useCallback((items: DeleteItem[]) => {
    setTimeout(() => setDeleteModalState({ isOpen: true, items }), 10)
  }, [])

  const closeDeleteModal = () => {
    setDeleteModalState({ isOpen: false, items: [] })
  }

  const confirmDelete = async () => {
    if (deleteModalState.items.length > 0) {
      closeDeleteModal()
      await handleDelete(deleteModalState.items)
    }
  }

  const user = useOwnUser()
  const account = useAccount()

  const DeleteConfirmationModal = (
    <ConfirmationModal
      isOpen={deleteModalState.isOpen}
      onClose={closeDeleteModal}
      onConfirm={confirmDelete}
      title={`Delete ${deleteModalState.items.length > 1 ? 'Items' : deleteModalState.items[0]?.type === 'folder' ? 'Folder' : 'File'}`}
    >
      <p>
        Are you sure you want to delete the following {deleteModalState.items.length > 1 ? 'items' : deleteModalState.items[0]?.type}
        {user?.super_admin ? ` from customer ${account.name}` : ''}?
      </p>
      <br />
      <ul className="max-h-40 space-y-1 overflow-y-auto rounded-lg border border-th-border p-1">
        {deleteModalState.items.map((file, index) => (
          <li key={index} className="flex items-center justify-between rounded-md border border-th-border bg-th-content px-2 py-1 text-sm">
            {file.name}
            <span className="whitespace-nowrap text-xs text-th-text-secondary">{file.type}</span>
          </li>
        ))}
      </ul>
      {deleteModalState.items.some((item) => item.type === 'folder') && (
        <div className="mt-4 rounded-lg bg-th-warning p-2 text-center text-sm font-bold text-th-white">
          Deleting folders will also delete all their contents.
        </div>
      )}
    </ConfirmationModal>
  )

  return {
    deletingIds,
    openDeleteModal,
    DeleteConfirmationModal
  }
}
