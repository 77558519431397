import { type UseBaseQueryOptions } from '@tanstack/react-query'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useQuery from 'src/hooks/utils/useQuery'

import { type Moment } from 'moment'

export interface Change {
  id: string
  number: string
  title: string
  description: string
  account: string
  originator: string
  originator_name: string
  requested_by: string
  requested_by_name: string
  assigned: string
  assigned_name: string
  category: string
  contact_type: string
  users_affected: string
  business_impact: string
  classification: string
  comment: string
  starts: string
  ends: string
  updated: string
  created: string
  state: string
  notification: boolean
  customer_approval: string
}

export interface Comment {
  author: string
  comment: string
  created: string | Moment
}

export type ChangeFilterOptions = {
  startDate: Moment
  endDate: Moment
  keyword: string | null
  classification: string[] | null
  category: string[] | null
  state: string[] | null
  orderBy: string | null
  orderByDesc: boolean
  take: number
  skip: number
  requester_page?: string
  period: string
}

export const mapToChangeRequestData = (filterOptions: ChangeFilterOptions): UseChangesRequestData => ({
  start_date: filterOptions.startDate.format('YYYY-MM-DD'),
  end_date: filterOptions.endDate.format('YYYY-MM-DD'),
  keyword: filterOptions.keyword || null,
  classification:
    (filterOptions.classification && filterOptions.classification.length >= 5) || filterOptions.keyword ? null : (filterOptions.classification ?? null),
  category: (filterOptions.category && filterOptions.category.length >= 11) || filterOptions.keyword ? null : (filterOptions.category ?? null),
  state: (filterOptions.state && filterOptions.state.length >= 6) || filterOptions.keyword ? null : (filterOptions.state ?? null),
  order_by: filterOptions.orderBy || null,
  order_by_desc: filterOptions.orderByDesc,
  take: filterOptions.take,
  skip: filterOptions.skip,
  requester_page: filterOptions.requester_page || null
})

export interface UseChangesRequestData {
  category: string[] | null
  classification: string[] | null
  end_date: string
  keyword: string | null
  order_by: string | null
  order_by_desc: boolean | null
  requester_page: string | null
  skip: number
  start_date: string
  state: string[] | null
  take: number
}

type ChangesQueryResult = {
  changes: Change[]
  total_count: number
}

export const fetchChanges = async (accountId: string, filterOptions: ChangeFilterOptions, signal?: AbortSignal) => {
  const requestData = mapToChangeRequestData(filterOptions)
  const response = await api.post(`/servicenow/account/${accountId}/changes`, requestData, {
    signal
  })
  return response.data as ChangesQueryResult
}

export const getChangesQueryKey = (accountId: string, filterOptions: ChangeFilterOptions) => {
  return ['getChangesQuery', accountId, mapToChangeRequestData(filterOptions)] as const
}

const useChangesQuery = (filterOptions: ChangeFilterOptions, options?: UseBaseQueryOptions<ChangesQueryResult>) => {
  const account = useAccount()
  return useQuery(getChangesQueryKey(account.id, filterOptions), async ({ signal }) => fetchChanges(account.id, filterOptions, signal), {
    enabled: !!account.id,
    refetchOnWindowFocus: false,
    ...options
  })
}

export default useChangesQuery
