import { type UseBaseQueryOptions } from '@tanstack/react-query'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useQuery from 'src/hooks/utils/useQuery'

import { type Comment } from './useChangesQuery'

export const fetchChangeComments = async (accountId: string, changeId: string, signal?: AbortSignal) => {
  const response = await api.get(`/servicenow/account/${accountId}/change_request/${changeId}/comments`, {
    signal
  })
  return response.data as Comment[]
}

export const getChangeCommentsQueryKey = (accountId: string, changeId: string) => {
  return ['getChangeCommentsQuery', accountId, changeId] as const
}

const useChangeCommentsQuery = (changeId: string | undefined, options?: UseBaseQueryOptions<Comment[]>) => {
  const account = useAccount()

  return useQuery(getChangeCommentsQueryKey(account.id, changeId ?? ''), async ({ signal }) => fetchChangeComments(account.id, changeId!, signal), {
    enabled: !!account.id && !!changeId,
    refetchOnWindowFocus: false,
    ...options
  })
}

export default useChangeCommentsQuery
