import { useQuery, useQueryClient } from '@tanstack/react-query'

import { useCallback, useEffect, useState } from 'react'

import { type User } from 'src/types/user'

import api from 'src/constants/api'

const useUserData = <T extends any>(key: string, defaultValue?: T) => {
  const queryClient = useQueryClient()
  const [isLoading, setIsLoading] = useState(false)

  const { data: user, refetch } = useQuery({
    queryKey: ['user'],
    queryFn: async ({ signal }) => {
      try {
        const response = await api.get('/provide/user', { signal })
        return response.data as User
      } catch {
        // Ignore internal error
      }
    },
    cacheTime: 60 * 1000 * 5,
    refetchOnWindowFocus: false
  })

  const handleUpdateUserData = useCallback(
    async (data: T) => {
      if (!user) return

      const previousUserData = user

      try {
        setIsLoading(true)

        // Optimistic update
        const updatedUserData = { ...user, user_data: { ...user.user_data, [key]: data } }
        queryClient.setQueryData(['user'], updatedUserData)

        const newUserData = await api.post<User>(`/provide/user/${user.id}/update`, key ? { [key]: data } : data)

        // Confirm update
        queryClient.setQueryData(['user'], { ...user, user_data: newUserData.data })
        await refetch()
      } catch {
        // Ignore internal error - Rollback to previous data
        queryClient.setQueryData(['user'], previousUserData)
      }

      setIsLoading(false)
    },
    [key, queryClient, refetch, user]
  )

  useEffect(() => {
    if (user && defaultValue !== undefined && (user.user_data === undefined || user.user_data[key] === undefined)) {
      handleUpdateUserData(defaultValue)
    }
  }, [user, key, defaultValue, handleUpdateUserData])

  const getData = (): T | null | undefined => {
    if (!user?.user_data) {
      return defaultValue
    }
    if (key) {
      return user.user_data[key] === undefined ? defaultValue : user.user_data[key]
    }
    return user.user_data as T
  }

  return {
    data: getData(),
    update: handleUpdateUserData,
    isLoading: !user || isLoading
  }
}

export default useUserData
