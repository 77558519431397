import type { UseBaseQueryOptions } from '@tanstack/react-query'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useOwnUser from 'src/hooks/utils/useOwnUser'
import useQuery from 'src/hooks/utils/useQuery'

export interface FolderConfig {
  id: string
  accountId: string
  name: string
  parentId: string | null
  path: string
  type: 'Unstructured' | 'Structured' | 'Template'
}

const useFolderConfigQuery = (options?: UseBaseQueryOptions<FolderConfig[]>) => {
  const account = useAccount()
  const user = useOwnUser()

  const query = useQuery(
    ['getFolderConfigsQuery', account.id],
    async ({ signal }) => {
      const response = await api.get(`/provide/${account.id}/folder/getFolderConfigs`, {
        signal
      })

      return response.data
    },
    {
      enabled: !!account.id && !!user?.super_admin,
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
      ...options
    }
  )

  return { ...query, isLoading: query.isLoading && !!account.id && !!user?.super_admin }
}

export default useFolderConfigQuery
