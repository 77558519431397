import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useMutation, { type UseMutationOptions } from 'src/hooks/utils/useMutation'

interface ShareDocumentMutation {
  ResourceIds: string[]
  TargetUserIds: string[]
  ShouldNotify: boolean
}

const useShareDocumentMutation = (options?: UseMutationOptions<ShareDocumentMutation>) => {
  const account = useAccount()

  return useMutation(
    async (data: ShareDocumentMutation) => {
      const response = await api.post(`provide/${account.id}/file/share`, data)
      return response.data
    },
    {
      ...options,
      errorMessage: 'An error occurred while sharing the document.'
    }
  )
}

export default useShareDocumentMutation
