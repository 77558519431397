import * as React from 'react'

import { classNames } from 'src/utils/classNames'

export interface SectionProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Section = React.forwardRef<HTMLTextAreaElement, SectionProps>((props) => {
  const { children } = props

  return (
    <section
      className={classNames(
        '-mt-10 rounded-t-content bg-th-content px-4 pb-20 pt-8 first:-mt-2 last:h-full last:pb-8 even:bg-th-content-secondary xs:px-10 xs:pt-10',
        props.className
      )}
    >
      {children}
    </section>
  )
})

Section.displayName = 'Section'

export default Section
