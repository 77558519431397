import { type ColumnDef } from '@tanstack/react-table'

import React, { useMemo } from 'react'

import { type ScheduledExport } from 'src/hooks/services/microsoft/queries/useScheduledExports'

import Table from 'src/components/Common/Table/Table'

import moment from 'moment'

interface ScheduledExportsTableProps {
  scheduledExports: ScheduledExport[]
  onRowClick: (row: ScheduledExport) => void
}

const ScheduledExportsTable: React.FC<ScheduledExportsTableProps> = ({ scheduledExports, onRowClick }) => {
  const columns = useMemo<ColumnDef<ScheduledExport>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        cell: (info) => <span>{info.getValue<string>() ?? 'N/A'}</span>
      },
      {
        accessorKey: 'description',
        header: 'Description',
        cell: (info) => <span>{info.getValue<string>() ?? 'N/A'}</span>
      },
      {
        accessorKey: 'nextExportDate',
        header: 'Next Export Date',
        cell: (info) => <span>{info.row.original.recurring ? moment(info.getValue() as string).format('YYYY-MM-DD HH:mm') : 'N/A'}</span>
      },
      {
        accessorKey: 'status',
        header: 'Status',
        cell: (info) => <span className="uppercase">{info.getValue<string>()}</span>
      },
      {
        accessorKey: 'recurring',
        header: 'Recurring',
        cell: (info) => <span>{info.getValue() ? 'Yes' : 'No'}</span>
      },
      {
        accessorKey: 'exportFrequency',
        header: 'Frequency',
        cell: (info) => <span className="uppercase">{info.row.original.recurring ? info.getValue<string>() : 'N/A'}</span>
      },
      {
        accessorKey: 'customerUrl',
        header: 'Customer URL'
      }
    ],
    []
  )

  return <Table data={scheduledExports} columns={columns} onRowClick={onRowClick} bordered />
}

export default ScheduledExportsTable
