import type { UseBaseQueryOptions } from '@tanstack/react-query'

import api from '~/constants/api'

import useAccount from '~/hooks/utils/useAccount'
import useQuery from '~/hooks/utils/useQuery'

import type { ChangeCustomerApproval } from './useChangeApprovalsQuery'

export const fetchChangeApproval = async (accountId: string, approvalId: string, signal?: AbortSignal) => {
  const response = await api.get(`/servicenow/account/${accountId}/changeCustomerApprovals/${approvalId}`, { signal })

  return response.data as ChangeCustomerApproval
}

export const getChangeApprovalQueryKey = (accountId: string, approvalId?: string) => {
  return ['getChangeApprovalQuery', accountId, approvalId] as const
}

const useChangeApprovalQuery = (approvalId?: string, options?: UseBaseQueryOptions<ChangeCustomerApproval>) => {
  const account = useAccount()

  return useQuery(getChangeApprovalQueryKey(account.id, approvalId), async ({ signal }) => fetchChangeApproval(account.id, approvalId!, signal), {
    enabled: !!account.id && !!approvalId,
    refetchOnWindowFocus: false,
    ...options
  })
}

export default useChangeApprovalQuery
