import { Fragment, type FunctionComponent, useEffect, useState } from 'react'

import { type Order } from '../../types/order'
import { type Service } from '../../types/service'

import moment from 'moment'
import { Iconly } from 'react-iconly'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { showAlert } from '../../actions/alertsActions'
import { closeDrawers } from '../../actions/drawerActions'
import api from '../../constants/api'
import Button from '../Common/Button'
import Drawer from '../Common/Drawer'
import State from '../Common/State'
import Submit from '../Common/Submit'

const OrderDrawer: FunctionComponent = (): JSX.Element => {
  const account = useSelector((state: RootStateOrAny) => state.account)
  const order: Order = useSelector((state: RootStateOrAny) => state.drawers.order)

  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState<Service[]>([])

  const dispatch = useDispatch()

  useEffect(() => {
    if (order) {
      setLoading(true)

      api
        .post(`/servicenow/account/${account.id}/services`, { order: order.id })
        .then((response) => {
          const services = response.data.sort((a: Service, b: Service) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))

          /* save order items to state */
          setItems(services)
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)

          /* alert the user that the order items couldn't be retrieved */
          dispatch(
            showAlert({
              type: 'error',
              message: "We couldn't retrieve the items for " + order.number + ' at this time. Please try again later.',
              component: 'Order',
              error
            })
          )
        })
    }
  }, [order, account, dispatch])

  /* drawer actions */
  const Actions: FunctionComponent = (): JSX.Element => {
    return (
      <Fragment>
        <Button bordered onClick={() => dispatch(closeDrawers())}>
          Cancel
        </Button>
        <Submit variant="primary" form="updateOrderForm" />
      </Fragment>
    )
  }

  /* drawer content */
  return (
    <Drawer title={order ? order.number : 'Order'} loading={loading} actions={<Actions />}>
      <div className="mb-8 flex justify-between">
        {/* order date */}
        <div>
          <div className="font-bold">Ordered on</div>
          <div className="text-sm">{moment(order.order_date).format('DD/MM/yy')}</div>
        </div>
      </div>

      {/* state and ordered by */}
      <div className="mb-4 flex justify-between">
        <State state={order.state} />

        {/* ordered by */}
        <div className="flex w-max items-center rounded-full bg-th-warning-light p-1 text-sm font-bold text-th-warning">
          <Iconly name="User" set="bold" className="h-5" />
          <span className="px-1">{order.ordered_by || 'Unknown'}</span>
        </div>
      </div>

      <hr className="my-8 border-th-border" />

      {/* linked quote */}
      {order.quote && (
        <div>
          Created from quote <span className="font-bold">{order.quote}</span>.
          <hr className="my-8 border-th-border" />
        </div>
      )}

      {/* services */}
      <div className="flex items-center font-bold">
        <div>Services</div>
      </div>

      {/* service list */}
      <div className="mt-6">
        {items.length > 0 && (
          <ul>
            {items.map((item: Service) => (
              <li key={item.id} className="flex cursor-pointer items-center border-b border-th-border py-2 text-sm last:border-b-0">
                {/* service details */}
                <div className="flex flex-auto space-x-4">
                  <span className="flex-auto">{item.name}</span>
                  <span className="text-2xs">{item.recurrence}</span>
                  <span className="w-10 text-right">£{item.total}</span>
                </div>
              </li>
            ))}
          </ul>
        )}
        {/* no services found */}
        {items.length === 0 && <div>This order doesn't contain any services.</div>}
      </div>
    </Drawer>
  )
}

export default OrderDrawer
