import { useState } from 'react'

import { type ChangeApprovalFilterOptions, fetchChangeApprovals } from '~/hooks/services/servicenow/queries/useChangeApprovalsQuery'
import { useDownloadProgress } from '~/hooks/utils/useDownloadProgress'

import { exportToCSV } from '~/utils/files'

export const useChangeApprovalCSV = (accountId: string) => {
  const { downloadPercentage, startProgress, stopProgress } = useDownloadProgress()
  const [downloadError, setDownloadError] = useState<string | null>(null)

  const handleDownload = async (filterOptions: ChangeApprovalFilterOptions) => {
    setDownloadError(null)
    const interval = startProgress()

    try {
      const { items } = await fetchChangeApprovals(accountId, filterOptions)

      await exportToCSV(items, 'changeApprovals.csv')
    } catch (error) {
      setDownloadError(error instanceof Error ? error.message : 'Unknown error')
    } finally {
      clearInterval(interval)
      stopProgress()
    }
  }

  return {
    downloadPercentage,
    downloadError,
    handleDownload
  }
}
