import { type FunctionComponent, type ReactNode } from 'react'

import { type FormatOptions, format } from 'src/utils/format'

import { Iconly } from 'react-iconly'

import Button from '../../../../components/Common/Button'
import Tile from '../../../../components/Common/Tile'

/* utils */
import { classNames } from '../../../../utils/classNames'

interface Props {
  icon: ReactNode
  value: number | string
  label: string
  percentage?: number
  arrow?: true
  editFunction?: () => unknown
  isLoading?: boolean
  format?: FormatOptions
  unit?: string
}

const SustainabilityTile: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { icon, value, label, percentage, arrow, editFunction, isLoading, unit } = props

  return (
    <Tile className="flex h-full items-center gap-1 px-4 py-6 text-th-text md:px-8 mdlg:px-4 lg:px-8">
      <div className="h-full w-8 flex-none xl:w-10">{icon}</div>
      <div className="flex h-full flex-auto items-center justify-center">
        <div className="gap-1 px-2 lg:gap-2">
          <div className="font-bold text-th-text-secondary">
            {label} <span className="ml-1 text-sm">({unit})</span>
          </div>
          <div className="text-xl font-extrabold tracking-wide xs:text-2xl">
            {isLoading ? <span className="animate-pulse">Loading...</span> : <>{props.format ? format(value, props.format) : value}</>}
          </div>
        </div>
      </div>
      {percentage !== undefined && (
        <div
          className={classNames(
            'flex items-center justify-end gap-1 text-xl font-extrabold',
            'h-full w-20 xl:text-2xl 2xl:w-24 2xl:text-3xl',
            percentage > 0 ? 'text-th-error' : 'text-th-success'
          )}
        >
          {arrow && percentage !== 0 && (percentage > 0 ? <Iconly name="ArrowUp" set="light" /> : <Iconly name="ArrowDown" set="light" />)}
          {Math.abs(percentage)}%
        </div>
      )}

      {editFunction !== undefined && (
        <Button onClick={() => editFunction()}>
          <Iconly name="Edit" set="light" className="h-4" style={{ cursor: 'pointer' }} />
        </Button>
      )}
    </Tile>
  )
}

export default SustainabilityTile
