import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useMutation, { type UseMutationOptions } from 'src/hooks/utils/useMutation'

import { type Folder } from '../queries/useFilesQuery'

const useUpdateFolderMutation = (options?: UseMutationOptions<Partial<Folder>>) => {
  const account = useAccount()

  return useMutation(
    async (folder: Partial<Folder>) => {
      const response = await api.post(`provide/${account.id}/folder/update`, folder)
      return response.data
    },
    {
      ...options,
      errorMessage: 'An error occurred while updating the folder.'
    }
  )
}

export default useUpdateFolderMutation
