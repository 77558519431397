import api from 'src/constants/api'

import useMutation from 'src/hooks/utils/useMutation'

const useCreateOwnRelationshipMutation = () => {
  return useMutation(
    async () => {
      const response = await api.post('/servicenow/relationship')
      return response.data
    },
    {
      errorMessage: 'Failed to create own relationship'
    }
  )
}

export default useCreateOwnRelationshipMutation
