import { type FunctionComponent } from 'react'

import { classNames } from 'src/utils/classNames'

import { useDispatch } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'

import { closeNavigation } from '../../../actions/navigationActions'
import Badge from '../Badge/Badge'

interface Props {
  route: string
  label: string
}

const ChildLink: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { route, label } = props

  const dispatch = useDispatch()
  const location = useLocation()

  return (
    <NavLink
      to={route}
      className={({ isActive }) =>
        classNames(
          'flex items-center text-sm hover:bg-th-border xs:hover:bg-th-navigation-child-active',
          isActive && 'bg-th-border xs:bg-th-navigation-child-active'
        )
      }
      onClick={() => {
        dispatch(closeNavigation())
        if (route !== location.pathname) {
          //dispatch(startLoading())
        }
      }}
    >
      <div className="flex w-10 justify-center xs:w-14">
        <Badge active={false} />
      </div>
      {label}
    </NavLink>
  )
}

export default ChildLink
