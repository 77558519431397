import { useEffect, useState } from 'react'

import { type RootStateOrAny, useSelector } from 'react-redux'

const useTheme = () => {
  const settingsTheme = useSelector((state: RootStateOrAny) => state.settings.theme)
  const [theme, setTheme] = useState(localStorage.getItem('theme') || settingsTheme || 'default')

  useEffect(() => {
    if (theme !== settingsTheme && settingsTheme) {
      setTheme(settingsTheme)
      localStorage.setItem('theme', settingsTheme)
    }
  }, [settingsTheme, theme])

  useEffect(() => {
    document.documentElement.classList.remove('theme-default', 'theme-dark', 'theme-light')
    document.documentElement.classList.add(`theme-${theme}`)
  }, [theme])

  return theme
}

export default useTheme
