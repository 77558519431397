import {
  PiFile,
  PiFileArchive,
  PiFileAudio,
  PiFileCsv,
  PiFileDoc,
  PiFileJpg,
  PiFilePdf,
  PiFilePng,
  PiFilePpt,
  PiFileText,
  PiFileZip,
  PiVideo
} from 'react-icons/pi'

const FileTypesIconMapping = {
  // PDF files
  'application/pdf': <PiFilePdf className="h-8 w-8 shrink-0" />,
  'application/x-pdf': <PiFilePdf className="h-8 w-8 shrink-0" />,

  // Image files
  'image/jpeg': <PiFileJpg className="h-8 w-8 shrink-0" />,
  'image/jpg': <PiFileJpg className="h-8 w-8 shrink-0" />,
  'image/png': <PiFilePng className="h-8 w-8 shrink-0" />,

  // Archive files
  'application/zip': <PiFileZip className="h-8 w-8 shrink-0" />,
  'application/x-zip': <PiFileZip className="h-8 w-8 shrink-0" />,
  'application/x-zip-compressed': <PiFileZip className="h-8 w-8 shrink-0" />,
  'application/x-rar-compressed': <PiFileArchive className="h-8 w-8 shrink-0" />,
  'application/rar': <PiFileArchive className="h-8 w-8 shrink-0" />,

  // Excel files
  'application/vnd.ms-excel': <PiFileCsv className="h-8 w-8 shrink-0" />,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': <PiFileCsv className="h-8 w-8 shrink-0" />,
  'application/x-excel': <PiFileCsv className="h-8 w-8 shrink-0" />,
  'application/excel': <PiFileCsv className="h-8 w-8 shrink-0" />,

  // Word files
  'application/msword': <PiFileDoc className="h-8 w-8 shrink-0" />,
  'application/vnd.ms-word': <PiFileDoc className="h-8 w-8 shrink-0" />,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': <PiFileDoc className="h-8 w-8 shrink-0" />,

  // PowerPoint files
  'application/vnd.ms-powerpoint': <PiFilePpt className="h-8 w-8 shrink-0" />,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': <PiFilePpt className="h-8 w-8 shrink-0" />,
  'application/powerpoint': <PiFilePpt className="h-8 w-8 shrink-0" />,
  'application/x-powerpoint': <PiFilePpt className="h-8 w-8 shrink-0" />,

  // Audio files
  'audio/mpeg': <PiFileAudio className="h-8 w-8 shrink-0" />,
  'audio/mp3': <PiFileAudio className="h-8 w-8 shrink-0" />,
  'audio/wav': <PiFileAudio className="h-8 w-8 shrink-0" />,
  'audio/x-wav': <PiFileAudio className="h-8 w-8 shrink-0" />,

  // Text files
  'text/plain': <PiFileText className="h-8 w-8 shrink-0" />,
  'text/csv': <PiFileCsv className="h-8 w-8 shrink-0" />,

  // Video files
  'video/mp4': <PiVideo className="h-8 w-8 shrink-0" />,
  'video/mpeg': <PiVideo className="h-8 w-8 shrink-0" />,
  'video/x-msvideo': <PiVideo className="h-8 w-8 shrink-0" />,
  'video/quicktime': <PiVideo className="h-8 w-8 shrink-0" />,

  default: <PiFile className="h-8 w-8 shrink-0" />
}

interface FileTypeIconProps {
  contentType: string
  className?: string
}

const FileTypeIcon: React.FC<FileTypeIconProps> = ({ contentType, className }) => {
  const IconComponent = FileTypesIconMapping[contentType as keyof typeof FileTypesIconMapping] || FileTypesIconMapping.default
  return <div className={className}>{IconComponent}</div>
}

export default FileTypeIcon
