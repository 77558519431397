import { useMutation } from '@tanstack/react-query'

import { type Attachment } from 'src/types/attachment'

import { showAlert } from 'src/actions/alertsActions'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'

import { downloadFile } from 'src/utils/files'

import { useDispatch } from 'react-redux'

interface DownloadAttachmentParams {
  changeId: string
  attachment: Attachment
}

export const useDownloadAttachmentMutation = () => {
  const account = useAccount()
  const dispatch = useDispatch()

  return useMutation({
    mutationFn: async ({ changeId, attachment }: DownloadAttachmentParams) => {
      const response = await api.get(`/servicenow/account/${account.id}/change_request/${changeId}/attachment/${attachment.id}`, {
        responseType: 'blob'
      })
      return { data: response.data, attachment }
    },
    onSuccess: ({ data, attachment }) => {
      const blob = new Blob([data])
      downloadFile(blob, attachment.name)
    },
    onError: (_error, { attachment }) => {
      dispatch(
        showAlert({
          type: 'error',
          message: `We couldn't download ${attachment.name} at this time. Please try again later.`,
          component: 'Change'
        })
      )
    }
  })
}
