import { type FunctionComponent, useState } from 'react'

import { type Tenant } from '../../../../types/azure'
import { BillingCycleType, type CartLineItem } from '../../../../types/cart'
import { type Availability as AvailabilityType, type Term as TermType } from '../../../../types/microsoft365'

import axios, { type AxiosResponse } from 'axios'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { showAlert } from '../../../../actions/alertsActions'
import { startLoading, stopLoading } from '../../../../actions/loadingActions'
import Button from '../../../../components/Common/Button'
import Input from '../../../../components/Common/Input'
import api from '../../../../constants/api'
import Term from './Term/Term'

interface Props {
  tenant: Tenant
  availability: AvailabilityType
}

const Availability: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { tenant, availability } = props

  const [term, setTerm] = useState<TermType>()
  const [quantity, setQuantity] = useState<number>(1)

  const dispatch = useDispatch()
  const account = useSelector((state: RootStateOrAny) => state.account)

  /* create a cart */
  const createCart = (cartLineItem: CartLineItem) => {
    api
      .post(`/ms-api/orders/account/${account.id}/${tenant.id}/cart`, {
        LineItems: [cartLineItem]
      })
      .then((response: AxiosResponse) => {
        localStorage.setItem(`cart-${tenant.id}`, response.data.Id)
        dispatch(
          showAlert({
            type: 'success',
            message: 'Item successfully added to your cart.',
            component: 'Availability'
          })
        )
        dispatch(stopLoading())
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(
            showAlert({
              type: 'error',
              message: error.response?.data,
              component: 'Availability',
              error
            })
          )
          dispatch(stopLoading())
        }
      })
  }

  /* add an item to the shopping cart */
  const addToCart = (id: string) => {
    dispatch(startLoading())

    if (term) {
      const cartLineItem: CartLineItem = {
        CatalogItemId: id,
        Quantity: quantity,
        BillingCycle: BillingCycleType[term.billingCycle as keyof typeof BillingCycleType],
        TermDuration: term.duration
      }

      /* check if a cart has already been created */
      const cartId = localStorage.getItem(`cart-${tenant.id}`)

      if (cartId) {
        /* update this cart instead of creating a new one */
        api
          .put(`/ms-api/orders/account/${account.id}/${tenant.id}/cart/${cartId}/add`, cartLineItem)
          .then(() => {
            dispatch(
              showAlert({
                type: 'success',
                message: 'Item successfully added to your cart.',
                component: 'Availability'
              })
            )
            dispatch(stopLoading())
          })
          .catch((error) => {
            if (!axios.isCancel(error)) {
              /* remove the stored cart id if the cart no longer exists and create a new cart */
              if (error.response?.status === 404 || error.response?.status === 400) {
                localStorage.removeItem(`cart-${tenant.id}`)
                createCart(cartLineItem)
              } else {
                dispatch(
                  showAlert({
                    type: 'error',
                    message: error.response?.data,
                    component: 'Availability',
                    error
                  })
                )

                dispatch(stopLoading())
              }
            }
          })
      } else {
        /* create a new cart if there wasn't a stored cart id */
        createCart(cartLineItem)
      }
    } else {
      dispatch(
        showAlert({
          type: 'error',
          message: 'You must select a term before the item can be added to your cart.',
          component: 'Availability'
        })
      )
      dispatch(stopLoading())
    }
  }

  return (
    <div className="flex flex-col gap-2 rounded-lg">
      <div className="grid grid-cols-3 gap-4">
        {availability.terms.map((t: TermType) => {
          return <Term term={t} onClick={() => setTerm(t)} selected={t.id === term?.id} />
        })}
      </div>
      <div className="mt-4 flex items-center justify-between">
        <div className="flex h-7 w-max items-center rounded-full bg-th-info-light px-3 font-bold text-th-primary">{availability.segment}</div>
        <div className="flex justify-end gap-4">
          <Input
            type="number"
            name="quantity"
            placeholder="Quantity"
            min={1}
            step={1}
            onChange={(e) => setQuantity(parseInt(e.target.value))}
            value={quantity}
          />
          <Button variant="primary" onClick={() => addToCart(availability.catalogItemId)} className="w-max" disabled={!term || quantity < 1 || isNaN(quantity)}>
            Add to Cart
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Availability
