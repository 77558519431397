import { Fragment, type FunctionComponent } from 'react'

import { type RootStateOrAny, useSelector } from 'react-redux'

import Tile from '../../../components/Common/Tile'

const AzureQuickLinks: FunctionComponent = (): JSX.Element => {
  const tenant = useSelector((state: RootStateOrAny) => state.tenant)
  const entitlement = useSelector((state: RootStateOrAny) => state.entitlement)
  const tenants = useSelector((state: RootStateOrAny) => state.tenants)
  const entitlements = useSelector((state: RootStateOrAny) => state.entitlements)
  return (
    <Fragment>
      <div className="my-5 grid grid-cols-1 items-center gap-4 sm:grid-cols-2 md:grid-cols-1 mdlg:grid-cols-2 xl:grid-cols-5">
        <Tile
          onClick={() => {
            window.open('https://portal.azure.com/#home', '_blank')
          }}
          className="flex h-full cursor-pointer items-center justify-center gap-2 overflow-hidden rounded-2xl border border-th-border bg-th-content p-4 px-4 py-6 text-th-text md:px-8 mdlg:px-4 lg:px-8"
        >
          <a className="text-sm font-bold text-th-text-secondary" href="https://portal.azure.com/#home" target="_blank" rel="noreferrer">
            {' '}
            Azure Portal{' '}
          </a>
        </Tile>
        <Tile
          onClick={() => {
            window.open('https://portal.azure.com/#blade/Microsoft_Azure_Health/AzureHealthBrowseBlade/serviceIssues', '_blank')
          }}
          className="flex h-full cursor-pointer items-center justify-center gap-2 overflow-hidden rounded-2xl border border-th-border bg-th-content p-4 px-4 py-6 text-th-text md:px-8 mdlg:px-4 lg:px-8"
        >
          <a
            className="text-sm font-bold text-th-text-secondary"
            href="https://portal.azure.com/#blade/Microsoft_Azure_Health/AzureHealthBrowseBlade/serviceIssues"
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            Azure Health{' '}
          </a>
        </Tile>
        <Tile
          onClick={() => {
            window.open('https://portal.azure.com/#blade/Microsoft_Azure_Monitoring/AzureMonitoringBrowseBlade/more', '_blank')
          }}
          className="flex h-full cursor-pointer items-center justify-center gap-2 overflow-hidden rounded-2xl border border-th-border bg-th-content p-4 px-4 py-6 text-th-text md:px-8 mdlg:px-4 lg:px-8"
        >
          <a
            className="text-sm font-bold text-th-text-secondary"
            href="https://portal.azure.com/#blade/Microsoft_Azure_Monitoring/AzureMonitoringBrowseBlade/more"
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            Azure Monitor{' '}
          </a>
        </Tile>
        <Tile
          onClick={() => {
            window.open('https://portal.azure.com/#blade/Microsoft_Azure_Policy/PolicyMenuBlade/Compliance', '_blank')
          }}
          className="flex h-full cursor-pointer items-center justify-center gap-2 overflow-hidden rounded-2xl border border-th-border bg-th-content p-4 px-4 py-6 text-th-text md:px-8 mdlg:px-4 lg:px-8"
        >
          <a
            className="text-sm font-bold text-th-text-secondary"
            href="https://portal.azure.com/#blade/Microsoft_Azure_Policy/PolicyMenuBlade/Compliance"
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            Policy & Compliance{' '}
          </a>
        </Tile>
        <Tile
          onClick={() => {
            window.open(
              'https://portal.azure.com/#@' +
                (tenant ? tenant?.id : tenants[0]?.id) +
                '/resource/subscriptions/' +
                (entitlement ? entitlement?.subscription_id : entitlements[0]?.id) +
                '/users',
              '_blank'
            )
          }}
          className="flex h-full cursor-pointer items-center justify-center gap-2 overflow-hidden rounded-2xl border border-th-border bg-th-content p-4 px-4 py-6 text-th-text md:px-8 mdlg:px-4 lg:px-8"
        >
          <a
            className="text-sm font-bold text-th-text-secondary"
            href={
              'https://portal.azure.com/#@' +
              (tenant ? tenant?.id : tenants[0]?.id) +
              '/resource/subscriptions/' +
              (entitlement ? entitlement?.subscription_id : entitlements[0]?.id) +
              '/users'
            }
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            Role Based Access Control{' '}
          </a>
        </Tile>
      </div>
    </Fragment>
  )
}

export default AzureQuickLinks
