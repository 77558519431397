import { useEffect, useState } from 'react'

import DateRange from 'src/components/Common/DateRange/DateRange'
import Dropdown from 'src/components/Common/Dropdown'

import moment, { type Moment } from 'moment'

const MONTH_PERIOD_OPTIONS = [
  {
    label: 'Current Month',
    value: 'current',
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month')
  },
  {
    label: 'Previous Month',
    value: 'previous',
    startDate: moment().subtract(1, 'month').startOf('month'),
    endDate: moment().subtract(1, 'month').endOf('month')
  },
  {
    label: 'Custom',
    value: 'custom',
    startDate: moment().startOf('year'),
    endDate: moment()
  }
]

const FULL_PERIOD_OPTIONS = [
  {
    label: 'Year To Date',
    value: 'year-to-date',
    startDate: moment().startOf('year'),
    endDate: moment()
  },
  {
    label: 'Current Year',
    value: 'current-year',
    startDate: moment().startOf('year'),
    endDate: moment().endOf('year')
  },
  {
    label: 'Previous Year',
    value: 'previous-year',
    startDate: moment().subtract(1, 'year').startOf('year'),
    endDate: moment().subtract(1, 'year').endOf('year')
  },
  {
    label: 'Month To Date',
    value: 'month-to-date',
    startDate: moment().startOf('month'),
    endDate: moment()
  },
  {
    label: 'Current Month',
    value: 'current-month',
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month')
  },
  {
    label: 'Previous Month',
    value: 'previous-month',
    startDate: moment().subtract(1, 'month').startOf('month'),
    endDate: moment().subtract(1, 'month').endOf('month')
  },
  {
    label: 'Custom',
    value: 'custom',
    startDate: moment().startOf('year'),
    endDate: moment()
  }
]

const MONTH_PRESET_DEFAULT = [moment().startOf('month'), moment().endOf('month')] as [Moment, Moment]
const FULL_PRESET_DEFAULT = [moment().startOf('year'), moment()] as [Moment, Moment]

type UseDatePickerConfig = {
  preset?: 'month' | 'full'

  defaultValue?: [Moment, Moment]

  value?: [Moment, Moment]
  onDateRangeChange?: (dateRange: [Moment, Moment]) => void

  periodOverride?: Array<{ label: string; value: string; startDate?: moment.Moment; endDate?: moment.Moment }>

  minDate?: Moment
}

const useDateRangePicker = (config?: UseDatePickerConfig) => {
  const DEFAULT_PERIOD_OPTIONS = config?.preset === 'full' ? FULL_PERIOD_OPTIONS : MONTH_PERIOD_OPTIONS
  const DEFAULT_PRESET = config?.preset === 'full' ? FULL_PRESET_DEFAULT : MONTH_PRESET_DEFAULT

  const [dateRange, setDateRange] = useState<[Moment, Moment]>(config?.defaultValue ?? DEFAULT_PRESET)
  const [period, setPeriod] = useState<string>(config?.periodOverride?.at(0)?.value ?? 'current')

  const handlePeriodChange = (option: Record<string, string>) => {
    setPeriod(option.value)

    const startDate = moment(option.startDate || -1, true)
    const endDate = moment(option.endDate || -1, true)
    if (startDate.isValid() && endDate.isValid()) setDateRange([startDate, endDate])
    else setDateRange(DEFAULT_PRESET)
  }

  useEffect(() => config?.onDateRangeChange?.(dateRange), [config, dateRange])

  const PeriodDropdown = (
    <Dropdown
      options={config?.periodOverride ?? DEFAULT_PERIOD_OPTIONS}
      value={
        config?.periodOverride?.find((o) => period.includes(o.value)) ??
        DEFAULT_PERIOD_OPTIONS.find((o) => period.includes(o.value)) ??
        DEFAULT_PERIOD_OPTIONS[0]
      }
      variant={Dropdown.variant.DEFAULT}
      label="Period"
      bordered
      multi={false}
      onChange={handlePeriodChange}
    />
  )

  const DateRangePicker = (
    <DateRange
      start={dateRange[0]}
      end={dateRange[1]}
      startDateId="start_date"
      endDateId="end_date"
      variant={DateRange.variant.DEFAULT}
      bordered
      onChange={(startDate, endDate) => (startDate.year() === 1970 || endDate.year() === 1970 ? null : setDateRange([startDate, endDate]))}
      disabled={!period.includes('custom')}
      focus={period === 'custom'}
      minDate={config?.minDate}
    />
  )

  const handleSetDateRange = (dateRange: [Moment, Moment]) => {
    const matchingPeriod = (config?.periodOverride ?? DEFAULT_PERIOD_OPTIONS).find(
      (o) => o.startDate?.isSame(dateRange[0], 'day') && o.endDate?.isSame(dateRange[1], 'day')
    )

    if (matchingPeriod) {
      setPeriod(matchingPeriod.value)
    } else {
      setPeriod('preset-custom')
      if (config?.minDate) {
        setDateRange([dateRange[0].isBefore(config.minDate) ? config.minDate : dateRange[0], dateRange[1]])
      }
    }

    setDateRange(dateRange)
  }

  const queryKey = [dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD')]

  return {
    PeriodDropdown,
    DateRangePicker,
    dateRange,
    queryKey,
    setDateRange: handleSetDateRange
  }
}

export default useDateRangePicker
