import { Shimmer, ShimmerElementType, ShimmerElementsGroup, createTheme } from '@fluentui/react'

import { Fragment, type FunctionComponent } from 'react'

import { type Promotion as PromotionType } from '../../../../types/microsoft365'

import moment from 'moment'
import { IoReloadSharp } from 'react-icons/io5'

import Pin from '../../../../components/Common/Pin'
import Tile from '../../../../components/Common/Tile'

interface Props {
  promotion: PromotionType
}

/* promotion loading shimmer */
const shimmerElements = (): JSX.Element => {
  return (
    <div style={{ display: 'flex' }}>
      {/* each shimmer elements container matches the height of the tallest container as they are wrapped.
        This means the gaps between wrapped shimmer elements is determnined by setting the height value
        relative to the height of the tallest element */}
      <ShimmerElementsGroup
        flexWrap
        width="100%"
        shimmerElements={[
          /* title */
          { type: ShimmerElementType.line, width: '100%', height: 40 },
          /* id */
          { type: ShimmerElementType.line, width: '50%', height: 20 },
          { type: ShimmerElementType.gap, width: '50%', height: 20 },
          /* description */
          { type: ShimmerElementType.line, width: '100%', height: 40 },
          { type: ShimmerElementType.line, width: '100%', height: 40 },
          { type: ShimmerElementType.line, width: '100%', height: 40 },
          { type: ShimmerElementType.line, width: '100%', height: 40 },
          { type: ShimmerElementType.line, width: '100%', height: 40 },
          /* promotion */
          { type: ShimmerElementType.line, width: '50%', height: 20 },
          { type: ShimmerElementType.gap, width: '50%', height: 20 }
        ]}
        backgroundColor="var(--content)"
      />
    </div>
  )
}

const Promotion: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { promotion } = props

  const termDurations = {
    P1M: '1 Month',
    P1Y: '1 Year',
    P3Y: '3 Years'
  }

  /* shimmer theme */
  const customThemeForShimmer = createTheme({
    palette: {
      themeSecondary: 'var(--translucent)' /* shimmer wave */,
      themeTertiary: 'var(--lowlight)' /* shimmer background */
    }
  })

  return (
    <Fragment>
      <Tile className="text-th-text">
        <Shimmer
          customElementsGroup={shimmerElements()}
          shimmerColors={{
            shimmer: customThemeForShimmer.palette.themeTertiary,
            shimmerWave: customThemeForShimmer.palette.themeSecondary
          }}
          isDataLoaded={promotion !== undefined}
          className="relative h-full"
        >
          {promotion && (
            <div className="flex flex-col gap-1">
              <div className="flex justify-between gap-2">
                {/* promotion title */}
                <div className="line-clamp-2 h-14 text-lg font-bold">{promotion.name}</div>
                {/* auto applied */}
                {promotion.properties.isAutoApplicable && (
                  <Pin icon={<IoReloadSharp className="h-5 w-5" />} popoverText="This promotion will be auto-applied on purchase." />
                )}
              </div>
              {/* promotion id */}
              <div className="text-sm text-th-text-secondary">{promotion.id}</div>
              {/* description */}
              <div className="mt-4 line-clamp-2 italic">{promotion.description}</div>
              {/* available until */}
              <div className="mt-4 flex justify-between">
                <div className="font-bold">Available Until</div>
                <div>{moment(promotion.endDate).format('DD/MM/yyyy')}</div>
              </div>
              {/* term duration and billing cycle */}
              <div className="mt-4 flex justify-between text-sm">
                <div>
                  {/* term duration */}
                  <div className="font-bold">Term Duration</div>
                  {termDurations[promotion.requiredProducts[0].term.duration as keyof typeof termDurations]}
                </div>
                <div>
                  {/* billing cycle */}
                  <div className="font-bold">Billing Cycle</div>
                  {promotion.requiredProducts[0].term.billingCycle}
                </div>
              </div>
              {/* discount */}
              <div className="mt-4 flex gap-4 text-sm">
                <div className="font-bold">Percent Discount</div>
                {(parseFloat(promotion.requiredProducts[0].pricingPolicies[0].value) * 100).toFixed(2)}%
              </div>
            </div>
          )}
        </Shimmer>
      </Tile>
    </Fragment>
  )
}

export default Promotion
