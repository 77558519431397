import { type UseBaseQueryOptions } from '@tanstack/react-query'

import api from '~/constants/api'

import useAccount from '~/hooks/utils/useAccount'
import useQuery from '~/hooks/utils/useQuery'

import type { Moment } from 'moment'

export interface ChangeCustomerApproval {
  id: string
  approver: string
  approver_name: string
  state: string
  approved: string
  rejected: string
  memo: string
  change: string
  number: string
  due_date: string
  created: string
}

export interface ChangeApprovalFilterOptions {
  period: string
  startDate: Moment
  endDate: Moment
  keyword: string | null
  state: string | null
  orderBy: string | null
  orderByDesc: boolean
  take: number
  skip: number
  showOwnApprovals: boolean
}

interface UseChangeApprovalsRequestData {
  startDate: string
  endDate: string
  keyword: string | null
  state: string | null
  orderBy: string | null
  orderByDesc: boolean
  take: number
  skip: number
  showOwnApprovals: boolean
}

export interface ChangeApprovalsResponse {
  items: ChangeCustomerApproval[]
  total_count: number
}

export const mapToChangeApprovalsRequestData = (filterOptions: ChangeApprovalFilterOptions): UseChangeApprovalsRequestData => ({
  startDate: filterOptions.startDate.format('YYYY-MM-DD'),
  endDate: filterOptions.endDate.format('YYYY-MM-DD'),
  keyword: filterOptions.keyword || null,
  state: filterOptions.state || null,
  orderBy: filterOptions.orderBy || null,
  orderByDesc: filterOptions.orderByDesc,
  take: filterOptions.take,
  skip: filterOptions.skip,
  showOwnApprovals: filterOptions.showOwnApprovals
})

export const fetchChangeApprovals = async (accountId: string, filterOptions: ChangeApprovalFilterOptions, signal?: AbortSignal) => {
  const requestData = mapToChangeApprovalsRequestData(filterOptions)
  const response = await api.post(`/servicenow/account/${accountId}/changeCustomerApprovals`, requestData, { signal })
  return response.data as ChangeApprovalsResponse
}

export const getChangeApprovalsQueryKey = (accountId: string, filterOptions: ChangeApprovalFilterOptions) => {
  return ['getChangeApprovalsQuery', accountId, mapToChangeApprovalsRequestData(filterOptions)] as const
}

const useChangeApprovalsQuery = (filterOptions: ChangeApprovalFilterOptions, options?: UseBaseQueryOptions<ChangeApprovalsResponse>) => {
  const account = useAccount()

  return useQuery(getChangeApprovalsQueryKey(account.id, filterOptions), async ({ signal }) => fetchChangeApprovals(account.id, filterOptions, signal), {
    enabled: !!account.id,
    refetchOnWindowFocus: false,
    ...options
  })
}

export default useChangeApprovalsQuery
