import { showAlert } from 'src/actions/alertsActions'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useMutation from 'src/hooks/utils/useMutation'

import type { AxiosError } from 'axios'
import { useDispatch } from 'react-redux'

interface UpdateContactInput {
  first_name: string
  last_name: string
  phone?: string | undefined
  mobile?: string | undefined
  account: any
  email: string
}

const useUpdateContactMutation = () => {
  const account = useAccount()
  const dispatch = useDispatch()

  return useMutation(
    async ({ contact }: { contact: UpdateContactInput }) => {
      try {
        await api.post(`/servicenow/account/${account.id}/contact/update`, contact)

        dispatch(
          showAlert({
            type: 'success',
            message: `Your contact details have been updated.`,
            component: 'ProfileDrawer'
          })
        )

        return true
      } catch (error) {
        dispatch(
          showAlert({
            type: 'error',
            message: 'We were unable to update your contact details. Please try again.',
            component: 'ProfileDrawer',
            error: error as AxiosError
          })
        )

        return false
      }
    },
    {
      errorMessage: 'We were unable to update your contact details. Please try again.'
    }
  )
}

export default useUpdateContactMutation
