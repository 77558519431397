import useChangeAttachmentsQuery from '~/hooks/services/servicenow/queries/useChangeAttachmentsQuery'
import useChangeQuery from '~/hooks/services/servicenow/queries/useChangeQuery'

import { Button } from '~/components/Common/Button'
import Spinner from '~/components/Common/Spinner'

import { formatFileSize } from '~/utils/files'

import { Iconly } from 'react-iconly'
import { IoClose } from 'react-icons/io5'
import { useParams } from 'react-router-dom'

import { useAttachments } from './useAttachments'

const Attachments = () => {
  const { id } = useParams()

  const { data: attachments } = useChangeAttachmentsQuery(id)
  const { data: change } = useChangeQuery(id)

  const { hiddenFileInput, isUploading, handleClick, handleFileChange, handleDownload, handleDelete, actionedIds } = useAttachments(id!)

  return (
    <>
      <div className="flex items-center justify-between">
        <div>Attachments</div>
        {change?.state !== 'Closed' && (
          <Button onClick={handleClick} variant="default" className="gap-1" disabled={isUploading} bordered>
            {isUploading ? <Spinner className="mr-2 h-4 w-4 animate-spin" /> : <Iconly name="PaperUpload" set="light" className="h-5" />}
            <span>Upload</span>
          </Button>
        )}
      </div>

      <input type="file" ref={hiddenFileInput} onChange={handleFileChange} className="hidden" />

      <div className="mt-6">
        <ul>
          {attachments?.map((attachment) => (
            <li key={attachment.id} className="flex items-center border-b border-th-border py-2 text-sm last:border-b-0">
              <div
                className="group flex flex-auto cursor-pointer justify-between aria-disabled:pointer-events-none aria-disabled:select-none aria-disabled:opacity-50"
                onClick={() => handleDownload(attachment)}
                aria-disabled={actionedIds.includes(attachment.id)}
              >
                <span className="group-hover:underline">{attachment.name}</span>
                <span>{formatFileSize(attachment.size)}</span>
              </div>
              <IoClose
                className="ml-3 h-5 w-5 cursor-pointer text-th-error aria-disabled:pointer-events-none aria-disabled:select-none aria-disabled:opacity-50"
                onClick={() => handleDelete(attachment.id)}
                aria-disabled={actionedIds.includes(attachment.id)}
              />
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

export default Attachments
