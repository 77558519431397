import { useCallback } from 'react'

import moment from 'moment'
import { useDebouncedCallback } from 'use-debounce'

import { useChangeFiltersContext } from '../context/ChangeFilter/useContext'

export const useChangeFilters = () => {
  const { filterOptions, setFilterOptions } = useChangeFiltersContext()

  const handleDatesChange = useCallback(
    (startDate: moment.Moment, endDate: moment.Moment) => {
      setFilterOptions((prev) => ({
        ...prev,
        startDate,
        endDate
      }))
    },
    [setFilterOptions]
  )

  const handlePeriodChange = useCallback(
    (newPeriod: string) => {
      const firstDayOfThisMonth = moment().startOf('month')
      const lastDayOfThisMonth = moment().endOf('month')
      const startOfAllTime = moment('1970-01-01').startOf('day')

      switch (newPeriod) {
        case 'all':
          handleDatesChange(startOfAllTime, lastDayOfThisMonth)
          break
        case 'current':
          handleDatesChange(firstDayOfThisMonth, lastDayOfThisMonth)
          break
        case 'previous':
          handleDatesChange(firstDayOfThisMonth.subtract(1, 'month'), lastDayOfThisMonth.subtract(1, 'month'))
          break
      }

      setFilterOptions((prev) => ({
        ...prev,
        period: newPeriod
      }))
    },
    [handleDatesChange, setFilterOptions]
  )

  const handleSearchChange = useDebouncedCallback((searchTerm: string) => {
    setFilterOptions((prev) => ({
      ...prev,
      keyword: searchTerm,
      state: null
    }))
  }, 500)

  const handleClearSearch = useCallback(() => {
    setFilterOptions((prev) => ({
      ...prev,
      keyword: null,
      state: ['Planned']
    }))
  }, [setFilterOptions])

  const handleStateFilter = useCallback(
    (state: string | string[]) => {
      setFilterOptions((prev) => ({
        ...prev,
        state: Array.isArray(state) ? state : filterOptions.state?.includes(state) ? null : [state]
      }))
    },
    [filterOptions.state, setFilterOptions]
  )

  const handleClassificationFilter = useCallback(
    (classifications: string[]) => {
      setFilterOptions((prev) => ({
        ...prev,
        classification: classifications.length ? classifications : null
      }))
    },
    [setFilterOptions]
  )

  const handleCategoryFilter = useCallback(
    (categories: string[]) => {
      setFilterOptions((prev) => ({
        ...prev,
        category: categories.length ? categories : null
      }))
    },
    [setFilterOptions]
  )

  const handlePageChange = useCallback(
    (newPage: number) => {
      setFilterOptions((prev) => ({
        ...prev,
        skip: newPage * prev.take
      }))
    },
    [setFilterOptions]
  )

  const handlePageSizeChange = useCallback(
    (newPageSize: number) => {
      setFilterOptions((prev) => ({
        ...prev,
        take: newPageSize
      }))
    },
    [setFilterOptions]
  )

  const handleSortChange = useCallback(
    (orderBy: string | null, orderByDesc: boolean) => {
      setFilterOptions((prev) => ({
        ...prev,
        orderBy,
        orderByDesc
      }))
    },
    [setFilterOptions]
  )

  return {
    filterOptions,
    handlePeriodChange,
    handleDatesChange,
    handleSearchChange,
    handleClearSearch,
    handleStateFilter,
    handleClassificationFilter,
    handleCategoryFilter,
    handlePageChange,
    handlePageSizeChange,
    handleSortChange
  }
}
