import { useEffect } from 'react'

import SmoothScrollbar from 'smooth-scrollbar'

const useSmoothScrollbar = () => {
  useEffect(() => {
    const scrollbar = document.getElementsByClassName('scrollbar')[0] as HTMLElement
    if (scrollbar) {
      const instance = SmoothScrollbar.init(scrollbar)
      instance.addListener((status) => {
        Array.from(document.getElementsByClassName('fixed')).forEach(
          (element) => ((element as HTMLElement).style.transform = `translate3d(${status.offset.x}px, ${status.offset.y}px, 0px)`)
        )
      })
    }
  }, [])
}

export default useSmoothScrollbar
