import { useMemo } from 'react'

import moment from 'moment'

import useReservedInstancesQuery from '../../services/servicenow/queries/useReservedInstancesQuery'
import { useTenantsQuery } from '../../services/servicenow/queries/useTenantsQuery'

interface useReservedInstancesTotalConfig {
  dateRange: [moment.Moment, moment.Moment]
}

const useReservedInstancesTotal = ({ dateRange }: useReservedInstancesTotalConfig) => {
  const { data: tenants } = useTenantsQuery({ active: true })
  const { data: reservedInstances } = useReservedInstancesQuery({ tenants })

  const reservedInstancesTotal = useMemo(() => {
    const active = reservedInstances.filter((ri) => ri.state === 'Active')
    const charges = active.flatMap((ri) => ri.charges ?? []).filter((c) => moment(c.date).isBetween(dateRange[0], dateRange[1]))
    const total = charges.reduce((acc, curr) => acc + curr.cost, 0)
    return total
  }, [dateRange, reservedInstances])

  return reservedInstancesTotal
}

export default useReservedInstancesTotal
