import { withAITracking } from '@microsoft/applicationinsights-react-js'

import { Fragment, type FunctionComponent, useEffect, useState } from 'react'

import { type AzureBudget, type Entitlement, type MeterUsage, type Tenant } from '../../../types/azure'

import axios, { type AxiosPromise, type AxiosResponse } from 'axios'
import moment, { type Moment } from 'moment'
import { FcBarChart, FcBullish, FcMoneyTransfer } from 'react-icons/fc'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { type OptionTypeBase } from 'react-select'

import { showAlert } from '../../../actions/alertsActions'
import { startLoading, stopLoading } from '../../../actions/loadingActions'
import Banner from '../../../components/Common/Banner'
import DateRange from '../../../components/Common/DateRange/DateRange'
import Dropdown from '../../../components/Common/Dropdown'
import Section from '../../../components/Common/Section'
import Spacer from '../../../components/Common/Spacer'
import { reactPlugin } from '../../../configs/appInsights'
import api from '../../../constants/api'
import AzureTile from '../AzureOverview/AzureTile/AzureTile'
import Chart from './Chart/Chart'

/* dropdown options */
const periodOptions = [
  {
    label: 'Current Month',
    value: 'current'
  },
  {
    label: 'Previous Month',
    value: 'previous'
  },
  {
    label: 'Custom',
    value: 'custom'
  },
  {
    label: 'Custom',
    value: 'hiddenOption'
  }
]

const SubscriptionUsage: FunctionComponent = (): JSX.Element => {
  /* dates */
  const [startDate, setStartDate] = useState<Moment>(moment().startOf('month'))
  const [endDate, setEndDate] = useState<Moment>(moment().endOf('month'))
  const [period, setPeriod] = useState<string>(periodOptions[0].value)

  /* tenants */
  const [tenants, setTenants] = useState<Tenant[]>([])
  const [tenantOptions, setTenantOptions] = useState<OptionTypeBase[]>([])
  const [selectedTenants, setSelectedTenants] = useState<string[]>()

  /* subscriptions */
  const [subscriptions, setSubscriptions] = useState<Entitlement[]>([])
  const [subscriptionOptions, setSubscriptionOptions] = useState<OptionTypeBase[]>([])
  const [selectedSubscriptions, setSelectedSubscriptions] = useState<string[]>([])

  /* tiles */
  const [total, setTotal] = useState<number>(0)
  const [forecast, setForecast] = useState<number>(0)

  /* usage */
  const [meterUsage, setMeterUsage] = useState<MeterUsage[]>([])
  const [filteredUsage, setFilteredUsage] = useState<MeterUsage[]>([])

  /* budgets */
  const [budgets, setBudgets] = useState<Record<string, AzureBudget>>()
  const [budget, setBudget] = useState<number>(0)

  const dispatch = useDispatch()
  const account = useSelector((state: RootStateOrAny) => state.account)

  /* set the date range based on the period that has been selected */
  const handlePeriodChange = (period: string) => {
    setPeriod(period)

    const firstDayOfThisMonth = moment().startOf('month')
    const lastDayOfThisMonth = moment().endOf('month').set({ hour: 1, minute: 0 })

    if (period === 'current') {
      /* set the dates to span the current month */
      setStartDate(firstDayOfThisMonth.startOf('month'))
      setEndDate(lastDayOfThisMonth.endOf('month').set({ hour: 1, minute: 0 }))
    } else if (period === 'previous') {
      /* set the dates to span the previous month */
      setStartDate(moment(firstDayOfThisMonth.subtract(1, 'months').startOf('month')))
      setEndDate(moment(lastDayOfThisMonth.subtract(1, 'months').endOf('month').set({ hour: 1, minute: 0 })))
    }
  }

  /* set the start and end dates */
  const handleDatesChange = (startDate: Moment, endDate: Moment) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  /* get the current accounts tenants */
  useEffect(() => {
    const source = axios.CancelToken.source()

    dispatch(startLoading())

    /* reset all the data */
    setTotal(0)
    setForecast(0)

    if (account.id) {
      api
        .get(`/servicenow/account/${account.id}/tenants`, { cancelToken: source.token })
        .then((response) => {
          if (response.data) {
            setTenants(response.data)
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            dispatch(stopLoading())
            dispatch(
              showAlert({
                type: 'error',
                message: 'We were unable to retrieve your Azure tenants.',
                component: 'AzureOverview',
                error
              })
            )
          }
        })
    }

    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  /* get the accounts subscriptions (entitlements) */
  useEffect(() => {
    const source = axios.CancelToken.source()

    if (account.id) {
      api
        .get(`/servicenow/account/${account.id}/entitlements`, { cancelToken: source.token })
        .then((response) => {
          if (response.data) {
            setSubscriptions(response.data)
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            dispatch(stopLoading())
            dispatch(
              showAlert({
                type: 'error',
                message: 'We were unable to retrieve your Azure subscriptions.',
                component: 'Subscription',
                error
              })
            )
          }
        })
    }

    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  /* get the budget for the accounts tenants */
  useEffect(() => {
    const source = axios.CancelToken.source()

    const newBudgetArray: Record<string, AzureBudget> = {}
    const promises: Promise<void | AxiosPromise>[] = []

    tenants.forEach((tenant) => {
      promises.push(
        api.get(`/ms-api/account/${account.id}/azure_usage/${tenant.id}/budget`, { cancelToken: source.token }).then((response: AxiosResponse) => {
          const budget: AzureBudget = {
            account_id: account.id,
            tenant_id: tenant.id,
            value: response.status === 200 ? parseFloat(response.data) : 0
          }

          newBudgetArray[tenant.id] = budget
        })
      )
    })

    /* wait for all requests to finish before setting the budgets */
    Promise.all(promises)
      .then(() => {
        setBudgets(newBudgetArray)
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(
            showAlert({
              type: 'error',
              message: 'We were unable to retrieve your budget.',
              component: 'AzureOverview',
              error
            })
          )
        }
      })

    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenants])

  /* sum the budgets of the selected tenants */
  useEffect(() => {
    if (budgets && selectedTenants)
      setBudget(selectedTenants.length > 0 ? selectedTenants.map((t) => (budgets[t] ? budgets[t].value || 0 : 0)).reduce((a, b) => a + b) : 0)
  }, [budgets, selectedTenants])

  /* build the tenant and subscription filters */
  useEffect(() => {
    if (tenants.length > 0 && subscriptions.length > 0) {
      /* ignore tenants that don't have atleast 1 subscription */
      const tenantIds = subscriptions.map((s) => s.tenant_id)

      const tenantOptions = tenants
        .filter((f) => tenantIds.includes(f.id))
        .map((t: Tenant) => {
          return {
            label: t.name,
            value: t.id
          }
        })

      setTenantOptions(tenantOptions)
      setSelectedTenants(tenantOptions.map((t) => t.value))
    }
  }, [subscriptions, tenants])

  /* filter the subscriptions filter by selected tenants */
  useEffect(() => {
    if (selectedTenants) {
      const subs = subscriptions.filter((s) => selectedTenants.includes(s.tenant_id))

      setSelectedSubscriptions(subs.map((s) => s.id))

      setSubscriptionOptions(
        subs.map((entitlement: Entitlement) => {
          return {
            label: entitlement.name,
            value: entitlement.id
          }
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenants])

  /* get the usage for the selected subscription */
  useEffect(() => {
    const source = axios.CancelToken.source()

    dispatch(startLoading())

    if (subscriptions.length > 0 && account.id) {
      api
        .post(
          `/ms-api/account/${account.id}/azure_usage/combined_daily_usage_by_entitlement`,
          {
            entitlements: subscriptions.map((s) => s.id),
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD')
          },
          { cancelToken: source.token }
        )
        .then((response: AxiosResponse) => {
          setMeterUsage(response.data)

          if (response.data.length === 0) {
            dispatch(stopLoading())
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            dispatch(stopLoading())
            dispatch(
              showAlert({
                type: 'error',
                message: 'We were unable to retrieve your subscription usage data.',
                component: 'Subscription',
                error
              })
            )
          }
        })
    }

    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.id, endDate, startDate, subscriptions])

  /* filter the usage by the selected subscriptions */
  useEffect(() => {
    if (meterUsage.length > 0 && selectedSubscriptions.length > 0) {
      setFilteredUsage(meterUsage.filter((m) => selectedSubscriptions.includes(m.entitlementId)))
    }
  }, [meterUsage, selectedSubscriptions])

  /* forecast the usage */
  useEffect(() => {
    if (selectedSubscriptions.length > 0 && filteredUsage.length > 0) {
      let total = 0
      const totalForDate: Record<string, number> = {}

      filteredUsage.forEach((usage: MeterUsage) => {
        if (selectedSubscriptions.includes(usage.entitlementId)) {
          total += usage.price

          const date = moment(usage.usageDate).format('YYYY-MM-DD')

          totalForDate[date] ? (totalForDate[date] += usage.price) : (totalForDate[date] = usage.price)
        }
      })

      const start = moment(startDate)

      let forecast = 0
      let lastIndex = start.format('YYYY-MM-DD')

      while (start.isBefore(endDate)) {
        if (totalForDate[lastIndex] !== undefined) {
          forecast += totalForDate[lastIndex]
          lastIndex = moment(lastIndex).add(1, 'days').format('YYYY-MM-DD')
        } else {
          const l = moment(lastIndex).subtract(2, 'days').format('YYYY-MM-DD')

          if (totalForDate[l]) forecast += totalForDate[l]
        }

        start.add(1, 'days')
      }

      setTotal(total)
      setForecast(forecast)
      dispatch(stopLoading())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubscriptions, filteredUsage])

  return (
    <Fragment>
      <Section>
        {/*         <Callout
          text="There is a delay getting your data from Microsoft. Sorry for the inconvenience. Please try again shortly."
          icon={<PiWarningFill className="h-5 w-5" />}
          color="warning"
          className="mb-8"
        /> */}
        <Banner>
          <Dropdown
            options={periodOptions}
            defaultValue={periodOptions[0]}
            variant={Dropdown.variant.DEFAULT}
            label="Period"
            bordered
            multi={false}
            onChange={(option: OptionTypeBase) => handlePeriodChange(option.value)}
          />
          <DateRange
            start={startDate}
            end={endDate}
            startDateId="subscription_start_date"
            endDateId="subscription_end_date"
            variant={DateRange.variant.DEFAULT}
            bordered
            onChange={(startDate, endDate) => handleDatesChange(startDate, endDate.set({ hour: 1, minute: 0 }))}
            disabled={period !== 'custom' && period !== 'hiddenOption'}
            focus={period === 'custom'}
          />
          <Spacer className="hidden sm:block" />
          {/* tenants filter */}
          <Dropdown
            options={tenantOptions}
            defaultValue={tenantOptions.length > 0 ? tenantOptions : undefined}
            variant={Dropdown.variant.DEFAULT}
            label="Tenants"
            bordered
            multi={true}
            onChange={(options) => setSelectedTenants(options.map((option: OptionTypeBase) => option.value))}
          />
          {/* subscriptions filter */}
          <Dropdown
            options={subscriptionOptions}
            defaultValue={subscriptionOptions.length > 0 ? subscriptionOptions : undefined}
            variant={Dropdown.variant.DEFAULT}
            label="Subscriptions"
            bordered
            multi={true}
            onChange={(options) => {
              setSelectedSubscriptions(options.map((option: OptionTypeBase) => option.value))
            }}
          />
        </Banner>
        <Spacer />
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-1 mdlg:grid-cols-2 xl:grid-cols-3">
          <AzureTile icon={<FcBarChart className="h-full w-full" />} value={total} label="Total spend" arrow />
          <AzureTile icon={<FcBullish className="h-full w-full" />} value={forecast} label="Forecast spend" arrow />
          <AzureTile icon={<FcMoneyTransfer className="h-full w-full" />} value={budget} label="Monthly budget" />
        </div>
      </Section>
      <Section>
        <div className="flex flex-col gap-8">
          <div className="grids-cols-1 grid gap-16 xl:grid-cols-2">
            <Chart title="Services" dataKey="meterCategory" usage={filteredUsage} />
            <Chart title="Meters" dataKey="meterName" usage={filteredUsage} />
          </div>
          <div className="mt-8 grid grid-cols-1 gap-16 xl:mt-0 xl:grid-cols-2">
            <Chart title="Groups" dataKey="resourceGroup" usage={filteredUsage} />
            <Chart title="Locations" dataKey="location" usage={filteredUsage} />
          </div>
        </div>
      </Section>
    </Fragment>
  )
}

const SubscriptionUsageWithTracking = withAITracking(reactPlugin, SubscriptionUsage, 'SubscriptionUsage')
export default SubscriptionUsageWithTracking
