import { useMemo } from 'react'

import moment from 'moment'

type TotalSpend = {
  name: string | undefined
  key: string
  totalSpend: number
  values: {
    usageDate: moment.Moment
    tenantId: string
    subscriptionId: string
    totalSpendGBP: number
    resourceLocation?: string | undefined
    resourceGroup?: string | undefined
    meterCategory?: string | undefined
    forecast?: boolean | undefined
  }[]
  inceptionDate: moment.Moment
  lastContentfulDate: moment.Moment
  average: number
}

interface UseChartDataShapeConfig {
  data: TotalSpend[]
}

type UseBasicChartDataShapeOutput = { name: string; value: number }[]

const useBasicChartDataShape = ({ data }: UseChartDataShapeConfig): UseBasicChartDataShapeOutput => {
  const compressed = useMemo(() => {
    if (data.length <= 8) return data.map((d) => ({ name: d.name ?? d.key, value: d.totalSpend }))

    const compressed = data.slice(0, 7)
    const lastEntry = {
      key: 'Other',
      name: 'Other',
      totalSpend: 0,
      average: 0,
      inceptionDate: moment(),
      lastContentfulDate: moment(),
      values: [] as (typeof data)[0]['values']
    }

    for (let i = 7; i < data.length; i++) {
      const curr = data[i]
      lastEntry.totalSpend += curr.totalSpend
      lastEntry.average += curr.average
      lastEntry.values.push(...curr.values)
    }

    lastEntry.inceptionDate = moment.min(lastEntry.values.map((v) => v.usageDate))
    lastEntry.lastContentfulDate = moment.max(lastEntry.values.map((v) => v.usageDate))
    compressed.push(lastEntry)

    return compressed.map((c) => ({
      name: c.name ?? c.key,
      value: c.totalSpend
    }))
  }, [data])

  return compressed
}

export default useBasicChartDataShape
