import { type ColumnDef } from '@tanstack/react-table'

import { useCallback, useMemo } from 'react'

import useChangeApprovalsQuery, { type ChangeCustomerApproval } from '~/hooks/services/servicenow/queries/useChangeApprovalsQuery'

import State from '~/components/Common/State'
import PaginatedTable from '~/components/Common/Table/PaginatedTable'

import { useChangeApprovalFilters } from '~/views/Support/ChangeApprovals/hooks/useChangeApprovalFilters'

import moment from 'moment'
import { useNavigate } from 'react-router-dom'

const ChangeCustomerApprovalTable = () => {
  const navigate = useNavigate()
  const { filterOptions, handlePageChange, handlePageSizeChange, handleSortChange } = useChangeApprovalFilters()
  const { data, isLoading } = useChangeApprovalsQuery(filterOptions)

  const columns = useMemo<ColumnDef<ChangeCustomerApproval>[]>(
    () => [
      {
        id: 'number',
        header: 'Change',
        accessorKey: 'number'
      },
      {
        id: 'approver',
        header: 'Approver',
        accessorKey: 'approver_name'
      },
      {
        id: 'approved',
        header: 'Approved on',
        cell: ({ row }) => (row.original.approved ? moment(row.original.approved).format('DD/MM/YY HH:mm') : '-')
      },
      {
        id: 'rejected',
        header: 'Rejected on',
        cell: ({ row }) => (row.original.rejected ? moment(row.original.rejected).format('DD/MM/YY HH:mm') : '-')
      },
      {
        id: 'state',
        header: 'State',
        cell: ({ row }) => <State state={row.original.state} />
      },
      {
        id: 'due_date',
        header: 'Due Date',
        cell: ({ row }) => moment(row.original.due_date).format('DD/MM/YY'),
        sortingFn: (a, b, id) => moment(a.getValue(id)).unix() - moment(b.getValue(id)).unix()
      }
    ],
    []
  )

  const handlePaginationChange = useCallback(
    ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => {
      handlePageChange(pageIndex)
      handlePageSizeChange(pageSize)
    },
    [handlePageChange, handlePageSizeChange]
  )

  const handleSortingChange = useCallback(
    (sorting: any) => {
      if (sorting.length === 0) {
        handleSortChange(null, false)
        return
      }

      const { id, desc } = sorting[0]
      const columnToFieldMap: Record<string, string> = {
        number: 'change',
        approver: 'approver',
        approved: 'approved',
        rejected: 'rejected',
        state: 'state',
        due_date: 'duedate'
      }

      handleSortChange(columnToFieldMap[id], desc)
    },
    [handleSortChange]
  )

  const handleRowClick = useCallback(
    (row: ChangeCustomerApproval) => {
      navigate(`/changes/approvals/${row.id}`)
    },
    [navigate]
  )

  return (
    <PaginatedTable
      data={data?.items || []}
      columns={columns}
      totalRows={data?.total_count || 0}
      pageCount={Math.ceil((data?.total_count || 0) / filterOptions.take)}
      isLoading={isLoading}
      onPaginationChange={handlePaginationChange}
      onSortingChange={handleSortingChange}
      onRowClick={handleRowClick}
    />
  )
}

export default ChangeCustomerApprovalTable
