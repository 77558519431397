import useChangeCommentsQuery from '~/hooks/services/servicenow/queries/useChangeCommentsQuery'

import { classNames } from '~/utils/classNames'

import moment from 'moment'
import { useParams } from 'react-router-dom'

const CommentList = () => {
  const { id } = useParams()
  const { data: comments } = useChangeCommentsQuery(id)

  return (
    <div className="flex flex-col gap-4">
      {comments?.map((comment, index) => {
        const text = comment.comment.split(' <{#}> ')
        const author = comment.author === 'Provide' ? (text.length > 1 ? text[0] : 'Provide') : comment.author

        return (
          <div key={index} className={classNames(comment.author === 'Provide' ? 'ml-8' : 'mr-8')}>
            <div className="flex justify-between text-sm font-bold">
              <div>{author}</div>
              <div>{moment(comment.created).format('DD/MM/yy HH:mm')}</div>
            </div>
            <div className={classNames('rounded p-2 text-sm', comment.author === 'Provide' ? 'bg-th-info-light' : 'bg-th-content-secondary')}>
              {text[text.length - 1]}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default CommentList
