import { Fragment, type FunctionComponent, useEffect, useState } from 'react'

import { type Quote } from '../../types/quote'
import { type QuoteItem } from '../../types/quoteitem'

import moment from 'moment'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { showAlert } from '../../actions/alertsActions'
import { closeDrawers } from '../../actions/drawerActions'
import { openDelegateQuoteModal, openSignQuoteModal } from '../../actions/modalActions'
import api from '../../constants/api'
import Button from '../Common/Button'
import Drawer from '../Common/Drawer'

const QuoteDrawer: FunctionComponent = (): JSX.Element => {
  const account = useSelector((state: RootStateOrAny) => state.account)
  const user = useSelector((state: RootStateOrAny) => state.user)
  const quote: Quote = useSelector((state: RootStateOrAny) => state.drawers.quote)

  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState<QuoteItem[]>([])

  const dispatch = useDispatch()

  useEffect(() => {
    if (quote) {
      setLoading(true)

      api
        .post(`/servicenow/account/${account.id}/quoteitems`, {
          quote: quote.id
        })
        .then((response) => {
          const quoteitems = response.data.sort((a: QuoteItem, b: QuoteItem) => (a.item < b.item ? -1 : a.item > b.item ? 1 : 0))

          /* save order items to state */
          setItems(quoteitems)
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)

          /* alert the user that the order items couldn't be retrieved */
          dispatch(
            showAlert({
              type: 'error',
              message: "We couldn't retrieve the items for " + quote.number + ' at this time. Please try again later.',
              component: 'Order',
              error
            })
          )
        })
    }
  }, [quote, account, dispatch])

  /* drawer actions */
  const Actions: FunctionComponent = (): JSX.Element => {
    return (
      <Fragment>
        {quote.state === 'Unsigned' && quote.ordered_by_address.toLowerCase() === user.email.toLowerCase() && (
          <Button bordered onClick={() => dispatch(openSignQuoteModal(quote))}>
            Sign
          </Button>
        )}
        {quote.state === 'Unsigned' && quote.ordered_by_address.toLowerCase() === user.email.toLowerCase() && (
          <Button bordered onClick={() => dispatch(openDelegateQuoteModal(quote))}>
            Delegate
          </Button>
        )}
        <Button bordered onClick={() => dispatch(closeDrawers())}>
          Close
        </Button>
      </Fragment>
    )
  }

  /* drawer content */
  return (
    <Drawer title={quote ? quote.number : 'Quote'} loading={loading} actions={<Actions />}>
      <div className="mb-8 flex justify-between">
        {/* requested by */}
        <div>
          <div className="font-bold">Ordered by</div>
          <div className="text-sm">{quote.ordered_by}</div>
        </div>
      </div>
      <div className="mb-8 flex justify-between">
        {/* sales person */}
        <div>
          <div className="font-bold">Sales Person</div>
          <div className="text-sm">{quote.sales_person}</div>
        </div>
      </div>
      <div className="mb-8 flex justify-between">
        {/* order date */}
        <div>
          <div className="font-bold">Expires on</div>
          <div className="text-sm">{moment(quote.created).format('DD/MM/yy')}</div>
        </div>
      </div>

      <hr className="my-8 border-th-border" />

      {/* quotes */}
      <div className="flex items-center font-bold">
        <div>Quote Items</div>
      </div>

      {/* quote list */}
      <div className="mt-6">
        {items.length > 0 && (
          <ul>
            {items.map((item: QuoteItem) => (
              <li key={item.id} className="flex cursor-pointer items-center border-b border-th-border py-2 text-sm last:border-b-0">
                {/* quote details */}
                <div className="flex flex-auto space-x-4">
                  <span className="flex-auto">{item.item}</span>
                  <span className="flex-auto">{item.recurrence}</span>
                  <span className="w-10 text-right">£{item.total}</span>
                </div>
              </li>
            ))}
          </ul>
        )}
        {/* no items found */}
        {items.length === 0 && <div>This quote does not contain any items.</div>}
      </div>
    </Drawer>
  )
}

export default QuoteDrawer
