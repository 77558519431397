import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useMutation, { type UseMutationOptions } from 'src/hooks/utils/useMutation'

import type { GlobalConfig } from '../queries/useGlobalConfigQuery'

type GlobalConfigMutationData = GlobalConfig & { accountIds?: string[] }
type GlobalConfigMutationOptions = UseMutationOptions<GlobalConfigMutationData>

const useGlobalConfigMutation = (options?: GlobalConfigMutationOptions) => {
  const account = useAccount()

  return useMutation(
    async (data: GlobalConfigMutationData) => {
      const response = await api.post(`/provide/global_config`, data, {
        params: { account_id: account.id }
      })
      return response.data
    },
    {
      queryKeysToInvalidate: [
        ['globalConfigsQuery', account.id, true],
        ['globalConfigsQuery', account.id, false]
      ],
      ...options
    }
  )
}

export default useGlobalConfigMutation
