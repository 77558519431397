import { useQueryClient } from '@tanstack/react-query'

import { useCallback, useState } from 'react'

import { showAlert } from 'src/actions/alertsActions'

import useUpdateFileMutation from 'src/hooks/services/provide/mutations/useUpdateFileMutation'
import useUpdateFolderMutation from 'src/hooks/services/provide/mutations/useUpdateFolderMutation'
import useFilesQuery, { type Folder } from 'src/hooks/services/provide/queries/useFilesQuery'
import useFolderConfigQuery from 'src/hooks/services/provide/queries/useFolderConfigQuery'
import useAccount from 'src/hooks/utils/useAccount'

import Spinner from 'src/components/Common/Spinner'
import BreadcrumbComponent from 'src/components/Content/FileExplorer/Breadcrumb'
import ConfirmationModal from 'src/components/Modals/ConfirmationModal'

import { useDispatch, useSelector } from 'react-redux'
import { type FolderState } from 'src/reducers/folderReducer'

const DEFAULT_FOLDER_PATH: Folder[] = [{ id: '', name: '', accountId: '', createdAt: '', createdFromConfigId: null, parentId: null, path: 'root' }]

type MoveItem = { id: string; name: string; type: string }

export const useFileFolderMove = () => {
  const { id: accountId } = useAccount()
  const queryClient = useQueryClient()

  const [movingFileIds, setMovingFileIds] = useState<string[]>([])
  const [moveModalState, setMoveModalState] = useState<{ isOpen: boolean; files: MoveItem[] }>({ isOpen: false, files: [] })

  const search = useSelector((state: FolderState) => state.folder.search)
  const currentFolderId = useSelector((state: FolderState) => state.folder.currentFolderId)

  const [folderPath, setFolderPath] = useState<Folder[]>(DEFAULT_FOLDER_PATH)
  const selectedFolderId = folderPath[folderPath.length - 1].id

  const handleFolderClick = (folder: Folder) => {
    setFolderPath([...folderPath, folder])
  }

  const handleFolderNavigate = (index: number) => {
    setFolderPath(folderPath.slice(0, index + 1))
  }

  const { data, isLoading } = useFilesQuery(
    { query: '', folderId: selectedFolderId || null },
    { refetchOnWindowFocus: false, refetchOnMount: false, enabled: moveModalState.isOpen }
  )
  const folders = data?.folders || []
  const filteredFolders = folders.filter((folder) => !moveModalState.files.some((f) => f.id === folder.id) && folder.name !== 'Uploads')

  const { mutateAsync: updateFileMetadata } = useUpdateFileMutation({
    queryKeysToInvalidate: [
      ['getFilesQuery', null, currentFolderId || null, accountId],
      ['getFilesQuery', null, selectedFolderId || null, accountId],
      ['getFilesQuery', search || null, currentFolderId || null, accountId]
    ]
  })
  const { mutateAsync: updateFolder } = useUpdateFolderMutation({
    queryKeysToInvalidate: [
      ['getFilesQuery', null, currentFolderId || null, accountId],
      ['getFilesQuery', null, selectedFolderId || null, accountId],
      ['getFilesQuery', search || null, currentFolderId || null, accountId]
    ]
  })

  const updateQueryData = (moveItems: MoveItem[], newFolderId: string) => {
    const currentFolderQueryKey = ['getFilesQuery', null, currentFolderId || null, accountId]
    const newFolderQueryKey = ['getFilesQuery', null, newFolderId, accountId]

    // Remove files from the current folder
    queryClient.setQueryData<typeof data>(currentFolderQueryKey, (oldData) => {
      if (!oldData) return oldData

      // Add files to the new folder
      queryClient.setQueryData<typeof data>(newFolderQueryKey, (newData) => {
        if (!newData) return newData

        const newFiles = moveItems.map((file) => oldData.files.find((f) => f.id === file.id)).flatMap((file) => file || [])
        const newFolders = moveItems.map((folder) => oldData.folders.find((f) => f.id === folder.id)).flatMap((folder) => folder || [])

        return {
          files: [...newData.files, ...newFiles],
          folders: [...newData.folders, ...newFolders],
          limitedView: newData.limitedView,
          correctAccount: newData.correctAccount
        }
      })

      return {
        ...oldData,
        files: oldData.files.filter((file) => !moveItems.some((f) => f.id === file.id) && (!search || file.fileName.includes(search))),
        folders: oldData.folders.filter((folder) => !moveItems.some((f) => f.id === folder.id) && (!search || folder.name.includes(search)))
      }
    })
  }

  const dispatch = useDispatch()
  const handleFileMove = async (files: MoveItem[], newFolderId: string) => {
    const fileIds = files.map((file) => file.id)
    setMovingFileIds((prev) => [...prev, ...fileIds])

    const batchSize = 5 // Adjust this value based on your API's capabilities
    const batches = []

    for (let i = 0; i < files.length; i += batchSize) {
      const batch = files.slice(i, i + batchSize)
      batches.push(batch)
    }

    try {
      for (const batch of batches) {
        await Promise.all(
          batch.map(async (file) => {
            if (file.type === 'file') {
              await updateFileMetadata({ id: file.id, folderId: newFolderId })
            } else {
              await updateFolder({ id: file.id, parentId: newFolderId })
            }
          })
        )
      }

      updateQueryData(files, newFolderId)

      const message = `${files.length} ${files.length === 1 ? 'file' : 'files'} moved successfully`
      dispatch(showAlert({ component: 'FileMove', message, type: 'success' }))
    } catch (error) {
      console.error('Move failed:', error)
      dispatch(showAlert({ component: 'FileMove', message: 'Failed to move some files', type: 'error' }))
    } finally {
      setMovingFileIds((prev) => prev.filter((id) => !fileIds.includes(id)))
    }
  }

  const openMoveModal = useCallback((files: MoveItem[]) => {
    setTimeout(() => setMoveModalState({ isOpen: true, files }), 10)
  }, [])

  const closeMoveModal = () => {
    setMoveModalState({ isOpen: false, files: [] })
  }

  const { data: folderConfigs } = useFolderConfigQuery({
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 5 * 60 * 1000,
    cacheTime: 5 * 60 * 1000
  })

  const lastFolder = folderPath.findLast((path) => path.createdFromConfigId !== null)
  const lastFolderConfig = folderConfigs?.find((folder) => folder.id === lastFolder?.createdFromConfigId) ?? null
  const freeFormFolder = lastFolderConfig?.type === 'Unstructured'

  const MoveModal = (
    <ConfirmationModal
      isOpen={moveModalState.isOpen}
      onClose={closeMoveModal}
      onConfirm={() => {
        handleFileMove(moveModalState.files, selectedFolderId!)
        closeMoveModal()
      }}
      disabled={selectedFolderId === null || !freeFormFolder || currentFolderId === selectedFolderId}
      title={`Move ${moveModalState.files.length > 1 ? `${moveModalState.files.length} files` : moveModalState.files[0]?.name || 'file'}`}
      countdownDuration={0}
    >
      <div className="flex flex-col space-y-4">
        <BreadcrumbComponent folderPath={folderPath} handleFolderNavigate={handleFolderNavigate} />

        <ul className="max-h-60 overflow-y-auto rounded-lg border border-th-border p-2">
          {filteredFolders.map((folder) => (
            <button
              key={folder.id}
              onClick={() => handleFolderClick(folder)}
              className="w-full max-w-[380px] overflow-hidden text-ellipsis whitespace-nowrap rounded-md border-th-border px-2 py-1 text-left hover:bg-th-content-secondary"
            >
              {folder.name}
            </button>
          ))}
          {filteredFolders.length === 0 && !isLoading && <li className="py-1 text-center text-th-text-secondary">No more folders found</li>}
          {isLoading && <Spinner className="mx-auto h-8 w-8" />}
        </ul>
      </div>
    </ConfirmationModal>
  )

  return {
    movingFileIds,
    openMoveModal,
    MoveModal
  }
}
