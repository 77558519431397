import { type ColumnDef } from '@tanstack/react-table'

import { useCallback, useMemo } from 'react'

import useChangesQuery, { type Change } from 'src/hooks/services/servicenow/queries/useChangesQuery'

import Classification from 'src/components/Common/Classification'
import State from 'src/components/Common/State'
import PaginatedTable from 'src/components/Common/Table/PaginatedTable'

import { useChangeFilters } from 'src/views/Support/Changes/hooks/useChangeFilters'

import moment from 'moment'
import { useNavigate } from 'react-router-dom'

const ChangeTable = () => {
  const navigate = useNavigate()

  const { filterOptions, handlePageChange, handlePageSizeChange, handleSortChange } = useChangeFilters()

  const { data, isLoading } = useChangesQuery(filterOptions)

  const columns = useMemo<ColumnDef<Change>[]>(
    () => [
      {
        id: 'notification',
        header: '',
        cell: ({ row }) => (row.original.notification ? <div className="h-2 w-2 rounded-full bg-th-primary" /> : null)
      },
      {
        id: 'number',
        header: 'Number',
        accessorKey: 'number'
      },
      {
        id: 'title',
        header: 'Title',
        accessorKey: 'title'
      },
      {
        id: 'requested_by',
        header: 'Requested By',
        accessorKey: 'requested_by_name'
      },
      {
        id: 'assigned',
        header: 'Assigned',
        accessorKey: 'assigned_name'
      },
      {
        id: 'category',
        header: 'Category',
        accessorKey: 'category'
      },
      {
        id: 'classification',
        header: 'Classification',
        cell: ({ row }) => <Classification classification={row.original.classification} />
      },
      {
        id: 'starts',
        header: 'Starts',
        cell: ({ row }) => (row.original.starts ? <span>{moment(row.original.starts).format('DD/MM/YY HH:mm')}</span> : null)
      },
      {
        id: 'ends',
        header: 'Ends',
        cell: ({ row }) => (row.original.ends ? <span>{moment(row.original.ends).format('DD/MM/YY HH:mm')}</span> : null)
      },
      {
        id: 'state',
        header: 'State',
        cell: ({ row }) => <State state={row.original.state} />
      }
    ],
    []
  )

  const handleRowClick = useCallback(
    (row: Change) => {
      navigate(`/changes/${row.id}`)
    },
    [navigate]
  )

  const handlePaginationChange = useCallback(
    ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => {
      handlePageChange(pageIndex)
      handlePageSizeChange(pageSize)
    },
    [handlePageChange, handlePageSizeChange]
  )

  const handleSortingChange = useCallback(
    (sorting: any) => {
      if (sorting.length === 0) {
        handleSortChange(null, false)
        return
      }

      const { id, desc } = sorting[0]
      const columnToFieldMap: Record<string, string> = {
        number: 'number',
        title: 'title',
        requested_by: 'originator_name',
        assigned: 'assigned_name',
        category: 'category',
        classification: 'classification',
        starts: 'created',
        ends: 'updated',
        state: 'state'
      }

      handleSortChange(columnToFieldMap[id], desc)
    },
    [handleSortChange]
  )

  return (
    <PaginatedTable
      data={data?.changes || []}
      columns={columns}
      totalRows={data?.total_count || 0}
      pageCount={Math.ceil((data?.total_count || 0) / filterOptions.take)}
      isLoading={isLoading}
      onPaginationChange={handlePaginationChange}
      onSortingChange={handleSortingChange}
      onRowClick={handleRowClick}
    />
  )
}

export default ChangeTable
