import { withAITracking } from '@microsoft/applicationinsights-react-js'

import { useEffect, useMemo, useState } from 'react'

import { stopLoading } from 'src/actions/loadingActions'

import useDailyTotalsForTagset from 'src/hooks/services/microsoft/queries/useDailyTotalsForTagset'
import useTrendsInsights, { type Insight } from 'src/hooks/services/microsoft/queries/useTrendsInsights'
import { useTenantsQuery } from 'src/hooks/services/servicenow/queries/useTenantsQuery'

import Heading from 'src/components/Common/Heading'
import Dropdown from 'src/components/Common/RefactoredDropdown'
import Section from 'src/components/Common/Section'
import Spacer from 'src/components/Common/Spacer'
import InsightsCarousel from 'src/components/Content/InsightsCarousel'
import SubscriptionDropdown from 'src/components/Dropdowns/SubscriptionDropdown'
import InsightsTable from 'src/components/Tables/InsightsTable'
import TrendsTable, { type NestedRecord } from 'src/components/Tables/TrendsTable'

import { reactPlugin } from 'src/configs/appInsights'

import moment from 'moment'
import { useDispatch } from 'react-redux'
import type { Column } from 'react-table'

const oneYearAgo = [moment().subtract(1, 'year').startOf('month'), moment().endOf('month')] as [moment.Moment, moment.Moment]
const lastThreeMonths = Array.from({ length: 3 }, (_, i) => moment().subtract(3 - i, 'months'))

// -~= Component =~- //
const AzureTrendsInsights = () => {
  // --~= State definitions =~-- /
  const dispatch = useDispatch()
  useEffect(() => void dispatch(stopLoading()), [dispatch])

  // -~= Helper Hooks =~- //
  const columns: Array<Column> = [
    {
      Header: 'Trend',
      accessor: 'trend',
      Cell: (row) => <span>{row.value}</span>
    },
    {
      Header: <span className="font-bold">12M Average</span>,
      Cell: (row) => <span className="text-th-text-secondary">{row.value}</span>,
      accessor: 'baseline'
    },
    ...lastThreeMonths.map((month) => ({
      Header: month.format('MMM YYYY'),
      accessor: month.format('MMM YYYY')
    })),
    {
      Header: 'Current',
      accessor: moment().format('MMM YYYY')
    }
  ]

  const { data: tenants } = useTenantsQuery({ active: true })
  const rawConsumptionData = useDailyTotalsForTagset({ dateRange: oneYearAgo, tenants, tags: [] })

  const trendsTableData = useMemo(() => {
    if (!rawConsumptionData.data) return []

    // 1. Group by tenant
    // 2. Add month totals in under that tenant
    const tenantMap = new Map<string, NestedRecord>()
    const months = [...lastThreeMonths, moment()].map((m) => m.format())

    for (const tenant of tenants) {
      const data = rawConsumptionData.data.filter((d) => d.tenantId === tenant.id)
      const total = data?.reduce((acc, curr) => acc + curr.totalSpendGBP, 0)
      const mean = total / 12 // 12 months

      const monthTotals = data.reduce(
        (acc, curr) => {
          const month = new Date(curr.usageDate).getMonth().toString() + new Date(curr.usageDate).getFullYear().toString()
          acc[month] = (acc[month] ?? 0) + curr.totalSpendGBP
          return acc
        },
        {} as Record<string, number>
      )

      const sumSquaredDiff = Object.values(monthTotals).reduce((acc, curr) => acc + Math.pow(curr - mean, 2), 0)
      const stdDev = Math.sqrt(sumSquaredDiff / 12)
      const trendLines = [mean - stdDev, mean + stdDev]

      tenantMap.set(tenant.id, {
        title: tenant.name,
        children: [],
        values: [{ value: mean }]
      })

      for (const month of months) {
        const monthTotal = monthTotals[new Date(month).getMonth().toString() + new Date(month).getFullYear().toString()]

        const trend = monthTotal > trendLines[1] ? true : monthTotal < trendLines[0] ? false : undefined
        const shouldTrend = month !== months.at(-1)

        tenantMap.get(tenant.id)!.values.push({
          value: monthTotal,
          uptrend: shouldTrend ? trend : undefined
        })
      }

      for (const subscription of tenant.subscriptions) {
        const subData = data.filter((d) => d.subscriptionId === subscription.id)
        const subTotal = subData.reduce((acc, curr) => acc + curr.totalSpendGBP, 0)
        const subMean = subTotal / 12

        const monthTotals = subData.reduce(
          (acc, curr) => {
            const month = new Date(curr.usageDate).getMonth().toString() + new Date(curr.usageDate).getFullYear().toString()
            acc[month] = (acc[month] ?? 0) + curr.totalSpendGBP
            return acc
          },
          {} as Record<string, number>
        )

        const sumSquaredDiff = Object.values(monthTotals).reduce((acc, curr) => acc + Math.pow(curr - subMean, 2), 0)
        const stdDev = Math.sqrt(sumSquaredDiff / 12)
        const trendLines = [subMean - stdDev, subMean + stdDev]

        tenantMap.get(tenant.id)!.children?.push({ title: subscription.name, values: [{ value: subMean }] })

        for (const month of months) {
          const monthlyTotal = monthTotals[new Date(month).getMonth().toString() + new Date(month).getFullYear().toString()]

          const trend = monthlyTotal > trendLines[1] ? true : monthlyTotal < trendLines[0] ? false : undefined
          const shouldTrend = month !== months.at(-1)

          tenantMap
            .get(tenant.id)!
            .children?.at(-1)
            ?.values.push({
              value: monthlyTotal,
              uptrend: shouldTrend ? trend : undefined
            })
        }
      }
    }

    return Array.from(tenantMap.values())
  }, [rawConsumptionData.data, tenants])

  const insights = useTrendsInsights()
  // const { data: newInsights } = useNewTrendsInsights()

  const [insightsTableFilter, setInsightsTableFilter] = useState<{
    groups: string[]
    types: string[]
    subscriptionsIds: string[]
    tenantIds: string[]
    periods: string[]
  }>({
    groups: ['TenantId', 'SubscriptionId', 'ResourceGroup', 'ResourceLocation', 'MeterCategory'],
    types: ['Increase', 'Decrease', 'Unusual', 'New', 'Ended'],
    periods: ['n/a', 'month', 'quarter', 'year'],
    subscriptionsIds: [],
    tenantIds: []
  })

  const [selectedInsight, setSelectedInsight] = useState<Insight | null>(null)

  return (
    <>
      <Section className={`${'flex flex-col items-center justify-center pt-12 xs:pt-16'}`}>
        {/* <div className="relative">
          <div className="w-[50vw] absolute inset-0 -translate-y-28 bg-th-content-secondary h-1 -translate-x-1/2 rounded-full overflow-hidden">
            <div style={{ width: `${((5 - (newInsights?.length ?? 0)) / 5) * 100}%` }} className="bg-th-primary h-1" />
          </div>
        </div> */}
        <InsightsCarousel onTileClick={(id) => setSelectedInsight(insights?.find((insight) => insight.id === id) || null)} />
      </Section>

      <Section>
        <Heading text="Insights">
          <Spacer className="hidden sm:block" />

          <Dropdown
            label="Period"
            data={[
              { label: 'N/A', value: 'n/a', checked: true },
              { label: 'Month', value: 'month', checked: true },
              { label: 'Quarter', value: 'quarter', checked: true },
              { label: 'Year', value: 'year', checked: true }
            ]}
            onChange={(_, options) => {
              setInsightsTableFilter((prev) => ({
                ...prev,
                periods: options.map((o) => o.value)
              }))
            }}
          />

          <Dropdown
            label="Levels"
            data={[
              { label: 'Tenant', value: 'TenantId', checked: true },
              { label: 'Subscription', value: 'SubscriptionId', checked: true },
              { label: 'Resource Group', value: 'ResourceGroup', checked: true },
              { label: 'Region', value: 'ResourceLocation', checked: true },
              { label: 'Service', value: 'MeterCategory', checked: true }
            ]}
            onChange={(_, options) => {
              setInsightsTableFilter((prev) => ({
                ...prev,
                groups: options.map((o) => o.value)
              }))
            }}
          />

          <Dropdown
            label="Types"
            data={[
              { label: 'Increase', value: 'Increase', checked: true },
              { label: 'Decrease', value: 'Decrease', checked: true },
              { label: 'Unusual', value: 'Unusual', checked: true },
              { label: 'New', value: 'New', checked: true },
              { label: 'Ended', value: 'Ended', checked: true }
            ]}
            onChange={(_, options) => {
              setInsightsTableFilter((prev) => ({
                ...prev,
                types: options.map((o) => o.value)
              }))
            }}
          />

          <SubscriptionDropdown
            onChange={(selectedTenants, selectedSubscriptions) =>
              setInsightsTableFilter((prev) => ({
                ...prev,
                subscriptionsIds: selectedSubscriptions.map((s) => s.id),
                tenantIds: selectedTenants.map((t) => t.id)
              }))
            }
          />
        </Heading>

        <InsightsTable data={insights ?? []} filter={insightsTableFilter} onRowClick={(row) => setSelectedInsight(row)} selectedInsight={selectedInsight} />
      </Section>

      <Section>
        <Heading
          text="Trends"
          info="The trend is analyzed by comparing it to the 12-month average and standard deviation to identify significant deviations in either direction."
        />

        <TrendsTable data={trendsTableData} headers={columns.slice(1).map((v) => v.Header as string)} />
      </Section>
    </>
  )
}

const AzureTrendsInsightsWithTracking = withAITracking(reactPlugin, AzureTrendsInsights, 'Azure Trends & Insights')
export default AzureTrendsInsightsWithTracking
