import { Shimmer, ShimmerElementType, ShimmerElementsGroup, createTheme } from '@fluentui/react'

import { type FunctionComponent } from 'react'

import { type Product as ProductType } from '../../../../types/microsoft365'

import { Iconly } from 'react-iconly'
import SmoothScrollbar from 'smooth-scrollbar'

import Tile from '../../../../components/Common/Tile'

interface Props {
  product: ProductType
  onClick: () => void
  onPromotionsClick: () => void
}

/* product loading shimmer */
const shimmerElements = (): JSX.Element => {
  return (
    <div style={{ display: 'flex' }}>
      {/* each shimmer elements container matches the height of the tallest container as they are wrapped.
        This means the gaps between wrapped shimmer elements is determnined by setting the height value
        relative to the height of the tallest element */}
      <ShimmerElementsGroup
        flexWrap
        width="100%"
        shimmerElements={[
          /* title */
          { type: ShimmerElementType.line, width: '100%', height: 40 },
          /* id */
          { type: ShimmerElementType.line, width: '50%', height: 20 },
          { type: ShimmerElementType.gap, width: '50%', height: 20 },
          /* description */
          { type: ShimmerElementType.line, width: '100%', height: 40 },
          { type: ShimmerElementType.line, width: '100%', height: 40 },
          { type: ShimmerElementType.line, width: '100%', height: 40 },
          { type: ShimmerElementType.line, width: '100%', height: 40 },
          { type: ShimmerElementType.line, width: '100%', height: 40 },
          /* promotion */
          { type: ShimmerElementType.line, width: '50%', height: 20 },
          { type: ShimmerElementType.gap, width: '50%', height: 20 }
        ]}
        backgroundColor="var(--content)"
      />
    </div>
  )
}

const Product: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { product } = props

  /* shimmer theme */
  const customThemeForShimmer = createTheme({
    palette: {
      themeSecondary: 'var(--translucent)' /* shimmer wave */,
      themeTertiary: 'var(--lowlight)' /* shimmer background */
    }
  })

  const handleClick = () => {
    props.onClick()

    /* reset scrollbar */
    const scrollbar = document.getElementsByClassName('scrollbar')[0] as HTMLElement
    if (scrollbar) SmoothScrollbar.get(scrollbar)?.setPosition(0, 0)
  }

  return (
    <Tile className="relative cursor-pointer text-th-text hover:shadow">
      <Shimmer
        customElementsGroup={shimmerElements()}
        shimmerColors={{
          shimmer: customThemeForShimmer.palette.themeTertiary,
          shimmerWave: customThemeForShimmer.palette.themeSecondary
        }}
        isDataLoaded={product !== undefined}
        className="relative h-full"
      >
        {product && (
          <div className="h-full">
            <div className="flex flex-col gap-4" onClick={() => handleClick()}>
              <div className="flex justify-between gap-4">
                <div>
                  {/* title */}
                  <div className="line-clamp-2 h-14 text-lg font-bold">{product.Title}</div>
                  {/* id */}
                  <div className="text-sm text-th-text-secondary">{product.Id}</div>
                </div>
                {/* view */}
                <div className="flex h-8 w-8 flex-none items-center justify-center rounded-full bg-th-content-secondary">
                  <Iconly name="ChevronRight" set="light" className="h-5 w-5" />
                </div>
              </div>
              {/* description */}
              <div className="mb-4 line-clamp-6 h-32">{product.Description}</div>
            </div>
            <div>
              {/* promotion */}
              {product.Promotions && (
                <div className="w-max text-sm font-bold text-th-primary underline" onClick={() => props.onPromotionsClick()}>
                  {product.Promotions.length} Available Promotion
                  {product.Promotions.length > 1 && 's'}
                </div>
              )}
            </div>
          </div>
        )}
      </Shimmer>
    </Tile>
  )
}

export default Product
