import { type SoftwareSubscription, type Tenant } from 'src/types/azure'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useQuery from 'src/hooks/utils/useQuery'

import moment from 'moment'

interface useSoftwareSubscriptionsQueryProps {
  tenants: Tenant[]
}

const useSoftwareSubscriptionsQuery = ({ tenants }: useSoftwareSubscriptionsQueryProps) => {
  const account = useAccount()

  const { data: softwareSubscriptions, ...rest } = useQuery(
    ['software-subscriptions', account.id, tenants],
    async ({ signal }) => {
      const response = await api.post(
        `/servicenow/account/${account.id}/software_subscriptions`,
        {
          tenant_ids: tenants.map((t) => t.id),
          start_date: '1970-01-01',
          end_date: moment().format('YYYY-MM-DD')
        },
        { signal }
      )
      return response.data as SoftwareSubscription[]
    },
    {
      enabled: !!account.id && !!tenants && !!tenants.length
    }
  )

  const instances = softwareSubscriptions ?? []

  const stateExtendedInstances = instances.map((instance) => {
    const lastChargeCancelled = instance.charges?.[0]?.type === 'cancelImmediate'

    return {
      ...instance,
      state: (lastChargeCancelled ? 'Cancelled' : 'Active') as 'Active' | 'Cancelled'
    }
  })

  return { data: stateExtendedInstances, ...rest }
}

export default useSoftwareSubscriptionsQuery
