import { withAITracking } from '@microsoft/applicationinsights-react-js'

import { type FunctionComponent, useEffect, useState } from 'react'

import { type AdvisorDetails } from '../../../../types/azure'

import axios, { type AxiosResponse } from 'axios'
import { FontIcon } from 'office-ui-fabric-react'
import { initializeIcons } from 'office-ui-fabric-react'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { showAlert } from '../../../../actions/alertsActions'
import { startLoading, stopLoading } from '../../../../actions/loadingActions'
import Banner from '../../../../components/Common/Banner'
import Section from '../../../../components/Common/Section'
import Spacer from '../../../../components/Common/Spacer'
import TenantDropdown from '../../../../components/Common/TenantDropdown'
import { reactPlugin } from '../../../../configs/appInsights'
import api from '../../../../constants/api'

const advisorCategories = [
  {
    category: 'Cost',
    displayName: 'Cost',
    icon: 'PaymentCard',
    details: {
      annualSavingsAmount: 0,
      highCount: 0,
      impactedResourcesCount: 0,
      lowCount: 0,
      mediumCount: 0,
      totalCount: 0
    },
    recommendations: 0
  },
  {
    category: 'Security',
    displayName: 'Security',
    icon: 'Shield',
    details: {
      annualSavingsAmount: 0,
      highCount: 0,
      impactedResourcesCount: 0,
      lowCount: 0,
      mediumCount: 0,
      totalCount: 0
    },
    recommendations: 0
  },
  {
    category: 'HighAvailability',
    displayName: 'Reliability',
    icon: 'Globe',
    details: {
      annualSavingsAmount: 0,
      highCount: 0,
      impactedResourcesCount: 0,
      lowCount: 0,
      mediumCount: 0,
      totalCount: 0
    },
    recommendations: 0
  },
  {
    category: 'OperationalExcellence',
    displayName: 'Operational Excellence',
    icon: 'Ribbon',
    details: {
      annualSavingsAmount: 0,
      highCount: 0,
      impactedResourcesCount: 0,
      lowCount: 0,
      mediumCount: 0,
      totalCount: 0
    },
    recommendations: 0
  },
  {
    category: 'Performance',
    displayName: 'Performance',
    icon: 'LineChart',
    details: {
      annualSavingsAmount: 0,
      highCount: 0,
      impactedResourcesCount: 0,
      lowCount: 0,
      mediumCount: 0,
      totalCount: 0
    },
    recommendations: 0
  }
]

const AzureAdvisor: FunctionComponent = (): JSX.Element => {
  const account = useSelector((state: RootStateOrAny) => state.account)
  const tenant = useSelector((state: RootStateOrAny) => state.tenant)

  const dispatch = useDispatch()

  const [advisorDetails, setAdvisorDetails] = useState(advisorCategories)

  useEffect(() => {
    const source = axios.CancelToken.source()

    initializeIcons()
    dispatch(startLoading())

    if (tenant.id !== '')
      api
        .get(`/soteria/account/${account.id}/tenant/${tenant.id}/azure_advisor`, { cancelToken: source.token })
        .then((response: AxiosResponse) => {
          if (response.data) {
            const d: AdvisorDetails = response.data.azureAdvisorDetails

            const advisorDetails = advisorCategories.map((c) => {
              const category: string = c.category
              const index = category as keyof typeof d.categoryDetails

              return {
                ...c,
                details: d.categoryDetails[index] || c.details,
                recommendations: d.categoryDetails[index]
                  ? d.categoryDetails[index].highCount + d.categoryDetails[index].mediumCount + d.categoryDetails[index].lowCount
                  : 0
              }
            })

            setAdvisorDetails(advisorDetails)
            dispatch(stopLoading())
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            dispatch(
              showAlert({
                type: 'error',
                message: 'We were unable to retrieve your secure score data.',
                component: 'Security',
                error
              })
            )
          }
        })
    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant])

  return (
    <Section>
      <Banner>
        <Spacer />
        <TenantDropdown />
      </Banner>
      <Spacer />
      Azure Advisor analyses your configurations and usage telemetry and offers personalised, actionable recommendations to help you optimise your Azure
      resources for reliability, security, operational excellence, performance, and cost.
      <Spacer />
      <div className="flex flex-wrap gap-8">
        {advisorDetails.map((category) => (
          <div className="w-max overflow-hidden rounded-lg border border-th-border">
            <div className="flex h-16 w-full bg-th-content-secondary px-4 text-xl">
              <div className="flex h-full items-center gap-4">
                <FontIcon iconName={category.icon} />
                <div>{category.displayName}</div>
              </div>
              <Spacer />
              {category.displayName === 'Cost' && (
                <div className="h-12 w-20 rounded-b-lg bg-th-border py-1 text-center text-sm">
                  <div className="font-bold">${category.details.annualSavingsAmount}</div>
                  <div className="text-xs">savings/year</div>
                </div>
              )}
            </div>
            <div className="border-b border-th-border p-4">
              <div className="flex items-center gap-4">
                <div className="text-2xl font-bold">{category.recommendations}</div>
                <span>Recommendations</span>
              </div>
              <div className="my-4 flex h-4 w-full overflow-hidden rounded bg-th-border">
                <div
                  style={{
                    width: (category.details.highCount / category.recommendations) * 100 + '%'
                  }}
                  className="bg-th-error"
                ></div>
                <div
                  style={{
                    width: (category.details.mediumCount / category.recommendations) * 100 + '%'
                  }}
                  className="bg-th-warning"
                ></div>
                <div
                  style={{
                    width: (category.details.lowCount / category.recommendations) * 100 + '%'
                  }}
                  className="bg-th-success"
                ></div>
              </div>
              <div className="flex items-center gap-4">
                <div className="flex w-24 items-center">
                  <div className="text-xl font-bold">{category.details.highCount}</div>
                  <span className="ml-3">High Impact</span>
                </div>
                <div className="flex w-24 items-center">
                  <div className="text-xl font-bold">{category.details.mediumCount}</div>
                  <span className="ml-3">Medium Impact</span>
                </div>
                <div className="flex w-24 items-center">
                  <div className="text-xl font-bold">{category.details.lowCount}</div>
                  <span className="ml-3">Low Impact</span>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-4 p-4">
              <div className="text-2xl font-bold">{category.details.impactedResourcesCount}</div>
              <span>Impacted Resources</span>
            </div>
          </div>
        ))}
      </div>
    </Section>
  )
}

const AzureAdvisorWithTracking = withAITracking(reactPlugin, AzureAdvisor, 'Azure Advisor')
export default AzureAdvisorWithTracking
