import useChangeApprovalQuery from '~/hooks/services/servicenow/queries/useChangeApprovalQuery'

//import useChangeQuery from '~/hooks/services/servicenow/queries/useChangeQuery'
import moment from 'moment'
import { useParams } from 'react-router-dom'

const Properties = () => {
  const { id } = useParams()
  const { data: changeApproval } = useChangeApprovalQuery(id)
  //const { data: change } = useChangeQuery(changeApproval?.change)

  return (
    <div className="space-y-6">
      <div>
        <div className="mb-1 text-th-text-secondary">Approval State</div>
        <div className="rounded-lg border border-th-border p-2 text-sm">{changeApproval?.state || 'Not Specified'}</div>
      </div>

      <div>
        <div className="mb-1 text-th-text-secondary">Approver</div>
        <div className="rounded-lg border border-th-border p-2 text-sm">{changeApproval?.approver_name || 'Not Specified'}</div>
      </div>

      {changeApproval?.approved && (
        <div>
          <div className="mb-1 text-th-text-secondary">Approved On</div>
          <div className="rounded-lg border border-th-border p-2 text-sm">
            {changeApproval?.approved ? moment(changeApproval?.approved).format('DD/MM/yy HH:mm') : 'Not yet approved'}
          </div>
        </div>
      )}

      {changeApproval?.rejected && (
        <div>
          <div className="mb-1 text-th-text-secondary">Rejected On</div>
          <div className="rounded-lg border border-th-border p-2 text-sm">
            {changeApproval?.rejected ? moment(changeApproval?.rejected).format('DD/MM/yy HH:mm') : 'Not yet approved'}
          </div>
        </div>
      )}

      <div>
        <div className="mb-1 text-th-text-secondary">Approval Notes</div>
        <div className="max-h-48 overflow-y-auto whitespace-pre-wrap break-words rounded-lg border border-th-border p-2 text-sm">
          {changeApproval?.memo || 'Not Specified'}
        </div>
      </div>
    </div>
  )
}

export default Properties
