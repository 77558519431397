import Banner from '~/components/Common/Banner'
import DateRange from '~/components/Common/DateRange/DateRange'
import RefactoredDropdown from '~/components/Common/RefactoredDropdown'

import { PERIOD_OPTIONS } from '../../constants'
import { useChangeApprovalFilters } from '../../hooks/useChangeApprovalFilters'

export const DateRangeFilter = () => {
  const { filterOptions, handlePeriodChange, handleDatesChange } = useChangeApprovalFilters()

  return (
    <Banner>
      <RefactoredDropdown
        data={PERIOD_OPTIONS.map((opt) => ({ label: opt.label, value: opt.value, checked: filterOptions.period === opt.value }))}
        label="Created"
        onChange={(option) => {
          handlePeriodChange(option.value)
        }}
        mode="simpleSelect"
      />
      <DateRange
        start={filterOptions.startDate}
        end={filterOptions.endDate}
        startDateId="start_date"
        endDateId="end_date"
        variant={DateRange.variant.DEFAULT}
        onChange={handleDatesChange}
        disabled={filterOptions.period !== 'custom'}
        focus={filterOptions.period === 'custom'}
        bordered
      />
    </Banner>
  )
}
