import React, { useRef } from 'react'

import { PiUploadSimpleBold } from 'react-icons/pi'

import Button from './Button'
import Spinner from './Spinner'

interface UploadButtonProps {
  onUpload: (files: FileList) => Promise<void> | void
  loading?: boolean
  disabled?: boolean
}

const UploadButton: React.FC<UploadButtonProps> = ({ onUpload, loading, disabled }) => {
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files.length > 0) {
      try {
        await onUpload(files)
      } finally {
        if (fileInputRef.current) {
          fileInputRef.current.value = ''
        }
      }
    }
  }

  return (
    <>
      <input ref={fileInputRef} type="file" id="file-upload" className="hidden" multiple onChange={handleFileChange} />
      <Button
        variant="primary"
        className="flex min-w-16 items-center justify-center sm:min-w-36"
        disabled={disabled || loading}
        bordered
        onClick={() => fileInputRef.current?.click()}
      >
        {loading ? (
          <>
            <Spinner className="mr-2 h-5 w-5 !border-l-th-content" />
            <span className="hidden sm:inline">Uploading</span>
          </>
        ) : (
          <>
            <PiUploadSimpleBold className="h-5 w-5 sm:mr-2" />
            <span className="hidden sm:inline">Upload Files</span>
          </>
        )}
      </Button>
    </>
  )
}

export default UploadButton
