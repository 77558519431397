import { useMemo } from 'react'

import moment from 'moment'

import { dateRangeKeys } from './dateRangeKeys'

type TotalSpend = {
  name: string | undefined
  key: string
  totalSpend: number
  values: {
    usageDate: moment.Moment
    tenantId: string
    subscriptionId: string
    totalSpendGBP: number
    resourceLocation?: string | undefined
    resourceGroup?: string | undefined
    meterCategory?: string | undefined
    forecast?: boolean | undefined
  }[]
  inceptionDate: moment.Moment
  lastContentfulDate: moment.Moment
  average: number
}

interface UseChartDataShapeConfig {
  data: TotalSpend[]
  dateRange?: [moment.Moment, moment.Moment]
  budgetTotal?: number
  cumulative?: boolean

  completeDataSetStats?: {
    totalSpend: number
    totalAverage: number
    dailySpend: {
      date: moment.Moment
      totalSpend: number
    }[]
  } | null
}

type ChartData = { date: string; values: { name: string; value: number; forecast: boolean }[]; budget?: number }[]
type ChartDataShapeOutput = { data: ChartData; periodicBudget?: number }

const useChartDataShape = ({ data, dateRange, budgetTotal, cumulative, completeDataSetStats }: UseChartDataShapeConfig): ChartDataShapeOutput => {
  // Setup Steps for Speeding things up...
  const isMonthly = useMemo(() => {
    if (!dateRange) return false

    const diff = dateRange[1].diff(dateRange[0], 'months')
    const isMonthly = diff > 2

    return isMonthly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeKeys(dateRange)])

  // 7. Compress the data into 8 entries
  const compressed = useMemo(() => {
    if (data.length <= 8) return data

    const compressed = data.slice(0, 7)
    const lastEntry = {
      key: 'Other',
      name: 'Other',
      totalSpend: 0,
      average: 0,
      inceptionDate: moment(),
      lastContentfulDate: moment(),
      values: [] as (typeof data)[0]['values']
    }

    for (let i = 7; i < data.length; i++) {
      const curr = data[i]
      lastEntry.totalSpend += curr.totalSpend
      lastEntry.average += curr.average
      lastEntry.values.push(...curr.values)
    }

    lastEntry.inceptionDate = moment.min(lastEntry.values.map((v) => v.usageDate))
    lastEntry.lastContentfulDate = moment.max(lastEntry.values.map((v) => v.usageDate))
    compressed.push(lastEntry)

    return compressed
  }, [data])

  // 4. Extend with forecast values
  const addedForecastValues = useMemo(() => {
    // Compress the datapoints from day to month if needed else just keep it to day for each entry
    const grouped = compressed.map((d) => {
      const values = d.values.reduce((acc, curr) => {
        const key = curr.usageDate.format(isMonthly ? 'MMM YYYY' : 'DD MMM')
        acc.set(key, [...(acc.get(key) ?? []), { ...curr, dateKey: key }])
        return acc
      }, new Map<string, Array<(typeof d.values)[number] & { dateKey: string }>>())

      const groupedValues = Array.from(values.entries()).map(([, values]) => {
        const totalSpendGBP = values.reduce((acc, curr) => acc + curr.totalSpendGBP, 0)
        return { ...values[0], totalSpendGBP }
      })

      return { ...d, values: groupedValues }
    })

    if (!dateRange) return grouped

    const extended = grouped.map((d) => {
      const currentDate = moment(d.inceptionDate).startOf(isMonthly ? 'month' : 'day')
      const nextDate = moment(d.inceptionDate)
        .startOf(isMonthly ? 'month' : 'day')
        .add(1, isMonthly ? 'month' : 'day')

      const increment = () => {
        currentDate.add(1, isMonthly ? 'month' : 'day')
        nextDate.add(1, isMonthly ? 'month' : 'day')
      }

      while (currentDate.isBefore(dateRange[1])) {
        const monthlyAverage = d.average * currentDate.daysInMonth()

        // Setup the keys
        const currentDateKey = currentDate.format(isMonthly ? 'MMM YYYY' : 'DD MMM')
        const nextDateKey = nextDate.format(isMonthly ? 'MMM YYYY' : 'DD MMM')

        // Check to see if forecasting must be done
        const hasNextValues = d.values.some((v) => v.dateKey === nextDateKey)

        const dateKeyFilteredValues = d.values.filter((v) => v.dateKey === currentDateKey && !v.forecast)
        const hasValues = dateKeyFilteredValues.length > 0

        // is next values time in future
        if (hasNextValues || (isMonthly && nextDate.isBefore(moment()))) {
          increment()
          continue
        }

        // Get the values for today
        const total = dateKeyFilteredValues.reduce((acc, curr) => acc + curr.totalSpendGBP, 0)

        // Calculate the forecast value
        const currentDifference = isMonthly ? monthlyAverage - total : d.average - total
        const forecastValue = hasValues ? currentDifference : isMonthly ? monthlyAverage : d.average

        // Add the forecast value to the values array
        d.values.push({ ...d.values[0], dateKey: currentDateKey, totalSpendGBP: Math.max(forecastValue, 0), forecast: true, usageDate: moment(currentDate) })

        // Increment the dates
        increment()
      }

      return { ...d }
    })

    if (!completeDataSetStats) return extended

    // console.log('COMPLETE DATA SET STATS: ', completeDataSetStats)

    // -~= SO WHAT IS HAPPENING HERE =~-
    // Some of the data has not come in yet, because a resource was cancelled
    // This means I am forecasting a higher number (estimating it will come in, but it doesn't, right)
    // So for each date, I need to get all the forecast values and see what combination of them on that day
    // would account for the difference between the totalOnThatDay from the complete data set and the totalOnThatDay from the current data set
    // Then I need to remove those values from the forecast array or set their value to 0, which the latter is probably better
    // Now follows the implementation of that:

    const dates = [] as moment.Moment[]
    for (const startDate = dateRange[0].clone(); startDate?.isSameOrBefore(dateRange[1]); startDate.add(1, isMonthly ? 'month' : 'day'))
      dates.push(startDate.clone())

    type FindCombinationToZeroValues = Array<TotalSpend['values'][number] & { key: string; dateKey: string }>
    function findCombinationToZero(forecastValues: FindCombinationToZeroValues, target: number): FindCombinationToZeroValues | null {
      // Base case for approximate match within ±0.5
      if (Math.abs(target) <= 0.001) return []

      for (let i = 0; i < forecastValues.length; i++) {
        // Check if adding the current value gets closer to the target within the acceptable range
        let newTarget = target - forecastValues[i].totalSpendGBP
        if (Math.abs(newTarget) <= 0.001) {
          // If within range, include this value and return
          return [forecastValues[i]]
        } else if (newTarget > 0) {
          // Only continue searching if the new target is positive
          let result = findCombinationToZero(forecastValues.slice(i + 1), newTarget)
          if (result !== null) {
            // Include this value in the result set
            return result.concat(forecastValues[i])
          }
        }
      }
      return null
    }

    // Loop through every date, get the total on that day and get the total from all the points and adjust the thing to the completeDataSetAverage
    for (const date of dates) {
      const dateKey = date.format(isMonthly ? 'MMM YYYY' : 'DD MMM')
      const valuesForCurrentDay = extended.flatMap((d) => d.values.filter((v) => v.dateKey === dateKey).map((v) => ({ ...v, name: d.name, key: d.key })))
      const forecastValuesForCurrentDay = valuesForCurrentDay.filter((v) => v.forecast).map((v) => ({ ...v, totalSpendGBP: v.totalSpendGBP }))
      if (!forecastValuesForCurrentDay.length) continue

      const totalOnThisDay = completeDataSetStats.dailySpend.find((d) => moment(d.date).isSame(date, isMonthly ? 'month' : 'day'))?.totalSpend ?? 0
      const actualAverage = isMonthly ? completeDataSetStats.totalAverage * date.daysInMonth() : completeDataSetStats.totalAverage

      const currentDayTotal = valuesForCurrentDay.reduce((acc, curr) => acc + curr.totalSpendGBP, 0)
      const hasRealizedValuesTomorrow =
        extended.flatMap((d) =>
          d.values.filter(
            (v) =>
              v.dateKey ===
                date
                  .clone()
                  .add(1, isMonthly ? 'month' : 'day')
                  .format(isMonthly ? 'MMM YYYY' : 'DD MMM') && !v.forecast
          )
        ).length > 0
      const referenceValue = hasRealizedValuesTomorrow ? totalOnThisDay : actualAverage

      const difference = currentDayTotal - referenceValue

      const referenceDifference = difference
      forecastValuesForCurrentDay.sort((a, b) => b.totalSpendGBP - a.totalSpendGBP)

      const valuesToZero = findCombinationToZero(forecastValuesForCurrentDay, referenceDifference)

      // Use the returned values to update the extended array
      if (valuesToZero) {
        for (const value of valuesToZero) {
          let extendedItem = extended.find((e) => e.key === value.key)

          let valueItems = extendedItem?.values.filter((v) => value.usageDate.isSame(v.usageDate) && v.forecast)

          for (const valueItem of valueItems ?? []) {
            valueItem.totalSpendGBP -= value.totalSpendGBP
          }
        }
      }

      if (!valuesToZero?.length && difference > 0.001) {
        let other = extended.find((e) => e.key === 'Other')
        let otherValue = other?.values.find((v) => v.dateKey === dateKey && v.forecast)

        let forecastValues = extended.flatMap((d) => d.values).filter((v) => v.dateKey === dateKey && v.forecast) ?? []

        let stillToDistribute = difference
        if (otherValue && otherValue.totalSpendGBP > difference) otherValue.totalSpendGBP -= difference
        else {
          for (const forecastValue of forecastValues) {
            const canDistibute = Math.min(stillToDistribute, forecastValue.totalSpendGBP)
            forecastValue.totalSpendGBP -= canDistibute
            stillToDistribute -= canDistibute
          }
        }
      }
    }

    return extended
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeKeys(dateRange), isMonthly, compressed, completeDataSetStats])

  // 5. Extend with 0 values for missing dates
  const addedMissingDates = useMemo(() => {
    if (!dateRange) return addedForecastValues

    const extended = addedForecastValues.map((d) => {
      const currentDate = moment(dateRange[0])

      while (currentDate.isBefore(dateRange[1])) {
        // Setup the keys
        const currentDateKey = currentDate.format(isMonthly ? 'MMM YYYY' : 'DD MMM')

        const hasForecastValueToday = d.values.some((v) => v.dateKey === currentDateKey && v.forecast)
        const hasRealizedValueToday = d.values.some((v) => v.dateKey === currentDateKey && !v.forecast)

        if (!hasForecastValueToday) d.values.push({ ...d.values[0], dateKey: currentDateKey, totalSpendGBP: 0, forecast: true, usageDate: moment(currentDate) })
        if (!hasRealizedValueToday)
          d.values.push({ ...d.values[0], dateKey: currentDateKey, totalSpendGBP: 0, forecast: false, usageDate: moment(currentDate) })

        currentDate.add(1, isMonthly ? 'month' : 'day')
      }

      d.values.sort((a, b) => a.usageDate.diff(b.usageDate))

      return d
    })

    return extended
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedForecastValues, dateRangeKeys(dateRange), isMonthly])

  // -~= HERE WE HAVE A COMPLETE LIST OF ALL THE DATA =~-
  // 6. Break up forecast values and realized values into separate entries
  const splitForecastAndRealized = useMemo(() => {
    const extended = addedMissingDates.map((d) => {
      const forecast = d.values.filter((v) => v.forecast)
      const realized = d.values.filter((v) => !v.forecast)

      return { ...d, values: { forecast, realized } }
    })

    return extended
  }, [addedMissingDates])

  const periodicBudget = useMemo(() => {
    if (!budgetTotal || !dateRange) return undefined

    const months = dateRange[1].diff(dateRange[0], 'months')
    return budgetTotal ? (months > 2 ? budgetTotal : budgetTotal / moment().daysInMonth()) : undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetTotal, dateRangeKeys(dateRange)])

  // 8. Unpack the data for one key into two arrays
  const unpackedForecastAndRealized = useMemo(() => {
    const unpacked = splitForecastAndRealized.flatMap((d) => {
      const forecast = d.values.forecast.map((v) => ({ ...v, budget: periodicBudget }))
      const realized = d.values.realized.map((v) => ({ ...v, budget: periodicBudget }))

      return [
        { ...d, values: realized },
        { ...d, values: forecast }
      ]
    })

    if (!cumulative) return unpacked

    const lastContentfulDay = moment.max(unpacked.map((d) => d.lastContentfulDate)).startOf('day')

    const cumulativeData = unpacked.map((d) => {
      const values = d.values.map((v, index) => {
        const previousValues = d.values.slice(0, index + 1)
        const total = previousValues.reduce((acc, curr) => acc + curr.totalSpendGBP, 0)
        const totalBudget = previousValues.reduce((acc, curr) => acc + curr.budget!, 0)

        // If the total realized is 0, then
        const isAfterLastContentfulDay = v.usageDate.startOf('day').isAfter(lastContentfulDay)

        return { ...v, totalSpendGBP: total, budget: totalBudget, forecast: isAfterLastContentfulDay ? true : (v.forecast ?? false) }
      })

      return { ...d, values }
    })

    return cumulativeData
  }, [splitForecastAndRealized, cumulative, periodicBudget])

  // 9. Reshape it to have date as the main object identifier and the rest as values
  const reshapedData = useMemo((): ChartData => {
    if (!dateRange) return []

    const dates = [] as moment.Moment[]
    for (const startDate = dateRange?.[0].clone(); startDate?.isBefore(dateRange[1]); startDate.add(1, isMonthly ? 'month' : 'day'))
      dates.push(startDate.clone())

    const output = dates.map((date) => {
      const values = unpackedForecastAndRealized.flatMap((d) =>
        d.values.filter((v) => moment(v.usageDate).isSame(date, isMonthly ? 'month' : 'day')).map((v) => ({ ...v, name: d.name, key: d.key }))
      )

      const mappedValues = values.map((v) => ({ name: v.name ?? v.key, value: v.totalSpendGBP, forecast: v.forecast ?? false }))

      return { date: isMonthly ? date.format('MMM YYYY') : date.format('DD MMM'), values: mappedValues, budget: values[0]?.budget }
    })

    return output
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unpackedForecastAndRealized, dateRangeKeys(dateRange), isMonthly])

  return {
    data: data.length ? reshapedData : [],
    periodicBudget
  }
}

export default useChartDataShape
