import React from 'react'

import { classNames } from 'src/utils/classNames'

import Popover from './Popover'
import Spinner from './Spinner'

interface IconButtonProps {
  onClick: () => void
  disabled?: boolean
  icon: React.ReactNode
  isLoading?: boolean
  className?: string
  tooltip?: string
}

const IconButton: React.FC<IconButtonProps> = ({ onClick, disabled, icon, isLoading, className, tooltip }) => {
  return (
    <Popover text={tooltip || ''} disabled={!tooltip}>
      <button
        onClick={onClick}
        disabled={disabled}
        className={classNames('rounded-md border border-th-border bg-th-content p-2 hover:bg-th-content-secondary', className)}
      >
        {isLoading ? <Spinner className="h-5 w-5" /> : icon}
      </button>
    </Popover>
  )
}

export default IconButton
