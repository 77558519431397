import type { UseBaseQueryOptions } from '@tanstack/react-query'

import { showAlert } from 'src/actions/alertsActions'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useQuery from 'src/hooks/utils/useQuery'

import { useDispatch, useSelector } from 'react-redux'
import { type FolderState } from 'src/reducers/folderReducer'

export interface FileMetadata {
  id: string
  accountId: string
  userId: string
  deletionDeadline: string
  fileName: string
  contentLength: number
  status: string
  contentType: string
  createdAt: string
  folderId: string | null
  path: string
  username: string
}

export interface Folder {
  id: string
  accountId: string
  name: string
  parentId: string | null
  path: string
  createdAt: string
  createdFromConfigId: string | null
}

interface UseFilesQueryParams {
  query?: string | null
  folderId?: string | null
}

interface FilesQueryResult {
  files: FileMetadata[]
  folders: Folder[]
  limitedView: boolean
  correctAccount: boolean
}

const useFilesQuery = ({ query, folderId = null }: UseFilesQueryParams, options?: UseBaseQueryOptions<FilesQueryResult>) => {
  const account = useAccount()
  const dispatch = useDispatch()
  const search = useSelector((state: FolderState) => state.folder.search)

  const searchTerm = query === undefined ? search || null : query || null

  return useQuery(
    ['getFilesQuery', searchTerm, folderId || null, account.id],
    async ({ signal }) => {
      const response = await api.get<FilesQueryResult>(`/provide/${account.id}/file/getAll`, {
        signal,
        params: { query: searchTerm, folderId: folderId || null }
      })

      if (response.data.correctAccount === false)
        dispatch(
          showAlert({ message: 'Wrong account selected for the current folder. Please check your account selection.', type: 'error', component: 'alert' })
        )

      return response.data
    },
    {
      enabled: !!account.id,
      refetchOnWindowFocus: false,
      ...options
    }
  )
}

export default useFilesQuery
