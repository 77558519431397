import { type AZServiceCatalogEntry, type Entitlement, type Recommendation } from '../types/azure'
import { type Contact } from '../types/contact'
import { type CreditNote } from '../types/creditnote'
import { type Invoice } from '../types/invoice'
import { type Order } from '../types/order'
import { type Quote } from '../types/quote'
import { type Service } from '../types/service'
import { type Ticket } from '../types/ticket'

/* ticket */
export const openTicketDrawer = (
  ticket: Ticket
): {
  type: string
  payload: Record<string, Ticket>
} => {
  return {
    type: 'OPEN_TICKET_DRAWER',
    payload: { ticket: ticket }
  }
}

/* new ticket */
export const openNewTicketDrawer = (): {
  type: string
  payload: Record<string, boolean>
} => {
  return {
    type: 'OPEN_NEW_TICKET_DRAWER',
    payload: { new_ticket: true }
  }
}

/* new azure plan */
export const openNewAzurePlanDrawer = (): {
  type: string
  payload: Record<string, boolean>
} => {
  return {
    type: 'OPEN_NEW_AZURE_PLAN_DRAWER',
    payload: { new_azure_plan: true }
  }
}

export const openRequestQuoteDrawer = (): {
  type: string
  payload: Record<string, boolean>
} => {
  return {
    type: 'OPEN_REQUEST_QUOTE_DRAWER',
    payload: { request_quote: true }
  }
}

export const openChangeAccountDetailsDrawer = (): {
  type: string
  payload: Record<string, boolean>
} => {
  return {
    type: 'OPEN_CHANGE_ACCOUNT_DETAILS_DRAWER',
    payload: { change_account_details: true }
  }
}

export const openFeedbackDrawer = (): {
  type: string
  payload: Record<string, boolean>
} => {
  return {
    type: 'OPEN_FEEDBACK_DRAWER',
    payload: { feedback: true }
  }
}

/* service */
export const openServiceDrawer = (
  service: Service
): {
  type: string
  payload: Record<string, Service>
} => {
  return {
    type: 'OPEN_SERVICE_DRAWER',
    payload: { service: service }
  }
}

export const openCancelServiceDrawer = (
  service: Service
): {
  type: string
  payload: Record<string, Service>
} => {
  return {
    type: 'OPEN_REQUEST_QUOTE_DRAWER',
    payload: { cancel_service: service }
  }
}

/* order */
export const openOrderDrawer = (
  order: Order
): {
  type: string
  payload: Record<string, Order>
} => {
  return {
    type: 'OPEN_ORDER_DRAWER',
    payload: { order: order }
  }
}

/* new contact */
export const openNewContactDrawer = (): {
  type: string
  payload: Record<string, boolean>
} => {
  return {
    type: 'OPEN_NEW_CONTACT_DRAWER',
    payload: { new_contact: true }
  }
}

/* close all drawers */
export const closeDrawers = (): {
  type: string
} => {
  return {
    type: 'CLOSE_DRAWERS'
  }
}

/* credit note*/
export const openCreditNoteDrawer = (
  creditnote: CreditNote
): {
  type: string
  payload: Record<string, CreditNote>
} => {
  return {
    type: 'OPEN_CREDITNOTE_DRAWER',
    payload: { creditnote: creditnote }
  }
}

/* quote */
export const openQuoteDrawer = (
  quote: Quote
): {
  type: string
  payload: Record<string, Quote>
} => {
  return {
    type: 'OPEN_QUOTE_DRAWER',
    payload: { quote: quote }
  }
}

/* profile */
export const openProfileDrawer = (
  profile: Contact
): {
  type: string
  payload: Record<string, Contact>
} => {
  return {
    type: 'OPEN_PROFILE_DRAWER',
    payload: { profile: profile }
  }
}

/* invoice */
export const openInvoiceDrawer = (
  invoice: Invoice
): {
  type: string
  payload: Record<string, Invoice>
} => {
  return {
    type: 'OPEN_INVOICE_DRAWER',
    payload: { invoice: invoice }
  }
}

/* reserved instance */
export const openReservedInstanceDrawer = (
  reserved_instance: string
): {
  type: string
  payload: Record<string, string | boolean>
} => {
  return {
    type: 'OPEN_RESERVED_INSTANCE_DRAWER',
    payload: {
      reserved_instance: reserved_instance,
      software_subscription: false
    }
  }
}

/* software subscription */
export const openSoftwareSubscriptionDrawer = (
  software_subscription: string
): {
  type: string
  payload: Record<string, string | boolean>
} => {
  return {
    type: 'OPEN_SOFTWARE_SUBSCRIPTION_DRAWER',
    payload: {
      software_subscription: software_subscription,
      reserved_instance: false
    }
  }
}

/* m365 secure score info */
export const openBookSecurityReviewDrawer = (
  type: string
): {
  type: string
  payload: Record<string, string>
} => {
  return {
    type: 'OPEN_BOOK_SECURITY_REVIEW_DRAWER',
    payload: {
      book_security_review: type
    }
  }
}

/* m365 secure score info */
export const openM365SecureScoreInfoDrawer = (): {
  type: string
  payload: Record<string, boolean>
} => {
  return {
    type: 'OPEN_M365_SECURE_SCORE_INFO_DRAWER',
    payload: {
      m365_secure_score_info: true
    }
  }
}

/* quote */
export const openRecommendationDrawer = (
  recommendation: Recommendation
): {
  type: string
  payload: Record<string, Recommendation>
} => {
  return {
    type: 'OPEN_RECOMMENDATION_DRAWER',
    payload: { recommendation: recommendation }
  }
}

/* azure deployment */
export const openAzureDeploymentDrawer = (
  deployment: AZServiceCatalogEntry
): {
  type: string
  payload: Record<string, AZServiceCatalogEntry>
} => {
  return {
    type: 'OPEN_AZURE_DEPLOYMENT_DRAWER',
    payload: { new_azure_deployment: deployment }
  }
}

/* connect to Azure LightHouse */
export const openLightHouseConnectionDrawer = (
  entitlement: Entitlement
): {
  type: string
  payload: Record<string, Entitlement>
} => {
  return {
    type: 'OPEN_LIGHTHOUSE_CONNECTION_DRAWER',
    payload: { azure_lighthouse_connect: entitlement }
  }
}
