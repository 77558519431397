import { type Account } from '../types/account'

export const setAccount = (
  account: Account
): {
  type: string
  payload: Account
} => {
  return {
    type: 'SET_ACCOUNT',
    payload: account
  }
}
