import { type ColumnDef } from '@tanstack/react-table'

import React, { useMemo } from 'react'

import type { FeatureFlag } from 'src/hooks/services/provide/queries/useAdminFeatureFlagQuery'

import Table from 'src/components/Common/Table/Table'

import { classNames } from 'src/utils/classNames'

import moment from 'moment'
import { PiPencilLine } from 'react-icons/pi'

interface AdminFeatureFlagsTableProps {
  flags: FeatureFlag[]
  onEditFlag: (flag: FeatureFlag) => void
}

const AdminFeatureFlagsTable: React.FC<AdminFeatureFlagsTableProps> = ({ flags, onEditFlag }) => {
  const columns = useMemo<ColumnDef<FeatureFlag>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name'
      },
      {
        accessorKey: 'description',
        header: 'Description'
      },
      {
        accessorKey: 'enabled',
        header: 'Enabled',
        cell: (info) => <div className="text-center">{info.getValue() ? 'Yes' : 'No'}</div>
      },
      {
        accessorKey: 'accountIds',
        header: 'Accounts',
        cell: (info) => <div className="text-center">{info.getValue<string[]>().length || 'All'}</div>
      },
      {
        accessorKey: 'updatedAt',
        header: 'Last Updated',
        cell: (info) => <div className="text-center">{moment(info.getValue<string>()).format('DD/MM/YY HH:mm')}</div>
      },
      {
        id: 'edit',
        header: 'Edit',
        cell: (info) => (
          <PiPencilLine
            className={classNames('h-5 w-5', info.row.original.id.length ? 'cursor-pointer' : 'cursor-progress opacity-50')}
            onClick={() => {
              if (!info.row.original.id.length) return
              onEditFlag(info.row.original)
            }}
          />
        )
      }
    ],
    [onEditFlag]
  )

  return <Table data={flags} columns={columns} bordered />
}

export default AdminFeatureFlagsTable
