import { Fragment, type FunctionComponent } from 'react'

import { type RootStateOrAny, useSelector } from 'react-redux'
import { Route, Routes } from 'react-router'
import Scrollbar from 'react-smooth-scrollbar'

/* utils */
import { classNames } from '../../utils/classNames'
import Login from '../../views/Login'

/* views */
import SignQuote from '../../views/SignQuote'
import Navigation from '../Navigation/Navigation'
import Header from './Header/Header'

const UnauthenticatedContent: FunctionComponent = (): JSX.Element => {
  const loading = useSelector((state: RootStateOrAny) => state.loading.loading)

  return (
    <Routes>
      <Route
        path="/sign_quote"
        element={
          <Fragment>
            <Navigation />
            <div
              className={classNames(
                'h-full w-full overflow-hidden bg-th-content',
                'flex flex-col text-th-text md:rounded-tl-content',
                loading && 'disable-scroll'
              )}
            >
              <Header />
              <Scrollbar className="flex-auto">
                <div id="signQuoteContent">
                  <SignQuote standAlone={true} />
                </div>
              </Scrollbar>
            </div>
          </Fragment>
        }
      />
      <Route path="*" element={<Login />} />
    </Routes>
  )
}

export default UnauthenticatedContent
