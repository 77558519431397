import { type FunctionComponent } from 'react'

import { useNavigate } from 'react-router'

import Button from '../../../components/Common/Button'

/* utils */
import { classNames } from '../../../utils/classNames'

interface Props {
  className: string
}

const Unauthorised: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { className } = props

  const navigate = useNavigate()

  return (
    <div className={classNames('absolute left-0 -mt-1 flex items-center', 'justify-center bg-th-content opacity-90', className)}>
      <div className="flex flex-col items-center gap-4">
        <div>You do not have permission to view this data.</div>
        <Button bordered onClick={() => navigate('/profile')}>
          View Permissions
        </Button>
      </div>
    </div>
  )
}

export default Unauthorised
