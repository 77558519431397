import { Shimmer, ShimmerElementType, ShimmerElementsGroup, createTheme } from '@fluentui/react'

import { type FunctionComponent } from 'react'

import { type AZServiceCatalogEntry } from '../../../types/azure'

import { Iconly } from 'react-iconly'
import SmoothScrollbar from 'smooth-scrollbar'

import Tile from '../../../components/Common/Tile'

interface Props {
  deployment: AZServiceCatalogEntry
  onClick: () => void
}

/* product loading shimmer */
const shimmerElements = (): JSX.Element => {
  return (
    <div style={{ display: 'flex' }}>
      {/* each shimmer elements container matches the height of the tallest container as they are wrapped.
        This means the gaps between wrapped shimmer elements is determnined by setting the height value
        relative to the height of the tallest element */}
      <ShimmerElementsGroup
        flexWrap
        width="100%"
        shimmerElements={[
          /* title */
          { type: ShimmerElementType.line, width: '100%', height: 40 },
          /* id */
          { type: ShimmerElementType.line, width: '50%', height: 20 },
          { type: ShimmerElementType.gap, width: '50%', height: 20 },
          /* description */
          { type: ShimmerElementType.line, width: '100%', height: 40 },
          { type: ShimmerElementType.line, width: '100%', height: 40 },
          { type: ShimmerElementType.line, width: '100%', height: 40 },
          { type: ShimmerElementType.line, width: '100%', height: 40 },
          { type: ShimmerElementType.line, width: '100%', height: 40 },
          /* promotion */
          { type: ShimmerElementType.line, width: '50%', height: 20 },
          { type: ShimmerElementType.gap, width: '50%', height: 20 }
        ]}
        backgroundColor="var(--content)"
      />
    </div>
  )
}

const AzureDeplyoment: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { deployment } = props

  /* shimmer theme */
  const customThemeForShimmer = createTheme({
    palette: {
      themeSecondary: 'var(--translucent)' /* shimmer wave */,
      themeTertiary: 'var(--lowlight)' /* shimmer background */
    }
  })

  const handleClick = () => {
    props.onClick()

    /* reset scrollbar */
    const scrollbar = document.getElementsByClassName('scrollbar')[0] as HTMLElement
    if (scrollbar) SmoothScrollbar.get(scrollbar)?.setPosition(0, 0)
  }

  return (
    <Tile className="relative cursor-pointer text-th-text hover:shadow">
      <Shimmer
        customElementsGroup={shimmerElements()}
        shimmerColors={{
          shimmer: customThemeForShimmer.palette.themeTertiary,
          shimmerWave: customThemeForShimmer.palette.themeSecondary
        }}
        isDataLoaded={deployment !== undefined}
        className="relative h-full"
      >
        {deployment && (
          <div className="h-full">
            <div className="flex flex-col gap-4" onClick={() => handleClick()}>
              <div className="flex justify-between gap-4">
                <div>
                  {/* title */}
                  <div className="line-clamp-2 h-14 text-lg font-bold">{deployment.resource_type}</div>
                  {/* id */}
                  <div className="text-sm text-th-text-secondary">{deployment.type}</div>
                </div>
                {/* view */}
                <div className="flex h-8 w-8 flex-none items-center justify-center rounded-full bg-th-content-secondary">
                  <Iconly name="ChevronRight" set="light" className="h-5 w-5" />
                </div>
              </div>
              {/* description */}
              <div className="mb-4 line-clamp-6 h-32">{deployment.short_description}</div>
            </div>
          </div>
        )}
      </Shimmer>
    </Tile>
  )
}

export default AzureDeplyoment
