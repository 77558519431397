import { useQueryClient } from '@tanstack/react-query'

import { useCallback, useEffect, useMemo, useState } from 'react'

import { showAlert } from 'src/actions/alertsActions'
import { addUpload, completeUpload, removeUpload, setUploadError, updateUploadProgress } from 'src/actions/uploadActions'

import Input from 'src/components/Common/Input'
import MultiStepModal from 'src/components/Common/MultiStepModal'

import axios from 'axios'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { type FolderState } from 'src/reducers/folderReducer'

import useDeleteFileMutation from '../services/provide/mutations/useDeleteFileMutation'
import useUploadSasUriMutation from '../services/provide/mutations/useUploadSasUriMutation'
import useFilesQuery, { type FileMetadata } from '../services/provide/queries/useFilesQuery'
import useAccount from './useAccount'
import useOwnUser from './useOwnUser'

interface UploadResponse {
  sasUri: string
  documentId: string
  folderId: string
}

interface UploadHookResult {
  uploadFiles: (files: FileList) => void
  uploadProgress: number | null
  isUploading: boolean
  uploadedFiles: FileMetadata[]
  UploadConfirmationModal: React.ReactNode
}

const REFETCH_INTERVAL = 2000 // 2 seconds

interface Step {
  title: string
  component: () => React.ReactNode
}

const useFileUpload = ({ onComplete }: { onComplete?: (newFiles: FileMetadata[]) => void }): UploadHookResult => {
  const account = useAccount()
  const [uploadProgress, setUploadProgress] = useState<number | null>(null)
  const [isUploading, setIsUploading] = useState(false)

  const uploads = useSelector((state: RootStateOrAny) => state.upload?.uploads || {})

  const queryClient = useQueryClient()
  const user = useOwnUser()
  const dispatch = useDispatch()

  const folderId = useSelector((state: FolderState) => state.folder.currentFolderId)
  const search = useSelector((state: FolderState) => state.folder.search)
  const folderPath = useSelector((state: FolderState) => state.folder.folderPath)

  const [customFolderId, setCustomFolderId] = useState<string | null>(null)
  const filesQuery = useFilesQuery(
    { folderId: (customFolderId ?? folderId) || null },
    { refetchInterval: isUploading ? REFETCH_INTERVAL : false, refetchOnWindowFocus: false, enabled: isUploading && !!account.id }
  )

  const [modalState, setModalState] = useState<{
    isOpen: boolean
    step: number
    files: File[]
    customerName: string
    uploadLocation: string
    filesToReplace: Record<string, boolean>
  }>({
    isOpen: false,
    step: 1,
    files: [],
    customerName: '',
    uploadLocation: '',
    filesToReplace: {}
  })

  const uploadMutation = useUploadSasUriMutation()
  const deleteFileMutation = useDeleteFileMutation()
  //const notifyFileUploadMutation = useNotifyFileUploadMutation()

  const uploadToBlob = useCallback(
    async (sasUri: string, file: File, uploadId: string) => {
      await axios.put(sasUri, file, {
        headers: {
          'x-ms-blob-type': 'BlockBlob',
          'Content-Type': file.type
        },
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total) {
            const progress = Math.min(Math.round((progressEvent.loaded * 100) / progressEvent.total), 100)
            dispatch(updateUploadProgress(uploadId, progress))
          }
        }
      })

      dispatch(updateUploadProgress(uploadId, 100))
    },
    [dispatch]
  )

  const uploadFiles = useCallback(
    async (files: File[]) => {
      setIsUploading(true)
      const uploadPromises: Promise<UploadResponse>[] = []
      const uploadIds: string[] = []

      for (const file of files) {
        if (user?.super_admin) {
          const existingFile = filesQuery.data?.files.find((f) => f.fileName === file.name)
          const shouldReplace = existingFile && modalState.filesToReplace[file.name]

          if (existingFile && !shouldReplace) continue
          else if (existingFile && shouldReplace) await deleteFileMutation.mutateAsync({ fileId: existingFile.id })
        }

        const uploadPromise = uploadMutation.mutateAsync({
          file,
          folderId: user?.super_admin ? folderId : null
        })
        uploadPromises.push(uploadPromise)
      }

      if (uploadPromises.length === 0) {
        setIsUploading(false)
        return
      }

      try {
        const results = await Promise.all(uploadPromises)

        // Batch add uploads
        const uploadsToAdd = results.reduce(
          (acc, result, index) => {
            acc[result.documentId] = files[index].name
            uploadIds.push(result.documentId)
            return acc
          },
          {} as Record<string, string>
        )

        Object.entries(uploadsToAdd).forEach(([id, fileName]) => dispatch(addUpload(id, fileName)))

        // Perform actual uploads
        await Promise.all(results.map((result, index) => uploadToBlob(result.sasUri, files[index], result.documentId)))

        setCustomFolderId(results[0]?.folderId)
        queryClient.invalidateQueries(['getFilesQuery', search || null, results[0]?.folderId || null, account.id])

        // Batch complete uploads
        uploadIds.forEach((id) => dispatch(completeUpload(id)))
      } catch (error) {
        // Batch set upload errors
        uploadIds.forEach((id) => dispatch(setUploadError(id)))
        console.error('Upload failed:', error)

        setTimeout(() => {
          dispatch(showAlert({ message: 'An error occurred while uploading files.', type: 'error', component: 'FileUpload' }))
          uploadIds.forEach((id) => dispatch(removeUpload(id)))
        }, 3000)

        setIsUploading(false)
      }
    },
    [
      account.id,
      deleteFileMutation,
      dispatch,
      filesQuery.data?.files,
      folderId,
      modalState.filesToReplace,
      queryClient,
      search,
      uploadMutation,
      uploadToBlob,
      user?.super_admin
    ]
  )

  const openConfirmationModal = (fileList: FileList) => {
    const filesArray = Array.from(fileList)
    const uploadLocation = user?.super_admin ? 'current folder' : 'uploads folder'
    setModalState({
      isOpen: true,
      step: 1,
      files: filesArray,
      customerName: '',
      uploadLocation,
      filesToReplace: {}
    })
  }

  const closeConfirmationModal = () => {
    setModalState((prevState) => ({ ...prevState, isOpen: false }))
  }

  const handleConfirmUpload = () => {
    if (modalState.files) {
      uploadFiles(modalState.files)
      closeConfirmationModal()
    }
  }

  const handleNextStep = () => {
    setModalState((prevState) => ({ ...prevState, step: prevState.step + 1 }))
  }

  const handlePreviousStep = () => {
    setModalState((prevState) => ({ ...prevState, step: prevState.step - 1 }))
  }

  const handleToggleReplace = (fileName: string) => {
    setModalState((prevState) => ({
      ...prevState,
      filesToReplace: {
        ...prevState.filesToReplace,
        [fileName]: !prevState.filesToReplace[fileName]
      }
    }))
  }

  const uploadedFiles = useMemo(() => filesQuery.data?.files?.filter((file) => Object.keys(uploads).includes(file.id)) ?? [], [filesQuery.data?.files, uploads])

  useEffect(() => {
    const hasContent = uploadedFiles.length > 0
    const allUploaded = uploadedFiles.every((file) => file.status === 'active' || file.status === 'error')
    const allFilesUploaded = uploadedFiles.length === Object.keys(uploads).length

    if (hasContent && allUploaded && allFilesUploaded) {
      uploadedFiles.forEach((file) => dispatch(removeUpload(file.id)))

      setUploadProgress(null)
      setCustomFolderId(null)
      setIsUploading(false)

      const uploadLocation = user?.super_admin ? 'the current folder' : 'your uploads folder'
      dispatch(showAlert({ message: `${uploadedFiles.length} file(s) uploaded successfully to ${uploadLocation}`, type: 'success', component: 'FileUpload' }))

      if (onComplete) onComplete(uploadedFiles)
    }
  }, [uploadedFiles, uploads, dispatch, user, onComplete])

  const getSteps = useCallback((): Step[] => {
    const steps: Step[] = [
      {
        title: 'Review Upload',
        component: () => {
          return (
            <div className="space-y-4">
              <p>
                You are about to upload files to the <b>{folderPath.at(-1)?.name}</b> folder for the customer <b>{account.name}</b>.
              </p>
              <p>
                Files to be uploaded ({modalState.files.length} {modalState.files.length === 1 ? 'file' : 'files'}):
              </p>

              <ul className="max-h-40 space-y-1 overflow-y-auto rounded-lg border border-th-border p-1">
                {modalState.files.map((file, index) => {
                  const fileToReplace = filesQuery.data?.files.some((existingFile) => existingFile.fileName === file.name)

                  return (
                    <li key={index} className="flex items-center justify-between gap-1 rounded-md border border-th-border bg-th-content px-2 py-1 text-sm">
                      <span className="max-w-full grow truncate">{file.name}</span>
                      {fileToReplace && user?.super_admin && (
                        <>
                          <button
                            disabled={!modalState.filesToReplace[file.name]}
                            onClick={() => handleToggleReplace(file.name)}
                            className={`rounded-md border px-2 py-1 ${
                              !modalState.filesToReplace[file.name] ? 'border-th-primary text-th-primary' : 'border-th-border text-th-text-secondary'
                            }`}
                          >
                            Skip
                          </button>
                          <button
                            disabled={modalState.filesToReplace[file.name]}
                            onClick={() => handleToggleReplace(file.name)}
                            className={`rounded-md border px-2 py-1 ${
                              modalState.filesToReplace[file.name] ? 'border-th-primary text-th-primary' : 'border-th-border text-th-text-secondary'
                            }`}
                          >
                            Replace
                          </button>
                        </>
                      )}
                    </li>
                  )
                })}
              </ul>
            </div>
          )
        }
      }
    ]

    steps.push({
      title: 'Confirm Customer',
      component: () => (
        <div className="space-y-4">
          <p>
            Please confirm that you are uploading to the correct customer by typing their name <b>{account.name}</b> below:
          </p>
          <Input
            value={modalState.customerName}
            onChange={(e) => setModalState((prevState) => ({ ...prevState, customerName: e.target.value }))}
            placeholder={account.name}
          />
        </div>
      )
    })

    return steps
  }, [folderPath, account.name, modalState.files, modalState.filesToReplace, modalState.customerName, filesQuery.data?.files, user?.super_admin])

  const steps = getSteps()

  const renderModalContent = () => {
    const currentStep = steps[modalState.step - 1]
    return currentStep ? currentStep.component() : null
  }

  const getStepTitle = (step: number) => {
    return steps[step - 1]?.title || ''
  }

  const UploadConfirmationModal = (
    <MultiStepModal
      isOpen={modalState.isOpen}
      onClose={closeConfirmationModal}
      onConfirm={handleConfirmUpload}
      title="File Upload Confirmation"
      stepTitle={getStepTitle(modalState.step)}
      isLoading={isUploading}
      disabled={modalState.step === steps.length && modalState.customerName.toLowerCase() !== account.name.toLowerCase()}
      currentStep={modalState.step}
      totalSteps={steps.length}
      onNext={handleNextStep}
      onPrevious={handlePreviousStep}
    >
      {renderModalContent()}
    </MultiStepModal>
  )

  return {
    uploadFiles: (files: FileList) => (user?.super_admin ? openConfirmationModal(files) : void uploadFiles(Array.from(files))),
    uploadProgress,
    isUploading,
    uploadedFiles,
    UploadConfirmationModal
  }
}

export default useFileUpload
