import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useMutation, { type UseMutationOptions } from 'src/hooks/utils/useMutation'

interface CreateUploadIncidentInput {
  fileIds: string[]
}

interface CreateUploadIncidentResponse {
  ticketId: string
}

const useCreateUploadIncidentMutation = (options?: UseMutationOptions<CreateUploadIncidentInput>) => {
  const account = useAccount()

  return useMutation<CreateUploadIncidentResponse, any, CreateUploadIncidentInput>(
    async (input) => {
      const response = await api.post(`/provide/${account.id}/file/createIncident`, {
        fileIds: input.fileIds
      })
      return response.data
    },
    {
      ...options
    }
  )
}

export default useCreateUploadIncidentMutation
