const initialState = {
  initialized: false
}

const initReducer = (
  state = initialState,
  action: {
    type: string
    payload: boolean
  }
): {
  initialized: boolean
} => {
  switch (action.type) {
    case 'SET_INITIALIZED':
      return {
        initialized: action.payload
      }
    default:
      return state
  }
}

export default initReducer
