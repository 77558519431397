import { type FunctionComponent, useEffect, useState } from 'react'

import { type KeyContact } from '../../../types/keycontact'

import axios from 'axios'
import { Iconly } from 'react-iconly'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { showAlert } from '../../../actions/alertsActions'
import Button from '../../../components/Common/Button'
import Heading from '../../../components/Common/Heading'
import Popover from '../../../components/Common/Popover'
import Tile from '../../../components/Common/Tile'
import api from '../../../constants/api'
import Loading from '../Loading/Loading'

interface Props {
  title: string
}

const KeyContacts: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { title } = props
  const [loading, setLoading] = useState<boolean>(true)

  const account = useSelector((state: RootStateOrAny) => state.account)
  const roles: Array<string> = ['inside_sales_rep', 'secondary_sales_rep', 'u_service_delivery_manager', 'u_executive_sponsor']
  const [keyContacts, setKeyContacts] = useState<KeyContact[]>([])
  const dispatch = useDispatch()

  /* get the account details */
  useEffect(() => {
    const source = axios.CancelToken.source()

    setLoading(true)

    if (account.id) {
      api
        .get(`/servicenow/account/${account.id}/details`, { cancelToken: source.token })
        .then((response) => {
          const contacts: Array<KeyContact> = []
          for (const role of roles) {
            if (response.data[0][role + '.email']) {
              const contact: KeyContact = {
                first_name: response.data[0][role + '.first_name'],
                last_name: response.data[0][role + '.last_name'],
                email: response.data[0][role + '.email'],
                phone: response.data[0][role + '.phone'],
                mobile: response.data[0][role + '.mobile_phone'],
                role: role
              }

              switch (contact.role) {
                case 'inside_sales_rep':
                  contact.role = 'Account Manager'
                  break
                case 'secondary_sales_rep':
                  contact.role = 'Service Value Team Specialist'
                  break
                case 'u_service_delivery_manager':
                  contact.role = 'Service Delivery Manager'
                  break
                case 'u_executive_sponsor':
                  contact.role = 'Executive Sponsor'
                  break
              }
              contacts.push(contact)
            }
            setKeyContacts(contacts)
          }
          setLoading(false)
        })
        .catch((error) => {
          if (axios.isCancel(error)) console.log('CANCELLED axios request')
          else
            dispatch(
              showAlert({
                type: 'error',
                message: 'We were unable to load your service contacts, please try again later.',
                component: 'KeyContacts',
                error
              })
            )
          return setLoading(false)
        })
    }

    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  return (
    <Tile className="overflow-visible p-6">
      <div className="relative">
        <Heading text={title}>
          <Popover text="Call 0800 368 1831">
            <Button variant="primary" onClick={() => window.open('tel: 08003681831', '_self')} className="flex gap-2">
              <span className="mr-1.5 flex h-5 w-5 items-center">
                <Iconly name="Calling" set="light" />
              </span>
              Call Support
            </Button>
          </Popover>
        </Heading>
        {/* loading spinner */}
        {loading && <Loading className="z-10 h-full w-full" />}

        {/* key contacts */}
        <div className="grid grid-cols-1">
          {keyContacts.map((contact, index) => {
            return (
              <div className="relative m-2 overflow-hidden bg-th-content py-1" key={index}>
                <div className="flex gap-4">
                  <div
                    className="flex h-12 w-12 flex-none cursor-pointer items-center justify-center rounded-full bg-th-content-secondary"
                    onClick={() => null}
                  >
                    <Iconly name="User" className="icon h-10 w-10 rounded-full p-1.5 xs:p-2" />
                  </div>
                  <div className="flex flex-auto items-center">
                    <div>
                      {/* name */}
                      <div className="flex items-center gap-2 text-lg font-bold">
                        <div className="flex items-center gap-4">
                          {contact.first_name === 'Customer' && contact.last_name === 'Success'
                            ? 'Customer Success Team'
                            : contact.first_name + ' ' + contact.last_name}
                        </div>
                      </div>
                      {/* role */}
                      <div className="flex items-center gap-2 text-xs text-th-text-secondary">{contact.role}</div>
                    </div>
                  </div>
                  <div className="flex h-12 w-12 flex-none cursor-pointer items-center justify-center rounded-full bg-th-content-secondary">
                    <Popover text={contact.phone ? contact.phone : 'No phone number available'}>
                      <a href={contact.phone ? 'tel:' + contact.phone : ''}>
                        <Iconly name="Calling" className="icon h-10 w-10 rounded-full p-1.5 xs:p-2" />
                      </a>
                    </Popover>
                  </div>
                  {contact.email && (
                    <div className="flex h-12 w-12 flex-none cursor-pointer items-center justify-center rounded-full bg-th-content-secondary">
                      <Popover text={contact.email ? contact.email : 'No email address available'}>
                        <a href={contact.email ? 'mailto:' + contact.email : ''}>
                          <Iconly name="Message" className="icon h-10 w-10 rounded-full p-1.5 xs:p-2" />
                        </a>
                      </Popover>
                    </div>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Tile>
  )
}

export default KeyContacts
