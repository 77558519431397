import { InteractionType, type RedirectRequest } from '@azure/msal-browser'
import { useMsalAuthentication } from '@azure/msal-react'

import { clientId } from '../configs/b2c'

const Login = () => {
  /* msal request */
  const request: RedirectRequest = {
    /* these scopes request an id token, refresh token, and access token respectively */
    scopes: ['openid', 'offline_access', clientId]
  }

  useMsalAuthentication(InteractionType.Redirect, request)

  return null
}

export default Login
