import { withAITracking } from '@microsoft/applicationinsights-react-js'

import { Fragment, type FunctionComponent, useEffect, useState } from 'react'

import { type AZServiceCatalogEntry } from '../../../types/azure'

import AzureManualDeploymentDrawer from 'src/components/Common/DeploymentForms/AzureManualDeploymentForm'

import axios from 'axios'
import { Iconly } from 'react-iconly'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { showAlert } from '../../../actions/alertsActions'
import { openAzureDeploymentDrawer } from '../../../actions/drawerActions'
import { startLoading, stopLoading } from '../../../actions/loadingActions'
import Heading from '../../../components/Common/Heading'
import Input from '../../../components/Common/Input'
import Section from '../../../components/Common/Section'
import { reactPlugin } from '../../../configs/appInsights'
import api from '../../../constants/api'
import AzureDeplyoment from './AzureDeployment'

const AzureDeployments: FunctionComponent = (): JSX.Element => {
  const dispatch = useDispatch()
  const account = useSelector((state: RootStateOrAny) => state.account)
  const [catalog, setCatalog] = useState<AZServiceCatalogEntry[]>([])
  const [filtered, setFiltered] = useState<AZServiceCatalogEntry[]>([])
  const [showSearch, setShowSearch] = useState<boolean>(false)

  const [manualResource, setManualResource] = useState<AZServiceCatalogEntry | null>(null)

  /* handle product click */
  const handleProductClick = (deployment: AZServiceCatalogEntry) => {
    if (deployment.type === 'Manual Resource') {
      setManualResource(deployment)
    } else {
      dispatch(openAzureDeploymentDrawer(deployment))
    }
  }

  /* filter the products by search term */
  const filter = (searchTerm: string) => {
    setFiltered(
      catalog?.filter(
        (entry: AZServiceCatalogEntry) =>
          entry.resource_type.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 || entry.type.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
      )
    )
  }

  useEffect(() => {
    dispatch(startLoading())
  }, [dispatch])

  useEffect(() => {
    const source = axios.CancelToken.source()

    /* reset all the data */
    if (account.id) {
      api
        .get(`/servicenow/account/${account.id}/azure_service_catalog`, { cancelToken: source.token })
        .then((response) => {
          if (response.data) {
            const catalog: AZServiceCatalogEntry[] = []
            response.data.map((entry: AZServiceCatalogEntry) => {
              if (['Automated Resource', 'Manual Resource'].includes(entry.type) && entry.enabled) {
                catalog.push(entry)
              }
              return {}
            })
            setCatalog(catalog)
            setFiltered(catalog)
            dispatch(stopLoading())
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            dispatch(stopLoading())
            dispatch(
              showAlert({
                type: 'error',
                message: 'We were unable to retrieve your Azure deployments.',
                component: 'AzureDeployments',
                error
              })
            )
          }
        })
    }

    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  return (
    <Fragment>
      {manualResource && <AzureManualDeploymentDrawer deployment={manualResource} onClose={() => setManualResource(null)} />}
      <Section>
        <Heading text="Deployment Catalogue">
          {/* search */}
          {showSearch ? (
            <Fragment>
              <Input type="text" name="search" placeholder="Search" autoFocus onChange={(e) => filter(e.target.value)} />
              <IoCloseCircleOutline className="h-6 w-6 cursor-pointer" onClick={() => setShowSearch(false)} />
            </Fragment>
          ) : (
            <Iconly name="Search" set="light" className="cursor-pointer" onClick={() => setShowSearch(true)} />
          )}
        </Heading>
      </Section>
      <Section>
        <div className="my-5 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-1 mdlg:grid-cols-2 xl:grid-cols-4">
          {filtered.map((entry: AZServiceCatalogEntry, index) => (
            <AzureDeplyoment deployment={entry} onClick={() => (entry ? handleProductClick(entry) : null)} key={index} />
          ))}
        </div>
      </Section>
    </Fragment>
  )
}

const AzureDeploymentsWithTracking = withAITracking(reactPlugin, AzureDeployments, 'Azure Deployments')
export default AzureDeploymentsWithTracking
