import type { FileMetadata, Folder } from 'src/hooks/services/provide/queries/useFilesQuery'

export type Data = (FileMetadata | Folder) & { type: string; name: string }

export const SET_SELECTED_ITEMS = 'SET_SELECTED_ITEMS'
export const GET_SELECTED_ITEMS = 'GET_SELECTED_ITEMS'

export const setSelectedItems = (items: Data[]) => ({
  type: SET_SELECTED_ITEMS as typeof SET_SELECTED_ITEMS,
  payload: { items }
})

export const getSelectedItems = () => ({
  type: GET_SELECTED_ITEMS as typeof GET_SELECTED_ITEMS
})
