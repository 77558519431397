import { type FunctionComponent } from 'react'

import { Bar, CartesianGrid, Label, BarChart as ReChartsBarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

interface ChartData {
  name: string
  cost: number
  fill: string
}

interface Props {
  data: ChartData[]
}

const BarChart: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data } = props

  return (
    <div className="relative">
      <ResponsiveContainer height={280} className="rounded-3xl bg-th-content text-2xs">
        <ReChartsBarChart
          data={data}
          margin={{
            top: 40,
            right: 30,
            left: 30,
            bottom: 30
          }}
        >
          <XAxis dataKey="name" strokeWidth={0} tick={{ fill: 'var(--text)' }} interval={'equidistantPreserveStart'} />
          <YAxis strokeWidth={0} tick={{ fill: 'var(--text)' }}>
            <Label value="Cost (£)" offset={0} position="left" angle={-90} fill="var(--text)" />
          </YAxis>
          <CartesianGrid vertical={false} strokeWidth={0.3} />
          <Tooltip contentStyle={{ backgroundColor: 'var(--content)' }} cursor={{ fill: 'transparent' }} />
          <Bar dataKey="cost" name="Cost (£)" fill="#b2b2b2" maxBarSize={200} />
        </ReChartsBarChart>
      </ResponsiveContainer>
      {/* no data message */}
      {data.length === 0 && (
        <div className="absolute top-0 flex h-full w-full items-center justify-center bg-th-translucent text-th-text">No data to display.</div>
      )}
    </div>
  )
}

export default BarChart
