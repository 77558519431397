import { type User } from '../types/user'

export const setUser = (
  user: User
): {
  type: string
  payload: User
} => {
  return {
    type: 'SET_USER',
    payload: user
  }
}

export const openUser = (): {
  type: string
  payload: Record<string, boolean>
} => {
  return {
    type: 'OPEN_USER',
    payload: { open: true }
  }
}

export const closeUser = (): {
  type: string
  payload: Record<string, boolean>
} => {
  return {
    type: 'CLOSE_USER',
    payload: { open: false }
  }
}
