import type { UseBaseQueryOptions } from '@tanstack/react-query'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useQuery from 'src/hooks/utils/useQuery'
import useOwnUser from '~/hooks/utils/useOwnUser'

export interface FeatureFlag {
  id: string
  name: string
  description: string
  enabled: boolean
  accountIds: string[]
  createdAt: string
  updatedAt: string
}

const useAdminFeatureFlagQuery = (options?: UseBaseQueryOptions<FeatureFlag[]>) => {
  const account = useAccount()
  const user = useOwnUser()

  const query = useQuery(
    ['getAdminFeatureFlags', account.id],
    async ({ signal }) => {
      const response = await api.get(`/provide/featureflags`, {
        signal
      })
      return response.data
    },
    {
      enabled: !!account.id && !!user?.super_admin,
      refetchOnWindowFocus: false,
      ...options
    }
  )

  return { ...query, isLoading: query.isLoading && !!account.id && !!user?.super_admin }
}

export default useAdminFeatureFlagQuery
