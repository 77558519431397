import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useMutation, { type UseMutationOptions } from 'src/hooks/utils/useMutation'

interface CreateFolderParams {
  folderName: string
  parentId: string | null
}

const useCreateFolderMutation = (options?: UseMutationOptions<CreateFolderParams>) => {
  const account = useAccount()

  return useMutation(
    async ({ folderName, parentId }: CreateFolderParams) => {
      const response = await api.post(`provide/${account.id}/folder/create`, {
        folderName,
        parentId
      })
      return response.data
    },
    {
      ...options,
      errorMessage: 'An error occurred while creating the folder.'
    }
  )
}

export default useCreateFolderMutation
