import React, { forwardRef } from 'react'

import { PiInfo } from 'react-icons/pi'

import Popover from '../Popover'
import './Checkbox.css'

interface Props {
  checked: boolean
  className?: string
  label?: string
  info?: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
}

const Checkbox = forwardRef<HTMLInputElement, Props>(({ checked, className, label, info, onChange }, ref) => {
  return (
    <label className="checkbox-container !mb-0 min-h-[17px]">
      <input type="checkbox" checked={checked} className={className} onChange={onChange} ref={ref} />
      <span className="checkmark"></span>
      <div className="flex items-center gap-1">
        {label && <span className="ml-2 text-base">{label}</span>}
        {info && (
          <Popover text={info} position="bottom">
            <PiInfo className="text-gray-500 mb-auto h-4 w-4 text-2xl" />
          </Popover>
        )}
      </div>
    </label>
  )
})

Checkbox.displayName = 'Checkbox'

export default Checkbox
