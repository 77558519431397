import { type FunctionComponent } from 'react'

import { Iconly } from 'react-iconly'
import { Link } from 'react-router-dom'

interface Props {
  label: string
  icon: string
  route: string
}

const FooterLink: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { label, icon, route } = props

  return (
    <Link
      to={route}
      className="flex h-12 w-12 items-center justify-center rounded-full bg-th-lowlight px-2.5 xs:h-14 xs:w-full xs:rounded-none xs:bg-th-secondary xs:hover:bg-th-navigation-parent-active"
    >
      <span className="icon flex h-full items-center justify-center xs:w-14">
        <Iconly name={icon} set="light" />
      </span>
      <span className="hidden flex-auto xs:block">{label}</span>
    </Link>
  )
}

export default FooterLink
