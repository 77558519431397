import { type FunctionComponent, type ReactNode } from 'react'

import { classNames } from 'src/utils/classNames'

interface Props {
  children?: ReactNode
  align?: 'left' | 'center' | 'right'
  className?: string
}

const Banner: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { children } = props

  return (
    <div
      className={classNames(
        'flex flex-col flex-wrap items-center gap-4 xs:flex-row',
        props.align === 'center' && 'justify-center',
        props.align === 'left' && 'justify-start',
        props.align === 'right' && 'justify-end',
        props.className
      )}
    >
      {children}
    </div>
  )
}

export default Banner
