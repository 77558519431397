import { Fragment } from 'react'

import useChangeStatsQuery from '~/hooks/services/servicenow/queries/useChangeStatsQuery'

import Count from '~/components/Common/Count'
import { Grid } from '~/components/Common/Grid'

import { FcAlarmClock, FcCancel, FcClock, FcOk } from 'react-icons/fc'

import { useChangeFilters } from '../../hooks/useChangeFilters'
import { DateRangeFilter } from './DateRangeFilter'

export const ChangeStats = () => {
  const { filterOptions, handleStateFilter } = useChangeFilters()
  const { data: stats } = useChangeStatsQuery(filterOptions)

  return (
    <Fragment>
      <DateRangeFilter />
      <Grid className="pt-6">
        <Count
          icon={<FcClock className="h-full w-full text-th-warning" />}
          value={stats?.planned}
          label="Planned"
          active={filterOptions.state?.includes('Planned')}
          onClick={() => handleStateFilter('Planned')}
        />
        <Count
          icon={<FcAlarmClock className="h-full w-full" />}
          value={stats?.pending_approval}
          label="Pending Approval"
          active={filterOptions.state?.includes('Pending Approval')}
          onClick={() => handleStateFilter('Pending Approval')}
        />
        <Count
          icon={<FcCancel className="h-full w-full" />}
          value={stats?.rejected}
          label="Rejected"
          active={filterOptions.state?.includes('Rejected')}
          onClick={() => handleStateFilter('Rejected')}
        />
        <Count
          icon={<FcOk className="h-full w-full" />}
          value={stats?.closed}
          label="Closed"
          active={filterOptions.state?.includes('Closed')}
          onClick={() => handleStateFilter('Closed')}
        />
      </Grid>
    </Fragment>
  )
}
