/* redux store */
import { legacy_createStore as createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

/* reducers */
import reducers from '../reducers'

/* redux store */
const store = createStore(reducers, composeWithDevTools())

export default store
