import { INITALIZE_FOLDER_PATH, SET_CURRENT_FOLDER_ID, SET_FOLDER_PATH, SET_SEARCH, UNINITALIZE_FOLDER_PATH } from 'src/actions/folderActions'

import type { Folder } from 'src/hooks/services/provide/queries/useFilesQuery'

export interface FolderState {
  folder: {
    search: string
    folderPath: Folder[]
    currentFolderId: string | null
    initializedFolderPath: boolean
  }
}

export const DEFAULT_FOLDER_PATH: Folder[] = [{ id: '', name: '', accountId: '', createdAt: '', createdFromConfigId: null, parentId: null, path: 'root' }]

const initialState: FolderState['folder'] = {
  search: '',
  folderPath: DEFAULT_FOLDER_PATH,
  currentFolderId: null,
  initializedFolderPath: false
}

const folderReducer = (state = initialState, action: any): FolderState['folder'] => {
  switch (action.type) {
    case INITALIZE_FOLDER_PATH:
      return { ...state, initializedFolderPath: true }
    case UNINITALIZE_FOLDER_PATH:
      return { ...state, initializedFolderPath: false }
    case SET_FOLDER_PATH:
      return { ...state, folderPath: action.payload.folderPath }
    case SET_CURRENT_FOLDER_ID:
      return { ...state, currentFolderId: action.payload.currentFolderId }
    case SET_SEARCH:
      return { ...state, search: action.payload.search }
    default:
      return state
  }
}

export default folderReducer
