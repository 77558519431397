import { type FunctionComponent, useEffect, useState } from 'react'

import { type MeterUsage } from '../../../../types/azure'

import { CSVLink } from 'react-csv'
import { useDispatch } from 'react-redux'
import { type OptionTypeBase } from 'react-select'

import { stopLoading } from '../../../../actions/loadingActions'
import Button from '../../../../components/Common/Button'
import Dropdown from '../../../../components/Common/Dropdown'
import Heading from '../../../../components/Common/Heading'
import BarChart from './Charts/BarChart'
import PieChart from './Charts/PieChart'

interface ChartData {
  name: string
  cost: number
  fill: string
}

interface Props {
  usage: MeterUsage[]
  dataKey: string
  title: string
}

/* dropdown options */
const chartOptions = [
  {
    label: 'Bar',
    value: 'bar'
  },
  {
    label: 'Pie',
    value: 'pie'
  }
]

/* chart colors */
const colors: string[] = ['#0087ae', '#4caf50', '#f2b44d', '#f98690']

const Chart: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { usage, dataKey, title } = props

  const [chart, setChart] = useState<string>('bar')
  const [data, setData] = useState<ChartData[]>([])

  const dispatch = useDispatch()

  /* reshape the data for the chart */
  useEffect(() => {
    if (usage.length > 0) {
      const services: Record<string, number> = {}

      usage.forEach((u: MeterUsage) => {
        if (parseFloat(u.price.toFixed(2)) > 0)
          services[u[dataKey as keyof typeof u]] ? (services[u[dataKey as keyof typeof u]] += u.price) : (services[u[dataKey as keyof typeof u]] = u.price)
      })

      let data: ChartData[] = Object.keys(services).map((s: string) => {
        return {
          name: s,
          cost: parseFloat(services[s].toFixed(2)),
          fill: colors[0]
        }
      })

      /* sort data by cost */
      data.sort((a: ChartData, b: ChartData) => b.cost - a.cost)

      /* add a fill to each datapoint */
      data = data.map((d: ChartData, index: number) => {
        const point: ChartData = d
        point.fill = colors[index % colors.length]
        return point
      })

      setData(data)
      dispatch(stopLoading())
    } else {
      setData([])
    }
  }, [dataKey, dispatch, usage])

  /* stop loading when the data has been set */
  useEffect(() => {
    dispatch(stopLoading())
  }, [data, dispatch])

  return (
    <div className="overflow-hidden">
      <Heading text={title}>
        <Dropdown
          options={chartOptions}
          defaultValue={chartOptions[0]}
          variant={Dropdown.variant.DEFAULT}
          label="Chart"
          multi={false}
          onChange={(chart: OptionTypeBase) => {
            setChart(chart.value)
          }}
        />
        {/* csv download */}
        <CSVLink
          data={data.map((d) => {
            return {
              Name: d.name,
              Cost: '£' + d.cost
            }
          })}
          filename={title + '.csv'}
          target="_blank"
        >
          <Button onClick={() => null}>Download CSV</Button>
        </CSVLink>
      </Heading>
      {chart === 'bar' && <BarChart data={data} />}
      {chart === 'pie' && <PieChart data={data} />}
    </div>
  )
}

export default Chart
