import { useState } from 'react'

import { useChangeApprovalMutation } from '~/hooks/services/servicenow/mutations/useChangeApprovalMutation'
import useChangeApprovalQuery from '~/hooks/services/servicenow/queries/useChangeApprovalQuery'

import Button from '~/components/Common/Button'
import TextArea from '~/components/Common/TextArea'

import { Iconly } from 'react-iconly'
import { useParams } from 'react-router-dom'

interface ApprovalActionsProps {
  onClose: () => void
}

const ApprovalActions = ({ onClose }: ApprovalActionsProps) => {
  const { id } = useParams()
  const { data: changeApproval } = useChangeApprovalQuery(id)

  const [approval, setApproval] = useState(false)
  const [reject, setReject] = useState(false)
  const [approvalMessage, setApprovalMessage] = useState('')

  const { mutate: handleApproval } = useChangeApprovalMutation()

  const toggleApproval = () => setApproval(!approval)
  const toggleReject = () => setReject(!reject)
  const cancelApproval = () => {
    setApproval(false)
    setReject(false)
    setApprovalMessage('')
  }

  const submitApproval = (approved: boolean) => {
    if (!changeApproval) return

    handleApproval(
      {
        changeId: changeApproval.change,
        customerApprovalId: changeApproval.id,
        approved,
        message: approvalMessage,
        originator: changeApproval.approver
      },
      {
        onSuccess: () => {
          cancelApproval()
          onClose()
        }
      }
    )
  }

  if (changeApproval?.state !== 'Requested') {
    return (
      <Button bordered onClick={onClose}>
        Close
      </Button>
    )
  }

  return (
    <div className="flex flex-col">
      {(approval || reject) && (
        <TextArea
          name="approval_message"
          label={approval ? 'Approval Message' : 'Reject Reason'}
          className="h-16"
          value={approvalMessage}
          onChange={(e) => setApprovalMessage(e.target.value)}
        />
      )}

      <div className="flex flex-row-reverse justify-between">
        <Button bordered onClick={onClose}>
          Close
        </Button>

        {approval && (
          <Button bordered onClick={() => submitApproval(true)} className="gap-2">
            <Iconly set="light" name="TickSquare" className="h-5" />
            <span>Confirm Approval</span>
          </Button>
        )}

        {reject && (
          <Button bordered disabled={!approvalMessage} onClick={() => submitApproval(false)} className="gap-2">
            <Iconly set="light" name="CloseSquare" className="h-5" />
            <span>Confirm Rejection</span>
          </Button>
        )}

        {!approval && !reject && (
          <>
            <Button bordered onClick={toggleApproval} className="gap-1">
              <Iconly set="light" name="TickSquare" className="h-5" />
              <span>Approve</span>
            </Button>
            <Button bordered onClick={toggleReject} className="gap-1">
              <Iconly set="light" name="CloseSquare" className="h-5" />
              <span>Reject</span>
            </Button>
          </>
        )}

        {(approval || reject) && (
          <Button bordered onClick={cancelApproval} className="gap-1">
            <span>Cancel</span>
          </Button>
        )}
      </div>
    </div>
  )
}

export default ApprovalActions
