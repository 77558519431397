import { combineReducers } from 'redux'

import accountReducer from './accountReducer'
import alertsReducer from './alertsReducer'
import authorisedReducer from './authorisedReducer'
import drawerReducer from './drawerReducer'
import { entitlementReducer, entitlementsReducer } from './entitlementReducer'
import fileReducer from './fileReducer'
import folderReducer, { type FolderState } from './folderReducer'
import initReducer from './initReducer'
import loadingReducer from './loadingReducer'
import modalReducer from './modalReducer'
import navigationReducer from './navigationReducer'
import settingsReducer from './settingsReducer'
import { tenantReducer, tenantsReducer } from './tenantReducer'
import uploadReducer from './uploadReducer'

/* reducers */
import userReducer from './userReducer'

/* combine all reducers */
const reducers = combineReducers({
  user: userReducer,
  account: accountReducer,
  tenant: tenantReducer,
  tenants: tenantsReducer,
  entitlement: entitlementReducer,
  entitlements: entitlementsReducer,
  alerts: alertsReducer,
  navigation: navigationReducer,
  loading: loadingReducer,
  modals: modalReducer,
  drawers: drawerReducer,
  settings: settingsReducer,
  authorised: authorisedReducer,
  initialized: initReducer,
  upload: uploadReducer,
  folder: folderReducer,
  file: fileReducer
})

export interface RootState {
  user: any
  account: any
  tenant: any
  tenants: any
  entitlement: any
  entitlements: any
  alerts: any
  navigation: any
  loading: any
  modals: any
  drawers: any
  settings: any
  authorised: any
  initialized: any
  upload: any
  folder: FolderState
}

export default reducers
