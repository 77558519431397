/* utils */
import { type ReactNode } from 'react'

interface Props {
  icon?: ReactNode
  link: string
  text: string
}

const ArticleLink = (props: Props): JSX.Element => {
  const { icon, link, text } = props

  const openLink = (link: string) => {
    window.open(link, '_blank')
  }

  return (
    <button
      onClick={() => {
        openLink(link)
      }}
      className="flex inline-flex w-48 items-center justify-center gap-8 rounded-lg bg-th-content font-bold"
    >
      {icon}
      <span className="text-sm font-bold text-th-text-secondary">{text}</span>
    </button>
  )
}

export default ArticleLink
