import { Fragment, type FunctionComponent } from 'react'

import { type RootStateOrAny, useSelector } from 'react-redux'

import logoColor from '../../../images/logo-color.svg'

/* images */
import logoWhite from '../../../images/logo-white.svg'

interface Props {
  variant?: string
}

const Logo: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const theme = useSelector((state: RootStateOrAny) => state.settings.theme)
  const variant = props.variant

  return (
    <div className="top-0 my-2 mb-4 flex h-32 w-full flex-none items-center justify-center xs:h-18 xs:justify-start xs:pl-5">
      {
        //if theme dark get white one no matter what
        theme === 'dark' ? (
          <img src={logoWhite} alt="logo" className="darkthemeis block h-1/2" />
        ) : //if variant is specified, get corresponding
        variant ? (
          variant === 'white' ? (
            <img src={logoWhite} alt="logo" className="whitevariant block h-1/2" />
          ) : (
            <img src={logoColor} alt="logo" className="othervariant block h-1/2" />
          )
        ) : (
          //else get both, show color below xs, and white above xs
          <Fragment>
            <img src={logoWhite} alt="logo" className="hidden h-1/2 xs:block" />
            <img src={logoColor} alt="logo" className="h-1/2 xs:hidden" />
          </Fragment>
        )
      }
    </div>
  )
}

export default Logo
