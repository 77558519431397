import RefactoredDropdown from '~/components/Common/RefactoredDropdown'

import { CLASSIFICATION_OPTIONS } from '../../constants'
import { useChangeFilters } from '../../hooks/useChangeFilters'

export const ClassificationFilter = () => {
  const { filterOptions, handleClassificationFilter } = useChangeFilters()

  return (
    <RefactoredDropdown
      data={CLASSIFICATION_OPTIONS.map((classification) => ({
        label: classification,
        value: classification,
        checked: filterOptions.classification?.includes(classification) || false,
        disabled: filterOptions.classification?.length === 1 ? filterOptions.classification.includes(classification) : false
      }))}
      label="Classification"
      onChange={(_, selectedNodes) => handleClassificationFilter(selectedNodes.map((o) => o.value))}
      disabled={!!filterOptions.keyword}
    />
  )
}
