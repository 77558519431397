import { useCallback, useMemo } from 'react'

import { type Entitlement } from 'src/types/azure'

import useTotalSpendForDateRange from '../../services/microsoft/queries/useTotalSpendForDateRange'
import { useTenantsQuery } from '../../services/servicenow/queries/useTenantsQuery'

interface UseConsumptionTotalConfig {
  dateRange: [moment.Moment, moment.Moment]
  subscriptions?: Entitlement[]
}

const useConsumptionTotal = ({ dateRange, subscriptions }: UseConsumptionTotalConfig) => {
  const { data: tenants } = useTenantsQuery({ active: true })
  const totalSpend = useTotalSpendForDateRange({ dateRange, tenants })

  const accumulate = useCallback((spend: typeof totalSpend, subsciptions?: typeof subscriptions) => {
    const filtered = spend?.filter((t) => subsciptions?.some((subscription) => t.subscriptionId === subscription.id) ?? true) ?? []
    const total = filtered?.reduce((acc, curr) => acc + curr.totalSpendGBP, 0)

    return total
  }, [])

  const totalAccumulated = useMemo(() => accumulate(totalSpend, subscriptions), [accumulate, totalSpend, subscriptions])

  return totalAccumulated
}

export default useConsumptionTotal
