import type { ChangeApprovalFilterOptions } from '~/hooks/services/servicenow/queries/useChangeApprovalsQuery'

import moment from 'moment'

export const PERIOD_OPTIONS = [
  { label: 'All Time', value: 'all' },
  { label: 'Current Month', value: 'current' },
  { label: 'Previous Month', value: 'previous' },
  { label: 'Custom', value: 'custom' }
]

export const STATE_OPTIONS = ['Requested', 'Approved', 'Rejected']

export const SHOW_OWN_APPROVALS_OPTIONS = [
  { label: 'All Approvals', value: false },
  { label: 'My Approvals', value: true }
]

export const DEFAULT_FILTER_OPTIONS: ChangeApprovalFilterOptions = {
  period: 'all',
  showOwnApprovals: false,
  startDate: moment(0),
  endDate: moment().endOf('month'),
  keyword: null,
  state: 'Requested',
  orderBy: null,
  orderByDesc: false,
  take: 10,
  skip: 0
}
