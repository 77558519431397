import { useQueryClient } from '@tanstack/react-query'

import { useCallback, useMemo, useState } from 'react'

import { showAlert } from 'src/actions/alertsActions'

import useShareDocumentMutation from 'src/hooks/services/provide/mutations/useShareDocumentMutation'
import useAccountUsers from 'src/hooks/services/provide/queries/useAccountUsers'

import Checkbox from 'src/components/Common/Checkbox/Checkbox'
import NestedDropdown from 'src/components/Common/RefactoredDropdown'
import ConfirmationModal from 'src/components/Modals/ConfirmationModal'

import { useDispatch } from 'react-redux'

export const useFileShare = () => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const [sharingFileIds, setSharingFileIds] = useState<string[]>([])
  const [shareModalState, setShareModalState] = useState<{ isOpen: boolean; ids: string[]; fileName: string; shouldNotify: boolean }>({
    isOpen: false,
    ids: [],
    fileName: '',
    shouldNotify: true
  })
  const [selectedContacts, setSelectedContacts] = useState<{ id: string; email: string }[]>([])

  const { data: accountUsers, isLoading: isLoadingAccountUsers } = useAccountUsers()

  const { mutateAsync: shareDocument } = useShareDocumentMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['getFilesQuery'])
      dispatch(showAlert({ component: 'FileShare', message: 'File shared successfully', type: 'success' }))
    },
    onError: () => {
      dispatch(showAlert({ component: 'FileShare', message: 'Failed to share file', type: 'error' }))
    }
  })

  const handleFileShare = async (ids: string[], targetUserIds: string[], shouldNotify: boolean) => {
    setSharingFileIds((prev) => [...prev, ...ids])
    try {
      await shareDocument({ ResourceIds: ids, TargetUserIds: targetUserIds, ShouldNotify: shouldNotify })
    } catch (error) {
      console.error('Share failed:', error)
    } finally {
      setSharingFileIds((prev) => prev.filter((fileId) => !ids.includes(fileId)))
      closeShareModal()
    }
  }

  const openShareModal = useCallback((ids: string[], fileName: string) => {
    setShareModalState({ isOpen: true, ids, fileName, shouldNotify: true })
  }, [])

  const closeShareModal = () => {
    setShareModalState({ isOpen: false, ids: [], fileName: '', shouldNotify: true })
    setSelectedContacts([])
  }

  const userOptions = useMemo(() => {
    return (
      accountUsers?.map((user) => ({
        value: user.Id,
        label: user.Email
      })) || []
    )
  }, [accountUsers])

  const ShareModal = (
    <ConfirmationModal
      isOpen={shareModalState.isOpen}
      onClose={closeShareModal}
      onConfirm={() =>
        selectedContacts.length > 0 &&
        handleFileShare(
          shareModalState.ids,
          selectedContacts.map((c) => c.id),
          shareModalState.shouldNotify
        )
      }
      title={`Share ${shareModalState.ids.length > 1 ? `${shareModalState.ids.length} files` : shareModalState.fileName}`}
      isLoading={sharingFileIds.some((fileId) => shareModalState.ids.includes(fileId)) || isLoadingAccountUsers}
      countdownDuration={0}
      disabled={selectedContacts.length === 0}
    >
      <div className="space-y-4">
        <NestedDropdown
          data={userOptions}
          onChange={(curr, data) => {
            const selectedOptions = data.map((option) => ({
              id: option.value,
              email: option.label
            }))
            setSelectedContacts(selectedOptions)
          }}
          label="Selected Contacts"
          loading={isLoadingAccountUsers}
        />
        <Checkbox
          label="Send email notifications to selected users"
          checked={shareModalState.shouldNotify}
          onChange={(event) => setShareModalState((prev) => ({ ...prev, shouldNotify: event.target.checked }))}
        />
      </div>
    </ConfirmationModal>
  )

  return {
    sharingFileIds,
    openShareModal,
    ShareModal
  }
}
