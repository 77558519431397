import { zodResolver } from '@hookform/resolvers/zod'

import { type Contact } from '../../types/contact'

import useUpdateContactMutation from 'src/hooks/services/servicenow/mutations/useUpdateContactMutation'
import useAccount from 'src/hooks/utils/useAccount'

import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { z } from 'zod'

import { closeDrawers } from '../../actions/drawerActions'
import Button from '../Common/Button'
import { Form, FormControl, FormField, FormItem, FormMessage } from '../Common/Form'
import Input from '../Common/Input'
import PortalDrawer from '../Common/PortalDrawer'
import Submit from '../Common/Submit'

const profileFormSchema = z.object({
  first_name: z.string(),
  last_name: z.string(),
  phone: z.string().optional(),
  mobile: z.string().optional()
})

interface ProfileDrawerProps {
  profile: Contact
  onClose: () => void
}

const ProfileDrawer = ({ onClose, profile }: ProfileDrawerProps) => {
  const account = useAccount()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const profileForm = useForm<z.infer<typeof profileFormSchema>>({
    resolver: zodResolver(profileFormSchema),
    defaultValues: {
      first_name: profile.first_name,
      last_name: profile.last_name,
      phone: profile.phone,
      mobile: profile.mobile
    }
  })

  const { mutateAsync: updateContact, isLoading: updatingContact } = useUpdateContactMutation()
  const onProfileFormSubmit = async (values: z.infer<typeof profileFormSchema>) => {
    const contact = {
      account: account.id,
      email: profile.email,
      ...values
    }

    const success = await updateContact({ contact })
    if (!success) return

    onClose()
    dispatch(closeDrawers())

    /* rerender the profile page */
    navigate('/profile')
  }

  return (
    <PortalDrawer
      title="Edit Profile"
      loading={updatingContact}
      onClose={() => {
        onClose()
        dispatch(closeDrawers())
      }}
      actions={
        <div className="grid grid-cols-2 gap-4">
          <Button
            bordered
            onClick={() => {
              onClose()
              dispatch(closeDrawers())
            }}
          >
            Cancel
          </Button>
          <Submit variant="primary" form="ProfileForm" />
        </div>
      }
    >
      <Form {...profileForm}>
        <form onSubmit={profileForm.handleSubmit(onProfileFormSubmit)} className="space-y-4" id="ProfileForm">
          {/* first name */}
          <FormField
            control={profileForm.control}
            name="first_name"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input {...field} label="First Name" required />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={profileForm.control}
            name="last_name"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input {...field} label="Last Name" required />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={profileForm.control}
            name="phone"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input {...field} label="Phone" type="tel" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={profileForm.control}
            name="mobile"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input {...field} label="Mobile" type="tel" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </form>
      </Form>
    </PortalDrawer>
  )
}

export default ProfileDrawer
