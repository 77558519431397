import { useMutation, useQueryClient } from '@tanstack/react-query'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useOwnUser from 'src/hooks/utils/useOwnUser'

import { getChangeCommentsQueryKey } from '../queries/useChangeCommentsQuery'
import { type Comment } from '../queries/useChangesQuery'

interface CommentMutationParams {
  changeId: string
  comment: string
}

export const useCreateChangeCommentMutation = () => {
  const queryClient = useQueryClient()
  const account = useAccount()
  const user = useOwnUser()

  return useMutation({
    mutationFn: async ({ changeId, comment }: CommentMutationParams) => {
      const data = {
        comment: `${user?.first_name} ${user?.last_name} <{#}> ${comment}`
      }
      const response = await api.post(`/servicenow/account/${account.id}/change_request/${changeId}/comment`, data)

      await queryClient.invalidateQueries({ queryKey: getChangeCommentsQueryKey(account.id, changeId) })

      return response.data as Comment
    }
  })
}
