import { type FunctionComponent, useState } from 'react'

import { type Contact } from '../../../types/contact'
import { type User } from '../../../types/user'

import ProfileDrawer from 'src/components/Drawers/ProfileDrawer'

import { Iconly } from 'react-iconly'
import { IoMoonOutline, IoSunnyOutline } from 'react-icons/io5'
import { MdStayCurrentPortrait } from 'react-icons/md'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { openContactsConfirmModal } from '../../../actions/modalActions'
import { updateSettings } from '../../../actions/settingsActions'
import Button from '../../../components/Common/Button'

interface Props {
  contact: Contact
}

const ContactDetails: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { contact } = props

  const dispatch = useDispatch()

  const user: User = useSelector((state: RootStateOrAny) => state.user)
  const theme = useSelector((state: RootStateOrAny) => state.settings.theme)

  const [profileDrawerOpen, setProfileDrawerOpen] = useState(false)

  return (
    <>
      {contact && (
        <>
          {profileDrawerOpen ? <ProfileDrawer onClose={() => setProfileDrawerOpen(false)} profile={contact} /> : null}
          <div className="grid grid-flow-row-dense grid-cols-3 gap-4 lg:grid-cols-6">
            <div className="col-span-3 flex items-center gap-8">
              {/* profile picture */}
              <div className="flex h-16 w-16 flex-none cursor-pointer items-center justify-center rounded-full bg-th-content-secondary" onClick={() => null}>
                <Iconly name="User" className="icon h-12 w-12 rounded-full p-1.5 xs:p-2" />
              </div>
              <div>
                {/* name */}
                <div className="flex items-center gap-2 text-xl font-bold">
                  <div className="flex items-center gap-2">{contact.first_name + ' ' + contact.last_name}</div>
                </div>
                {/* email */}
                <div className="flex items-center gap-2">
                  <a href={'mailto:' + contact.email}>{contact.email}</a>
                </div>
              </div>
            </div>

            <div className="lg-mt-0 col-span-2 mt-4 flex flex-none flex-col">
              {/* phone */}
              <div className="flex h-8 items-center gap-2">
                <Iconly name="Call" set="bold" className="h-5 w-7" />
                {contact.phone ? <a href={'tel:' + contact.phone}>{contact.phone}</a> : <span className="opacity-30">xxxxx xxx xxx</span>}
              </div>

              {/* mobile */}
              <div className="flex h-8 items-center gap-2">
                <MdStayCurrentPortrait className="h-5 w-7" />
                {contact.mobile ? <a href={'tel:' + contact.mobile}>{contact.mobile}</a> : <span className="opacity-30">xxxxx xxx xxx</span>}
              </div>
            </div>

            <div className="flex flex-none items-center justify-end">
              <div className="flex flex-col gap-2">
                {contact.email.toLowerCase() === user.email.toLowerCase() ? (
                  /* edit contact */
                  <Button
                    onClick={() => setProfileDrawerOpen(true)}
                    className="w-max gap-2"
                    disabled={contact.email.toLowerCase() !== user.email.toLowerCase()}
                    bordered
                  >
                    <Iconly name="Edit" set="light" className="h-4" />
                    Edit
                  </Button>
                ) : (
                  /* remove contact */
                  <Button
                    onClick={() => dispatch(openContactsConfirmModal())}
                    className="gap-2"
                    disabled={contact.email.toLowerCase() === user.email.toLowerCase()}
                    bordered
                  >
                    <Iconly name="Delete" set="bold" className="h-5 w-5" />
                    Remove
                  </Button>
                )}
                {/* toggle theme */}
                {theme === 'dark' ? (
                  <Button bordered onClick={() => dispatch(updateSettings({ theme: 'default' }))} className="gap-2">
                    <IoSunnyOutline />
                    Default Theme
                  </Button>
                ) : (
                  <Button bordered onClick={() => dispatch(updateSettings({ theme: 'dark' }))} className="gap-2">
                    <IoMoonOutline />
                    Dark Theme
                  </Button>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ContactDetails
