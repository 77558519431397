import { type Entitlement } from '../types/azure'

export const setEntitlements = (
  entitlements: Entitlement[]
): {
  type: string
  payload: Entitlement[]
} => {
  return {
    type: 'SET_ENTITLEMENTS',
    payload: entitlements
  }
}

export const setEntitlement = (
  entitlement: Entitlement
): {
  type: string
  payload: Entitlement
} => {
  return {
    type: 'SET_ENTITLEMENT',
    payload: entitlement
  }
}
