import api from 'src/constants/api'

import useMutation, { type UseMutationOptions } from 'src/hooks/utils/useMutation'

interface CancelSubscriptionParams {
  accountId: string
  tenantId: string
  subscriptionId: string
}

const useCancelSubscriptionMutation = (options?: UseMutationOptions<CancelSubscriptionParams>) => {
  return useMutation(async ({ accountId, tenantId, subscriptionId }: CancelSubscriptionParams) => {
    const response = await api.patch(`/ms-api/subscriptions/account/${accountId}/${tenantId}/${subscriptionId}/cancel`)
    return response.data
  }, options)
}

export default useCancelSubscriptionMutation
