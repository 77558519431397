import { useMemo } from 'react'

import { type Tenant } from 'src/types/azure'

import { setTenant } from 'src/actions/tenantActions'

import { useTenantsQuery } from 'src/hooks/services/servicenow/queries/useTenantsQuery'

import { type TreeNode } from 'react-dropdown-tree-select'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import RefactoredDropdown from '../Common/RefactoredDropdown'

type TenantDropdownProps = {
  onChange?: (selectedTenants: Tenant[]) => void
  showAllTenants?: boolean
}

const TenantDropdown: React.FC<TenantDropdownProps> = ({ onChange, showAllTenants }) => {
  const { data: tenants, isLoading } = useTenantsQuery({ active: !showAllTenants, includeSubscriptions: false })
  const dispatch = useDispatch()

  const globalTenant = useSelector((state: RootStateOrAny) => state.tenant)

  const data = useMemo(() => {
    const filteredTenants = showAllTenants ? tenants : tenants.filter((tenant) => tenant.subscriptions.length > 0)

    const mappedData = filteredTenants.map((tenant) => ({
      label: tenant.name,
      value: tenant.id,
      checked: globalTenant.id === tenant.id
    }))

    onChange?.((tenants.filter((tenant) => tenant.id === globalTenant.id) || [tenants[0]]).filter(Boolean))

    return mappedData
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAllTenants, tenants, globalTenant.id])

  const handleDropdownChange = (_curr: TreeNode, data: TreeNode[]) => {
    const topLevel = data.filter((d) => d._depth === 0)
    const selectedTenants = topLevel.map((tenant) => ({ id: tenant.value, name: tenant.label }))

    onChange?.(selectedTenants)

    const tenant = selectedTenants[0]
    if (!tenant) return

    dispatch(setTenant(tenant))
  }

  return <RefactoredDropdown data={data} onChange={handleDropdownChange} label="Tenant" loading={isLoading} mode="simpleSelect" />
}

export default TenantDropdown
