import { useMemo } from 'react'

import { type Tenant } from 'src/types/azure'

import { useTenantsQuery } from 'src/hooks/services/servicenow/queries/useTenantsQuery'
import type { Emission } from 'src/hooks/services/sustainability/queries/useEmissionsQuery'

import { type DateRange } from 'src/components/Common/MonthRangePicker'

interface ChartDataPoint {
  name: string
  value: number
  forecast: boolean
}

interface ChartDataEntry {
  date: string
  values: ChartDataPoint[]
}

type EmissionsChartData = ChartDataEntry[]

type Grouping = 'tenant' | 'subscriptionId' | 'azureServiceName' | 'scope' | 'region'
type EmissionWithTenant = Emission & { tenantId?: string }

const MAX_INDIVIDUAL_GROUPINGS = 7

function calculateTotalEmission(emission: EmissionWithTenant): number {
  return (emission.scope1 ?? 0) + (emission.scope2 ?? 0) + (emission.scope3 ?? 0)
}

function calculateCarbonIntensity(emission: number, usage: number): number {
  return usage > 0 ? emission / usage : 0
}

function getGroupingTotals(emissions: EmissionWithTenant[], grouping: Grouping, pieChart = false): Map<string, number> {
  const groupingTotals = new Map<string, number>()

  for (const emission of emissions) {
    let key
    switch (grouping) {
      case 'tenant':
        key = emission.tenantId ?? ''
        break
      case 'subscriptionId':
        key = emission.subscriptionId ?? ''
        break
      case 'azureServiceName':
        key = emission.azureServiceName ?? ''
        break
      case 'scope':
        key = 'scope'
        break
      case 'region':
        key = emission.azureRegionName ?? ''
        break
    }

    if (!key) continue

    if (pieChart && key === 'scope') {
      groupingTotals.set('scope1', (groupingTotals.get('scope1') ?? 0) + (emission.scope1 ?? 0))
      groupingTotals.set('scope2', (groupingTotals.get('scope2') ?? 0) + (emission.scope2 ?? 0))
      groupingTotals.set('scope3', (groupingTotals.get('scope3') ?? 0) + (emission.scope3 ?? 0))
    } else {
      const value = calculateTotalEmission(emission)
      groupingTotals.set(key, (groupingTotals.get(key) ?? 0) + value)
    }
  }

  return groupingTotals
}

function categorizeSubscriptions(subscriptionTotals: Map<string, number>): {
  relevantGroupings: string[]
  otherGroupings: string[]
} {
  const sortedGroupings = Array.from(subscriptionTotals.entries()).sort((a, b) => b[1] - a[1])
  return {
    relevantGroupings: sortedGroupings.slice(0, MAX_INDIVIDUAL_GROUPINGS).map(([id]) => id),
    otherGroupings: sortedGroupings.slice(MAX_INDIVIDUAL_GROUPINGS).map(([id]) => id)
  }
}

function processOtherEmissions(otherEmissions: EmissionWithTenant[]): Map<string, Emission> {
  const otherDateTotals = new Map<string, Emission>()

  for (const emission of otherEmissions) {
    const date = emission.date
    if (!date) continue

    const currentValue = otherDateTotals.get(date) ?? { date, scope1: 0, scope2: 0, scope3: 0 }
    otherDateTotals.set(date, {
      date,
      scope1: (currentValue.scope1 ?? 0) + (emission.scope1 ?? 0),
      scope2: (currentValue.scope2 ?? 0) + (emission.scope2 ?? 0),
      scope3: (currentValue.scope3 ?? 0) + (emission.scope3 ?? 0)
    })
  }

  return otherDateTotals
}

function reshapeData(emissions: EmissionWithTenant[], grouping: Grouping): EmissionsChartData {
  const dateMappedData = new Map<string, ChartDataPoint[]>()

  for (const emission of emissions) {
    const date = emission.date
    if (!date) continue

    if (!dateMappedData.has(date)) dateMappedData.set(date, [])

    const value = calculateTotalEmission(emission)

    if (['subscriptionId', 'azureServiceName', 'region'].includes(grouping)) {
      const name = grouping === 'region' ? (emission.azureRegionName as string) : (emission[grouping as keyof EmissionWithTenant] as string)
      dateMappedData.get(date)?.push({ name, value, forecast: false })
    } else if (grouping === 'scope') {
      const names = ['scope1', 'scope2', 'scope3']
      for (const name of names) {
        const scopeValue = (emission[name as keyof EmissionWithTenant] as number) ?? 0
        dateMappedData.get(date)?.push({ name, value: scopeValue, forecast: false })
      }
    } else {
      dateMappedData.get(date)?.push({ name: emission.tenantId as string, value, forecast: false })
    }
  }

  const dateRecords = Array.from(dateMappedData.entries())
    .map(([date, values]) => ({ date, values }))
    .sort((a, b) => a.date.localeCompare(b.date))

  return dateRecords
}

function collapseDuplicates(data: EmissionsChartData): EmissionsChartData {
  for (const { values } of data) {
    const groupedValues = new Map<string, number>()

    for (const { name, value } of values) {
      groupedValues.set(name, (groupedValues.get(name) ?? 0) + value)
    }

    const newValues = Array.from(groupedValues.entries()).map(([name, value]) => ({ name, value, forecast: false }))
    values.splice(0, values.length, ...newValues)
  }

  return data
}

function accumulateData(data: EmissionsChartData): EmissionsChartData {
  for (let i = 1; i < data.length; i++) {
    for (let j = 0; j < data[i].values.length; j++) {
      data[i].values[j].value += data[i - 1].values[j].value
    }
  }

  return data
}

function alterIdentifierToName(
  data: EmissionsChartData,
  selectedSubscriptions: { id: string; name: string }[],
  tenants: Tenant[] | undefined,
  grouping: Grouping
): EmissionsChartData {
  const subscriptionMap = new Map(selectedSubscriptions.map((s) => [s.id, s.name]))
  const tenantMap = new Map(tenants?.map((t) => [t.id, t.name]))

  for (const { values } of data) {
    for (const value of values) {
      if (!value.name) value.name = 'Other'

      switch (grouping) {
        case 'tenant':
          value.name = tenantMap.get(value.name) ?? value.name
          break
        case 'subscriptionId':
          value.name = subscriptionMap.get(value.name) ?? value.name
          break
        case 'azureServiceName':
          value.name = value.name
          break
        case 'scope':
          value.name = value.name === 'scope1' ? 'Scope 1' : value.name === 'scope2' ? 'Scope 2' : 'Scope 3'
          break
        case 'region':
          value.name = value.name
          break
      }
    }
  }

  return data
}

function filterBasedOnGrouping(data: EmissionWithTenant[], grouping: Grouping, selectedGroupingIds: string[]) {
  switch (grouping) {
    case 'tenant':
      return data.filter((d) => selectedGroupingIds.some((s) => s === d.tenantId))
    case 'subscriptionId':
      return data.filter((d) => selectedGroupingIds.some((s) => s === d.subscriptionId))
    case 'azureServiceName':
      return data.filter((d) => selectedGroupingIds.some((s) => s === d.azureServiceName))
    case 'scope':
      return data
    case 'region':
      return data.filter((d) => selectedGroupingIds.some((s) => s === d.azureRegionName))
  }
}

function sortBasedOnGrouping(data: EmissionWithTenant[], grouping: Grouping, selectedGroupingIds: string[]) {
  switch (grouping) {
    case 'tenant':
      return data.sort((a, b) => selectedGroupingIds.indexOf(a.tenantId ?? '') - selectedGroupingIds.indexOf(b.tenantId ?? ''))
    case 'subscriptionId':
      return data.sort((a, b) => selectedGroupingIds.indexOf(a.subscriptionId ?? '') - selectedGroupingIds.indexOf(b.subscriptionId ?? ''))
    case 'azureServiceName':
      return data.sort((a, b) => selectedGroupingIds.indexOf(a.azureServiceName ?? '') - selectedGroupingIds.indexOf(b.azureServiceName ?? ''))
    case 'scope':
      return data
    case 'region':
      return data.sort((a, b) => selectedGroupingIds.indexOf(a.azureRegionName ?? '') - selectedGroupingIds.indexOf(b.azureRegionName ?? ''))
  }
}

function alterDateToMonthName(data: EmissionsChartData) {
  return data.map((p) => ({
    ...p,
    date: new Date(p.date).toLocaleString('default', { month: 'short', year: 'numeric' })
  }))
}

function addMissingZeroValues(data: EmissionsChartData) {
  const uniqueNames = new Set<string>()
  for (const { values } of data) {
    for (const { name } of values) {
      uniqueNames.add(name)
    }
  }

  for (let i = 0; i < data.length; i++) {
    const { values } = data[i]
    for (const name of uniqueNames) {
      if (!values.some((v) => v.name === name)) {
        const valuesPosition = data.findLastIndex((p) => p.values.some((v) => v.name === name))
        const internalValuesPosition = data[valuesPosition].values.findIndex((v) => v.name === name)

        data[i].values.splice(internalValuesPosition, 0, { name, value: 0, forecast: false })
      }
    }
  }

  return data
}

export function useEmissionsChartData(
  emissions: Emission[] | undefined,
  selectedSubscriptions: { id: string; name: string }[],
  dateRange: DateRange,
  grouping = 'tenant',
  useIntensity = false,
  cumulative = false
): EmissionsChartData {
  const { data: tenants } = useTenantsQuery({ active: false })

  const selectedData = useMemo(() => {
    if (!emissions?.length) return []

    if (!useIntensity) return emissions

    return emissions.map((e) => ({
      ...e,
      scope1: calculateCarbonIntensity(e.scope1 ?? 0, e.usageUSD ?? 0),
      scope2: calculateCarbonIntensity(e.scope2 ?? 0, e.usageUSD ?? 0),
      scope3: calculateCarbonIntensity(e.scope3 ?? 0, e.usageUSD ?? 0)
    }))
  }, [emissions, useIntensity])

  const data = useMemo(() => {
    if (!selectedData?.length) return []

    const timezoneAdjustedEmissionsData = selectedData.map((e) => {
      const date = new Date(e.date ?? '')
      date.setHours(date.getHours() + date.getTimezoneOffset() / 60)
      return { ...e, parsedDate: date }
    })

    const dateFilteredEmissions = timezoneAdjustedEmissionsData.filter((e) => e.parsedDate >= dateRange.startDate && e.parsedDate <= dateRange.endDate)

    const tenantExtendedEmissions = dateFilteredEmissions.map((e) => {
      const tenant = tenants?.find((t) => t.subscriptions.some((s) => s.id === e.subscriptionId))
      return { ...e, tenantId: tenant?.id }
    })

    const groupingTotals = getGroupingTotals(tenantExtendedEmissions, grouping as Grouping)
    const { relevantGroupings, otherGroupings } = categorizeSubscriptions(groupingTotals)

    const relevantEmissions = filterBasedOnGrouping(tenantExtendedEmissions, grouping as Grouping, relevantGroupings)
    const relevantSortedEmissions = sortBasedOnGrouping(relevantEmissions, grouping as Grouping, relevantGroupings)

    const otherEmissions = filterBasedOnGrouping(tenantExtendedEmissions, grouping as Grouping, otherGroupings)
    const otherDateTotals = processOtherEmissions(otherEmissions)

    let combinedEmissions: EmissionWithTenant[] = []
    if (grouping === 'scope') {
      combinedEmissions = relevantSortedEmissions
    } else {
      combinedEmissions = [...relevantSortedEmissions, ...Array.from(otherDateTotals.values())]
    }

    const reshapedData = reshapeData(combinedEmissions, grouping as Grouping)

    const collapsedData = collapseDuplicates(reshapedData)

    addMissingZeroValues(collapsedData)

    cumulative ? accumulateData(collapsedData) : reshapedData

    const nameAlteredData = alterIdentifierToName(collapsedData, selectedSubscriptions, tenants, grouping as Grouping)

    const dateReplacedWithMonth = alterDateToMonthName(nameAlteredData)

    return dateReplacedWithMonth
  }, [selectedData, selectedSubscriptions, dateRange, grouping, cumulative, tenants])

  return data
}

type UseEmissionsPieChartDataOutput = { name: string; value: number }[]

export function useEmissionsPieChartData(
  emissions: Emission[] | undefined,
  selectedSubscriptions: { id: string; name: string }[],
  grouping = 'tenant',
  useIntensity = false
): UseEmissionsPieChartDataOutput {
  const { data: tenants } = useTenantsQuery({ active: false })

  const selectedData = useMemo(() => {
    if (!emissions?.length) return []

    if (!useIntensity) return emissions

    return emissions.map((e) => ({
      ...e,
      scope1: calculateCarbonIntensity(e.scope1 ?? 0, e.usageUSD ?? 0),
      scope2: calculateCarbonIntensity(e.scope2 ?? 0, e.usageUSD ?? 0),
      scope3: calculateCarbonIntensity(e.scope3 ?? 0, e.usageUSD ?? 0)
    }))
  }, [emissions, useIntensity])

  return useMemo(() => {
    if (!selectedData?.length) return []

    const tenantExtendedEmissions = selectedData.map((e) => {
      const tenant = tenants?.find((t) => t.subscriptions.some((s) => s.id === e.subscriptionId))
      return { ...e, tenantId: tenant?.id }
    })

    const groupingTotals = getGroupingTotals(tenantExtendedEmissions, grouping as Grouping, true)
    const { relevantGroupings, otherGroupings } = categorizeSubscriptions(groupingTotals)

    const pieChartData: UseEmissionsPieChartDataOutput = []

    for (const [key, value] of groupingTotals) {
      if (relevantGroupings.includes(key)) {
        let name = key
        switch (grouping) {
          case 'tenant':
            name = tenants?.find((t) => t.id === key)?.name ?? key
            break
          case 'subscriptionId':
            name = selectedSubscriptions.find((s) => s.id === key)?.name ?? key
            break
          case 'azureServiceName':
            name = key
            break
          case 'scope':
            name = key === 'scope1' ? 'Scope 1' : key === 'scope2' ? 'Scope 2' : 'Scope 3'
            break
          case 'region':
            name = key
            break
        }
        pieChartData.push({ name, value })
      }
    }

    if (otherGroupings.length > 0 && grouping !== 'scope') {
      const otherValue = otherGroupings.reduce((sum, key) => sum + (groupingTotals.get(key) ?? 0), 0)
      pieChartData.push({ name: 'Other', value: otherValue })
    }

    return pieChartData.sort((a, b) => b.value - a.value)
  }, [selectedData, selectedSubscriptions, grouping, tenants])
}
