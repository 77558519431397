const initialState = {
  authorised: true
}

const authorisedReducer = (
  state = initialState,
  action: {
    type: string
    payload: boolean
  }
): {
  authorised: boolean
} => {
  switch (action.type) {
    case 'SET_AUTHORISED':
      return {
        authorised: action.payload
      }
    default:
      return state
  }
}

export default authorisedReducer
