import {
  type ColumnDef,
  type PaginationState,
  type RowSelectionState,
  type SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'

import { useEffect, useState } from 'react'

import { PiCaretDown, PiCaretLeft, PiCaretRight, PiCaretUp } from 'react-icons/pi'

import Spinner from '../Spinner'

interface PaginatedTableProps<T extends object> {
  data: T[]
  columns: ColumnDef<T>[]
  hiddenColumns?: string[]
  bordered?: boolean
  onRowClick?: (row: T) => void | Promise<void>
  onRowSelectionChange?: (selectedRows: T[]) => void
  defaultSortBy?: SortingState
  // New pagination props
  totalRows: number
  pageCount: number
  isLoading?: boolean
  onPaginationChange: (pagination: PaginationState) => void
  onSortingChange?: (sorting: SortingState) => void
}

export default function PaginatedTable<T extends object>({
  data,
  columns,
  hiddenColumns = [],
  bordered = false,
  onRowClick,
  onRowSelectionChange,
  defaultSortBy = [],
  totalRows,
  pageCount,
  isLoading = false,
  onPaginationChange,
  onSortingChange
}: PaginatedTableProps<T>) {
  const [sorting, setSorting] = useState<SortingState>(defaultSortBy)
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  })

  useEffect(() => {
    setRowSelection({})
  }, [data])

  useEffect(() => {
    onRowSelectionChange?.(Object.keys(rowSelection).map((index) => data[Number(index)]))
  }, [rowSelection, data, onRowSelectionChange])

  useEffect(() => {
    onPaginationChange({ pageIndex, pageSize })
  }, [pageIndex, pageSize, onPaginationChange])

  useEffect(() => {
    onSortingChange?.(sorting)
  }, [sorting, onSortingChange])

  const table = useReactTable({
    data,
    columns,
    pageCount,
    state: {
      sorting,
      rowSelection,
      pagination: {
        pageIndex,
        pageSize
      }
    },
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    manualSorting: true,
    initialState: {
      columnVisibility: hiddenColumns.reduce((acc, columnId) => ({ ...acc, [columnId]: false }), {})
    }
  })

  const paginationRange = Array.from({ length: Math.min(5, pageCount) }, (_, i) => {
    let start = pageIndex - 2
    if (start < 0) start = 0
    if (start > pageCount - 5) start = Math.max(0, pageCount - 5)
    return start + i + 1
  }).filter((page) => page <= pageCount)

  return (
    <div className={`rounded-content bg-th-content py-4 ${bordered ? 'border border-th-border' : ''}`}>
      <div className="overflow-x-auto">
        <table className="w-full text-sm">
          <thead className="border-b border-th-border text-th-text-secondary">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="cursor-pointer select-none whitespace-nowrap p-4 text-left first:pl-8 hover:text-th-text"
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <div className="flex items-center gap-2">
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {header.column.getIsSorted() &&
                        (header.column.getIsSorted() === 'desc' ? <PiCaretDown className="h-4 w-4" /> : <PiCaretUp className="h-4 w-4" />)}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="font-bold text-th-text">
            {isLoading ? (
              <tr>
                <td colSpan={columns.length} className="p-3 text-center">
                  <Spinner className="mx-auto h-7 w-7" />
                </td>
              </tr>
            ) : table.getRowModel().rows.length === 0 ? (
              <tr>
                <td colSpan={columns.length} className="p-4 text-center">
                  No data available
                </td>
              </tr>
            ) : (
              table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  onClick={() => onRowClick?.(row.original)}
                  className={`border-b border-th-border ${onRowClick ? 'cursor-pointer hover:shadow' : ''}`}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id} className="p-4 first:pl-8">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="grid grid-cols-3 gap-4 border-t border-th-border px-8 pt-4 text-sm font-bold">
        <select
          value={pageSize}
          onChange={(e) => table.setPageSize(Number(e.target.value))}
          className="w-min rounded-lg border border-th-border bg-th-content px-2 py-1 text-xs"
        >
          {[5, 10, 20, 25, 50, 100].map((size) => (
            <option key={size} value={size}>
              Show: {size}
            </option>
          ))}
        </select>

        <div className="flex items-center justify-center gap-2">
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage() || isLoading}
            className="rounded-lg border border-th-border px-2 py-1"
          >
            <PiCaretLeft className="h-5 w-5" />
          </button>
          {paginationRange.map((pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => table.setPageIndex(pageNumber - 1)}
              disabled={isLoading}
              className={`rounded-lg border px-4 py-1 ${pageNumber === pageIndex + 1 ? 'border-th-primary focus:shadow-none' : 'border-th-border'}`}
            >
              {pageNumber}
            </button>
          ))}
          <button onClick={() => table.nextPage()} disabled={!table.getCanNextPage() || isLoading} className="rounded-lg border border-th-border px-2 py-1">
            <PiCaretRight className="h-5 w-5" />
          </button>
        </div>

        <div className="flex items-center justify-end text-xs">
          {isLoading
            ? 'Loading...'
            : totalRows === 0
              ? 'Showing 0 entries'
              : `Showing ${pageSize * pageIndex + 1} to ${Math.min(pageSize * (pageIndex + 1), totalRows)} of ${totalRows} entries`}
        </div>
      </div>
    </div>
  )
}
