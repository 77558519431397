import { type FormEvent, Fragment, type FunctionComponent, useState } from 'react'

import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { showAlert } from '../../actions/alertsActions'
import { closeDrawers } from '../../actions/drawerActions'
import api from '../../constants/api'
import Button from '../Common/Button'
import Drawer from '../Common/Drawer'
import Submit from '../Common/Submit'

const NewAzurePlan: FunctionComponent = (): JSX.Element => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const account = useSelector((state: RootStateOrAny) => state.account)

  /* drawer actions */
  const Actions: FunctionComponent = (): JSX.Element => {
    return (
      <Fragment>
        <Button bordered onClick={() => dispatch(closeDrawers())}>
          Cancel
        </Button>
        <Submit variant="primary" form="newAzurePlanForm" />
      </Fragment>
    )
  }

  const newAzurePlan = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    const azurePlan = {
      account: account.id,
      contact_type: 'Provide',
      category: 'Incident',
      title: 'New Azure Plan Request',
      users_affected: 'Single',
      business_impact: 'No Immediate Impact',
      description: 'New Azure Plan Request subbmitted from PROVIDE'
    }

    api
      .post(`/servicenow/account/${account.id}/ticket`, azurePlan)
      .then((response) => {
        setLoading(false)
        dispatch(closeDrawers())

        /* trigger azurePlan table re-render to display the new azurePlan */
        navigate('/tickets', { state: azurePlan })

        dispatch(
          showAlert({
            type: 'success',
            message: response.data.number + ' created.',
            component: 'NewAzurePlan'
          })
        )
      })
      .catch((error) => {
        setLoading(false)
        dispatch(
          showAlert({
            type: 'error',
            message: 'We were unable to create your azurePlan. Please try again.',
            component: 'NewAzurePlan',
            error
          })
        )
      })
  }

  return (
    <Drawer title={'Request new Azure Subscription'} loading={loading} actions={<Actions />}>
      <form onSubmit={(e) => newAzurePlan(e)} id="newAzurePlanForm" className="flex flex-col gap-4">
        <p>
          Submitting this request will trigger the creation of a new Azure Subscription for you and will also sign up Cloud Direct as your Microsoft Cloud
          Solution Provider for your Microsoft Cloud account.
        </p>
        <p>Please read the terms and conditions carefully and only submit the request if you accept and agree.</p>
        <a
          href="https://portal.office.com/partner/partnersignup.aspx?type=ResellerRelationship&id=cdcsppartnerid&csp=1&msppid=0"
          target="_blank"
          rel="noreferrer"
        >
          https://portal.office.com/partner/partnersignup.aspx?type=ResellerRelationship&id=cdcsppartnerid&csp=1&msppid=0
        </a>
      </form>
    </Drawer>
  )
}

export default NewAzurePlan
