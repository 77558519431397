import { type FunctionComponent, useEffect, useState } from 'react'

import { type Recommendation as RecommendationType } from '../../types/azure'

import moment from 'moment'
import { type RootStateOrAny, useSelector } from 'react-redux'

import Drawer from '../Common/Drawer'
import State from '../Common/State'

const Recommendation: FunctionComponent = (): JSX.Element => {
  const recommendation: RecommendationType = useSelector((state: RootStateOrAny) => state.drawers.recommendation)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(false)
  }, [])

  /* drawer content */
  return (
    <Drawer title={'Recommendation'} loading={loading}>
      <div className="flex flex-col gap-4">
        {/* tenant */}
        <div className="flex justify-between">
          <div>
            <div className="font-bold">Tenant</div>
            <div className="text-sm">{recommendation.tenantName}</div>
          </div>
          {/* impact */}
          <div className="h-min">
            <State state={recommendation.properties.impact + ' Impact'} />
          </div>
        </div>

        {/* subscription */}
        <div>
          <div className="font-bold">Subscription</div>
          <div className="text-sm">{recommendation.subscriptionName}</div>
        </div>

        {/* category */}
        <div>
          <div className="font-bold">Category</div>
          <div className="text-sm">{recommendation.properties.category}</div>
        </div>

        {/* problem */}
        <div>
          <div className="font-bold">Problem</div>
          <div className="text-sm">{recommendation.properties.shortDescription.problem}</div>
        </div>

        {/* impacted field */}
        <div>
          <div className="font-bold">Impacted Field</div>
          <div className="text-sm">{recommendation.properties.impactedField}</div>
        </div>

        {/* impacted value */}
        <div>
          <div className="font-bold">Impacted Value</div>
          <div className="text-sm">{recommendation.properties.impactedValue}</div>
        </div>

        {/* updated */}
        <div>
          <div className="font-bold">Updated</div>
          <div className="text-sm">{moment(recommendation.properties.lastUpdated).format('DD/MM/yy HH:mm')}</div>
        </div>
      </div>

      {/* extended properties */}
      {recommendation.properties.extendedProperties && recommendation.properties.extendedProperties.annualSavingsAmount && (
        <div className="mt-8 flex flex-col gap-4">
          <hr className="mb-4 border-th-border" />

          {/* annual savings amount */}
          <div>
            <div className="font-bold">Annual Savings Amount</div>
            <div className="text-sm">
              {recommendation.properties.extendedProperties.annualSavingsAmount + ' ' + recommendation.properties.extendedProperties.savingsCurrency}
            </div>
          </div>

          {/* location */}
          <div>
            <div className="font-bold">Location</div>
            <div className="text-sm">{recommendation.properties.extendedProperties.location}</div>
          </div>

          {/* reservation type */}
          <div>
            <div className="font-bold">Reservation Type</div>
            <div className="text-sm">{recommendation.properties.extendedProperties.reservationType}</div>
          </div>

          {/* savings percentage */}
          <div>
            <div className="font-bold">Savings Percentage</div>
            <div className="text-sm">{recommendation.properties.extendedProperties.savingsPercentage}</div>
          </div>

          {/* scope */}
          <div>
            <div className="font-bold">Scope</div>
            <div className="text-sm">{recommendation.properties.extendedProperties.scope}</div>
          </div>

          {/* term */}
          <div>
            <div className="font-bold">Term</div>
            <div className="text-sm">{recommendation.properties.extendedProperties.term}</div>
          </div>

          {/* target resource count */}
          <div>
            <div className="font-bold">Target Resource Count</div>
            <div className="text-sm">{recommendation.properties.extendedProperties.targetResourceCount}</div>
          </div>

          {/* vm size */}
          <div>
            <div className="font-bold">VM Size</div>
            <div className="text-sm">{recommendation.properties.extendedProperties.vmSize}</div>
          </div>
        </div>
      )}
    </Drawer>
  )
}

export default Recommendation
