import { type FunctionComponent } from 'react'

import { IoCloseCircleOutline } from 'react-icons/io5'
import { useDispatch } from 'react-redux'

import { closeNavigation } from '../../../../actions/navigationActions'

const Close: FunctionComponent = (): JSX.Element => {
  const dispatch = useDispatch()

  return (
    <button onClick={() => dispatch(closeNavigation())} className="mx-4 flex h-16 w-16 items-center justify-center rounded-full bg-th-lowlight p-4 xs:hidden">
      <IoCloseCircleOutline className="h-full w-full" />
    </button>
  )
}

export default Close
