import { type ColumnDef } from '@tanstack/react-table'

import React, { useMemo } from 'react'

import { type Charge, type ReservedInstance, type SoftwareSubscription } from 'src/types/azure'

import Popover from 'src/components/Common/Popover'
import Table from 'src/components/Common/Table/Table'

import { classNames } from 'src/utils/classNames'
import { format, formatCurrency } from 'src/utils/format'

import moment from 'moment'
import { PiWarningFill } from 'react-icons/pi'

interface AzureRecurringSpendTableProps {
  data: Array<ReservedInstance | SoftwareSubscription>
  onRowClick: (row: ReservedInstance | SoftwareSubscription) => void
}

const AzureRecurringSpendTable: React.FC<AzureRecurringSpendTableProps> = ({ data, onRowClick }) => {
  const columns = useMemo<ColumnDef<ReservedInstance | SoftwareSubscription>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        cell: (info) => info.getValue()
      },
      {
        accessorKey: 'type',
        header: 'Type'
      },
      {
        accessorKey: 'state',
        header: 'State'
      },
      {
        accessorKey: 'expires',
        header: 'Expire',
        cell: (info) => {
          const value = info.getValue() as string | undefined
          return (
            <div className={classNames('flex items-center gap-2', value && moment(value).isBefore(moment()) && 'text-th-red')}>
              <p>{value ? moment(value).format('DD/MM/YY') : ''}</p>
              {value && moment(value).isBefore(moment()) && (
                <Popover text={`Expired ${moment(value).fromNow()}`}>
                  <PiWarningFill className="h-4 w-4" />
                </Popover>
              )}
            </div>
          )
        },
        sortingFn: (rowA, rowB) => {
          const a = (rowA.original as ReservedInstance).expires
          const b = (rowB.original as ReservedInstance).expires
          if (!a) return -2
          if (!b) return 2
          return moment(a).isBefore(moment(b)) ? 1 : moment(a).isAfter(moment(b)) ? -1 : 0
        }
      },
      {
        accessorKey: 'term',
        header: 'Term',
        cell: (info) => <span>{format(info.getValue() as string, 'term')}</span>
      },
      {
        accessorKey: 'billing_plan',
        header: 'Billing Plan',
        cell: (info) => <span>{format(info.getValue() as string, 'billingPlan')}</span>
      },
      {
        accessorKey: 'product_id',
        header: 'Product Identifier'
      },
      {
        accessorKey: 'quantity',
        header: 'Quantity'
      },
      {
        accessorKey: 'cost',
        header: 'Total Charge',
        cell: (info) => <p className="font-bold">{formatCurrency(info.getValue() as number, true)}</p>
      },
      {
        accessorKey: 'charges',
        header: 'Last Charge',
        cell: (info) => {
          const charges = info.getValue() as Charge[]
          return <p className="font-bold">{formatCurrency(charges?.[0]?.cost ?? 0, true)}</p>
        }
      }
    ],
    []
  )

  return <Table data={data} columns={columns} onRowClick={(row) => onRowClick(row)} defaultSortBy={[{ id: 'expire', desc: true }]} />
}

export default AzureRecurringSpendTable
