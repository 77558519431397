import React from 'react'

import { Button } from 'src/components/Common/Button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from 'src/components/Common/Dialog'

interface MultiStepModalProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  title: string
  stepTitle: string
  isLoading: boolean
  disabled: boolean
  currentStep: number
  totalSteps: number
  onNext: () => void
  onPrevious: () => void
  children: React.ReactNode
}

const MultiStepModal: React.FC<MultiStepModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  stepTitle,
  isLoading,
  disabled,
  currentStep,
  totalSteps,
  onNext,
  onPrevious,
  children
}) => {
  const isLastStep = currentStep === totalSteps

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4 text-th-text">
          <div className="flex justify-between text-sm text-th-text-secondary">
            <span>
              Step {currentStep} of {totalSteps}
            </span>
            <span>{stepTitle}</span>
          </div>
          <div className="mb-4">{children}</div>
        </div>
        <DialogFooter className="sm:justify-between">
          {currentStep > 1 && (
            <Button onClick={onPrevious} bordered>
              Previous
            </Button>
          )}
          {!isLastStep ? (
            <Button onClick={onNext} bordered className="ml-auto">
              Next
            </Button>
          ) : (
            <Button onClick={onConfirm} disabled={disabled || isLoading} variant="primary">
              {isLoading ? 'Uploading...' : 'Confirm Upload'}
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default MultiStepModal
