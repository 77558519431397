import { type FunctionComponent, useEffect, useState } from 'react'

import { type Tenant } from '../../../../types/azure'
import { type Cart, type CartLineItem } from '../../../../types/cart'
import { type Availability, type Price } from '../../../../types/microsoft365'

import useUpdateCartItemQuantityMutation from 'src/hooks/services/microsoft/mutations/useUpdateCartItemQuantityMutation'

import Spinner from 'src/components/Common/Spinner'

import axios, { type AxiosResponse } from 'axios'
import { Iconly } from 'react-iconly'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { showAlert } from '../../../../actions/alertsActions'
import { startLoading, stopLoading } from '../../../../actions/loadingActions'
import Button from '../../../../components/Common/Button'
import Input from '../../../../components/Common/Input'
import Popover from '../../../../components/Common/Popover'
import Tile from '../../../../components/Common/Tile'
import api from '../../../../constants/api'

interface Props {
  tenant: Tenant
  cart: Cart
  item: CartLineItem
  product: Availability['product']
  price: Price
  setCart: (cart: Cart | null) => void

  setUpdating: (updating: boolean) => void
}

const CartItem: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { tenant, cart, item, setUpdating } = props

  const [quantity, setQuantity] = useState<number>(item.Quantity)
  useEffect(() => setQuantity(item.Quantity), [item.Quantity])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setUpdating(item.Quantity !== quantity), [item.Quantity, quantity])

  const dispatch = useDispatch()
  const account = useSelector((state: RootStateOrAny) => state.account)

  const termDurations = {
    P1M: '1 Month',
    P1Y: '1 Year',
    P3Y: '3 Years'
  }

  const updateCartItemMutation = useUpdateCartItemQuantityMutation()

  /* update line item quantity */
  const updateQuantity = () => {
    if (!cart || item.Quantity === quantity) return
    if (quantity === 0) return removeItem()

    if (cart.Id === undefined || item.Id === undefined) return console.error('Cart or item id is undefined')

    dispatch(startLoading())
    updateCartItemMutation.mutate(
      { tenantId: tenant.id, cartId: cart.Id, itemId: item.Id, quantity },
      {
        onSuccess: (data) => {
          dispatch(
            showAlert({
              type: 'success',
              message: 'Item quantity successfully updated.',
              component: 'Availability'
            })
          )

          props.setCart(data)
        },
        onError: (error) => {
          if (!axios.isCancel(error)) {
            dispatch(
              showAlert({
                type: 'error',
                message: error.response?.data,
                component: 'Cart',
                error
              })
            )
          }
        },
        onSettled: () => dispatch(stopLoading())
      }
    )
  }

  /* remove an item from the cart */
  const removeItem = () => {
    dispatch(startLoading())

    if (cart) {
      api
        .put(`/ms-api/orders/account/${account.id}/${tenant.id}/cart/${cart.Id}/item/${item.Id}/remove`)
        .then((response: AxiosResponse) => {
          /* if the last item was removed from the cart then delete it */
          if (response.status === 204) {
            localStorage.removeItem(`cart-${tenant.id}`)
            dispatch(
              showAlert({
                type: 'success',
                message: 'Cart successfully emptied.',
                component: 'Availability'
              })
            )
            /* refresh the cart ui */
            props.setCart(null)
            return dispatch(stopLoading())
          }

          dispatch(
            showAlert({
              type: 'success',
              message: 'Item successfully removed from your cart.',
              component: 'Availability'
            })
          )

          /* refresh the cart ui */
          props.setCart(response.data)
          dispatch(stopLoading())
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            /* remove the stored cart id if the cart no longer exists */
            if (error.response?.status === 404) {
              localStorage.removeItem(`cart-${tenant.id}`)
            }

            dispatch(
              showAlert({
                type: 'error',
                message: 'Your cart has expired.',
                component: 'Cart',
                error
              })
            )
            dispatch(stopLoading())
          }
        })
    }
  }

  return (
    <Tile className="p-6">
      {props.product ? (
        <div className="">
          <div className="flex justify-between">
            {/* title */}
            <div className="text-lg font-bold">{props.product.title}</div>
            {/* remove */}
            <Popover text="Remove">
              <Iconly name="Delete" set="light" onClick={() => removeItem()} className="h-5 w-5 cursor-pointer">
                Remove
              </Iconly>
            </Popover>
          </div>
          <div className="flex flex-wrap items-center justify-between gap-10">
            <div className="flex flex-col gap-2 sm:w-full">
              {/* id */}
              <div className="text-sm text-th-text-secondary">{item.CatalogItemId}</div>
              {/* error */}
              {item.Error && (
                <div className="text-th-error">
                  <div>{item.Error.ErrorDescription}</div>
                </div>
              )}
              {/* description */}
              <div>{props.product.description}</div>
            </div>
            <div className="flex w-full justify-between lg:w-max">
              {/* billing cycle */}
              <div className="w-28 flex-shrink-0">
                <div className="font-bold">Billing Cycle</div>
                <div>{item.BillingCycle.toString().charAt(0).toUpperCase() + item.BillingCycle.toString().slice(1)}</div>
              </div>
              {/* term duration */}
              <div className="flex w-28 flex-shrink-0 flex-col gap-1">
                <div className="font-bold">Term Duration</div>
                <div>{termDurations[item.TermDuration as keyof typeof termDurations]}</div>
              </div>
            </div>
            <div className="flex w-full flex-wrap items-center justify-between gap-8 lg:w-max">
              {/* quantity */}
              <div className="flex items-center gap-2">
                <Input type="number" name="quantity" placeholder="Quantity" min={1} value={quantity} onChange={(e) => setQuantity(parseInt(e.target.value))} />
                <Button
                  bordered
                  variant="primary"
                  onClick={() => updateQuantity()}
                  disabled={item.Quantity === quantity}
                  isLoading={updateCartItemMutation.isLoading}
                >
                  Update
                </Button>
              </div>
              {/* price */}
              <div className="flex w-32 flex-shrink-0 justify-center">
                {props.price ? (
                  props.price.erp === 0 ? (
                    'Free'
                  ) : (
                    <div>
                      <span className="font-bold">
                        £
                        {props.price.billingPlan.trim() === 'Monthly' && props.price.termDuration.trim() === 'P1Y'
                          ? ((props.price.erp * quantity) / 12).toFixed(2)
                          : (props.price.erp * quantity).toFixed(2)}
                        &nbsp; excl. VAT
                      </span>
                    </div>
                  )
                ) : (
                  'Price Unavailable'
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Spinner className="mx-auto h-6 w-6" />
      )}
    </Tile>
  )
}

export default CartItem
