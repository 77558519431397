import type { UseBaseQueryOptions } from '@tanstack/react-query'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useQuery from 'src/hooks/utils/useQuery'

import type { Folder } from './useFilesQuery'

interface UseGetPathToRootQueryParams {
  folderId: string
}

const useGetPathToRootQuery = ({ folderId }: UseGetPathToRootQueryParams, options?: UseBaseQueryOptions<Folder[]>) => {
  const account = useAccount()

  return useQuery(
    ['getPathToRootQuery', folderId, account.id],
    async ({ signal }) => {
      const response = await api.post(`/provide/${account.id}/file/getPathToRoot`, { folderId }, { signal })
      return response.data as Folder[]
    },
    {
      enabled: !!account.id && !!folderId,
      refetchOnWindowFocus: false,
      ...options
    }
  )
}

export default useGetPathToRootQuery
