import { type FunctionComponent, useEffect, useState } from 'react'

import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import Scrollbar from 'react-smooth-scrollbar'

import { showAlert } from '../../actions/alertsActions'
import { closeModals } from '../../actions/modalActions'
import api from '../../constants/api'
import Modal from '../Common/Modal'

const SignQuoteModal: FunctionComponent = (): JSX.Element => {
  const [loading, setLoading] = useState(true)
  const [loadingStage, setLoadingStage] = useState('...')
  const [signableIframeURL, setSignableIframeURL] = useState('')
  const [directSignableURL, setDirectSignableURL] = useState('')
  const [embedded_url_received, setEmbeddedUrlReceived] = useState(false)
  const dispatch = useDispatch()

  const open = useSelector((state: RootStateOrAny) => state.modals.signquotemodal)
  const quote = useSelector((state: RootStateOrAny) => state.modals.quote)

  const getDirectLinkFromEmbedded = (str: string) => {
    const start = str.indexOf('iframe src="') + 'iframe src="'.length
    const end = str.indexOf('" width="100%" height="95%" frameborder="0" style="border: 0; overflow: hidden"></iframe>')
    return str.substring(start, end)
  }

  /* get the users accounts */
  useEffect(() => {
    if (open) {
      setLoading(true)
      setLoadingStage('Loading quote details')
      api
        .get(`old-provide/sign_quote/get_quote_info?id=${quote.encrypted_number}`)
        .then((response) => {
          if (response.data.error) {
            dispatch(
              showAlert({
                type: 'error',
                message: 'Error fetching details for Quote',
                component: 'SignQuote'
              })
            )
            setLoading(false)
            dispatch(closeModals())
            return
          }

          const quoteDetails = JSON.parse(response.data.quote_details)
          const quoteState = quoteDetails.qut_u_state

          const content = JSON.parse(quoteDetails.qut_u_contract_content)

          if (quoteState !== 'Sent') {
            if (quoteState !== 'Delegated') {
              dispatch(
                showAlert({
                  type: 'error',
                  message: 'This quote has been handled already',
                  component: 'SignQuote'
                })
              )
              setLoading(false)
              dispatch(closeModals())
              return
            }
          }

          const config = {
            headers: {
              'Content-type': 'application/x-www-form-urlencoded',
              Authorization: 'Basic cGRmZ2VuOkJXOW5pTVNU'
            }
          }

          const postData = new URLSearchParams()
          postData.append('quote_sys_id', quote.id)
          postData.append('quote_body', content.body)
          postData.append('quote_header', content.header)
          postData.append('quote_footer', content.footer)
          postData.append('internal', 'true')
          postData.append('snow_instance', 'cdstaging')

          api
            .post(`old-provide/sign_quote/pdf_gen`, postData, config)
            .then((response) => {
              const pdf = response.data.pdf
              const postData2 = new URLSearchParams()
              postData2.append('doc_title', quote.number)
              postData2.append('pdf', pdf)
              postData2.append('organisation', quoteDetails.acc_name)
              postData2.append('fname', quoteDetails.cnt_first_name)
              postData2.append('lname', quoteDetails.cnt_last_name)
              postData2.append('email', quoteDetails.cnt_email)
              postData2.append('job_title', quoteDetails.usr_title)
              postData2.append('id', quote.number)
              postData2.append('sys_id', quote.id)
              postData2.append('snow_instance', 'cdstaging')
              postData2.append('self_sign', '1')

              setLoadingStage('Loading signing interface from Signable ')
              api
                .post(`old-provide/sign_quote/send_doc`, postData2, config)
                .then((response) => {
                  setLoading(false)
                  if (response.data.embedded_signing_url) {
                    setEmbeddedUrlReceived(true)
                    const directURL = response.data.embedded_signing_url ? getDirectLinkFromEmbedded(response.data.embedded_signing_url) : ''
                    const tweakedIFrameHTML = response.data.embedded_signing_url.replace(
                      '<iframe',
                      '<iframe onload="javascript:(function(o){o.style.height=o.contentWindow.document.body.scrollHeight+"px";o.style.width=o.contentWindow.document.body.scrollWidth+"px";}(this));" style="height:800px; width:1500px; border:none;overflow:hidden;" '
                    )
                    setDirectSignableURL(directURL)
                    setSignableIframeURL(tweakedIFrameHTML)
                  } else {
                    dispatch(closeModals())
                    dispatch(
                      showAlert({
                        type: 'error',
                        message: 'We were unable to connect to Signable for quote signing.',
                        component: 'SignQuote'
                      })
                    )
                  }
                })
                .catch(() => {
                  setLoading(false)
                })
            })
            .catch((error) => {
              setLoading(false)
              dispatch(closeModals())
              dispatch(
                showAlert({
                  type: 'error',
                  message: 'We were unable to generate quote PDF for signing.',
                  component: 'SignQuote',
                  error
                })
              )
            })
        })
        .catch((error) => {
          setLoading(false)
          dispatch(closeModals())
          dispatch(
            showAlert({
              type: 'error',
              message: 'We were unable to fetch Quote details.',
              component: 'SignQuote',
              error
            })
          )
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quote, open])

  return (
    <Modal
      title={'Signing ' + quote.number}
      open={open}
      loading={loading}
      className={loading ? 'w-screen xs:w-96' : 'lg:w-320 w-full xs:w-full'}
      loadingStage={loadingStage}
    >
      <div className="flex font-body">
        <Scrollbar className="flex-auto">
          {embedded_url_received && (
            <div>
              <div
                className="overflow-scroll"
                dangerouslySetInnerHTML={{
                  __html: signableIframeURL
                }}
              />
              {directSignableURL && (
                <div>
                  <p className="m-2 text-xs">
                    Trouble seeing this page?{' '}
                    <a className="underline" target="_blank" rel="noreferrer" href={directSignableURL}>
                      Click here
                    </a>
                  </p>
                </div>
              )}
            </div>
          )}
        </Scrollbar>
      </div>
    </Modal>
  )
}

export default SignQuoteModal
