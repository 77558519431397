import { type PersistedClient, type Persister } from '@tanstack/react-query-persist-client'

import { del, get, set } from 'idb-keyval'

const sizeExceptions = ['userQuery', 'accountsQuery']
/**
 * Creates an Indexed DB persister
 * @see https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API
 */
export function createIDBPersister(idbValidKey: IDBValidKey = 'reactQuery') {
  const timeframe = 24 * 60 * 60 * 1000 // 24 hours in milliseconds
  const sizeLimit = 5 * 1024 * 10 // ~5MB

  return {
    persistClient: async (client: PersistedClient) => {
      // Remove all the data entries on the client query that is older than 24 hours
      const filteredClientState = client.clientState.queries.filter(
        (query) =>
          query.state.dataUpdatedAt > Date.now() - timeframe &&
          (JSON.stringify(query.state.data).length < sizeLimit || sizeExceptions.includes((query.queryKey[0] as string) ?? 'Unknown'))
      )
      client.clientState.queries = filteredClientState

      set(idbValidKey, JSON.stringify(client))
    },
    restoreClient: async () => {
      return JSON.parse((await get(idbValidKey)) || '{}') as PersistedClient
    },
    removeClient: async () => {
      await del(idbValidKey)
    }
  } as Persister
}
