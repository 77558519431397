import { useMemo, useState } from 'react'

import { type Tenant } from 'src/types/azure'

import useTags from 'src/hooks/services/microsoft/queries/useTags'

import type moment from 'moment'
import { useDispatch } from 'react-redux'
import Scrollbar from 'react-smooth-scrollbar'

import { closeModals } from '../../actions/modalActions'
import Input from '../Common/Input'
import PortalModal from './PortalModal'

interface TagSelectModalProps {
  dateRange: [moment.Moment, moment.Moment]
  tenants: Array<Tenant>
  onTagChange: (selectedTag: string | null) => void
  open: boolean
  loading?: boolean
}

const TagSelectModal = ({ dateRange, tenants, onTagChange, open, loading }: TagSelectModalProps): JSX.Element => {
  const dispatch = useDispatch()

  const [filter, setFilter] = useState('')

  const tags = useTags({ dateRange: dateRange, tenants })

  const filteredTags = useMemo(() => {
    return [...new Set((tags?.filter((tag) => tag.tag.toLowerCase().includes(filter.toLowerCase())) ?? [])?.map((tag) => tag.tag.split(' : ')[0]))]
  }, [tags, filter])

  return (
    <PortalModal
      title="Select Tag Prefix"
      open={open}
      loading={loading ?? !tags}
      onClose={() => {
        dispatch(closeModals())
        onTagChange(null)
      }}
    >
      <Input
        name="tag-search"
        type="text"
        className="mb-4 w-full rounded-lg border border-th-border p-2 font-body text-sm"
        placeholder="Search"
        autoFocus
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
      />
      <div className="flex max-h-96 font-body">
        <Scrollbar className="max-h-96 w-full">
          {filteredTags.map((tag) => {
            return (
              <li
                key={tag}
                className="w-full cursor-pointer list-none border-b border-th-content-secondary p-2 text-sm last:border-b-0 hover:bg-th-content-secondary"
                onClick={() => {
                  dispatch(closeModals())
                  onTagChange(tag)
                }}
              >
                {tag}
              </li>
            )
          })}
        </Scrollbar>
      </div>
    </PortalModal>
  )
}

export default TagSelectModal
