import { withAITracking } from '@microsoft/applicationinsights-react-js'

import { useEffect, useState } from 'react'

import { stopLoading } from 'src/actions/loadingActions'

import useAlerts from 'src/hooks/composite/useAlerts'
import useAccount from 'src/hooks/utils/useAccount'

import Banner from 'src/components/Common/Banner'
import Button from 'src/components/Common/Button'
import Section from 'src/components/Common/Section'
import GlobalConfigDrawer from 'src/components/Drawers/GlobalConfigDrawer'
import AdminAlertsTable from 'src/components/Tables/AdminAlertTable'

import { reactPlugin } from 'src/configs/appInsights'

import { useDispatch } from 'react-redux'

const AdminAlerts: React.FC = () => {
  const dispatch = useDispatch()
  useEffect(() => void dispatch(stopLoading()), [dispatch])

  const account = useAccount()
  const { alerts } = useAlerts({ admin: true })
  const [selectedAlert, setSelectedAlert] = useState<((typeof alerts)[number] & { accountIds: string[] }) | null>(null)

  const handleCreateAlert = () => {
    setSelectedAlert({
      enabled: false,
      text: '',
      persistence_mode: 'persistent',
      routes: [],
      type: 'alert',
      variant: 'info',
      appliedToCurrentRoute: false,
      isAppliedToRoute: () => false,
      isDismissed: false,
      isGlobal: false,
      created_by: account.id,
      updated_by: account.id,
      id: '',
      updated_at: new Date().toISOString(),
      accountIds: []
    })
  }

  return (
    <>
      <Section>
        <Banner align="right" className="mb-8">
          <Button onClick={handleCreateAlert} variant="primary">
            Create Alert
          </Button>
        </Banner>
        <AdminAlertsTable alerts={alerts} onEditAlert={setSelectedAlert} />
      </Section>

      <GlobalConfigDrawer onClose={() => setSelectedAlert(null)} globalConfig={selectedAlert} hidden={!selectedAlert} />
    </>
  )
}
const AdminAlertsWithTracking = withAITracking(reactPlugin, AdminAlerts, 'AdminAlerts')
export default AdminAlertsWithTracking
