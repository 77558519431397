import { useQuery } from '@tanstack/react-query'

import { type Tenant } from 'src/types/azure'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'

import axios from 'axios'

interface UseBudgetsProps {
  tenants: Tenant[]
}

const useBudgets = ({ tenants }: UseBudgetsProps) => {
  const account = useAccount()

  const { data: budgets } = useQuery({
    queryKey: ['budgets', account.id, tenants?.map((t) => t.id)],
    queryFn: async ({ signal }) => {
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by TanStack Query')
      })

      try {
        const response = await api.post(
          `/ms-api/account/${account.id}/azure_usage/budgets`,
          tenants?.map((t) => t.id),
          {
            cancelToken: source.token
          }
        )
        return (response.data as Array<{ tenant_id: string; value: number }>) || []
      } catch (error) {
        if (!axios.isCancel(error)) {
          return []
        }
      }
      return []
    },
    enabled: !!account.id && !!tenants && !!tenants.length
  })

  return budgets
}

export default useBudgets
