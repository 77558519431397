import { useEffect, useState } from 'react'

import DatePicker from 'src/components/Common/DatePicker/DatePicker'
// Assuming you have a Date component
import Dropdown from 'src/components/Common/Dropdown'

import moment, { type Moment } from 'moment'

const DATE_OPTIONS = [
  {
    label: 'Today',
    value: 'today',
    date: moment()
  },
  {
    label: 'Yesterday',
    value: 'yesterday',
    date: moment().subtract(1, 'days')
  },
  {
    label: 'Custom',
    value: 'custom'
  }
]

const DEFAULT_DATE = moment() // Default to today

type UseDatePickerConfig = {
  defaultValue?: Moment
  value?: Moment
  onDateChange?: (date: Moment) => void
  minDate?: Moment
  disabled?: boolean
}

const useDatePicker = (config?: UseDatePickerConfig) => {
  const [selectedDate, setSelectedDate] = useState<Moment>(config?.defaultValue ?? DEFAULT_DATE)
  const [dateOption, setDateOption] = useState<string>('today')

  const handleDateOptionChange = (option: Record<string, any>) => {
    setDateOption(option.value)

    if (option.date) setSelectedDate(moment(option.date))
  }

  useEffect(() => config?.onDateChange?.(selectedDate), [config, selectedDate])

  const DateDropdown = (
    <Dropdown
      options={DATE_OPTIONS}
      value={DATE_OPTIONS.find((o) => o.value === dateOption) ?? DATE_OPTIONS[0]}
      variant={Dropdown.variant.DEFAULT}
      label="Date"
      bordered
      multi={false}
      disabled={config?.disabled}
      onChange={handleDateOptionChange}
    />
  )

  const DatePickerComponent = (
    <DatePicker
      date={selectedDate}
      dateId="_date_picker"
      bordered
      onChange={(date) => setSelectedDate(date)}
      disabled={config?.disabled}
      minDate={config?.minDate}
    />
  )

  const handleSetDate = (date: Moment) => {
    const matchingOption = DATE_OPTIONS.find((o) => o.date?.isSame(date, 'day'))

    if (matchingOption) {
      setDateOption(matchingOption.value)
    } else {
      setDateOption('custom')
    }

    setSelectedDate(date)
  }

  const queryKey = selectedDate.format('YYYY-MM-DD')

  return {
    DateDropdown,
    DatePickerComponent,
    selectedDate,
    queryKey,
    setSelectedDate: handleSetDate
  }
}

export default useDatePicker
