import { useCallback, useRef, useState } from 'react'

import { type Attachment } from '~/types/attachment'

import { useCreateChangeAttachmentMutation } from '~/hooks/services/servicenow/mutations/useCreateChangeAttachmentMutation'
import { useDeleteChangeAttachmentMutation } from '~/hooks/services/servicenow/mutations/useDeleteChangeAttachmentMutation'
import { useDownloadAttachmentMutation } from '~/hooks/services/servicenow/mutations/useDownloadAttachmentMutation'

export const useAttachments = (changeId: string | null | undefined) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null)

  const { mutateAsync: uploadAttachment, isLoading: isUploading } = useCreateChangeAttachmentMutation()
  const { mutateAsync: deleteAttachment } = useDeleteChangeAttachmentMutation()
  const { mutateAsync: downloadAttachment } = useDownloadAttachmentMutation()

  const [actionedIds, setActionedIds] = useState<string[]>([])

  const handleClick = useCallback(() => {
    hiddenFileInput.current?.click()
  }, [])

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]
      if (file && changeId) {
        uploadAttachment({ changeId, file })
        event.target.value = ''
      } else {
        console.error('No change ID in useAttachments')
      }
    },
    [changeId, uploadAttachment]
  )

  const handleDownload = useCallback(
    async (attachment: Attachment) => {
      if (changeId) {
        setActionedIds((prev) => [...prev, attachment.id])

        await downloadAttachment({ changeId, attachment })

        setActionedIds((prev) => prev.filter((id) => id !== attachment.id))
      } else {
        console.error('No change ID in useAttachments')
      }
    },
    [changeId, downloadAttachment]
  )

  const handleDelete = useCallback(
    async (attachmentId: string) => {
      if (changeId) {
        setActionedIds((prev) => [...prev, attachmentId])

        await deleteAttachment({ changeId, attachmentId })

        setActionedIds((prev) => prev.filter((id) => id !== attachmentId))
      } else {
        console.error('No change ID in useAttachments')
      }
    },
    [changeId, deleteAttachment]
  )

  return {
    hiddenFileInput,
    isUploading,
    actionedIds,
    handleClick,
    handleFileChange,
    handleDownload,
    handleDelete
  }
}
