import { type Folder } from 'src/hooks/services/provide/queries/useFilesQuery'

export const INITALIZE_FOLDER_PATH = 'INITALIZE_FOLDER_PATH'
export const UNINITALIZE_FOLDER_PATH = 'UNINITALIZE_FOLDER_PATH'
export const SET_FOLDER_PATH = 'SET_FOLDER_PATH'
export const SET_CURRENT_FOLDER_ID = 'SET_CURRENT_FOLDER_ID'
export const SET_SEARCH = 'SET_SEARCH'

export const initializeFolderPath = () => ({
  type: INITALIZE_FOLDER_PATH as typeof INITALIZE_FOLDER_PATH
})

export const uninitializeFolderPath = () => ({
  type: UNINITALIZE_FOLDER_PATH as typeof UNINITALIZE_FOLDER_PATH
})

export const setFolderPath = (folderPath: Folder[]) => ({
  type: SET_FOLDER_PATH as typeof SET_FOLDER_PATH,
  payload: { folderPath }
})

export const setCurrentFolderId = (currentFolderId: string | null) => ({
  type: SET_CURRENT_FOLDER_ID as typeof SET_CURRENT_FOLDER_ID,
  payload: { currentFolderId }
})

export const setSearch = (search: string) => ({
  type: SET_SEARCH as typeof SET_SEARCH,
  payload: { search }
})

export type FolderPathAction =
  | ReturnType<typeof initializeFolderPath>
  | ReturnType<typeof uninitializeFolderPath>
  | ReturnType<typeof setFolderPath>
  | ReturnType<typeof setCurrentFolderId>
  | ReturnType<typeof setSearch>
