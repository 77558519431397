import type { UseBaseQueryOptions } from '@tanstack/react-query'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useQuery from 'src/hooks/utils/useQuery'

export interface AccountUser {
  Id: string
  Email: string
}

const useAccountUsers = (options?: UseBaseQueryOptions<AccountUser[]>) => {
  const account = useAccount()

  return useQuery(
    ['getAccountUsers', account.id],
    async ({ signal }) => {
      const response = await api.get(`/provide/${account.id}/users`, {
        signal
      })

      return response.data
    },
    {
      enabled: !!account.id,
      refetchOnWindowFocus: false,
      ...options
    }
  )
}

export default useAccountUsers
