import { Children } from 'react'

interface GridProps {
  children: React.ReactNode
  columns?: 2 | 3 | 4 | 5
  className?: string
}

export const Grid = ({ children, columns, className = '' }: GridProps) => {
  const childrenCount = Children.count(children)
  const defaultColumns = Math.min(childrenCount, 5) as 2 | 3 | 4 | 5

  const finalColumns = columns ?? (defaultColumns < 2 ? 2 : defaultColumns)

  const gridColumns = {
    2: 'sm:grid-cols-2',
    3: 'sm:grid-cols-2 lg:grid-cols-3',
    4: 'sm:grid-cols-2 xl:grid-cols-4',
    5: 'sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5'
  }

  return <div className={`grid grid-cols-1 gap-4 ${gridColumns[finalColumns]} ${className}`}>{children}</div>
}
