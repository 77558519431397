import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useMutation, { type UseMutationOptions } from 'src/hooks/utils/useMutation'

import { type FileMetadata } from '../queries/useFilesQuery'

const useUpdateFileMutation = (options?: UseMutationOptions<Partial<FileMetadata>>) => {
  const account = useAccount()

  return useMutation(
    async (fileMetadata: Partial<FileMetadata>) => {
      const response = await api.post(`provide/${account.id}/file/update`, fileMetadata)
      return response.data
    },
    {
      ...options,
      errorMessage: 'An error occurred while updating the file.'
    }
  )
}

export default useUpdateFileMutation
