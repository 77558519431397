import { useMemo, useState } from 'react'

import { classNames } from 'src/utils/classNames'
import { getBlueGradientColors, getRainbowColors } from 'src/utils/colors'
import { formatCurrency } from 'src/utils/format'

type SectionedTableProps = {
  title?: string
  data: { date: string; values: { name: string; value: number; forecast: boolean }[] }[]
  budget?: number
  colorful?: boolean
  cumulative?: boolean
  filtered: string[]
  setFiltered: (filtered: string[]) => void
  shouldFormatCurrency?: boolean
}

const SectionedTable: React.FC<SectionedTableProps> = ({ title, data, colorful, cumulative, filtered, setFiltered, shouldFormatCurrency = true }) => {
  const names = useMemo(() => [...new Set(data.map((item) => item.values.map((val) => val.name)).flat())], [data])

  const totals = useMemo(() => {
    return names.map((name, idx) => {
      const values = data.map((d) => d.values.filter((v) => !v.forecast)[idx]).filter((d) => !!d && !d.forecast)
      return {
        name,
        value: cumulative ? (values.at(-1)?.value ?? 0) : values.reduce((acc, curr) => acc + curr.value, 0)
      }
    })
  }, [names, data, cumulative])

  const restructed = useMemo(() => {
    if (!names.length) return []

    const reordered = names.map((_, idx) => ({
      name: names[idx],
      values: data
        .map((d) => d.values.filter((v) => !v.forecast)[idx])
        .filter((d) => !!d && !d.forecast)
        .map((d) => d.value)
    }))

    // fill missing holes at the end
    const max = Math.max(...reordered.map((d) => d.values.length))
    reordered.forEach((d) => {
      while (d.values.length < max) d.values.push(0)
    })

    // Add totals record
    reordered.push({
      name: 'Total',
      values: Array(max)
        .fill(0)
        .map((_, idx) => reordered.reduce((acc, curr) => acc + (curr.values[idx] ?? 0), 0))
    })

    return reordered
  }, [names, data])

  const colors = useMemo(() => {
    const getColors = colorful ? getRainbowColors : getBlueGradientColors
    return getColors(names.length).reduce((acc, cur, idx) => ({ ...acc, [names[idx]]: cur }), {} as Record<string, string>)
  }, [names, colorful])

  const [hovered, setHovered] = useState<{ name: string; index: number } | null>(null)

  return (
    <>
      {title && <p className="mb-8 font-headline text-2xl font-bold text-th-text">{title}</p>}
      <div className="mb-8 flex min-w-[40rem] justify-between overflow-hidden rounded-xl border border-th-border pb-[15px] text-sm">
        <div className="text-bold min-w-[8rem] font-headline">
          <p className="border-b border-r border-th-border px-3 py-3">Stack By</p>
          {[...names, 'Total'].map((name) => (
            <button
              className={classNames(
                'flex w-full items-center gap-3 border-b border-r border-th-border px-3 py-2',
                hovered?.name === name && !filtered.some((r) => r === name)
                  ? 'bg-th-content-secondary'
                  : filtered.some((r) => r === name) && hovered?.name === name
                    ? 'identify bg-th-info-light'
                    : filtered.some((r) => r === name) && 'bg-th-info-light'
              )}
              onClick={() => setFiltered(filtered.includes(name) ? filtered.filter((r) => r !== name) : [...filtered, name])}
              disabled={name === 'Total'}
            >
              {name !== 'Total' && <div className="h-3 w-3 rounded-full" style={{ backgroundColor: colors[name] }} />}
              <p className="capitalize">{name}</p>
            </button>
          ))}
        </div>

        <div className="relative flex grow">
          <div className="absolute inset-x-0 -bottom-[15px] top-0 overflow-x-auto overflow-y-hidden whitespace-nowrap">
            <div
              className="text-bold grid font-headline"
              style={{ gridTemplateColumns: `repeat(${data.filter((d) => !d.values.every((v) => v.forecast)).length}, minmax(5rem, 1fr))` }}
            >
              {data
                .filter((d) => !d.values.every((v) => v.forecast))
                .map(({ date }, idx) => (
                  <p className={classNames('border-b border-r border-th-border px-3 py-3', idx === hovered?.index && 'bg-th-content-secondary')}>{date}</p>
                ))}
            </div>

            {restructed.map((d) => (
              <div className="grid text-th-text-secondary" style={{ gridTemplateColumns: `repeat(${d.values.length}, minmax(5rem, 1fr))` }}>
                {d.values.map((v, idx) => (
                  <p
                    className={classNames(
                      'border-b border-r border-th-border px-3 py-2',
                      (idx === hovered?.index || d.name === hovered?.name) && !filtered.some((r) => r === d.name)
                        ? 'bg-th-content-secondary'
                        : filtered.some((r) => r === d.name) && (idx === hovered?.index || d.name === hovered?.name)
                          ? 'identify bg-th-info-light'
                          : filtered.some((r) => r === d.name) && 'bg-th-info-light',
                      d.name === 'Total' && 'font-bold'
                    )}
                    onMouseEnter={() => setHovered({ name: d.name, index: idx })}
                    onMouseLeave={() => setHovered(null)}
                  >
                    {shouldFormatCurrency ? formatCurrency(v, true, 0) : v.toPrecision(4)}
                  </p>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div>
          <p className="text-bold border-b border-th-border px-3 py-3 font-headline">Total</p>
          <div className="">
            {[...totals, { name: 'Total', value: totals.reduce((acc, curr) => acc + curr.value, 0) }].map((t) => (
              <p
                className={classNames(
                  'border-b border-th-border px-3 py-2 font-bold',
                  t.name === hovered?.name && !filtered.some((r) => r === t.name)
                    ? 'bg-th-content-secondary'
                    : t.name === hovered?.name && filtered.some((r) => r === t.name)
                      ? 'identify bg-th-info-light'
                      : filtered.some((r) => r === t.name) && 'bg-th-info-light'
                )}
              >
                {shouldFormatCurrency ? formatCurrency(t.value, true, 0) : t.value.toPrecision(4)}
              </p>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default SectionedTable
