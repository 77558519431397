import { useQuery } from '@tanstack/react-query'

import { showAlert } from 'src/actions/alertsActions'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'

import axios from 'axios'
import { useDispatch } from 'react-redux'

import { useTenantsQuery } from '../../servicenow/queries/useTenantsQuery'

export interface ScheduledExport {
  id: string
  tenantIds: Array<{ tenantId: string }>
  accountId: string
  name: string
  description: string
  status: string
  recurring: boolean
  nextExportDate: string
  subscriptions: Array<{ subscriptionId: string; subscriptionName: string }>
  exportFrequency: string
  customerUrl: string
  exports: Array<{ id: string; exportScheduleId: string; status: string; blobUri: string; exportedDate: string }>
  months: Array<string>
}

const useScheduledExports = () => {
  const { data: tenants } = useTenantsQuery({ active: true })
  const account = useAccount()
  const dispatch = useDispatch()

  const { data: results } = useQuery({
    queryKey: ['scheduled-exports', account.id, tenants.map((t) => t.id)],
    queryFn: async ({ signal }) => {
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by TanStack Query')
      })

      try {
        const response = await api.post(
          `/ms-api/v2/account/${account.id}/azure_usage/get_data_export_schedules`,
          {
            tenantIds: tenants.map((t) => t.id)
          },
          { cancelToken: source.token }
        )
        return (response.data as ScheduledExport[]) || []
      } catch (error) {
        if (!axios.isCancel(error)) {
          dispatch(
            showAlert({
              type: 'error',
              message: `We were unable to retrieve your scheduled exports.`,
              component: 'AzureOverview',
              error: error as Error
            })
          )
        }
        return null
      }
    },
    enabled: !!account.id && !!tenants && !!tenants.length,
    refetchInterval: 15 * 1000
  })

  const scheduledExports = results?.flatMap((data) => (data ? data : [])) ?? []

  return scheduledExports
}

export default useScheduledExports
