import React, { forwardRef } from 'react'

import ReactSwitch from 'react-switch'

interface Props {
  id?: string
  checked?: boolean
  defaultChecked?: boolean
  disabled?: boolean
  onChange?: (checked: boolean) => void
}

const Switch = forwardRef<ReactSwitch, Props>(({ id, checked, defaultChecked, disabled, onChange }, ref) => {
  return (
    <ReactSwitch
      id={id}
      onChange={onChange ?? (() => {})}
      checked={checked !== undefined ? checked : (defaultChecked ?? false)}
      disabled={disabled}
      onColor="#4caf50"
      offColor="#f98690"
      ref={ref}
    />
  )
})

Switch.displayName = 'Switch'

export default Switch
