import { useCallback, useState } from 'react'

export const useDownloadProgress = () => {
  const [downloadPercentage, setDownloadPercentage] = useState<number | null>(null)

  const startProgress = useCallback(() => {
    setDownloadPercentage(0)
    const interval = setInterval(
      () =>
        setDownloadPercentage((prev) => {
          if (!prev) return 2
          return prev >= 80 ? prev + (100 - prev) * 0.05 : prev + 2
        }),
      150
    )
    return interval
  }, [])

  const stopProgress = useCallback(() => {
    setDownloadPercentage(null)
  }, [])

  return {
    downloadPercentage,
    startProgress,
    stopProgress
  }
}
