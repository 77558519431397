import { useEffect, useState } from 'react'

import useCreateFeatureFlagMutation from 'src/hooks/services/provide/mutations/useCreateFeatureFlagMutation'
import useUpdateFeatureFlagMutation from 'src/hooks/services/provide/mutations/useUpdateFeatureFlagMutation'
import type { FeatureFlag } from 'src/hooks/services/provide/queries/useAdminFeatureFlagQuery'
import useAccountsQuery from 'src/hooks/services/servicenow/queries/useAccountsQuery'

import Button from '../Common/Button'
import Input from '../Common/Input'
import PortalDrawer from '../Common/PortalDrawer'
import Dropdown from '../Common/RefactoredDropdown'
import Switch from '../Common/Switch'
import TextArea from '../Common/TextArea'

interface FeatureFlagDrawerProps {
  featureFlag: FeatureFlag | null
  onClose: () => void
  hidden?: boolean
}

const FeatureFlagDrawer: React.FC<FeatureFlagDrawerProps> = ({ featureFlag, onClose, hidden }) => {
  const [isEnabled, setIsEnabled] = useState(featureFlag?.enabled ?? false)
  const [name, setName] = useState(featureFlag?.name ?? '')
  const [description, setDescription] = useState(featureFlag?.description ?? '')
  const [accountIds, setAccountIds] = useState<string[]>(featureFlag?.accountIds ?? [])

  const { data: accounts } = useAccountsQuery()

  const { mutateAsync: createFeatureFlag, isLoading: isCreating } = useCreateFeatureFlagMutation()
  const { mutateAsync: updateFeatureFlag, isLoading: isUpdating } = useUpdateFeatureFlagMutation()

  useEffect(() => {
    setIsEnabled(featureFlag?.enabled ?? false)
    setName(featureFlag?.name ?? '')
    setDescription(featureFlag?.description ?? '')
    setAccountIds(featureFlag?.accountIds ?? [])
  }, [featureFlag])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!featureFlag) return

    const data = {
      name,
      description,
      enabled: isEnabled,
      accountIds
    }

    try {
      if (featureFlag.id) {
        await updateFeatureFlag({ ...data, id: featureFlag.id })
      } else {
        await createFeatureFlag(data)
      }
      onClose()
    } catch (error) {
      console.error('Error saving feature flag:', error)
    }
  }

  const Actions = () => (
    <div className="grid grid-cols-2 gap-2">
      <Button bordered onClick={onClose}>
        Cancel
      </Button>
      <Button type="submit" form="featureFlagForm" variant="primary" isLoading={isCreating || isUpdating}>
        Save
      </Button>
    </div>
  )

  return (
    <PortalDrawer title={featureFlag?.id ? 'Edit Feature Flag' : 'Create Feature Flag'} actions={<Actions />} onClose={onClose} hidden={hidden}>
      <form id="featureFlagForm" onSubmit={handleSubmit} className="flex flex-col gap-4">
        <Input label="Name" name="featureFlagName" value={name} onChange={(e) => setName(e.target.value)} required placeholder="featureFlagName" />
        <TextArea
          label="Description"
          name="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
          placeholder="Describe what this feature flag controls..."
          className="!mb-0"
        />
        <div className="flex flex-col gap-1">
          <label className="ml-1 text-th-text-secondary">Accounts</label>
          <Dropdown
            data={
              accounts?.map((account) => ({
                label: account.name,
                value: account.id,
                checked: accountIds.includes(account.id)
              })) ?? []
            }
            disabled={isCreating || isUpdating}
            keepOpenOnSelect
            className="justify-between"
            onChange={(_, selected) => setAccountIds(selected.map((s) => s.value))}
          />
        </div>
        <div className="mt-2 flex items-center gap-2">
          <Switch id="enabled" checked={isEnabled} onChange={setIsEnabled} />
          <label htmlFor="enabled" className="text-sm">
            Enabled
          </label>
        </div>
      </form>
    </PortalDrawer>
  )
}

export default FeatureFlagDrawer
