import { type UseBaseQueryOptions } from '@tanstack/react-query'

import { type Attachment } from 'src/types/attachment'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useQuery from 'src/hooks/utils/useQuery'

export const fetchChangeAttachments = async (accountId: string, changeId: string, signal?: AbortSignal) => {
  const response = await api.get(`/servicenow/account/${accountId}/change_request/${changeId}/attachments`, {
    signal
  })
  return response.data as Attachment[]
}

export const getChangeAttachmentsQueryKey = (accountId: string, changeId: string) => {
  return ['getChangeAttachmentsQuery', accountId, changeId] as const
}

const useChangeAttachmentsQuery = (changeId: string | undefined, options?: UseBaseQueryOptions<Attachment[]>) => {
  const account = useAccount()

  return useQuery(getChangeAttachmentsQueryKey(account.id, changeId ?? ''), async ({ signal }) => fetchChangeAttachments(account.id, changeId!, signal), {
    enabled: !!account.id && !!changeId,
    refetchOnWindowFocus: false,
    ...options
  })
}

export default useChangeAttachmentsQuery
