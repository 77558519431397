import { type ColumnDef } from '@tanstack/react-table'

import React, { useMemo } from 'react'

import { type Contact } from '../../types/contact'

import { useNavigate } from 'react-router-dom'

import State from '../Common/State'
import Table from '../Common/Table/Table'

interface Props {
  contacts: Contact[]
}

const ContactTable: React.FC<Props> = ({ contacts }) => {
  const navigate = useNavigate()

  const columns = useMemo<ColumnDef<Contact>[]>(
    () => [
      {
        accessorKey: 'first_name',
        header: 'First Name'
      },
      {
        accessorKey: 'last_name',
        header: 'Last Name'
      },
      {
        accessorKey: 'email',
        header: 'Email'
      },
      {
        accessorKey: 'phone',
        header: 'Phone'
      },
      {
        accessorKey: 'mobile',
        header: 'Mobile'
      },
      {
        accessorKey: 'registered',
        header: 'Registered',
        cell: (info) => <State state={info.getValue() ? 'Registered' : 'Not Registered'} />
      },
      {
        accessorKey: 'admin',
        header: 'Admin',
        cell: (info) => (info.getValue() ? <State state="Admin" /> : null)
      }
    ],
    []
  )

  const data = useMemo(() => contacts, [contacts])

  return <Table data={data} columns={columns} onRowClick={(row) => navigate('/profile', { state: row })} />
}

export default ContactTable
