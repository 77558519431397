import { type ColumnDef, type Row } from '@tanstack/react-table'

import React, { useMemo } from 'react'

import { setSelectedItems } from 'src/actions/fileActions'

import { useFileDownload } from 'src/hooks/composite/files/useFileDownload'
import { useFileFolderDelete } from 'src/hooks/composite/files/useFileFolderDelete'
import { useFileFolderMove } from 'src/hooks/composite/files/useFileFolderMove'
import { useFileFolderRename } from 'src/hooks/composite/files/useFileFolderRename'
import { useFilePath } from 'src/hooks/composite/files/useFilePath'
import { useFileShare } from 'src/hooks/composite/files/useFileShare'
import { type FileMetadata, type Folder } from 'src/hooks/services/provide/queries/useFilesQuery'
import useFolderConfigQuery from 'src/hooks/services/provide/queries/useFolderConfigQuery'

/* hooks */
import useOwnUser from 'src/hooks/utils/useOwnUser'

import Checkbox from 'src/components/Common/Checkbox/Checkbox'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from 'src/components/Common/DropdownMenu'
import FileTypeIcon from 'src/components/Common/FileTypeIcon'
import Spinner from 'src/components/Common/Spinner'
import Table from 'src/components/Common/Table/Table'

import moment from 'moment'
import { PiDotsThree, PiDownload, PiFlowArrow, PiFolder, PiPencil, PiShare, PiTrash } from 'react-icons/pi'
import { useDispatch, useSelector } from 'react-redux'
import { type FolderState } from 'src/reducers/folderReducer'

type Data = (FileMetadata | Folder) & { type: string; name: string }

interface TableViewProps {
  data: Data[]
  onFolderClick: (folder: Folder) => void
  limitedView: boolean
}

const TableView: React.FC<TableViewProps> = ({ data, onFolderClick, limitedView }) => {
  const user = useOwnUser()

  const { openDeleteModal, DeleteConfirmationModal, deletingIds } = useFileFolderDelete()
  const { handleFileDownload, downloadingFileIds } = useFileDownload()
  const { MoveModal, movingFileIds, openMoveModal } = useFileFolderMove()
  const { RenameModal, openRenameModal, renamingFileIds } = useFileFolderRename()
  const { handleFolderClick } = useFilePath()
  const { openShareModal, ShareModal, sharingFileIds } = useFileShare()

  const search = useSelector((state: FolderState) => state.folder.search)
  const folderPath = useSelector((state: FolderState) => state.folder.folderPath)
  const dispatch = useDispatch()

  const { data: folderConfigs } = useFolderConfigQuery()

  const columns = useMemo<ColumnDef<Data>[]>(() => {
    const lastFolder = folderPath.findLast((path) => path.createdFromConfigId !== null)
    const lastFolderConfig = folderConfigs?.find((folder) => folder.id === lastFolder?.createdFromConfigId) ?? null
    const inUploadsFolder = folderPath.some((path) => path.name === 'Uploads')
    const freeFormFolder = lastFolderConfig?.type === 'Unstructured' || inUploadsFolder

    // In projects folder
    const previousFolder = folderPath.at(-1)
    const previousFolderConfig = folderConfigs?.find((folder) => folder.id === previousFolder?.createdFromConfigId) ?? null
    const templateFolder = previousFolderConfig?.type === 'Template'

    const templateColumn: ColumnDef<Data> = {
      id: 'select',
      header: ({ table }) => (
        <div className="flex items-center">
          <Checkbox
            checked={table.getIsAllPageRowsSelected()}
            onChange={(e) => table.toggleAllPageRowsSelected(!!e.target.checked)}
            aria-label="Select all"
            className="!hidden"
          />
        </div>
      ),
      cell: ({ row }) => (
        <div className="flex items-center">
          <Checkbox checked={row.getIsSelected()} onChange={(e) => row.toggleSelected(!!e.target.checked)} aria-label="Select row" className="!hidden" />
        </div>
      ),
      enableSorting: false,
      enableHiding: false
    }

    const searchColumn: ColumnDef<Data> = {
      accessorKey: 'path',
      header: 'Path',
      cell: ({ row }: { row: Row<Data> }) => (
        <button
          onClick={() => handleFolderClick({ ...(row.original as Folder), id: (row.original as FileMetadata).folderId! }, true)}
          className="underline underline-offset-4"
        >
          {(row.original as Folder).path ?? 'N/A'}
        </button>
      )
    }

    const baseColumns: ColumnDef<Data>[] = [
      {
        accessorKey: 'name',
        header: 'Name',
        cell: ({ row }) => {
          const isFolder = row.original.type === 'folder'
          return isFolder ? (
            <button className="flex items-center gap-2 hover:underline" onClick={() => onFolderClick(row.original as Folder)}>
              <PiFolder className="h-8 w-8 shrink-0 text-th-blue" />
              <span>{row.original.name}</span>
            </button>
          ) : (
            <div className="flex items-center gap-2">
              <FileTypeIcon contentType={(row.original as unknown as FileMetadata).contentType} />
              <span>{row.original.name}</span>
            </div>
          )
        }
      },
      {
        accessorKey: 'username',
        header: 'Uploaded by',
        cell: ({ row }) => (row.original as FileMetadata).username ?? 'N/A'
      },
      {
        accessorKey: 'createdAt',
        header: 'Created at',
        cell: (info) => moment(info.getValue<string>()).format('DD/MM/YYYY HH:mm'),
        sortingFn: (a, b) => moment(a.getValue('createdAt')).diff(moment(b.getValue('createdAt')))
      },
      {
        id: 'actions',
        header: () => <div className="ml-auto">Actions</div>,
        cell: ({ row }) => {
          const isFolder = row.original.type === 'folder'
          const isLoading =
            deletingIds.includes(row.original.id) ||
            (!isFolder &&
              (downloadingFileIds.includes(row.original.id) ||
                movingFileIds.includes(row.original.id) ||
                renamingFileIds.includes(row.original.id) ||
                sharingFileIds.includes(row.original.id)))

          const isSuperAdmin = user?.super_admin
          const isUserDeletable =
            !isFolder &&
            new Date((row.original as FileMetadata).deletionDeadline).getTime() > new Date().getTime() &&
            user?.id === (row.original as FileMetadata).userId

          return (
            <DropdownMenu>
              {(isSuperAdmin || !isFolder) && (
                <DropdownMenuTrigger className="ml-auto flex h-6 w-6 shrink-0 items-center justify-center focus:shadow-none" disabled={isLoading}>
                  {isLoading ? <Spinner className="h-6 w-6" /> : <PiDotsThree className="h-6 w-6 hover:text-th-blue" />}
                </DropdownMenuTrigger>
              )}
              <DropdownMenuContent>
                <DropdownMenuItem onClick={() => openShareModal([row.original.id], row.original.name)}>
                  <PiShare className="mr-2 h-4 w-4" /> Share
                </DropdownMenuItem>
                {isFolder ? (
                  isSuperAdmin && (
                    <>
                      <DropdownMenuItem onClick={() => openDeleteModal([{ id: row.original.id, type: 'folder', name: row.original.name }])}>
                        <PiTrash className="mr-2 h-4 w-4" /> Delete
                      </DropdownMenuItem>

                      {(freeFormFolder || templateFolder) && !search && (
                        <>
                          <DropdownMenuItem onClick={() => openRenameModal(row.original.id, row.original.name, isFolder ? 'folder' : 'file')}>
                            <PiPencil className="mr-2 h-4 w-4" /> Rename
                          </DropdownMenuItem>
                          {freeFormFolder && (
                            <DropdownMenuItem
                              onClick={() => openMoveModal([{ id: row.original.id, name: row.original.name, type: isFolder ? 'folder' : 'file' }])}
                            >
                              <PiFlowArrow className="mr-2 h-4 w-4" /> Move
                            </DropdownMenuItem>
                          )}
                        </>
                      )}
                    </>
                  )
                ) : (
                  <>
                    <DropdownMenuItem onClick={() => handleFileDownload(row.original.id, row.original.name)}>
                      <PiDownload className="mr-2 h-4 w-4" /> Download
                    </DropdownMenuItem>
                    {(isSuperAdmin || isUserDeletable) && (
                      <DropdownMenuItem onClick={() => openDeleteModal([{ id: row.original.id, type: 'file', name: row.original.name }])}>
                        <PiTrash className="mr-2 h-4 w-4" /> Delete
                      </DropdownMenuItem>
                    )}
                    {isSuperAdmin && (
                      <>
                        {!inUploadsFolder && (
                          <DropdownMenuItem onClick={() => openRenameModal(row.original.id, row.original.name, isFolder ? 'folder' : 'file')}>
                            <PiPencil className="mr-2 h-4 w-4" /> Rename
                          </DropdownMenuItem>
                        )}
                        <DropdownMenuItem onClick={() => openMoveModal([{ id: row.original.id, name: row.original.name, type: isFolder ? 'folder' : 'file' }])}>
                          <PiFlowArrow className="mr-2 h-4 w-4" /> Move
                        </DropdownMenuItem>
                      </>
                    )}
                  </>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          )
        }
      }
    ]

    baseColumns.splice(0, 0, templateColumn)
    if (search) baseColumns.splice(baseColumns.length - 1, 0, searchColumn)

    return baseColumns
  }, [
    folderPath,
    folderConfigs,
    search,
    handleFolderClick,
    onFolderClick,
    deletingIds,
    downloadingFileIds,
    movingFileIds,
    renamingFileIds,
    sharingFileIds,
    user?.super_admin,
    user?.id,
    openShareModal,
    openDeleteModal,
    openRenameModal,
    openMoveModal,
    handleFileDownload
  ])

  return (
    <>
      <Table
        data={data}
        columns={columns}
        bordered
        onRowSelectionChange={(items) => dispatch(setSelectedItems(items.flatMap((item) => item ?? [])))}
        defaultSortBy={[{ id: 'createdAt', desc: true }]}
      />
      {DeleteConfirmationModal}
      {MoveModal}
      {RenameModal}
      {ShareModal}
      {limitedView && (
        <div className="mt-4 text-center text-sm text-th-gray">
          <p>You have limited permissions and may not see all available data.</p>
        </div>
      )}
    </>
  )
}

export default TableView
