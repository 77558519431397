import { type ColumnDef } from '@tanstack/react-table'

import React, { useMemo } from 'react'

import type { Alert } from 'src/hooks/composite/useAlerts'

import Table from 'src/components/Common/Table/Table'

import { classNames } from 'src/utils/classNames'

import { PiPencilLine } from 'react-icons/pi'

interface AdminAlert extends Alert {
  accountIds: string[]
}

interface AdminAlertsTableProps {
  alerts: Alert[]
  onEditAlert: (alert: AdminAlert) => void
}

const AdminAlertsTable: React.FC<AdminAlertsTableProps> = ({ alerts, onEditAlert }) => {
  const columns = useMemo<ColumnDef<Alert>[]>(
    () => [
      {
        accessorKey: 'text',
        header: 'Text'
      },
      {
        accessorKey: 'enabled',
        header: 'Enabled',
        cell: (info) => <div className="text-center">{info.getValue() ? 'Yes' : 'No'}</div>
      },
      {
        accessorKey: 'variant',
        header: 'Variant',
        cell: (info) => <div className="text-center capitalize">{info.getValue<string>()}</div>
      },
      {
        accessorKey: 'routes',
        header: 'Route Count',
        cell: (info) => <div className="text-center">{info.getValue<string>().length}</div>
      },
      {
        accessorKey: 'accountIds',
        header: 'Accounts',
        cell: (info) => <div className="text-center">{info.getValue<string>()?.length || 'N/A'}</div>
      },
      {
        accessorKey: 'persistence_mode',
        header: 'Persistence Mode',
        cell: (info) => <div className="text-center capitalize">{info.getValue<string>()}</div>
      },
      {
        id: 'edit',
        header: 'Edit',
        cell: (info) => (
          <PiPencilLine
            className={classNames('h-5 w-5', info.row.original.id.length ? 'cursor-pointer' : 'cursor-progress opacity-50')}
            onClick={() => {
              if (!info.row.original.id.length) return
              onEditAlert(info.row.original as AdminAlert)
            }}
          />
        )
      }
    ],
    [onEditAlert]
  )

  return <Table data={alerts} columns={columns} bordered />
}

export default AdminAlertsTable
