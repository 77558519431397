import { useMemo, useRef, useState } from 'react'

import { classNames } from 'src/utils/classNames'
import { getBlueGradientColors } from 'src/utils/colors'
import { formatCurrency } from 'src/utils/format'

import { Area, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

const rgbaToHex = (rgba = 'rgba(20, 32, 59, 1)') => {
  // Parse the rgba string to get R, G, B, A values
  const match = rgba.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*(?:\.\d+)?))?\)$/)

  if (!match) {
    throw new Error('Invalid RGBA string')
  }

  const red = parseInt(match[1], 10)
  const green = parseInt(match[2], 10)
  const blue = parseInt(match[3], 10)

  // Convert to hex string
  const hex = ((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1)

  return `#${hex}`
}

type SectionedAreaProps = {
  title?: string
  data: { date: string; values: { name: string; value: number; forecast: boolean }[]; budget?: number }[]

  colorful?: boolean

  filtered: string[]
  setFiltered: (filtered: string[]) => void

  removeForecast?: boolean
  isLoading?: boolean

  hideForecast: boolean
  hideBudget: boolean
  onHideChange: (hideForecast: boolean, hideBudget: boolean) => void

  shouldFormatCurrency?: boolean

  interval?: 'equidistantPreserveStart' | 'preserveStart' | 'preserveEnd' | 'preserveStartEnd' | undefined

  xAxisLabel?: string
  yAxisLabel?: string
}

const SectionedArea: React.FC<SectionedAreaProps> = (props) => {
  const { shouldFormatCurrency = true } = props
  const hasBudget = props.data.some((d) => !!d.budget)

  const altered = useMemo(() => {
    // sort values by name and secondly by forecast type
    const data = props.data.map((dataItem) => ({
      ...dataItem,
      values: dataItem.values.map((val) => (val.forecast && props.hideForecast ? { ...val, value: 0 } : val))
    }))

    return data
  }, [props.data, props.hideForecast])

  const names = [...new Set(props.data.map((item) => item.values.map((val) => val.name)).flat())]
  const returnedColors = getBlueGradientColors(names.length)
  const colors = returnedColors.reduce((acc, cur, idx) => ({ ...acc, [names[idx]]: cur }), {} as Record<string, string>)

  const [hovered, setHovered] = useState<string | null>(null)

  const graphRef = useRef<HTMLDivElement>(null)

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const isMouseInGraph = graphRef.current?.contains(e.target as Node)
    if (!isMouseInGraph) setHovered(null)
  }

  const stopPercentage = useMemo(() => {
    const count = altered.length
    const lastNonZero = altered.findIndex(
      (d, index) =>
        d.values.every((v) => !!v.forecast || !v.value) &&
        !altered
          .slice(index)
          .flatMap((a) => a.values)
          .some((v) => v.value > 0 && !v.forecast)
    )
    if (lastNonZero === -1 || props.hideForecast || props.removeForecast) return 100
    return ((lastNonZero - 1) / (count - 1)) * 100
  }, [altered, props.hideForecast, props.removeForecast])

  const renderTooltip = (tooltipProps: any) => {
    const payload = tooltipProps.payload as { name: string; value: number }[]
    if (!payload || !payload.length) return <></>

    const total = payload
      .filter((p) => (!props.filtered.length || props.filtered.includes(p.name)) && p.name !== 'budget')
      .reduce((acc, cur) => acc + cur.value, 0)
    const value = payload.find((d: any) => d.name === hovered)?.value || null
    const budget = payload.filter((p) => p.name === 'budget')[0]?.value

    return (
      <div className={classNames('flex gap-2 rounded-md bg-th-content p-4 shadow-md')}>
        <div className="px-2">
          <p>Total</p>
          <p className="font-bold text-th-text">{shouldFormatCurrency ? formatCurrency(total, true, 0) : total.toPrecision(4)}</p>
        </div>
        {value && (
          <div className="px-2">
            <p className="capitalize">{hovered}</p>
            <p className="font-bold text-th-text">{shouldFormatCurrency ? formatCurrency(value, true, 0) : value.toPrecision(4)}</p>
          </div>
        )}
        {!!budget && (
          <div className="px-2">
            <p className="capitalize">Budget</p>
            <p className="font-bold text-th-text">{shouldFormatCurrency ? formatCurrency(budget, true, 0) : budget.toPrecision(4)}</p>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="space-y-1" onMouseMove={handleMouseMove}>
      {props.title && <p className="mb-12 font-headline text-2xl font-bold text-th-text">{props.title}</p>}
      <div className="relative h-80 w-full md:h-96 lg:h-[30rem]" ref={graphRef}>
        {props.isLoading ? (
          <div className="pointer-events-none absolute inset-0 z-10 flex items-center justify-center">
            <div className="pb-8 pl-8 text-2xl text-th-text-secondary">
              <div className="spinner h-16 w-16" />
            </div>
          </div>
        ) : (
          !props.data.length && (
            <div className="pointer-events-none absolute inset-0 z-10 flex items-center justify-center">
              <p className="pb-8 pl-8 text-2xl text-th-text-secondary">No data to show</p>
            </div>
          )
        )}
        <ResponsiveContainer>
          <ComposedChart data={altered}>
            <defs>
              {names
                .flatMap((name) => [name, name])
                .map((name) => (
                  <>
                    <linearGradient id={name.split(' ').join('')} x1="0" y1="0" x2="1" y2="0">
                      <stop offset="0%" stopColor={rgbaToHex(colors[name])} stopOpacity={1} />
                      <stop offset={`${colors[name] ? stopPercentage : 0}%`} stopColor={rgbaToHex(colors[name])} stopOpacity={1} />
                      <stop offset={`${colors[name] ? stopPercentage : 0}%`} stopColor={rgbaToHex(colors[name])} stopOpacity={0.5} />
                      <stop offset="100%" stopColor={rgbaToHex(colors[name])} stopOpacity={0.5} />
                    </linearGradient>
                    <linearGradient id={name.split(' ').join('') + '_stroke'} x1="0" y1="0" x2="1" y2="0">
                      <stop offset="0%" stopColor={rgbaToHex(colors[name])} stopOpacity={0} />
                      <stop offset={`${colors[name] ? stopPercentage : 0}%`} stopColor={rgbaToHex(colors[name])} stopOpacity={0} />
                      <stop offset={`${colors[name] ? stopPercentage : 0}%`} stopColor={rgbaToHex(colors[name])} stopOpacity={1} />
                      <stop offset="100%" stopColor={rgbaToHex(colors[name])} stopOpacity={1} />
                    </linearGradient>
                  </>
                ))}
            </defs>
            <CartesianGrid vertical={false} />
            <Tooltip content={renderTooltip} />
            <YAxis
              tickFormatter={(value) => (shouldFormatCurrency ? formatCurrency(parseFloat(value), true, 0) : value.toPrecision(4))}
              fontSize={12}
              axisLine={false}
              tickLine={false}
              tickCount={10}
              width={70}
              label={props.yAxisLabel ? { value: props.yAxisLabel, angle: -90, position: 'insideLeft', textAnchor: 'middle' } : undefined}
            />
            <XAxis
              dataKey="date"
              fontSize={11}
              axisLine={false}
              tickLine={false}
              minTickGap={2}
              interval={props.interval || 'equidistantPreserveStart'}
              label={props.xAxisLabel}
            />
            {(props.removeForecast ? names : names.flatMap((name) => [name, name])).map((name, idx) => (
              <Area
                name={name}
                key={idx}
                dataKey={`values.${idx}.value`}
                stackId="a"
                activeDot={{ r: 0 }}
                fillOpacity={hovered === name || hovered === null ? (idx % 2 === 0 || props.removeForecast ? 1 : 0.5) : 0.5}
                strokeDasharray={idx % 2 === 0 || props.removeForecast ? '' : '5 5'}
                strokeDashoffset={-5}
                strokeWidth={idx % 2 === 0 || props.removeForecast ? 0 : 2}
                strokeOpacity={hovered === name || hovered === null ? 1 : 0.2}
                stroke={colors[name]}
                fill={idx % 2 === 0 || props.removeForecast ? `url(#${name.split(' ').join('')})` : colors[name]}
                onMouseEnter={() => setHovered(name)}
                onMouseLeave={() => setHovered(null)}
                onClick={() => props.setFiltered(props.filtered.length === 1 && props.filtered[0] === name ? [] : [name])}
                isAnimationActive={false}
                hide={props.filtered.length > 0 && !props.filtered.includes(name)}
                className={classNames('cursor-pointer', hovered === name ? 'identify' : '')}
                style={{ outlineColor: colors[name] }}
              />
            ))}
            {hasBudget && !props.hideBudget && <Line dataKey="budget" strokeWidth={2} stroke="gray" animationDuration={400} />}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <div className="ml-10 flex flex-wrap items-center justify-center gap-x-2 gap-y-2 text-sm">
        {names
          .filter((name) => name !== 'Forecast')
          .map((name, idx) => (
            <button
              key={idx}
              className={classNames(
                'flex items-center gap-2 whitespace-nowrap rounded-md px-2 py-0.5 duration-100',
                props.filtered.length && props.filtered.includes(name) ? 'bg-th-border' : 'hover:bg-th-border'
              )}
              onClick={() => props.setFiltered(props.filtered.includes(name) ? props.filtered.filter((n) => n !== name) : [...props.filtered, name])}
            >
              <div className={`h-3 w-3 rounded-full ${props.colorful ? 'bg-th-border' : ''}`} style={{ backgroundColor: colors[name] }} />
              <span className="font-bold capitalize">{name}</span>
            </button>
          ))}
        {hasBudget && (
          <button
            className={classNames(
              'flex items-center gap-2 whitespace-nowrap rounded-md px-2 py-0.5 duration-100',
              !props.hideBudget ? 'bg-th-border' : 'hover:bg-th-border'
            )}
            onClick={() => props.onHideChange(props.hideForecast, !props.hideBudget)}
          >
            <div className="h-0.5 w-3 bg-th-gray"></div>
            <span className="font-bold">Budget line</span>
          </button>
        )}

        {!props.removeForecast && (
          <button
            className={classNames(
              'flex items-center gap-2 whitespace-nowrap rounded-md px-2 py-0.5 duration-100',
              !props.hideForecast ? 'bg-th-border' : 'hover:bg-th-border'
            )}
            onClick={() => props.onHideChange(!props.hideForecast, props.hideBudget)}
          >
            <div className="h-3 w-3 rounded-full outline-dashed outline-1 outline-th-gray"></div>
            <span className="font-bold">Forecast</span>
          </button>
        )}
      </div>
    </div>
  )
}

export default SectionedArea
