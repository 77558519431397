import { type ColumnDef } from '@tanstack/react-table'

import React, { useMemo } from 'react'

import { type Insight } from 'src/hooks/services/microsoft/queries/useTrendsInsights'
import { useTenantsQuery } from 'src/hooks/services/servicenow/queries/useTenantsQuery'

import Table from 'src/components/Common/Table/Table'
import { InsightsMapper } from 'src/components/Content/InsightsCarousel'

import InsightDrawer from '../Drawers/InsightDrawer'

interface InsightsTableProps {
  data: Insight[]
  onRowClick: (row: Insight | null) => void
  selectedInsight: Insight | null
  filter: {
    groups: string[]
    types: string[]
    subscriptionsIds: string[]
    tenantIds: string[]
    periods: string[]
  }
}

const levelMapper: Record<string, string> = {
  TenantId: 'Tenant',
  SubscriptionId: 'Subscription',
  ResourceGroup: 'Resource Group',
  MeterCategory: 'Service',
  ResourceLocation: 'Region'
}

const InsightsTable: React.FC<InsightsTableProps> = ({ data, filter, onRowClick, selectedInsight }) => {
  const { data: tenants } = useTenantsQuery({ active: true })
  const subscriptions = useMemo(() => tenants?.flatMap((tenant) => tenant.subscriptions) ?? [], [tenants])

  const filteredData = useMemo(() => {
    if (!data) return []

    return data
      .filter((insight) => {
        if (filter.groups.length && !filter.groups.includes(insight.group)) return false
        if (filter.types.length && !filter.types.includes(insight.type)) return false
        if (filter.periods.length && !filter.periods.includes(insight.period)) return false

        const matchSubId = filter.subscriptionsIds.includes(insight.subscriptionId)
        const matchTenantId = filter.tenantIds.includes(insight.tenantId)

        return matchSubId || (!insight.subscriptionId && matchTenantId)
      })
      .map((d) => {
        const { title, message } = InsightsMapper(d, tenants, subscriptions)
        return { title, message, ...d }
      })
  }, [data, filter, tenants, subscriptions])

  const columns = useMemo<ColumnDef<(typeof filteredData)[number]>[]>(
    () => [
      {
        accessorKey: 'title',
        header: 'Title'
      },
      {
        accessorKey: 'message',
        header: 'Description'
      },
      {
        accessorKey: 'group',
        header: 'Level',
        cell: (info) => {
          const value = info.getValue() as string
          const mappedValue = levelMapper[value] ?? value
          return <span>{mappedValue}</span>
        }
      },
      {
        accessorKey: 'period',
        header: 'Period',
        cell: (info) => <span className="capitalize">{info.getValue() as string}</span>
      }
    ],
    []
  )

  return (
    <>
      {selectedInsight && <InsightDrawer insight={selectedInsight} onClose={() => onRowClick(null)} />}
      <Table data={filteredData} columns={columns} onRowClick={(row) => onRowClick(row as Insight)} />
    </>
  )
}

export default InsightsTable
