import { type AzureBudget } from '../types/azure'
import { type Quote } from '../types/quote'

/* contacts */
export const openContactsConfirmModal = (): {
  type: string
  payload: Record<string, boolean>
} => {
  return {
    type: 'OPEN_CONTACT_CONFIRM_MODAL',
    payload: { contactconfirmmodal: true }
  }
}

/* accounts */
export const openAccountsModal = (): {
  type: string
  payload: Record<string, boolean>
} => {
  return {
    type: 'OPEN_ACCOUNTS_MODAL',
    payload: { accounts: true }
  }
}

/* signquote */
export const openSignQuoteView = (
  encryptedNumber?: string
): {
  type: string
  payload: Record<string, string | boolean | undefined>
} => {
  return {
    type: 'OPEN_SIGNQUOTE_VIEW',
    payload: { signquoteview: true, encryptedNumber: encryptedNumber }
  }
}

export const openSignQuoteModal = (
  quote: Quote
): {
  type: string
  payload: Record<string, string | boolean | Quote | undefined>
} => {
  return {
    type: 'OPEN_SIGNQUOTE_MODAL',
    payload: { signquotemodal: true, quote: quote }
  }
}

export const openDelegateQuoteModal = (
  quote: Quote
): {
  type: string
  payload: Record<string, string | boolean | Quote | undefined>
} => {
  return {
    type: 'OPEN_DELEGATEQUOTE_MODAL',
    payload: { delegatequotemodal: true, quote: quote }
  }
}

/* close signQuote modals - authenticated*/
export const closeSignQuoteModal = (): {
  type: string
  payload: Record<string, boolean>
} => {
  return {
    type: 'CLOSE_SIGNQUOTE_MODAL',
    payload: { signquotemodal: false }
  }
}

/* close signQuote view - unauthenticated*/
export const closeSignQuoteView = (): {
  type: string
  payload: Record<string, boolean>
} => {
  return {
    type: 'CLOSE_SIGNQUOTE_VIEW',
    payload: { signquoteview: false }
  }
}

/* editazurebudget */
export const openEditAzureBudgetModal = (
  budget: AzureBudget
): {
  type: string
  payload: Record<string, boolean | AzureBudget>
} => {
  return {
    type: 'OPEN_EDIT_AZURE_BUDGET_MODAL',
    payload: { editazurebudgetmodal: budget }
  }
}

export const closeEditAzureBudgetModal = (): {
  type: string
  payload: Record<string, boolean>
} => {
  return {
    type: 'CLOSE_EDIT_AZURE_BUDGET_MODAL',
    payload: { editazurebudgetmodal: false }
  }
}

export const openNoAzureModal = (): {
  type: string
  payload: Record<string, boolean>
} => {
  return {
    type: 'OPEN_NOAZURE_MODAL',
    payload: { noazuremodal: true }
  }
}

export const closeNoAzureModal = (): {
  type: string
  payload: Record<string, boolean>
} => {
  return {
    type: 'CLOSE_NOAZURE_MODAL',
    payload: { noazuremodal: false }
  }
}

export const openTagSelectModal = (): {
  type: string
  payload: Record<string, boolean>
} => {
  return {
    type: 'OPEN_TAG_SELECT_MODAL',
    payload: { tagSelectModal: true }
  }
}

export const closeTagSelectModal = (): {
  type: string
  payload: Record<string, boolean>
} => {
  return {
    type: 'CLOSE_TAG_SELECT_MODAL',
    payload: { tagSelectModal: false }
  }
}

/* close all modals */
export const closeModals = (): {
  type: string
  payload: Record<string, boolean>
} => {
  return {
    type: 'CLOSE_MODALS',
    payload: {
      accounts: false,
      signquoteview: false,
      signquotemodal: false,
      delegatequotemodal: false,
      editazurebudgetmodal: false,
      tagSelectModal: false
    }
  }
}
