import { withAITracking } from '@microsoft/applicationinsights-react-js'

import Section from '~/components/Common/Section'
import Spacer from '~/components/Common/Spacer'
import ChangeDrawer from '~/components/Drawers/Change/ChangeDrawer'
import ChangeTable from '~/components/Tables/ChangeTable'

import { reactPlugin } from '~/configs/appInsights'

import { ChangeFilters } from './components/ChangeFilters'
import { ChangeStats } from './components/ChangeStats'
import { ChangeFiltersProvider } from './context/ChangeFilter/provider'

const Changes = () => {
  return (
    <ChangeFiltersProvider>
      <Section>
        <ChangeStats />
      </Section>

      <Section>
        <ChangeFilters />

        <Spacer className="!block" />

        <ChangeTable />
      </Section>

      <ChangeDrawer />
    </ChangeFiltersProvider>
  )
}

const ChangesWithTracking = withAITracking(reactPlugin, Changes, 'Changes')
export default ChangesWithTracking
