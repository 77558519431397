const initialState = {
  theme: null
}

const settingsReducer = (
  state = initialState,
  action: {
    type: string
    payload: Record<string, string>
  }
): Record<string, string | null> => {
  switch (action.type) {
    case 'SET_SETTINGS':
    case 'UPDATE_SETTINGS':
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

export default settingsReducer
