import { type Account } from '../types/account'

const initialState = {
  name: '',
  id: ''
}

const accountReducer = (
  state = initialState,
  action: {
    type: string
    payload: Account
  }
): Account => {
  switch (action.type) {
    case 'SET_ACCOUNT':
      localStorage.setItem('account', JSON.stringify(action.payload))
      return action.payload
    default:
      return state
  }
}

export default accountReducer
