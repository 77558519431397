import {
  type ColumnDef,
  type RowSelectionState,
  type SortingState,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'

import { useEffect, useMemo, useState } from 'react'

import { PiCaretDown, PiCaretLeft, PiCaretRight, PiCaretUp } from 'react-icons/pi'

interface TableProps<T extends object> {
  data: T[]
  columns: ColumnDef<T>[]
  hiddenColumns?: string[]
  disableControls?: boolean
  bordered?: boolean
  onRowClick?: (row: T) => void | Promise<void>
  onRowSelectionChange?: (selectedRows: T[]) => void
  defaultSortBy?: SortingState
}

export default function Table<T extends object>({
  data,
  columns,
  hiddenColumns = [],
  disableControls = false,
  bordered = false,
  onRowClick,
  onRowSelectionChange,
  defaultSortBy = []
}: TableProps<T>) {
  const [sorting, setSorting] = useState<SortingState>(defaultSortBy)
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  useEffect(() => {
    setRowSelection({})
  }, [data])

  useEffect(() => {
    onRowSelectionChange?.(Object.keys(rowSelection).map((index) => data[Number(index)]))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection, data])

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      rowSelection
    },
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: disableControls ? data.length : 10
      },
      columnVisibility: hiddenColumns.reduce((acc, columnId) => ({ ...acc, [columnId]: false }), {})
    }
  })

  const paginationRange = useMemo(() => {
    const totalPageCount = table.getPageCount()
    const currentPage = table.getState().pagination.pageIndex + 1

    if (totalPageCount <= 5) {
      return Array.from({ length: totalPageCount }, (_, i) => i + 1)
    }

    let rangeStart = currentPage - 2
    let rangeEnd = currentPage + 2

    if (rangeStart < 1) {
      rangeStart = 1
      rangeEnd = 5
    } else if (rangeEnd > totalPageCount) {
      rangeStart = totalPageCount - 4
      rangeEnd = totalPageCount
    }

    return Array.from({ length: 5 }, (_, i) => {
      const pageNumber = rangeStart + i
      if (pageNumber <= totalPageCount) {
        return pageNumber
      }
      return pageNumber - totalPageCount
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getPageCount(), table.getState().pagination.pageIndex])

  return (
    <div className={`rounded-content bg-th-content py-4 ${bordered ? 'border border-th-border' : ''}`}>
      <div className="overflow-x-auto">
        <table className="w-full text-sm">
          <thead className="border-b border-th-border text-th-text-secondary">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="cursor-pointer select-none whitespace-nowrap p-4 text-left first:pl-8 hover:text-th-text"
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <div className="flex items-center gap-2">
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {header.column.getIsSorted() &&
                        (header.column.getIsSorted() === 'desc' ? <PiCaretDown className="h-4 w-4" /> : <PiCaretUp className="h-4 w-4" />)}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="font-bold text-th-text">
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                onClick={() => onRowClick?.(row.original)}
                className={`border-b border-th-border ${onRowClick ? 'cursor-pointer hover:shadow' : ''}`}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="p-4 first:pl-8">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {!disableControls && (
        <div className="flex items-center justify-center gap-4 px-8 pt-4 text-sm font-bold">
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => table.setPageSize(Number(e.target.value))}
            className="rounded-lg border border-th-border bg-th-content px-2 py-1 text-xs"
          >
            {[5, 10, 20, 25, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show: {pageSize}
              </option>
            ))}
          </select>
          <div className="flex flex-auto justify-center">
            <div className="flex w-min items-center gap-2">
              <button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} className="rounded-lg border border-th-border px-2 py-1">
                <PiCaretLeft className="h-5 w-5" />
              </button>
              {paginationRange.map((pageNumber) => (
                <button
                  key={pageNumber}
                  onClick={() => table.setPageIndex(pageNumber - 1)}
                  className={`rounded-lg border px-4 py-1 ${
                    pageNumber === table.getState().pagination.pageIndex + 1 ? 'border-th-primary focus:shadow-none' : 'border-th-border'
                  }`}
                >
                  {pageNumber}
                </button>
              ))}
              <button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} className="rounded-lg border border-th-border px-2 py-1">
                <PiCaretRight className="h-5 w-5" />
              </button>
            </div>
          </div>
          <div className="text-xs">
            Showing {table.getRowModel().rows.length} of {data.length} entries
          </div>
        </div>
      )}
    </div>
  )
}
