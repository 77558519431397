import useChangeApprovalQuery from '~/hooks/services/servicenow/queries/useChangeApprovalQuery'
import useAccount from '~/hooks/utils/useAccount'
import useOwnUser from '~/hooks/utils/useOwnUser'

import PortalDrawer from '~/components/Common/PortalDrawer'

import { useNavigate, useParams } from 'react-router-dom'

import ApprovalActions from './components/ApprovalActions'
import ChangeHeader from './components/ChangeHeader'
import Properties from './components/Properties'

const ChangeDrawer = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { data: changeApproval, isLoading: isApprovalLoading } = useChangeApprovalQuery(id)

  const handleClose = () => {
    const currentPath = window.location.pathname
    const newPath = currentPath.substring(0, currentPath.lastIndexOf('/'))
    navigate(newPath)
  }

  const user = useOwnUser()
  const account = useAccount()

  const drawerIsHidden = !id || !user || !account

  return (
    <PortalDrawer
      title={changeApproval ? changeApproval.number : 'Change'}
      loading={isApprovalLoading}
      hidden={drawerIsHidden}
      onClose={handleClose}
      actions={<ApprovalActions onClose={handleClose} />}
    >
      <ChangeHeader />

      <hr className="my-8 border-th-border" />
      <Properties />
    </PortalDrawer>
  )
}

export default ChangeDrawer
