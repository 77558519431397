import { withAITracking } from '@microsoft/applicationinsights-react-js'

import { Fragment, type FunctionComponent, useEffect, useState } from 'react'

import { type Contact } from '../../types/contact'
import { type User } from '../../types/user'

import axios, { type AxiosResponse } from 'axios'
import { CSVLink } from 'react-csv'
import { Iconly } from 'react-iconly'
import { FcCancel, FcContacts, FcKey, FcOk } from 'react-icons/fc'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { showAlert } from '../../actions/alertsActions'
import { startLoading, stopLoading } from '../../actions/loadingActions'
import Banner from '../../components/Common/Banner'
import Button from '../../components/Common/Button'
import Count from '../../components/Common/Count'
import Popover from '../../components/Common/Popover'
import Section from '../../components/Common/Section'
import Spacer from '../../components/Common/Spacer'
import ContactTable from '../../components/Tables/ContactTable'
import { reactPlugin } from '../../configs/appInsights'
import api from '../../constants/api'

const Contacts: FunctionComponent = (): JSX.Element => {
  const [contacts, setContacts] = useState<Contact[]>([])
  const [filtered, setFiltered] = useState<Contact[]>(contacts)
  const [showSearch, setShowSearch] = useState<boolean>(false)
  const [state, setState] = useState<string | undefined>(undefined)

  const dispatch = useDispatch()
  const location = useLocation()
  const account = useSelector((state: RootStateOrAny) => state.account)

  /* filter the contacts by search term */
  const filter = (value: string) => {
    const searchTerm = value.toLowerCase()

    setFiltered(
      contacts.filter(
        (contacts: Contact) =>
          contacts.first_name.toLowerCase().indexOf(searchTerm) !== -1 ||
          contacts.last_name.toLowerCase().indexOf(searchTerm) !== -1 ||
          contacts.email.toLowerCase().indexOf(searchTerm) !== -1
      )
    )
  }

  /* get the contacts for the selected account */
  useEffect(() => {
    const source = axios.CancelToken.source()

    dispatch(startLoading())

    if (account.id) {
      /* get the contacts from ServiceNow */
      api
        .get(`/servicenow/account/${account.id}/contacts`, { cancelToken: source.token })
        .then((response) => {
          if (response.data) {
            /* stop loading here if there are no contacts to process */
            if (response.data.length === 0) {
              return dispatch(stopLoading())
            }

            const serviceNowContacts = response.data
            const sys_ids = serviceNowContacts.map((contact: Contact) => contact.id)

            /* get the registrations from b2c */
            api
              .post(`/graph/registrations`, { sys_ids }, { cancelToken: source.token })
              .then((response) => {
                const registrations: Record<string, boolean> = response.data

                /* mark each contact as registered or unregistered */
                const contacts = serviceNowContacts.map((contact: Contact) => {
                  return {
                    ...contact,
                    registered: registrations ? registrations[contact.id] : false
                  }
                })

                /* check if any of the registered contacts are admins */
                const promises = contacts.map(async (contact: Contact) => {
                  if (contact.registered) {
                    return await api
                      .get(`/provide/account/${account.id}/user/${contact.email}`, { cancelToken: source.token })
                      .then((response: AxiosResponse) => {
                        const user: User = response.data

                        if (user.accounts) {
                          /* ensure the roles we are getting are for the current account */
                          const index: number = user.accounts.findIndex((acc) => acc.id === account.id)
                          const acc = user.accounts[index]

                          /* set the admin flag */
                          if (acc) {
                            contact.admin = acc.roles.indexOf('Global.Admin') > -1
                          }
                        }

                        return contact
                      })
                      .catch(() => {
                        return contact
                      })
                  }
                  return contact
                })

                /* update the contacts state when the mapping has finished */
                Promise.all(promises).then(() => setContacts(contacts))
              })
              .catch((error) => {
                if (!axios.isCancel(error)) {
                  dispatch(
                    showAlert({
                      type: 'error',
                      message: 'We were unable to retrieve your contacts.' + JSON.stringify(error),
                      component: 'Contacts',
                      error
                    })
                  )
                  dispatch(stopLoading())
                }
              })
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            dispatch(
              showAlert({
                type: 'error',
                message: 'We were unable to retrieve your contacts.',
                component: 'Contacts',
                error
              })
            )
            dispatch(stopLoading())
          }
        })
    }

    return () => {
      // cancel source reqs on componentwillunmount
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.id, location.state])

  /* filter the contacts by state */
  useEffect(() => {
    const filtered = contacts.filter((contact: Contact) => {
      if (state === 'admin') {
        return contact.admin
      }

      if (state === 'registered') {
        return contact.registered
      }

      if (state === 'not registered') {
        return !contact.registered
      }

      return true
    })
    setFiltered(filtered)
  }, [contacts, state, showSearch])

  /* stop loading when the contacts have been processed */
  useEffect(() => {
    if (filtered.length > 0) {
      dispatch(stopLoading())
    }
  }, [filtered, dispatch])

  return (
    <Fragment>
      <Section>
        <div className="my-5 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-1 mdlg:grid-cols-2 xl:grid-cols-4">
          <Count
            icon={<FcContacts className="h-full w-full text-th-warning" />}
            value={contacts.length}
            label="All Contacts"
            className="cursor-pointer"
            onClick={() => setState(undefined)}
            active={state === undefined}
          />
          <Count
            icon={<FcKey className="h-full w-full text-th-warning" />}
            value={contacts.filter((contact) => contact.admin).length}
            label={contacts.filter((contact) => contact.admin).length > 1 ? 'Admins' : 'Admin'}
            className="cursor-pointer"
            onClick={() => setState('admin')}
            active={state === 'admin'}
          />
          <Popover text="Contacts registered to Provide">
            <Count
              icon={<FcOk className="h-full w-full text-th-warning" />}
              value={contacts.filter((contact) => contact.registered).length}
              label="Registered"
              className="cursor-pointer"
              onClick={() => setState('registered')}
              active={state === 'registered'}
            />
          </Popover>
          <Popover text="Contacts not registered to Provide">
            <Count
              icon={<FcCancel className="h-full w-full text-th-warning" />}
              value={contacts.filter((contact) => !contact.registered).length}
              label="Not Registered"
              className="cursor-pointer"
              onClick={() => setState('not registered')}
              active={state === 'not registered'}
            />
          </Popover>
        </div>
      </Section>
      <Section>
        <Banner>
          <Spacer className="hidden sm:block" />
          {/* search */}
          {showSearch ? (
            <Fragment>
              <input type="text" className="rounded-lg p-2 font-body text-sm" placeholder="Search" autoFocus onChange={(e) => filter(e.target.value)} />
              <IoCloseCircleOutline className="h-6 w-6 cursor-pointer" onClick={() => setShowSearch(false)} />
            </Fragment>
          ) : (
            <Iconly name="Search" set="light" className="cursor-pointer" onClick={() => setShowSearch(true)} />
          )}
          {/* csv download */}
          <CSVLink data={filtered} filename="contacts.csv" target="_blank">
            <Button onClick={() => null}>Download CSV</Button>
          </CSVLink>
        </Banner>
        <Spacer />
        <ContactTable contacts={filtered} />
      </Section>
    </Fragment>
  )
}

const ContactsWithTracking = withAITracking(reactPlugin, Contacts, 'Contacts')
export default ContactsWithTracking
