import { useMsal } from '@azure/msal-react'
import { useQueryClient } from '@tanstack/react-query'

import { type FunctionComponent } from 'react'

import { Iconly } from 'react-iconly'
import { IoChevronForward, IoClose } from 'react-icons/io5'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { closeModals, openAccountsModal } from '../../../actions/modalActions'
import { closeNavigation } from '../../../actions/navigationActions'
import { closeUser } from '../../../actions/userActions'
import Button from '../../Common/Button'
import Card from '../../Common/Card'

const UserCard: FunctionComponent = (): JSX.Element => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { instance } = useMsal()

  const user = useSelector((state: RootStateOrAny) => state.user)
  const account = useSelector((state: RootStateOrAny) => state.account)

  const queryClient = useQueryClient()

  return (
    <Card
      position={Card.position.BOTTOM_LEFT}
      className="z-50 xs:mb-6 xs:ml-32 md:-ml-6"
      close={() => {
        dispatch(closeModals())
        dispatch(closeUser())
      }}
    >
      <div className="flex">
        {/* user picture */}
        <div className="flex h-12 w-12 items-center justify-center rounded-full xs:rounded-none">
          <Iconly name="User" className="icon h-12 w-12 rounded-full bg-th-content-secondary p-3" />
        </div>
        {/* user info */}
        <div className="flex h-12 w-full flex-col pl-5">
          <div>
            {user.first_name} {user.last_name}
          </div>
          <div className="text-xs text-th-text-secondary">{user.email}</div>
        </div>
        {/* close */}
        <IoClose
          className="h-6 w-6 cursor-pointer xs:hidden"
          onClick={() => {
            dispatch(closeModals())
            dispatch(closeUser())
          }}
        />
      </div>
      {/* account selection */}
      <Button
        bordered
        onClick={() => {
          dispatch(closeUser())
          dispatch(openAccountsModal())
          dispatch(closeNavigation())
        }}
        className="my-4 flex w-full gap-2"
      >
        <span className="flex-auto text-left">{account.name}</span>
        <IoChevronForward />
      </Button>
      <hr className="border-th-border" />
      {/* user actions */}
      <div className="mt-4 flex items-center justify-between gap-4">
        <Button
          bordered
          onClick={() => {
            dispatch(closeModals())
            dispatch(closeUser())
            queryClient.clear()
            queryClient.invalidateQueries()
            instance.logout()
          }}
          className="gap-2"
        >
          Logout
          <Iconly name="Logout" set="light" className="h-4" />
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            dispatch(closeModals())
            dispatch(closeUser())
            dispatch(closeNavigation())
            navigate('/profile')
          }}
        >
          View Profile
        </Button>
      </div>
    </Card>
  )
}

export default UserCard
