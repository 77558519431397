import { type UseQueryOptions, useQueryClient } from '@tanstack/react-query'

import { useEffect } from 'react'

import type { Cart } from 'src/types/cart'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import { useLocalStorage } from 'src/hooks/utils/useLocalStorage'
import useQuery from 'src/hooks/utils/useQuery'

interface UseCartQuery {
  tenantId: string | undefined
}

const useCartQuery = ({ tenantId }: UseCartQuery, options?: UseQueryOptions<Cart>) => {
  const { id: accountId } = useAccount()
  const [storedCartId, setStoredCartId] = useLocalStorage<string | null>(`cart-${tenantId}`, null)

  const queryKey = ['cartQuery', accountId, tenantId, storedCartId]

  const queryResult = useQuery(
    queryKey,
    async ({ signal }) => {
      const response = await api.get(`/ms-api/orders/account/${accountId}/${tenantId}/cart/${storedCartId}`, { signal })
      return response.data as Cart
    },
    {
      enabled: !!accountId && !!tenantId && storedCartId !== undefined && storedCartId !== null,
      ...options
    }
  )

  useEffect(() => {
    if (queryResult.isError) setStoredCartId(null)
  }, [queryResult, setStoredCartId])

  const queryClient = useQueryClient()
  const setData = (newCart: typeof queryResult.data | null) => queryClient.setQueryData(queryKey, newCart)

  const invalidateData = () => queryClient.invalidateQueries(queryKey)

  return { ...queryResult, setData, invalidateData }
}

export default useCartQuery
