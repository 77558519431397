import { useEffect } from 'react'

import type { User } from 'src/types/user'

import { setSettings } from 'src/actions/settingsActions'
import { setUser } from 'src/actions/userActions'

import api from 'src/constants/api'

import type { Claims } from 'src/hooks/utils/useMsalClaims'
import useQuery from 'src/hooks/utils/useQuery'

import type { AxiosError } from 'axios'
import { useDispatch } from 'react-redux'

import useCreateUserMutation from '../mutations/useCreateUserMutation'

const useUserQuery = ({ claims }: { claims: Claims | null }) => {
  const createUserMutation = useCreateUserMutation()
  const dispatch = useDispatch()

  const userQuery = useQuery(
    ['userQuery', claims],
    async ({ signal }) => {
      try {
        const response = await api.get('provide/user', { signal })
        return response.data as User
      } catch (error) {
        if ((error as AxiosError)?.response?.status === 404 && claims) return await createUserMutation.mutateAsync(claims)
        throw error
      }
    },
    {
      errorMessage: 'Failed to fetch user',
      cacheTime: 60 * 1000 * 5,
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    if (!userQuery.data) return

    const { id, first_name, last_name, email, ...data } = userQuery.data

    dispatch(
      setUser({
        id: id || claims?.sub || 'Unknown',
        first_name: first_name || claims?.first_name || 'User',
        last_name: last_name || claims?.last_name || 'Unknown',
        email: email || claims?.email || 'Unknown',
        ...data
      })
    )

    if (userQuery.data.settings) dispatch(setSettings(userQuery.data.settings))
  }, [claims, dispatch, userQuery.data])

  return {
    ...userQuery
  }
}

export default useUserQuery
