import { type User } from 'src/types/user'

import { setSettings } from 'src/actions/settingsActions'
import { setUser } from 'src/actions/userActions'

import api from 'src/constants/api'

import type { Claims } from 'src/hooks/utils/useMsalClaims'
import useMutation from 'src/hooks/utils/useMutation'

import { useDispatch } from 'react-redux'

const useCreateUserMutation = () => {
  const dispatch = useDispatch()

  return useMutation(
    async (claims: Claims) => {
      const response = await api.post('provide/user/', {
        id: claims.sub,
        email: claims.email
      })
      return response.data as User
    },
    {
      errorMessage: 'Failed to create user',
      onSuccess: (user, claims) => {
        const { id, first_name, last_name, email, ...data } = user

        dispatch(
          setUser({
            id: id || claims?.sub || 'Unknown',
            first_name: first_name || claims?.first_name || 'User',
            last_name: last_name || claims?.last_name || 'Unknown',
            email: email || claims?.email || 'Unknown',
            ...data
          })
        )

        if (user.settings) dispatch(setSettings(user.settings))
      }
    }
  )
}

export default useCreateUserMutation
