import { useMemo } from 'react'

import moment from 'moment'

import useSoftwareSubscriptionsQuery from '../../services/servicenow/queries/useSoftwareSubscriptionsQuery'
import { useTenantsQuery } from '../../services/servicenow/queries/useTenantsQuery'

interface useSoftwareSubscriptionsTotalConfig {
  dateRange: [moment.Moment, moment.Moment]
}

const useSoftwareSubscriptionsTotal = ({ dateRange }: useSoftwareSubscriptionsTotalConfig) => {
  const { data: tenants } = useTenantsQuery({ active: true })
  const { data: softwareSubscriptions } = useSoftwareSubscriptionsQuery({ tenants })

  const softwareSubscriptionsTotal = useMemo(() => {
    const active = softwareSubscriptions.filter((ss) => ss.state === 'Active' && moment(ss.charges?.at(0)?.date).isBetween(dateRange[0], dateRange[1]))
    const total = active.reduce((acc, curr) => acc + curr.cost, 0)
    return total
  }, [softwareSubscriptions, dateRange])

  return softwareSubscriptionsTotal
}

export default useSoftwareSubscriptionsTotal
