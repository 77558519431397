import { showAlert } from 'src/actions/alertsActions'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useMutation from 'src/hooks/utils/useMutation'

import { useDispatch } from 'react-redux'

interface CreateTicketInput {
  category: string
  title: string
  contact_type: string
  correlation_display: string
  service_technology: string
  issue: string
  assigned_group: string
  users_affected: string
  business_impact: string
  description: string
}

const useCreateTicketMutation = () => {
  const account = useAccount()
  const dispatch = useDispatch()

  return useMutation(
    async ({ ticket }: { ticket: CreateTicketInput }) => {
      try {
        const response = await api.post(`/servicenow/account/${account.id}/ticket`, { ...ticket, account: account.id })

        dispatch(
          showAlert({
            type: 'success',
            message: response.data.number + ' created.',
            component: 'NewAzureDeployment'
          })
        )

        return true
      } catch {
        return false
      }
    },
    {
      errorMessage: 'We were unable to initiate your deployment to Azure. Please try again.'
    }
  )
}

export default useCreateTicketMutation
