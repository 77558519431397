import { type FunctionComponent } from 'react'

interface Props {
  className?: string
}

const Spacer: FunctionComponent<Props> = (props: Props): JSX.Element => {
  return <div className={`hidden h-8 flex-auto sm:block ${props.className}`}></div>
}

export default Spacer
