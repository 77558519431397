import { useQueryClient } from '@tanstack/react-query'

import React, { useState } from 'react'

import useCreateFolderMutation from 'src/hooks/services/provide/mutations/useCreateFolderMutation'
import useFilesQuery, { type FileMetadata, type Folder } from 'src/hooks/services/provide/queries/useFilesQuery'
import useFolderConfigQuery from 'src/hooks/services/provide/queries/useFolderConfigQuery'
import useAccount from 'src/hooks/utils/useAccount'
import useOwnUser from 'src/hooks/utils/useOwnUser'

import Button from 'src/components/Common/Button'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from 'src/components/Common/Dialog'

import { useSelector } from 'react-redux'
import { type FolderState } from 'src/reducers/folderReducer'

import Input from '../Common/Input'
import Popover from '../Common/Popover'
import Dropdown from '../Common/RefactoredDropdown'

const CreateFolderDialog: React.FC = () => {
  const { id: accountId } = useAccount()

  const folderPath = useSelector((state: FolderState) => state.folder.folderPath)
  const search = useSelector((state: FolderState) => state.folder.search)

  const folderId = folderPath.at(-1)?.id || null
  const [newFolderName, setNewFolderName] = useState('')
  const [isCreateFolderDialogOpen, setIsCreateFolderDialogOpen] = useState(false)

  const queryClient = useQueryClient()

  const { mutateAsync: createFolder, isLoading: isCreatingFolder } = useCreateFolderMutation({
    queryKeysToInvalidate: [['getFilesQuery', search || null, folderId || null, accountId]],
    onSuccess: (newFolder) => {
      queryClient.setQueryData<{ files: FileMetadata[]; folders: Folder[] }>(['getFilesQuery', search || null, folderId || null, accountId], (oldData) => {
        if (!oldData) return oldData
        return {
          ...oldData,
          folders: [...oldData.folders, newFolder]
        }
      })
    }
  })

  const handleCreateFolder = async () => {
    await createFolder({ folderName: newFolderName.trim(), parentId: folderId })
    setNewFolderName('')
    setIsCreateFolderDialogOpen(false)
  }

  const user = useOwnUser()

  const { data: folderConfigs, isLoading: isLoadingFolderOptions } = useFolderConfigQuery()

  const { data: existingFoldersAndFiles, isLoading: isLoadingExistingFolders } = useFilesQuery(
    { query: null, folderId: folderId || null },
    { refetchOnWindowFocus: false }
  )
  const existingFolders = existingFoldersAndFiles?.folders || []

  const isTemplatedFolder = folderConfigs?.find((folder) => folderPath.at(-2)?.createdFromConfigId === folder.id)?.type === 'Template'
  const childFolder = isTemplatedFolder ? folderPath.at(-2) : folderPath.at(-1)
  const childFolderConfigs = folderConfigs?.filter((folder) => folder.parentId === childFolder?.createdFromConfigId) || []
  const availableFolders = childFolderConfigs?.filter((folder) => !existingFolders.find((existingFolder) => existingFolder.name === folder.name)) || []

  const lastFolderConfigId = folderPath.findLast((path) => path.createdFromConfigId !== null)?.createdFromConfigId
  const lastFolderConfig = folderConfigs?.find((folder) => folder.id === lastFolderConfigId) ?? null
  const freeFormFolder = lastFolderConfig?.type === 'Unstructured'

  const previousFolderConfig = folderConfigs?.find((folder) => folderPath.at(-1)?.createdFromConfigId === folder.id)
  const isTemplate = previousFolderConfig?.type === 'Template'

  const creationString = isTemplate ? previousFolderConfig.name.slice(0, -1) + ' Folder' : 'Folder'

  if (!user || !user.super_admin) return <div />

  const isLoading = isLoadingFolderOptions || isLoadingExistingFolders
  const isUploadsFolder = folderPath.some((folder) => folder.name === 'Uploads') // Treat as unstructured folder
  const hasAvailableFolders = availableFolders.length > 0 // should always be structured
  const isFreeformFolder = isUploadsFolder || freeFormFolder

  return (
    <Dialog open={isCreateFolderDialogOpen} onOpenChange={setIsCreateFolderDialogOpen}>
      <DialogTrigger asChild>
        <Popover
          key="create-folder"
          text="All predefined folders names have been created. Contact an administrator to add more options or enable custom folder names."
          disabled={isLoading || hasAvailableFolders || isFreeformFolder}
        >
          <Button
            bordered
            disabled={isLoading || !(hasAvailableFolders || isFreeformFolder) || (isUploadsFolder && user?.super_admin)}
            isLoading={isLoadingFolderOptions || isLoadingExistingFolders}
            onClick={() => setIsCreateFolderDialogOpen(true)}
          >
            New {creationString}
          </Button>
        </Popover>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create New {creationString}</DialogTitle>
        </DialogHeader>
        {existingFolders.find((folder) => folder.id === newFolderName) && <p className="text-red-500">Folder with this name already exists</p>}
        {freeFormFolder || isTemplate ? (
          <Input placeholder={`${creationString} Name`} value={newFolderName} onChange={(e) => setNewFolderName(e.target.value)} />
        ) : (
          <Dropdown
            data={availableFolders.map((folder) => ({ value: folder.name, label: folder.name, checked: newFolderName === folder.name })) || []}
            onChange={(e) => setNewFolderName(e.value)}
            mode="simpleSelect"
          />
        )}
        <Popover
          text="A folder with this name already exists in the current directory. Please choose a different name."
          disabled={!existingFolders.some((folder) => folder.name === newFolderName)}
          className="w-full"
        >
          <Button
            className="w-full"
            onClick={handleCreateFolder}
            bordered
            isLoading={isCreatingFolder}
            disabled={newFolderName.trim().length === 0 || existingFolders.some((folder) => folder.name === newFolderName)}
          >
            Create
          </Button>
        </Popover>
      </DialogContent>
    </Dialog>
  )
}

export default CreateFolderDialog
