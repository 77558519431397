import axios from 'axios'

import { setAuthorised } from '../actions/authorisedActions'
import { stopLoading } from '../actions/loadingActions'
import { clientId, msalInstance } from '../configs/b2c'

/* redux store */
import store from '../configs/store'

const api = axios.create({
  baseURL: window.config.apiUrl,
  withCredentials: false
})

/* get an access token using the msal instance */
const getAccessToken = async (): Promise<string | void | undefined> => {
  if (!msalInstance.getActiveAccount()) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
  }

  const request = {
    scopes: [clientId]
  }

  const token = await msalInstance
    .acquireTokenSilent(request)
    .then((response) => {
      return response.accessToken
    })
    .catch(() => {
      return msalInstance.logoutRedirect()
    })

  return token
}

/* append port for local development */
if (window.config.apiUrl === 'http://localhost') {
  api.interceptors.request.use((request) => {
    const url = request.url?.split('/')

    if (url) {
      /* a slash needs to be added if the request url did not start with one */
      const slash = url[0] === '' ? '' : '/'
      const prefix = url[0] || url[1]

      switch (prefix) {
        case 'servicenow':
          request.baseURL += ':7071' + slash
          break
        case 'provide':
          request.baseURL += ':7072' + slash
          break
        case 'graph':
          request.baseURL += ':7073' + slash
          break
        case 'soteria':
          /*request.baseURL += ':7074' + slash*/
          request.baseURL = 'https://apim-cd-stage.azure-api.net/soteria'
          request.url = request.url?.replace('/' + prefix, '')
          break
        case 'azure':
          request.baseURL += ':7042' + slash
          break
        case 'ms-api':
          request.baseURL += ':44327' + slash
          request.baseURL = request.baseURL?.replace('http', 'https')
          request.url = request.url?.replace('/' + prefix, '').replace('/v2', '')
          return request
        case 'sustainability':
          request.baseURL += ':7069' + slash
          break
        case 'old-provide':
          request.baseURL = 'https://apim-cd-stage.azure-api.net/old-provide'
          break
        case 'website':
          request.baseURL = 'https://clouddirect.net'
          break
      }
      if (prefix === 'old-provide' || prefix === 'website') {
        request.url = request.url?.replace(prefix, '')
      } else {
        request.url = request.url?.replace(prefix, 'api')
      }
    }
    return request
  })
}

/* set the access token in the authorization header for every request */
/* - except to old-provide - */

api.interceptors.request.use(
  async (request) => {
    if (request.url?.split('/')[0] !== 'old-provide') {
      const token = await getAccessToken()

      if (token) {
        request.headers['Authorization'] = `Bearer ${token}`
      }
    }
    return request
  },
  (error) => {
    return Promise.reject(error)
  }
)

/* handle the response of each request */
api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    switch (error?.response?.status) {
      case 401:
      case 403:
        if (error.response.config?.headers?.THROW_UNAUTHORISED !== false) {
          store.dispatch(setAuthorised(false))
        }
        store.dispatch(stopLoading())
        return Promise.resolve(error)
      default:
        return Promise.reject(error)
    }
  }
)

export default api
