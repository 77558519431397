import { type Tenant } from '../types/azure'

const initialState = {
  name: '',
  id: ''
}

export const tenantReducer = (
  state = initialState,
  action: {
    type: string
    payload: Tenant
  }
): Tenant => {
  switch (action.type) {
    case 'SET_TENANT':
      localStorage.setItem('tenant', JSON.stringify(action.payload))
      return action.payload
    default:
      return state
  }
}

export const tenantsReducer = (
  state: Tenant[] | null = null,
  action: {
    type: string
    payload: Tenant[]
  }
): Tenant[] | null => {
  switch (action.type) {
    case 'SET_TENANTS':
      return action.payload
    default:
      return state
  }
}
