import { createContext } from 'react'

import type { ChangeApprovalFilterOptions } from '~/hooks/services/servicenow/queries/useChangeApprovalsQuery'

export interface ChangeApprovalFiltersContextType {
  filterOptions: ChangeApprovalFilterOptions
  setFilterOptions: (fn: (prev: ChangeApprovalFilterOptions) => ChangeApprovalFilterOptions) => void
}

export const ChangeApprovalFiltersContext = createContext<ChangeApprovalFiltersContextType | undefined>(undefined)
