import React, { useMemo } from 'react'

import { useFileDownload } from 'src/hooks/composite/files/useFileDownload'
import { useFileFolderDelete } from 'src/hooks/composite/files/useFileFolderDelete'
import { useFileFolderMove } from 'src/hooks/composite/files/useFileFolderMove'
import { useFilePath } from 'src/hooks/composite/files/useFilePath'
import { useFileShare } from 'src/hooks/composite/files/useFileShare'
import useFilesQuery, { type FileMetadata, type Folder } from 'src/hooks/services/provide/queries/useFilesQuery'
import useFolderConfigQuery from 'src/hooks/services/provide/queries/useFolderConfigQuery'
import useOwnUser from 'src/hooks/utils/useOwnUser'

import IconButton from 'src/components/Common/IconButton'
import Spinner from 'src/components/Common/Spinner'

import { classNames } from 'src/utils/classNames'

import { PiDownload, PiFlowArrow, PiShare, PiTrash } from 'react-icons/pi'
import { useSelector } from 'react-redux'
import { type FileState } from 'src/reducers/fileReducer'

import BreadcrumbComponent from './Breadcrumb'
import TableView from './TableView'

const FileExplorer: React.FC = () => {
  const { folderPath, currentFolderId, handleFolderClick, handleFolderNavigate } = useFilePath()

  //const [viewMode, setViewMode] = useLocalStorage<'grid' | 'table'>('fileExplorerViewMode', 'table')

  const { data, isLoading } = useFilesQuery({ folderId: currentFolderId || null })

  const combinedData = useMemo(() => {
    const files = data?.files || []
    const folders = data?.folders || []

    return [...folders, ...files].map((item) => ({
      ...item,
      name: (item as Folder).name || (item as FileMetadata).fileName,
      type: (item as Folder).parentId !== undefined ? 'folder' : 'file'
    }))
  }, [data])

  const selectedItems = useSelector((state: FileState) => state.file)

  const { MoveModal, openMoveModal, movingFileIds } = useFileFolderMove()
  const { DeleteConfirmationModal, openDeleteModal, deletingIds } = useFileFolderDelete()
  const { handleMultipleFilesDownload, downloadingFileIds } = useFileDownload()
  const { ShareModal, openShareModal, sharingFileIds } = useFileShare()

  const { data: folderConfigs } = useFolderConfigQuery()

  const lastFolder = folderPath.findLast((path) => path.createdFromConfigId !== null)
  const lastFolderConfig = folderConfigs?.find((folder) => folder.id === lastFolder?.createdFromConfigId) ?? null
  const hasFolderInMultiSelect = selectedItems.some((item) => item.type === 'folder')
  const freeFormFolder = lastFolderConfig?.type === 'Unstructured' || folderPath.some((p) => p.name === 'Uploads')

  const user = useOwnUser()

  return (
    <div>
      {ShareModal}
      {MoveModal}
      {DeleteConfirmationModal}
      <div className="mb-4 flex items-center justify-between">
        <BreadcrumbComponent folderPath={folderPath} handleFolderNavigate={handleFolderNavigate} />
        <div className="mr-4 flex h-8 items-center gap-2">
          <div className={classNames('flex items-center gap-2', !selectedItems.length && 'hidden')}>
            <IconButton
              onClick={() => handleMultipleFilesDownload(selectedItems)}
              disabled={downloadingFileIds.length > 0}
              icon={<PiDownload className="h-5 w-5" />}
              tooltip="Download"
              isLoading={downloadingFileIds.length > 0}
              className={classNames(hasFolderInMultiSelect && 'hidden')}
            />
            <IconButton
              onClick={() =>
                openShareModal(
                  selectedItems.map((item) => item.id),
                  selectedItems[0].name
                )
              }
              disabled={sharingFileIds.length > 0}
              icon={<PiShare className="h-5 w-5" />}
              tooltip="Share"
              isLoading={sharingFileIds.length > 0}
            />
            <IconButton
              onClick={() => openMoveModal(selectedItems.map((item) => ({ id: item.id, name: item.name, type: item.type })))}
              disabled={movingFileIds.length > 0}
              icon={<PiFlowArrow className="h-5 w-5" />}
              tooltip="Move"
              isLoading={movingFileIds.length > 0}
              className={classNames((!freeFormFolder || !user?.super_admin) && 'hidden')}
            />
            <IconButton
              onClick={() => openDeleteModal(selectedItems)}
              disabled={deletingIds.length > 0}
              icon={<PiTrash className="h-5 w-5" />}
              tooltip="Delete"
              isLoading={deletingIds.length > 0}
              className={classNames(!user?.super_admin && 'hidden')}
            />
          </div>
        </div>
      </div>

      {isLoading ? (
        <Spinner className="mx-auto h-8 w-8" />
      ) : (
        <TableView data={combinedData} onFolderClick={handleFolderClick} key="tableView" limitedView={data?.limitedView || false} />
      )}
    </div>
  )
}

export default FileExplorer
