import { type HTMLAttributes, forwardRef } from 'react'

/* utils */
import { classNames } from '../../utils/classNames'

interface SpinnerProps extends HTMLAttributes<HTMLDivElement> {}

const Spinner = forwardRef<HTMLDivElement, SpinnerProps>(({ className, ...rest }, ref) => {
  return <div className={classNames('spinner', className)} ref={ref} {...rest} />
})

Spinner.displayName = 'Spinner'

export default Spinner
