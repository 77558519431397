import { Fragment, type FunctionComponent, useEffect, useState } from 'react'

import { type CreditNote } from '../../types/creditnote'
import { type CreditNoteItem } from '../../types/creditnoteitem'

import moment from 'moment'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { showAlert } from '../../actions/alertsActions'
import { closeDrawers } from '../../actions/drawerActions'
import api from '../../constants/api'
import Button from '../Common/Button'
import Drawer from '../Common/Drawer'
import State from '../Common/State'

const CreditNoteDrawer: FunctionComponent = (): JSX.Element => {
  const account = useSelector((state: RootStateOrAny) => state.account)
  const creditnote: CreditNote = useSelector((state: RootStateOrAny) => state.drawers.creditnote)

  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState<CreditNoteItem[]>([])

  const dispatch = useDispatch()
  useEffect(() => {
    if (creditnote) {
      setLoading(true)

      api
        .post(`/servicenow/account/${account.id}/creditnoteitems`, {
          creditnote: creditnote.id
        })
        .then((response) => {
          const creditnoteitems = response.data.sort((a: CreditNoteItem, b: CreditNoteItem) => (a.item < b.item ? -1 : a.item > b.item ? 1 : 0))

          /* save order items to state */
          setItems(creditnoteitems)
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)

          /* alert the user that the order items couldn't be retrieved */
          dispatch(
            showAlert({
              type: 'error',
              message: "We couldn't retrieve the items for " + creditnote.number + ' at this time. Please try again later.',
              component: 'Order',
              error
            })
          )
        })
    }
  }, [creditnote, account, dispatch])

  /* drawer actions */
  const Actions: FunctionComponent = (): JSX.Element => {
    return (
      <Fragment>
        <Button bordered onClick={() => dispatch(closeDrawers())}>
          Close
        </Button>
      </Fragment>
    )
  }

  /* drawer content */
  return (
    <Drawer title={creditnote ? creditnote.number : 'Credit Note'} loading={loading} actions={<Actions />}>
      <div className="mb-8 flex justify-between">
        {/* order date */}
        <div>
          <div className="font-bold">Taxed on</div>
          <div className="text-sm">{moment(creditnote.tax_date).format('DD/MM/yy')}</div>
        </div>
      </div>

      {/* state and ordered by */}
      <div className="mb-4 flex justify-between">
        <State state={creditnote.size} />
      </div>

      <hr className="my-8 border-th-border" />

      {/* services */}
      <div className="flex items-center font-bold">
        <div>Credit Note Items</div>
      </div>

      {/* service list */}
      <div className="mt-6">
        {items.length > 0 && (
          <ul>
            {items.map((item: CreditNoteItem) => (
              <li key={item.id} className="flex cursor-pointer items-center border-b border-th-border py-2 text-sm last:border-b-0">
                {/* service details */}
                <div className="flex flex-auto space-x-4">
                  <span className="flex-auto">{item.item}</span>
                  <span className="w-10 text-right">£{item.amount}</span>
                </div>
              </li>
            ))}
          </ul>
        )}
        {/* no services found */}
        {items.length === 0 && <div>This credit note doesn't contain any items.</div>}
      </div>
    </Drawer>
  )
}

export default CreditNoteDrawer
