import { withAITracking } from '@microsoft/applicationinsights-react-js'

import { Fragment, type FunctionComponent, useEffect, useState } from 'react'

import { type Recommendation } from '../../../../types/azure'

import axios, { type AxiosResponse } from 'axios'
import { CSVLink } from 'react-csv'
import { Iconly } from 'react-iconly'
import { FcHighPriority, FcList, FcLowPriority, FcMediumPriority } from 'react-icons/fc'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { type OptionTypeBase } from 'react-select'

import { showAlert } from '../../../../actions/alertsActions'
import { startLoading, stopLoading } from '../../../../actions/loadingActions'
import Banner from '../../../../components/Common/Banner'
import Button from '../../../../components/Common/Button'
import Count from '../../../../components/Common/Count'
import Dropdown from '../../../../components/Common/Dropdown'
import Input from '../../../../components/Common/Input'
import Section from '../../../../components/Common/Section'
import Spacer from '../../../../components/Common/Spacer'
import TenantDropdown from '../../../../components/Common/TenantDropdown'
import RecommendationTable from '../../../../components/Tables/RecommendationTable'
import { reactPlugin } from '../../../../configs/appInsights'
import api from '../../../../constants/api'

const advisorCategories = {
  Cost: 'Cost',
  Security: 'Security',
  HighAvailability: 'Reliability',
  OperationalExcellence: 'Operational Excellence',
  Performance: 'Performance'
}

/* dropdown options */
const categories = [
  {
    label: 'All',
    value: undefined
  },
  {
    label: 'Cost',
    value: 'Cost'
  },
  {
    label: 'Security',
    value: 'Security'
  },
  {
    label: 'Reliability',
    value: 'Reliability'
  },
  {
    label: 'Operational Excellence',
    value: 'Operational Excellence'
  },
  {
    label: 'Performance',
    value: 'Performance'
  }
]

const Recommendations: FunctionComponent = (): JSX.Element => {
  const account = useSelector((state: RootStateOrAny) => state.account)
  const tenant = useSelector((state: RootStateOrAny) => state.tenant)

  const dispatch = useDispatch()

  const [recommendations, setRecommendations] = useState<Recommendation[]>([])
  const [filtered, setFiltered] = useState<Recommendation[]>([])
  const [impact, setImpact] = useState<string>()

  /* filters */
  const [category, setCategory] = useState<string | undefined>(undefined)
  const [subscription, setSubscription] = useState<string | undefined>(undefined)
  const [subscriptions, setSubscriptions] = useState<OptionTypeBase[]>([
    {
      label: 'All',
      value: undefined
    }
  ])
  const [showSearch, setShowSearch] = useState<boolean>(false)

  /* filter the recommendations by search term */
  const filter = (value: string) => {
    const searchTerm = value.toLowerCase()

    setFiltered(
      recommendations.filter(
        (rec: Recommendation) =>
          rec.properties.shortDescription.problem.toLowerCase().indexOf(searchTerm) !== -1 || rec.subscriptionName.toLowerCase().indexOf(searchTerm) !== -1
      )
    )
  }

  useEffect(() => {
    const source = axios.CancelToken.source()

    dispatch(startLoading())

    if (tenant.id) {
      api
        .get(`/soteria/account/${account.id}/tenant/${tenant.id}/recommendations`, { cancelToken: source.token })
        .then((response: AxiosResponse) => {
          if (response.data) {
            const recommendations: Record<string, Recommendation> = {}
            const subs: OptionTypeBase[] = subscriptions

            response.data.forEach((rec: Recommendation) => {
              const key = (rec.properties.category + rec.subscriptionId + rec.properties.shortDescription.problem) as keyof typeof recommendations

              /* populate the subscriptions filter */
              subs.push({
                label: rec.subscriptionName,
                value: rec.subscriptionId
              })

              /* relabel the category */
              rec.properties.category = advisorCategories[rec.properties.category as keyof typeof advisorCategories]

              /* increment the resource count */
              recommendations[key] ? recommendations[key].resources++ : (recommendations[key] = { ...rec, resources: 1 })
            })

            setRecommendations(Object.values(recommendations))
            setSubscriptions(
              subs.filter(
                (current, index, self) =>
                  /* dedupe subscriptions filter */
                  index === self.findIndex((t) => t.value === current.value)
              )
            )
            dispatch(stopLoading())
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            dispatch(stopLoading())
            dispatch(
              showAlert({
                type: 'error',
                message: 'We were unable to retrieve your Azure Advisor recommendations.',
                component: 'Recommendations',
                error
              })
            )
          }
        })
    }

    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant])

  /* filter the recommendations by impact */
  useEffect(() => {
    const filtered = recommendations.filter(
      (rec: Recommendation) =>
        (rec.subscriptionId === subscription || subscription === undefined) &&
        (rec.properties.impact === impact || impact === undefined) &&
        (rec.properties.category === category || category === undefined)
    )
    setFiltered(filtered)
  }, [impact, subscription, category, recommendations])

  return (
    <Fragment>
      <Section>
        <Banner>
          <Spacer />
          <TenantDropdown />
        </Banner>
        <div className="my-5 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-1 mdlg:grid-cols-2 xl:grid-cols-4">
          <Count
            icon={<FcList className="h-full w-full text-th-warning" />}
            value={recommendations.length}
            label="Recommendations"
            className="cursor-pointer"
            onClick={() => setImpact(undefined)}
            active={impact === undefined}
          />
          <Count
            icon={<FcHighPriority className="h-full w-full" />}
            value={recommendations.filter((recommendation) => recommendation.properties.impact === 'High').length}
            label="High Impact"
            className="cursor-pointer"
            onClick={() => setImpact('High')}
            active={impact === 'High'}
          />
          <Count
            icon={<FcMediumPriority className="h-full w-full" />}
            value={recommendations.filter((recommendation) => recommendation.properties.impact === 'Medium').length}
            label="Medium Impact"
            className="cursor-pointer"
            onClick={() => setImpact('Medium')}
            active={impact === 'Medium'}
          />
          <Count
            icon={<FcLowPriority className="h-full w-full" />}
            value={recommendations.filter((recommendation) => recommendation.properties.impact === 'Low').length}
            label="Low Impact"
            className="cursor-pointer"
            onClick={() => setImpact('Low')}
            active={impact === 'Low'}
          />
        </div>
      </Section>
      <Section>
        <Banner>
          <Dropdown
            options={categories}
            defaultValue={categories[0]}
            variant={Dropdown.variant.DEFAULT}
            label="Category"
            multi={false}
            onChange={(option) => setCategory(option.value)}
          />
          <Dropdown
            options={subscriptions}
            defaultValue={subscriptions[0]}
            variant={Dropdown.variant.DEFAULT}
            label="Subscription"
            multi={false}
            onChange={(option) => setSubscription(option.value)}
          />
          <Spacer className="hidden sm:block" />
          {/* search */}
          {showSearch ? (
            <Fragment>
              <Input type="text" name="search" placeholder="Search" autoFocus onChange={(e) => filter(e.target.value)} />
              <IoCloseCircleOutline className="h-6 w-6 cursor-pointer" onClick={() => setShowSearch(false)} />
            </Fragment>
          ) : (
            <Iconly name="Search" set="light" className="cursor-pointer" onClick={() => setShowSearch(true)} />
          )}
          {/* csv download */}
          <CSVLink
            data={filtered.map((rec: Recommendation) => ({
              Category: rec.properties.category,
              Description: rec.properties.shortDescription.problem,
              Subscription: rec.subscriptionName,
              SubscriptionId: rec.subscriptionId,
              Resources: rec.resources,
              Impact: rec.properties.impact,
              Updated: rec.properties.lastUpdated
            }))}
            filename="recommendations.csv"
            target="_blank"
          >
            <Button onClick={() => null}>Download CSV</Button>
          </CSVLink>
        </Banner>
        <Spacer />
        <RecommendationTable recommendations={filtered} />
      </Section>
    </Fragment>
  )
}

const AzureAdvisorRecommendationsWithTracking = withAITracking(reactPlugin, Recommendations, 'Azure Advisor Recommendations')
export default AzureAdvisorRecommendationsWithTracking
