import { withAITracking } from '@microsoft/applicationinsights-react-js'

import Section from '~/components/Common/Section'
import ChangeDrawer from '~/components/Drawers/Change/ChangeDrawer'

import { reactPlugin } from '~/configs/appInsights'

import { ChangeScheduleCalendar } from './components/Calendar'
import { ChangeFiltersProvider } from './context/ChangeFilter/provider'

const ChangeSchedules = () => {
  return (
    <ChangeFiltersProvider>
      <Section>
        <ChangeScheduleCalendar />
      </Section>

      <ChangeDrawer />
    </ChangeFiltersProvider>
  )
}

const ChangeSchedulesWithTracking = withAITracking(reactPlugin, ChangeSchedules, 'ChangeSchedules')
export default ChangeSchedulesWithTracking
