import { useMemo } from 'react'

import useUserAccount from '../utils/useAccountRoles'
import useOwnUser from '../utils/useOwnUser'

const useDocumentAccess = () => {
  const user = useOwnUser()
  const { permissions, roles } = useUserAccount()

  const accessInfo = useMemo(() => {
    const hasAnyDocumentPermission = permissions.some((permission) => permission.startsWith('Document.'))
    const hasAnyDocumentRole = roles.some((role) => role.startsWith('Document.'))
    const hasGlobalAdminRole = roles.some((role) => role.includes('Global.Admin'))
    const hasSuperAdminRole = user?.super_admin
    const hasDocumentAdminRole = roles.some((role) => role.includes('Document.Admin'))
    const hasDocumentUserRole = roles.some((role) => role.includes('Document.User'))

    const hasDocumentsAccess = hasAnyDocumentPermission || hasAnyDocumentRole || hasGlobalAdminRole || hasSuperAdminRole
    const hasGlobalWriteAccess = hasGlobalAdminRole || hasSuperAdminRole || hasDocumentAdminRole
    const hasGlobalReadAccess = hasGlobalAdminRole || hasSuperAdminRole || hasAnyDocumentRole

    return {
      hasAnyDocumentRole,
      hasGlobalAdminRole,
      hasSuperAdminRole,
      hasDocumentAdminRole,
      hasDocumentsAccess,
      hasDocumentUserRole,
      hasGlobalWriteAccess,
      hasGlobalReadAccess
    }
  }, [permissions, roles, user?.super_admin])

  const hasResourceAccess = (resourceId: string, accessType: 'read' | 'write' = 'read') => {
    if (accessType === 'read') {
      if (accessInfo.hasGlobalReadAccess) return true
      return roles.some((role) => role.startsWith(`Document.Read.${resourceId}`))
    }

    if (accessType === 'write') {
      if (accessInfo.hasGlobalWriteAccess) return true
      return roles.some((role) => role.startsWith(`Document.Write.${resourceId}`))
    }

    return false
  }

  return {
    ...accessInfo,
    hasResourceAccess
  }
}

export default useDocumentAccess
