import { type Emission } from 'src/hooks/services/sustainability/queries/useEmissionsQuery'

import type { DateRange } from 'src/components/Common/MonthRangePicker'

const useEmissionTileData = (dateFilteredEmissionTotals: Emission[] | undefined, months: number, dateRange: DateRange, intensity: boolean) => {
  const scope1Total =
    dateFilteredEmissionTotals?.reduce((acc, curr) => acc + (curr.scope1 ? (curr.scope1 ?? 0) / (intensity ? (curr.usageUSD ?? 1) : 1) : 0), 0) ?? 0
  const scope2Total =
    dateFilteredEmissionTotals?.reduce((acc, curr) => acc + (curr.scope2 ? (curr.scope2 ?? 0) / (intensity ? (curr.usageUSD ?? 1) : 1) : 0), 0) ?? 0
  const scope3Total =
    dateFilteredEmissionTotals?.reduce((acc, curr) => acc + (curr.scope3 ? (curr.scope3 ?? 0) / (intensity ? (curr.usageUSD ?? 1) : 1) : 0), 0) ?? 0

  const emissionTotal = scope1Total + scope2Total + scope3Total

  return {
    dateFilteredEmissionTotals,
    scope1Total,
    scope2Total,
    scope3Total,
    emissionTotal
  }
}

export default useEmissionTileData
