import { type Price } from './microsoft365'

export interface Cart {
  Id?: string
  CreationTimestamp?: string
  LastModifiedTimestamp?: string
  ExpirationTimestamp?: string
  LastModifiedUser?: string
  Status?: string
  PartnerOnRecordAttestationAccepted?: boolean
  LineItems: CartLineItem[]
  TotalSpend?: number
}

export interface CartLineItem {
  Id?: number
  CatalogItemId: string
  FriendlyName?: string
  Quantity: number
  BillingCycle: BillingCycleType
  TermDuration: string
  PromotionId?: string
  RenewsTo?: {
    TermDuration: string
  } | null
  ProvisioningContext?: {
    subscriptionId: string
    scope: string
  }
  Error?: {
    ErrorCode?: string
    ErrorDescription?: string
  }
  Price?: Price
}

export enum BillingCycleType {
  Unknown,
  Monthly,
  Annual,
  None,
  OneTime,
  Triennial
}
