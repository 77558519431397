import { useQueryClient } from '@tanstack/react-query'

import { useMemo, useState } from 'react'

import api from 'src/constants/api'

import type { ScheduledExport } from 'src/hooks/services/microsoft/queries/useScheduledExports'
import { useTenantsQuery } from 'src/hooks/services/servicenow/queries/useTenantsQuery'
import useAccount from 'src/hooks/utils/useAccount'

import moment from 'moment'

import Button from '../Common/Button'
import PortalDrawer from '../Common/PortalDrawer'
import Dropdown from '../Common/RefactoredDropdown'
import Spinner from '../Common/Spinner'

type ScheduledExportDrawerProps = {
  scheduledExport: ScheduledExport
  onClose: () => void
}

const ScheduledExportDrawer: React.FC<ScheduledExportDrawerProps> = ({ onClose, scheduledExport }) => {
  const account = useAccount({ onChange: onClose })

  const [isDownloading, setIsDownloading] = useState(false)
  const handleExportDownload = async (ex: (typeof scheduledExport)['exports'][number]) => {
    setIsDownloading(true)

    try {
      // Accepts Binary Data
      const response = await api.post(
        `/ms-api/v2/account/${account.id}/azure_usage/fetchoutputfile`,
        {
          tenantIds: scheduledExport.tenantIds,
          fileName: ex.blobUri
        },
        {
          responseType: 'blob'
        }
      )

      // Parse Binary Data
      const blob = new Blob([response.data], { type: 'application/octet-stream' })
      const url = window.URL.createObjectURL(blob) // Create a URL to the blob

      // Create a link and click it
      const link = document.createElement('a')
      link.setAttribute('href', url)

      // the content-disposition header is used for the filename
      link.setAttribute('download', ex.blobUri)

      document.body.appendChild(link)

      link.click()

      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Error downloading file:', error)
    } finally {
      setIsDownloading(false)
    }
  }

  const [isCancelling, setIsCancelling] = useState(false)
  const { data: tenants } = useTenantsQuery({ active: true })
  const queryClient = useQueryClient()
  const handleExportCancel = async () => {
    setIsCancelling(true)

    await api.post(`/ms-api/v2/account/${account.id}/azure_usage/cancel_data_export_schedule`, {
      tenantIds: scheduledExport.tenantIds,
      scheduleId: scheduledExport.id
    })

    await queryClient.invalidateQueries(['scheduled-exports', account.id, tenants.map((t) => t.id)])
    onClose()
    setIsCancelling(false)
  }

  const subtitleText = useMemo(() => {
    if (!scheduledExport.recurring) return 'Once-Off'

    const frequency = scheduledExport.exportFrequency
    if (!frequency) return 'Recurring'

    if (frequency === 'daily') return 'Recurring Daily at ' + moment(scheduledExport.nextExportDate).format('HH:mm')
    if (frequency === 'weekly') return 'Recurring Weekly on ' + moment(scheduledExport.nextExportDate).format('dddd')
    if (frequency === 'monthly') return 'Recurring Monthly on the ' + moment(scheduledExport.nextExportDate).format('Do')

    return 'Recurring'
  }, [scheduledExport])

  const data = useMemo(() => {
    const subscriptionIds = scheduledExport.subscriptions.map((s) => s.subscriptionId)

    const mappedData = tenants
      .map((tenant) => ({
        label: tenant.name,
        value: tenant.id,
        expanded: true,
        checked: true,
        children: tenant.subscriptions
          .map((option) => ({
            label: option.name,
            value: option.id,
            checked: true
          }))
          .filter((sub) => subscriptionIds.includes(sub.value))
      }))
      .filter((tenant) => tenant.children?.length)

    return mappedData
  }, [scheduledExport.subscriptions, tenants])

  return (
    <>
      <PortalDrawer
        title={scheduledExport.name || 'Schedule Export'}
        subtitle={subtitleText}
        onClose={onClose}
        actions={
          <div className={`grid gap-4 ${scheduledExport.status === 'pending' ? 'grid-cols-2' : ''}`}>
            <Button onClick={() => onClose()} bordered disabled={isCancelling}>
              Close
            </Button>
            {scheduledExport.status === 'pending' && (
              <Button onClick={handleExportCancel} bordered variant="error" disabled={isCancelling}>
                {isCancelling ? <Spinner className="h-[14px] w-[14px] border-l-th-content" /> : 'Delete'}
              </Button>
            )}
          </div>
        }
      >
        {/* {scheduledExport.description && (
          <>
            <div className="text-sm">{scheduledExport.description}</div>
            <br />
          </>
        )} */}

        <Dropdown data={data} label="Selected Subscriptions" customTriggerIndicator={<></>} hideCheckbox />

        <br />

        <Dropdown
          data={scheduledExport.months.map((month) => ({ label: month.at(0)?.toUpperCase() + month.slice(1), value: month, checked: true }))}
          label="Selected Months"
          customTriggerIndicator={<></>}
          hideCheckbox
        />

        {scheduledExport.customerUrl && (
          <>
            <br />
            <div className="font-bold">Customer URL:</div>
            <div className="rounded-full border border-th-border px-4 py-2 text-sm">{scheduledExport.customerUrl || 'N/A'}</div>
          </>
        )}

        <br />

        <div className="mb-6 grid grid-cols-2 gap-4">
          <div className="rounded-md border border-th-border p-4">
            <p className="text-center text-th-text-secondary">Next Export Date</p>
            <p className="text-center text-lg font-bold capitalize text-th-text">
              {scheduledExport.recurring ? moment(scheduledExport.nextExportDate).format('YYYY-MM-DD HH:mm') : 'N/A'}
            </p>
          </div>

          <div className="rounded-md border border-th-border p-4">
            <p className="text-center text-th-text-secondary">Status</p>
            <p className="text-center text-lg font-bold uppercase text-th-text">{scheduledExport.status}</p>
          </div>
        </div>

        <hr className="my-8 border-th-border" />

        <div className="font-bold">Exports:</div>
        <div className="mt-0">
          <ul>
            {scheduledExport.exports
              .sort((a, b) => new Date(b.exportedDate).valueOf() - new Date(a.exportedDate).valueOf())
              .map((ex) => (
                <li key={ex.id} className="flex items-center border-b border-th-border py-2 text-sm last:border-b-0">
                  <div className="flex w-full items-center justify-between">
                    <span>{moment(ex.exportedDate).format('DD/MM/YYYY HH:mm A')}</span>
                    <span>
                      {ex.status.toLowerCase() !== 'pending' ? (
                        <button
                          className="flex w-20 items-center justify-center rounded-full bg-th-primary px-2 py-1 text-th-content disabled:pointer-events-none disabled:opacity-50"
                          disabled={isDownloading}
                          onClick={() => void handleExportDownload(ex)}
                        >
                          {isDownloading ? <Spinner className="h-4 w-4 border-l-th-content" /> : 'Download'}
                        </button>
                      ) : (
                        ex.status
                      )}
                    </span>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </PortalDrawer>
    </>
  )
}

export default ScheduledExportDrawer
