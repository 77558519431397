import React, { useEffect, useState } from 'react'

import { classNames } from 'src/utils/classNames'

import { PiCaretLeft, PiCaretRight } from 'react-icons/pi'

import Button from './Button'
import { Popover, PopoverContent, PopoverTrigger } from './PopoverDialog'

const MONTHS: readonly string[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
] as const

export interface DateRange {
  startDate: Date
  endDate: Date
}

interface MonthRangePickerProps {
  onChange: (range: DateRange) => void
  initialStartDate?: Date
  initialEndDate?: Date

  cutOffDate?: Date
}

const MonthRangePicker: React.FC<MonthRangePickerProps> = ({ onChange, initialStartDate = new Date(), initialEndDate = new Date(), cutOffDate }) => {
  const [startDate, setStartDate] = useState<Date>(initialStartDate)
  const [endDate, setEndDate] = useState<Date>(initialEndDate)
  const [activeDate, setActiveDate] = useState<Date>(new Date(Math.min(startDate.getTime(), endDate.getTime())))
  const [isSelectingEnd, setIsSelectingEnd] = useState<boolean>(false)

  const handleMonthClick = (month: number): void => {
    const newDate = new Date(activeDate.getFullYear(), month)

    setActiveDate(newDate)

    if (!isSelectingEnd) {
      setStartDate(newDate)
      setEndDate(newDate)
      setIsSelectingEnd(true)
    } else {
      if (newDate < startDate) {
        setEndDate(startDate)
        setStartDate(newDate)
      } else {
        setEndDate(newDate)
      }
      setIsSelectingEnd(false)
    }
  }

  useEffect(() => {
    onChange({
      startDate,
      endDate
    })
  }, [startDate, endDate, onChange])

  const changeYear = (delta: number): void => {
    setActiveDate(new Date(activeDate.getFullYear() + delta, activeDate.getMonth()))
  }

  const formatDateRange = (start: Date, end: Date): string => {
    return `${MONTHS[start.getMonth()]} ${start.getFullYear()} - ${MONTHS[end.getMonth()]} ${end.getFullYear()}`
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button bordered>{formatDateRange(startDate, endDate)}</Button>
      </PopoverTrigger>
      <PopoverContent align="start">
        <div className="grid grid-cols-3">
          <button type="button" onClick={() => changeYear(-1)} className="grow rounded-md border border-th-border duration-75 hover:bg-th-content-secondary">
            <PiCaretLeft className="mx-auto h-6" />
          </button>
          <span className="text-center">{activeDate.getFullYear()}</span>
          <button type="button" onClick={() => changeYear(1)} className="grow rounded-md border border-th-border duration-75 hover:bg-th-content-secondary">
            <PiCaretRight className="mx-auto h-6" />
          </button>
        </div>
        <div className="mt-2 grid grid-cols-3 gap-1">
          {MONTHS.map((month, index) => {
            const currentDate = new Date(activeDate.getFullYear(), index)

            const isSelected = currentDate >= startDate && currentDate <= endDate
            const isDisabled = cutOffDate && currentDate > cutOffDate

            return (
              <button
                type="button"
                key={month}
                disabled={isDisabled}
                onClick={() => handleMonthClick(index)}
                className={classNames(
                  'rounded-md border border-th-border p-1 duration-75 hover:bg-th-content-secondary',
                  isSelected && 'bg-th-primary text-th-white hover:bg-th-primary',
                  isDisabled && 'opacity-50'
                )}
              >
                {month.slice(0, 3)}
              </button>
            )
          })}
        </div>
      </PopoverContent>
    </Popover>
  )
}

export default MonthRangePicker
