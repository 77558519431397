import { useQueries, useQueryClient } from '@tanstack/react-query'

import { useCallback, useMemo, useState } from 'react'

import api from 'src/constants/api'

import { useTenantsQuery } from 'src/hooks/services/servicenow/queries/useTenantsQuery'
import useAccount from 'src/hooks/utils/useAccount'

import Dropdown from 'src/components/Common/RefactoredDropdown'
import TagSelectModal from 'src/components/Modals/TagSelectModal'

import { useColors } from 'src/utils/colors'
import { format } from 'src/utils/format'

import { type Moment } from 'moment'
import { PiArrowUp, PiCaretDownBold, PiCaretUpBold } from 'react-icons/pi'

import TreeSVG, { type TreeSVGProps } from './TreeSVG'

type DataItem = {
  tag?: string | null
  meterCategory?: string | null
  resourceGroup?: string | null
  resourceLocation?: string | null
  resourceName?: string | null
  subscriptionId?: string | null
  tenantId?: string | null
  totalSpendGBP: string
}

type DecompositionGraphProps = {
  dateRange: [Moment, Moment]
} & Omit<TreeSVGProps, 'nodes'>

const categoryMapper = {
  Subscription: 'subscriptionId',
  Category: 'meterCategory',
  'Resource Group': 'resourceGroup',
  Region: 'resourceLocation',
  'Resource Name': 'resourceName',
  Tag: 'tag'
}

const categories = ['Subscription', 'Category', 'Resource Group', 'Region', 'Tag', 'Resource Name']

const DecompositionGraph: React.FC<DecompositionGraphProps> = ({ colorful, props, dateRange }) => {
  const colors = useColors(colorful)
  const account = useAccount()

  const [_categories, _setCategories] = useState(['Subscription', 'Category', 'Resource Group', 'Region', 'Resource Name'])
  const [categoryConfig, setCategoryConfig] = useState<Record<string, { selected: string; pageIndex: number }>>({}) // category -> config

  const handleNodeClick = useCallback(
    (categoryIndex: number, name: string) => {
      const category = _categories.at(categoryIndex)
      const alteredCategories = _categories.slice(categoryIndex)

      const previousSelected = alteredCategories.reduce(
        (acc, category) => ({ ...acc, [category]: { ...categoryConfig[category], selected: '', pageIndex: 0 } }),
        categoryConfig
      )

      setCategoryConfig({
        ...previousSelected,
        [category as string]: { selected: name, pageIndex: categoryConfig[category as keyof typeof categoryConfig]?.pageIndex ?? 0 }
      })
    },
    [_categories, categoryConfig]
  )

  const handlePageChange = (category: string, pageIndex: number) => {
    if (pageIndex < 0) return
    setCategoryConfig({ ...categoryConfig, [category]: { ...(categoryConfig[category] || {}), pageIndex } })
  }

  const { data: tenants } = useTenantsQuery({ active: true })
  const names = tenants.flatMap((tenant) => [{ id: tenant.id, name: tenant.name }, ...tenant.subscriptions.map((sub) => ({ id: sub.id, name: sub.name }))])

  const queryClient = useQueryClient()

  const results = useQueries({
    queries: _categories.map((category, categoryIndex) => {
      const categoryRequestInput = {
        tenantIds: tenants.map((tenant) => tenant.id),
        subscriptionIds: tenants.flatMap((tenant) => tenant.subscriptions.map((sub) => sub.id)),
        startDate: dateRange[0].format('YYYY-MM-DD'),
        endDate: dateRange[1].format('YYYY-MM-DD'),
        groupBy: category.includes('tag_') ? category : categoryMapper[category as keyof typeof categoryMapper],
        filter: _categories.slice(0, categoryIndex).reduce(
          (acc, c) => {
            const id = c.includes('tag_') ? c : categoryMapper[c as keyof typeof categoryMapper]
            if (categoryConfig[c]?.selected) acc[id] = categoryConfig[c]?.selected
            return acc
          },
          {} as Record<string, string>
        )
      }

      return {
        queryKey: ['decomp_data', categoryRequestInput],
        queryFn: async () => {
          // const prefetchQueryState = queryClient.getQueryState<Array<DataItem>>(['decomp_data', categoryRequestInput])

          try {
            const response = await api.post(`/ms-api/v2/account/${account.id}/azure_usage/decomp_data`, categoryRequestInput)
            return response.data.resultRows as DataItem[]
          } catch (e) {
            console.error('Error fetching decomposition graph data:', e)
            return [] as DataItem[]
          }
        },
        enabled:
          !!account.id &&
          !!tenants &&
          !!dateRange &&
          (!!categoryConfig[_categories[categoryIndex - 1]]?.selected ||
            categoryIndex === 0 ||
            (_categories[categoryIndex - 1] === 'Tag' && !!categoryConfig['tag_' + (categoryIndex - 1)]?.selected)),
        cacheTime: 1000 * 60 * 5,
        refetchOnWindowFocus: false
      }
    })
  })

  const nodes = useMemo(() => {
    const _nodes = _categories.map((category, categoryIndex) => {
      if (category === 'none' || _categories.slice(0, categoryIndex).some((c) => c === 'none')) {
        const isPreviousCategorySelected = _categories[categoryIndex - 1] && categoryConfig[_categories[categoryIndex - 1]]?.selected
        if (!isPreviousCategorySelected) return []

        return [
          {
            content: (
              <div className={`line-clamp-2 h-24 w-40 overflow-ellipsis text-center text-sm`}>
                <PiArrowUp className="mx-auto mb-2" />
                Select a category before continuing
              </div>
            )
          }
        ]
      }

      const isPreviousCategorySelected = _categories[categoryIndex - 1] && categoryConfig[_categories[categoryIndex - 1]]?.selected
      if (!isPreviousCategorySelected && categoryIndex !== 0) return []

      const categoryData = results[categoryIndex]?.data
      if (!categoryData)
        return [
          {
            content: <div className="spinner mx-auto h-12 w-12" />,
            selected: false,
            total: 0,
            invisible: true
          }
        ]

      const categoryNodes = categoryData.map((item) => {
        const name =
          names.find((n) => n.id === item.subscriptionId)?.name ??
          item.meterCategory ??
          item.resourceGroup ??
          item.resourceLocation ??
          item.resourceName ??
          item.tag?.split(' : ').at(1) ??
          'Unknown'

        return {
          name,
          id:
            item.subscriptionId ??
            item.meterCategory ??
            item.resourceGroup ??
            item.resourceLocation ??
            item.resourceName ??
            item.tag?.split(' : ').at(1) ??
            'Unknown',
          total: parseFloat(item.totalSpendGBP)
        }
      })

      if (!categoryNodes.length) return []

      const sortedByTotal = categoryNodes.sort((a, b) => b.total - a.total)

      const countDuplicates = new Map<string, number>()
      for (const node of sortedByTotal) {
        countDuplicates.set(node.name, (countDuplicates.get(node.name) ?? 0) + 1)
        if (countDuplicates.get(node.name) === 1) continue

        const count = countDuplicates.get(node.name)!
        const index = sortedByTotal.findIndex((n) => n.id === node.id)
        sortedByTotal[index] = {
          name: `${node.name} (${count - 1})`,
          total: node.total,
          id: node.id
        }
      }

      const max = sortedByTotal[0].total
      const pageIndex = categoryConfig[category]?.pageIndex ?? 0

      const selectedNode = sortedByTotal.find((node) => node.id === categoryConfig[category]?.selected)

      const initialPage = sortedByTotal.slice(Math.max(pageIndex * 5, 0), (pageIndex + 1) * 5)
      const pageHasSelectedNode = initialPage.some((node) => node.name === selectedNode?.name)

      const pageRest = sortedByTotal.filter((s) => s.name !== selectedNode?.name).slice(Math.max(pageIndex * 5 - 1, 0), (pageIndex + 1) * 5 - 1)
      const alteredPage = !selectedNode || pageHasSelectedNode ? initialPage : [selectedNode ?? pageRest[0], ...pageRest.slice(0, 4)]

      const pageData = alteredPage

      for (const node of pageData) {
        // We are in the last level of the tree
        const nextCategory = _categories[categoryIndex + 1]
        if (nextCategory === 'none' || !nextCategory) continue

        const groupBy = nextCategory.includes('tag_') ? nextCategory : categoryMapper[nextCategory as keyof typeof categoryMapper]
        const id = category.includes('tag_') ? category : categoryMapper[category as keyof typeof categoryMapper]

        const filter = _categories.slice(0, categoryIndex).reduce(
          (acc, c) => {
            const id = c.includes('tag_') ? c : categoryMapper[c as keyof typeof categoryMapper]
            if (categoryConfig[c]?.selected) acc[id] = categoryConfig[c]?.selected
            return acc
          },
          {} as Record<string, string>
        )
        filter[id] = node.id ?? node.name

        const categoryRequestInput = {
          tenantIds: tenants.map((tenant) => tenant.id),
          subscriptionIds: tenants.flatMap((tenant) => tenant.subscriptions.map((sub) => sub.id)),
          startDate: dateRange[0].format('YYYY-MM-DD'),
          endDate: dateRange[1].format('YYYY-MM-DD'),
          groupBy: groupBy,
          filter: filter
        }

        const prefetchQueryState = queryClient.getQueryState<Array<DataItem>>(['decomp_data', categoryRequestInput])
        if (!prefetchQueryState?.data && prefetchQueryState?.fetchStatus !== 'fetching') {
          void queryClient.prefetchQuery(['decomp_data', categoryRequestInput], {
            queryFn: async () => {
              try {
                const response = await api.post(`/ms-api/v2/account/${account.id}/azure_usage/decomp_data`, categoryRequestInput)
                return response.data.resultRows as DataItem[]
              } catch (e) {
                console.error('Error fetching decomposition graph data:', e)
                return [] as DataItem[]
              }
            }
          })
        }
      }

      return pageData.map((node, nodeIndex) => ({
        content: (
          <div
            className={`h-24 w-40 ${categoryIndex === 4 ? '' : 'cursor-pointer'}`}
            onClick={() => (categoryIndex === 4 ? null : handleNodeClick(categoryIndex, node.id ?? node.name))}
          >
            <div className="relative h-6 w-full overflow-hidden rounded-full border border-th-border bg-th-content-secondary">
              <div className="absolute inset-0" style={{ width: `${(node.total / max) * 100}%`, backgroundColor: colors[colors.length - nodeIndex - 1] }} />
            </div>
            <div className="line-clamp-2 overflow-ellipsis font-headline text-sm">{node.name}</div>
            <div className="font-headline text-sm">{format(node.total, 'currencyFixed')}</div>
          </div>
        ),
        selected: categoryConfig[category]?.selected === (node.id ?? node.name),
        total: node.total
      }))
    })

    return [
      [
        {
          content: (
            <div className="h-24 w-40 cursor-pointer">
              <div className="relative h-6 w-full overflow-hidden rounded-full border border-th-border bg-th-content-secondary">
                <div className="absolute inset-0" style={{ width: '100%', backgroundColor: colors[colors.length - 1] }} />
              </div>
              <div className="font-headline text-sm">All</div>
              <div className="font-headline text-sm">
                {format(results.at(0)?.data?.reduce((total, item) => total + parseFloat(item.totalSpendGBP), 0) || 0, 'currencyFixed')}
              </div>
            </div>
          ),
          selected: true
        }
      ],
      ..._nodes
    ]
  }, [_categories, colors, results, categoryConfig, names, tenants, dateRange, queryClient, account.id, handleNodeClick])

  const [selectingTagIndex, setSelectingTagIndex] = useState<number | null>(null)
  const [previousCategoryStateForTag, setPreviousCategoryStateForTag] = useState<typeof _categories | null>(null)

  return (
    <div className="relative h-[700px] w-full overflow-x-auto" style={{ transform: 'rotateX(180deg)' }}>
      <div className="absolute inset-0 pt-2" style={{ transform: 'rotateX(180deg)' }}>
        <div className="h-[665px] w-[1440px]">
          <div className="mb-8 flex gap-24">
            <div className="w-40" />
            {_categories.map((category, categoryIndex) => {
              const isFixed = categoryIndex === 0 || categoryIndex === categories.length - 2

              const _categoryOptions = categories.slice(1, 5).map((c) => ({
                label: c === 'Tag' ? category.split('_').at(1) || 'Tag' : c,
                value: c,
                checked: c === 'Tag' ? category.includes('tag_') : category === c,
                disabled: _categories
                  .slice(0, categoryIndex)
                  .some((cat) => cat === c || ((c === 'Tag' || c.includes('tag_')) && (cat === 'Tag' || cat.includes('tag_'))))
              }))

              const hasSelected = _categoryOptions.some((c) => c.checked)
              const categoryOptions = _categoryOptions.concat({ label: 'None', value: 'none', checked: !hasSelected, disabled: false })

              return (
                <>
                  {isFixed ? (
                    <div className="flex w-40 items-center justify-center rounded-full border border-th-border bg-th-content-secondary px-4 font-headline text-sm">
                      {category}
                    </div>
                  ) : (
                    <Dropdown
                      className="w-40"
                      key={category}
                      data={categoryOptions}
                      mode="simpleSelect"
                      onChange={(_, selectedNodes) => {
                        if (selectedNodes[0].value === 'Tag') {
                          setPreviousCategoryStateForTag(_categories)
                          setSelectingTagIndex(categoryIndex)
                        }

                        _setCategories((prev) => {
                          const newCategories = prev.map((c, i) => {
                            const isFixed = i === 0 || i === categories.length - 2
                            if (i === categoryIndex) return selectedNodes[0].value
                            if (i > categoryIndex && !isFixed) return 'none'
                            return c
                          })
                          return newCategories
                        })
                      }}
                    />
                  )}
                </>
              )
            })}
          </div>
          <div className="mb-8 flex gap-24">
            <div className="w-40" />
            {_categories.map((category, index) => (
              <button
                key={index}
                className="flex h-6 w-40 items-center justify-center rounded-full border border-th-border text-center font-headline text-sm duration-75 enabled:hover:border-th-gray disabled:pointer-events-none disabled:opacity-50"
                onClick={() => handlePageChange(category, (categoryConfig[category]?.pageIndex ?? 0) - 1)}
                disabled={(categoryConfig[category]?.pageIndex ?? 0) === 0}
              >
                <PiCaretUpBold className="inline-block" />
              </button>
            ))}
          </div>
          <TreeSVG nodes={nodes} colorful={colorful} props={props} />
          <div className="mt-8 flex gap-24">
            <div className="w-40" />
            {_categories.map((category, index) => (
              <button
                key={index}
                className="flex h-6 w-40 items-center justify-center rounded-full border border-th-border text-center font-headline text-sm duration-75 enabled:hover:border-th-gray disabled:pointer-events-none disabled:opacity-50"
                onClick={() => handlePageChange(category, (categoryConfig[category]?.pageIndex ?? 0) + 1)}
                disabled={Math.floor((results[index]?.data?.length ?? 0) / 5 - (categoryConfig[category]?.pageIndex ?? 0)) === 0}
              >
                <PiCaretDownBold className="inline-block" />
              </button>
            ))}
          </div>
        </div>
      </div>
      <TagSelectModal
        dateRange={dateRange}
        tenants={tenants}
        open={selectingTagIndex !== null}
        onTagChange={(tag) => {
          if (!tag) {
            setSelectingTagIndex(null)
            _setCategories(previousCategoryStateForTag ?? _categories)
            return setPreviousCategoryStateForTag(null)
          }

          _setCategories((prev) => {
            return prev.map((c, i) => {
              if (i === selectingTagIndex) return `tag_${tag}`
              return c
            })
          })

          setCategoryConfig({ ...categoryConfig, [`tag_${tag}`]: { selected: '', pageIndex: 0 } })
          setSelectingTagIndex(null)
        }}
      />
    </div>
  )
}

export default DecompositionGraph
