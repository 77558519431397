import { Fragment, type FunctionComponent } from 'react'

import { type AZServiceCatalogEntry } from '../../types/azure'

import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { closeDrawers } from '../../actions/drawerActions'
import Button from '../Common/Button'
import AzureAutomationAccountDeploymentForm from '../Common/DeploymentForms/AzureAutomationAccountDeplyomentForm'
import AzureContainerRegistryDeploymentForm from '../Common/DeploymentForms/AzureContainerRegistryDeploymentForm'
import AzureFunctionAppDeploymentForm from '../Common/DeploymentForms/AzureFunctionAppDeplyomentForm'
import AzureKeyVaultDeploymentForm from '../Common/DeploymentForms/AzureKeyVaultDeploymentForm'
import AzurePrivateDNSZoneDeploymentForm from '../Common/DeploymentForms/AzurePrivateDNSZoneDeplyomentForm'
import AzureResourceGroupDeploymentForm from '../Common/DeploymentForms/AzureResourceGroupDeplyomentForm'
import AzureStorageAccountDeploymentForm from '../Common/DeploymentForms/AzureStorageAccountDeplyomentForm'
import AzureSubscriptionDeploymentForm from '../Common/DeploymentForms/AzureSubscriptionDeplyomentForm'
import AzureUserAssignedIdentityDeploymentForm from '../Common/DeploymentForms/AzureUserAssignedIdentityDeplyomentForm'
import Drawer from '../Common/Drawer'
import Popover from '../Common/Popover'
import Submit from '../Common/Submit'

const NewAzureDeployment: FunctionComponent = (): JSX.Element => {
  const dispatch = useDispatch()
  const deployment: AZServiceCatalogEntry = useSelector((state: RootStateOrAny) => state.drawers.new_azure_deployment)
  /* drawer actions */
  const Actions: FunctionComponent = (): JSX.Element => {
    return (
      <Fragment>
        <div className="grid w-full">
          <div className="grid-row mb-4 w-full text-xs">
            <Popover
              text={
                <div className="mx-4 p-2">
                  By clicking “Submit” I
                  <ol style={{ listStyleType: 'upper-alpha' }}>
                    <li>agree to the applicable legal terms associated with the offering</li>
                    <li>
                      authorize Cloud Direct to charge or bill my current payment method for the fees associated the offering(s), including applicable taxes,
                      with the same billing frequency as my Azure subscription, until I discontinue use of the offering(s); Deploying this template will create
                      one or more Azure resources. You acknowledge that you are responsible for reviewing the applicable pricing and legal terms associated with
                      all resources and offerings deployed as part of this deployment."
                    </li>
                  </ol>
                </div>
              }
            >
              <div className="text-right">
                By clicking “Submit” I agree to the{' '}
                <a
                  href="https://www.clouddirect.net/legal/general-terms-and-conditions/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-th-secondary underline"
                >
                  terms
                </a>
                .
              </div>
            </Popover>
          </div>

          <div className="grid-row w-full">
            <div className="flex justify-between">
              <Button bordered onClick={() => dispatch(closeDrawers())}>
                Cancel
              </Button>
              <Submit variant="primary" form="newAzureDeploymentForm" />
            </div>
          </div>
        </div>
      </Fragment>
    )
  }

  return (
    <Drawer title={'Deploying new ' + deployment.resource_type} loading={false} actions={<Actions />}>
      {/* core form + simpleTenantPicker */}
      {deployment.pipeline_short_name === 'CreateSubscription' && <AzureSubscriptionDeploymentForm deployment={deployment} />}
      {/* core form + tenantSubPicker */}
      {deployment.pipeline_short_name === 'CreateResourceGroup' && <AzureResourceGroupDeploymentForm deployment={deployment} />}
      {/* core form + tenantSubPicker + resourceGroupPicker */}
      {deployment.pipeline_short_name === 'CreatePrivateDNSZone' && <AzurePrivateDNSZoneDeploymentForm deployment={deployment} />}
      {deployment.pipeline_short_name === 'CreateAutomationAccount' && <AzureAutomationAccountDeploymentForm deployment={deployment} />}
      {deployment.pipeline_short_name === 'CreateUserAssignedIdentity' && <AzureUserAssignedIdentityDeploymentForm deployment={deployment} />}
      {deployment.pipeline_short_name === 'CreateKeyVault' && <AzureKeyVaultDeploymentForm deployment={deployment} />}
      {/* core form + tenantSubPicker + resourceGroupPicker + sku */}
      {deployment.pipeline_short_name === 'CreateStorageAccount' && <AzureStorageAccountDeploymentForm deployment={deployment} />}
      {/* core form + tenantSubPicker + resourceGroupPicker + sku + adminuser */}
      {deployment.pipeline_short_name === 'CreateContainerRegistry' && <AzureContainerRegistryDeploymentForm deployment={deployment} />}
      {/* core form + tenantSubPicker + resourceGroupPicker + storageAccountPicker + sku */}
      {deployment.pipeline_short_name === 'CreateFunctionApp' && <AzureFunctionAppDeploymentForm deployment={deployment} />}
    </Drawer>
  )
}

export default NewAzureDeployment
