import { Fragment, type FunctionComponent, useEffect, useState } from 'react'

import { type Sku as SkuType, type Subscription } from '../../../../types/microsoft365'

import { Iconly } from 'react-iconly'
import { IoCheckmarkCircle, IoCloseCircle, IoExtensionPuzzleOutline, IoTimeOutline } from 'react-icons/io5'

import Popover from '../../../../components/Common/Popover'
import Spacer from '../../../../components/Common/Spacer'
import Tile from '../../../../components/Common/Tile'

interface Props {
  sku: SkuType
  subscriptions: Subscription[]
  onClick: () => void
}

const CheckingPrerequisites: FunctionComponent<{
  hasPrereqs: boolean | undefined
}> = ({ hasPrereqs }): JSX.Element => {
  if (hasPrereqs === undefined) return <div>Checking prerequisites...</div>

  if (hasPrereqs)
    return (
      <div className="flex items-center gap-2">
        <div className="h-5 w-5">
          <IoCheckmarkCircle className="h-5 w-5 text-th-success" />
        </div>
        You meet the prequisites for this product.
      </div>
    )

  return (
    <div className="flex items-center gap-2">
      <div className="h-5 w-5">
        <IoCloseCircle className="h-5 w-5 text-th-error" />
      </div>
      You do not meet the prequisites for this product.
    </div>
  )
}

const Sku: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { sku, subscriptions } = props

  const [hasPrereqs, setHasPrereqs] = useState<boolean>()

  /* cross check each skus prerequisites with the subscriptions product ids */
  useEffect(() => {
    if (sku) {
      const prerequisites = sku.dynamicAttributes.prerequisiteSkus

      if (prerequisites.length > 0) {
        subscriptions.forEach((subscription: Subscription) => {
          if (subscription.Status === 'active' && subscription.OfferId.indexOf(':') > -1) {
            const offerId = subscription.OfferId.split(':')
            const id = offerId[0] + ':' + offerId[1]

            const hasPrereq = prerequisites.find((prerequisite: string) => prerequisite === id) !== undefined

            setHasPrereqs(hasPrereq)
          }
        })
      } else {
        setHasPrereqs(true)
      }
    }
  }, [sku, subscriptions])

  return (
    <Fragment>
      <Tile className="cursor-pointer text-th-text hover:shadow" onClick={props.onClick}>
        {/* header */}
        <div className="flex flex-col gap-1">
          <div className="flex justify-between gap-4">
            <div className="flex flex-col">
              {/* title */}
              <div className="text-lg font-bold">{sku.title}</div>
              {/* id */}
              <div className="text-sm text-th-text-secondary">{sku.id}</div>
            </div>
            <div className="flex gap-1">
              {/* add-on */}
              {sku.dynamicAttributes.isAddon && (
                <Popover text="Add-on">
                  <div className="flex h-8 w-8 items-center justify-center rounded-full bg-th-content-secondary">
                    <IoExtensionPuzzleOutline className="h-5 w-5" />
                  </div>
                </Popover>
              )}
              {/* trial */}
              {sku.isTrial && (
                <Popover text="Trial">
                  <div className="flex h-8 w-8 items-center justify-center rounded-full bg-th-content-secondary">
                    <IoTimeOutline className="h-5 w-5" />
                  </div>
                </Popover>
              )}
              {/* view */}
              <div className="flex h-8 w-8 items-center justify-center rounded-full bg-th-content-secondary">
                <Iconly name="ChevronRight" set="light" className="h-5 w-5" />
              </div>
            </div>
          </div>
        </div>
        <Spacer />
        {/* description */}
        <div>{sku.description}</div>
        <Spacer />
        {/* prerequisites */}
        <CheckingPrerequisites hasPrereqs={hasPrereqs} />
        <Spacer />
        {/* billing type */}
        <div className="w-max rounded-full bg-th-info-light px-3 font-bold text-th-primary">{sku.dynamicAttributes.billingType}</div>
      </Tile>
    </Fragment>
  )
}

export default Sku
