import { withAITracking } from '@microsoft/applicationinsights-react-js'

import React, { Fragment, useEffect, useState } from 'react'

import { type Entitlement } from 'src/types/azure'

import { showAlert } from 'src/actions/alertsActions'
import { stopLoading } from 'src/actions/loadingActions'

import { useTenantsQuery } from 'src/hooks/services/servicenow/queries/useTenantsQuery'
import useEmissionsQuery, { type Emission } from 'src/hooks/services/sustainability/queries/useEmissionsQuery'

import Button from 'src/components/Common/Button'
import Heading from 'src/components/Common/Heading'
import MonthRangePicker, { type DateRange } from 'src/components/Common/MonthRangePicker'
import Section from 'src/components/Common/Section'
import Spacer from 'src/components/Common/Spacer'
import SubscriptionDropdown from 'src/components/Dropdowns/SubscriptionDropdown'
import ColumnDescriptionsTable from 'src/components/Tables/ColumnDescriptionsTable'

import { reactPlugin } from 'src/configs/appInsights'

import moment from 'moment'
import { useDispatch } from 'react-redux'

const TABLE_DATA = [
  ['date', 'The date of the emission data'],
  ['tenantId', 'The unique identifier for the tenant'],
  ['tenantName', 'The name of the tenant'],
  ['subscriptionId', 'The unique identifier for the Azure subscription'],
  ['subscriptionName', 'The name of the Azure subscription'],
  ['azureServiceName', 'The Azure service category'],
  ['azureRegionName', 'The Azure region where the service is deployed'],
  ['subService', 'The specific Azure service'],
  ['scope1', 'The Scope 1 emissions in kilograms of CO2 equivalent'],
  ['scope2', 'The Scope 2 emissions in kilograms of CO2 equivalent'],
  ['scope3', 'The Scope 3 emissions in kilograms of CO2 equivalent'],
  ['usage', 'The usage cost in USD']
]

const SustainabilityExport: React.FC = () => {
  const dispatch = useDispatch()
  useEffect(() => void dispatch(stopLoading()), [dispatch])

  const { data: tenants } = useTenantsQuery({ active: true })
  const [selectedSubscriptions, setSelectedSubscriptions] = useState<Entitlement[]>([])

  const cutoffDate = new Date()
  cutoffDate.setHours(0, 0, 0, 0)
  cutoffDate.setDate(1)
  cutoffDate.setMonth(cutoffDate.getMonth() - 2)

  const startDate = new Date(cutoffDate)
  startDate.setFullYear(startDate.getFullYear() - 3)

  const [dateRange, setDateRange] = useState<DateRange>({ startDate, endDate: cutoffDate })

  const { refetch: fetchEmissions, isFetching } = useEmissionsQuery(
    {
      subscriptionIds: selectedSubscriptions.map((s) => s.id),
      months: Math.ceil((cutoffDate.getTime() - dateRange.startDate.getTime()) / (1000 * 60 * 60 * 24 * 30)) + 3
    },
    { enabled: false }
  )

  const handleExport = async () => {
    try {
      const { data: emissions } = await fetchEmissions()

      const timezoneAdjustedEmissionTotals = emissions?.map((e) => {
        const date = new Date(e.date ?? '')
        date.setHours(date.getHours() + date.getTimezoneOffset() / 60)
        return { ...e, parsedDate: date }
      })

      const dateFilteredEmissionTotals = timezoneAdjustedEmissionTotals?.filter((e) => {
        const parsedDate = new Date(e.parsedDate ?? '')
        return parsedDate >= dateRange.startDate && parsedDate <= dateRange.endDate
      })

      if (dateFilteredEmissionTotals) {
        const csvContent = convertToCSV(dateFilteredEmissionTotals)
        downloadCSV(csvContent, `sustainability_export_${moment().format('YYYY-MM-DD')}.csv`)
      }
    } catch (error) {
      console.error('Error fetching emissions data:', error)
      dispatch(showAlert({ type: 'error', message: 'Error fetching emissions data', component: 'SustainabilityExport' }))
    }
  }

  const convertToCSV = (data: Emission[]) => {
    const filered = data.filter((row) => row.scope1 || row.scope2 || row.scope3)

    const extended = filered.map((row) => {
      const tenant = tenants?.find((t) => t.subscriptions.some((s) => s.id === row.subscriptionId))
      const subscription = tenant?.subscriptions.find((s) => s.id === row.subscriptionId)

      return {
        date: row.date,
        tenantId: tenant?.id,
        tenantName: tenant?.name,
        subscriptionId: row.subscriptionId,
        subscriptionName: subscription?.name,
        azureServiceName: row.azureServiceName,
        azureRegionName: row.azureRegionName,
        subService: row.subService,
        scope1: row.scope1,
        scope2: row.scope2,
        scope3: row.scope3,
        usage: row.usageUSD
      }
    })

    const headers = Object.keys(extended[0])
    const rows = extended.map((row) =>
      headers
        .map((header) => {
          const value = row[header as keyof typeof row] ?? ''
          return typeof value === 'string' && value.includes(',') ? `"${value}"` : value
        })
        .join(',')
    )
    return [headers, ...rows].join('\n')
  }

  const downloadCSV = (content: string, fileName: string) => {
    const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', fileName)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  return (
    <Fragment>
      <Section className="!pb-10">
        <Heading text="Data Export">
          <Spacer className="hidden sm:block" />
          <MonthRangePicker initialEndDate={dateRange.endDate} initialStartDate={dateRange.startDate} onChange={setDateRange} cutOffDate={cutoffDate} />
          <SubscriptionDropdown onChange={(_, subscriptions) => setSelectedSubscriptions(subscriptions)} />
          <Button
            className=""
            onClick={handleExport}
            bordered
            variant="primary"
            disabled={isFetching || selectedSubscriptions.length === 0}
            isLoading={isFetching}
          >
            {isFetching ? 'Exporting...' : 'Export'}
          </Button>
        </Heading>
      </Section>
      <Section>
        <Heading text="Column Descriptions" />
        <ColumnDescriptionsTable columnDescriptions={TABLE_DATA.map(([name, value]) => ({ name, value }))} />
      </Section>
    </Fragment>
  )
}

const SustainabilityExportWithTracking = withAITracking(reactPlugin, SustainabilityExport, 'Sustainability Data Export')
export default SustainabilityExportWithTracking
