import { useQuery } from '@tanstack/react-query'

import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react'

import { type Tenant } from 'src/types/azure'

import { showAlert } from 'src/actions/alertsActions'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'

import axios from 'axios'
import { useDispatch } from 'react-redux'
import { type OptionTypeBase } from 'react-select'

import NestedDropdown from '../Common/RefactoredDropdown'

type Tag = {
  tag: string
  tagId: number
}

type TagsDropdownProps = {
  dateRange: [moment.Moment, moment.Moment]
  tenants: Array<Tenant>

  onTagsChange: (selectedTags: Tag[]) => void
  value?: Tag[]
}

const TagsDropdown = forwardRef(({ dateRange, onTagsChange, tenants, value }: TagsDropdownProps, ref) => {
  const account = useAccount()
  const dispatch = useDispatch()

  const formattedDateRange = dateRange.map((date) => date.format('YYYY-MM-DD'))
  const tenantIds = tenants.map((tenant) => tenant.id)

  const { data: tags } = useQuery({
    queryKey: ['tenant-tags', account.id, formattedDateRange[0], formattedDateRange[1], tenantIds],
    queryFn: async ({ signal }) => {
      try {
        const response = await api.post(
          `/ms-api/v2/account/${account.id}/azure_usage/get_tenants_tags_for_date_range`,
          {
            tenantIds: tenantIds,
            startDate: formattedDateRange[0],
            endDate: formattedDateRange[1]
          },
          { signal }
        )
        return response.data as Tag[]
      } catch (error) {
        if (!axios.isCancel(error)) {
          dispatch(
            showAlert({
              type: 'error',
              message: 'We were unable to retrieve your tags.',
              component: 'AzureOverview',
              error: error as Error
            })
          )
        }
        return null
      }
    },
    enabled: !!account.id && !!tenantIds.length
  })

  const nestedDropdownRef = useRef<{ clear: () => void }>()
  useImperativeHandle(ref, () => ({
    clear: () => {
      nestedDropdownRef.current?.clear()
    }
  }))

  // TODO: DEDUPE TAGS LIST | AGGREGATE THEM
  const [selectedTagLength, setSelectedTagLength] = useState<number>(0)

  const tagOptions = useMemo(() => {
    if (!value) {
      setSelectedTagLength(0)
      onTagsChange([])
    }
    // onTagsChange([])

    if (!tags) return []

    const tagSet = new Set(tags.map((tag: Tag) => tag.tag))

    const filtered = [...tagSet].map((tag) => {
      return {
        label: tag,
        value: tag,
        checked: value?.some((t) => t.tag === tag) ?? false
      }
    })

    if (value) setSelectedTagLength(filtered.filter((tag) => tag.checked).length)

    return filtered
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags, JSON.stringify(value)])

  return (
    <NestedDropdown
      data={tagOptions}
      label="Tags"
      onChange={(_, options) => {
        const tagValues = options.map((option: OptionTypeBase) => option.value)
        const _tags = tagValues.map((tag: string) => tags?.find((t: Tag) => t.tag === tag)!)
        setSelectedTagLength(tagValues.length)
        onTagsChange(_tags)
      }}
      loading={!tags && !!account.id && !!tenantIds.length}
      customTriggerIndicator={<span className="font-bold text-th-text">{selectedTagLength || 'All'}</span>}
      selectionLimit={10}
      ref={nestedDropdownRef}
    />
  )
})

export default TagsDropdown
