import { type FunctionComponent } from 'react'

import { type Alert as AlertType } from '../../../types/alert'
import { type Tab } from '../../../types/navigation'

/* hooks */
import useCurrentRoute from 'src/hooks/utils/useCurrentRoute'

import { classNames } from 'src/utils/classNames'

import { Iconly } from 'react-iconly'
import { type RootStateOrAny, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'

import Button from '../../Common/Button'
import Alert from '../Alert/Alert'
import Hamburger from './Hamburger/Hamburger'

interface Props {
  fixedLabel?: string
}

const Header: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const route = useCurrentRoute()
  const navigate = useNavigate()
  const alerts = useSelector((state: RootStateOrAny) => state.alerts)

  /* action function and route change handler */
  const handleClick = () => {
    route.action?.function && route.action.function()
    route.action?.route && navigate(route.action.route)
  }

  return (
    <header className="relative px-4 pt-4 xs:px-10 xs:pt-10">
      <div className="relative mb-4 flex flex-wrap items-center xs:mb-10 lg:flex-nowrap">
        <Hamburger className="md:hidden" />
        {/* title */}
        <span className="ml-6 w-max whitespace-nowrap font-headline text-3xl font-bold tracking-wide md:ml-0">
          {props.fixedLabel ? props.fixedLabel : route.label}
        </span>
        {/* tabs */}
        {route.tabs && route.tabs.length > 1 && (
          <ul className="mt-6 flex h-9 w-full items-center gap-2 space-x-12 overflow-x-auto overflow-y-hidden whitespace-nowrap p-2 tracking-wide text-th-text-secondary lg:ml-16 lg:mt-0">
            {route.tabs.map((tab: Tab, index: number) => {
              return (
                <NavLink
                  to={tab.route}
                  key={index}
                  end
                  className={({ isActive }) => classNames('h-7 font-headline font-bold', isActive && 'border-b-2 border-th-primary text-th-primary')}
                >
                  {tab.label}
                </NavLink>
              )
            })}
          </ul>
        )}
        {/* call to action */}
        {route.action && (
          <div className="absolute right-0 top-0">
            <Button variant="primary" onClick={() => handleClick()}>
              {route.action.icon && (
                <span className="mr-1.5 flex h-5 w-5 items-center">
                  <Iconly name={route.action.icon} set="light" />
                </span>
              )}
              {route.action.label}
            </Button>
          </div>
        )}
      </div>
      <hr className="hidden border-th-border xs:block" />
      {/* alerts */}
      <div className="absolute left-0 z-40 mt-4 w-full px-4 xs:px-10">
        {alerts.map((alert: AlertType, index: number) => {
          return <Alert alert={alert} index={index} key={index} />
        })}
      </div>
    </header>
  )
}

export default Header
