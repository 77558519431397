import { type FunctionComponent, type ReactNode } from 'react'

import Popover from './Popover'

interface Props {
  icon: ReactNode
  popoverText: string
}

const Pin: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { icon, popoverText } = props

  return (
    <Popover text={popoverText}>
      <div className="flex h-8 w-8 items-center justify-center rounded-full bg-th-content-secondary">{icon}</div>
    </Popover>
  )
}

export default Pin
