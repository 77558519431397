import { type Data, GET_SELECTED_ITEMS, SET_SELECTED_ITEMS } from 'src/actions/fileActions'

export interface FileState {
  file: Data[]
}

const initialState: FileState['file'] = []

const folderReducer = (state = initialState, action: any): FileState['file'] => {
  switch (action.type) {
    case SET_SELECTED_ITEMS:
      return action.payload.items
    case GET_SELECTED_ITEMS:
      return state
    default:
      return state
  }
}

export default folderReducer
