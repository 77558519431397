import { Fragment, useState } from 'react'

import Input from '~/components/Common/Input'

import { Iconly } from 'react-iconly'
import { IoCloseCircleOutline } from 'react-icons/io5'

import { useChangeApprovalFilters } from '../../hooks/useChangeApprovalFilters'

export const SearchFilter = () => {
  const { handleSearchChange, handleClearSearch } = useChangeApprovalFilters()
  const [showSearch, setShowSearch] = useState(false)

  return (
    <Fragment>
      {showSearch ? (
        <Fragment>
          <Input
            type="text"
            name="search"
            placeholder="Search"
            autoFocus
            onChange={(e) => {
              handleSearchChange(e.target.value)
            }}
          />
          <IoCloseCircleOutline
            className="h-6 w-6 cursor-pointer"
            onClick={() => {
              setShowSearch(false)
              handleClearSearch()
            }}
          />
        </Fragment>
      ) : (
        <Iconly name="Search" set="light" className="cursor-pointer" onClick={() => setShowSearch(true)} />
      )}
    </Fragment>
  )
}
