import { classNames } from 'src/utils/classNames'

type ProgressIndicatorProps = {
  percent?: number | null
  className?: string
}

const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({ percent, className }) => {
  return <div className={classNames('absolute inset-x-0 top-0 h-1.5 bg-th-tertiary', className)} style={{ width: `${percent ?? 0}%` }} />
}

export default ProgressIndicator
