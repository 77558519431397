import { type UseBaseQueryOptions } from '@tanstack/react-query'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useQuery from 'src/hooks/utils/useQuery'

import { type Moment } from 'moment'

export interface ChangeStats {
  planned: number
  pending_approval: number
  rejected: number
  closed: number
}

export interface ChangeStatsFilterOptions {
  startDate: Moment
  endDate: Moment
}

interface ChangeStatsRequestData {
  start_date: string
  end_date: string
}

const mapToChangeStatsRequestData = (filterOptions: ChangeStatsFilterOptions): ChangeStatsRequestData => ({
  start_date: filterOptions.startDate.format('YYYY-MM-DD'),
  end_date: filterOptions.endDate.format('YYYY-MM-DD')
})

export const getChangeStatsQueryKey = (accountId: string, filterOptions: ChangeStatsFilterOptions) => {
  const requestData = mapToChangeStatsRequestData(filterOptions)
  return ['changeStats', accountId, requestData]
}

const useChangeStatsQuery = (filterOptions: ChangeStatsFilterOptions, options?: UseBaseQueryOptions<ChangeStats | null>) => {
  const account = useAccount()
  const requestData = mapToChangeStatsRequestData(filterOptions)

  return useQuery(
    getChangeStatsQueryKey(account.id, filterOptions),
    async ({ signal }) => {
      try {
        const response = await api.post(`/servicenow/account/${account.id}/changes/stats`, requestData, { signal })
        return response.data as ChangeStats
      } catch (error) {
        console.error(error)
        return null
      }
    },
    {
      enabled: !!account.id,
      refetchOnWindowFocus: false,
      ...options
    }
  )
}

export default useChangeStatsQuery
