import { useEffect, useState } from 'react'

import { type Moment } from 'moment'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

/* utils */
import { classNames } from '../../../utils/classNames'

/* css */
import './DatePicker.css'

// Consider renaming this file since it's no longer strictly for a range.

/* variants */
enum Variant {
  PRIMARY,
  SECONDARY,
  DEFAULT
}

const VARIANT_MAPS: Record<Variant, string> = {
  [Variant.PRIMARY]: 'bg-th-primary text-th-white',
  [Variant.SECONDARY]: 'bg-th-content-secondary',
  [Variant.DEFAULT]: 'bg-th-content'
}
interface Props {
  date: Moment // Now just one date
  dateId: string // ID for the single date input
  variant: Variant
  bordered?: boolean
  disabled?: boolean
  hidden?: boolean
  focus?: boolean
  minDate?: Moment
  onChange: (date: Moment) => unknown // Callback now takes just one date
}

const DateSingle = (props: Props): JSX.Element => {
  const { date, dateId, variant, bordered, disabled, hidden, focus } = props

  const [selectedDate, setSelectedDate] = useState<Moment | null>(date)
  const [focused, setFocused] = useState<boolean>(false)

  // Open the picker if the focus prop is set
  useEffect(() => {
    if (focus) setFocused(true)
  }, [focus])

  const handleDateChange = (date: Moment | null) => {
    setSelectedDate(date)
    if (date) props.onChange(date.startOf('day'))
  }

  return (
    <div
      className={classNames(
        'flex h-full gap-1 rounded-full px-2.5 py-1.5',
        'items-center',
        VARIANT_MAPS[variant],
        bordered && 'border border-th-border',
        disabled ? 'cursor-default opacity-50' : 'z-20',
        hidden && 'hidden'
      )}
    >
      <label className="ml-1 text-sm font-semibold">Start Date:</label>
      <SingleDatePicker
        date={selectedDate}
        onDateChange={handleDateChange}
        focused={focused}
        onFocusChange={({ focused }) => setFocused(focused)}
        id={dateId}
        displayFormat="DD/MM/YYYY"
        numberOfMonths={1}
        disabled={disabled}
        hideKeyboardShortcutsPanel
        noBorder
        small
        readOnly
        isDayBlocked={(day) => (props.minDate && day.isBefore(props.minDate)) || false}
        calendarInfoPosition="top"
      />
    </div>
  )
}

DateSingle.defaultProps = {
  variant: Variant.DEFAULT
}

DateSingle.variant = Variant

export default DateSingle
