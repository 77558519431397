import useAccount from './useAccount'
import useOwnUser from './useOwnUser'

const useAccountRoles = () => {
  const account = useAccount()
  const user = useOwnUser()

  const userAccount = user?.accounts?.find((acc) => acc.id === account.id)

  return {
    roles: userAccount?.roles ?? [],
    permissions: userAccount?.permissions ?? []
  }
}

export default useAccountRoles
