import { withAITracking } from '@microsoft/applicationinsights-react-js'

import { Fragment, type FunctionComponent, useEffect, useState } from 'react'

import { type Service } from '../../types/service'

import axios from 'axios'
import moment, { type Moment } from 'moment'
import { CSVLink } from 'react-csv'
import { Iconly } from 'react-iconly'
import { FcClock, FcLeave, FcOk, FcServices } from 'react-icons/fc'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { showAlert } from '../../actions/alertsActions'
import { startLoading, stopLoading } from '../../actions/loadingActions'
import Banner from '../../components/Common/Banner'
import Button from '../../components/Common/Button'
import Count from '../../components/Common/Count'
import DateRange from '../../components/Common/DateRange/DateRange'
import Dropdown from '../../components/Common/Dropdown'
import Section from '../../components/Common/Section'
import Spacer from '../../components/Common/Spacer'
import ServiceTable from '../../components/Tables/ServiceTable'
import { reactPlugin } from '../../configs/appInsights'
import api from '../../constants/api'

/* dropdown options */
const periodOptions = [
  {
    label: 'Current Month',
    value: 'current'
  },
  {
    label: 'Previous Month',
    value: 'previous'
  },
  {
    label: 'Custom',
    value: 'custom'
  }
]

const firstDayOfThisMonth = moment().startOf('month')
const lastDayOfThisMonth = moment().endOf('month')

const Services: FunctionComponent = (): JSX.Element => {
  const [startDate, setStartDate] = useState<Moment>(firstDayOfThisMonth)
  const [endDate, setEndDate] = useState<Moment>(lastDayOfThisMonth)
  const [period, setPeriod] = useState<string>(periodOptions[0].value)
  const [services, setServices] = useState<Service[]>([])
  const [filtered, setFiltered] = useState<Service[]>(services)
  const [showSearch, setShowSearch] = useState<boolean>(false)
  const [state, setState] = useState<string | undefined>(undefined)
  const [active, setActive] = useState<number>(0)
  const [pending, setPending] = useState<number>(0)
  const [ended, setEnded] = useState<number>(0)

  const dispatch = useDispatch()
  const account = useSelector((state: RootStateOrAny) => state.account)

  /* filter the services by search term */
  const filter = (value: string) => {
    const searchTerm = value.toLowerCase()

    setFiltered(
      services.filter(
        (service: Service) =>
          service.number.toLowerCase().indexOf(searchTerm) !== -1 ||
          service.order_number.toLowerCase().indexOf(searchTerm) !== -1 ||
          service.item.toLowerCase().indexOf(searchTerm) !== -1 ||
          service.name.toLowerCase().indexOf(searchTerm) !== -1
      )
    )
  }

  /* set the date range based on the period that has been selected */
  const handlePeriodChange = (period: string) => {
    setPeriod(period)

    const firstDayOfThisMonth = moment().startOf('month')
    const lastDayOfThisMonth = moment().endOf('month')

    if (period === 'current') {
      /* set the dates to span the current month */
      setStartDate(firstDayOfThisMonth.startOf('month'))
      setEndDate(lastDayOfThisMonth.endOf('month'))
    } else if (period === 'previous') {
      /* set the dates to span the previous month */
      setStartDate(moment(firstDayOfThisMonth.subtract(1, 'months').startOf('month')))
      setEndDate(moment(lastDayOfThisMonth.subtract(1, 'months').endOf('month')))
    }
  }

  /* set the start and end dates */
  const handleDatesChange = (startDate: Moment, endDate: Moment) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  /* start loading animation on page load */
  useEffect(() => {
    dispatch(startLoading())
  }, [dispatch])

  /* get the services for the selected account */
  useEffect(() => {
    const source = axios.CancelToken.source()

    dispatch(startLoading())

    const data = {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD')
    }

    if (account.id) {
      api
        .post(`/servicenow/account/${account.id}/services`, data, { cancelToken: source.token })
        .then((response) => {
          const states: Record<string, number> = {}

          if (response.data) {
            for (const service of response.data) {
              states[service.state] = states[service.state] ? states[service.state] + 1 : 1
            }

            /* stop loading here if there are no services to process */
            if (response.data.length === 0) {
              dispatch(stopLoading())
            }
          }

          setServices(response.data || [])
          setActive(states['Active'] || 0)
          setPending(states['Pending'] || 0)
          setEnded(states['Ended'] || 0)
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            dispatch(
              showAlert({
                type: 'error',
                message: 'We were unable to retrieve your services.',
                component: 'Services',
                error
              })
            )
            dispatch(stopLoading())
          }
        })
    }

    return () => {
      source.cancel()
    }
  }, [account, startDate, endDate, dispatch])

  /* filter the services by state */
  useEffect(() => {
    const filtered = services.filter((service: Service) => {
      return service.state === state || state === undefined
    })
    setFiltered(filtered)
  }, [state, services, showSearch])

  /* stop loading when the services have been processed */
  useEffect(() => {
    if (filtered.length > 0) {
      dispatch(stopLoading())
    }
  }, [filtered, dispatch])

  return (
    <Fragment>
      <Section>
        <div className="my-5 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-1 mdlg:grid-cols-2 xl:grid-cols-4">
          <Count
            icon={<FcServices className="h-full w-full text-th-warning" />}
            value={services.length}
            label="All Services"
            className="cursor-pointer"
            onClick={() => setState(undefined)}
            active={state === undefined}
          />
          <Count
            icon={<FcOk className="h-full w-full" />}
            value={active}
            label="Active"
            className="cursor-pointer"
            onClick={() => setState('Active')}
            active={state === 'Active'}
          />
          <Count
            icon={<FcClock className="h-full w-full" />}
            value={pending}
            label="Pending"
            className="cursor-pointer"
            onClick={() => setState('Pending')}
            active={state === 'Pending'}
          />
          <Count
            icon={<FcLeave className="h-full w-full" />}
            value={ended}
            label="Ended"
            className="cursor-pointer"
            onClick={() => setState('Ended')}
            active={state === 'Ended'}
          />
        </div>
      </Section>
      <Section>
        <Banner>
          {/* date range */}
          <Dropdown
            options={periodOptions}
            defaultValue={periodOptions[0]}
            variant={Dropdown.variant.DEFAULT}
            label="Created"
            multi={false}
            onChange={(option) => handlePeriodChange(option.value)}
          />
          <DateRange
            start={startDate}
            end={endDate}
            startDateId="services_start_date"
            endDateId="services_end_date"
            variant={DateRange.variant.DEFAULT}
            onChange={(startDate, endDate) => handleDatesChange(startDate, endDate)}
            disabled={period !== 'custom'}
            focus={period === 'custom'}
          />
          <Spacer className="hidden sm:block" />
          {/* search */}
          {showSearch ? (
            <Fragment>
              <input type="text" className="rounded-lg p-2 font-body text-sm" placeholder="Search" autoFocus onChange={(e) => filter(e.target.value)} />
              <IoCloseCircleOutline className="h-6 w-6 cursor-pointer" onClick={() => setShowSearch(false)} />
            </Fragment>
          ) : (
            <Iconly name="Search" set="light" className="cursor-pointer" onClick={() => setShowSearch(true)} />
          )}
          {/* csv download */}
          <CSVLink data={filtered} filename="services.csv" target="_blank">
            <Button onClick={() => null}>Download CSV</Button>
          </CSVLink>
        </Banner>
        <Spacer />
        <ServiceTable services={filtered} />
      </Section>
    </Fragment>
  )
}

const ServicesWithTracking = withAITracking(reactPlugin, Services, 'Services')
export default ServicesWithTracking
