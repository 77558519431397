import { type FunctionComponent, useEffect, useState } from 'react'

import { type Quote } from '../types/quote'

import ReactModal from 'react-modal'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import Scrollbar from 'react-smooth-scrollbar'

import { showAlert } from '../actions/alertsActions'
import { closeModals, openSignQuoteView } from '../actions/modalActions'
import Button from '../components/Common/Button'
import Input from '../components/Common/Input'
import Spinner from '../components/Common/Spinner'
import Submit from '../components/Common/Submit'
import Header from '../components/Content/Header/Header'
import api from '../constants/api'
import { classNames } from '../utils/classNames'

interface Props {
  standAlone?: boolean
  quote?: Quote
}

interface Signer {
  org: string
  first_name: string
  last_name: string
  email: string
  job_title: string
}

const SignQuote: FunctionComponent<Props> = (props: Props): JSX.Element => {
  /*static texts*/

  /* hooks */
  const location = useLocation()
  const dispatch = useDispatch()

  /*other constants*/
  const queryParams = new URLSearchParams(location.search)
  const encrypted_id = queryParams.get('id')

  /* globalstate */
  const open = useSelector((state: RootStateOrAny) => state.modals.signquoteview)
  const passedInQuote = useSelector((state: RootStateOrAny) => state.modals.encryptedNumber)

  /* localstate */
  const standAlone = props.standAlone
  const [doc_sent, setDocSent] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [loading, setLoading] = useState(true)
  const [sign_mode, setSignMode] = useState('')
  const [choice_made, setChoiceMade] = useState(false)
  const [self_sign, setSelfSign] = useState(false)
  const [self_sign_confirmed, setSelfSignConfirmed] = useState(false)
  const [quote_id, setQuoteId] = useState(passedInQuote || '')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [token, setToken] = useState(standAlone ? queryParams.get('token') : 'empty')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [internal, setInternal] = useState(standAlone ? queryParams.get('internal') : '')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [snow_instance, setSnowInstance] = useState(standAlone ? queryParams.get('snow_instance') : '')
  const [embedded_url_received, setEmbeddedUrlReceived] = useState(false)
  const [token_already_used, setTokenAlreadyUsed] = useState(false)
  const [quote_body, setQuoteBody] = useState('')
  const [quote_header, setQuoteHeader] = useState('')
  const [quote_footer, setQuoteFooter] = useState('')
  const [quote_sys_id, setQuoteSysId] = useState('')

  const [delegate_org, setDelegateOrg] = useState('')
  const [delegate_fname, setDelegateFName] = useState('')
  const [delegate_lname, setDelegateLName] = useState('')
  const [delegate_email, setDelegateEmail] = useState('')
  const [delegate_jobtitle, setDelegateJobTitle] = useState('')
  const [signer_org, setSignerOrg] = useState('')
  const [signer_fname, setSignerFName] = useState('')
  const [signer_lname, setSignerLName] = useState('')
  const [signer_email, setSignerEmail] = useState('')
  const [signer_jobtitle, setSignerJobTitle] = useState('')
  const [signableIframeURL, setSignableIframeURL] = useState('')
  const [directSignableURL, setDirectSignableURL] = useState('')
  const [delegated, setDelegated] = useState(false)
  const handleInputChange = (value: string) => {
    setSignMode(value)
  }

  const check_token = (token: string | null) => {
    if (token) {
      api
        .post(`old-provide/sign_quote/check_token`, { token: token })
        .then((response) => {
          if (response.data.token_valid === true) {
            if (response.data.token_use <= 999) {
              let delegee
              if (response.data.delegated_signing) {
                delegee = JSON.parse(response.data.delegate_details)
              }
              load_data(delegee)
            } else {
              dispatch(
                showAlert({
                  type: 'error',
                  message: 'This token has been already used',
                  component: 'SignQuote'
                })
              )
            }
          } else {
            dispatch(
              showAlert({
                type: 'error',
                message: 'Token not found.',
                component: 'SignQuote'
              })
            )
          }
        })
        .catch(() => {
          dispatch(
            showAlert({
              type: 'error',
              message: 'Token not found.',
              component: 'SignQuote'
            })
          )
        })
    }
  }

  const load_data = (delegee?: Signer) => {
    setChoiceMade(false)
    setDocSent(false)
    setProcessing(false)
    api
      .get(`old-provide/sign_quote/get_quote_info?id=${encrypted_id || passedInQuote}`)
      .then((response) => {
        if (response.data.error) {
          const error = new Error(response.data.error.toString())
          dispatch(
            showAlert({
              type: 'error',
              message: 'Error fetching details for Quote',
              component: 'SignQuote',
              error
            })
          )
          return
        }
        setQuoteId(response.data.quote_id)
        const quoteDetails = JSON.parse(response.data.quote_details)
        const quoteState = quoteDetails.qut_u_state
        const content = JSON.parse(quoteDetails.qut_u_contract_content)
        if (quoteState !== 'Sent') {
          if (quoteState !== 'Delegated') {
            setTokenAlreadyUsed(true)
            dispatch(
              showAlert({
                type: 'error',
                message: 'The Signable document has already been requested for this Quote.',
                component: 'SignQuote'
              })
            )
            return
          } else {
            if (!delegee) {
              setTokenAlreadyUsed(true)
              dispatch(
                showAlert({
                  type: 'error',
                  message: 'This Quote has been already delegated to someone else.',
                  component: 'SignQuote'
                })
              )
            }
          }
        }
        setQuoteBody(content.body)
        setQuoteHeader(content.header)
        setQuoteFooter(content.footer)
        setQuoteSysId(quoteDetails.qut_sys_id)

        // additional vars necessary for nominate signer form model
        if (delegee) {
          setSignerOrg(delegee.org)
          setSignerFName(delegee.first_name)
          setSignerLName(delegee.last_name)
          setSignerEmail(delegee.email)
          setSignerJobTitle(delegee.job_title)
        } else {
          setSignerOrg(quoteDetails.acc_name)
          setSignerFName(quoteDetails.cnt_first_name)
          setSignerLName(quoteDetails.cnt_last_name)
          setSignerEmail(quoteDetails.cnt_email)
          setSignerJobTitle(quoteDetails.usr_title)
        }
      })
      .catch((error) => {
        dispatch(
          showAlert({
            type: 'error',
            message: 'Error fetching details for Quote ',
            component: 'SignQuote',
            error
          })
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const submitSignMode = () => {
    setChoiceMade(true)
    if (sign_mode === 'self_sign') {
      setSelfSign(true)
    } else if (sign_mode === 'nominate_sign') {
      setSelfSign(false)
    }
  }

  const confirmSignMode = () => {
    setLoading(true)
    if (sign_mode) {
      setDocSent(true)
      setProcessing(true)
      setSelfSignConfirmed(sign_mode === 'self_sign')

      const config = {
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: 'Basic cGRmZ2VuOkJXOW5pTVNU'
        }
      }

      const postData = new URLSearchParams()
      postData.append('quote_sys_id', quote_sys_id)
      postData.append('quote_body', quote_body)
      postData.append('quote_header', quote_header)
      postData.append('quote_footer', quote_footer)
      postData.append('internal', internal ? '1' : '0')
      postData.append('instance', snow_instance || '')

      api
        .post(`old-provide/sign_quote/pdf_gen`, postData, config)
        .then((response) => {
          sendPDFToSignable(response.data.pdf)
        })
        .catch((error) => {
          dispatch(
            showAlert({
              type: 'error',
              message: 'We were unable to generate quote PDF for signing.',
              component: 'SignQuote',
              error
            })
          )
        })
    }
  }

  const getDirectLinkFromEmbedded = (str: string) => {
    const start = str.indexOf('iframe src="') + 'iframe src="'.length
    const end = str.indexOf('" width="100%" height="95%" frameborder="0" style="border: 0; overflow: hidden"></iframe>')
    return str.substring(start, end)
  }

  const sendPDFToSignable = (pdf: string) => {
    const config = {
      headers: {
        'Content-type': 'application/x-www-form-urlencoded'
      }
    }

    const postData = new URLSearchParams()
    postData.append('doc_title', quote_id)
    postData.append('pdf', pdf)
    if (self_sign.toString() === 'false') {
      postData.append('organisation', delegate_org)
      postData.append('fname', delegate_fname)
      postData.append('lname', delegate_lname)
      postData.append('email', delegate_email)
      postData.append('job_title', delegate_jobtitle)
    } else {
      postData.append('organisation', signer_org)
      postData.append('fname', signer_fname)
      postData.append('lname', signer_lname)
      postData.append('email', signer_email)
      postData.append('job_title', signer_jobtitle)
    }
    postData.append('id', quote_id)
    postData.append('sys_id', quote_sys_id)
    postData.append('snow_instance', snow_instance || '')
    postData.append('self_sign', self_sign.toString() === 'false' ? '0' : '1')

    api
      .post(`old-provide/sign_quote/send_doc`, postData, config)
      .then((response) => {
        if (!self_sign) {
          if (response.data.success) {
            setDelegated(true)
            setProcessing(false)
          }
        } else {
          if (response.data.embedded_signing_url) {
            setEmbeddedUrlReceived(true)
            const directURL = response.data.embedded_signing_url ? getDirectLinkFromEmbedded(response.data.embedded_signing_url) : ''
            const tweakedIFrameHTML = response.data.embedded_signing_url.replace(
              '<iframe',
              '<iframe onload="javascript:(function(o){o.style.height=o.contentWindow.document.body.scrollHeight+"px";}(this));" style="height:800px; width:1500px; border:none;overflow:hidden;" '
            )
            setDirectSignableURL(directURL)
            setSignableIframeURL(tweakedIFrameHTML)
          } else {
            dispatch(
              showAlert({
                type: 'error',
                message: 'No signing url received from Signable',
                component: 'SignQuote'
              })
            )
          }
        }
      })
      .catch((error) => {
        dispatch(
          showAlert({
            type: 'error',
            message: 'We were unable to send the PDF to Signable',
            component: 'SignQuote',
            error
          })
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const reset = () => {
    setLoading(true)
    setSignMode('')
    setChoiceMade(false)
    setSelfSignConfirmed(false)

    setQuoteId('')
    load_data()
  }

  useEffect(() => {
    ReactModal.setAppElement(standAlone ? '#signQuoteContent' : '#content')
  })

  useEffect(() => {
    if (standAlone) {
      if (!open) {
        dispatch(openSignQuoteView())
      }
    }
  }, [standAlone, open, dispatch])

  useEffect(() => {
    standAlone && check_token(token)
    if (!standAlone) {
      setSignMode('self_sign')
      setChoiceMade(true)
      setSelfSignConfirmed(true)
      confirmSignMode()
      load_data()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return (
    <ReactModal
      onRequestClose={() => {
        if (!standAlone) dispatch(closeModals())
      }}
      isOpen={open}
      className={classNames(
        standAlone && 'content h-full w-full overflow-hidden bg-th-content',
        standAlone && 'flex flex-col text-th-text md:rounded-tl-content',
        !standAlone && 'z-100 absolute left-1/2 top-1/2',
        !standAlone && '-translate-x-1/2 -translate-y-1/2 transform',
        !standAlone && 'overflow-hidden rounded-lg p-4 shadow-xl xs:m-2',
        !standAlone && 'theme-default w-screen bg-th-content-secondary text-th-text xs:w-96 md:w-3/4',
        !standAlone && 'border border-th-gray'
      )}
      overlayClassName={classNames(
        standAlone && 'fixed inset-0 theme-default',
        standAlone && 'bg-th-gray md:ml-60 md:rounded-tl-content',
        !standAlone && 'fixed inset-0 p-4 theme-default',
        !standAlone && 'bg-th-gray md:ml-60 md:rounded-tl-content'
      )}
    >
      {/* modal header */}
      {standAlone && <Header fixedLabel={'Signing your agreement with Cloud Direct: ' + quote_id}></Header>}
      {!standAlone && (
        <div className="flex items-center justify-between">
          <div className="font-headline text-2xl font-bold">
            <span>Signing your agreement with Cloud Direct | Quote: {quote_id}</span>
          </div>
        </div>
      )}
      <Scrollbar>
        {/* modal content */}
        <div className="my-5 flex px-8 py-4 text-xl text-th-text">
          {/* loading spinner */}
          {loading && (
            <div className="mx-auto flex justify-center py-12">
              <Spinner className="h-20 w-20" />
            </div>
          )}
          {!loading && (
            <div className="bh-th-content flex-col flex-wrap">
              {!token_already_used && !choice_made && (
                <div id="choose_signer" className="mx-auto w-3/4">
                  <p className="my-2">Thank you for choosing Cloud Direct.</p>
                  <p className="my-2">Please choose who will sign the Service Agreement.</p>

                  <form name="choose_signer_form">
                    <div className="m-1">
                      <label>
                        <input
                          className="ml-2"
                          type="radio"
                          value={'self_sign'}
                          name={'sign_mode'}
                          onChange={() => handleInputChange('self_sign')}
                          checked={'self_sign' === sign_mode}
                        />
                        <span className="mx-2">I'll sign myself</span>
                      </label>
                    </div>
                    <div className="m-1">
                      <label>
                        <input
                          className="ml-2"
                          type="radio"
                          value={'nominate_sign'}
                          name={'sign_mode'}
                          onChange={() => handleInputChange('nominate_sign')}
                          checked={'nominate_sign' === sign_mode}
                        />
                        <span className="mx-2">I'll nominate someone else</span>
                      </label>
                    </div>
                    <br />
                    <Button variant="primary" disabled={!sign_mode} onClick={() => submitSignMode()} className="btn btn-primary btn-lg btn-ok">
                      Next
                    </Button>
                    <br />
                    <br />
                    <p className="font-weight-light">
                      <i>
                        NB. Please do not refresh this page, otherwise the link will expire and you will need to obtain another from your Cloud Direct
                        salesperson.
                      </i>
                    </p>
                  </form>
                </div>
              )}
              {sign_mode !== 'self_sign' && !token_already_used && choice_made && !doc_sent && (
                <div id="delegated_signer_form" className="mx-auto w-3/4">
                  <p>
                    You have chosen to delegate signing of the legal agreement to another member of your organisation. Please enter their details below and
                    press <b>Submit</b>. They will then receive an invitation to sign the agreement.
                  </p>
                  <br />
                  <form onSubmit={() => confirmSignMode()} id="delegate_details_form" name="delegate_details_form" className="flex flex-col">
                    <Input type="text" name="organisation" label="Organisation:" onChange={(e) => setDelegateOrg(e.target.value)} required />
                    <Input type="text" name="firstname" label="First name:" onChange={(e) => setDelegateFName(e.target.value)} required />
                    <Input type="text" name="lastname" label="Last name" onChange={(e) => setDelegateLName(e.target.value)} required />
                    <Input
                      type="email"
                      name="emailaddress"
                      label="Email address:"
                      onChange={(e) => {
                        setDelegateEmail(e.target.value)
                      }}
                      required
                    />
                    <Input type="text" name="jobtitle" label="Job Title:" onChange={(e) => setDelegateJobTitle(e.target.value)} required />

                    <div className="flex h-8 flex-row">
                      <Button
                        variant="primary"
                        onClick={() => {
                          'reset()'
                        }}
                        className="mr-2 h-8"
                      >
                        Back
                      </Button>
                      <Submit variant="primary" form="delegate_details_form" />
                    </div>
                  </form>

                  <br />
                </div>
              )}
              {sign_mode === 'self_sign' && !token_already_used && choice_made && !self_sign_confirmed && (
                <div id="confirm_choice" className="mx-auto w-3/4">
                  <p>
                    <b>Please note:</b> For security reasons, this is a limited-use URL and will expire after 5 visits.
                  </p>
                  <p>
                    Please press <b>Back</b> now if you wish to nominate someone else to sign.If you click <b>Confirm</b> before deciding that you would like to
                    nominate someone else to sign, please request another link from your Cloud Direct salesperson.
                  </p>
                  <br />
                  <div className="flex h-8 flex-row">
                    <Button
                      variant="primary"
                      /*disabled={!sign_mode}*/
                      onClick={() => reset()}
                      className="h-8"
                    >
                      Back
                    </Button>
                    <Button
                      variant="primary"
                      /*disabled={!sign_mode}*/
                      onClick={() => {
                        confirmSignMode()
                      }}
                      className="mx-4 h-8"
                    >
                      Confirm
                    </Button>
                  </div>
                </div>
              )}

              {((sign_mode === 'self_sign' && doc_sent && !embedded_url_received) || (sign_mode !== 'self_sign' && doc_sent && processing)) && (
                <div id="preparing_docs" className="mx-auto flex justify-center py-4">
                  <Spinner className="h-20 w-20" />
                </div>
              )}
              {delegated && (
                <p className="my-2">
                  Thanks for confirming signatory details. An email will shortly be sent to your nominated contact inviting them to sign the service agreement.
                </p>
              )}
              {sign_mode === 'self_sign' && choice_made && self_sign_confirmed && embedded_url_received && (
                <div>
                  <div
                    className="overflow-scroll"
                    dangerouslySetInnerHTML={{
                      __html: signableIframeURL
                    }}
                  />
                  {directSignableURL && (
                    <div>
                      <p className="m-2 text-xs">
                        Trouble seeing this page?{' '}
                        <a className="underline" target="_blank" rel="noreferrer" href={directSignableURL}>
                          Click here
                        </a>
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </Scrollbar>
    </ReactModal>
  )
}
export default SignQuote
