/* redux store */
import { type User } from '../types/user'

import { showAlert } from '../actions/alertsActions'
import store from '../configs/store'
import api from '../constants/api'

/* set the users settings in the store */
export const setSettings = (
  settings: Record<string, string>
): {
  type: string
  payload: Record<string, string>
} => {
  return {
    type: 'SET_SETTINGS',
    payload: settings
  }
}

/* update the users settings in the database */
export const updateSettings = (
  setting: Record<string, string>
): {
  type: string
  payload: Record<string, string>
} => {
  const user: User = store.getState().user

  /* update the users settings in the database */
  api.post(`/provide/user/${user.id}/settings`, setting).catch((error) => {
    store.dispatch(
      showAlert({
        type: 'error',
        message: 'We were unable to save your settings.',
        component: 'settingsReducer',
        error
      })
    )
  })

  return {
    type: 'UPDATE_SETTINGS',
    payload: setting
  }
}
