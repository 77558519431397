import moment from 'moment'

export const formatCurrency = (num: number | string = 0, fixed?: boolean, fixedLen = 2) => {
  if (parseFloat(num.toString()) == 0) return '£0'
  // if number is smaller than 10 and fixed is true, we want to show 2 decimals
  const isSmallerThan1 = parseFloat(num.toString()) < 1
  const isSmallerThan10 = parseFloat(num.toString()) < 10
  const number = fixed ? parseFloat(num.toString()).toFixed(isSmallerThan1 ? 3 : isSmallerThan10 ? 2 : fixedLen) : num.toString()
  return '£' + (isSmallerThan10 ? number : number.replace(/\B(?=(\d{3})+(?!\d))/g, ','))
}

export const formatTerm = (term: string = '') => {
  const termMapping: { [key: string]: string } = {
    P1D: '1 Day',
    P1W: '1 Week',
    P1M: '1 Month',
    P3M: '3 Months',
    P6M: '6 Months',
    P1Y: '1 Year',
    P2Y: '2 Years',
    P3Y: '3 Years',
    P5Y: '5 Years'
  }

  return termMapping[term] || 'Unknown'
}

export const formatBillingPlan = (plan: string) => {
  const newPlan = plan.split('_').join(' ')
  return newPlan.charAt(0).toUpperCase() + newPlan.slice(1)
}

export const formatDate = (date: string) => {
  return moment(date).format('DD/MM/YYYY')
}

export type FormatOptions = 'currency' | 'term' | 'billingPlan' | 'currencyFixed' | 'date'
export const format = (value: string | number | null | undefined = '', option: FormatOptions) => {
  if (value == null && value === undefined) return value

  switch (option) {
    case 'currency':
      return formatCurrency(value as number)
    case 'currencyFixed':
      return formatCurrency(value as number, true)
    case 'term':
      return formatTerm(value as string)
    case 'billingPlan':
      return formatBillingPlan(value as string)
    case 'date':
      return formatDate(value as string)
    default:
      return value
  }
}
