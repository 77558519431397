import { type FunctionComponent } from 'react'

import Drawer from '../Common/Drawer'
import Spacer from '../Common/Spacer'

const M365SecureScoreInfo: FunctionComponent = (): JSX.Element => {
  /* drawer content */
  return (
    <Drawer title="M365 Secure Score" loading={false}>
      <div className="mb-4 text-xl font-bold">What is Microsoft Secure Score?</div>
      <div className="text-sm">
        Microsoft Secure Score is a security analytics tool available to you as an Office 365 licence holder. The metrics on display are live figures and unique
        to your organisation. They indicate your current security levels based on system configurations, user behaviour and other detected settings to provide a
        snapshot of where there are opportunities to enhance security. It's always important to remember that no system is immune to security breaches.
        Ultimately the Secure Score allows us to help you address areas of concern and to introduce best practices that will further minimise security-related
        risks.
      </div>
      <Spacer />
      <div className="mb-4 text-xl font-bold">How Does it Work?</div>
      <div className="text-sm">
        Secure Score determines which services you are using (for example: Exchange, OneDrive, SharePoint) and calculates a theoretical maximum score. User
        behaviours such as reviewing security reports along with implementing configurations such as multi-factor authentication (MFA) are rewarded with points.
        Security should always be balanced by usability; not every recommendation will suit your organisation. But when reviewed with a Cloud Direct expert,
        your Secure Score can increase by acting on a set of priority measures.
      </div>
    </Drawer>
  )
}

export default M365SecureScoreInfo
