const initialState = {
  accounts: false,
  signquoteview: false,
  signquotemodal: false,
  delegatequotemodal: false,
  contactconfirmmodal: false
}

const modalReducer = (
  state = initialState,
  action: {
    type: string
    payload: Record<string, string | boolean>
  }
): Record<string, boolean> => {
  switch (action.type) {
    case 'OPEN_CONTACT_CONFIRM_MODAL':
      return {
        ...state,
        contactconfirmmodal: true
      }
    case 'OPEN_ACCOUNTS_MODAL':
      return {
        ...state,
        accounts: true
      }
    case 'OPEN_TICKET_MODAL':
      return {
        ...state,
        ...action.payload
      }
    case 'OPEN_NEW_TICKET_MODAL':
      return {
        ...state,
        ...action.payload
      }
    case 'OPEN_SIGNQUOTE_VIEW':
      return {
        ...state,
        signquoteview: true,
        ...action.payload
      }
    case 'OPEN_SIGNQUOTE_MODAL':
      return {
        ...state,
        signquotemodal: true,
        ...action.payload
      }
    case 'OPEN_DELEGATEQUOTE_MODAL':
      return {
        ...state,
        delegatequotemodal: true,
        ...action.payload
      }
    case 'CLOSE_SIGNQUOTE_VIEW':
      return {
        ...state,
        signquoteview: false
      }
    case 'CLOSE_SIGNQUOTE_MODAL':
      return {
        ...state,
        signquotemodal: false
      }
    case 'OPEN_EDIT_AZURE_BUDGET_MODAL':
      return {
        ...state,
        ...action.payload
      }
    case 'CLOSE_EDIT_AZURE_BUDGET_MODAL':
      return {
        ...state,
        ...action.payload
      }
    case 'OPEN_NOAZURE_MODAL':
      return {
        ...state,
        noazuremodal: true
      }
    case 'CLOSE_NOAZURE_MODAL':
      return {
        ...state,
        noazuremodal: false
      }
    case 'CLOSE_MODALS':
      return {
        accounts: false,
        signquoteview: false,
        signquotemodal: false,
        delegatequotemodal: false,
        editazurebudgetmodal: false,
        contactconfirmmodal: false
      }
    default:
      return state
  }
}

export default modalReducer
