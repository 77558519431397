import { useMemo } from 'react'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useQuery from 'src/hooks/utils/useQuery'

import { useLocation } from 'react-router'

export type GlobalConfig = {
  enabled: boolean
  persistence_mode: 'persistent' | 'dismissable' | 'auto-close'
  text: string
  variant: 'info' | 'success' | 'warning' | 'error'
  routes: string[]
  type: 'alert'
  id: string
  created_by: string
  updated_by: string
  updated_at: string
}

export type ExtendedGlobalConfig = GlobalConfig & {
  appliedToCurrentRoute: boolean
  isAppliedToRoute: (route: string) => boolean
  isGlobal: boolean
}

export const ADDITIONAL_MATCHERS: Record<string, string> = {
  '/dashboard': '/'
}

const useGlobalConfig = ({ admin } = { admin: false }) => {
  const account = useAccount()
  const location = useLocation()

  const { data: globalConfigs, isFetching } = useQuery(
    ['globalConfigsQuery', account.id, admin],
    async ({ signal }) => {
      const response = await api.get(`/provide/${admin ? 'ADMIN' : account.id}/global_config`, {
        params: { account_id: account.id },
        signal
      })
      return response.data as GlobalConfig[]
    },
    {
      enabled: !!account.id,
      refetchOnWindowFocus: false
    }
  )

  const extendedConfigs = useMemo(
    () =>
      globalConfigs?.map((c) => ({
        ...c,
        appliedToCurrentRoute: c?.routes?.some((route) => location.pathname === route || location.pathname === ADDITIONAL_MATCHERS[route]),
        isAppliedToRoute: (route: string) => c?.routes?.some((r) => r === route || ADDITIONAL_MATCHERS[r] === route),
        isGlobal: c.routes.some((r) => r === '*')
      })),
    [globalConfigs, location.pathname]
  )

  return { globalConfigs: extendedConfigs ?? null, isFetching }
}

export default useGlobalConfig
