import { useQuery } from '@tanstack/react-query'

import { type Tag, type Tenant } from 'src/types/azure'

import { showAlert } from 'src/actions/alertsActions'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'

import axios from 'axios'
import { type Moment } from 'moment'
import { useDispatch } from 'react-redux'

import { useTenantsQuery } from '../../servicenow/queries/useTenantsQuery'

interface UseTagsProps {
  dateRange: [Moment, Moment]
  tenants?: Tenant[]
}

const useTags = ({ dateRange, tenants }: UseTagsProps) => {
  const { data: allTenants } = useTenantsQuery({ active: true })
  const account = useAccount()
  const formattedDateRange = dateRange.map((date) => date.format('YYYY-MM-DD'))
  const tenantIds = tenants?.map((tenant) => tenant.id) ?? allTenants.map((tenant) => tenant.id)
  const dispatch = useDispatch()

  const { data: tags } = useQuery({
    queryKey: ['tenant-tags', account.id, formattedDateRange[0], formattedDateRange[1], tenantIds],
    queryFn: async ({ signal }) => {
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by TanStack Query')
      })

      try {
        const response = await api.post(
          `/ms-api/v2/account/${account.id}/azure_usage/get_tenants_tags_for_date_range`,
          {
            tenantIds: tenantIds,
            startDate: formattedDateRange[0],
            endDate: formattedDateRange[1]
          },
          { cancelToken: source.token }
        )
        return response.data as Tag[]
      } catch (error) {
        if (!axios.isCancel(error)) {
          dispatch(
            showAlert({
              type: 'error',
              message: 'We were unable to retrieve your tags.',
              component: 'AzureOverview',
              error: error as Error
            })
          )
        }
        return null
      }
    },
    enabled: !!account.id && !!tenantIds.length
  })

  return tags
}

export default useTags
