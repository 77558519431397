import ProgressBar from '@ramonak/react-progress-bar'

import { Fragment, type FunctionComponent, useEffect, useState } from 'react'

import { type AzureSecureScore as AzureSecureScoreType, type Score, type SecureScoreTenant } from '../../types/security'

import axios, { type AxiosResponse } from 'axios'
import { FcBullish, FcLock, FcPieChart } from 'react-icons/fc'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { showAlert } from '../../actions/alertsActions'
import { startLoading, stopLoading } from '../../actions/loadingActions'
import Banner from '../../components/Common/Banner'
import Count from '../../components/Common/Count'
import Heading from '../../components/Common/Heading'
import Section from '../../components/Common/Section'
import Spacer from '../../components/Common/Spacer'
import TenantDropdown from '../../components/Common/TenantDropdown'
import api from '../../constants/api'

const AzureSecureScore: FunctionComponent = (): JSX.Element => {
  /* secure scores */
  const [score, setScore] = useState<Score>()
  const [azureSecureScores, setAzureSecureScores] = useState<AzureSecureScoreType[]>()

  const dispatch = useDispatch()
  const account = useSelector((state: RootStateOrAny) => state.account)
  const tenant = useSelector((state: RootStateOrAny) => state.tenant)

  /* get the tenants secure score */
  useEffect(() => {
    const source = axios.CancelToken.source()

    setScore(undefined)
    setAzureSecureScores(undefined)
    dispatch(startLoading())

    const promises = []

    if (tenant.id !== '') {
      /* azure secure score */
      promises.push(
        api.get(`/soteria/account/${account.id}/tenant/${tenant.id}`, { cancelToken: source.token }).then((response: AxiosResponse) => {
          if (response.data) {
            const tenant: SecureScoreTenant = response.data

            if (tenant.azureSecureScore) {
              const score: Score = tenant.azureSecureScore.score
              setScore(score)
            }
          }
        })
      )

      /* azure secure score breakdown */
      promises.push(
        api.get(`/soteria/account/${account.id}/tenant/${tenant.id}/azure_secure_scores`, { cancelToken: source.token }).then((response: AxiosResponse) => {
          if (response.data && response.data.length > 0) {
            setAzureSecureScores(response.data)
          }
        })
      )

      Promise.all(promises)
        .then(() => dispatch(stopLoading()))
        .catch((error) => {
          if (!axios.isCancel(error)) {
            dispatch(
              showAlert({
                type: 'error',
                message: 'We were unable to retrieve your secure score data.',
                component: 'AzureSecureScore',
                error
              })
            )

            setScore(undefined)
            setAzureSecureScores(undefined)

            dispatch(stopLoading())
          }
        })
    }

    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant])

  return (
    <Fragment>
      <Section>
        <Banner>
          <Spacer />
          {/* tenant filter */}
          <TenantDropdown />
        </Banner>
        <Spacer />
        {/* score tiles */}
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-1 mdlg:grid-cols-2 xl:grid-cols-3">
          <Count icon={<FcBullish className="h-full w-full text-th-warning" />} value={score?.current.toFixed(0) || 0} label="Current Score" />
          <Count icon={<FcLock className="h-full w-full" />} value={score?.max.toFixed(0) || 0} label="Maximum Score" />
          <Count icon={<FcPieChart className="h-full w-full" />} value={(score ? (score?.percentage * 100).toFixed(0) : 0) + '%'} label="Score Percentage" />
        </div>
      </Section>
      <Section>
        {/* score breakdown */}
        <Heading text="Score Breakdown" />
        <div className="flex flex-col rounded-lg bg-th-content">
          <div className="grid grid-cols-7 rounded-t-lg bg-th-border p-4 font-bold">
            <div className="col-span-3">Subscription</div>
            <div>Current Score</div>
            <div>Maximum Score</div>
            <div className="col-span-2">Score Percentage</div>
          </div>
          {azureSecureScores && azureSecureScores.length > 0 ? (
            azureSecureScores.map((score: AzureSecureScoreType) => (
              <div className="grid grid-cols-7 border-t border-th-border p-4">
                <div className="col-span-3">{score.subscriptionName}</div>
                <div>{score.overview.score.current.toFixed(0)}</div>
                <div>{score.overview.score.max.toFixed(0)}</div>
                <div className="col-span-2 flex items-center gap-4">
                  <ProgressBar
                    completed={(score.overview.score.percentage * 100).toFixed(0)}
                    maxCompleted={100}
                    className="w-full"
                    isLabelVisible={false}
                    bgColor={
                      score.overview.score.percentage < 0.33 ? 'var(--error)' : score.overview.score.percentage < 0.66 ? 'var(--warning)' : 'var(--success)'
                    }
                    baseBgColor="var(--border)"
                  />
                  <div className="w-16">{(score.overview.score.percentage * 100).toFixed(0)}%</div>
                </div>
              </div>
            ))
          ) : (
            <div className="p-4">No secure scores found.</div>
          )}
        </div>
      </Section>
      <Section>
        <Heading text="Disclaimer" />
        <div className="text-sm">
          The Secure Score does not express an absolute measure of how likely you are to get breached. It expresses the extent to which you have adopted
          controls which can offset the risk of being breached. No service can guarantee that you will not be breached, and the Secure Score should not be
          interpreted as a guarantee in any way.
        </div>
      </Section>
    </Fragment>
  )
}

export default AzureSecureScore
