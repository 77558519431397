export const setAuthorised = (
  authorised: boolean
): {
  type: string
  payload: boolean
} => {
  return {
    type: 'SET_AUTHORISED',
    payload: authorised
  }
}
