import { type FunctionComponent } from 'react'

import Spinner from '../../Common/Spinner'

const Loading: FunctionComponent = (): JSX.Element => {
  return (
    <div className="z-8 fixed left-0 top-0 flex h-full w-full items-center justify-center bg-th-content opacity-90 md:left-60 md:rounded-tl-content md:pr-60">
      <Spinner className="h-14 w-14" />
    </div>
  )
}

export default Loading
