import { type UseQueryOptions } from '@tanstack/react-query'

import { type Subscription } from 'src/types/microsoft365'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useQuery from 'src/hooks/utils/useQuery'

const useSubscriptionsQuery = (tenantId: string | undefined, options?: UseQueryOptions<Subscription[]>) => {
  const account = useAccount()

  return useQuery(
    ['subscriptions', account.id, tenantId],
    async ({ signal }) => {
      if (!account.id || !tenantId) throw new Error('Account ID or Tenant ID is missing')

      const response = await api.get(`/ms-api/subscriptions/account/${account.id}/${tenantId}`, { signal })
      return response.data?.Items as Subscription[]
    },
    {
      enabled: !!account.id && !!tenantId,
      ...options
    }
  )
}

export default useSubscriptionsQuery
