import { useMemo, useState } from 'react'

import useDatePicker from 'src/hooks/composite/useDatePicker'
import useAccount from 'src/hooks/utils/useAccount'

import { classNames } from 'src/utils/classNames'

import moment, { type Moment } from 'moment'
import { PiCheckCircle, PiXCircle } from 'react-icons/pi'

import Button from '../Common/Button'
import Checkbox from '../Common/Checkbox/Checkbox'
import Input from '../Common/Input'
import Popover from '../Common/Popover'
import PortalDrawer from '../Common/PortalDrawer'
import Dropdown from '../Common/RefactoredDropdown'
import Spinner from '../Common/Spinner'
import TextArea from '../Common/TextArea'
import SubscriptionDropdown from '../Dropdowns/SubscriptionDropdown'

const startMonth = moment('Aug 2022', 'MMM YYYY')
const monthDiff = moment().diff(startMonth, 'months')

const eachHourOfDay = Array.from({ length: 24 }, (_, i) => ({
  label: moment().startOf('day').add(i, 'hours').format('HH:mm A'),
  value: moment().startOf('day').add(i, 'hours')
}))

const eachDayOfWeek = Array.from({ length: 7 }, (_, i) => ({
  label: moment()
    .startOf('week')
    .add(i + 1, 'days')
    .format('dddd'),
  value: moment()
    .startOf('week')
    .add(i + 1, 'days')
}))

const eachDayOfMonth = Array.from({ length: 30 }, (_, i) => ({
  label: moment().startOf('month').add(i, 'days').format('DD'),
  value: moment().startOf('month').add(i, 'days').format()
})).concat({ label: 'Last day of Month', value: 'ldom' })

export type AzureExportSubmitData = {
  name?: string // optional
  description?: string // optional
  tenantIds: Array<string>
  recurring: boolean
  nextExportDate: string // '2024-04-08T11:02:49.346Z'
  subscriptions: Array<{ subscriptionId: string; subscriptionName?: string }>
  frequency?: 'daily' | 'weekly' | 'monthly'
  months: Array<string>
  customerUrl: string
  allSubsInOneFile?: boolean
}

type AzureExportFormData = {
  name: string
  description: string
  tenantIds: Array<string>
  recurring: boolean
  subscriptions: Array<{ subscriptionId: string; subscriptionName?: string }>
  frequency?: 'daily' | 'weekly' | 'monthly' | ''
  months: Array<string>
  customerUrl: string
  nextExportDate: string
  allSubsInOneFile: boolean
}

type ReservedInstanceDrawerProps = {
  onClose: () => void
  onSubmit?: (values: AzureExportSubmitData) => void
  isLoading?: boolean
  hidden: boolean
}

const AzureExportDrawer: React.FC<ReservedInstanceDrawerProps> = ({ onClose, onSubmit, isLoading, hidden }) => {
  useAccount({ onChange: onClose })

  const [openMoment] = useState<Moment>(moment())
  const exportPlaceholder = 'Export' + '-' + openMoment.format('YYYY-MM-DD_HH:mm:ss')
  const [exportFormData, setExportFormData] = useState<AzureExportFormData>({
    name: '',
    description: '',
    frequency: '',
    tenantIds: [],
    recurring: false,
    subscriptions: [],
    customerUrl: '',
    months: [],
    nextExportDate: '',
    allSubsInOneFile: true
  })
  const [scheduled, setScheduled] = useState(false)

  const months = Array.from({ length: monthDiff - 1 }, (_, i) => ({
    label: startMonth.clone().add(i, 'months').format('MMM YYYY'),
    value: startMonth.clone().add(i, 'months').format('YYYY-MM')
  }))
    .concat([
      {
        label: 'Previous Month',
        value: 'previous'
      },
      {
        label: 'Current Month',
        value: 'current'
      }
    ])
    .toReversed()

  const { DatePickerComponent, selectedDate } = useDatePicker({ disabled: exportFormData.nextExportDate === 'ldom', minDate: openMoment })
  const handleExportData = () => {
    // if it's daily set the next export date to tomorrow the time they selected
    let nextExportDate = selectedDate as Moment | string
    if (openMoment.isAfter(selectedDate)) nextExportDate = openMoment

    if (exportFormData.nextExportDate === 'ldom') {
      nextExportDate = 'ldom'
    } else {
      switch (exportFormData.frequency) {
        case 'daily':
          nextExportDate = moment(exportFormData.nextExportDate, 'YYYY-MM-DDTHH:mm:ss')
          while (nextExportDate.isBefore(selectedDate)) nextExportDate.add(1, 'days')
          break
        case 'weekly':
          nextExportDate = moment(exportFormData.nextExportDate, 'YYYY-MM-DD')
          while (nextExportDate.isBefore(selectedDate)) nextExportDate.add(1, 'weeks')
          break
        case 'monthly':
          nextExportDate = moment(exportFormData.nextExportDate)
          while (nextExportDate.isBefore(selectedDate)) nextExportDate.add(1, 'months')
          break
      }
    }

    onSubmit?.({
      name: exportFormData.name || exportPlaceholder,
      description: exportFormData.description,
      tenantIds: exportFormData.tenantIds,
      recurring: exportFormData.recurring,
      nextExportDate:
        nextExportDate === 'ldom'
          ? 'ldom'
          : exportFormData.frequency === 'daily'
            ? (nextExportDate as Moment).add(parseInt((nextExportDate as Moment).format('Z').substring(0, 3)), 'hours').format()
            : (nextExportDate as Moment).format(),
      subscriptions: exportFormData.subscriptions,
      frequency: exportFormData.frequency ? exportFormData.frequency : undefined,
      months: exportFormData.months,
      customerUrl: exportFormData.customerUrl,
      allSubsInOneFile: exportFormData.allSubsInOneFile
    })
  }

  const times = useMemo(() => {
    if (exportFormData.frequency === 'daily') return eachHourOfDay
    if (exportFormData.frequency === 'weekly') return eachDayOfWeek
    return eachDayOfMonth
  }, [exportFormData.frequency])

  const [validationResults, setValidationResults] = useState([false, false, false])

  const sasUrlValidation = [
    [(url: string) => url.startsWith('https://'), 'HTTPS Protocol'],
    [(url: string) => url.includes('blob.core.windows.net'), 'Blob Storage Container'],
    [(url: string) => url.includes('&sig='), 'Includes Signature']
  ] as const

  // Function to update validation results
  const updateValidationResults = (url: string) => {
    // Extracting necessary parts from SAS URL
    const httpsProtocol = sasUrlValidation[0][0](url)
    const container = sasUrlValidation[1][0](url)
    const signiture = sasUrlValidation[2][0](url)

    // Update state with validation results
    setValidationResults([httpsProtocol, container, signiture])
  }

  return (
    <>
      <PortalDrawer
        title="Export Azure Data"
        onClose={onClose}
        hidden={hidden}
        actions={
          <div className="grid grid-cols-2 gap-4">
            <Button onClick={() => onClose()} bordered disabled={isLoading}>
              Cancel
            </Button>
            {exportFormData.months.length === 0 || (exportFormData.subscriptions.length === 0 && exportFormData.tenantIds.length === 0) ? (
              <Popover
                className="w-full"
                text={
                  exportFormData.months.length === 0 || (exportFormData.subscriptions.length === 0 && exportFormData.tenantIds.length === 0)
                    ? `Select at least one ${exportFormData.months.length === 0 ? 'month to export' : ''}${
                        exportFormData.months.length === 0 && exportFormData.subscriptions.length === 0 && exportFormData.tenantIds.length === 0 ? ' and ' : ''
                      }${exportFormData.subscriptions.length === 0 && exportFormData.tenantIds.length === 0 ? 'subscription to export' : ''}`
                    : ''
                }
                position="top"
              >
                <Button
                  className="w-full"
                  onClick={handleExportData}
                  bordered
                  variant="primary"
                  disabled={
                    isLoading ||
                    exportFormData.months.length === 0 ||
                    (exportFormData.subscriptions.length === 0 && exportFormData.tenantIds.length === 0) ||
                    (!!exportFormData.customerUrl && validationResults.some((v) => !v))
                  }
                >
                  {isLoading ? <Spinner className="h-[14px] w-[14px] border-l-th-content" /> : 'Export'}
                </Button>
              </Popover>
            ) : (
              <Button
                onClick={handleExportData}
                bordered
                variant="primary"
                disabled={
                  isLoading ||
                  exportFormData.months.length === 0 ||
                  (exportFormData.subscriptions.length === 0 && exportFormData.tenantIds.length === 0) ||
                  (!!exportFormData.customerUrl && validationResults.some((v) => !v))
                }
              >
                {isLoading ? <Spinner className="h-[14px] w-[14px] border-l-th-content" /> : 'Export'}
              </Button>
            )}
          </div>
        }
      >
        <div className="flex flex-col gap-4">
          <div className="grid grid-cols-2 items-center gap-4">
            <SubscriptionDropdown
              className="dropdown-right"
              onChange={(tenants, subscriptions) => {
                setExportFormData((prev) => ({
                  ...prev,
                  tenantIds: tenants.map((t) => t.id),
                  subscriptions: subscriptions.map((s) => ({ subscriptionId: s.id, subscriptionName: s.name }))
                }))
              }}
            />

            <Dropdown
              data={months}
              label="Months"
              onChange={(_, months) => {
                setExportFormData((prev) => ({ ...prev, months: months.map((m: { value: string }) => m.value) }))
              }}
            />
          </div>

          <hr className="my-4 border-th-border" />

          <div className="grid grid-cols-2 items-center gap-4">
            <Checkbox
              label="Merge into One File"
              info="If checked, all subscriptions will be merged into one file. If unchecked, each subscription will be exported into a separate file."
              checked={exportFormData.allSubsInOneFile}
              onChange={(e) => {
                setExportFormData((prev) => ({ ...prev, allSubsInOneFile: e.target.checked }))
              }}
            />
            <Checkbox
              label="Scheduled"
              checked={scheduled}
              onChange={(e) => {
                setScheduled(e.target.checked)
                if (!e.target.checked) setExportFormData((prev) => ({ ...prev, recurring: false }))
              }}
            />
          </div>

          {scheduled && (
            <>
              <div className="grid grid-cols-2 items-center gap-4">
                <Checkbox
                  label="Recurring"
                  checked={exportFormData.recurring}
                  onChange={(e) => {
                    setExportFormData((prev) => ({ ...prev, recurring: e.target.checked, frequency: e.target.checked ? 'monthly' : '' }))
                  }}
                />

                {DatePickerComponent}

                {exportFormData.recurring && (
                  <>
                    <Dropdown
                      data={[
                        { label: 'Daily', value: 'daily' },
                        { label: 'Weekly', value: 'weekly' },
                        { label: 'Monthly', value: 'monthly', checked: true }
                      ]}
                      label="Frequency"
                      onChange={(_, frequency) => {
                        setExportFormData((prev) => ({ ...prev, frequency: frequency[0]?.value as 'daily' | 'weekly' | 'monthly' }))
                      }}
                      mode="simpleSelect"
                    />

                    <Dropdown
                      data={times}
                      label={exportFormData.frequency === 'daily' ? 'Time' : 'Day'}
                      onChange={(_, time) => {
                        setExportFormData((prev) => ({ ...prev, nextExportDate: time[0]?.value }))
                      }}
                      mode="simpleSelect"
                    />
                  </>
                )}
              </div>
            </>
          )}

          <hr className="my-4 border-th-border" />

          <Input
            type="text"
            name="name"
            label="Name"
            onChange={(e) => setExportFormData((prev) => ({ ...prev, name: e.target.value }))}
            placeholder={exportPlaceholder}
          />
          <TextArea name="description" label="Description" onChange={(e) => setExportFormData((prev) => ({ ...prev, description: e.target.value }))} />

          <div className="relative">
            <Input
              type="text"
              info="Optionally enter a SAS url for an Azure Storage container that can receive the exported data. Refer to [this guide](https://www.clouddirect.net/knowledge-base/KB0014666/provide-portal-steps-to-create-a-blob-container-sas-url-for-data-export) for help."
              name="destination"
              label="Export Destination"
              onChange={(e) => {
                updateValidationResults(e.target.value)
                setExportFormData((prev) => ({ ...prev, customerUrl: e.target.value }))
              }}
            />
            {exportFormData.customerUrl && validationResults.some((v) => !v) && (
              <div className="absolute left-0 top-full mt-2 rounded-xl p-2">
                {sasUrlValidation.map(([_, message], index) => (
                  <div key={index} className="flex items-center gap-2">
                    {validationResults[index] ? <PiCheckCircle className="text-th-green" /> : <PiXCircle className="text-th-error" />}

                    <span className={classNames(validationResults[index] ? 'text-th-text-secondary' : 'text-th-error')}>{message}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </PortalDrawer>
    </>
  )
}

export default AzureExportDrawer
