import { type User } from '../types/user'

const initialState = {
  id: '',
  first_name: 'Unknown',
  last_name: 'User',
  email: '',
  open: false
}

const userReducer = (
  state = initialState,
  action: {
    type: string
    payload: User
  }
): User | Record<string, boolean> => {
  switch (action.type) {
    case 'SET_USER':
      return action.payload
    case 'OPEN_USER':
      return {
        ...state,
        open: true
      }
    case 'CLOSE_USER':
      return {
        ...state,
        open: false
      }
    default:
      return state
  }
}

export default userReducer
