import { Fragment, type FunctionComponent } from 'react'

import { type Entitlement } from '../../types/azure'

import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { closeDrawers } from '../../actions/drawerActions'
import Button from '../Common/Button'
import Drawer from '../Common/Drawer'

const AzureLightHouseConnection: FunctionComponent = (): JSX.Element => {
  const dispatch = useDispatch()
  const entitlement: Entitlement = useSelector((state: RootStateOrAny) => state.drawers.azure_lighthouse_connect)
  /* drawer actions */
  const Actions: FunctionComponent = (): JSX.Element => {
    return (
      <Fragment>
        <Button bordered onClick={() => dispatch(closeDrawers())}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            dispatch(closeDrawers())
            window.open(
              'https://portal.azure.com/#view/Microsoft_Azure_Marketplace/GalleryItemDetailsBladeNopdl/id/cloud-direct-1665588.clouddirect_managed_deployment/selectionMode~/false/resourceGroupId//resourceGroupLocation//dontDiscardJourney~/false/selectedMenuId/home/launchingContext~/%7B%22galleryItemId%22%3A%22cloud-direct-1665588.clouddirect_managed_deploymentclouddirect_managed_deployment%22%2C%22source%22%3A%5B%22GalleryFeaturedMenuItemPart%22%2C%22VirtualizedTileDetails%22%5D%2C%22menuItemId%22%3A%22home%22%2C%22subMenuItemId%22%3A%22Search%20results%22%2C%22telemetryId%22%3A%2260889e4b-30bd-4df8-a623-7e6370e00e66%22%7D/searchTelemetryId/d33449f4-c0e5-4370-9a6e-651e55839781/isLiteSearchFlowEnabled~/false',
              '_blank'
            )
          }}
          className="flex gap-2"
        >
          Go to Marketplace
        </Button>
      </Fragment>
    )
  }

  return (
    <Drawer title={'Connecting ' + entitlement.name + ' to Azure Lighthouse'} loading={false} actions={<Actions />}>
      <div className="text-md">
        <div className="py-2">
          <p>
            {'Automated resource deployments to Azure for your '} <b>{entitlement.name}</b>
            {' (' + entitlement.subscription_id + ') subscription are disabled.'}
          </p>
          <br />
          <p>
            {'To allow deployments, you need to vist the Azure Marketplace and subscribe to our managed service called '}
            <b>
              <a
                href="https://portal.azure.com/#view/Microsoft_Azure_Marketplace/GalleryItemDetailsBladeNopdl/id/cloud-direct-1665588.clouddirect_managed_deployment/selectionMode~/false/resourceGroupId//resourceGroupLocation//dontDiscardJourney~/false/selectedMenuId/home/launchingContext~/%7B%22galleryItemId%22%3A%22cloud-direct-1665588.clouddirect_managed_deploymentclouddirect_managed_deployment%22%2C%22source%22%3A%5B%22GalleryFeaturedMenuItemPart%22%2C%22VirtualizedTileDetails%22%5D%2C%22menuItemId%22%3A%22home%22%2C%22subMenuItemId%22%3A%22Search%20results%22%2C%22telemetryId%22%3A%2260889e4b-30bd-4df8-a623-7e6370e00e66%22%7D/searchTelemetryId/d33449f4-c0e5-4370-9a6e-651e55839781/isLiteSearchFlowEnabled~/false"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                Cloud Direct Managed Deployments
              </a>
            </b>
            {'.'}
          </p>
          <br />
          <p>{'Please make sure to target the appropriate and desired Azure Subscription for the managed service.'}</p>
        </div>
      </div>
    </Drawer>
  )
}

export default AzureLightHouseConnection
