import Banner from '~/components/Common/Banner'
import Spacer from '~/components/Common/Spacer'

import { ChangeCSVDialog } from '../ChangeCSVDialog'
import { CategoryFilter } from './CategoryFilter'
import { ClassificationFilter } from './ClassificationFilter'
import { SearchFilter } from './SearchFilter'

export const ChangeFilters = () => {
  return (
    <Banner>
      <CategoryFilter />
      <ClassificationFilter />

      <Spacer className="hidden sm:block" />

      <SearchFilter />
      <ChangeCSVDialog />
    </Banner>
  )
}
