import React from 'react'

import ReactDOM from 'react-dom'
import { useSelector } from 'react-redux'

interface RootState {
  upload: {
    uploads: {
      [id: string]: {
        progress: number
        fileName: string
        status: 'uploading' | 'completed' | 'error'
      }
    }
  }
}

const UploadProgressPortal: React.FC = () => {
  const uploads = useSelector((state: RootState) => state.upload?.uploads || {})

  const uploadEntries = Object.entries(uploads)

  if (uploadEntries.length === 0) return null

  const portalContent = (
    <div className="fixed bottom-4 right-4 max-h-96 w-80 space-y-4 overflow-y-auto rounded-lg border border-th-border bg-th-content p-4 text-th-text shadow-lg">
      {uploadEntries.map(([id, upload]) => (
        <div key={id} className="bg-gray-100 border-b border-th-border p-2 last:border-b-0">
          <div className="mb-2 flex items-center justify-between">
            <span className="truncate font-semibold">{upload.fileName}</span>
            <span className={`text-sm ${upload.status === 'completed' ? 'text-th-success' : upload.status === 'error' ? 'text-th-error' : 'text-th-blue'}`}>
              {upload.status === 'completed' ? 'Verifying' : upload.status === 'error' ? 'Error' : `${upload.progress}%`}
            </span>
          </div>
          <div className="bg-gray-200 h-2.5 w-full rounded-full">
            <div
              className={`h-2.5 rounded-full ${upload.status === 'completed' ? 'bg-th-success' : upload.status === 'error' ? 'bg-th-error' : 'bg-th-blue'}`}
              style={{ width: `${upload.progress}%` }}
            ></div>
          </div>
        </div>
      ))}
    </div>
  )

  return ReactDOM.createPortal(portalContent, document.body)
}

export default UploadProgressPortal
