import { type ReactNode, useState } from 'react'

import type { ChangeFilterOptions } from '~/hooks/services/servicenow/queries/useChangesQuery'

import { DEFAULT_FILTER_OPTIONS } from '../../constants'
import { ChangeFiltersContext } from './context'

export function ChangeFiltersProvider({ children }: { children: ReactNode }) {
  const [filterOptions, setFilterOptions] = useState<ChangeFilterOptions>(DEFAULT_FILTER_OPTIONS)

  return <ChangeFiltersContext.Provider value={{ filterOptions, setFilterOptions }}>{children}</ChangeFiltersContext.Provider>
}
