import { useQuery } from '@tanstack/react-query'

import { type Tenant } from 'src/types/azure'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'

import axios from 'axios'
import { type Moment } from 'moment'

interface UseTotalSpendForDateRangeProps {
  dateRange: [Moment, Moment]
  tenants: Array<Tenant>
}

type TotalSpend = {
  tenantId: string
  subscriptionId: string
  totalSpendGBP: number
}

const useTotalSpendForDateRange = ({ dateRange, tenants }: UseTotalSpendForDateRangeProps) => {
  const account = useAccount()

  const formattedDateRange = dateRange.map((date) => date.format('YYYY-MM-DD'))

  const { data: totalSpendForDateRange } = useQuery({
    queryKey: ['total-spend-for-date-range', account.id, formattedDateRange[0], formattedDateRange[1], tenants.map((tenant) => tenant.id)],
    queryFn: async ({ signal }) => {
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by TanStack Query')
      })

      try {
        const response = await api.post(
          `/ms-api/v2/account/${account.id}/azure_usage/total_spend_for_date_range`,
          {
            tenantIds: tenants.map((tenant) => tenant.id),
            startDate: formattedDateRange[0],
            endDate: formattedDateRange[1]
          },
          { cancelToken: source.token }
        )
        return response.data as TotalSpend[]
      } catch {
        // Ignore internal error
        return null
      }
    },
    enabled: !!account.id && !!tenants.length,
    cacheTime: 1000 * 60 * 60 * 24 // 24 hours
  })

  return totalSpendForDateRange
}

export default useTotalSpendForDateRange
