import { useEffect } from 'react'

import type { Account } from 'src/types/account'

import { setAccount } from 'src/actions/accountActions'

import api from 'src/constants/api'

import useMsalClaims from 'src/hooks/utils/useMsalClaims'
import useQuery from 'src/hooks/utils/useQuery'

import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

const useAccountsQuery = () => {
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const { data: claims } = useMsalClaims()

  const accountsQuery = useQuery(
    ['accountsQuery'],
    async ({ signal }) => {
      const response = await api.get('/servicenow/accounts', { signal })
      return response.data as Account[]
    },
    {
      errorMessage: 'Failed to fetch accounts',
      cacheTime: 60 * 1000 * 5,
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    const accounts = accountsQuery.data
    if (!accounts || !claims) return

    if (claims?.tid !== '2d2006bf-2fde-473c-8ce4-ea5307e8eb99') {
      /* create the account objects on the users profile in the database if they don't already exist */
      accounts.forEach((account: Account) => {
        void api.post(`provide/account/${account.id}/roles`, {})
      })
    }

    const urlAccountId = searchParams.get('accountId')
    if (urlAccountId) {
      const accountFromUrl = accounts.find((acc: Account) => acc.id === urlAccountId)
      if (accountFromUrl) {
        dispatch(setAccount(accountFromUrl))
        searchParams.delete('accountId')
        setSearchParams(searchParams)
        return
      }
    }

    const storedAccount = localStorage.getItem('account')
    if (storedAccount && storedAccount !== 'undefined') {
      const account: Account = JSON.parse(storedAccount)

      /* check if the stored account is one of the current users */
      const exists = accounts.find((acc: Account) => acc.id === account.id)

      /* set the account to the stored account */
      if (exists) {
        dispatch(setAccount(account))
        return
      }
    }

    /* if the user logged in via the Cloud Direct tenant set Cloud Direct as the account */
    if (claims?.tid === '2d2006bf-2fde-473c-8ce4-ea5307e8eb99') {
      dispatch(
        setAccount({
          name: 'Cloud Direct',
          id: '765d9e1659980100e50a18315ecfde7b'
        })
      )
    } else {
      /* set the account as the users default account */
      if (accounts.length) dispatch(setAccount(accounts[0]))
    }
  }, [accountsQuery.data, claims, dispatch, searchParams, setSearchParams])

  return accountsQuery
}

export default useAccountsQuery
