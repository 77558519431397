import React, { useEffect, useState } from 'react'

import { showAlert } from 'src/actions/alertsActions'

import useGlobalConfigMutation from 'src/hooks/services/provide/mutations/useGlobalConfigMutation'
import { type ExtendedGlobalConfig } from 'src/hooks/services/provide/queries/useGlobalConfigQuery'
import useAccountsQuery from 'src/hooks/services/servicenow/queries/useAccountsQuery'
import useAccount from 'src/hooks/utils/useAccount'

import { allRoutes } from 'src/configs/routes'

import { useDispatch } from 'react-redux'

import Button from '../Common/Button'
import Input from '../Common/Input'
import PortalDrawer from '../Common/PortalDrawer'
import Dropdown from '../Common/RefactoredDropdown'
import Switch from '../Common/Switch'

type GlobalConfigDrawerProps = {
  globalConfig: (ExtendedGlobalConfig & { accountIds: string[] }) | null
  onClose: () => void
  hidden?: boolean
}

type FormData = {
  enabled: boolean
  text: string
  variant: 'info' | 'success' | 'warning' | 'error'
  persistence_mode: 'persistent' | 'dismissable' | 'auto-close'
  routes: string[]
  accountIds: string[]
}

const initialFormData: FormData = {
  enabled: false,
  text: '',
  variant: 'info',
  persistence_mode: 'persistent',
  routes: [],
  accountIds: []
}

const GlobalConfigDrawer: React.FC<GlobalConfigDrawerProps> = ({ onClose, globalConfig, hidden }) => {
  const dispatch = useDispatch()

  const { data: accounts } = useAccountsQuery()

  const account = useAccount()
  const globalConfigMutation = useGlobalConfigMutation()

  const [formData, setFormData] = useState<FormData>(initialFormData)

  useEffect(() => {
    if (globalConfig) {
      setFormData({
        enabled: globalConfig.enabled ?? false,
        text: globalConfig.text ?? '',
        variant: globalConfig.variant ?? 'info',
        persistence_mode: globalConfig.persistence_mode ?? 'persistent',
        routes: globalConfig.routes ?? [],
        accountIds: globalConfig.accountIds ?? []
      })
    }
  }, [globalConfig])

  const handleChange = (name: keyof FormData) => (value: unknown) => {
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!globalConfig) return

    const updatedGlobalConfig = {
      ...globalConfig,
      ...formData,
      type: 'alert' as const,
      updated_by: account.id
    }

    try {
      await globalConfigMutation.mutateAsync(updatedGlobalConfig)
      onClose()
    } catch (error) {
      dispatch(showAlert({ message: 'Error updating global config', type: 'error', component: 'GlobalConfigDrawer', error: error as Error }))
    }
  }

  const isDisabled = !globalConfig || globalConfigMutation.isLoading

  return (
    <PortalDrawer onClose={onClose} title={globalConfig?.id ? 'Edit Alert' : 'Create Alert'} subtitle={globalConfig?.id} hidden={hidden}>
      <form onSubmit={handleSubmit} className="flex h-full flex-col gap-4">
        <FormField label="Enabled" required>
          <Switch id="enabled" checked={formData.enabled} disabled={isDisabled} onChange={handleChange('enabled')} />
        </FormField>

        <FormField label="Text" required>
          <Input name="text" type="text" required value={formData.text} onChange={(e) => handleChange('text')(e.target.value)} disabled={isDisabled} />
        </FormField>

        <FormField label="Variant" required>
          <Dropdown
            mode="simpleSelect"
            data={['info', 'success', 'warning', 'error'].map((variant) => ({
              label: variant.charAt(0).toUpperCase() + variant.slice(1),
              value: variant,
              checked: formData.variant === variant
            }))}
            disabled={isDisabled}
            keepOpenOnSelect
            className="justify-between"
            onChange={(_, selected) => handleChange('variant')(selected[0]?.value)}
          />
        </FormField>

        <FormField label="Persistence Mode" required>
          <Dropdown
            mode="simpleSelect"
            data={['persistent', 'dismissable', 'auto-close'].map((mode) => ({
              label: mode.charAt(0).toUpperCase() + mode.slice(1),
              value: mode,
              checked: formData.persistence_mode === mode
            }))}
            disabled={isDisabled}
            keepOpenOnSelect
            className="justify-between"
            onChange={(_, selected) => handleChange('persistence_mode')(selected[0]?.value)}
          />
        </FormField>

        <FormField label="Routes" required>
          <Dropdown
            data={[
              { label: 'Global (all routes)', value: '*', checked: formData.routes.includes('*') },
              ...allRoutes
                .filter((r) => !r.hidden)
                .map((route) => ({
                  label: route.route ? `${route.label} (${route.route})` : route.label,
                  value: route.route ?? route.children?.map((childRoute) => childRoute.route).join(',') ?? `${route.label} (no route)`,
                  checked: route.route ? globalConfig?.isAppliedToRoute(route.route) : false,
                  expanded: true,
                  children: route.children?.map((childRoute) => ({
                    label: childRoute.route ? `${childRoute.label} (${childRoute.route})` : childRoute.label,
                    value: childRoute.route ?? `${childRoute.label} (no route)`,
                    checked: childRoute.route ? globalConfig?.isAppliedToRoute(childRoute.route) : false
                  }))
                }))
            ]}
            disabled={isDisabled}
            keepOpenOnSelect
            className="justify-between"
            onChange={(_, selected) => handleChange('routes')(selected.map((s) => s.value))}
          />
        </FormField>

        <FormField label="Accounts">
          <Dropdown
            data={
              accounts?.map((account) => ({
                label: account.name,
                value: account.id,
                checked: formData.accountIds.includes(account.id)
              })) ?? []
            }
            disabled={isDisabled}
            keepOpenOnSelect
            className="justify-between"
            onChange={(_, selected) => handleChange('accountIds')(selected.map((s) => s.value))}
          />
        </FormField>

        <hr className="mt-auto border-th-border" />
        <div className="flex justify-end gap-4">
          <Button type="button" className="w-full" bordered onClick={onClose} disabled={isDisabled}>
            Cancel
          </Button>
          <Button type="submit" className="w-full" bordered disabled={isDisabled} variant="primary">
            Save
          </Button>
        </div>
      </form>
    </PortalDrawer>
  )
}

const FormField: React.FC<{ label: string; required?: boolean; children: React.ReactNode }> = ({ label, required, children }) => (
  <div className="flex flex-col gap-1">
    <label className="ml-1 text-th-text-secondary">
      {label}
      {required && <span className="text-th-error"> *</span>}
    </label>
    {children}
  </div>
)

export default GlobalConfigDrawer
