import ProgressBar from '@ramonak/react-progress-bar'

import { type FunctionComponent, useEffect, useState } from 'react'

import { type SecureScoreTenant } from '../../../types/security'

import axios, { type AxiosResponse } from 'axios'
import { Iconly } from 'react-iconly'
import { type RootStateOrAny, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import Heading from '../../../components/Common/Heading'
import TenantDropdown from '../../../components/Common/TenantDropdown'
import Tile from '../../../components/Common/Tile'
import api from '../../../constants/api'
import Loading from '../Loading/Loading'
import Unauthorised from '../Unauthorised/Unauthorised'

const SecureScores: FunctionComponent = (): JSX.Element => {
  const account = useSelector((state: RootStateOrAny) => state.account)
  const tenant = useSelector((state: RootStateOrAny) => state.tenant)

  const navigate = useNavigate()

  const [loading, setLoading] = useState<boolean>(true)
  const [authorised, setAuthorised] = useState<boolean>(true)

  /* secure scores */
  const [m365Percentage, setM365Percentage] = useState<number>(0)
  const [azurePercentage, setAzurePercentage] = useState<number>(0)

  /* get the selected tenants secure scores */
  useEffect(() => {
    const source = axios.CancelToken.source()

    setLoading(true)

    if (tenant.id === 'undefined') {
      setAuthorised(false)
      return setLoading(false)
    }

    if (tenant.id !== '') {
      api
        .get(`/soteria/account/${account.id}/tenant/${tenant.id}`, { cancelToken: source.token })
        .then((response: AxiosResponse) => {
          if (response.data) {
            const tenant: SecureScoreTenant = response.data

            if (tenant.currentSecureScore?.scorePercent) {
              setM365Percentage(tenant.currentSecureScore?.scorePercent ?? 0)
            } else {
              setM365Percentage(0)
            }

            if (tenant.azureSecureScore?.score?.percentage) {
              setAzurePercentage((tenant.azureSecureScore?.score?.percentage ?? 0) * 100)
            } else {
              setAzurePercentage(0)
            }

            setAuthorised(true)
            return setLoading(false)
          } else {
            setM365Percentage(0)
            setAzurePercentage(0)
            setAuthorised(true)
            return setLoading(false)
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) console.log('CANCELLED axios request')
          else if (error.response?.status === 401) {
            setAuthorised(false)
          }

          setM365Percentage(0)
          setAzurePercentage(0)
          return setLoading(false)
        })
    }

    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant])

  return (
    <Tile className="overflow-visible p-6">
      <div className="relative">
        {/* loading spinner */}
        {loading && <Loading className="z-10 h-full w-full" />}
        {/* unauthorised message */}
        {!authorised && <Unauthorised className="z-10 h-full w-full" />}
        <Heading text="Secure Scores">
          <TenantDropdown className="w-max" />
        </Heading>
        <div className="flex flex-col gap-2 py-2">
          {/* microsoft 365 */}
          <div className="flex flex-col">
            <div>Microsoft 365</div>
            <div className="flex items-center">
              <ProgressBar
                completed={m365Percentage}
                maxCompleted={100}
                isLabelVisible={false}
                bgColor={m365Percentage <= 33 ? 'var(--error)' : m365Percentage <= 66 ? 'var(--warning)' : 'var(--success)'}
                baseBgColor="var(--border)"
                className="flex-1"
              />
              {/* percentage */}
              <span className="w-24 text-center">{m365Percentage.toFixed(0)}%</span>
              {/* view */}
              <div
                className="flex h-8 w-8 flex-none cursor-pointer items-center justify-center rounded-full bg-th-content-secondary"
                onClick={() => navigate('/m365_secure_score')}
              >
                <Iconly name="ChevronRight" set="light" className="h-5 w-5" />
              </div>
            </div>
          </div>
          {/* azure */}
          <div className="flex flex-col">
            <div>Azure</div>
            <div className="flex items-center">
              <ProgressBar
                completed={azurePercentage}
                maxCompleted={100}
                isLabelVisible={false}
                bgColor={azurePercentage <= 33 ? 'var(--error)' : azurePercentage <= 66 ? 'var(--warning)' : 'var(--success)'}
                baseBgColor="var(--border)"
                className="flex-1"
              />
              {/* percentage */}
              <span className="w-24 text-center">{azurePercentage.toFixed(0)}%</span>
              {/* view */}
              <div
                className="flex h-8 w-8 flex-none cursor-pointer items-center justify-center rounded-full bg-th-content-secondary"
                onClick={() => navigate('/azure_secure_score')}
              >
                <Iconly name="ChevronRight" set="light" className="h-5 w-5" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Tile>
  )
}

export default SecureScores
