import { withAITracking } from '@microsoft/applicationinsights-react-js'

import { Fragment, type FunctionComponent, useEffect, useState } from 'react'

import { type Invoice } from '../../types/invoice'

import axios from 'axios'
import moment, { type Moment } from 'moment'
import { CSVLink } from 'react-csv'
import { Iconly } from 'react-iconly'
import { FcDocument, FcFolder, FcLeave, FcPaid } from 'react-icons/fc'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { showAlert } from '../../actions/alertsActions'
import { startLoading, stopLoading } from '../../actions/loadingActions'
import Banner from '../../components/Common/Banner'
import Button from '../../components/Common/Button'
import Count from '../../components/Common/Count'
import DateRange from '../../components/Common/DateRange/DateRange'
import Dropdown from '../../components/Common/Dropdown'
import Section from '../../components/Common/Section'
import Spacer from '../../components/Common/Spacer'
import InvoiceTable from '../../components/Tables/InvoiceTable'
import { reactPlugin } from '../../configs/appInsights'
import api from '../../constants/api'

/* dropdown options */
const periodOptions = [
  {
    label: 'Current Month',
    value: 'current'
  },
  {
    label: 'Previous Month',
    value: 'previous'
  },
  {
    label: 'Custom',
    value: 'custom'
  }
]

const firstDayOfThisMonth = moment().startOf('month')
const lastDayOfThisMonth = moment().endOf('month')

const Invoices: FunctionComponent = (): JSX.Element => {
  const [startDate, setStartDate] = useState<Moment>(firstDayOfThisMonth)
  const [endDate, setEndDate] = useState<Moment>(lastDayOfThisMonth)
  const [period, setPeriod] = useState<string>(periodOptions[0].value)
  const [invoices, setInvoices] = useState<Invoice[]>([])
  const [filtered, setFiltered] = useState<Invoice[]>(invoices)
  const [showSearch, setShowSearch] = useState<boolean>(false)
  const [state, setState] = useState<string | undefined>(undefined)
  const [open, setOpen] = useState<number>(0)
  const [overdue, setOverdue] = useState<number>(0)
  const [paid, setPaid] = useState<number>(0)

  const dispatch = useDispatch()
  const account = useSelector((state: RootStateOrAny) => state.account)

  /* filter the invoices by search term */
  const filter = (value: string) => {
    const searchTerm = value.toLowerCase()

    setFiltered(invoices.filter((invoice: Invoice) => invoice.number.toLowerCase().indexOf(searchTerm) !== -1))
  }

  /* set the date range based on the period that has been selected */
  const handlePeriodChange = (period: string) => {
    setPeriod(period)

    const firstDayOfThisMonth = moment().startOf('month')
    const lastDayOfThisMonth = moment().endOf('month')

    if (period === 'current') {
      /* set the dates to span the current month */
      setStartDate(firstDayOfThisMonth.startOf('month'))
      setEndDate(lastDayOfThisMonth.endOf('month'))
    } else if (period === 'previous') {
      /* set the dates to span the previous month */
      setStartDate(moment(firstDayOfThisMonth.subtract(1, 'months').startOf('month')))
      setEndDate(moment(lastDayOfThisMonth.subtract(1, 'months').endOf('month')))
    }
  }

  /* set the start and end dates */
  const handleDatesChange = (startDate: Moment, endDate: Moment) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  /* start loading animation on page load */
  useEffect(() => {
    dispatch(startLoading())
  }, [dispatch])

  /* get the invoices for the selected account */
  useEffect(() => {
    const source = axios.CancelToken.source()

    dispatch(startLoading())

    const data = {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD')
    }

    if (account.id) {
      api
        .post(`/servicenow/account/${account.id}/invoices`, data, { cancelToken: source.token })
        .then((response) => {
          if (response.data) {
            const states: Record<string, number> = {}

            for (const invoice of response.data) {
              const due_date = moment(invoice.due_date)
              const today = moment()

              if (invoice.due_date && due_date.isBefore(today) && invoice.state !== 'Paid In Full') {
                invoice.state = 'Overdue'
              }

              states[invoice.state] = states[invoice.state] ? states[invoice.state] + 1 : 1
            }

            setInvoices(response.data)
            setOpen(states['Open'] || 0)
            setOverdue(states['Overdue'] || 0)
            setPaid(states['Paid In Full'] || 0)

            /* stop loading here if there are no invoices to process */
            if (response.data.length === 0) {
              dispatch(stopLoading())
            }
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            dispatch(
              showAlert({
                type: 'error',
                message: 'We were unable to retrieve your invoices.',
                component: 'Invoices',
                error
              })
            )
            dispatch(stopLoading())
          }
        })
    }

    return () => {
      source.cancel()
    }
  }, [account, startDate, endDate, dispatch])

  /* filter the invoices by state */
  useEffect(() => {
    const filtered = invoices.filter((invoice: Invoice) => {
      return invoice.state === state || state === undefined
    })
    setFiltered(filtered)
  }, [state, invoices, showSearch])

  /* stop loading when the invoices have been processed */
  useEffect(() => {
    if (filtered.length > 0) {
      dispatch(stopLoading())
    }
  }, [filtered, dispatch])

  return (
    <Fragment>
      <Section>
        <div className="my-5 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-1 mdlg:grid-cols-2 xl:grid-cols-4">
          <Count
            icon={<FcFolder className="h-full w-full text-th-warning" />}
            value={invoices.length}
            label="All Invoices"
            className="cursor-pointer"
            onClick={() => setState(undefined)}
            active={state === undefined}
          />
          <Count
            icon={<FcDocument className="h-full w-full" />}
            value={open}
            label="Open"
            className="cursor-pointer"
            onClick={() => setState('Open')}
            active={state === 'Open'}
          />
          <Count
            icon={<FcLeave className="h-full w-full" />}
            value={overdue}
            label="Overdue"
            className="cursor-pointer"
            onClick={() => setState('Overdue')}
            active={state === 'Overdue'}
          />
          <Count
            icon={<FcPaid className="h-full w-full" />}
            value={paid}
            label="Paid In Full"
            className="cursor-pointer"
            onClick={() => setState('Paid In Full')}
            active={state === 'Paid In Full'}
          />
        </div>
      </Section>
      <Section>
        <Banner>
          {/* date range */}
          <Dropdown
            options={periodOptions}
            defaultValue={periodOptions[0]}
            variant={Dropdown.variant.DEFAULT}
            label="Created"
            multi={false}
            onChange={(option) => handlePeriodChange(option.value)}
          />
          <DateRange
            start={startDate}
            end={endDate}
            startDateId="invoices_start_date"
            endDateId="invoices_end_date"
            variant={DateRange.variant.DEFAULT}
            onChange={(startDate, endDate) => handleDatesChange(startDate, endDate)}
            disabled={period !== 'custom'}
            focus={period === 'custom'}
          />
          <Spacer className="hidden sm:block" />
          {/* search */}
          {showSearch ? (
            <Fragment>
              <input type="text" className="rounded-lg p-2 font-body text-sm" placeholder="Search" autoFocus onChange={(e) => filter(e.target.value)} />
              <IoCloseCircleOutline className="h-6 w-6 cursor-pointer" onClick={() => setShowSearch(false)} />
            </Fragment>
          ) : (
            <Iconly name="Search" set="light" className="cursor-pointer" onClick={() => setShowSearch(true)} />
          )}
          {/* csv download */}
          <CSVLink data={filtered} filename="invoices.csv" target="_blank">
            <Button onClick={() => null}>Download CSV</Button>
          </CSVLink>
        </Banner>
        <Spacer />
        <InvoiceTable invoices={filtered} />
      </Section>
    </Fragment>
  )
}

const InvoicesWithTracking = withAITracking(reactPlugin, Invoices, 'Invoices')
export default InvoicesWithTracking
