import { useMutation, useQueryClient } from '@tanstack/react-query'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'

import { getChangeAttachmentsQueryKey } from '../queries/useChangeAttachmentsQuery'

interface AttachmentMutationParams {
  changeId: string
  file: File
}

export const useCreateChangeAttachmentMutation = () => {
  const queryClient = useQueryClient()
  const account = useAccount()

  return useMutation({
    mutationFn: async ({ changeId, file }: AttachmentMutationParams) => {
      const formData = new FormData()
      formData.append('file', file)

      const response = await api.post(`/servicenow/account/${account.id}/change_request/${changeId}/attachment`, formData)

      await queryClient.invalidateQueries({ queryKey: getChangeAttachmentsQueryKey(account.id, changeId) })

      return response.data
    }
  })
}
