import { type ColumnDef } from '@tanstack/react-table'

import React, { useMemo } from 'react'

import { type AzureUsageExportDataRow } from 'src/types/azure'

import Table from 'src/components/Common/Table/Table'

interface AzureUsageExampleTableProps {
  data: AzureUsageExportDataRow[]
}

const AzureUsageExampleTable: React.FC<AzureUsageExampleTableProps> = ({ data }) => {
  const columns = useMemo<ColumnDef<AzureUsageExportDataRow>[]>(
    () => [
      { accessorKey: 'usageDate', header: 'Usage Date' },
      { accessorKey: 'tenantId', header: 'Tenant ID' },
      { accessorKey: 'subscriptionId', header: 'Subscription ID' },
      { accessorKey: 'tenantName', header: 'Tenant Name' },
      { accessorKey: 'subscriptionName', header: 'Subscription Name' },
      { accessorKey: 'resourceGroup', header: 'Resource Group' },
      { accessorKey: 'resourceLocation', header: 'Resource Location' },
      { accessorKey: 'resourceURI', header: 'Resource URI' },
      { accessorKey: 'resourceName', header: 'Resource Name' },
      { accessorKey: 'meterId', header: 'Meter ID' },
      { accessorKey: 'meterName', header: 'Meter Name' },
      { accessorKey: 'meterRegion', header: 'Meter Region' },
      { accessorKey: 'meterCategory', header: 'Meter Category' },
      { accessorKey: 'meterSubCategory', header: 'Meter SubCategory' },
      { accessorKey: 'unitOfMeasure', header: 'Unit of Measure' },
      { accessorKey: 'unitPriceGBP', header: 'Unit Price GBP' },
      { accessorKey: 'quantity', header: 'Quantity' },
      { accessorKey: 'totalSpendGBP', header: 'Total Spend GBP' },
      { accessorKey: 'tags', header: 'Tags' },
      { accessorKey: 'billed', header: 'Billed' }
    ],
    []
  )

  return <Table data={data} columns={columns} />
}

export default AzureUsageExampleTable
