import { type ColumnDef } from '@tanstack/react-table'

import React, { type FunctionComponent, useCallback, useMemo } from 'react'

import { type Entitlement, type Tenant } from '../../types/azure'

import Table from 'src/components/Common/Table/Table'

import { Iconly } from 'react-iconly'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { closeDrawers, openLightHouseConnectionDrawer } from '../../actions/drawerActions'
import Popover from '../Common/Popover'

interface Props {
  entitlements: Entitlement[]
}

const SubscriptionTable: FunctionComponent<Props> = ({ entitlements }): JSX.Element => {
  const tenants = useSelector((state: RootStateOrAny) => state.tenants)
  const dispatch = useDispatch()

  const enableDeployments = useCallback(
    (event: React.MouseEvent, entitlement: Entitlement) => {
      event.stopPropagation()
      dispatch(closeDrawers())
      dispatch(openLightHouseConnectionDrawer(entitlement))
    },
    [dispatch]
  )

  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'name',
          header: 'Name'
        },
        {
          accessorKey: 'id',
          header: 'Subscription ID'
        },
        {
          accessorKey: 'tenant_id',
          header: 'Tenant'
        },
        {
          accessorKey: 'state',
          header: 'State'
        },
        {
          accessorKey: 'in_lighthouse',
          header: 'Deployments Enabled',
          cell: (info) => (
            <div className="flex max-w-xs justify-center">
              {info.getValue() ? (
                <Popover text="Deployments enabled" className="flex justify-center">
                  <Iconly set="two-tone" primaryColor="green" name="TickSquare" />
                </Popover>
              ) : (
                <Popover text="Deployments disabled. Click for more info ..." className="flex justify-center">
                  <Iconly
                    set="two-tone"
                    primaryColor="red"
                    name="CloseSquare"
                    onClick={(event: React.MouseEvent) => enableDeployments(event, info.row.original)}
                    className="cursor-pointer"
                  />
                </Popover>
              )}
            </div>
          )
        }
      ] satisfies ColumnDef<(typeof entitlements)[number]>[],
    [enableDeployments]
  )

  const data = useMemo(
    () =>
      entitlements.map((entitlement: Entitlement) => {
        const tenant = tenants?.find((t: Tenant) => t.id === entitlement.tenant_id)
        return {
          ...entitlement,
          tenant_id: tenant?.name,
          state: entitlement.state.charAt(0).toUpperCase() + entitlement.state.slice(1)
        }
      }),
    [entitlements, tenants]
  )

  return <Table data={data} columns={columns} />
}

export default SubscriptionTable
