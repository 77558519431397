import { useCallback, useState } from 'react'

import useDownloadSasUriMutation from 'src/hooks/services/provide/mutations/useDownloadSasUriMutation'

export const useFileDownload = () => {
  const [downloadingFileIds, setDownloadingFileIds] = useState<string[]>([])
  const { mutateAsync: getDownloadSasUri } = useDownloadSasUriMutation()

  const requestDownloadPermission = async () => {
    try {
      // Request downloads permission
      const result = await navigator.permissions.query({ name: 'downloads' as PermissionName })
      return result.state === 'granted'
    } catch (error) {
      // Fallback for browsers that don't support downloads permission
      console.warn('Downloads permission API not supported:', error)
      return true
    }
  }

  const handleFileDownload = useCallback(
    async (fileId: string, fileName: string) => {
      setDownloadingFileIds((prev) => [...prev, fileId])
      try {
        const { sasUri } = await getDownloadSasUri({ fileId })

        const link = document.createElement('a')
        link.href = sasUri
        link.download = fileName

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (error) {
        console.error('Download failed:', error)
      } finally {
        setDownloadingFileIds((prev) => prev.filter((id) => id !== fileId))
      }
    },
    [getDownloadSasUri]
  )

  const handleMultipleFilesDownload = useCallback(
    async (files: Array<{ id: string; name: string }>) => {
      const hasPermission = await requestDownloadPermission()

      if (hasPermission) {
        setDownloadingFileIds(files.map((file) => file.id))
        try {
          const downloadUrls = await Promise.all(
            files.map(async (file) => {
              const { sasUri } = await getDownloadSasUri({ fileId: file.id })
              return { sasUri, fileName: file.name }
            })
          )

          const container = document.createElement('div')
          container.style.display = 'none'
          document.body.appendChild(container)

          for (const { sasUri, fileName } of downloadUrls) {
            const link = document.createElement('a')
            link.href = sasUri
            link.download = fileName
            container.appendChild(link)
            await new Promise((resolve) => setTimeout(resolve, 250))
            link.click()
          }

          await new Promise((resolve) => setTimeout(resolve, 250))
          document.body.removeChild(container)
        } catch (error) {
          console.error('Multiple downloads failed:', error)
        } finally {
          setDownloadingFileIds([])
        }
      } else {
        for (const file of files) {
          await handleFileDownload(file.id, file.name)
          await new Promise((resolve) => setTimeout(resolve, 250))
        }
      }
    },
    [getDownloadSasUri, handleFileDownload]
  )

  return { downloadingFileIds, handleFileDownload, handleMultipleFilesDownload }
}
