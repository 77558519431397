import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useMutation from 'src/hooks/utils/useMutation'

const useDownloadSasUriMutation = () => {
  const account = useAccount()

  return useMutation(
    async ({ fileId }: { fileId: string }) => {
      const response = await api.post(`provide/${account.id}/file/download`, { id: fileId })
      return response.data as { sasUri: string; fileName: string; contentType: string; contentLength: number }
    },
    {
      errorMessage: 'An error occurred while getting the download url.'
    }
  )
}

export default useDownloadSasUriMutation
