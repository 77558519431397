import { type Entitlement } from '../types/azure'

const initialState = {
  name: '',
  id: '',
  subscription_id: '',
  tenant_id: '',
  state: ''
}

export const entitlementReducer = (
  state = initialState,
  action: {
    type: string
    payload: Entitlement
  }
): Entitlement => {
  switch (action.type) {
    case 'SET_ENTITLEMENT':
      localStorage.setItem('entitlement', JSON.stringify(action.payload))
      return action.payload
    default:
      return state
  }
}

export const entitlementsReducer = (
  state: Entitlement[] = [],
  action: {
    type: string
    payload: Entitlement[]
  }
): Entitlement[] => {
  switch (action.type) {
    case 'SET_ENTITLEMENTS':
      return action.payload
    default:
      return state
  }
}
