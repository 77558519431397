import { type ColumnDef } from '@tanstack/react-table'

import React, { useMemo } from 'react'

import Table from 'src/components/Common/Table/Table'

interface ColumnDescription {
  name: string
  value: string
}

interface ColumnDescriptionsTableProps {
  columnDescriptions: ColumnDescription[]
}

const ColumnDescriptionsTable: React.FC<ColumnDescriptionsTableProps> = ({ columnDescriptions }) => {
  const columns = useMemo<ColumnDef<ColumnDescription>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Column Name'
      },
      {
        accessorKey: 'value',
        header: 'Description'
      }
    ],
    []
  )

  return <Table data={columnDescriptions} columns={columns} disableControls bordered />
}

export default ColumnDescriptionsTable
