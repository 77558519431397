import { type FormEvent, Fragment, type FunctionComponent, useState } from 'react'

import { type Tenant } from '../../types/azure'

import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { showAlert } from '../../actions/alertsActions'
import { closeDrawers } from '../../actions/drawerActions'
import api from '../../constants/api'
import Button from '../Common/Button'
import Drawer from '../Common/Drawer'
import Select from '../Common/Select'
import Submit from '../Common/Submit'
import TextArea from '../Common/TextArea'

/* review type */
const reviewTypeOptions = [
  {
    label: 'M365',
    value: 'M365'
  },
  {
    label: 'Azure',
    value: 'Azure'
  }
]

/* form names */
interface Forms extends HTMLCollectionOf<HTMLFormElement> {
  bookSecurityReviewForm: HTMLFormElement
}

const BookSecurityReview: FunctionComponent = (): JSX.Element => {
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const account = useSelector((state: RootStateOrAny) => state.account)
  const tenants = useSelector((state: RootStateOrAny) => state.tenants)
  const tenant = useSelector((state: RootStateOrAny) => state.tenant)
  const reviewType: string = useSelector((state: RootStateOrAny) => state.drawers.book_security_review)

  /* drawer actions */
  const Actions: FunctionComponent = (): JSX.Element => {
    return (
      <Fragment>
        <Button bordered onClick={() => dispatch(closeDrawers())}>
          Cancel
        </Button>
        <Submit variant="primary" form="bookSecurityReviewForm" />
      </Fragment>
    )
  }

  const bookSecurityReview = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    const forms = document.forms as Forms
    const form = forms.bookSecurityReviewForm
    const data = new FormData(form)

    const ticket = {
      account: account.id,
      contact_type: 'Provide',
      category: 'Request',
      title: data.get('type') + ' Security Review',
      description: 'Tenant: ' + data.get('tenant') + '\n\n' + data.get('additional_info')
    }

    api
      .post(`/servicenow/account/${account.id}/ticket`, ticket)
      .then((response) => {
        setLoading(false)
        dispatch(closeDrawers())

        dispatch(
          showAlert({
            type: 'success',
            message: response.data.number + ' created.',
            component: 'BookSecurityReview'
          })
        )
      })
      .catch((error) => {
        setLoading(false)
        dispatch(
          showAlert({
            type: 'error',
            message: 'We were unable to create your ticket. Please try again.',
            component: 'BookSecurityReview',
            error
          })
        )
      })
  }

  return (
    <Drawer title={'Book a Review'} loading={loading} actions={<Actions />}>
      <form onSubmit={(e) => bookSecurityReview(e)} id="bookSecurityReviewForm" className="flex flex-col gap-4">
        {/* type */}
        <Select name="type" label="Type" options={reviewTypeOptions} selected={reviewType} required />
        {/* tenant */}
        <Select
          name="tenant"
          label="Tenant"
          options={tenants.map((t: Tenant) => {
            return {
              label: t.name,
              value: t.id
            }
          })}
          selected={tenant.id}
          required
        />
        {/* additional information */}
        <TextArea name="additional_info" label="Additional Information" />
      </form>
    </Drawer>
  )
}

export default BookSecurityReview
