import { type ColumnDef } from '@tanstack/react-table'

import React, { useMemo } from 'react'

import { type Order } from '../../types/order'

import moment from 'moment'
import { Iconly } from 'react-iconly'
import { useDispatch } from 'react-redux'

import { closeDrawers, openOrderDrawer } from '../../actions/drawerActions'
import Table from '../Common/Table/Table'

interface Props {
  orders: Order[]
}

const OrderTable: React.FC<Props> = ({ orders }) => {
  const dispatch = useDispatch()

  const columns = useMemo<ColumnDef<Order>[]>(
    () => [
      {
        accessorKey: 'number',
        header: 'Number'
      },
      {
        accessorKey: 'ordered_by',
        header: 'Ordered By'
      },
      {
        accessorKey: 'order_date',
        header: 'Order Date',
        cell: (info) => moment(info.getValue() as string).format('DD/MM/YY'),
        sortingFn: (a, b, id) => moment(a.getValue(id)).unix() - moment(b.getValue(id)).unix()
      },
      {
        accessorKey: 'state',
        header: 'State'
      },
      {
        accessorKey: 'one_time',
        header: 'One Time (£)'
      },
      {
        accessorKey: 'mrr',
        header: 'Monthly Recurring (£)'
      },
      {
        id: 'actions',
        cell: () => <Iconly set="light" name="Show" className="cursor-pointer" />
      }
    ],
    []
  )

  return (
    <Table
      data={orders}
      columns={columns}
      onRowClick={(row) => {
        dispatch(closeDrawers())
        dispatch(openOrderDrawer(row))
      }}
    />
  )
}

export default OrderTable
