import { useCallback, useEffect, useMemo, useState } from 'react'

import { Iconly } from 'react-iconly'
import Scrollbar from 'react-smooth-scrollbar'

/* utils */
import { classNames } from '../../utils/classNames'

interface Props {
  headers: string[]
  data: (string | JSX.Element)[][] | null

  totalRowCount?: number

  onPageSizeChange?: (pageSize: number) => void
  onPageChange?: (pageIndex: number) => void
  onRowClick?: (rowIndex: number) => void
  onSortChange?: (columnIndex: number, direction: 'asc' | 'desc') => void
}

const PaginatedTable: React.FC<Props> = (props: Props): JSX.Element => {
  const [pageSize, setPageSize] = useState(10)
  const [pageIndex, setPageIndex] = useState(0)
  const [sortBy, setSortBy] = useState<{ columnIndex: number; direction: 'asc' | 'desc' } | null>(null)

  const handlePageChange = useCallback(
    (pageIndex: number) => {
      props.onPageChange?.(pageIndex)
      setPageIndex(pageIndex)
    },
    [props]
  )

  useEffect(() => {
    const hasTotalRowCount = props.totalRowCount && props.totalRowCount > 0
    const isOverPages = hasTotalRowCount && (props.totalRowCount || 0) < pageIndex * pageSize
    if (isOverPages) handlePageChange(Math.floor((props.totalRowCount || 0) / pageSize))
  }, [handlePageChange, pageIndex, pageSize, props.totalRowCount])

  const pageOptions = useMemo(
    () =>
      Array(Math.ceil((props.totalRowCount || 0) / pageSize))
        .fill(0)
        .map((_, idx) => idx),
    [pageSize, props.totalRowCount]
  )

  useEffect(() => {
    if (pageOptions.length < pageIndex && props.data) handlePageChange(pageOptions.length - 1)
  }, [pageOptions.length, pageIndex, props.data, handlePageChange])

  return (
    <div className="rounded-content bg-th-content px-4 py-4">
      <Scrollbar alwaysShowTracks={true}>
        <table className="w-full text-sm">
          {/* head */}
          <thead className="border-b border-th-border text-th-text-secondary">
            <tr>
              {props.headers.map((column) => (
                <th
                  className="cursor-pointer p-4 text-left"
                  key={column + '_header'}
                  onClick={() => {
                    const isNewSort = sortBy?.columnIndex !== props.headers.indexOf(column)
                    setSortBy({ columnIndex: props.headers.indexOf(column), direction: isNewSort ? 'asc' : sortBy?.direction === 'asc' ? 'desc' : 'asc' })
                    props.onSortChange?.(props.headers.indexOf(column), isNewSort ? 'asc' : sortBy?.direction === 'asc' ? 'desc' : 'asc')
                  }}
                >
                  <span className="flex items-center gap-2">
                    {column}
                    {sortBy?.columnIndex === props.headers.indexOf(column) &&
                      (sortBy.direction === 'asc' ? (
                        <Iconly name="ChevronDown" set="light" className="h-4 w-4" />
                      ) : (
                        <Iconly name="ChevronUp" set="light" className="h-4 w-4" />
                      ))}
                  </span>
                </th>
              ))}
            </tr>
          </thead>
          {/* body */}
          <tbody className="font-bold text-th-text">
            {props.data?.map((row, index) => (
              <tr
                onClick={() => props.onRowClick?.(index)}
                className={classNames('border-b border-th-border', 'cursor-pointer hover:shadow')}
                key={index + '_row'}
              >
                {row.map((cell, idx) => {
                  return (
                    <td className="p-4" key={idx + index + '_cell'}>
                      {cell}
                    </td>
                  )
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </Scrollbar>

      {/* pagination */}
      <div className="flex items-center justify-center gap-4 px-8 pt-4 text-sm font-bold">
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value))
            props.onPageSizeChange?.(Number(e.target.value))
          }}
          className="rounded-lg border border-th-border bg-th-content px-2 py-1 text-xs"
        >
          {[5, 10, 20, 25, 50, 100].map((pageSize) => (
            <option key={pageSize + '_size'} value={pageSize}>
              Show: {pageSize}
            </option>
          ))}
        </select>
        <div className="flex flex-auto justify-center">
          <div className="flex w-min items-center gap-2">
            <button
              onClick={() => {
                props.onPageChange?.(pageIndex - 1)
                handlePageChange(pageIndex - 1)
              }}
              disabled={pageIndex <= 0}
              className="rounded-lg border border-th-border px-2 py-1"
            >
              <Iconly name="ArrowLeft" set="light" className="h-5" />
            </button>
            {pageOptions.map((option: number) => {
              if (
                option === pageOptions.length - 3 &&
                pageIndex < pageOptions.length - 5 &&
                pageIndex !== option &&
                option < pageOptions.length - 2 &&
                pageOptions.length > 5
              ) {
                return <div className="px-2 py-1">...</div>
              }

              if (
                (option > pageIndex + 1 && option < pageOptions.length - 2 && pageIndex < pageOptions.length - 5) ||
                (option < pageIndex && option < pageOptions.length - 5)
              ) {
                return null
              }

              return (
                <button
                  key={option + '_page'}
                  onClick={() => {
                    props.onPageChange?.(option)
                    setPageIndex(option)
                  }}
                  className={classNames('rounded-lg border px-4 py-1', pageIndex === option ? 'border-th-primary focus:shadow-none' : 'border-th-border')}
                >
                  {option + 1}
                </button>
              )
            })}
            <button
              onClick={() => {
                props.onPageChange?.(pageIndex + 1)
                setPageIndex(pageIndex + 1)
              }}
              disabled={pageIndex >= pageOptions.length - 1}
              className="rounded-lg border border-th-border px-2 py-1"
            >
              <Iconly name="ArrowRight" set="light" className="h-5" />
            </button>
          </div>
        </div>
        <div className="text-xs">
          Showing {Math.min(pageSize, props.data?.length || 0)} of {props.totalRowCount} entries
        </div>
      </div>
    </div>
  )
}

export default PaginatedTable
