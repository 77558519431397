import { SeverityLevel } from '@microsoft/applicationinsights-web'

import { type Alert } from '../types/alert'

import { appInsights } from '../configs/appInsights'

export const showAlert = (
  alert: Alert,
  index?: number
): {
  type: string
  payload: {
    alert: Alert
    index: number
  }
} => {
  if (alert.type === 'error')
    /* send information to app insights */
    appInsights.trackException({
      error: alert.error || alert.message ? new Error(alert.message) : new Error('Unknown error occured'),
      severityLevel: SeverityLevel.Error,
      properties: {
        endpoint: window.location.pathname,
        component: alert.component,
        message: alert.message || 'Unknown error occured'
      }
    })

  return {
    type: 'SHOW_ALERT',
    payload: { alert, index: index || 0 }
  }
}

export const hideAlert = (
  index: number
): {
  type: string
  payload: { index: number }
} => {
  return {
    type: 'HIDE_ALERT',
    payload: {
      index: index
    }
  }
}
