import { useMsal } from '@azure/msal-react'

import { useEffect, useState } from 'react'

export interface Claims {
  sub?: string
  first_name?: string
  last_name?: string
  email?: string
  tid?: string
  sys_id?: string
}

const useMsalClaims = () => {
  const { instance } = useMsal()

  const [claims, setClaims] = useState<Claims | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (claims) return

    setIsLoading(true)

    // Has Active Account
    const idTokenClaims = instance.getActiveAccount()?.idTokenClaims
    if (idTokenClaims) {
      setClaims(idTokenClaims)
      return setIsLoading(false)
    }

    // Does not have active account
    const accounts = instance.getAllAccounts()
    if (!accounts.length) return setIsLoading(false)

    // Has Accounts but no active account
    instance.setActiveAccount(accounts[0])

    // Check to see if claims are set
    if (accounts[0]!.idTokenClaims) return setIsLoading(false)
    setClaims(accounts[0]!.idTokenClaims!)
    return setIsLoading(false)
  }, [claims, instance])

  return {
    data: claims,
    isLoading
  }
}

export default useMsalClaims
