import { type FunctionComponent } from 'react'

/* utils */
import { classNames } from '../../../utils/classNames'

interface Props {
  active: boolean
  className?: string
}

const Badge: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { active, className } = props

  return <div className={classNames('h-2.5 w-2.5 rounded-full border border-th-white bg-th-error', className, !active && 'hidden')}></div>
}

export default Badge
