import { type UseBaseQueryOptions } from '@tanstack/react-query'

import api from '~/constants/api'

import useAccount from '~/hooks/utils/useAccount'
import useQuery from '~/hooks/utils/useQuery'

import { type Moment } from 'moment'

export interface ApprovalStats {
  approved: number
  rejected: number
  requested: number
  expired: number
  total: number
}

export interface ApprovalStatsFilterOptions {
  startDate: Moment
  endDate: Moment
}

interface ApprovalStatsRequestData {
  startDate: string
  endDate: string
}

const mapToApprovalStatsRequestData = (filterOptions: ApprovalStatsFilterOptions): ApprovalStatsRequestData => ({
  startDate: filterOptions.startDate.format('YYYY-MM-DD'),
  endDate: filterOptions.endDate.format('YYYY-MM-DD')
})

export const fetchApprovalStats = async (accountId: string, requestData: ApprovalStatsRequestData, signal?: AbortSignal) => {
  const response = await api.post(`/servicenow/account/${accountId}/changeCustomerApprovals/stats`, requestData, { signal })
  return response.data as ApprovalStats
}

export const getApprovalStatsQueryKey = (accountId: string, filterOptions: ApprovalStatsFilterOptions) => {
  return ['approvalStats', accountId, mapToApprovalStatsRequestData(filterOptions)] as const
}

const useApprovalStatsQuery = (filterOptions: ApprovalStatsFilterOptions, options?: UseBaseQueryOptions<ApprovalStats | null>) => {
  const account = useAccount()
  const requestData = mapToApprovalStatsRequestData(filterOptions)

  return useQuery(getApprovalStatsQueryKey(account.id, filterOptions), async ({ signal }) => fetchApprovalStats(account.id, requestData, signal), {
    enabled: !!account.id,
    refetchOnWindowFocus: false,
    ...options
  })
}

export default useApprovalStatsQuery
