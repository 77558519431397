import { type FunctionComponent } from 'react'

import { useDispatch } from 'react-redux'

import { openNavigation } from '../../../../actions/navigationActions'

interface Props {
  className?: string
}

const Hamburger: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { className } = props

  const dispatch = useDispatch()

  return (
    <button className={className + ' focus:outline-none'} onClick={() => dispatch(openNavigation())}>
      <div className="mb-0.75 h-0.75 w-4 rounded-full bg-th-tertiary"></div>
      <div className="mb-0.75 h-0.75 w-5 rounded-full bg-th-tertiary"></div>
      <div className="h-0.75 w-5 rounded-full bg-th-tertiary"></div>
    </button>
  )
}

export default Hamburger
