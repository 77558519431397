import { useMutation, useQueryClient } from '@tanstack/react-query'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useOwnUser from 'src/hooks/utils/useOwnUser'

import { useChangeApprovalFiltersContext } from '~/views/Support/ChangeApprovals/context/ChangeApprovalFilter/useContext'
import { useChangeFiltersContext } from '~/views/Support/Changes/context/ChangeFilter/useContext'

import { getApprovalStatsQueryKey } from '../queries/useApprovalStatsQuery'
import { getChangeApprovalQueryKey } from '../queries/useChangeApprovalQuery'
import { getChangeApprovalsQueryKey } from '../queries/useChangeApprovalsQuery'
import { getChangeQueryKey } from '../queries/useChangeQuery'
import { getChangeStatsQueryKey } from '../queries/useChangeStatsQuery'
import { getChangesQueryKey } from '../queries/useChangesQuery'

interface ApprovalMutationParams {
  changeId: string
  customerApprovalId: string
  approved: boolean
  message: string
  originator: string
}

export const useChangeApprovalMutation = () => {
  const queryClient = useQueryClient()
  const account = useAccount()
  const user = useOwnUser()
  const changesContext = useChangeFiltersContext(false)
  const approvalsContext = useChangeApprovalFiltersContext(false)
  return useMutation({
    mutationFn: async ({ changeId, customerApprovalId, approved, message, originator }: ApprovalMutationParams) => {
      const data = {
        change: changeId,
        approved_by: originator, //not even needed, backend login context  sysid should be used.
        approved,
        comment: `${user?.first_name} ${user?.last_name} <{#}> ${approved ? 'Approved' : 'Rejected'}: ${message}`
      }

      const response = await api.post(`/servicenow/account/${account.id}/change/${changeId}/handleapproval/${customerApprovalId}`, data)

      const refreshArray = [
        queryClient.invalidateQueries({ queryKey: getChangeApprovalQueryKey(account.id, customerApprovalId) }),
        queryClient.invalidateQueries({ queryKey: getChangeQueryKey(account.id, changeId) })
      ]

      if (changesContext) {
        refreshArray.push(queryClient.invalidateQueries({ queryKey: getChangesQueryKey(account.id, changesContext.filterOptions) }))
        refreshArray.push(queryClient.invalidateQueries({ queryKey: getChangeStatsQueryKey(account.id, changesContext.filterOptions) }))
      }

      if (approvalsContext) {
        refreshArray.push(queryClient.invalidateQueries({ queryKey: getChangeApprovalsQueryKey(account.id, approvalsContext.filterOptions) }))
        refreshArray.push(queryClient.invalidateQueries({ queryKey: getApprovalStatsQueryKey(account.id, approvalsContext.filterOptions) }))
      }

      await Promise.all(refreshArray)

      return response.data
    }
  })
}
